/**
 * @author HypnosNova / https://www.threejs.org.cn/gallery
 */

import {
	Vector2
} from "three";
import { MainObjects } from "../common/MainObjects";

var SelectionHelper = (function () {

	function SelectionHelper(selectionBox, renderer) {

		this.element = document.createElement('div');
		this.element.style.pointerEvents = 'none';
		this.element.style.border = "1px dashed #55aaff";
		this.element.style.backgroundColor = "rgba(75, 160, 255, 0.3)";
		this.element.style.position = "absolute";
		this.enable = false;

		this.renderer = renderer;

		this.startPoint = new Vector2();
		this.pointTopLeft = new Vector2();
		this.pointBottomRight = new Vector2();

		this.isDown = false;

		this.renderer.domElement.addEventListener('mousedown', function (event) {
			if (this.enable && event.button == 0) {
				this.isDown = true;
				this.onSelectStart(event);
			}
		}.bind(this), false);

		this.renderer.domElement.addEventListener('mousemove', function (event) {
			if (this.enable && event.button == 0) {
				if (this.isDown) {

					this.onSelectMove(event);

				}
			}
		}.bind(this), false);

		this.renderer.domElement.addEventListener('mouseup', function (event) {

			if (this.enable && event.button == 0) {
				this.isDown = false;
				this.onSelectOver(event);
			}
		}.bind(this), false);

	}

	SelectionHelper.prototype.onSelectStart = function (event) {

		this.renderer.domElement.parentElement.appendChild(this.element);

		const offsetLeft = MainObjects.Render.m_container.offsetLeft;
		const offsetTop = MainObjects.Render.m_container.offsetTop;

		this.element.style.left = (event.clientX - offsetLeft) + 'px';
		this.element.style.top = (event.clientY - offsetTop) + 'px';
		this.element.style.width = '0px';
		this.element.style.height = '0px';

		this.startPoint.x = event.clientX - offsetLeft;
		this.startPoint.y = event.clientY - offsetTop;

	};

	SelectionHelper.prototype.onSelectMove = function (event) {

		const offsetLeft = MainObjects.Render.m_container.offsetLeft;
		const offsetTop = MainObjects.Render.m_container.offsetTop;

		this.pointBottomRight.x = Math.max(this.startPoint.x, event.clientX - offsetLeft);
		this.pointBottomRight.y = Math.max(this.startPoint.y, event.clientY - offsetTop);
		this.pointTopLeft.x = Math.min(this.startPoint.x, event.clientX - offsetLeft);
		this.pointTopLeft.y = Math.min(this.startPoint.y, event.clientY - offsetTop);

		this.element.style.left = this.pointTopLeft.x + 'px';
		this.element.style.top = this.pointTopLeft.y + 'px';
		this.element.style.width = (this.pointBottomRight.x - this.pointTopLeft.x) + 'px';
		this.element.style.height = (this.pointBottomRight.y - this.pointTopLeft.y) + 'px';

	};

	SelectionHelper.prototype.onSelectOver = function () {

		if (this.element.parentElement != null) {
			this.element.parentElement.removeChild(this.element);
		}
	};

	return SelectionHelper;

})();

export { SelectionHelper };