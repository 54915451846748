import * as THREE from 'three';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"
import { MainObjects } from "../common/MainObjects"
import { Loader } from "../loader/Loader"
import { IImage } from "../interface/IRes"
import { INetwork } from "../interface/INetwork"
import { MaterialFactory } from "../factory/MaterialFactory"
import { IMaterialPreview } from '../interface/IMaterialPreview';
import { MaterialObject } from '../object/MaterialObject';
import { Saver } from '../loader/Saver';
import { MMDToonMaterial } from '../shader/MMDToonMaterial';
import { MMDToonTwistMaterial } from '../shader/MMDToonTwistMaterial';

class PreviewResouse {
    //option:
    //{
    //  container
    //}
    constructor(option) {
        window.createImageBitmap = undefined;
        this.container = option.container;
        const aspect = this.container.clientWidth / this.container.clientHeight;
        this.scene = new THREE.Scene();
        this.scene.background = new THREE.Color(new THREE.Color(0.2745, 0.2745, 0.2745));
        this.camera = new THREE.PerspectiveCamera(40, aspect, 0.1, 100000);
        this.camera_render = new THREE.PerspectiveCamera(40, 1, 0.1, 100000);
        this.camera.position.set(1, 1, 1);
        this.controls = new OrbitControls(this.camera, this.container);
        this.enableDamping = true;
        this.enablePan = false;
        this.enableZoom = false;
        const dirLight = new THREE.DirectionalLight( 0xffffff, 0.5 );
        dirLight.color.setHSL( 0.1, 1, 0.95 );
        dirLight.position.set( - 1, 1.75, 1 );
        dirLight.position.multiplyScalar( 30 );
        this.scene.add(dirLight);

        const hemiLight = new THREE.HemisphereLight( 0xffffff, 0xffffff, 0.2 );
        hemiLight.color.setHSL( 0.6, 1, 0.6 );
        hemiLight.groundColor.setHSL( 0.095, 1, 0.75 );
        hemiLight.position.set( 0, 50, 0 );
        this.scene.add( hemiLight );
        // const axesHelper = new THREE.AxesHelper( 10 );
        // this.scene.add( axesHelper );
        this.renderer = new THREE.WebGLRenderer({ antialias: true });
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setSize(this.container.clientWidth, this.container.clientHeight);
        this.renderer.toneMapping = THREE.CineonToneMapping;
        this.renderer.toneMappingExposure = 1;
        this.renderer.outputEncoding = THREE.sRGBEncoding;
        //rt
        this.width = 256;
        this.height = 256;
        this.start_renderTarget = false;
        this.renderTarget = new THREE.WebGLRenderTarget(this.width, this.height, { encoding: THREE.sRGBEncoding });
        this.buffer = new Uint8Array(4 * this.width * this.height);
        this.canvas = document.createElement("canvas");
        this.canvas.width = this.width;
        this.canvas.height = this.height;
        this.ctx = this.canvas.getContext('2d');
        this.blob = null;
        this.m_loadCallback = null;
        //res
        this.textureHdr = null;
        this.gltf = null;
        this.object = null;
        this.object_data = null;
        const p_geometry = new THREE.PlaneGeometry(10, 10, 10);
        const p_material = new THREE.MeshBasicMaterial();
        p_material.side = THREE.DoubleSide;
        this.cube = new THREE.Mesh(p_geometry, p_material);

        const sp_geometry = new THREE.SphereGeometry(10, 32, 16);
        this.sphere = new THREE.Mesh(sp_geometry, p_material);
        this.needupdate = true;
        //add
        this.container.appendChild(this.renderer.domElement);
        this.update();
    }

    renderToBuffer() {
        if (this.start_renderTarget) {
            var _this = this;
            this.start_renderTarget = false;
            var currentRenderTarget = this.renderer.getRenderTarget();
            this.renderer.setRenderTarget(this.renderTarget);
            this.renderer.render(this.scene, this.camera_render);
            this.renderer.readRenderTargetPixels(this.renderTarget, 0, 0, this.width, this.height, this.buffer);
            this.clamped = new Uint8ClampedArray(this.buffer.buffer);
            var imageData = new ImageData(this.clamped, this.width, this.height);
            var newImageData = this.ctx.getImageData(0, 0, this.width, this.height);
            this.ctx.putImageData(this.imageRevert(imageData, newImageData), 0, 0);
            this.canvas.toBlob(function (blob) {
                _this.blob = blob;
                _this.m_loadCallback();
            }, "image/png");
            this.renderer.setRenderTarget(currentRenderTarget);
        }
    }

    imageRevert(srcData, dstData) {
        for (var i = 0, h = srcData.height; i < h; i++) {
            for (var j = 0, w = srcData.width; j < w; j++) {
                dstData.data[i * w * 4 + j * 4 + 0] =
                    srcData.data[(h - i) * w * 4 + j * 4 + 0];
                dstData.data[i * w * 4 + j * 4 + 1] =
                    srcData.data[(h - i) * w * 4 + j * 4 + 1];
                dstData.data[i * w * 4 + j * 4 + 2] =
                    srcData.data[(h - i) * w * 4 + j * 4 + 2];
                dstData.data[i * w * 4 + j * 4 + 3] =
                    srcData.data[(h - i) * w * 4 + j * 4 + 3];
            }
        }
        return dstData;
    }

    addRenderDom(option) {
        this.container = option.container;
        this.container.appendChild(this.renderer.domElement);
        this.resize();
        this.camera.position.set(0, 0, 20);
        this.controls = new OrbitControls(this.camera, this.container);
        this.needupdate = true;
    }

    load(files, ext, process, callback) {
        var _this = this;
        const url = Loader.loadFiles(files, ext, function (p) {
            process(p);
        }, function (res, extension) {

            if (extension.indexOf("gltf") != -1) {
                _this.camera.position.set(20, 10, 20);
                _this.controls.update();
                _this.loadGltf(res, callback);
            }
            else if (extension.indexOf("obj") != -1) {
                _this.camera.position.set(20, 10, 20);
                _this.controls.update();
                _this.loadObj(res, callback);
            }
            else if (extension.indexOf("png") != -1 || extension.indexOf("jpg") != -1 ||
                extension.indexOf("jpeg") != -1 || extension.indexOf("hdr") != -1 || extension.indexOf("exr") != -1
                || extension.indexOf("webp") != -1) {
                _this.camera.position.set(0, 0, 20);
                _this.controls.update();
                _this.loadTexture(res, callback)
            }
        });
        return url;
    }

    loadObj(obj, callback) {
        var _this = this;
        this.loadHdr(function () {
            _this.scene.add(obj);
            _this.gltf = obj;
            var array = new Array();
            obj.traverse(function (child) {
                if (child.isMesh) {
                    array.push(child);
                    child.frustumCulled = false;
                    child.castShadow = true;
                }
            });
            _this.loadFinish(array, 1.5, callback);
        });
    }

    loadGltf(gltf, callback) {
        var _this = this;
        this.loadHdr(function () {
            _this.scene.add(gltf.scene);
            _this.gltf = gltf.scene;
            var array = new Array();
            gltf.scene.traverse(function (child) {
                if (child.isMesh) {
                    array.push(child);
                    child.frustumCulled = false;
                    child.castShadow = true;
                }
            });
            _this.loadFinish(array, 1.5, callback);
        });
    }

    loadTexture(texture, callback) {
        this.cube.material.map = texture;
        if(texture != null)
        {
            this.cube.material.map.encoding = THREE.sRGBEncoding;
        }
        var w = texture.image.width;
        var h = texture.image.height;
        var radio = h / w;
        this.cube.scale.set(1.0, radio, 1.0);
        this.scene.add(this.cube);
        this.loadFinish([this.cube], 1.5, callback);
    }

    loadFinish(array, size, callback) {
        MainObjects.Blueprint.m_cameraListenerBp.zoomCameraPerspectiveToSelection(this.camera, this.controls, array, size, false);

        this.camera_render.position.copy(this.camera.position);
        this.camera_render.rotation.copy(this.camera.rotation);
        this.camera_render.scale.copy(this.camera.scale);
        this.camera_render.updateMatrixWorld();
        this.camera_render.updateProjectionMatrix();
        this.start_renderTarget = true;
        this.m_loadCallback = callback;
    }

    loadHdr(callback) {
        var _this = this;
        if (_this.textureHdr == null) {
            this.hdr = INetwork.getUrl(IImage.hdr);
            const manager = new THREE.LoadingManager();
            Loader.loadFile(this.hdr, "hdr", manager, function (texture, extension) {
                _this.scene.environment = texture;
                _this.textureHdr = texture;
                callback();
            });
        }
        else {
            callback();
        }
    }

    close() {
        if (this.gltf != null) {
            this.gltf.traverse(function (child) {
                if (child.isMesh) {
                    child.material.dispose();
                    child.geometry.dispose();
                }
            });
            this.gltf.removeFromParent();
        }
        this.cube.removeFromParent();
        this.sphere.removeFromParent();
        this.sphere.material.dispose();
        if (this.object != null) {
            this.object.removeFromParent();
            if (this.object.userData != null && this.object.userData.m_gameObjectHelper != null) {
                this.object.userData.m_gameObjectHelper.removeFromParent();
            }
            this.object_data = null;
        }
        this.needupdate = false;
    }

    update() {
        requestAnimationFrame(this.update.bind(this));
        if (this.needupdate) {
            this.renderer.render(this.scene, this.camera);
            this.renderToBuffer();
        }
    }

    resize() {
        this.camera.aspect = this.container.clientWidth / this.container.clientHeight;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize(this.container.clientWidth, this.container.clientHeight);
    }

    loadGroup(object, callback) {
        var _this = this;
        this.object_data = MainObjects.Blueprint.m_sceneManagerBp.getObjectJsonInfo(object);
        var array = new Array();
        var manager = new THREE.LoadingManager();
        manager.onProgress = function (url, itemsLoaded, itemsTotal) {
            processCallback(itemsLoaded / itemsTotal);
        };

        manager.onError = function (url) {
            console.log('There was an error loading ' + url);
        };

        manager.onLoad = function () {
          
            _this.camera.position.set(20, 10, 20);
            _this.object.position.set(0, 0, 0);
            _this.controls.update();
            setTimeout(function () {
                array = [];
                array.push( _this.object);
                _this.object.traverse(function (child) {
                    if(child.isMesh)
                    {
                        array.push(child);
                    }
                })
                _this.loadFinish(array, 1.5, callback);
            }, 200);
        };

        this.loadHdr(function () {
            

            MainObjects.Blueprint.m_sceneManagerBp.getObjectFromJson(_this.object_data, function (object_copy) {
                _this.object = object_copy;
                manager.onLoad();
            }, true, manager, _this.scene);
        });
    }

    loadMaterial(json, callback, type = MaterialObject.MaterialType.e_STANDARD) {
        var _this = this;
        this.loadHdr(function () {
            if (json != null) {
                type = json.type;
            }
            if (type == MaterialObject.MaterialType.e_STANDARD) {
                var mat = new THREE.MeshStandardMaterial();
                var material = MaterialFactory.createStandardMaterial(mat, MaterialObject.MaterialEditorType.e_NET);
                _this.sphere.material = material;
            }
            else if (type == MaterialObject.MaterialType.e_BASIC) {
                var mat = new THREE.MeshBasicMaterial();
                var material = MaterialFactory.createBasicMaterial(mat, MaterialObject.MaterialEditorType.e_NET);
                _this.sphere.material = material;
            }
            else if (type == MaterialObject.MaterialType.e_PHONE) {
                var mat = new THREE.MeshPhongMaterial();
                var material = MaterialFactory.createPhoneMaterial(mat, MaterialObject.MaterialEditorType.e_NET);
                _this.sphere.material = material;
            }else if (type == MaterialObject.MaterialType.e_TOON_PHONE) {
                var mat = new MMDToonMaterial();
                var material = MaterialFactory.createToonPhoneMaterial(mat, MaterialObject.MaterialEditorType.e_NET);
                _this.sphere.material = material;  
            }else if (type == MaterialObject.MaterialType.e_TOON_PHONE_TWIST) {
                var mat = new MMDToonTwistMaterial();
                var material = MaterialFactory.createToonPhoneTwistMaterial(mat, MaterialObject.MaterialEditorType.e_NET);
                _this.sphere.material = material;  
            }


            if (json != null) {

                var manager = new THREE.LoadingManager();
                manager.onProgress = function (url, itemsLoaded, itemsTotal) {

                };

                manager.onError = function (url) {
                    console.log('There was an error loading ' + url);
                };

                manager.onLoad = function () {
                    console.log('Loading complete!');
                    MainObjects.Blueprint.m_materialEditorBp.getMaterialFromJson(_this.sphere, json);
                    _this.scene.add(_this.sphere);
                    if (IMaterialPreview.f_getMaterialCallback != null) {
                        IMaterialPreview.f_getMaterialCallback();
                    }
                    _this.loadFinish([_this.sphere], 1.5, callback);
                };
                Saver.m_resMap.clear();
                Saver.getMaterialFromJson(json);
                if (Saver.m_resMap.size > 0) {

                    
                    Saver.loadFileToScene(manager);
                }
                else {
                    manager.onLoad();
                }   
            }
            else
            {
                _this.scene.add(_this.sphere);
                if (IMaterialPreview.f_getMaterialCallback != null) {
                    IMaterialPreview.f_getMaterialCallback();
                }
                _this.loadFinish([_this.sphere], 1.5, callback);
            }

        });


    }
}

export { PreviewResouse };