<template>
  <div class="property-container">
    <GeneratorEditor name="Start Life"
                     :allowedType="valueFunctionTypes"
                     :value="particleSystem?.startLife"
                     :onChange="onChangeStartLife"/>
    <GeneratorEditor name="Start Size"
                     :allowedType="valueFunctionTypes"
                     :value="particleSystem?.startSize"
                     :onChange="onChangeStartSize"/>
    <GeneratorEditor name="Start Speed"
                     :allowedType="valueFunctionTypes"
                     :value="particleSystem?.startSpeed"
                     :onChange="onChangeStartSpeed"/>
    <GeneratorEditor name="Start Color"
                     :allowedType="colorValueFunctionTypes"
                     :value="particleSystem?.startColor"
                     :onChange="onChangeStartColor"/>
    <div v-if="particleSystem?.renderMode === RenderMode.Mesh">
      <GeneratorEditor name="Start Rotation"
                       :allowedType="rotationFunctionTypes"
                       :value="particleSystem?.startRotation"
                       :onChange="onChangeStartRotation"/>
    </div>
    <div v-else>
      <GeneratorEditor name="Start Rotation"
                       :allowedType="valueFunctionTypes"
                       :value="particleSystem?.startRotation"
                       :onChange="onChangeStartRotation"/>
    </div>
    <div v-if="particleSystem?.renderMode === RenderMode.Trail">
      <GeneratorEditor name="Start Length"
                       :allowedType="valueFunctionTypes"
                       :value="(particleSystem?.rendererEmitterSettings)?.startLength"
                       :onChange="onChangeStartLength"/>
      <div class="property">
        <div>{{ t('58edb') }}</div>
        <el-select @change="onChangeFollowLocalOrigin"
                     :value="(particleSystem?.rendererEmitterSettings)?.followLocalOrigin">
          <el-option
              v-for="item in [{value:true,label: t('true')}, {value:false,label: t('false')}]"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>
    </div>
  </div>
</template>

<script>
import {RenderMode} from "three.quarks";
import {t} from "../../util/lang";
import GeneratorEditor from "@/views/ParticleSystem/Editors/GeneratorEditor.vue";


export default {
  props: {
    particleSystem: Object,
    updateProperties: Function,
  },
  components: {
    GeneratorEditor,
  },
  computed: {
    RenderMode() {
      return RenderMode
    },
  },
  data() {
    const valueFunctionTypes= ['value', 'valueFunc'];
    const rotationFunctionTypes = ['rotationFunc'];
    const colorValueFunctionTypes = ['color', 'colorFunc'];
    return {
      valueFunctionTypes,
      rotationFunctionTypes,
      colorValueFunctionTypes
    }
  },
  methods: {
    t,
      onChangeStartSpeed(g) {
        this.$props.particleSystem.startSpeed = g;
        this.$props.updateProperties();
      },

      onChangeStartLife(g) {
        this.$props.particleSystem.startLife = g;
        this.$props.updateProperties();
      },

      onChangeStartSize(g) {
        this.$props.particleSystem.startSize = g;
        this.$props.updateProperties();
      },

      onChangeStartColor(g) {
        this.$props.particleSystem.startColor = g;
        this.$props.updateProperties();
      },

      onChangeStartRotation(g) {
        this.$props.particleSystem.startRotation = g;
        this.$props.updateProperties();
      },

      onChangeStartLength(g) {
        this.$props.particleSystem.rendererEmitterSettings.startLength = g;
        this.$props.updateProperties();
      },

      onChangeFollowLocalOrigin(value) {
        this.$props.particleSystem.rendererEmitterSettings.followLocalOrigin = value;
        this.$props.updateProperties();
      },
  }
  // setup(props) {
  //   {t} = useTranslation();
  //   valueFunctionTypes = ['value', 'valueFunc'];
  //   rotationFunctionTypes = ['rotationFunc'];
  //   colorValueFunctionTypes = ['color', 'colorFunc'];
  //
  //   onChangeStartSpeed(g) {
  //     props.particleSystem.startSpeed = g;
  //     props.updateProperties();
  //   };
  //
  //   onChangeStartLife(g) {
  //     props.particleSystem.startLife = g;
  //     props.updateProperties();
  //   };
  //
  //   onChangeStartSize(g) {
  //     props.particleSystem.startSize = g;
  //     props.updateProperties();
  //   };
  //
  //   onChangeStartColor(g) {
  //     props.particleSystem.startColor = g;
  //     props.updateProperties();
  //   };
  //
  //   onChangeStartRotation(g) {
  //     props.particleSystem.startRotation = g;
  //     props.updateProperties();
  //   };
  //
  //   onChangeStartLength(g) {
  //     props.particleSystem.rendererEmitterSettings.startLength = g;
  //     props.updateProperties();
  //   };
  //
  //   onChangeFollowLocalOrigin = (value) => {
  //     props.particleSystem.rendererEmitterSettings.followLocalOrigin = value;
  //     props.updateProperties();
  //   };
  //
  //
  // }
}
</script>