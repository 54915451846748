class MutilTextBehavior {
    constructor() {
        this.m_mutilTextArray = new Array();
    }

    setText(i, text)
    {
        this.m_mutilTextArray[i] = text;
    }
    getJson()
    {
        return this.m_mutilTextArray;
    }
    
    setJson(json)
    {
        this.m_mutilTextArray = json;
    }

    init(object){

    }
    destory()
    {
 
    }
}

export { MutilTextBehavior };
