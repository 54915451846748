var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{directives:[{name:"dragFixed",rawName:"v-dragFixed"}],class:{
    EditorBox:_vm.upgrading==2,
    createEditorBox: _vm.upgrading==1
    
    },on:{"click":_vm.myClick}},[_c('div',{staticClass:"controlDrag",attrs:{"id":"controlDrag"}}),_c('div',{class:{
    left:_vm.upgrading==2,
    createLeft: _vm.upgrading==1
    }},[_c('PreviewEditor',{attrs:{"files":_vm.files,"fileUrl":_vm.fileUrl,"ext":_vm.ext,"upDataId":_vm.upDataId,"upgrading":_vm.upgrading,"FileDirectoryId":_vm.FileDirectoryId,"ResourceType":_vm.ResourceType,"upDataNode":_vm.upDataNode,"sid":_vm.childSia},on:{"transfer":_vm.getPreviewShow,"setFileDirectoryId":_vm.setFileDirectoryId}})],1),(_vm.upgrading==2)?_c('div',{class:{
              right: true,
              redactRight: _vm.upgrading==2,
             
            }},[_c('PreviewMateria',{on:{"passVaule":_vm.passValue}})],1):_vm._e(),_c('div',{staticClass:"close",on:{"click":_vm.closeChange}},[_c('img',{attrs:{"src":require("../../assets//Project/close.png"),"alt":""}})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }