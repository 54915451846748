class Language {
    static Language =
        {
            China: 0,
            English: 1,
        }
    static m_language = Language.Language.China;
    static LOCAL_TEXT(key, world) {
        switch (Language.m_language) {
            case Language.Language.China:
                return world;
            default:
                return key;
        }
    }
}

export { Language };