
import { OssManager } from "../../../../threejs/network/OssManager";
import { MainObjects } from "../../../../threejs/common/MainObjects";
import { INetwork } from "../../../../threejs/interface/INetwork";

class ColoudSoresINetwork {
   static uploadJsonToOss(coloudSoresId, json, name, finishCallback) {
      var oss = new OssManager(MainObjects.Network.m_bucketName);
      oss.f_finishCallback = finishCallback;
      var srcStoreAs = oss.getCloudShopJson(coloudSoresId) + name + ".json";
      console.log(srcStoreAs,"json地址");
      var dir = oss.getCloudShopJson(coloudSoresId);
      var max = 20;
      INetwork.getFileList(dir, function (objects) {


         oss.uploadJson(srcStoreAs, json);

      });
   }

   static downloadJsonToOss(coloudSoresId, name, finishCallback) {
      console.log(coloudSoresId,name,"srcStoreAs");

      var oss = new OssManager(MainObjects.Network.m_bucketName);

      var srcStoreAs = oss.getCloudShopJson(coloudSoresId) + name + ".json";
      var dir = oss.getCloudShopJson(coloudSoresId);
      INetwork.downloadJson(srcStoreAs, function (data) {

         finishCallback(data)
      })

   }


}
export { ColoudSoresINetwork };
