import { CameraData } from "./CameraData";

import { ModelData } from "./ModelData";
import { CubeData } from "./factory/CubeData";

import { Dexie } from 'dexie'
import { ColoudSoresINetwork } from "./ColoudSoresINetwork"
import { ILoader, ICameraControls, IScene } from "yi_creator_runtime"

import {IGeometryFix} from 'yi_creator_runtime/build/runtime/Interface/IGeometryFix'
import * as BufferGeometryUtils from 'three/examples/jsm/utils/BufferGeometryUtils';
import * as THREE from 'three';

class ICloudSores {
    static m_cloudSores = null;

    //TODO: 
    //ICloudSores.m_cloudSores = new ICloudSores();

    //ICloudSores.m_cloudSores.init()

    //init 里面 加载从数据库
    //加载IRender.init()


    constructor() {
        //当前选中的相机索引
        this.m_currentIndex = 0;
        //全景相机LIST
        this.m_cameraDataList = [];
        //模型LIST 合并cube和modle
        this.m_modelDataList = new Array();
        //投影模型
        this.m_projectMesh = null
        //CubeList
        this.m_CubeList = []
        this.m_cloudSoresId = "";
        this.m_sqlId = "",
            //TODO 这个不要
            // this.m_geometryArray=[]
            this.m_cloudSoresDb = new Dexie("ColoudSoresManager");
        this.m_cloudSoresDb.version(2).stores({
            //TODO 小写
            coloudSores: "id,value",
            camera: "id,value",
            model: "id,value",
        });
        //TODO 这个不要
        this.m_tempCubeArry = [];
        //所有的变量都从这里设置，不要到处都是
        // 判斷渲染更新
        this.m_poc = null
        // 初始相机的index
        this.m_initialCameraIndex=null
        this.m_backgroundUrl = "" 
        this.m_angleValue=1
        this.m_distance=1,
        // 地址经纬度
        this.m_addressPos=[]
        this.m_commodityNumber=0
        this.m_furnitureNumber=0

        // 地图经纬度
        this.pis= [116.397128,39.916527]
    }
    init(id, callback) {
        let _this = this
  

        this.getColoudSoresInDb(id, (data) => {


            _this.m_cloudSoresId = data.cloudSoresDbId

            let cameraList = JSON.parse(data.cameraList)
            let modelLis = JSON.parse(data.modelLis)
            let CubeArr = JSON.parse(data.CubeArr)


            this.pis=data.pis

            console.log(data,"data.pis");
            this.m_addressPos=data.m_addressPos
            

            
            console.log(CubeArr,"CubeArr");

            if (data.m_initialCameraIndex) {
                this.m_initialCameraIndex=data.m_initialCameraIndex
                
            }
            // console.log(data.m_backgroundUrl,"data.m_backgroundUrl");
            if (data.m_backgroundUrl) {
                this.m_backgroundUrl=data.m_backgroundUrl
                
            }
            
            console.log(modelLis,"modelLis");
            _this.setCameraDataList(cameraList, modelLis)
            _this.m_tempCubeArry = CubeArr
            console.log(_this.m_tempCubeArry, "_this.m_tempCubeArry");

            _this.m_angleValue=data.m_angleValue

            _this.m_distance = data.m_distance

            callback()
        })
        // this.m_cloudSoresId = id
    }

    destroyedCloydSores() {
        this.m_cloudSoresDb.delete(this.m_cloudSoresId)
        this.m_cloudSoresDb.camera.delete(this.m_cloudSoresId)
        this.m_cloudSoresDb.model.delete(this.m_cloudSoresId)
    }

    setCameraDataList(cameraList3D, modelLis3D) {
        for (var data of cameraList3D) {
            var cameraData = new CameraData();
            cameraData.m_id = data.m_id;
            cameraData.m_name = data.m_name;
            cameraData.m_FOV = data.m_FOV;
            cameraData.m_Far = data.m_Far;
            cameraData.m_Near = data.m_Near;

            cameraData.m_vertical = data.m_vertical;
            cameraData.m_horizontal = data.m_horizontal;
            cameraData.m_initialCamera = data.m_initialCamera;
            cameraData.m_img = data.m_img
            cameraData.m_pos.x = data.m_pos.x
            cameraData.m_pos.y = data.m_pos.y
            cameraData.m_pos.z = data.m_pos.z
            
            this.m_cameraDataList.push(cameraData);
        }
        for (var data of modelLis3D) {

            var modelData = new ModelData();
            modelData.m_id = data.id;
            modelData.m_name = data.name;
            modelData.m_number = data.number;
            modelData.m_type = data.type;
            modelData.m_img = data.img;
            modelData.m_url = data.m_url;

            
            modelData.m_furnitureData = data.furnitureData;
            modelData.m_commodityData = data.commodityData;
            this.m_modelDataList.push(modelData);
        }



    }

    initProjectCameraImage(defaultCamera = null, index) {



        this.m_currentIndex = index;
        let _this = this
        let pointList = []

        let imgList = []
        let imgIndexList = []
        this.m_cameraDataList.forEach((element, index) => {
            let b = false;
            element.m_img.forEach(i => {
                if (i != "") {
                    imgList.push(i)
                    b = true;
                }
            })
            if (b) {
                imgIndexList.push(index);
            }

        });
        for (let i = 0; i < imgIndexList.length; i++) {
            let j = imgIndexList[i];
            let element = this.m_cameraDataList[j];
            let cameraObject = new THREE.Object3D()
            cameraObject.name = "point" + j
            if (element.m_initialCamera == true) {
                if (defaultCamera == null) {
                    defaultCamera = cameraObject.name
                }
            }
            cameraObject.position.set(element.m_pos.x, element.m_pos.y, element.m_pos.z)
            pointList.push(cameraObject)
        }

        if ((imgList.length == pointList.length * 6) && (imgList.length > 0)) {
            console.log(imgList,"imgList");

            ILoader.loadTextures(imgList, function (p) {

            }, function (map) {

                if (map.size > 0) {
                    let textureList = [];
                    imgList.forEach((element) => {
                        if (map.get(element)) {
                            if (map.get(element).length) {
                                var pop = map.get(element).pop();
                                if (pop) {
                                    textureList.push(pop);
                                }

                            }
                            else {
                                textureList.push(map.get(element))
                            }
                        }

                    })

                    if (textureList.length == pointList.length * 6) {
                        console.log(_this.m_projectMesh,"_this.m_projectMesh")
                        if ( _this.m_projectMesh) {
                            
                        var config = {
                            projectMesh: _this.m_projectMesh,
                            pointObjects: pointList,
                            textures: textureList,
                            closeTouch:true,
                        };

                        console.log(config,"config")

                        if (_this.m_poc) {
                            _this.m_poc.destory()
                        }

                        _this.m_poc = ICameraControls.createProjectObjectControl(config)
                        IScene.addToScene(_this.m_poc.m_sphere)
                   
                        if (_this.m_backgroundUrl) {
                            _this.m_poc.setUseSphere(true)

                            let texture = new THREE.TextureLoader().load(_this.m_backgroundUrl);
                            console.log(texture,"texture");

                            _this.m_poc.setSphereTexture(texture)


                        }



                        _this.m_poc.setStartMovePath(defaultCamera)
                        }

                    }else{
                        console.error("生成projectMesh失败")
                    }
                }

            })
        }
    }

    setCubeList(processCallback, callback) {

        var _this = this;
        var CubeList = this.m_tempCubeArry;

        let CubeDataList = []
        const material = new THREE.MeshBasicMaterial({
            color: 0x00ff00,
            fog: false,
            toneMapped: false,
        });
        var srcList = [];
        CubeList.forEach((item) => {
            // console.log(item, "item");
            let cube = new CubeData()
            cube.setData(item);
            srcList.push(item.m_url)
            CubeDataList.push(cube)
            console.log(cube, "cube");
        })
        this.m_CubeList = CubeDataList
        console.log(this.m_CubeList,"m_CubeList");

        var geoArry = [];
        ILoader.loadObject(srcList, processCallback, function (map) {


            for (let i = 0; i < CubeDataList.length; i++) {
                let cube = CubeDataList[i];
                let objectList = map.get(cube.m_url)
                if (objectList.length) {
                    cube.createGltf(objectList.pop());
                }
                else {
                    cube.createGltf(objectList);
                }


                cube.m_object.traverse(function (child) {
                    if (child.isMesh) {
                        var geo= IGeometryFix.fixGeometryIndexPositionUV(child.geometry);
                        let matrixWorldGeometry = geo.applyMatrix4(child.matrixWorld);
                        geoArry.push(matrixWorldGeometry);
                    }
                });

            }
            const mergedGeometries = BufferGeometryUtils.mergeBufferGeometries(geoArry, false);
            _this.m_projectMesh = new THREE.Mesh(mergedGeometries, material);
            // IScene.addToScene(_this.m_projectMesh )
            callback();
        });


    }

    createSphere(material) {
        const geometry = new THREE.SphereGeometry(200, 32, 16);
        geometry.scale(-1, 1, 1)
        const sphere = new THREE.Mesh(geometry, material);
        return sphere
    }

    save(callback) {
        var saveData = {};
        saveData.id = this.m_cloudSoresId
        var cameraDataJson = [];
        for (var data of this.m_cameraDataList) {
            let obj = {
                m_id: data.m_id,
                m_name: data.m_name,
                m_FOV: data.m_FOV,
                m_vertical: data.m_vertical,
                m_horizontal: data.m_horizontal,
                m_initialCamera: data.m_initialCamera,
                m_img: data.m_img,
                m_pos: {},
                m_Far:data.m_Far,
                m_Near:data.m_Near,

            }

            obj.m_pos.x = data.m_pos.x
            obj.m_pos.y = data.m_pos.y
            obj.m_pos.z = data.m_pos.z




            cameraDataJson.push(obj);
        }
        // cameraDataJson.push(JSON.stringify(saveData));
        cameraDataJson = JSON.stringify(cameraDataJson);
        saveData.cameraList = cameraDataJson;
        let CubeList = []

        this.m_CubeList.forEach((item) => {
            let obj = {
                size: item.size,
                m_id: item.m_id,
                pos: item.pos,
                rot: item.rot,
                m_url: item.m_url,

            }

            CubeList.push(obj)
        })


        saveData.CubeArr = JSON.stringify(CubeList);

        saveData.m_backgroundUrl= this.m_backgroundUrl


        // this.cacheCamera(this.id, cameraDataJson);
        var modelDataJson = [];
        // console.log(this.m_modelDataList,"modelDataJson");
        // 商品数量
        let commodityNumber=0
        // 家具数量
        let furnitureNumber=0

        for (var data of this.m_modelDataList) {
            if (data.m_type==1) {
                commodityNumber =commodityNumber+data.m_number
            }else if (data.m_type==2) {
                
                furnitureNumber =furnitureNumber+data.m_number
            }


            let obj = {
                id: data.m_id,
                name: data.m_name,
                number: data.m_number,
                m_url:data.m_url,
                type: data.m_type,
                img: data.m_img,
                furnitureData: data.m_furnitureData,
                commodityData: data.m_commodityData,
            }
            modelDataJson.push(obj);
        }
        this.m_commodityNumber=commodityNumber
        this.m_furnitureNumber=furnitureNumber


        modelDataJson = JSON.stringify(modelDataJson);

        saveData.modelLis = modelDataJson;
        saveData.m_initialCameraIndex=this.m_initialCameraIndex

        // this.cacheModel(this.m_cloudSoresId, modelDataJson)
        saveData.cloudSoresDbId = this.m_cloudSoresId

        saveData.m_angleValue=this.m_angleValue
        saveData.m_distance=this.m_distance

        saveData.m_commodityNumber=commodityNumber
        saveData.m_furnitureNumber=furnitureNumber
        saveData.pis=this.pis
        saveData = JSON.stringify(saveData);
        
        ColoudSoresINetwork.uploadJsonToOss(this.m_sqlId, saveData, this.m_sqlId, callback)
    }

    cacheCamera(id, json, callback) {
        this.m_cloudSoresDb.camera.add({ id: id, value: json }).then(function () {
            if (callback) {
                callback();

            }
        });
    }

    changeSqlId(id) {
        this.m_sqlId = id
    }

    cacheModel(id, json, callback) {
        this.m_cloudSoresDb.model.add({ id: id, value: json }).then(function () {
            if (callback) {
                callback();
            }
        });
    }

    async cacheColoudSores(id, json, callback) {
        await this.m_cloudSoresDb.coloudSores.add({ id: id, value: json }).then(function () {
            if (callback) {
                callback();
            }
        }).catch(async () => {

            await this.m_cloudSoresDb.coloudSores.put({ id: id, value: json }).then(function () {
                if (callback) {
                    callback();
                }
            });

        });
    }
    async getCameraInDb(callback) {

        var temp = await this.m_cloudSoresDb.camera.where('id').equals(Saver.m_tempSaveName).first();
        let cameraList = []
        if (temp) {
            temp = JSON.parse(temp);

            for (let data of temp) {
                cameraList.push(JSON.parse(data))
            }
        }
        callback(cameraList)
    }

    async getColoudSoresInDb(id, callback) {

        var temp = await this.m_cloudSoresDb.coloudSores.where('id').equals(id).first();

        if (temp) {
            let data = JSON.parse(temp.value);
            // for (let data of temp) {
            //     cameraList.push(JSON.parse(data))
            // }
            callback(data)

        } else {
            console.error("从数据库获取不到")
        }
    }

    async getModelInDb(callback) {
        var temp = await this.m_cloudSoresDb.model.where('id').equals(Saver.m_tempSaveName).first();
        let modelList = []
        if (temp) {
            temp = JSON.parse(temp);

            for (let data of temp) {
                modelList.push(JSON.parse(data))
            }
        }
        callback(modelList)
    }

    downloadData(coloudSoresId, name, callback) {
        let _this = this
        ColoudSoresINetwork.downloadJsonToOss(
            _this.m_sqlId,
            _this.m_sqlId,
            function (data) {
                console.log(data, "downloadData");

                let jsonData = JSON.stringify(data)

                // let cameraList = JSON.parse(jsonData.cameraDataJson)
                // let modelList = JSON.parse(jsonData.modelDataJson)
                // let cameraDataJson = []
                // let modelDataJson = []
                // cameraList.forEach(element => {
                //     cameraDataJson.push(JSON.parse(element))
                // });

                // modelList.forEach(element => {
                //     modelDataJson.push(JSON.parse(element))
                // });
                // let obj = {
                //     id: jsonData.id,
                //     modelList: modelDataJson,
                //     cameraList: cameraDataJson,
                //     CubeList: jsonData.CubeList
                // }
                _this.cacheColoudSores(data.cloudSoresDbId, jsonData, () => {

                    callback(data)
                })

            }
        )
    }

    getCameraDataList() {
        return this.m_cameraDataList;
    }
    getModelDataList() {
        return this.m_modelDataList;
    }

    getCameraData(id) {
        for (var data of this.m_cameraDataList) {
            if (data.id == id) {
                return data.getCameraData()
            }

        }

    }

    setCameraData(id, date) {
        for (var data of this.m_cameraDataList) {
            // console.log("id", date, id)
            data.m_FOV=date.m_FOV
            if (data.m_id == id) {

                data.setCameraData(date)
            }

        }
    }

    setInitialCamera(id) {
        for (var data of this.m_cameraDataList) {
           

            if (data.m_id == id) {

                 this.m_initialCameraIndex = this.m_cameraDataList.indexOf(data)
                data.m_initialCamera = true


            } else {
                data.m_initialCamera = false
            }

        }

    }

    setFurnitureData(id, data) {
        for (let item of this.m_modelDataList) {
            if (id == item.m_id) {
                item.setFurnitureData(data)
            }
        }
    }

    setCommodityData(id, data) {
        for (let item of this.m_modelDataList) {
            if (id == item.m_id) {
                item.setCommodityData(data)
            }
        }
    }

    clearMark(id) {
        for (let item of this.m_modelDataList) {
            if (id == item.m_id) {
                item.clearMark()
            }
        }
    }

    convertDataToSql() {
        let modelList = []
        // this.m_modelDataList.forEach((item) => {
        console.log(this.m_modelDataList, this.m_modelDataList.length, "this.m_modelDataList")



        for (let item of this.m_modelDataList) {




            let obj = {
                // id:item.m_url,
                // modelId: item.m_id,
                modelId: item.m_url,

                modelName: item.m_name,
                modelImg: item.m_img,
                modelNumber: item.m_number,
                modelType: item.m_type,

                m_url:item.m_url

            }
            console.log(item.m_type, "item.m_type==0");
            if (item.m_type == 0) {
                obj.showName = ""
                obj.len = ""
                obj.breadth = ""
                obj.altitude = ""
                obj.guidingPrice = ""
                obj.url = ""
                obj.showImg = ""
                obj.describe = ""
            } else if (item.m_type == 1) {
                obj.showName = item.m_commodityData.showName
                obj.len = ""
                obj.breadth = ""
                obj.altitude = ""
                obj.guidingPrice = item.m_commodityData.guidingPrice
                obj.url = item.m_commodityData.goToUrl
                obj.showImg = item.showImg
                obj.describe = item.m_commodityData.describe
            } else if (item.m_type == 2) {
                obj.showName = item.m_furnitureData.explicitName
                obj.len = item.m_furnitureData.len
                obj.breadth = item.m_furnitureData.breadth
                obj.altitude = item.m_furnitureData.altitude
                obj.guidingPrice = ""
                obj.url = ""
                obj.showImg = item.m_furnitureData.showImg
                obj.describe = item.m_furnitureData.describe
            }

            modelList.push(obj)
            // console.log(obj, "obj");
        }

        // })
        return modelList
    }

    convertSqlToData(modeList) {
        let modelDataList = []
        for (let item of modeList) {
            let modelData = new ModelData();
            modelData.m_id = item.modelId
            modelData.m_name = item.modelName
            modelData.m_img = item.modelImg
            modelData.m_number = item.modelNumber

            let furnitureData = {
                explicitName: "",
                len: "",
                breadth: "",
                altitude: "",
                describe: "",
                showImg: ""
            }
            let commodityData = {
                showName: "",
                guidingPrice: "",
                describe: "",
                showImg: "",
                goToUrl: "",
            }
            // 0 ： 无 1：商品 2：家具
            modelData.m_type = item.modelType

            if (item.modelType == 1) {

                commodityData.showName = item.showName
                commodityData.guidingPrice = item.guidingPrice
                commodityData.goToUrl = item.url
                commodityData.showImg = item.showImg
                commodityData.describe = item.describe
            } else if (item.modelType == 2) {
                furnitureData.explicitName = item.showName
                furnitureData.len = item.len
                furnitureData.breadth = item.breadth
                furnitureData.altitude = item.altitude
                furnitureData.showImg = item.showImg
                furnitureData.describe = item.describe
            }
            modelData.m_furnitureData = furnitureData

            modelData.m_commodityData = commodityData

            modelData.m_url=item.m_url

            modelDataList.push(modelData)
        }
        this.m_modelDataList = modelDataList
        console.log( this.m_modelDataList," this.m_modelDataList");
    }

}
export { ICloudSores };
