import {AdditiveBlending, DoubleSide, Group, MeshBasicMaterial, NormalBlending, Vector4} from "three";
import {
    Bezier,
    ColorOverLife, ColorRange, ConeEmitter,
    ConstantColor,
    ConstantValue,
    IntervalValue,
    ParticleSystem, PiecewiseBezier, PointEmitter, RandomColor,
    RenderMode, SizeOverLife,
    SphereEmitter
} from "three.quarks";


export function createShipTrail(textures) {
    const emitterGroup = new Group();
    const mat = new MeshBasicMaterial({map: textures, blending: AdditiveBlending, transparent: true, side: DoubleSide});

    emitterGroup.name = "ShipTrail";

    const beam = new ParticleSystem({
        duration: 1,
        looping: true,
        startLife: new ConstantValue(1.0),
        startSpeed: new ConstantValue(0),
        startSize: new ConstantValue(20.0),
        startColor: new ConstantColor(new Vector4(0.5220588 * 0.772549, 0.6440161 * 0.772549, 1 * 0.772549, 0.772549)),
        worldSpace: false,


        emissionOverTime: new ConstantValue(1),
        emissionBursts: [],
        shape: new PointEmitter(),
        material: mat,
        blending: AdditiveBlending,
        startTileIndex: new ConstantValue(1),
        uTileCount: 10,
        vTileCount: 10,
        renderOrder: 0,
    });
    beam.emitter.name = 'beam';
    emitterGroup.add(beam.emitter);

    // emitterGroup.add(beam.emitter);

    const glowBeam = new ParticleSystem({
        duration: 1,
        looping: true,
        startLife: new ConstantValue(0.5),
        startSpeed: new ConstantValue(0),
        startSize: new ConstantValue(10),
        startColor: new ConstantColor(new Vector4(0.5220588 * 0.772549, 0.6440161 * 0.772549, 1 * 0.772549, 0.772549)),
        worldSpace: true,


        emissionOverTime: new ConstantValue(120),

        shape: new SphereEmitter({
            radius: .0001,
            thickness: 1,
            arc: Math.PI * 2,
        }),
        material: mat,

        blending: AdditiveBlending,
        startTileIndex: new ConstantValue(1),
        uTileCount: 10,
        vTileCount: 10,
        renderOrder: 2,
    });
    glowBeam.addBehavior(new SizeOverLife(new PiecewiseBezier([[new Bezier(1, 1.0, 0.8, 0.5), 0]])));
    glowBeam.addBehavior(new ColorOverLife(new ColorRange(new Vector4(1, 1, 1, 1), new Vector4(0, 0, 0, 0))));
    glowBeam.emitter.name = 'glowBeam';

    emitterGroup.add(glowBeam.emitter);


    const particles = new ParticleSystem({
        duration: 1,
        looping: true,
        startLife: new IntervalValue(0.3, 0.6),
        startSpeed: new IntervalValue(20, 40),
        startSize: new IntervalValue(1, 2),
        startColor: new RandomColor(new Vector4(1, 1, 1, .5), new Vector4(0.5220588, 0.6440161, 1, 0.772549)),
        worldSpace: false,


        emissionOverTime: new ConstantValue(60),

        shape: new ConeEmitter({
            angle: 80 / 180 * Math.PI,
            radius: 1,
            thickness: 0.3,
            arc: Math.PI * 2,
        }),
        material: mat,

        renderMode: RenderMode.StretchedBillBoard,
        speedFactor: .2,
        blending: NormalBlending,
        startTileIndex: new ConstantValue(0),
        uTileCount: 10,
        vTileCount: 10,
        renderOrder: 0,
    });
    particles.addBehavior(new SizeOverLife(new PiecewiseBezier([[new Bezier(1, 0.25, 0.05, 0), 0]])));
    particles.emitter.rotateY(-Math.PI / 2);
    particles.emitter.name = 'particles';
    emitterGroup.add(particles.emitter);
    mat.dispose()

    // emitterGroup.userData = {
    //     script:
    //         "    this.position.x += delta * 200;\n" +
    //         "    if (this.position.x > 200)\n" +
    //         "        this.position.x = -200;\n"
    // };
    // emitterGroup.userData.func = new Function("delta", emitterGroup.userData.script);
    return emitterGroup;
}
