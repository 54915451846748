<template>
  <div class="burst-main">
    <div class="burst-header">
      <div class="header-left">{{ t('Burst') }} <i @click="handleExpandBurst" class="el-icon-caret-left" :class="active ? 'active-icon': ''"></i> </div>
      <el-button icon="el-icon-delete" @click="onDelete">
      </el-button>
    </div>
    <div class="params" :class=" active ? 'active': 'unActive'">
      <div v-for="varName in keys" :key="varName" class="property burst-property">
        <div class="name">{{ t(varName) }}</div>
        <NumberInput :value="params[varName]" :onChange="value => onChangeKeyValue(varName, value)" />
      </div>
    </div>

  </div>
</template>

<script>

import NumberInput from "@/views/component/NumberInput.vue";
import {t} from "../../../util/lang";
export default {
  name: 'BurstEmitterEditor',
  components: {
    NumberInput
  },
  props: {
    params: Object,
    updateProperties: Function,
    onDelete: Function
  },
  data() {
    const keys = ['time','count','cycle','interval','probability']

    return {
      keys,
      active: true
    }
  },
  methods: {
    t,
    onChangeKeyValue(k, v) {
      this.$props.params[k] = v;
      console.log(this.$props.params[k])
      this.$props.updateProperties();
    },
    handleExpandBurst() {
      console.log('AAAA')
      this.active = !this.active

    }
  },

};
</script>
<style lang="less">
.burst-main {
  padding-left: 50px;
  .burst-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .header-left {
      display: flex;
      align-items: center;
      .el-icon-caret-left {
        margin-left: 10px;
        transition: 0.5s;
        font-size: 25px;
      }
      .active-icon {
        transform: rotate(-90deg);
      }
    }
    .el-icon-delete {
      font-size: 25px;
    }

    button {
      border: 0px;
    }
  }
  .burst-property {
    padding-left: 0;
    margin-left: 0;
  }
  .params {
    transition: 0.5s;
    overflow: hidden;
    height: 250px;
  }
  .active {
    height: 250px;
    .burst-property {
      transition: 0.5s;
      transform: translateY(0);
    }
  }
  .unActive {
    height: 0px;
    .burst-property {
      transition: 0.5s;
      transform: translateY(-250px);
    }
  }
}

</style>
