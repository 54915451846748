<template>
    <div class="box" >
        <div class="content">
            <div class="title" @click="titleChange">
              <div class="icon">
                <img src="../../assets/Property/icon.png" alt="">
              </div>
              <p>属性</p>
            </div>
            <div class="tabTitle">
                <a :title="item.title" v-for="(item,index) in tabImgList" :key="index">
                  <div class="ImgList" @click="propertyChange(index)" :class="{active:currentType==index}">
                    <img :src="item.img" alt="">
                  </div>
                </a>
              </div>
            <div v-show="model" class="main">
              
              <div v-show="TransformState">
                <Transform ref="myChild"/>
              </div>
              <div v-show="MeshFilterState">
                 <MeshFilter/>
              </div>
              <div v-show="CollisionState">
                <Collision ref="CollChild" />
              </div>
              <div v-show="MaterialEditorState">
                <MaterialEditor ref="MeterChild" @passVaule="passValue"/>
              </div>
              <div v-show="SkeletaState">
                <SkeletalAnimation ref="SkeletChild"/>
              </div>
              <div v-show="settingState">
                <ObjectAttributes :object3d="object3d" ref="AreaChild"/>
              </div>
              <div v-show="phereLighState">
                <HemisphereLight ref="phereChild"/>
              </div>
              <div v-show="renderState">
                <RenderAttributes ref="renderChild"/>
              </div>
              <div v-show="button">
                <!-- <div class="button_box"> -->
                  <AddComponent ref="addChild"/>
                  <!-- <CapsuleCollider style="margin-top:1.6276vw"/> -->
                  <!-- <Terrain style="margin-top:1.6276vw"/> -->
                  <!-- <el-button class="button" type="primary">添加组件</el-button> -->
                <!-- </div> -->
              </div>
            </div>
            
        </div>
        <!-- <setEditor v-show="setEditor" ref="setEditorChild" /> -->
    </div>
</template>
<script>
import setEditor from "../layout/SetMaterialEditor"
import Transform from "../property/Transform";
import MeshFilter from "../property/MeshFilter";
import Collision from "../property/Collision";
import MaterialEditor from "../property/MaterialEditor";
import CameraSetting from "../manager/CameraSetting.vue";
import Settings from "../manager/Settings";
import ObjectAttributes from "../property/ObjectAttributes";
import HemisphereLight from "../property/HemisphereLight";
import AddComponent from "../property/AddComponent";
import CapsuleCollider from "../property/CapsuleCollider";
import Terrain from "../property/Terrain";
import SkeletalAnimation from "../property/SkeletalAnimation";
import RenderAttributes from "../property/RenderAttributes";

import NameImg from "../../assets/Property/Name.png";
import checkedName from "../../assets/Property/checkedName.png";
import HemisphereLightImg from "../../assets/Property/set.png";
import checkedHemisphereLight from "../../assets/Property/checkedSet.png";
import MeshFilterImg from "../../assets/Property/MeshFilter.png";
import checkedMeshFilter from "../../assets/Property/checkedMeshFilter.png";
import CollisionImg from "../../assets/Property/Collision.png";
import checkedCollision from "../../assets/Property/checkedCollision.png";
import MaterialEditorImg from "../../assets/Property/MaterialEditor.png";
import checkedMaterialEditor from "../../assets/Property/checkedMaterialEditor.png";
import moduleImg from "../../assets/Resource/icon_script.png";
import checkedModule from "../../assets/Resource/icon_script_scene.png";
import Param from "../../assets/Property/Param.png";
import checkedParam from "../../assets/Property/checkedParam.png";
import skeleton from "../../assets/Property/skeleton.png";
import checkedSkeleton from "../../assets/Property/checkedSkeleton.png";
import render from "../../assets/Property/render.png";
import checkedRender from "../../assets/Property/checkedRender.png";

import { ITransform } from "../../threejs/interface/ITransform";
export default {
    name:"Property",
    props: {
      PropertyHeight: Number,
      object3d: Object

    },

    data(){
        return{
            setEditor:false,
            childSia:'',//接受子组件的值
            currentType:0,
            model:true,
            button:false,
            TransformState:true,//变换
            MeshFilterState:false,//网格
            CollisionState:false,//碰撞器
            MaterialEditorState:false,//材质编辑
            settingState:false,//参数设置
            phereLighState:false,//光
            SkeletaState:false,//骨骼动画
            renderState:false,//渲染属性
            tabImgList:[
              {img:checkedName,title:'模型变换'},
              {img:Param,title:'属性设置'},
              {img:MaterialEditorImg,title:'材质编辑'},
              {img:skeleton,title:'骨骼动画'},
              {img:CollisionImg,title:'碰撞器'},
              {img:HemisphereLightImg,title:'设置'},
              {img:render,title:'渲染属性'},
              {img:moduleImg,title:'添加组件'},
            ]
        }
    },
    components:{
      Transform,MeshFilter,Collision,MaterialEditor,CameraSetting,Settings,ObjectAttributes,HemisphereLight,
      AddComponent,CapsuleCollider,Terrain,SkeletalAnimation,RenderAttributes,setEditor
    },
    created(){
    },
    mounted(){
    },
    methods:{
        titleChange(){
          // this.setEditor = !this.setEditor;
          // this.setEditor = !this.setEditor;
          // ITransform.f_selectTransformCallback =  this.$refs.setEditorChild.IMaterialPreviewChange;
        },
        passValue(data) {
          this.childSia = data;
          this.$emit('passVaule',this.childSia)
        },
        setDragShow(msg) {
          this.$parent.setDragShow(msg);
        },
        propertyChange(index){
          this.currentType = index;
          if(index == 0){
            ITransform.f_selectTransformCallback= this.$refs.myChild.selectTransformChange;
            ITransform.f_selectTransformCallback(true)
            this.tabImgList = [{img:checkedName,title:"模型变换"},{img:Param,title:"属性设置"},{img:MaterialEditorImg,title:"材质编辑"},{img:skeleton,title:"骨骼动画"},{img:CollisionImg,title:"碰撞器"},
                              {img:HemisphereLightImg,title:"设置"},{img:render,title:"渲染属性"},{img:moduleImg,title:"添加组件"}]
            this.TransformState = true;
            this.settingState = false;
            this.CollisionState = false;
            this.MaterialEditorState = false;
            this.button = false;
            this.phereLighState = false;
            this.SkeletaState = false;
            this.renderState = false;
          }else if(index == 1){
            ITransform.f_selectTransformCallback= this.$refs.AreaChild.IParameterChange;
            ITransform.f_selectTransformCallback(true)
            this.tabImgList = [{img:NameImg,title:"模型变换"},{img:checkedParam,title:"属性设置"},{img:MaterialEditorImg,title:"材质编辑"},{img:skeleton,title:"骨骼动画"},{img:CollisionImg,title:"碰撞器"},
                              {img:HemisphereLightImg,title:"设置"},{img:render,title:"渲染属性"},{img:moduleImg,title:"添加组件"}]
            this.settingState = true;
            this.CollisionState = false;
            this.MaterialEditorState = false;
            this.TransformState = false;
            this.button = false;
            this.phereLighState = false;
            this.SkeletaState = false;
            this.renderState = false;
          }else if(index == 2){
            this.tabImgList = [{img:NameImg,title:"模型变换"},{img:Param,title:"属性设置"},{img:checkedMaterialEditor,title:"材质编辑"},{img:skeleton,title:"骨骼动画"},{img:CollisionImg,title:"碰撞器"},
                              {img:HemisphereLightImg,title:"设置"},{img:render,title:"渲染属性"},{img:moduleImg,title:"添加组件"}]
            ITransform.f_selectTransformCallback= this.$refs.MeterChild.IMaterialChange;
            ITransform.f_selectTransformCallback(true)
            this.MaterialEditorState = true;
            this.CollisionState = false;
            this.settingState = false;
            this.TransformState = false;
            this.button = false;
            this.phereLighState = false;
            this.SkeletaState = false;
            this.renderState = false;
          }else if(index==3){
            this.tabImgList = [{img:NameImg,title:"模型变换"},{img:Param,title:"属性设置"},{img:MaterialEditorImg,title:"材质编辑"},{img:checkedSkeleton,title:"骨骼动画"},{img:CollisionImg,title:"碰撞器"},
                              {img:HemisphereLightImg,title:"设置"},{img:render,title:"渲染属性"},{img:moduleImg,title:"添加组件"}]
            ITransform.f_selectTransformCallback = this.$refs.SkeletChild.IAnimationChange;
            ITransform.f_selectTransformCallback(true)
            this.SkeletaState = true;
            this.CollisionState = false;
            this.MaterialEditorState = false;
            this.settingState = false;
            this.TransformState = false;
            this.button = false;
            this.phereLighState = false;
            this.renderState = false;
          }
          else if(index == 4){
            ITransform.f_selectTransformCallback = this.$refs.CollChild.getCollisionsList;
            ITransform.f_selectTransformCallback(true)
            this.tabImgList = [{img:NameImg,title:"模型变换"},{img:Param,title:"属性设置"},{img:MaterialEditorImg,title:"材质编辑"},{img:skeleton,title:"骨骼动画"},{img:checkedCollision,title:"碰撞器"},
                              {img:HemisphereLightImg,title:"设置"},{img:render,title:"渲染属性"},{img:moduleImg,title:"添加组件"}]
            this.CollisionState = true;
            this.MaterialEditorState = false;
            this.settingState = false;
            this.TransformState = false;
            this.button = false;
            this.phereLighState = false;
            this.SkeletaState = false;
            this.renderState = false;
          }else if(index == 5){
            this.tabImgList = [{img:NameImg,title:"模型变换"},{img:Param,title:"属性设置"},{img:MaterialEditorImg,title:"材质编辑"},{img:skeleton,title:"骨骼动画"},{img:CollisionImg,title:"碰撞器"},
                              {img:checkedHemisphereLight,title:"设置"},{img:render,title:"渲染属性"},{img:moduleImg,title:"添加组件"}]
            ITransform.f_selectTransformCallback= this.$refs.phereChild.heimisphereChange;
            ITransform.f_selectTransformCallback(true)
            this.MaterialEditorState = false;
            this.settingState = false;
            this.TransformState = false;
            this.CollisionState = false;
            this.button = false;
            this.phereLighState = true;
            this.SkeletaState = false;
            this.renderState = false;
          }else if(index == 6){
            ITransform.f_selectTransformCallback = this.$refs.renderChild.RenderValueChange;
            ITransform.f_selectTransformCallback(true)
            this.tabImgList = [{img:NameImg,title:"模型变换"},{img:Param,title:"属性设置"},{img:MaterialEditorImg,title:"材质编辑"},{img:skeleton,title:"骨骼动画"},{img:CollisionImg,title:"碰撞器"},
                              {img:HemisphereLightImg,title:"设置"},{img:checkedRender,title:"渲染属性"},{img:moduleImg,title:"添加组件"}]
            this.renderState = true;
            this.phereLighState = false;
            this.settingState = false;
            this.button =false;
            this.MaterialEditorState = false;
            this.TransformState = false;
            this.CollisionState = false;
            this.SkeletaState = false;
          }else if(index == 7){
            ITransform.f_selectTransformCallback = this.$refs.addChild.IBehaviorData;
            ITransform.f_selectTransformCallback(true)
            this.tabImgList = [{img:NameImg,title:"模型变换"},{img:Param,title:"属性设置"},{img:MaterialEditorImg,title:"材质编辑"},{img:skeleton,title:"骨骼动画"},{img:CollisionImg,title:"碰撞器"},
                  {img:HemisphereLightImg,title:"设置"},{img:render,title:"渲染属性"},{img:checkedModule,title:"添加组件"}]
            this.renderState = false;
            this.phereLighState = false;
            this.settingState = false;
            this.button =true;
            this.MaterialEditorState = false;
            this.TransformState = false;
            this.CollisionState = false;
            this.SkeletaState = false;
          }
        }
    }
}
</script>
<style scoped>
.box{
  width: 100%;
  height: 100%;
  margin-top: 20px;
}
.content{
    width: 100%;
    height: 100%;
    background:#fff;
    border-radius: 5px;
    overflow-y: auto;
    position: relative;
    scrollbar-width: none;
}
a{
  text-decoration:none;
}
.main{
  width: 100%;
  height: calc(100% - 25px);
  overflow-y: auto;
  position: relative;
  scrollbar-width: none;
}
.button_box{
  margin-bottom: 20px;
}
.button_box::-webkit-scrollbar {
  display: none;  
}
.button_box{
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.button_box >>> .el-button--primary{
  background: #1673ff;
  margin-top: 25px;
}
.anmDiv{
transition: 1S;
}
.aaa{
width: 420px;
box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
transition: 1S;
}
.active{
  background: #fff;
}
/* 滚动条样式 */
.content::-webkit-scrollbar{
  display: none;
}
.main::-webkit-scrollbar {
  /*滚动条整体样式*/
  display: none;
  width : 10px;  
  height: 1px;
  }
.main::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background:#BEC2C9;
  }
.main::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  /* box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2); */
  border-radius: 10px;
  background:#eff3f4;
}
.title{
    width: 90%;
    height:25px;
    color: #000;
    font-weight: bold;
    font-size: 10px;
    margin: auto;
    display: flex;
    align-items: center;
}
.title .icon{
  width: 15px;
  height: 14px;
  margin-right: 10px;
  margin-left: 8px;
}
.icon img{
  width: 15px;
  height: 14px;
}
.el-button--primary{
  width: 40%;
  height: 35px;
  line-height: 0px;
  border-radius: 8px;
  margin-top: 10px;
  margin-bottom: 50px;
  margin-left: 170px;
}
.tabTitle{
  width: 40px;
  height:95%;
  position:absolute;
  left: 15px;
  top: 30px;
  background:rgba(247, 247, 247, 1);
  z-index: 999;
}
.ImgList{
  width: 38px;
  height: 45px;
  margin:2px auto;
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin-left: 2px;
}
.ImgList img{
  width: 15px;
  height: 15px;
}
.ImgList:hover{
  background: #cddfff;
}
</style>