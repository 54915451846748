<template>
    <div v-show="boxState">
        <div class="box" :class="isActive ? 'anmDiv' : 'aaa'" v-if="textureState">
            <div class="header">
                <div @click="shrinkChange"><i class="el-icon-arrow-down"></i> </div>
                <p>纹理控制器</p>
                <div class="del" @click="reduce()"><i class="el-icon-circle-close"></i> </div>
            </div>
            <div>
                <div class="loction">
                   <div class="title" style="width:3.58073vw; text-align: left;">平移开始</div>
                   <div class="x-axis axis">
                       <p>U</p>
                       <input v-model="m_u_start" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @input="changeNum($event)" placeholder="U开始"  @focus="Focus" @blur="Blur" />
                   </div>
                   <div class="y-axis axis" style="margin-left:0.65104vw">
                       <p>V</p>
                       <input v-model="m_v_start" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @input="changeNum($event)" placeholder="V开始"  @focus="Focus" @blur="Blur"/>
                   </div>
                </div>
                <div class="loction">
                   <div class="title" style="width:3.58073vw; text-align: left;">平移结束</div>
                   <div class="x-axis axis">
                       <p>U</p>
                       <input v-model="m_u_end" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @input="changeNum($event)" placeholder="U结束"  @focus="Focus" @blur="Blur" />
                   </div>
                   <div class="y-axis axis" style="margin-left:0.65104vw">
                       <p>V</p>
                       <input v-model="m_v_end" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @input="changeNum($event)" placeholder="V结束"  @focus="Focus" @blur="Blur"/>
                   </div>
                </div>
                <div class="projection">
                    <div class="title" style="width:3.58073vw; text-align: left;">旋&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;转</div>
                    <div class="txt_box">
                        <input class="input" type="text" @input="changeNum($event)" v-model="m_rot_start" @focus="Focus"
                            @blur="Blur" placeholder="开始">
                    </div>
                    <div class="txt_box" style="margin-left:1.30208vw">
                        <input class="input" type="text" @input="changeNum($event)" v-model="m_rot_end" @focus="Focus"
                            @blur="Blur" placeholder="结束">
                    </div>
                </div>
                <div class="shadow">
                    <div class="title" style="width:3.58073vw; text-align: left; margin-left: 1.04167vw;">悠&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;悠</div>
                    <div class="ipt_box">
                        <input class="input1" type="checkbox" @input="textureSelect($event)" v-model="textureModel" />
                    </div>
                </div>
                <div class="projection">
                    <div class="title" style="width:3.58073vw; text-align: left;">时&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;间</div>
                    <div class="txt_box">
                        <input class="input" type="text" @input="changeNum($event)" v-model="m_time" @focus="Focus"
                            @blur="Blur" placeholder="请输入时间">
                    </div>
                </div>
            </div>
        </div>
        <div class="box3" :class="isActive1 ? 'anmDiv' : 'aaa'" v-if="controllerState">
            <div class="header">
                <div @click="shrinkChange1"><i class="el-icon-arrow-down"></i> </div>
                <p>动态控制器</p>
                <div class="del" @click="reduce1()"><i class="el-icon-circle-close"></i> </div>
            </div>
            <div>
                <div class="loction1">
                   <div class="title">移动开始</div>
                   <div class="x-axis axis">
                       <p>X</p>
                       <input v-model="m_pos_startX" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @input="changeNum1($event)"  @focus="Focus" @blur="Blur" />
                   </div>
                   <div class="y-axis axis">
                       <p>Y</p>
                       <input v-model="m_pos_startY" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @input="changeNum1($event)"  @focus="Focus" @blur="Blur"/>
                   </div>
                   <div class="z-axis axis">
                       <p>Z</p>
                       <input v-model="m_pos_startZ" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @input="changeNum1($event)"  @focus="Focus" @blur="Blur"/>
                   </div>
                   <div class="reset" @click="pasteChange()"><img src="../../assets/Property/paste.png" alt=""> </div>
                </div>
                <div class="loction1">
                   <div class="title">移动结束</div>
                   <div class="x-axis axis">
                       <p>X</p>
                       <input v-model="m_pos_endX" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @input="changeNum1($event)"  @focus="Focus" @blur="Blur" />
                   </div>
                   <div class="y-axis axis">
                       <p>Y</p>
                       <input v-model="m_pos_endY" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @input="changeNum1($event)"  @focus="Focus" @blur="Blur"/>
                   </div>
                   <div class="z-axis axis">
                       <p>Z</p>
                       <input v-model="m_pos_endZ" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @input="changeNum1($event)"  @focus="Focus" @blur="Blur"/>
                   </div>
                   <div class="reset" @click="pasteChange1()"><img src="../../assets/Property/paste.png" alt=""> </div>
                </div>
                <div class="loction1">
                   <div class="title">旋转开始</div>
                   <div class="x-axis axis">
                       <p>X</p>
                       <input v-model="m_rot_startX" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @input="changeNum1($event)"  @focus="Focus" @blur="Blur" />
                   </div>
                   <div class="y-axis axis">
                       <p>Y</p>
                       <input v-model="m_rot_startY" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @input="changeNum1($event)"  @focus="Focus" @blur="Blur"/>
                   </div>
                   <div class="z-axis axis">
                       <p>Z</p>
                       <input v-model="m_rot_startZ" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @input="changeNum1($event)"  @focus="Focus" @blur="Blur"/>
                   </div>
                   <div class="reset" @click="pasteChange2()"><img src="../../assets/Property/paste.png" alt=""> </div>
                </div>
                <div class="loction1">
                   <div class="title">旋转结束</div>
                   <div class="x-axis axis">
                       <p>X</p>
                       <input v-model="m_rot_endX" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @input="changeNum1($event)"  @focus="Focus" @blur="Blur" />
                   </div>
                   <div class="y-axis axis">
                       <p>Y</p>
                       <input v-model="m_rot_endY" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @input="changeNum1($event)"  @focus="Focus" @blur="Blur"/>
                   </div>
                   <div class="z-axis axis">
                       <p>Z</p>
                       <input v-model="m_rot_endZ" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @input="changeNum1($event)"  @focus="Focus" @blur="Blur"/>
                   </div>
                   <div class="reset" @click="pasteChange3()"><img src="../../assets/Property/paste.png" alt=""> </div>
                </div>
                <div class="loction1">
                   <div class="title">缩放开始</div>
                   <div class="x-axis axis">
                       <p>X</p>
                       <input v-model="m_scale_startX" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @input="changeNum1($event)"  @focus="Focus" @blur="Blur" />
                   </div>
                   <div class="y-axis axis">
                       <p>Y</p>
                       <input v-model="m_scale_startY" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @input="changeNum1($event)"  @focus="Focus" @blur="Blur"/>
                   </div>
                   <div class="z-axis axis">
                       <p>Z</p>
                       <input v-model="m_scale_startZ" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @input="changeNum1($event)"  @focus="Focus" @blur="Blur"/>
                   </div>
                   <div class="reset" @click="pasteChange4()"><img src="../../assets/Property/paste.png" alt=""> </div>
                </div>
                <div class="loction1">
                   <div class="title">缩放结束</div>
                   <div class="x-axis axis">
                       <p>X</p>
                       <input v-model="m_scale_endX" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @input="changeNum1($event)"  @focus="Focus" @blur="Blur" />
                   </div>
                   <div class="y-axis axis">
                       <p>Y</p>
                       <input v-model="m_scale_endY" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @input="changeNum1($event)"  @focus="Focus" @blur="Blur"/>
                   </div>
                   <div class="z-axis axis">
                       <p>Z</p>
                       <input v-model="m_scale_endZ" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @input="changeNum1($event)"  @focus="Focus" @blur="Blur"/>
                   </div>
                   <div class="reset" @click="pasteChange5()"><img src="../../assets/Property/paste.png" alt=""> </div>
                </div>
                <div class="shadow">
                    <div class="title" style="width:3.58073vw; text-align: left; margin-left: 1.04167vw;">悠&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;悠</div>
                    <div class="ipt_box">
                        <input class="input1" type="checkbox" @input="DynamicModeSelect($event)" v-model="DynamicMode" />
                    </div>
                </div>
                <div class="projection">
                    <div class="title" style="width:3.58073vw; text-align: left;">时&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;间</div>
                    <div class="txt_box">
                        <input class="input" type="text" @input="changeNum1($event)" v-model="d_time"
                            @focus="Focus" @blur="Blur" placeholder="">
                    </div>
                </div>
              
            </div>
        </div>
        <div class="box2" :class="isActive2 ? 'anmDiv' : 'aaa'" v-if="targetState">
            <div class="header">
                <div @click="shrinkChange2"><i class="el-icon-arrow-down"></i> </div>
                <p>目标控制器</p>
                <div class="del" @click="reduce2()"><i class="el-icon-circle-close"></i> </div>
            </div>
            <div>
                <div class="projection">
                    <div class="title">距离目标高度</div>
                    <div class="txt_box">
                        <input class="input" type="text" @input="changeNum2($event)" v-model="heightTarget"
                            @focus="Focus" @blur="Blur" placeholder="">
                    </div>
                </div>
                <div class="projection">
                    <div class="title">观察目标高度</div>
                    <div class="txt_box">
                        <input class="input" type="text" @input="changeNum2($event)" v-model="observeTarget"
                            @focus="Focus" @blur="Blur" placeholder="">
                    </div>
                </div>
                <div class="projection">
                    <div class="title">旋转速度</div>
                    <div class="txt_box">
                        <input class="input" type="text" @input="changeNum2($event)" v-model="rotationalSpeed"
                            @focus="Focus" @blur="Blur" placeholder="">
                    </div>
                </div>
            </div>
        </div>
        <div class="box4" :class="isActive3 ? 'anmDiv' : 'aaa'" v-if="textState">
            <div class="header">
                <div @click="shrinkChange3"><i class="el-icon-arrow-down"></i> </div>
                <p>文本控制器</p>
                <div class="del" @click="reduce3()"><i class="el-icon-circle-close"></i> </div>
            </div>
            <div>
                <div class="textarea_box">
                    <el-input type="textarea" class="textarea" @input="textareaChnage0()" v-model="text0"
                         :show-word-limit="true" resize="none" @focus="Focus" @blur="Blur"></el-input>
                </div>
                <div class="textarea_box">
                    <el-input type="textarea" class="textarea" @input="textareaChnage1()" v-model="text1"
                         :show-word-limit="true" resize="none" @focus="Focus" @blur="Blur"></el-input>
                </div>
                <div class="textarea_box">
                    <el-input type="textarea" class="textarea" @input="textareaChnage2()" v-model="text2"
                         :show-word-limit="true" resize="none" @focus="Focus" @blur="Blur"></el-input>
                </div>
            </div>
        </div>
        <div class="box2" :class="isActive4 ? 'anmDiv' : 'aaa'" v-if="pathState">
            <div class="header">
                <div @click="shrinkChange4"><i class="el-icon-arrow-down"></i> </div>
                <p>曲线模拟器</p>
                <div class="del" @click="reduce4()"><i class="el-icon-circle-close"></i> </div>
            </div>
            <div>
                <div class="projection">
                    <div class="title" style="width:3.58073vw;">曲&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;线</div>
                    <div class="txt_box">
                        <el-select class="select" @change="selectChange()" v-model="select" placeholder="请选择">
                            <el-option
                                v-for="item in selectList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                </div>
               <div class="projection">
                    <div class="title" style="width:3.58073vw;">速&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;度</div>
                    <div class="txt_box">
                        <input class="input" type="number" @input="changeNum2($event)" v-model="speed"
                            @focus="Focus" @blur="Blur" placeholder="">
                    </div>
                </div>
                <div class="shadow">
                    <div class="title" style="width:3.58073vw; text-align: left; margin-left: 1.04167vw;">循&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;环</div>
                    <div class="txt_box">
                        <el-select class="select" @change="selectLoop()" v-model="bloop" placeholder="请选择">
                            <el-option
                                v-for="item in loopList"
                                :key="item.id"
                                :label="item.label"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="shadow">
                    <div class="title" style="width:3.58073vw; text-align: left; margin-left: 1.04167vw;">模&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;拟</div>
                    <div class="ipt_box">
                        <input class="input1" type="checkbox" @input="simChange($event)" v-model="sim" />
                    </div>
                </div>
                <div class="projection">
                    <div class="title">起&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;始</div>
                    <div class="slider">
                        <el-slider v-model="percent" @input="startChange()" :min="0" :max="100" :step="1" show-input :show-tooltip="false"></el-slider>
                    </div>
                </div>
            </div>
        </div>
        <div class="box4" :class="isActive5 ? 'anmDiv' : 'aaa'" v-if="hudState">
            <div class="header">
                <div @click="shrinkChange5"><i class="el-icon-arrow-down"></i> </div>
                <p>HUD</p>
                <div class="del" @click="reduce5()"><i class="el-icon-circle-close"></i> </div>
            </div>
            <div>
                <div class="loction1">
                   <div class="title" style="width:3.58073vw; text-align: left;">位&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;置</div>
                   <div class="x-axis axis">
                       <p>X</p>
                       <input v-model="positionX" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @input="changeNum0($event)"  @focus="Focus" @blur="Blur" />
                   </div>
                   <div class="y-axis axis">
                       <p>Y</p>
                       <input v-model="positionY" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @input="changeNum0($event)"  @focus="Focus" @blur="Blur"/>
                   </div>
                   <div class="z-axis axis">
                       <p>Z</p>
                       <input v-model="positionZ" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @input="changeNum0($event)"  @focus="Focus" @blur="Blur"/>
                   </div>
                </div>
               <div class="projection">
                    <div class="title" style="width:4.88281vw">尺&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;寸</div>
                    <div class="txt_box" style="margin-left:undefined">
                        <input class="input" type="number" @input="changeNum0($event)" v-model="size"
                            @focus="Focus" @blur="Blur" placeholder="">
                    </div>
                </div>
                <div class="color color1">
                    <div class="title">颜&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;色</div>
                    <el-color-picker v-model="Color" @change="ColorChange()" @active-change="ActiveColor" popper-class="picker"></el-color-picker>
                    <div class="import_color">
                        <input type="text" v-model="Color" @focus="Focus" @blur="Blur" @input="colorImport()">
                    </div>
                </div>
                <div class="textarea_box1">
                    <div class="title">文&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;本</div>
                    <el-input type="textarea" class="textarea" @input="hubChange()" v-model="hubText"
                        maxlength="100" :show-word-limit="true" resize="none" @focus="Focus" @blur="Blur"></el-input>
                </div>
            </div>
        </div>
        <div class="box5" :class="isActive6 ? 'anmDiv' : 'aaa'" v-if="NavMeshState">
            <div class="header">
                <div @click="shrinkChange6"><i class="el-icon-arrow-down"></i> </div>
                <p>AI寻路</p>
                <div class="del" @click="reduce6()"><i class="el-icon-circle-close"></i> </div>
            </div>
            <div>
                <div class="projection">
                    <div class="title">寻路资源url</div>
                    <!-- <div class="txt_box">
                        <input class="input" type="text" @input="AiNum($event)" v-model="m_navMeshPath"
                            @focus="Focus" @blur="Blur" placeholder="">
                    </div> -->
                    <el-input type="textarea" class="textarea" @input="AiNum()" v-model="m_navMeshPath"
                        :show-word-limit="true" resize="none" @focus="Focus" @blur="Blur"></el-input>
                </div>
                <div class="projection" style="margin-top:5.20833vw">
                    <div class="title">可移动最大速度</div>
                    <div class="txt_box">
                        <input class="input" type="number" @input="AiNum($event)" v-model="m_maxSpeed"
                            @focus="Focus" @blur="Blur" placeholder="">
                    </div>
                </div>
                <div class="projection">
                    <div class="title">可移动动力力量</div>
                    <div class="txt_box">
                        <input class="input" type="number" @input="AiNum($event)" v-model="m_maxForce"
                            @focus="Focus" @blur="Blur" placeholder="">
                    </div>
                </div>
                <div class="projection">
                    <div class="title">寻点距离</div>
                    <div class="txt_box">
                        <input class="input" type="number" @input="AiNum($event)" v-model="m_nextWaypointDistance"
                            @focus="Focus" @blur="Blur" placeholder="">
                    </div>
                </div>
                <div class="shadow">
                    <div class="title" style="text-align: left;margin-left: 1.04167vw;">动画开关</div>
                    <div class="ipt_box">
                        <input class="input1" type="checkbox" @input="animChange($event)" v-model="m_b_anim" />
                    </div>
                </div>
                <div class="projection">
                    <div class="title">动画骨骼节点</div>
                    <div class="txt_box">
                        <input class="input" type="text" @input="AiNum($event)" v-model="m_group_anim_name"
                            @focus="Focus" @blur="Blur" placeholder="">
                    </div>
                </div>
                <div class="projection">
                    <div class="title">待机动画</div>
                    <div class="txt_box">
                        <input class="input" type="text" @input="AiNum($event)" v-model="m_idle_anim"
                            @focus="Focus" @blur="Blur" placeholder="">
                    </div>
                </div>
                <div class="projection">
                    <div class="title">移动动画</div>
                    <div class="txt_box">
                        <input class="input" type="text" @input="AiNum($event)" v-model="m_run_anim"
                            @focus="Focus" @blur="Blur" placeholder="">
                    </div>
                </div>
                <div class="loction1">
                   <div class="title" style="width:3.58073vw; text-align: left;">偏&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;移</div>
                   <div class="x-axis axis">
                       <p>X</p>
                       <input v-model="offsetX" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @input="AiNum($event)"  @focus="Focus" @blur="Blur" />
                   </div>
                   <div class="y-axis axis">
                       <p>Y</p>
                       <input v-model="offsetY" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @input="AiNum($event)"  @focus="Focus" @blur="Blur"/>
                   </div>
                   <div class="z-axis axis">
                       <p>Z</p>
                       <input v-model="offsetZ" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @input="AiNum($event)"  @focus="Focus" @blur="Blur"/>
                   </div>
                </div>
                <div class="projection">
                    <div class="title">自定义数据</div>
                    <el-input type="textarea" class="textarea" @input="AiNum()" v-model="m_userData"
                        maxlength="100" :show-word-limit="true" resize="none" @focus="Focus" @blur="Blur"></el-input>
                </div>
            </div>
        </div>

        <div class="box6" :class="isActive7 ? 'anmDiv' : 'aaa'" v-if="mapState">
            <div class="header">
                <div @click="shrinkChange7"><i class="el-icon-arrow-down"></i> </div>
                <p>键值对脚本</p>
              &nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;
              <span @click.stop="handleBatchAddClick" style="cursor: pointer">Batch Add </span>
              <div class="del" @click="reduce7()">  <i class="el-icon-circle-close"></i> </div>
            </div>
            <div>
                <div class="projection" v-for="(item,index) in mapList" :key="index">
                    <div class="title" style="width:3.58073vw; text-align: left;" @dblclick="handleDoubleClick(item.value)">键值对</div>
                    <div class="txt_box">
                        <input class="input" type="text" @input="mapNum($event)" v-model="item.key" @focus="Focus"
                            @blur="Blur" placeholder="key">
                    </div>
                    <div class="txt_box" style="margin-left:1.30208vw">
                      <el-popover
                          placement="top-start"
                          title="😯"
                          v-if="item.value.includes('http')"
                          width="200"
                          trigger="hover"
                          >
                        <img style="width: 13.02083vw;object-fit: contain" :src="item.value.replace(/\?(.*)/,'')">
                        <input slot="reference" class="input" type="text" @input="mapNum($event)" v-model="item.value" @focus="Focus"
                               @blur="Blur" placeholder="value">
                      </el-popover>
                      <input v-if="!item.value.includes('http')" slot="reference" class="input" type="text" @input="mapNum($event)" v-model="item.value" @focus="Focus"
                             @blur="Blur" placeholder="value">
                    </div>
                </div>
            </div>
        </div>

        <div class="videoCover">
            <div class="add" @click.stop="tabState = !tabState"><i class="el-icon-circle-plus-outline"></i> </div>
            <!-- <div class="add" type="primary" @click="reduce()"><i class="el-icon-remove-outline"></i> </div> -->
            <div class="tab" v-show="tabState">
                <div class="list" @click.stop="tabChange(index)" v-for="(item, index) in list" :key="index"
                    :class="{ active: cur == index }">
                    <div class="img"><img :src="item.src" alt=""/></div>
                    <div class="text">{{ item.txt }} </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { ISetting } from "../../threejs/interface/ISetting";
import { IBehavior } from "../../threejs/interface/IBehavior";
import texture from "../../assets/Property/texture.png";
import checkedTex from "../../assets/Property/checkedTex.png";
import controller from "../../assets/Property/controller.png";
import checkedCon from "../../assets/Property/checkedCon.png";
import target from "../../assets/Property/target.png";
import checkedTar from "../../assets/Property/checkedTar.png"
import text from "../../assets/Property/text.png";
import checkedText from "../../assets/Property/checkedText.png"
import path from "../../assets/Property/path.png"
import checkedPath from "../../assets/Property/checkedPath.png"
import hud from "../../assets/Property/hud.png";
import CheckedHud from "../../assets/Property/checkedHud.png";
import Ai from "../../assets/Property/Ai.png";
import CheckedAi from "../../assets/Property/checkedAi.png";
import map from "../../assets/Property/map.png";
import CheckedMap from "../../assets/Property/checkedMap.png"
import {mergeMaps} from "../../util/common";
export default {
    name: "Video",
    data() {
        return {
            boxState:false,
            isActive: true,
            isActive1: true,
            isActive2: true,
            isActive3: true,
            isActive4:true,
            isActive5:true,
            isActive6:true,
            isActive7:true,
            value: 50,
            list: [
                {
                    txt:"纹理控制器",
                    src:texture,
                },
                {
                    txt:"动态控制器",
                    src:controller,
                },
                {
                    txt:"文本控制器",
                    src:text,
                },
                {
                    txt:"曲线模拟器",
                    src:path,
                },
                {
                    txt:"HUD",
                    src:hud,
                },
                {
                    txt:"AI寻路",
                    src:Ai,
                },
                {
                    txt:"键值对脚本",
                    src:map
                }
            ],
            currentType: 0,
            autorotation: false,
            textureState: false,
            controllerState: false,
            targetState: false,
            tabState: false,
            textState: false,
            pathState:false,
            hudState:false,
            NavMeshState:false,
            mapState:false,
            cur: -1,
            uSpeed: 0,//u速度
            vSpeed: 0,//v速度
            AutomaticSpeed: 0,//自动旋转速度
            ControlSpeed: 0,//控制旋转速度
            upRange: 0,//上角度范围
            downRange: 0,//下角度范围
            leftRange: 0,//左角度范围
            rightRange: 0,//右角度范围
            zoomRange: 0,//缩放范围
            zoomRange1: 0,//缩放范围
            heightTarget: 0,//距离目标高度
            observeTarget: 0,//观察目标高度
            rotationalSpeed: 0,//旋转速度
            text0: "",
            text1: "",
            text2: "",
            speed:"",//速度
            sim:false,//模拟
            selectList:[
                {
                    value:1,
                    id:"",
                    label:""
                },
            ],
            select:"",
            loopList:[
                {
                    value:0,
                    id:0,
                    label:"无"
                },
                {
                    value:1,
                    id:1,
                    label:"反复"
                },
                {
                    value:2,
                    id:2,
                    label:"循环"
                },
            ],
            textureModel:false,//悠悠
            DynamicMode:false,//悠悠
            bloop:0,
            percent:"",//起始
            positionX:"",
            positionY:"",
            positionZ:"",
            Color:"#FFFFFF",//颜色
            hubText:"",//hub文本
            size:"",//尺寸
            m_navMeshPath:"",//寻路资源url
            m_maxSpeed:0,//可以移动的最大速度
            m_maxForce:0,//可以移动动力力量
            m_nextWaypointDistance:"",//寻点距离
            m_idle_anim:"",//待机动画
            m_run_anim:"",//移动动画
            m_group_anim_name:"",//动画骨骼节点
            m_b_anim:false,//动画开关
            m_userData:"",//自定义数据
            //纹理控制器
            m_u_start:'',//U开始
            m_v_start:'',//V开始
            m_u_end:'',//U结束
            m_v_end:'',//V结束
            m_time:"",//时间
            m_rot_start:'',//旋转开始
            m_rot_end:'',//旋转结束
            //动态控制器
            m_pos_startX:"",//移动x
            m_pos_startY:"",//移动y
            m_pos_startZ:"",//移动z
            m_pos_endX:"",//移动EndX
            m_pos_endY:"",//移动EndY
            m_pos_endZ:"",//移动EndZ
            m_rot_startX:"",//旋转X
            m_rot_startY:"",//旋转Y
            m_rot_startZ:"",//旋转Z
            m_rot_endX:"",//旋转EndX
            m_rot_endY:"",//旋转EndY
            m_rot_endZ:"",//旋转EndZ
            m_scale_startX:"",//缩放X
            m_scale_startY:"",//缩放Y
            m_scale_startZ:"",//缩放Z
            m_scale_endX:"",//缩放EndX
            m_scale_endY:"",//缩放EndY
            m_scale_endZ:"",//缩放EndZ
            d_time:"",//时间
            offsetX:"",//偏移X
            offsetY:"",//偏移Y
            offsetZ:"",//偏移Z
            mapList:[
                {key:"",value:''},{key:"",value:''},{key:"",value:''},{key:"",value:''},{key:"",value:''},{key:"",value:''},{key:"",value:''},{key:"",value:''},{key:"",value:''},{key:"",value:''},
            ],
        }
    },
    mounted() {
        window.addEventListener("click", this.addChange);
    },
    computed:{
        storeData(){
            return this.$store.state.scriptData;
        }
    },
    watch:{
        async storeData(index){
            //曲线模拟器
            if( index != '' ){
                let list =  eval("("+ index +")");
                // console.log(list)
                if(list != ''){
                    if(list.scripType == "CurveBehavior"){
                        IBehavior.addBehavior(IBehavior.m_curveBehavior);
                        this.pathState = true;
                        this.speed = list.data.m_speed;
                        this.bloop = list.data.m_bloop;
                        this.sim = list.data.m_sim;
                        this.select = list.data.m_curve_name;
                        this.percent = list.data.m_percent;
                        const CurveBehaviorList = IBehavior.getCurveBehaviorList();
                        if(CurveBehaviorList != null){
                            // console.log("---",CurveBehaviorList)
                            this.selectList = CurveBehaviorList;
                        }
                    }
                    // else{
                    //     this.pathState = false;
                    // }
                    //纹理控制器
                    if(list.scripType == "TextureBehavior"){
                        IBehavior.addBehavior(IBehavior.m_textureBehavior);
                        this.textureState = true;
                        this.m_u_start = list.data.m_u_start;
                        this.m_v_start = list.data.m_v_start;
                        this.m_u_end = list.data.m_u_end;
                        this.m_v_end = list.data.m_v_end;
                        this.m_time = list.data.m_time;
                        this.m_rot_start = list.data.m_rot_start;
                        this.m_rot_end = list.data.m_rot_end;
                        this.textureModel = list.m_yoyo;
                        this.changeNum();
                    }
                    // else{
                    //     this.textureState = false;
                    // }
                    //AI寻路
                    if(list.scripType == "NavMeshBehavior"){
                        IBehavior.addBehavior(IBehavior.m_navMeshBehavior);
                        this.NavMeshState = true;
                        this.m_b_anim = list.data.m_b_anim;
                        this.m_group_anim_name = list.data.m_group_anim_name;
                        this.m_idle_anim = list.data.m_idle_anim;
                        this.m_maxForce = list.data.m_maxForce;
                        this.m_maxSpeed = list.data.m_maxSpeed;
                        this.m_navMeshPath = list.data.m_navMeshPath;
                        this.m_nextWaypointDistance = list.data.m_nextWaypointDistance;
                        this.m_run_anim = list.data.m_run_anim;
                        this.m_userData = list.data.m_userData;
                        this.offsetX = list.data.m_offset[0];
                        this.offsetY = list.data.m_offset[1];
                        this.offsetZ = list.data.m_offset[2];
                        this.AiNum();
                    }
                    // else{
                    //     this.NavMeshState = false;
                    // }
                    //HUD
                    if(list.scripType == "HudBehavior"){
                        IBehavior.addBehavior(IBehavior.m_hudBehavior);
                        this.hudState = true;
                        this.positionX = list.data.m_center.x;
                        this.positionY = list.data.m_center.y;
                        this.positionZ = list.data.m_center.z;
                        this.Color = list.data.m_color;
                        this.size = list.data.m_size;
                        this.hubText = list.data.m_text;
                        this.changeNum0();
                    }
                    // else{
                    //     this.hudState = false;
                    // }
                    //文本控制器
                    if(list.scripType == "MutilTextBehavior"){
                        IBehavior.addBehavior(IBehavior.m_mutilTextBehavior);
                        this.textState = true;
                        this.text0 = "";
                        this.text1 = "";
                        this.text2 = "";
                        this.text0 = list.data[0];
                        this.text1 = list.data[1];
                        this.text2 = list.data[2];
                    }
                    // else{
                    //     this.textState = false;
                    // }
                    //动态控制器
                    if(list.scripType == "DymaticBehavior"){
                        IBehavior.addBehavior(IBehavior.m_dymaticBehavior);
                        this.controllerState = true;
                        this.m_pos_startX = list.data.m_pos_start[0];
                        this.m_pos_startY = list.data.m_pos_start[1];
                        this.m_pos_startZ = list.data.m_pos_start[2];
                        this.m_pos_endX = list.data.m_pos_end[0];
                        this.m_pos_endY = list.data.m_pos_end[1];
                        this.m_pos_endZ = list.data.m_pos_end[2];
                        this.m_rot_startX = list.data.m_rot_start[0];
                        this.m_rot_startY = list.data.m_rot_start[1];
                        this.m_rot_startZ = list.data.m_rot_start[2];
                        this.m_rot_endX = list.data.m_rot_end[0];
                        this.m_rot_endY = list.data.m_rot_end[1];
                        this.m_rot_endZ = list.data.m_rot_end[2];
                        this.m_scale_startX = list.data.m_scale_start[0];
                        this.m_scale_startY = list.data.m_scale_start[1];
                        this.m_scale_startZ = list.data.m_scale_start[2];
                        this.m_scale_endX = list.data.m_scale_end[0];
                        this.m_scale_endY = list.data.m_scale_end[1];
                        this.m_scale_endZ = list.data.m_scale_end[2];
                        this.d_time = list.data.m_time;
                        this.DynamicMode = list.m_yoyo;
                        this.changeNum1();
                    }
                    // else{
                    //     this.controllerState = false;
                    // }
                    this.$store.commit("ScriptDataChange", '')
                }
            }
        }
    },
    methods: {
      handleDoubleClick(value) {
        window.open(value, '_blank')
      },
        handleBatchAddClick() {
          const json1 = localStorage.getItem('s2cTemp')
          const json2 = localStorage.getItem('thumb')
          // if(!json1 && !json2) {
          //   return
          // }
          let obj1 = {}, obj2 = {}
          json1 && Object.assign(obj1, JSON.parse(json1))
          json2 && Object.assign(obj2, JSON.parse(json2))
          Object.assign(obj1, obj2)
          const obj = obj1
          const getBehaviorList = IBehavior.getBehavior();

          let map = getBehaviorList.get(IBehavior.m_mapBehavior);
          let myMap = new Map();

          Object.entries(obj).forEach(item => {
            myMap.set(item[0], item[1])
          })
          const mergedMap = mergeMaps(map.m_map, myMap)
          IBehavior.setMapBehavior(mergedMap)
          if(map != null && map != undefined){
            // console.log(map)
            this.mapState = true;
            let arr = [];
            let array = 0;
            mergedMap.forEach((value,key)=>{
              let obj = {};
              obj.value = value;
              obj.key = key;
              arr.push(obj)
              array++;
            })
            for(let i =0;i<10 - array;i++){
              arr.push({key:"",value:""})
            }
            this.mapList= arr;
          }else{
            this.mapState = false;
          }
          // console.log(this.mapList)
          // map.forEach(item => {
          // })
        },
        IBehaviorData() {
            //文本控制器
            const getBehaviorList = IBehavior.getBehavior();
            // console.log("--", getBehaviorList)
            if (getBehaviorList != null) {
                this.boxState = true;
                var mt = getBehaviorList.get(IBehavior.m_mutilTextBehavior);
                if (mt != null && mt != undefined) {
                    var list = mt.m_mutilTextArray;
                    list.forEach(element => {
                        // console.log(element)
                    });

                    this.textState = true;
                    this.text0 = "";
                    this.text1 = "";
                    this.text2 = "";
                    this.text0 = list[0];
                    this.text1 = list[1];
                    this.text2 = list[2];
                }else{
                    this.textState = false;
                }
                
                //纹理控制器
                var tb = getBehaviorList.get(IBehavior.m_textureBehavior);
                if(tb != null && tb != undefined){
                    // console.log("tb",tb)
                    this.m_u_start = tb.m_u_start;
                    this.m_v_start = tb.m_v_start;
                    this.m_u_end = tb.m_u_end;
                    this.m_v_end = tb.m_v_end;
                    this.m_time = tb.m_time;
                    this.m_rot_start = tb.m_rot_start;
                    this.m_rot_end = tb.m_rot_end;
                    this.textureModel = tb.m_yoyo;
                    this.textureState = true;
                }else{
                    this.textureState = false;
                }
                //动态控制器
                var db = getBehaviorList.get(IBehavior.m_dymaticBehavior);
                if(db != null && db != undefined){
                    // console.log("db",db)
                    this.m_pos_startX = db.m_pos_start[0];
                    this.m_pos_startY = db.m_pos_start[1];
                    this.m_pos_startZ = db.m_pos_start[2];
                    this.m_pos_endX = db.m_pos_end[0];
                    this.m_pos_endY = db.m_pos_end[1];
                    this.m_pos_endZ = db.m_pos_end[2];
                    this.m_rot_startX = db.m_rot_start[0];
                    this.m_rot_startY = db.m_rot_start[1];
                    this.m_rot_startZ = db.m_rot_start[2];
                    this.m_rot_endX = db.m_rot_end[0];
                    this.m_rot_endY = db.m_rot_end[1];
                    this.m_rot_endZ = db.m_rot_end[2];
                    this.m_scale_startX = db.m_scale_start[0];
                    this.m_scale_startY = db.m_scale_start[1];
                    this.m_scale_startZ = db.m_scale_start[2];
                    this.m_scale_endX = db.m_scale_end[0];
                    this.m_scale_endY = db.m_scale_end[1];
                    this.m_scale_endZ = db.m_scale_end[2];
                    this.DynamicMode = db.m_yoyo;
                    this.d_time = db.m_time;
                    this.controllerState = true;
                }else{
                    this.controllerState = false;
                }

                //曲线模拟器
                var cb = getBehaviorList.get(IBehavior.m_curveBehavior);
                if (cb != null && cb != undefined) {
                    // console.log("11",cb)
                    this.speed = cb.m_speed;
                    this.bloop = cb.m_bloop;
                    this.sim = cb.m_sim;
                    this.select = cb.m_curve_name;
                    this.pathState = true;
                    this.percent = cb.m_percent;
                    const CurveBehaviorList = IBehavior.getCurveBehaviorList();
                    if(CurveBehaviorList != null){
                        // console.log("---",CurveBehaviorList)
                        this.selectList = CurveBehaviorList;
                    }
                }else{
                    this.pathState = false;
                }
                //HUB
                let hub = getBehaviorList.get(IBehavior.m_hudBehavior);
                if (hub != null && hub != undefined) {
                    // console.log("11",hub)
                    this.positionX = hub.m_center.x;
                    this.positionY = hub.m_center.y;
                    this.positionZ = hub.m_center.z;
                    this.Color = hub.m_color;
                    this.size = hub.m_size;
                    this.hubText = hub.m_text;
                    this.hudState = true;
                }else{
                    this.hudState = false;
                }
                //AI寻路
                let nav = getBehaviorList.get(IBehavior.m_navMeshBehavior);
                if(nav != null && nav !=undefined){
                    // console.log("ai",nav)
                    this.m_b_anim = nav.m_b_anim;
                    this.m_group_anim_name = nav.m_group_anim_name;
                    this.m_idle_anim = nav.m_idle_anim;
                    this.m_maxForce = nav.m_maxForce;
                    this.m_maxSpeed = nav.m_maxSpeed;
                    this.m_navMeshPath = nav.m_navMeshPath;
                    this.m_nextWaypointDistance = nav.m_nextWaypointDistance;
                    this.m_run_anim = nav.m_run_anim;
                    this.m_userData = nav.m_userData;
                    this.offsetX = nav.m_offset[0];
                    this.offsetY = nav.m_offset[1];
                    this.offsetZ = nav.m_offset[2];
                    this.NavMeshState = true;
                }else{
                    this.NavMeshState = false;
                }
                //键值对
                let map = getBehaviorList.get(IBehavior.m_mapBehavior);
                if(map != null && map != undefined){
                    // console.log(map)
                    this.mapState = true;
                    let arr = [];
                    let array = 0;
                    map.m_map.forEach((value,key)=>{
                        let obj = {};
                        obj.value = value;
                        obj.key = key;
                        arr.push(obj)
                        array++;
                    })
                    for(let i =0;i<10 - array;i++){
                        arr.push({key:"",value:""})
                    }
                    this.mapList= arr;
                }else{
                    this.mapState = false;
                }

            }else{
                this.boxState = false;
            }


        },
        loopChange(){
            let data = {
                curve_name:this.select,
                speed:Number(this.speed),
                bloop:this.bloop,
                sim:this.sim,
                percent:Number(this.percent)
            }
            IBehavior.setCurveBehavior(data);
        },
        simChange(e){
            this.sim = e.target.checked;
           let data = {
                curve_name:this.select,
                speed:Number(this.speed),
                bloop:this.bloop,
                sim:this.sim,
                percent:Number(this.percent)
            }
            IBehavior.setCurveBehavior(data);
        },
        textareaChnage0() {
            IBehavior.setMutilTextBehaviorText(0,this.text0)
        },
        textareaChnage1() {
            IBehavior.setMutilTextBehaviorText(1,this.text1)
        },
        textareaChnage2() {
            IBehavior.setMutilTextBehaviorText(2,this.text2)
        },
        addChange() {
            this.tabState = false;
        },
        reduce() {
            this.$confirm("您确认关闭纹理控制器吗！", "提示", {
                confirmButtonText: "确认",
                cancelButtonText: "取消",
                type: "warning"
            })
            .then(async () => {
                this.textureState = false;
                this.$store.commit("ScriptDataChange", '')
                IBehavior.removeBehavior(IBehavior.m_textureBehavior)
            })
            .catch(() => {
                this.$message({
                    type: "info",
                    message: "已取消"
                });
            });
        },
        reduce1() {
            this.$confirm("您确认关闭动态控制器吗！", "提示", {
                confirmButtonText: "确认",
                cancelButtonText: "取消",
                type: "warning"
            })
            .then(async () => {
                this.controllerState = false;
                this.$store.commit("ScriptDataChange", '')
                IBehavior.removeBehavior(IBehavior.m_dymaticBehavior)
            })
            .catch(() => {
                this.$message({
                    type: "info",
                    message: "已取消"
                });
            });
        },
        reduce2() {
            this.targetState = false;
        },
        reduce3() {
            this.$confirm("您确认关闭文本控制器吗！", "提示", {
                confirmButtonText: "确认",
                cancelButtonText: "取消",
                type: "warning"
            })
            .then(async () => {
                this.textState = false;
                this.$store.commit("ScriptDataChange", '')
                IBehavior.removeBehavior(IBehavior.m_mutilTextBehavior)
            })
            .catch(() => {
                this.$message({
                    type: "info",
                    message: "已取消"
                });
            });
        },
        reduce4(){
            this.$confirm("您确认关闭路径模拟器吗！", "提示", {
                confirmButtonText: "确认",
                cancelButtonText: "取消",
                type: "warning"
            })
            .then(async () => {
                this.pathState = false;
                this.$store.commit("ScriptDataChange", '')
                IBehavior.removeBehavior(IBehavior.m_curveBehavior)
            })
            .catch(() => {
                this.$message({
                    type: "info",
                    message: "已取消"
                });
            });
        },
        reduce5(){
            this.$confirm("您确认关闭HUD吗！", "提示", {
                confirmButtonText: "确认",
                cancelButtonText: "取消",
                type: "warning"
            })
            .then(async () => {
                this.hudState = false;
                this.$store.commit("ScriptDataChange", '')
                IBehavior.removeBehavior(IBehavior.m_hudBehavior)
            })
            .catch(() => {
                this.$message({
                    type: "info",
                    message: "已取消"
                });
            });
        },
        reduce6(){
            this.$confirm("您确认关闭AI寻路吗！", "提示", {
                confirmButtonText: "确认",
                cancelButtonText: "取消",
                type: "warning"
            })
            .then(async () => {
                this.NavMeshState = false;
                this.$store.commit("ScriptDataChange", '')
                IBehavior.removeBehavior(IBehavior.m_navMeshBehavior)
            })
            .catch(() => {
                this.$message({
                    type: "info",
                    message: "已取消"
                });
            });
        },
        reduce7(){
            this.$confirm("您确认关闭键值对脚本吗！", "提示", {
                confirmButtonText: "确认",
                cancelButtonText: "取消",
                type: "warning"
            })
            .then(async () => {
                this.mapState = false;
                this.$store.commit("ScriptDataChange", '')
                IBehavior.removeBehavior(IBehavior.m_mapBehavior)
            })
            .catch(() => {
                this.$message({
                    type: "info",
                    message: "已取消"
                });
            });
        },
        tabChange(index) {
            this.cur = index;
            this.tabState = false;
            if (index == 0) {
                this.textureState = true;
                this.list[0].src = checkedTex;
                this.list[1].src = controller;
                this.list[2].src = text;
                this.list[3].src = path;
                this.list[4].src = hud;
                this.list[5].src = Ai;
                this.list[6].scr = map;
                IBehavior.addBehavior(IBehavior.m_textureBehavior);
                this.IBehaviorData()
            } else if (index == 1) {
                this.controllerState = true;
                this.list[0].src = texture;
                this.list[1].src = checkedCon;
                this.list[2].src = text;
                this.list[3].src = path;
                this.list[4].src = hud;
                this.list[5].src = Ai;
                this.list[6].scr = map;
                IBehavior.addBehavior(IBehavior.m_dymaticBehavior);
                this.IBehaviorData()
            } else if (index == 2) {
                this.list[0].src = texture;
                this.list[1].src = controller;
                this.list[2].src = checkedText;
                this.list[3].src = path;
                this.list[4].src = hud;
                this.list[5].src = Ai;
                this.list[6].scr = map;
                this.text0 = "";
                this.text1 = "";
                this.text2 = "";
                this.textState = true;
                IBehavior.addBehavior(IBehavior.m_mutilTextBehavior);
            }else if(index == 3){
                this.list[0].src = texture;
                this.list[1].src = controller;
                this.list[2].src = text;
                this.list[3].src = checkedPath;
                this.list[4].src = hud;
                this.list[5].src = Ai;
                this.list[6].scr = map;
                this.pathState = true;
                IBehavior.addBehavior(IBehavior.m_curveBehavior);
                this.IBehaviorData()
            }else if(index == 4){
                this.list[0].src = texture;
                this.list[1].src = controller;
                this.list[2].src = text;
                this.list[3].src = path;
                this.list[4].src = CheckedHud;
                this.list[5].src = Ai;
                this.list[6].scr = map;
                this.hudState = true;
                IBehavior.addBehavior(IBehavior.m_hudBehavior);
                this.IBehaviorData()
            }else if(index == 5){
                this.list[0].src = texture;
                this.list[1].src = controller;
                this.list[2].src = text;
                this.list[3].src = path;
                this.list[4].src = hud;
                this.list[5].src = CheckedAi;
                this.list[6].scr = map;
                this.NavMeshState= true;
                IBehavior.addBehavior(IBehavior.m_navMeshBehavior);
                this.IBehaviorData()
            }else if(index == 6){
                IBehavior.addBehavior(IBehavior.m_mapBehavior)
                this.list[0].src = texture;
                this.list[1].src = controller;
                this.list[2].src = text;
                this.list[3].src = path;
                this.list[4].src = hud;
                this.list[5].src = Ai;
                this.list[6].scr = CheckedMap;
                this.mapState = true;
                this.IBehaviorData();
            }
        },
        selectChange(){
            let data = {
                curve_name:this.select,
                speed:Number(this.speed),
                bloop:this.bloop,
                sim:this.sim,
                percent:Number(this.percent)
            }
            // console.log(data)
            IBehavior.setCurveBehavior(data);
        },
        selectLoop(){
            let data = {
                curve_name:this.select,
                speed:Number(this.speed),
                bloop:this.bloop,
                sim:this.sim,
                percent:Number(this.percent)
            }
            // console.log(data)
            IBehavior.setCurveBehavior(data);
        },
        //纹理动态方式e
        textureSelect(e){
            this.textureModel = e.target.checked;
            let data = {
                m_u_start : Number(this.m_u_start),
                m_v_start : Number(this.m_v_start),
                m_u_end : Number(this.m_u_end),
                m_v_end : Number(this.m_v_end),
                m_time : Number(this.m_time),
                m_rot_start : Number(this.m_rot_start),
                m_rot_end : Number(this.m_rot_end),
                m_yoyo : this.textureModel
            }
            // console.log(data)
            IBehavior.setTextureBehavior(data)
        },
        //动态方式
        DynamicModeSelect(e){
            this.DynamicMode = e.target.checked;
            let data = {
                m_pos_start:[Number(this.m_pos_startX),Number(this.m_pos_startY),Number(this.m_pos_startZ)],
                m_pos_end:[Number(this.m_pos_endX),Number(this.m_pos_endY),Number(this.m_pos_endZ)],
                m_rot_start:[Number(this.m_rot_startX),Number(this.m_rot_startY),Number(this.m_rot_startZ)],
                m_rot_end:[Number(this.m_rot_endX),Number(this.m_rot_endY),Number(this.m_rot_endZ)],
                m_scale_start:[Number(this.m_scale_startX),Number(this.m_scale_startY),Number(this.m_scale_startZ)],
                m_scale_end:[Number(this.m_scale_endX),Number(this.m_scale_endY),Number(this.m_scale_endZ)],
                m_time:Number(this.d_time),
                m_yoyo:this.DynamicMode
            }
            console.log(data)
            IBehavior.setDymaticBehavior(data);
        },
        startChange(){
            let data = {
                curve_name:this.select,
                speed:Number(this.speed),
                bloop:this.bloop,
                sim:this.sim,
                percent:Number(this.percent)
            }
            // console.log(data)
            IBehavior.setCurveBehavior(data);
        },
        bHelperChange() {

        },
        animChange(e){
            this.m_b_anim = e.target.checked;
            let data = {
                m_navMeshPath:this.m_navMeshPath,
                m_maxSpeed:Number(this.m_maxSpeed),
                m_maxForce:Number(this.m_maxForce),
                m_nextWaypointDistance:Number(this.m_nextWaypointDistance),
                m_b_anim:this.m_b_anim,
                m_group_anim_name:this.m_group_anim_name,
                m_idle_anim:this.m_idle_anim,
                m_run_anim:this.m_run_anim,
                m_userData:this.m_userData,
                m_offset:[Number(this.offsetX),Number(this.offsetY),Number(this.offsetZ)]
            }
            // console.log(data)
            IBehavior.setNavMeshBehavior(data)
        },
        changeNum() {
            let data = {
                m_u_start : Number(this.m_u_start),
                m_v_start : Number(this.m_v_start),
                m_u_end : Number(this.m_u_end),
                m_v_end : Number(this.m_v_end),
                m_time : Number(this.m_time),
                m_rot_start : Number(this.m_rot_start),
                m_rot_end : Number(this.m_rot_end),
                m_yoyo : this.textureModel
            }
            // console.log(data)
            IBehavior.setTextureBehavior(data)
        },
        changeNum1() {
            let data = {
                m_pos_start:[Number(this.m_pos_startX),Number(this.m_pos_startY),Number(this.m_pos_startZ)],
                m_pos_end:[Number(this.m_pos_endX),Number(this.m_pos_endY),Number(this.m_pos_endZ)],
                m_rot_start:[Number(this.m_rot_startX),Number(this.m_rot_startY),Number(this.m_rot_startZ)],
                m_rot_end:[Number(this.m_rot_endX),Number(this.m_rot_endY),Number(this.m_rot_endZ)],
                m_scale_start:[Number(this.m_scale_startX),Number(this.m_scale_startY),Number(this.m_scale_startZ)],
                m_scale_end:[Number(this.m_scale_endX),Number(this.m_scale_endY),Number(this.m_scale_endZ)],
                m_time:Number(this.d_time),
                m_yoyo:this.DynamicMode
            }
            // console.log(data)
            IBehavior.setDymaticBehavior(data);

        },
        async pasteChange(){
            var val = await navigator.clipboard.readText();
            this.m_pos_startX = Number(val.split(',')[0]);
            this.m_pos_startY = Number(val.split(',')[1]);
            this.m_pos_startZ = Number(val.split(',')[2]);
            let data = {
                m_pos_start:[Number(this.m_pos_startX),Number(this.m_pos_startY),Number(this.m_pos_startZ)],
                m_pos_end:[Number(this.m_pos_endX),Number(this.m_pos_endY),Number(this.m_pos_endZ)],
                m_rot_start:[Number(this.m_rot_startX),Number(this.m_rot_startY),Number(this.m_rot_startZ)],
                m_rot_end:[Number(this.m_rot_endX),Number(this.m_rot_endY),Number(this.m_rot_endZ)],
                m_scale_start:[Number(this.m_scale_startX),Number(this.m_scale_startY),Number(this.m_scale_startZ)],
                m_scale_end:[Number(this.m_scale_endX),Number(this.m_scale_endY),Number(this.m_scale_endZ)],
                m_time:Number(this.d_time),
                m_yoyo:this.DynamicMode
            }
            // console.log(data)
            IBehavior.setDymaticBehavior(data);
        },
        async pasteChange1(){
            var val = await navigator.clipboard.readText();
            this.m_pos_endX = Number(val.split(',')[0]);
            this.m_pos_endY = Number(val.split(',')[1]);
            this.m_pos_endZ = Number(val.split(',')[2]);
            let data = {
                m_pos_start:[Number(this.m_pos_startX),Number(this.m_pos_startY),Number(this.m_pos_startZ)],
                m_pos_end:[Number(this.m_pos_endX),Number(this.m_pos_endY),Number(this.m_pos_endZ)],
                m_rot_start:[Number(this.m_rot_startX),Number(this.m_rot_startY),Number(this.m_rot_startZ)],
                m_rot_end:[Number(this.m_rot_endX),Number(this.m_rot_endY),Number(this.m_rot_endZ)],
                m_scale_start:[Number(this.m_scale_startX),Number(this.m_scale_startY),Number(this.m_scale_startZ)],
                m_scale_end:[Number(this.m_scale_endX),Number(this.m_scale_endY),Number(this.m_scale_endZ)],
                m_time:Number(this.d_time),
                m_yoyo:this.DynamicMode
            }
            // console.log(data)
            IBehavior.setDymaticBehavior(data);
        },
        async pasteChange2(){
            var val = await navigator.clipboard.readText();
            this.m_rot_startX = Number(val.split(',')[0]);
            this.m_rot_startY = Number(val.split(',')[1]);
            this.m_rot_startZ = Number(val.split(',')[2]);
            let data = {
                m_pos_start:[Number(this.m_pos_startX),Number(this.m_pos_startY),Number(this.m_pos_startZ)],
                m_pos_end:[Number(this.m_pos_endX),Number(this.m_pos_endY),Number(this.m_pos_endZ)],
                m_rot_start:[Number(this.m_rot_startX),Number(this.m_rot_startY),Number(this.m_rot_startZ)],
                m_rot_end:[Number(this.m_rot_endX),Number(this.m_rot_endY),Number(this.m_rot_endZ)],
                m_scale_start:[Number(this.m_scale_startX),Number(this.m_scale_startY),Number(this.m_scale_startZ)],
                m_scale_end:[Number(this.m_scale_endX),Number(this.m_scale_endY),Number(this.m_scale_endZ)],
                m_time:Number(this.d_time),
                m_yoyo:this.DynamicMode
            }
            // console.log(data)
            IBehavior.setDymaticBehavior(data);
            
        },
        async pasteChange3(){
            var val = await navigator.clipboard.readText();
            this.m_rot_endX = Number(val.split(',')[0]);
            this.m_rot_endY = Number(val.split(',')[1]);
            this.m_rot_endZ = Number(val.split(',')[2]);
            let data = {
                m_pos_start:[Number(this.m_pos_startX),Number(this.m_pos_startY),Number(this.m_pos_startZ)],
                m_pos_end:[Number(this.m_pos_endX),Number(this.m_pos_endY),Number(this.m_pos_endZ)],
                m_rot_start:[Number(this.m_rot_startX),Number(this.m_rot_startY),Number(this.m_rot_startZ)],
                m_rot_end:[Number(this.m_rot_endX),Number(this.m_rot_endY),Number(this.m_rot_endZ)],
                m_scale_start:[Number(this.m_scale_startX),Number(this.m_scale_startY),Number(this.m_scale_startZ)],
                m_scale_end:[Number(this.m_scale_endX),Number(this.m_scale_endY),Number(this.m_scale_endZ)],
                m_time:Number(this.d_time),
                m_yoyo:this.DynamicMode
            }
            // console.log(data)
            IBehavior.setDymaticBehavior(data);
        },
        async pasteChange4(){
            var val = await navigator.clipboard.readText();
            this.m_scale_startX = Number(val.split(',')[0]);
            this.m_scale_startY = Number(val.split(',')[1]);
            this.m_scale_startZ = Number(val.split(',')[2]);
            let data = {
                m_pos_start:[Number(this.m_pos_startX),Number(this.m_pos_startY),Number(this.m_pos_startZ)],
                m_pos_end:[Number(this.m_pos_endX),Number(this.m_pos_endY),Number(this.m_pos_endZ)],
                m_rot_start:[Number(this.m_rot_startX),Number(this.m_rot_startY),Number(this.m_rot_startZ)],
                m_rot_end:[Number(this.m_rot_endX),Number(this.m_rot_endY),Number(this.m_rot_endZ)],
                m_scale_start:[Number(this.m_scale_startX),Number(this.m_scale_startY),Number(this.m_scale_startZ)],
                m_scale_end:[Number(this.m_scale_endX),Number(this.m_scale_endY),Number(this.m_scale_endZ)],
                m_time:Number(this.d_time),
                m_yoyo:this.DynamicMode
            }
            // console.log(data)
            IBehavior.setDymaticBehavior(data);
        },
        async pasteChange5(){
            var val = await navigator.clipboard.readText();
            this.m_scale_endX = Number(val.split(',')[0]);
            this.m_scale_endY = Number(val.split(',')[1]);
            this.m_scale_endZ = Number(val.split(',')[2]);
            let data = {
                m_pos_start:[Number(this.m_pos_startX),Number(this.m_pos_startY),Number(this.m_pos_startZ)],
                m_pos_end:[Number(this.m_pos_endX),Number(this.m_pos_endY),Number(this.m_pos_endZ)],
                m_rot_start:[Number(this.m_rot_startX),Number(this.m_rot_startY),Number(this.m_rot_startZ)],
                m_rot_end:[Number(this.m_rot_endX),Number(this.m_rot_endY),Number(this.m_rot_endZ)],
                m_scale_start:[Number(this.m_scale_startX),Number(this.m_scale_startY),Number(this.m_scale_startZ)],
                m_scale_end:[Number(this.m_scale_endX),Number(this.m_scale_endY),Number(this.m_scale_endZ)],
                m_time:Number(this.d_time),
                m_yoyo:this.DynamicMode
            }
            // console.log(data)
            IBehavior.setDymaticBehavior(data);
        },
        changeNum2() {
            let data = {
                curve_name:this.select,
                speed:Number(this.speed),
                bloop:this.bloop,
                sim:this.sim,
                percent:Number(this.percent)
            }
            // console.log(data)
            IBehavior.setCurveBehavior(data);
        },
        AiNum(){
            let data = {
                m_navMeshPath:this.m_navMeshPath,
                m_maxSpeed:Number(this.m_maxSpeed),
                m_maxForce:Number(this.m_maxForce),
                m_nextWaypointDistance:Number(this.m_nextWaypointDistance),
                m_b_anim:this.m_b_anim,
                m_group_anim_name:this.m_group_anim_name,
                m_idle_anim:this.m_idle_anim,
                m_run_anim:this.m_run_anim,
                m_userData:this.m_userData,
                m_offset:[Number(this.offsetX),Number(this.offsetY),Number(this.offsetZ)]
            }
            // console.log(data)
            IBehavior.setNavMeshBehavior(data)
        },
        mapNum(){
            let myMap = new Map();
            this.mapList.forEach((item)=>{
                if(!myMap.has(item.key)){
                    myMap.set(item.key,item.value)
                }
            })
            // console.log(myMap)
            IBehavior.setMapBehavior(myMap)
            
        },
        shrinkChange() {
            if (this.isActive) {
                this.isActive = false
            } else {
                this.isActive = true;
            }
        },
        shrinkChange1() {
            if (this.isActive1) {
                this.isActive1 = false
            } else {
                this.isActive1 = true;
            }
        },
        shrinkChange2() {
            if (this.isActive2) {
                this.isActive2 = false
            } else {
                this.isActive2 = true;
            }
        },
        shrinkChange3() {
            if (this.isActive3) {
                this.isActive3 = false
            } else {
                this.isActive3 = true;
            }
        },
        shrinkChange4() {
            if (this.isActive4) {
                this.isActive4 = false
            } else {
                this.isActive4 = true;
            }
        },
        shrinkChange5() {
            if (this.isActive5) {
                this.isActive5 = false
            } else {
                this.isActive5 = true;
            }
        },
        shrinkChange6(){
            if (this.isActive6) {
                this.isActive6 = false
            } else {
                this.isActive6 = true;
            }
        },
        shrinkChange7(){
            if (this.isActive7) {
                this.isActive7 = false
            } else {
                this.isActive7 = true;
            }
        },
        changeNum0(){
            let color = this.Color.slice(1);
                let rgba = [
                    parseInt('0x'+color.slice(0, 2)),
                    parseInt('0x'+color.slice(2, 4)),
                    parseInt('0x'+color.slice(4, 6)),
                ];
                let color0 = rgba[0]/255;
                let color1 = rgba[1]/255;
                let color2 = rgba[2]/255;
                let data = {
                    m_center:[Number(this.positionX),Number(this.positionY),Number(this.positionZ)],
                    m_colorArray:[color0,color1,color2],
                    m_text:this.hubText,
                    m_size:Number(this.size)
                }
                // console.log(data)
                IBehavior.setHudBehavior(data);
        },
        sizeNum(){
            let color = this.Color.slice(1);
            let rgba = [
                parseInt('0x'+color.slice(0, 2)),
                parseInt('0x'+color.slice(2, 4)),
                parseInt('0x'+color.slice(4, 6)),
            ];
            let color0 = rgba[0]/255;
            let color1 = rgba[1]/255;
            let color2 = rgba[2]/255;
            let data = {
                m_center:[Number(this.positionX),Number(this.positionY),Number(this.positionZ)],
                m_colorArray:[color0,color1,color2],
                m_text:this.hubText,
                m_size:Number(this.size)
            }
            // console.log(data)
            IBehavior.setHudBehavior(data);
        },
        hubChange(){
            let color = this.Color.slice(1);
            let rgba = [
                parseInt('0x'+color.slice(0, 2)),
                parseInt('0x'+color.slice(2, 4)),
                parseInt('0x'+color.slice(4, 6)),
            ];
            let color0 = rgba[0]/255;
            let color1 = rgba[1]/255;
            let color2 = rgba[2]/255;
            let data = {
                m_center:[Number(this.positionX),Number(this.positionY),Number(this.positionZ)],
                m_colorArray:[color0,color1,color2],
                m_text:this.hubText,
                m_size:Number(this.size)
            }
            // console.log(data)
            IBehavior.setHudBehavior(data);
        },
        ColorChange(){
            let color = this.Color.slice(1);
            let rgba = [
                parseInt('0x'+color.slice(0, 2)),
                parseInt('0x'+color.slice(2, 4)),
                parseInt('0x'+color.slice(4, 6)),
            ];
            let color0 = rgba[0]/255;
            let color1 = rgba[1]/255;
            let color2 = rgba[2]/255;
            let data = {
                m_center:[Number(this.positionX),Number(this.positionY),Number(this.positionZ)],
                m_colorArray:[color0,color1,color2],
                m_text:this.hubText,
                m_size:Number(this.size)
            }
            // console.log(data)
            IBehavior.setHudBehavior(data);
        },
        colorImport(){
            let color = this.Color.slice(1);
            let rgba = [
                parseInt('0x'+color.slice(0, 2)),
                parseInt('0x'+color.slice(2, 4)),
                parseInt('0x'+color.slice(4, 6)),
            ];
            let color0 = rgba[0]/255;
            let color1 = rgba[1]/255;
            let color2 = rgba[2]/255;
            let data = {
                m_center:[Number(this.positionX),Number(this.positionY),Number(this.positionZ)],
                m_colorArray:[color0,color1,color2],
                m_text:this.hubText,
                m_size:Number(this.size)
            }
            // console.log(data)
            IBehavior.setHudBehavior(data);
        },
        ActiveColor(value){
            let value1 =  this.transitionColror(value);
            this.Color = value1;
            let color = value1.slice(1);
            let rgba = [
                parseInt('0x'+color.slice(0, 2)),
                parseInt('0x'+color.slice(2, 4)),
                parseInt('0x'+color.slice(4, 6)),
            ];
            let color0 = rgba[0]/255;
            let color1 = rgba[1]/255;
            let color2 = rgba[2]/255;
            let data = {
                m_center:[Number(this.positionX),Number(this.positionY),Number(this.positionZ)],
                m_colorArray:[color0,color1,color2],
                m_text:this.hubText,
                m_size:Number(this.size)
            }
            // console.log(data)
            IBehavior.setHudBehavior(data);
        },
        Focus() {
            ISetting.isInput(true);
        },
        Blur() {
            ISetting.isInput(false);
        },
        //rgb转十六进制
        transitionColror(color){
            var values = color
            .replace(/rgba?\(/, '')
            .replace(/\)/, '')
            .replace(/[\s+]/g, '')
            .split(',');
            var a = parseFloat(values[3] || 1),
                r = Math.round(a * parseInt(values[0]) + (1 - a) * 255),
                g = Math.round(a * parseInt(values[1]) + (1 - a) * 255),
                b = Math.round(a * parseInt(values[2]) + (1 - a) * 255);
            return "#" +
                ("0" + r.toString(16)).slice(-2) +
                ("0" + g.toString(16)).slice(-2) +
                ("0" + b.toString(16)).slice(-2);
        },
    }
}
</script>
<style scoped>
.box {
    width: 90%;
    height: 260px;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    border-radius: 5px;
    margin-top: 8px;
    margin-left: 70px;
    font-size: 10px;
    background: #fff;
    overflow: hidden;
}

.box3 {
    width: 90%;
    height: 420px;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    border-radius: 5px;
    margin-top: 8px;
    margin-left: 70px;
    font-size: 10px;
    background: #fff;
    overflow: hidden;
}

.box2 {
    width: 90%;
    height: 260px;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    border-radius: 5px;
    margin-top: 8px;
    margin-left: 70px;
    font-size: 10px;
    background: #fff;
    overflow: hidden;
}

.box4 {
    width: 90%;
    height: 400px;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    border-radius: 5px;
    margin-top: 8px;
    margin-left: 70px;
    font-size: 10px;
    background: #fff;
    overflow: hidden;
}
.box5 {
    width: 90%;
    height: 650px;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    border-radius: 5px;
    margin-top: 8px;
    margin-left: 70px;
    font-size: 10px;
    background: #fff;
    overflow: hidden;
}
.box6 {
    width: 90%;
    height: 480px;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    border-radius: 5px;
    margin-top: 8px;
    margin-left: 70px;
    font-size: 10px;
    background: #fff;
    overflow: hidden;
}
.anmDiv {
    transition: 1S;

}

.aaa {
    height: 40px;
    transition: 1S;
}

.header {
    display: flex;
    width: 95%;
    height: 40px;
    margin: auto;
    align-items: center;
    color: #000;
    font-weight: bold;
    font-size: 12px;
}

.header p {
    margin-left: 10px;
}

.header i {
    font-weight: bold;
    cursor: pointer;
}

.projection {
    display: flex;
    width: 100%;
    height: 20px;
    align-items: center;
    margin: 20px auto;
    font-size: 12px;
}

.projection .title {
    width: 100px;
    height: 20px;
    line-height: 20px;
    text-align: left;
    font-weight: bolder;
    overflow: hidden;
    font-size: 10px;
    margin-left: 20px;
}
.projection .textarea{
    width: 70%;
    height: 100px;
    outline: none;
    border: none;
    border-radius: 6px;
    color: #808080;
    resize: none;
    scrollbar-width: none;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    margin-left: 0px;
}
.txt_box {
    width: 200px;
    height: 30px;
    box-shadow: 0px 0px 2px 0px rgba(33, 35, 49, 0.1);
    border-radius: 6px;
    display: flex;
    align-items: center;
}

.projection .input {
    width: 190px;
    height: 28px;
    outline: none;
    color: #808080;
    border: none;
    border-radius: 4px;
    text-indent: 5px;
}

.videoCover {
    display: flex;
    width: 100%;
    height: 150px;
    align-items: center;
    margin: auto;
    font-size: 12px;
    margin-bottom: 230px;
}

.videoCover .title {
    width: 70px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-weight: bolder;
    overflow: hidden;
}

.shadow {
    display: flex;
    width: 90%;
    height: 25px;
    align-items: center;
    margin-top: 15px;
}

.shadow .title {
    width: 100px;
    height: 20px;
    line-height: 20px;
    background: #fff;
    text-align: center;
    font-weight: bold;
    overflow: hidden;
}

.input1:checked {
    background: #1673ff
}

.input1 {
    width: 16px;
    height: 16px;
    outline: none;
    background-color: rgba(125, 128, 133, 0.4);
    border: solid 1px #dddddd;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    font-size: 0.8rem;
    padding: 0;
    position: relative;
    display: inline-block;
    vertical-align: top;
    cursor: default;
    -webkit-appearance: none;
    -webkit-user-select: none;
    user-select: none;
    -webkit-transition: background-color ease 0.1s;
    transition: background-color ease 0.1s;
    outline: none;
}

.input1:checked::after {
    content: '';
    top: 3px;
    left: 3px;
    position: absolute;
    background: transparent;
    border: #fff solid 2px;
    border-top: none;
    border-right: none;
    height: 4px;
    width: 6px;
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.ipt_box {
    width: 65px;
    height: 25px;
    /* margin-left: 30px; */
    background: #FFFFFF;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.add {
    margin: auto;
    cursor: pointer;
}

.add i {
    font-size: 30px;
    margin: auto;
}

.tab {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 62%);
    width: 140px;
    height: 280px;
    border-radius: 10px;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
}

.tab .list {
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
    font-size: 12px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
}
.tab .list .img{
    height:100%;
    display: flex;
    align-items: center;
    justify-items: center;
}
.tab .list img{
    width: 15px;
    height: 15px;
}
.tab .list .text{
    width: 70%;
    height: 100%;
}
.list:hover {
    background: #cddfff;
}

.active {
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    color: royalblue;
}

.del {
    position: absolute;
    right: 20px;
}

.del i {
    font-size: 18px;
}

.textarea_box {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}
.textarea_box1{
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin: auto;
}
.textarea_box1 .title{
    width: 60px;
    height: 20px;
    line-height: 20px;
    background: #fff;
    text-align: left;
    font-weight: bold;
    overflow: hidden;
    margin-left: 20px;
}
.textarea {
    width: 90%;
    height: 100px;
    outline: none;
    border: none;
    border-radius: 6px;
    color: #808080;
    resize: none;
    scrollbar-width: none;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    margin: auto;
}
.textarea_box1 .textarea{
    width: 80%;
    height: 100px;
    outline: none;
    border: none;
    border-radius: 6px;
    color: #808080;
    resize: none;
    scrollbar-width: none;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    margin-left: 30px;
}
.textarea /deep/ .el-textarea__inner {
    height: 100px !important;
}

textarea::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 8px;
    height: 1px;
}

textarea::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 8px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #BEC2C9;
}

textarea::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    /* box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2); */
    border-radius: 10px;
    background: #eff3f4;
}
.el-message-box__headerbtn .el-message-box__close{
    font-size: 8px !important;
}
.select{
    width: 100%;
    height: 28px;
    outline: none;
    color: #808080;
    border: none;
    border-radius:4px;
}
.select /deep/ .el-input{
   width: 100% !important;
   height: 28px !important;
   outline: none !important;
}
.select /deep/ .el-input__inner{
   width: 100% !important;
   height: 28px !important;
   outline: none !important;
}
.select /deep/ .el-select__caret{
  height: 100% !important;
  line-height: 28px !important;
}
.slider{
  width: 350px;
  /* margin-left: 20px; */
  /* margin-right: 10px; */
}
.loction{
    width: 100%;
    /* margin: 20px auto; */
    display: flex;
    height: 25px;
    align-items: center;
    justify-content: left;
    overflow: hidden;
    margin-top: 10px;
}
.loction .title{
    width: 55px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    font-weight: bolder;
    overflow: hidden;
    margin-left: 20px;
}
.loction1{
    width: 100%;
    margin: 20px auto;
    display: flex;
    height: 25px;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
}
.loction1 .title{
    width: 55px;
    height: 30px;
    text-align: left;
    line-height: 30px;
    font-weight: bolder;
    overflow: hidden;
    font-size: 10px;
    margin-left: 20px;
}
.axis{
    width: 24%;
    height: 100%;
    display: flex;
    align-items: center;
    background-color:#F7F7F7;
    border-radius: 5px;

}
.axis p{
    width: 22px;
    height: 18px;
    line-height: 20px;
    text-align: center;
    color: #929292;
    background-color: #fff;
    margin-left: 2px;
    border-radius: 4px;
    float: left;
    color: #000000;
    font-size: 12px;
}
.axis input{
    width: 90%;
    height: 18px;
    text-align: center;
    outline: none;
    border: none;
    background-color: #F7F7F7;
    border-radius: 4px;
    font-weight: bold;
    font-size: 8px;
}
.x-axis p{
    background-color:#F20004;
}
.y-axis p{
    background-color: #43BD00;
}
.z-axis p {
    background-color: #5180E9;
}
.reset{
    width: 30px;
    height: 25px;
    text-align: center;
    line-height: 25px;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    border-radius: 5px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left:5px;
}
.reset img{
    width: 14px;
    height: 12px;
}
.color{
    display: flex;
    width: 100%;
    height: 40px;
    align-items: center;
    /* margin-top: 20px; */
    margin: auto;
    margin-bottom: 10px;
}
.color .title{
    width: 60px;
    height: 20px;
    line-height: 20px;
    text-align: left;
    font-weight: bold;
    overflow: hidden;
    margin-left: 20px;
}
.color >>>.el-color-picker__trigger{
    height: 30px;
    width: 245px;
    margin-top: 5px;
    margin-left: 30px;
}
.import_color{
  width: 70px;
  height: 30px;
  box-shadow: 0px 0px 2px 0px rgba(33, 35, 49, 0.1);
  border-radius: 6px;
  display: flex;
  text-align: center;
  align-items: center;
  margin-left: 10px;
}
.import_color input{
  width: 65px;
  height: 28px;
  outline: none;
  border: none;
  color: #808080;
  border-radius: 5px;
  text-align: center;
}
</style>
<style>
.picker .el-input__inner{
    display: none !important;
}
.el-color-picker__mask{
    width: 0px !important;
}
</style>