import { MainObjects } from "../common/MainObjects"
import { GameObject } from "../object/GameObject"
import { IScene } from "../interface/IScene"
import { ISelector } from "./ISelector";

class ITransform {

    static ChangeTransformMode =
    {
        e_POS: 0,
        e_ROT: 1,
        e_SCAL: 2,
        e_SIZE: 3,
        e_LENGTH: 4,
        e_MOVE: 5
    }

    static m_draging = false;
    static m_copying = false;
    static m_draging_scale = false;
    static f_selectTransformCallback = null;
    static f_selectTransformNeedUpdateCallback = null;
    static changeLock(isTree = true) {
        MainObjects.Blueprint.m_selectorPropertyBp.changeLock();
        MainObjects.Blueprint.m_selectListenerBp.setAxis();
        if(isTree)
        {
            if (ITransform.f_selectTransformCallback != null) {
                ITransform.f_selectTransformCallback(true);
            }
        }
        if (IScene.f_sceneTreeCallback != null) {
            IScene.f_sceneTreeCallback();
        }
    }
    static changeVisible(isTree = true) {
        MainObjects.Blueprint.m_selectorPropertyBp.changeVisible();
        MainObjects.Blueprint.m_selectListenerBp.setAxis();
        if(isTree)
        {
            if (ITransform.f_selectTransformCallback != null) {
                ITransform.f_selectTransformCallback(true);
            }
        }
        if (IScene.f_sceneTreeCallback != null) {
            IScene.f_sceneTreeCallback();
        }
    }
    
    static deleteObject() {
        MainObjects.Blueprint.m_selectListenerBp.deleteObjects();
    }
    static getSelectTransform() {
        MainObjects.Blueprint.m_selectorPropertyBp.isSelectorSameType();
        if (!ITransform.canChange()) {
            return null;
        }
        var transform = {
            name: "-",
            id: "-",
            instanceType: GameObject.InstanceType.e_UNIQUE,
            type: GameObject.GameObjectType.e_DEFULT,
            block: false,
            bvisible: true,
            position: [0, 0, 0],
            rotation: [0, 0, 0],
            scale: [0, 0, 0],
            size: [0, 0, 0],
            dirlength: [1, 1, 1, 1, 1, 1], //x y z -x -y -z
            move: [0, 0, 0],
            layer: []
        }
        const firstObj = MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjectFirst();
        if (firstObj != null) {
            transform.id = firstObj.name;
            transform.name = firstObj.userData.m_name;
            transform.type = firstObj.userData.m_gameObjectType;
            transform.block = firstObj.userData.m_block;
            transform.instanceType = firstObj.userData.m_instanceType;
            transform.bvisible = firstObj.visible;
            transform.layer = firstObj.userData.m_layers;
            if (firstObj.isLight) {
                transform.bvisible = firstObj.userData.m_gameObjectHelper.visible;
            }
            firstObj.position.toArray(transform.position);
            firstObj.rotation.toArray(transform.rotation);

            firstObj.scale.toArray(transform.scale);
            if (!MainObjects.Blueprint.m_selectorPropertyBp.isSelectorSameType()) {
                transform.type = GameObject.GameObjectType.e_DEFULT;
                transform.instanceType = GameObject.InstanceType.e_UNIQUE;
                transform.layer = 0;
            }
            if (firstObj.userData.m_boudingBoxHelper != null) {
                transform.size[0] = firstObj.userData.m_boudingBoxHelper.m_xLen * firstObj.scale.x;
                transform.size[1] = firstObj.userData.m_boudingBoxHelper.m_yLen * firstObj.scale.y;
                transform.size[2] = firstObj.userData.m_boudingBoxHelper.m_zLen * firstObj.scale.z;

                transform.dirlength = MainObjects.Blueprint.m_scaleBoxBp.getLength();
            }
        }
        MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjectFromSecond(function (obj) {

            transform.id = MainObjects.Blueprint.m_selectorPropertyBp.getSameValue(transform.id, obj.name);
            transform.name = MainObjects.Blueprint.m_selectorPropertyBp.getSameValue(transform.name, obj.userData.m_name);
            transform.position = MainObjects.Blueprint.m_selectorPropertyBp.getSameArrayValue(transform.position, obj.position.toArray());
            transform.rotation = MainObjects.Blueprint.m_selectorPropertyBp.getSameArrayValue(transform.rotation, obj.rotation.toArray());

            transform.scale = MainObjects.Blueprint.m_selectorPropertyBp.getSameArrayValue(transform.scale, obj.scale.toArray());
            var visible = obj.visible;
            if (obj.isLight) {
                visible = obj.userData.m_gameObjectHelper.visible;
            }
            transform.bvisible = MainObjects.Blueprint.m_selectorPropertyBp.getSameValue(transform.bvisible, visible);
            if (obj.userData.m_boudingBoxHelper != null) {
                transform.size[0] = MainObjects.Blueprint.m_selectorPropertyBp.getSameValue(transform.size[0], obj.userData.m_boudingBoxHelper.m_xLen * obj.scale.x);
                transform.size[1] = MainObjects.Blueprint.m_selectorPropertyBp.getSameValue(transform.size[1], obj.userData.m_boudingBoxHelper.m_yLen * obj.scale.y);
                transform.size[2] = MainObjects.Blueprint.m_selectorPropertyBp.getSameValue(transform.size[2], obj.userData.m_boudingBoxHelper.m_zLen * obj.scale.z);
            }
        });

        if (ITransform.m_draging || MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjectsCount() > 1) {
            MainObjects.Blueprint.m_selectListenerBp.m_currentGroup.position.toArray(transform.position);
            MainObjects.Blueprint.m_selectListenerBp.m_currentGroup.rotation.toArray(transform.rotation);
            MainObjects.Blueprint.m_selectListenerBp.m_currentGroup.scale.toArray(transform.scale);
        }
        else
        {
            if(ITransform.m_draging_scale)
            {
                MainObjects.Blueprint.m_scaleBoxBp.m_center.scale.toArray(transform.scale);

                transform.size[0] = MainObjects.Blueprint.m_scaleBoxBp.m_xLen;
                transform.size[1] = MainObjects.Blueprint.m_scaleBoxBp.m_yLen;
                transform.size[2] = MainObjects.Blueprint.m_scaleBoxBp.m_zLen;
            }
        }

        transform.rotation = ITransform.radToAngleArray(transform.rotation);
        transform.position = ITransform.mTommArray(transform.position);
        transform.size = ITransform.mTommArray(transform.size);
        transform.dirlength = ITransform.mTommArray(transform.dirlength);
        return transform;
    }
    static setSelectObjectName(name) {
        MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjects(function (obj) {
            obj.userData.m_name = name;
        });

        if (ITransform.f_selectTransformCallback != null) {
            ITransform.f_selectTransformCallback(true);
        }
        if (IScene.f_sceneTreeCallback != null) {
            IScene.f_sceneTreeCallback();
        }
    }

    static setSelectObjectTransform(transform, mode, undo = true) {
        transform.rotation = ITransform.angleToRadArray(transform.rotation);
        transform.position = ITransform.mmTomArray(transform.position);
        transform.size = ITransform.mmTomArray(transform.size);
        transform.dirlength = ITransform.mmTomArray(transform.dirlength);
        transform.move = ITransform.mmTomArray(transform.move);

        var undoData;
        if (undo) {
            undoData = MainObjects.Blueprint.m_selectListenerBp.addUndoRedoTransform(MainObjects.Render.m_outlinePass.selectedObjects);
        }
        var length = MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjectsCount();
        var obj = MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjectFirst();
        if(length > 1)
        {
            obj = MainObjects.Blueprint.m_selectListenerBp.m_currentGroup;
            for (let index = 0; index < length; index++) {
                const obj_c = MainObjects.Render.m_outlinePass.selectedObjects[index];
                if (!obj_c.userData.m_block) {
                    if (obj_c.userData.m_gameObjectType != GameObject.GameObjectType.e_GEOMETRY_CURVE) {
                        obj_c.userData.m_oldParent = obj_c.parent;
                        if(obj_c.id != obj_c.parent.id)
                        {
                            obj.attach(obj_c);
                        }
                    }
                }
            }
        }
        var index = 0;
        if (ITransform.canChange() && !obj.userData.m_block) {

            if(ITransform.ChangeTransformMode.e_POS == mode)
            {
                obj.position.set(
                    ITransform.canSetNumValue(obj.position.x, transform.position[0]),
                    ITransform.canSetNumValue(obj.position.y, transform.position[1]),
                    ITransform.canSetNumValue(obj.position.z, transform.position[2]));
            }

            if(obj.userData.m_gameObjectType == GameObject.GameObjectType.e_GEOMETRY_CURVE)
            {
                MainObjects.Blueprint.m_curveEditorBp.updateCurve(obj.userData.m_parent);
            }
            else
            {
                if(ITransform.ChangeTransformMode.e_ROT == mode)
                {
                    obj.rotation.set(
                        ITransform.canSetNumValue(obj.rotation.x, transform.rotation[0]),
                        ITransform.canSetNumValue(obj.rotation.y, transform.rotation[1]),
                        ITransform.canSetNumValue(obj.rotation.z, transform.rotation[2]));
                }

                if(ITransform.ChangeTransformMode.e_SCAL == mode)
                {
                    obj.scale.set(
                        ITransform.canSetNumValue(obj.scale.x, transform.scale[0]),
                        ITransform.canSetNumValue(obj.scale.y, transform.scale[1]),
                        ITransform.canSetNumValue(obj.scale.z, transform.scale[2]));
                }

                if(ITransform.ChangeTransformMode.e_SIZE == mode)
                {
                    MainObjects.Blueprint.m_scaleBoxBp.setScale(obj, transform.size[0], transform.size[1], transform.size[2]);
                }

                if(ITransform.ChangeTransformMode.e_LENGTH == mode)
                {
                    if (index == 0) {
                        MainObjects.Blueprint.m_scaleBoxBp.setLength(obj, transform.dirlength);
                    }
                }
                
                if(ITransform.ChangeTransformMode.e_MOVE == mode)
                {
                    if(MainObjects.Control.m_transformControl.space == "local")
                    {
                        obj.translateX(transform.move[0]);
                        obj.translateY(transform.move[1]);
                        obj.translateZ(transform.move[2]);
                    }
                    else
                    {
                        obj.position.set( obj.position.x + transform.move[0], obj.position.y + transform.move[1], obj.position.z + transform.move[2]);
                    }
                }
            }
            MainObjects.Blueprint.m_selectListenerBp.updateObject(obj);
            index++;
        }
        if(length > 1)
        {
            for (let index = 0; index < length; index++) {
                const obj_c = MainObjects.Render.m_outlinePass.selectedObjects[index];
                if (!obj_c.userData.m_block) {
                    if (obj_c.userData.m_gameObjectType != GameObject.GameObjectType.e_GEOMETRY_CURVE) {
                        if(obj_c.userData.m_oldParent.id != obj_c.parent.id)
                        {
                            obj_c.userData.m_oldParent.attach(obj_c);
                        }    
                    }
                }
            }
        }
        else
        {
            MainObjects.Blueprint.m_selectListenerBp.setAxis();
        }
        if (undo) {
            MainObjects.Blueprint.m_selectListenerBp.addUndoRedoTransform(MainObjects.Render.m_outlinePass.selectedObjects, undoData, true);
        }

    }

    static canChange() {
        if(MainObjects.Render.m_outlinePass.selectedObjects.length == 0)
        {
            return false; 
        }
        return true;
    }

    static canSetNumValue(src, dst) {
        var b = (typeof dst === 'number' && isFinite(dst));
        if (b) {
            return dst;
        }
        else {
            return src;
        }
    }

    static radToAngle(rad) {
        var b = (typeof rad === 'number' && isFinite(rad));
        if (b) {
            return rad * 180 / Math.PI;
        }
        else {
            return rad;
        }

    }

    static angleToRad(angle) {
        var b = (typeof angle === 'number' && isFinite(angle));
        if (b) {
            return angle * Math.PI / 180;
        }
        else {
            return angle;
        }
    }

    static mTomm(value) {
        return value * 1000;
    }

    static mmTom(value) {
        return value / 1000;
    }

    static mmTomArray(list) {
        var fl = new Array();
        for (var l of list) {
            fl.push(ITransform.mmTom(l));
        }
        return fl;
    }

    static mTommArray(list) {
        var fl = new Array();
        for (var l of list) {
            fl.push(ITransform.mTomm(l));
        }
        return fl;
    }

    static radToAngleArray(list) {
        var fl = new Array();
        for (var l of list) {
            fl.push(ITransform.radToAngle(l));
        }
        return fl;
    }

    static angleToRadArray(list) {
        var fl = new Array();
        for (var l of list) {
            fl.push(ITransform.angleToRad(l));
        }
        return fl;
    }

    static equalFloat(a, b) {
        if (Math.abs(a - b) < 0.001) {
            return true;
        }
        else {
            return false;
        }
    }

    static setInstanceType(mode)
    {
        MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjects(function (obj) {
            obj.userData.m_instanceType = mode;
            obj.traverse(function(chird){
                if(chird.userData != undefined && chird.userData.m_instanceType != undefined)
                {
                    chird.userData.m_instanceType = mode;
                }
            });
        });

        if (ITransform.f_selectTransformCallback != null) {
            ITransform.f_selectTransformCallback(true);
        }
    }

    static setLayer(value)
    {
        MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjects(function (obj) {
            obj.userData.m_layers = value;
            obj.traverse(function(chird){
                if(chird.userData != undefined)
                {
                    chird.userData.m_layers = value;
                }
            });
        });

        if (ITransform.f_selectTransformCallback != null) {
            ITransform.f_selectTransformCallback(true);
        }
    }
}

export { ITransform };