import { MutilTextBehavior } from "../behavior/MutilTextBehavior";
import { MainObjects } from "../common/MainObjects";
import { ObjectsRepos } from "../factory/ObjectsRepos";
import { IBehavior } from "../interface/IBehavior";
import * as THREE from 'three';
import { CurveBehavior } from "../behavior/CurveBehavior";
import { HudBehavior } from "../behavior/HudBehavior";
import { TerrainData } from "../data/TerrainData";
import { WaterData } from "../data/WaterData";
import { NavMeshBehavior } from "../behavior/NavMeshBehavior";
import { TextureBehavior } from "../behavior/TextureBehavior";
import { DymaticBehavior } from "../behavior/DymaticBehavior";
import { ViewData } from "../data/ViewData";
import {ParticleSystemData} from "@/threejs/data/ParticleSystemData";
import { MapBehavior } from "../behavior/MapBehavior";

class GameObject {
    static GameObjectType =
        {
            e_DEFULT: 0,
            e_GROUP: 1,
            e_MESH: 2,
            e_DIR_LIGHT: 3,
            e_POINT_LIGHT: 4,
            e_SPOT_LIGHT: 5,
            e_AREA_LIGHT: 6,
            e_CAMERA_PERSP: 7,
            e_CAMERA_ORTHO: 8,
            e_OBJECT: 9,
            e_GLTF: 10,
            e_GLTF_MESH: 11,
            e_CURVE: 12,
            e_LOD: 13,
            e_GEOMETRY_CURVE: 14,
            e_SELECT_HELPER_BOX: 15,
            e_CAMERA_EQUIRECT: 16,
            e_AUDIO: 17,
            e_SKY: 18,
            e_LENS_FLARE: 19,
            e_COLLISION_LAND: 20,
            e_REAL_TIME_SKY: 21,
            e_SKY_DIR_LIGHT: 22,
            e_PARTICLE_SYS: 23,
            e_TERRAIN_SYS: 24,
            e_WATER_BASE: 25,
            e_PARTICLE_SYS_EMITTER: 26,
        };
    static MeshType =
        {
            e_GEOMETRY_BOX: 0,
            e_GEOMETRY_SPHERE: 1,
            e_GEOMETRY_CYLINDER: 2,
            e_GEOMETRY_CONE: 3,
            e_GEOMETRY_TORUS: 4,
            e_GEOMETRY_PLANE: 5,
            e_GEOMETRY_RING: 6,
        };
    static InstanceType =
        {
            e_DYNAMIC_INSTANCE: 1,
            e_STATIC_INSTANCE: 2
        }
    constructor() {
        this.m_id = "-";
        this.m_name = "-";
        this.m_url = "-";
        this.m_instanceType = GameObject.InstanceType.e_STATIC_INSTANCE;
        this.m_gameObjectType = GameObject.GameObjectType.e_DEFULT;
        this.m_meshType = GameObject.MeshType.e_GLTF;
        this.m_block = false;
        this.m_layers = 0;

        this.m_currentAnimationName = "";
        this.m_skeleton_id = null;
        this.m_skeleton_name = null;
        this.m_bPlay = true;

        this.m_w = 1920;
        this.m_h = 1080;
        this.m_mainCamera = false;
        this.m_helper_visible = true;
        this.m_lightRealtime = false;

        this.m_arcSegments = 200;
        this.m_group_center = "NoSet";

        this.m_animationMixer = null;
        this.m_animationAction = new Map();
        this.m_skeleton = null;
        this.m_gltf = null;
        this.m_gltf_meshList = new Array();
        this.m_positionList = new Array();

        this.m_gameLightCameraHelper = null;
        this.m_gameObjectHelper = null;
        this.m_boudingBoxHelper = null;
        this.m_oldParent = null;
        this.m_parent = null;
        this.m_curve = null;
        this.m_sound = null;
        //CollisionsType
        this.m_collisionsType = 0;
        this.m_mass = 0; //kg
        this.m_gravity = -9.81; 
        this.m_collisionsMesh = null;
        this.m_offset = new THREE.Vector3();
        this.m_size = new THREE.Vector3();
        this.m_collisionFlags = 0;
        this.m_angularFactor = new THREE.Vector3();
        this.m_friction = 0;
        //Behavior
        this.m_behavior = new ObjectsRepos();
        //REAL TIME SKY
        this.m_skyScalar = 10000;
        this.m_skyLightRadius= 10;
        this.m_currentSkyTime = 12;
        this.m_dayTime = [7, 17]//24  
        this.m_azimuth = 180;
        //felr
        this.m_dom_List = new Array();
        //Particle
        this.m_particleSystem = null;
        this.m_particleSystem_data = new ParticleSystemData();

        //Terrain
        this.m_terrain_data = new TerrainData();

        //Water
        this.m_water_data = new WaterData();

        //View
        this.m_view_data = new ViewData();
    }

    getGameObjectJson() {
        if (this.m_gameObjectHelper != null) {
            this.m_helper_visible = this.m_gameObjectHelper.visible;
        }
        var data = {
            m_id: this.m_id,
            m_name: this.m_name,
            m_url: this.m_url,
            m_instanceType : this.m_instanceType,
            m_layers : this.m_layers,
            m_gameObjectType: this.m_gameObjectType,
            m_meshType: this.m_meshType,
            m_block: this.m_block,
            m_currentAnimationName: this.m_currentAnimationName,
            m_bPlay: this.m_bPlay,
            m_timeScale: (this.m_animationMixer == null) ? 1 : this.m_animationMixer.timeScale,
            m_w: this.m_w,
            m_h: this.m_h,
            m_mainCamera: this.m_mainCamera,
            m_helper_visible: this.m_helper_visible,
            m_arcSegments: this.m_arcSegments,
            m_group_center: this.m_group_center,
            m_skeleton_name: this.m_skeleton_name
        }
        for(var [key, value] of this.m_behavior.m_objectsMap)
        {
            if(key == IBehavior.m_mutilTextBehavior)
            {
                data.m_mutilTextBehavior = value.getJson();
            }
            else if(key == IBehavior.m_curveBehavior)
            {
                data.m_curveBehavior = value.getJson();
            }
            else if(key == IBehavior.m_hudBehavior)
            {
                data.m_hudBehavior = value.getJson();
            }
            else if(key == IBehavior.m_navMeshBehavior)
            {
                data.m_navMeshBehavior = value.getJson();
            }
            else if(key == IBehavior.m_textureBehavior)
            {
                data.m_textureBehavior = value.getJson();
            }
            else if(key == IBehavior.m_dymaticBehavior)
            {
                data.m_dymaticBehavior = value.getJson();
            }
            else if(key == IBehavior.m_mapBehavior)
            {
                data.m_mapBehavior = value.getJson();
            }
        }
        return data;
    }

    setGameObjectJson(json, object) {
        this.m_id = json.m_id;
        this.m_name = json.m_name;
        this.m_url = json.m_url;
        if(json.m_instanceType != undefined)
        {
            this.m_instanceType = json.m_instanceType;
        }
        if(json.m_layers!= undefined)
        {
            this.m_layers = json.m_layers;
        }
        this.m_gameObjectType = json.m_gameObjectType;
        this.m_meshType = json.m_meshType;
        this.m_block = json.m_block;
        this.m_bPlay = json.m_bPlay;
        this.m_w = json.m_w;
        this.m_h = json.m_h;
        this.m_mainCamera = json.m_mainCamera;
        this.m_helper_visible = json.m_helper_visible;
        this.m_arcSegments = json.m_arcSegments;

        if (json.m_skeleton_name != undefined) {
            this.m_skeleton_name = json.m_skeleton_name;
        }

        if (json.m_group_center != undefined) {
            this.m_group_center = json.m_group_center;
        }

        if (this.m_gameObjectHelper != null) {
            this.m_gameObjectHelper.visible = this.m_helper_visible;
        }
        if (this.m_animationMixer != null) {
            var startAction = this.m_animationAction.get(this.m_currentAnimationName);
            var endAction = this.m_animationAction.get(json.m_currentAnimationName);
            MainObjects.Blueprint.m_animationManagerBp.synchronizeCrossFade(startAction, endAction, 0);
            this.m_currentAnimationName = json.m_currentAnimationName;
            this.m_animationMixer.timeScale = json.m_timeScale;
            if (this.m_bPlay) {
                for (var [name, action] of this.m_animationAction) {
                    action.paused = false;
                }
            }
            else {
                for (var [name, action] of this.m_animationAction) {
                    action.paused = true;
                }
            }
        }
        if(json.m_mutilTextBehavior != undefined && json.m_mutilTextBehavior != {})
        {
            this.m_behavior.add(IBehavior.m_mutilTextBehavior, new MutilTextBehavior())
            this.m_behavior.get(IBehavior.m_mutilTextBehavior).setJson(json.m_mutilTextBehavior);
        }
        if(json.m_curveBehavior != undefined && json.m_curveBehavior != {})
        {
            this.m_behavior.add(IBehavior.m_curveBehavior, new CurveBehavior())
            this.m_behavior.get(IBehavior.m_curveBehavior).setJson(json.m_curveBehavior, true);
        }
        if(json.m_hudBehavior != undefined && json.m_hudBehavior != {})
        {
            this.m_behavior.add(IBehavior.m_hudBehavior, new HudBehavior())
            this.m_behavior.get(IBehavior.m_hudBehavior).setJson(json.m_hudBehavior);
            MainObjects.Blueprint.m_behaviorBp.addBehavior(object);
        }
        if(json.m_navMeshBehavior != undefined && json.m_navMeshBehavior != {})
        {
            this.m_behavior.add(IBehavior.m_navMeshBehavior, new NavMeshBehavior())
            this.m_behavior.get(IBehavior.m_navMeshBehavior).setJson(json.m_navMeshBehavior);
        }
        if(json.m_textureBehavior != undefined && json.m_textureBehavior != {})
        {
            this.m_behavior.add(IBehavior.m_textureBehavior, new TextureBehavior())
            this.m_behavior.get(IBehavior.m_textureBehavior).setJson(json.m_textureBehavior);
            MainObjects.Blueprint.m_behaviorBp.addBehavior(object)
        }
        if(json.m_dymaticBehavior != undefined && json.m_dymaticBehavior != {})
        {
            this.m_behavior.add(IBehavior.m_dymaticBehavior, new DymaticBehavior())
            this.m_behavior.get(IBehavior.m_dymaticBehavior).setJson(json.m_dymaticBehavior);
            MainObjects.Blueprint.m_behaviorBp.addBehavior(object)
        }
        if(json.m_mapBehavior != undefined && json.m_mapBehavior != {})
        {
            this.m_behavior.add(IBehavior.m_mapBehavior, new MapBehavior())
            this.m_behavior.get(IBehavior.m_mapBehavior).setJson(json.m_mapBehavior, false);
            MainObjects.Blueprint.m_behaviorBp.addBehavior(object)
        }
    }

    getTreeData(object) {
        var data = {
            id: object.id,
            name: this.m_name,
            type: this.m_gameObjectType,
            object: object,
            block: this.m_block,
            bvisible: object.visible,
            canLock: true,
            canRename: true,
            canDrag: true,
            canCreateGroup: true,
            bMainCamera: this.m_mainCamera
        }
        if (object.isLight) {
            data.bvisible = this.m_gameObjectHelper.visible;
        }
        var listChild = new Array();
        for (let index = 0; index < this.m_gltf_meshList.length; index++) {
            const element = this.m_gltf_meshList[index];
            var dataChild = element.userData.getTreeData(element);
            dataChild.canLock = true;
            dataChild.canRename = true;
            dataChild.canDrag = false;
            dataChild.canCreateGroup = false;
            listChild.push(dataChild);
        }
        if (this.m_gameObjectType == GameObject.GameObjectType.e_CURVE) {
            data.canDrag = false;
        }

        if (listChild.length > 0) {
            data.children = listChild;
        }
        return data;
    }

    getObjectJson(object) {
        var data = {
            parentName: object.parent.name,
            name: object.name,
            castShadow: object.castShadow,
            receiveShadow: object.receiveShadow,
            renderOrder: object.renderOrder,
            position: [0, 0, 0],
            rotation: [0, 0, 0],
            scale: [0, 0, 0],
            visible: object.visible
        }
        object.position.toArray(data.position);
        object.rotation.toArray(data.rotation);
        object.scale.toArray(data.scale);
        return data;
    }

    setObjectJson(object, data) {
        object.castShadow = data.castShadow;
        object.receiveShadow = data.receiveShadow;
        object.renderOrder = data.renderOrder;
        object.position.fromArray(data.position);
        object.rotation.fromArray(data.rotation);
        object.scale.fromArray(data.scale);
        object.visible = data.visible;
    }

    getLightJson(object) {
        var lightData = {
            color: [0, 0, 0],
            intensity: 1,
            distance: 0,
            angle: Math.PI / 2,
            width: 10,
            height: 10,
            m_lightRealtime: object.visible,
        }
        var b = false;
        if (this.m_gameObjectType == GameObject.GameObjectType.e_DIR_LIGHT 
            || this.m_gameObjectType == GameObject.GameObjectType.e_SKY_DIR_LIGHT) {
            object.color.toArray(lightData.color);
            lightData.intensity = object.intensity;
            lightData.shadow = this.getLightShadowJson(object);
            b = true;
        }
        else if (this.m_gameObjectType == GameObject.GameObjectType.e_POINT_LIGHT) {
            object.color.toArray(lightData.color);
            lightData.intensity = object.intensity;
            lightData.decay = object.decay;
            lightData.distance = object.distance;

            lightData.shadow = this.getLightShadowJson(object);
            b = true;
        }
        else if (this.m_gameObjectType == GameObject.GameObjectType.e_SPOT_LIGHT) {
            object.color.toArray(lightData.color);
            lightData.intensity = object.intensity;
            lightData.distance = object.distance;
            lightData.angle = object.angle;

            lightData.shadow = this.getLightShadowJson(object);
            b = true;
        }
        else if (this.m_gameObjectType == GameObject.GameObjectType.e_AREA_LIGHT) {
            object.color.toArray(lightData.color);
            lightData.intensity = object.intensity;
            lightData.width = object.width;
            lightData.height = object.height;
            b = true;
        }
        if (b) {
            return lightData;
        } else {
            return null;
        }
    }

    setLightJson(object, lightData) {
        if(!lightData){
           return;
        }
        object.color.fromArray(lightData.color);
        object.intensity = lightData.intensity;
        if (this.m_gameObjectType == GameObject.GameObjectType.e_POINT_LIGHT) {
            object.distance = lightData.distance;
            this.setLightShadowJson(object, lightData.shadow)
        }
        else if (this.m_gameObjectType == GameObject.GameObjectType.e_SPOT_LIGHT) {
            object.distance = lightData.distance;
            object.angle = lightData.angle;
            this.setLightShadowJson(object, lightData.shadow)
        }
        else if (this.m_gameObjectType == GameObject.GameObjectType.e_AREA_LIGHT) {
            object.width = lightData.width;
            object.height = lightData.height;
        }
        else if(this.m_gameObjectType == GameObject.GameObjectType.e_DIR_LIGHT 
            || this.m_gameObjectType == GameObject.GameObjectType.e_SKY_DIR_LIGHT) {
            this.setLightShadowJson(object, lightData.shadow)
        }
        object.visible = lightData.m_lightRealtime;
        this.m_lightRealtime = object.visible;
    }
    
    getLightShadowJson(object) {
        var data = {};
        var shadow = object.shadow;
        if(shadow != undefined)
        {
            data.mapSize_width = shadow.mapSize.width;
            data.mapSize_height  = shadow.mapSize.height;

            data.camera_near  = shadow.camera.near;
            data.camera_far  = shadow.camera.far;

            data.camera_left  = shadow.camera.left;
            data.camera_right  = shadow.camera.right;
            data.camera_top  = shadow.camera.top;
            data.camera_bottom = shadow.camera.bottom;

            if(shadow.isSpotLightShadow)
            {
                //聚焦 0.01[0, 1]
                data.focus = shadow.focus;
            }
            //偏差step 0.0001[-0.01, 0.01]
            data.bias = shadow.bias;
            //偏移 0.0001[-0.01, 0.01]
            data.normalBias = shadow.normalBias;
            //半径 0.001[1, 5]
            data.radius = shadow.normalBias;
            //模糊 1[1, 10]
            data.blurSamples = shadow.blurSamples;

            var helper = object.userData.m_gameObjectHelper;
            if(helper != null)
            {
                data.camera_visible = helper.visible;
            }
            
        }
        return data;
    }

    setLightShadowJson(object, data)
    {
        if(object.shadow != undefined && data != undefined)
        {
            object.shadow.mapSize.width = data.mapSize_width;
            object.shadow.mapSize.height = data.mapSize_height;

            object.shadow.camera.near = data.camera_near;
            object.shadow.camera.far = data.camera_far;

            object.shadow.camera.left = data.camera_left;
            object.shadow.camera.right = data.camera_right;
            object.shadow.camera.top = data.camera_top;
            object.shadow.camera.bottom = data.camera_bottom;

            if( object.shadow.isSpotLightShadow)
            {
                //聚焦 0.01[0, 1]
                object.shadow.focus = data.focus;
            }
            //偏差step 0.0001[-0.01, 0.01]
            object.shadow.bias = data.bias;
            //偏移 0.0001[-0.01, 0.01]
            object.shadow.normalBias = data.normalBias;
            //半径 0.001[1, 5]
            object.shadow.radius = data.radius;
            //模糊 1[1, 10]
            object.shadow.blurSamples = data.blurSamples;
            
            var helper = object.userData.m_gameObjectHelper;
            if(helper != null)
            {
                helper.visible = data.camera_visible;
            }  
        }
    }

    getCameraJson(object) {
        var cameraData = {
            far: 1,
            near: 0.1,
            zoom: 1,
            fov: 50,
            view_data: object.userData.m_view_data.getJson()
        }
        var b = false;
        if (this.m_gameObjectType == GameObject.GameObjectType.e_CAMERA_PERSP || this.m_gameObjectType == GameObject.GameObjectType.e_CAMERA_EQUIRECT) {
            cameraData.far = object.far;
            cameraData.near = object.near;
            cameraData.zoom = object.zoom;

            cameraData.fov = object.fov;
            b = true;
        }
        else if (this.m_gameObjectType == GameObject.GameObjectType.e_CAMERA_ORTHO) {
            cameraData.far = object.far;
            cameraData.near = object.near;
            cameraData.zoom = object.zoom;
            b = true;
        }
        if (b) {
            console.log(cameraData);
            return cameraData;
        } else {
            return null;
        }
    }

    setCameraJson(object, cameraData) {
        if (this.m_gameObjectType == GameObject.GameObjectType.e_CAMERA_PERSP) {
            object.far = cameraData.far;
            object.near = cameraData.near;
            object.zoom = cameraData.zoom;
            object.fov = cameraData.fov;
        }
        else if (this.m_gameObjectType == GameObject.GameObjectType.e_CAMERA_ORTHO) {
            object.far = cameraData.far;
            object.near = cameraData.near;
            object.zoom = cameraData.zoom;
        }
        if(cameraData.view_data){
            object.userData.m_view_data.setJson(cameraData.view_data);
        }

    }

    setBehavior(key)
    {
        if(!this.m_behavior.get(key))
        {
            if(key == IBehavior.m_mutilTextBehavior)
            {
                this.m_behavior.add(key, new MutilTextBehavior())
            }
            else if(key == IBehavior.m_curveBehavior)
            {
                this.m_behavior.add(key, new CurveBehavior())
            }
            else if(key == IBehavior.m_hudBehavior)
            {
                this.m_behavior.add(key, new HudBehavior())
            }
            else if(key == IBehavior.m_navMeshBehavior)
            {
                this.m_behavior.add(key, new NavMeshBehavior())
            }
            else if(key == IBehavior.m_textureBehavior)
            {
                this.m_behavior.add(key, new TextureBehavior())
            }
            else if(key == IBehavior.m_dymaticBehavior)
            {
                this.m_behavior.add(key, new DymaticBehavior())
            }
            else if(key == IBehavior.m_mapBehavior)
            {
                this.m_behavior.add(key, new MapBehavior())
            }
        }
    }

    removeBehavior(key)
    {
        if(this.m_behavior.get(key))
        {
            this.m_behavior.get(key).destory();
            this.m_behavior.remove(key);
        }
     

    }

    initBehavior(object)
    {
        for(var [key, value] of this.m_behavior.m_objectsMap)
        {
            value.init(object);
        }
    }
}

export { GameObject };