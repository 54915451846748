<template>
    <div>
        <div class="box" :class="isActive?'anmDiv':'aaa'">
           <div class="header">
               <div @click="shrinkChange"><i class="el-icon-arrow-down"></i> </div>
               <p>碰撞器</p>
           </div>
           <div class="header_type">
               <p>类型</p>
               <div class="ipt_box">
                   <!-- <select class="select" v-model="select" @change="selectChange()">
                        <option v-for="(item,index) in optionList" :key="index">{{item}} </option>
                    </select> -->
                    <el-select class="select" @change="selectChange()" v-model="select" placeholder="请选择">
                        <el-option
                            v-for="item in optionList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
               </div>
           </div>
           <div class="adjust" v-show="capsule">
               <div class="txt">
                   <div>参数设置</div>
               </div>
               <div class="header_type">
                    <p>物理类型</p>
                    <div class="ipt_box">
                        <el-select class="select" @change="CollisionChange()" v-model="collisionSelect" placeholder="请选择">
                            <el-option
                                v-for="item in CollisionList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div>
               <div class="loction1">
                   <div class="title">相对坐标</div>
                   <div class="x-axis axis">
                       <p>X</p>
                       <input v-model="positionX" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @change="changeNum($event)"  @focus="Focus" @blur="Blur" />
                   </div>
                   <div class="y-axis axis">
                       <p>Y</p>
                       <input v-model="positionY" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @change="changeNum($event)"  @focus="Focus" @blur="Blur"/>
                   </div>
                   <div class="z-axis axis">
                       <p>Z</p>
                       <input v-model="positionZ" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @change="changeNum($event)"  @focus="Focus" @blur="Blur"/>
                   </div>
               </div>
               <div class="loction1">
                   <div class="title">角度摩擦</div>
                   <div class="x-axis axis">
                       <p>X</p>
                       <input v-model="angularFactorX" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @change="frictionNum($event)"  @focus="Focus" @blur="Blur" />
                   </div>
                   <div class="y-axis axis">
                       <p>Y</p>
                       <input v-model="angularFactorY" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @change="frictionNum($event)"  @focus="Focus" @blur="Blur"/>
                   </div>
                   <div class="z-axis axis">
                       <p>Z</p>
                       <input v-model="angularFactorZ" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @change="frictionNum($event)"  @focus="Focus" @blur="Blur"/>
                   </div>
                </div>
                <div class="shadow">
                    <div class="title">摩擦</div>
                    <div class="txt_box">
                        <input type="number" v-model="friction"  @change="frictionNum()" @focus="Focus" @blur="Blur">
                    </div>
                </div>
                <div class="shadow">
                    <div class="title">半径</div>
                    <div class="txt_box">
                        <input type="number" v-model="radius"  @change="changeNum()" @focus="Focus" @blur="Blur">
                    </div>
                </div>
                <div class="shadow">
                    <div class="title">高度</div>
                    <div class="txt_box">
                        <input type="number" v-model="height"  @change="changeNum()" @focus="Focus" @blur="Blur">
                    </div>
                </div>
                <div class="shadow">
                    <div class="title">重力</div>
                    <div class="txt_box">
                        <input type="number" v-model="gravity" @change="frictionNum()" @focus="Focus" @blur="Blur">
                    </div>
                </div>
                <div class="shadow">
                    <div class="title">质量</div>
                    <div class="txt_box">
                        <input type="number" v-model="quality"  @change="changeNum()" @focus="Focus" @blur="Blur">
                    </div>
                </div>
           </div>
           <div  class="terrain" v-show="terrain">
                <div class="txt">
                   <div>参数设置</div>
               </div>
               <div class="header_type">
                    <p>物理类型</p>
                    <div class="ipt_box">
                        <span>{{ptyType}}</span>
                    </div>
                </div>
           </div>
           <div  class="terrain" v-show="ballState">
                <div class="txt">
                   <div>参数设置</div>
                </div>
                <div class="header_type">
                    <p>物理类型</p>
                    <div class="ipt_box">
                        <span>{{ ptyType }}</span>
                    </div>
                </div>
           </div>
           <div  class="terrain" v-show="terrainBox">
                <div class="txt">
                   <div>参数设置</div>
               </div>
                <div class="shadow">
                    <div class="title">碰撞盒</div>
                    <div class="ipt_box">
                        <input class="input" type="checkbox" @change="TerrainBoxChange($event)" v-model="CollisionBox" />
                    </div>
                </div>
           </div>
           <div  class="terrain" v-show="combination">
                <div class="txt">
                   <div>参数设置</div>
               </div>
               <div class="header_type">
                    <p>物理类型</p>
                    <div class="ipt_box">
                        <el-select class="select" @change="CollisionChange()" v-model="collisionSelect" placeholder="请选择">
                            <el-option
                                v-for="item in CollisionList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="loction1">
                   <div class="title">相对坐标</div>
                   <div class="x-axis axis">
                       <p>X</p>
                       <input v-model="positionX" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @change="changeNum($event)"  @focus="Focus" @blur="Blur" />
                   </div>
                   <div class="y-axis axis">
                       <p>Y</p>
                       <input v-model="positionY" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @change="changeNum($event)"  @focus="Focus" @blur="Blur"/>
                   </div>
                   <div class="z-axis axis">
                       <p>Z</p>
                       <input v-model="positionZ" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @change="changeNum($event)"  @focus="Focus" @blur="Blur"/>
                   </div>
               </div>
                <div class="loction1">
                   <div class="title">尺寸</div>
                   <div class="x-axis axis">
                       <p>X</p>
                       <input v-model="sizePositionX" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @change="changeNum($event)"  @focus="Focus" @blur="Blur" />
                   </div>
                   <div class="y-axis axis">
                       <p>Y</p>
                       <input v-model="sizePositionY" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @change="changeNum($event)"  @focus="Focus" @blur="Blur"/>
                   </div>
                   <div class="z-axis axis">
                       <p>Z</p>
                       <input v-model="sizePositionZ" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @change="changeNum($event)"  @focus="Focus" @blur="Blur"/>
                   </div>
               </div>
               <div class="loction1">
                   <div class="title">角度摩擦</div>
                   <div class="x-axis axis">
                       <p>X</p>
                       <input v-model="angularFactorX" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @change="frictionNum($event)"  @focus="Focus" @blur="Blur" />
                   </div>
                   <div class="y-axis axis">
                       <p>Y</p>
                       <input v-model="angularFactorY" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @change="frictionNum($event)"  @focus="Focus" @blur="Blur"/>
                   </div>
                   <div class="z-axis axis">
                       <p>Z</p>
                       <input v-model="angularFactorZ" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @change="frictionNum($event)"  @focus="Focus" @blur="Blur"/>
                   </div>
                </div>
                <div class="shadow">
                    <div class="title">摩擦</div>
                    <div class="txt_box">
                        <input type="number" v-model="friction"  @change="frictionNum()" @focus="Focus" @blur="Blur">
                    </div>
                </div>
               <div class="shadow">
                    <div class="title">质量</div>
                    <div class="txt_box">
                        <input type="number" v-model="quality" @change="changeNum()" @focus="Focus" @blur="Blur">
                    </div>
                </div>
                <div class="shadow">
                    <div class="title">重力</div>
                    <div class="txt_box">
                        <input type="number" v-model="gravity" @change="frictionNum()" @focus="Focus" @blur="Blur">
                    </div>
                </div>
           </div>
        </div>
    </div>
</template>
<script>
import { fstat } from "fs";
import M from "minimatch";
import {ICollisions} from "../../threejs/interface/ICollisions"
import { ISetting } from "../../threejs/interface/ISetting";
export default {
    data(){
        return{
            ptyType:"",//物理类型
            CollisionList:[
                {
                    value:0,
                    label:"动态"
                },
                {
                    value:1,
                    label:"静态"
                },
                {
                    value:2,
                    label:"运动"
                },
                {
                    value:4,
                    label:"动态触发器"
                },
                {
                    value:5,
                    label:"静态触发器"
                },
                {
                    value:6,
                    label:"运动触发器"
                }
            ],
            optionList:[
                {
                    value:0,
                    label:"无"
                },
                {
                    value:1,
                    label:"网格碰撞器"
                },
                {
                    value:2,
                    label:"胶囊碰撞器"
                },
                {
                    value:3,
                    label:"包围盒碰撞器"
                },
                {
                    value:4,
                    label:"盒碰撞器"
                },
            ],
            select:'',
            collisionSelect:'',
            simulation:false,//模拟
            CollisionBox:false,//碰撞盒
            radius:'',//半径
            height:"",//高度
            gravity:"",//重力
            quality:"",//质量
            capsule:false,
            terrain:false,
            ballState:false,
            terrainBox:false,
            combination:false,
            isActive:true,
            start:'',
            end:'',
            selectType:'',
            positionX:"",
            positionY:"",
            positionZ:"",
            sizePositionX:'',
            sizePositionY:'',
            sizePositionZ:'',
            angularFactorX:'',//角度摩擦
            angularFactorY:'',
            angularFactorZ:'',
            friction:'',
        }
    },
    mounted(){
        // this.select = this.optionList[0]
    },
    methods:{
        getCollisionsList(){
            let getCollisions = ICollisions.getCollisions();
            // console.log("11",getCollisions)
            this.radius = (getCollisions.radius).toFixed(3);
            this.height = getCollisions.height.toFixed(2);
            this.positionX = (Math.round(getCollisions.center[0]*100)/100).toFixed(3);
            this.positionY =(Math.round(getCollisions.center[1]*100)/100).toFixed(3);
            this.positionZ = (Math.round(getCollisions.center[2]*100)/100).toFixed(3);
            this.sizePositionX = (Math.round(getCollisions.size[0]*100)/100).toFixed(3);
            this.sizePositionY = (Math.round(getCollisions.size[1]*100)/100).toFixed(3);
            this.sizePositionZ = (Math.round(getCollisions.size[2]*100)/100).toFixed(3);
            this.angularFactorX = (Math.round(getCollisions.angularFactor[0]*100)/100).toFixed(3);
            this.angularFactorY = (Math.round(getCollisions.angularFactor[1]*100)/100).toFixed(3);
            this.angularFactorZ = (Math.round(getCollisions.angularFactor[2]*100)/100).toFixed(3);
            this.friction = getCollisions.friction;
            this.CollisionBox  = getCollisions.bHelper;
            this.gravity = getCollisions.gravity;
            this.selectType = getCollisions.type;
            this.quality = getCollisions.mass.toFixed(2);
            if(getCollisions.collisionFlags == 0){
                this.collisionSelect = this.CollisionList[0].value;
            }else if(getCollisions.collisionFlags == 1){
                this.collisionSelect = this.CollisionList[1].value;
                this.ptyType = "静态"
            }else if(getCollisions.collisionFlags == 2){
                this.collisionSelect = this.CollisionList[2].value;
            }else if(getCollisions.collisionFlags == 4){
                this.collisionSelect = this.CollisionList[3].value;
            }else if(getCollisions.collisionFlags == 5){
                this.collisionSelect = this.CollisionList[4].value;
            }else if(getCollisions.collisionFlags == 6){
                this.collisionSelect = this.CollisionList[5].value;
            }
            let type = getCollisions.type;
            if(type == 0){
                this.select = this.optionList[0].value
                this.capsule = false;
                this.terrain = false;
                this.ballState = false;
                this.terrainBox = false;
                this.combination = false;
            }
            else if(type == 1){
                this.select = this.optionList[1].value
                this.capsule = false;
                this.terrain = true;
                this.ballState = false;
                this.terrainBox = false;
                this.combination = false;
            }
            else if(type == 2){
                this.select = this.optionList[2].value;
                this.capsule = true;
                this.terrain = false;
                this.ballState = false;
                this.terrainBox = false;
                this.combination = false;
            }
            else if(type == 3){
                this.select = this.optionList[3].value;
                this.ballState = true;
                this.capsule = false;
                this.terrain = false;
                this.terrainBox = false;
                this.combination = false;
            }
            else if(type == 4){
                this.select = this.optionList[4].value;
                this.ballState = false;
                this.capsule = false;
                this.terrain = false;
                this.terrainBox = false;
                this.combination = true;
            }
        },
        shrinkChange(){
            if (this.isActive) {
              this.isActive = false
            } else {
              this.isActive = true;
            }
        },
        selectChange(){
            // console.log(this.select)
            if(this.select == 1){
                ICollisions.setCollisionsType(1)
                this.capsule = false;
                this.terrain = true;
                this.ballState = false;
                this.terrainBox = false;
                this.combination = false;
            }
            else if(this.select == 2){
                ICollisions.setCollisionsType(2)
                this.capsule = true;
                this.terrain = false;
                this.ballState = false;
                this.terrainBox = false;
                this.combination = false;
            }
            else if(this.select == 3){
                this.ballState = true;
                this.capsule = false;
                this.terrain = false;
                this.terrainBox = false;
                this.combination = false;
                ICollisions.setCollisionsType(3)
            }
            else if(this.select == 4){
                this.terrainBox = false;
                this.ballState = false;
                this.capsule = false;
                this.terrain = false;
                this.combination = true;
                ICollisions.setCollisionsType(4)
            }
            else{
                ICollisions.setCollisionsType(0)
                this.ballState = false;
                this.capsule = false;
                this.terrain = false;
                this.terrainBox = false;
                this.combination = false;
            }
            this.getCollisionsList();
        },
        CollisionChange(){
            let data = {
                angularFactor:[Number(this.angularFactorX),Number(this.angularFactorY),Number(this.angularFactorZ)],
                gravity:Number(this.gravity),
                collisionFlags:Number(this.collisionSelect),
                friction:Number(this.friction)
            }
            // console.log(data)
            ICollisions.setCollisionsPhyData(data)
        },
        changeNum(){
            let data = {
                size:[Number(this.sizePositionX),Number(this.sizePositionY),Number(this.sizePositionZ)],
                center:[Number(this.positionX),Number(this.positionY),Number(this.positionZ)],
                radius:Number(this.radius),
                mass:Number(this.quality),
                height:Number(this.height),
            }
            // console.log(data) 
            ICollisions.setCollisionsBodyProp(data)
        },
        frictionNum(){
            let data = {
                angularFactor:[Number(this.angularFactorX),Number(this.angularFactorY),Number(this.angularFactorZ)],
                gravity:Number(this.gravity),
                collisionFlags:Number(this.collisionSelect),
                friction:Number(this.friction)
            }
            // console.log(data)
            ICollisions.setCollisionsPhyData(data)
        },
        CollisionBoxChange(event){
            ICollisions.setCollisionsHelper(this.CollisionBox)
        },
        TerrainBoxChange(event){
            ICollisions.setCollisionsHelper(this.CollisionBox)
        },
        Focus(){
            ISetting.isInput(true);
        },
        Blur(){
            ISetting.isInput(false);
        },

    }
}
</script>
<style scoped>
.box{
    width: 80%;
    height: 550px;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    border-radius: 5px;
    margin-top:8px;
    margin-left: 70px;
    font-size: 10px;
    background: #ffff;
    overflow:hidden;
}
.anmDiv{
transition: 1S;
 
}
.aaa{
height: 40px;
transition: 1S;
}
.header{
    display: flex;
    width: 90%;
    height: 40px;
    margin: auto;
    align-items: center;
    color: #000;
    font-weight: bold;
    font-size: 12px;
}
.header p{
    margin-left: 10px;
}
.header i{
    font-weight: bold;
    cursor: pointer;
}
.header_type{
    display: flex;
    width: 90%;
    height: 25px;
    margin: 8px auto;
    align-items: center;
}
.header_type .ipt_box{
    width:260px;
    height: 30px;
    box-shadow: 0px 0px 2px 0px rgba(33, 35, 49, 0.1);
    border-radius: 6px;
    /* display: flex;
    align-items: center; */
}
.header_type p{
    color: #000;
    font-weight: bold;
    font-size: 12px;
}
.header_type span{
    color: #808080;
    display: inline-block;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    text-indent: 10px;
}
.header_type .select{
    width: 260px;
    height: 28px;
    outline: none;
    color: #808080;
    border: none;
    border-radius:4px;
    /* margin-left: 10px; */
}
.select /deep/ .el-input{
   width: 260px !important;
   height: 35px !important;
   outline: none !important;
}
.select /deep/ .el-input__inner{
   width: 260px !important;
   height: 28px !important;
   outline: none !important;
}
.select /deep/ .el-select__caret{
  height: 28px !important;
  line-height: 28px !important;
}
.header_type p{
    width: 60px;
    height: 20px;
    line-height: 20px;
    overflow: hidden;
    text-align: center;
}
.adjust{
    width: 100%;
    height: calc(100% - 30px);
    background: #fff;
    margin: 5px auto;
}
.txt{
    width:90%;
    height: 30px;
    line-height: 30px;
    background: #F7F7F7;
    font-weight: bolder;
    text-align: center;
    border-radius: 10px;
    margin:10px auto;
}
.loction{
    width: 90%;
    margin: 20px auto;
    display: flex;
    height: 25px;
    align-items: center;
    /* justify-content: space-around; */
}
.loction .title{
    width: 60px;
    height: 25px;
    background: #fff;
    text-align: center;
    line-height: 25px;
    font-weight: bolder;
    overflow: hidden;
}
.loction .ipt_box{
    width:290px;
    height: 30px;
    box-shadow: 0px 0px 2px 0px rgba(33, 35, 49, 0.1);
    border-radius: 6px;
    display: flex;
    align-items: center;
}
.loction .ipt_box input{
    outline: none;
    height: 25px;
    width: 280px;
    border: none;
    border-radius: 5px;
    color: #808080;
    text-indent: 5px;
}
.shadow{
    display: flex;
    width: 90%;
    height: 20px;
    align-items: center;
    margin: 25px auto;
}
.shadow .title{
    width: 60px;
    height: 20px;
    line-height: 20px;
    background: #fff;
    text-align: center;
    font-weight: bold;
    overflow: hidden;
}
.shadow .txt_box{
    width:160px;
    height: 30px;
    box-shadow: 0px 0px 2px 0px rgba(33, 35, 49, 0.1);
    border-radius: 6px;
    display: flex;
    align-items: center;
    margin-left: 20px;
}
.shadow .txt_box input{
    outline: none;
    height: 25px;
    width: 150px;
    border: none;
    border-radius: 5px;
    color: #808080;
    text-indent: 5px;
}
.input:checked {
	background:#1673ff
}
.input {
	width:16px;
	height:16px;
    outline: none;
	background-color:rgba(125, 128, 133, 0.4);
	border:solid 1px #dddddd;
	-webkit-border-radius:50%;
	border-radius:50%;
	font-size:0.8rem;
	padding:0;
	position:relative;
	display:inline-block;
	vertical-align:top;
	cursor:default;
	-webkit-appearance:none;
	-webkit-user-select:none;
	user-select:none;
	-webkit-transition:background-color ease 0.1s;
	transition:background-color ease 0.1s;
	outline: none;
}
.input:checked::after {
	content:'';
	top:3px;
	left:3px;
	position:absolute;
	background:transparent;
	border:#fff solid 2px;
	border-top:none;
	border-right:none;
	height:4px;
	width:6px;
	-moz-transform:rotate(-45deg);
	-ms-transform:rotate(-45deg);
	-webkit-transform:rotate(-45deg);
	transform:rotate(-45deg);
}
.ipt_box{
    width: 65px;
    height: 25px;
    margin-left: 20px;
    background: #FFFFFF;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.terrain{
    width: 100%;
    height: calc(100% - 30px);
    background: #fff;
    margin: 5px auto;
}
.loction1{
    width: 90%;
    margin: 20px auto;
    display: flex;
    height: 25px;
    align-items: center;
    /* justify-content: space-between; */
    overflow: hidden;
}
.loction1 .title{
    width: 70px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    font-weight: bolder;
    overflow: hidden;
}
.axis{
    width: 25%;
    height: 100%;
    display: flex;
    align-items: center;
    background-color:#F7F7F7;
    border-radius: 5px;
    margin-left: 20px;

}
.axis p{
    width: 22px;
    height: 18px;
    line-height: 20px;
    text-align: center;
    color: #929292;
    background-color: #fff;
    margin-left: 2px;
    border-radius: 4px;
    float: left;
    color: #000000;
    font-size: 12px;
}
.axis input{
    width: 90%;
    height: 18px;
    text-align: center;
    outline: none;
    border: none;
    background-color: #F7F7F7;
    border-radius: 4px;
    font-weight: bold;
    font-size: 8px;
}
.x-axis p{
    background-color:#F20004;
}
.y-axis p{
    background-color: #43BD00;
}
.z-axis p {
    background-color: #5180E9;
}
</style>