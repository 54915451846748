import {
	BackSide,
	SphereGeometry,
	Mesh,
	ShaderMaterial,
	UniformsUtils,
	Vector3,
	Color
} from 'three';

/**
 * Based on "A Practical Analytic Model for Daylight"
 * aka The Preetham Model, the de facto standard analytic skydome model
 * https://www.researchgate.net/publication/220720443_A_Practical_Analytic_Model_for_Daylight
 *
 * First implemented by Simon Wallner
 * http://simonwallner.at/project/atmospheric-scattering/
 *
 * Improved by Martin Upitis
 * http://blenderartists.org/forum/showthread.php?245954-preethams-sky-impementation-HDR
 *
 * Three.js integration by zz85 http://twitter.com/blurspline
*/

class SkySample extends Mesh {

	constructor() {

		const shader = SkySample.SkyShader;

		const material = new ShaderMaterial( {
			name: 'SkyShader',
			fragmentShader: shader.fragmentShader,
			vertexShader: shader.vertexShader,
			uniforms: UniformsUtils.clone( shader.uniforms ),
			side: BackSide,
		} );

		super( new SphereGeometry( 2000, 32, 15 ), material );

		this.isSky = true;
	}

}

SkySample.SkyShader = {

	uniforms : {
		'topColor': { value: new Color( 0x0077ff ) },
		'bottomColor': { value: new Color( 0xffffff ) },
		'offset': { value: 33 },
		'exponent': { value: 0.6 }
	},

	vertexShader: /* glsl */`
	varying vec3 vWorldPosition;
	void main() {

		vec4 worldPosition = modelMatrix * vec4( position, 1.0 );
		vWorldPosition = worldPosition.xyz;

		gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );

	}`,

	fragmentShader: /* glsl */`
	uniform vec3 topColor;
	uniform vec3 bottomColor;
	uniform float offset;
	uniform float exponent;

	varying vec3 vWorldPosition;

	void main() {

		float h = normalize( vWorldPosition + offset ).y;
		gl_FragColor = vec4( mix( bottomColor, topColor, max( pow( max( h , 0.0), exponent ), 0.0 ) ), 1.0 );

	}`

};

export { SkySample };