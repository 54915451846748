<template>
  <div class="header_box">
    <div class="header" v-show="header">
        <p>类型</p>
        <div class="ipt_box">
          {{optionListValue}}
          <!-- <select class="select" v-model="select" @change="selectChange()">
            <option v-for="(item, index) in optionList" :key="index">
              {{ item }}
            </option>
          </select> -->
        </div>
    </div>
    <div class="box" v-show="BaseMaterial">
        <div class="content" :class="isActive?'anmDiv':'aaa'">
          <div class="conColor">
            <div class="title">颜色</div>
            <div class="chartlet_box" style="margin-left:0.32552vw">
              <div class="chartlet"><img :src="map" alt="" /></div>
              <input type="radio" name="1" @click="MapChange($event)" class="input"/>
            </div>
            <el-color-picker v-model="color" @change="ColorApplyChange()" @active-change="activeColor" popper-class="picker"></el-color-picker>
            <div class="import_color">
              <input type="text" v-model="color" @focus="Focus" @blur="Blur" @input="colorImport()">
            </div>
            <div @click="shrinkChange" class="shrink"><i class="el-icon-arrow-down"></i> </div>
            <a title="重置贴图"><div class="reset" @click="ColorResetChange"><img src="../../assets/Property/reset.png" alt=""> </div></a>
          </div>
          <div v-show="loction">
          <div class="loction">
            <div class="title">重复</div>
            <div class="x-axis axis">
              <p>U</p>
              <input type="number" @change="RepeatUvChange($event)" v-model="ColorRepeatU" @focus="Focus" @blur="Blur"/>
            </div>
            <div class="y-axis axis">
              <p>V</p>
                <input type="number" @change="RepeatUvChange($event)" v-model="ColorRepeatV" @focus="Focus" @blur="Blur"/>
              </div>
            </div>
            <div class="loction">
              <div class="title">平移</div>
              <div class="x-axis axis">
                <p>U</p>
                <input type="number" @change="OffsetUvChange($event)" v-model="ColorOffsetU" @focus="Focus" @blur="Blur"/>
              </div>
              <div class="y-axis axis">
                <p>V</p>
                <input type="number" @change="OffsetUvChange($event)" v-model="ColorOffsetV" @focus="Focus" @blur="Blur"/>
              </div>
            </div>
            <div class="loction">
              <div class="title">旋转</div>
              <div class="ipt_box">
                <input type="number" @change="RotationUvChange($event)" v-model="ColorRotation" name="" id="" @focus="Focus" @blur="Blur"/>
              </div>
            </div>
            <div class="loction">
              <div class="title">GAMMA</div>
              <div class="ipt_box">
                <input type="number" @change="colorGammaChange($event)" name="" id="" v-model="colorGamma" @focus="Focus" @blur="Blur">
              </div>
            </div>
          </div>
        </div>
        <div class="illumination">
          <div class="title">光照</div>
          <div class="chartlet_box">
            <div class="chartlet" id="0"><img :src="lightMap" alt="" /></div>
            <input type="radio" class="input" name="1" @click="lightMapChecked($event)"/>
          </div>
          <div class="ipt_box">
            <input type="number" class="ipt" @change="changeNum($event)" v-model="lightMapIntensity" @focus="Focus" @blur="Blur"/>
          </div>
          <a title="重置贴图"><div class="reset" @click="LightResetChange"><img src="../../assets/Property/reset.png" alt=""> </div></a>
        </div>
        <div class="illumination">
          <div class="title">环境</div>
          <div class="chartlet_box">
            <div class="chartlet" id="hdrImg2"><img :src="envMap" alt="" /></div>
            <input type="radio" name="1"  @click="envMapChange($event)" class="input"/>
          </div>
          <div class="ipt_box">
            <input type="number" class="ipt" @change="changeNum($event)" v-model="envMapIntensity" @focus="Focus" @blur="Blur" />
          </div>
          <a title="重置贴图"><div class="reset" @click="envMapResetChange"><img src="../../assets/Property/reset.png" alt=""> </div></a>
        </div>
        <div class="illumination">
          <div class="title">环境遮挡</div>
          <div class="chartlet_box">
            <div class="chartlet" id="2"><img :src="aoMap" alt="" /></div>
            <input type="radio" name="1" @click="aoMapChange($event)" class="input"/>
          </div>
          <div class="ipt_box">
            <input type="number" class="ipt" @change="changeNum($event)" v-model="aoMapIntensity" @focus="Focus" @blur="Blur" />
          </div>
          <a title="重置贴图"><div class="reset" @click="aoMapResetChange"><img src="../../assets/Property/reset.png" alt=""> </div></a>
        </div>
        <div class="illumination">
          <div class="title">高光</div>
          <div class="chartlet_box">
            <div class="chartlet" id="3"><img :src="specularMap" alt="" /></div>
            <input type="radio" name="1" @click="specularMapChange($event)" class="input"/>
          </div>
          <div class="ipt_box">
            <input type="number" class="ipt" @change="changeNum($event)" @focus="Focus" @blur="Blur" />
          </div>
          <a title="重置贴图"><div class="reset" @click="specularResetChange"><img src="../../assets/Property/reset.png" alt=""> </div></a>
        </div>
        <div class="fog">
          <div class="title">雾影响</div> 
          <div class="ipt_box">
            <input type="checkbox" @change="fogChange($event)" class="input" v-model="fog" />
          </div>
        </div>
        <div class="lucency_box">
          <div class="lucency">
            <div class="title">透明</div>
            <div class="ipt_box">
              <input type="checkbox" @change="transparentChange($event)" class="input" v-model="transparent" />
            </div>
          </div>
          <div class="transparency">
            <div class="title">透明度</div>
            <div class="slider">
              <el-slider v-model="opacity" @input="opacityChange()" :min="0" :max="1" :step="0.1" :show-tooltip="false"></el-slider>
            </div>
            <div class="ipt_box">
              <input type="text" readonly="readonly" v-model="opacity" />
            </div>
          </div>
        </div>
        <div class="content" :class="reflectivityActive?'anmDiv':'aaa'">
          <div class="conColor">
            <div class="title">折射率</div>
            <div class="slider">
              <el-slider v-model="refractionRatio" @input="ratioChange()" show-input :min="0" :max="1" :step="0.01" :show-tooltip="false"></el-slider>
            </div>
            <!-- <div class="txt_box1">
              <input type="text" readonly="readonly" v-model="roughness" />
            </div> -->
            <!-- <div @click="reflectivityChange" class="shrink"><i class="el-icon-arrow-down"></i> </div> -->
            <!-- <a title="重置贴图"><div class="reset" @click="roughResetChange"><img src="../../assets/Property/reset.png" alt=""> </div></a> -->
          </div>
        </div>

        <div class="render">
          <div class="title">渲染面</div>
          <div class="ipt_box">
            <!-- <select
              class="select"
              v-model="renderSelect"
              @change="renderChange()"
            >
              <option v-for="(item, index) in renderOptionList" :key="index">
                {{ item }}
              </option>
            </select> -->
            <el-select  @change="renderChange()" class="select" v-model="renderSelect" placeholder="请选择">
              <el-option
                v-for="item in renderOptionList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
    </div>
    <div class="Standard" v-show="StandardMaterial">
        <div class="content" :class="ColorActive?'anmDiv':'aaa'">
          <div class="conColor">
            <div class="title">颜色</div>
            <div class="chartlet_box">
              <div class="chartlet"><img :src="map" alt="" /></div>
              <input type="radio" name="1" @click="MapChange($event)" class="input"/>
            </div>
            <el-color-picker v-model="color" @change="ColorApplyChange()" @active-change="activeColor" popper-class="picker"></el-color-picker>
            <div class="import_color">
              <input type="text" v-model="color" @focus="Focus" @blur="Blur" @input="colorImport()">
            </div>
            <div @click="ColorChange" class="shrink"><i class="el-icon-arrow-down"></i> </div>
            <a title="重置贴图"><div class="reset" @click="ColorResetChange"><img src="../../assets/Property/reset.png" alt=""> </div></a>
          </div>
          <div v-show="loction">
            <div class="loction">
              <div class="title">重复</div>
              <div class="x-axis axis">
                <p>U</p>  
                <input type="number" @change="RepeatUvChange()" v-model="ColorRepeatU" @focus="Focus" @blur="Blur" />
              </div>
              <div class="y-axis axis">
                <p>V</p>
                <input type="number" @change="RepeatUvChange()" v-model="ColorRepeatV" @focus="Focus" @blur="Blur" />
              </div>
            </div>
            <div class="loction">
              <div class="title">平移</div>
              <div class="x-axis axis">
                <p>U</p>
                <input type="number" @change="OffsetUvChange()" v-model="ColorOffsetU" @focus="Focus" @blur="Blur" />
              </div>
              <div class="y-axis axis">
                <p>V</p>
                <input type="number" @change="OffsetUvChange()" v-model="ColorOffsetV" @focus="Focus" @blur="Blur" />
              </div>
            </div>
            <div class="loction">
              <div class="title">旋转</div>
              <div class="ipt_box">
                <input type="number" @change="RotationUvChange($event)" name="" id="" v-model="ColorRotation" @focus="Focus" @blur="Blur">
              </div>
            </div>
            <div class="loction">
              <div class="title">GAMMA</div>
              <div class="ipt_box">
                <input type="number" @change="colorGammaChange($event)" name="" id="" v-model="colorGamma" @focus="Focus" @blur="Blur">
              </div>
            </div>
          </div>
        </div>
        <div class="content" :class="metalActive?'anmDiv':'aaa'">
          <div class="conColor">
            <div class="title">金属度</div>
            <div class="chartlet_box">
              <div class="chartlet" id="0"><img :src="metalnessMap" alt="" /></div>
              <input type="radio" name="1" @click="metalnessMapChange($event)" class="input"/>
            </div>
            <div class="slider">
              <el-slider id="A" v-model="metalness" @input="metalnessChange()" show-input :min="0" :max="1" :step="0.01" :show-tooltip="false"></el-slider>
            </div>
            <!-- <div class="txt_box1">
              <input type="text" readonly="readonly" v-model="metalness" />
            </div> -->
            <div @click="metalChange" class="shrink"><i class="el-icon-arrow-down"></i> </div>
            <a title="重置贴图"><div class="reset" @click="MetaResetChange"><img src="../../assets/Property/reset.png" alt=""> </div></a>
          </div>
          <div v-show="MetaLoction">
              <div class="loction">
              <div class="title">重复</div>
              <div class="x-axis axis">
                <p>U</p>
                <input type="number" @change="RepeatMetaUvChange($event)" v-model="MetalnessRepeatU" @focus="Focus" @blur="Blur"/>
              </div>
              <div class="y-axis axis">
                <p>V</p>
                <input type="number" @change="RepeatMetaUvChange($event)" v-model="MetalnessRepeatV" @focus="Focus" @blur="Blur" />
              </div>
            </div>
            <div class="loction">
              <div class="title">平移</div>
              <div class="x-axis axis">
                <p>U</p>
                <input type="number" @change="OffsetMetaUvChange($event)" v-model="MetalnessOffsetU" @focus="Focus" @blur="Blur"/>
              </div>
              <div class="y-axis axis">
                <p>V</p>
                <input type="number" @change="OffsetMetaUvChange($event)" v-model="MetalnessOffsetV" @focus="Focus" @blur="Blur"/>
              </div>
            </div>
            <div class="loction">
              <div class="title">旋转</div>
              <div class="ipt_box">
                <input type="number" @change="RotationMetaUvChange($event)" name="" id="" v-model="MetalnessRotation" @focus="Focus" @blur="Blur" />
              </div>
            </div>
            <div class="loction">
              <div class="title">GAMMA</div>
              <div class="ipt_box">
                <input type="number" @change="RotationGammaChange($event)" name="" id="" v-model="MetalnessGamma" @focus="Focus" @blur="Blur">
              </div>
            </div>
          </div>
        </div>
        <div class="content" :class="roughActive?'anmDiv':'aaa'">
          <div class="conColor">
            <div class="title">粗糙度</div>
            <div class="chartlet_box">
              <div class="chartlet" id="1"><img :src="roughnessMap" alt="" /></div>
              <input type="radio" name="1" @click="roughnessMapChange($event)" class="input"/>
            </div>
            <div class="slider">
              <el-slider v-model="roughness" @input="roughnessChange()" show-input :min="0" :max="1" :step="0.01" :show-tooltip="false"></el-slider>
            </div>
            <!-- <div class="txt_box1">
              <input type="text" readonly="readonly" v-model="roughness" />
            </div> -->
            <div @click="roughChange" class="shrink"><i class="el-icon-arrow-down"></i> </div>
            <a title="重置贴图"><div class="reset" @click="roughResetChange"><img src="../../assets/Property/reset.png" alt=""> </div></a>
          </div>
          <div v-show="roughnessLoction">
              <div class="loction">
              <div class="title">重复</div>
              <div class="x-axis axis">
                <p>U</p>
                <input type="number" @change="RepeatroughnessUvChange($event)" v-model="roughnessRepeatU" @focus="Focus" @blur="Blur"/>
              </div>
              <div class="y-axis axis">
                <p>V</p>
                <input type="number" @change="RepeatroughnessUvChange($event)" v-model="roughnessRepeatV" @focus="Focus" @blur="Blur"/>
              </div>
            </div>
            <div class="loction">
              <div class="title">平移</div>
              <div class="x-axis axis">
                <p>U</p>
                <input type="number" @change="OffsetroughnessUvChange($event)" v-model="roughnessOffsetU" @focus="Focus" @blur="Blur"/>
              </div>
              <div class="y-axis axis">
                <p>V</p>
                <input type="number" @change="OffsetroughnessUvChange($event)" v-model="roughnessOffsetV" @focus="Focus" @blur="Blur"/>
              </div>
            </div>
            <div class="loction">
              <div class="title">旋转</div>
              <div class="ipt_box">
                <input type="number" @change="RotationroughnessUvChange($event)" name="" id="" v-model="roughnessRotation" @focus="Focus" @blur="Blur" />
              </div>
            </div>
            <div class="loction">
              <div class="title">GAMMA</div>
              <div class="ipt_box">
                <input type="number" @change="roughnessGammaChange($event)" name="" id="" v-model="roughnessGamma" @focus="Focus" @blur="Blur">
              </div>
            </div>
          </div>
        </div>
        <div class="content" :class="normalActive?'anmDiv':'aaa'">
          <div class="conColor">
            <div class="title">法线</div>
            <div class="chartlet_box">
              <div class="chartlet" id="2"><img :src="normalMap" alt="" /></div>
              <input type="radio" name="1" @click="normalMapChange($event)" class="input"/>
            </div>
            <div @click="normalChange" class="shrink"><i class="el-icon-arrow-down"></i> </div>
            <a title="重置贴图"><div class="normaReset" @click="NormalResetChange"><img src="../../assets/Property/reset.png" alt=""> </div></a>
          </div>
          <div v-show="normalLoction">
              <div class="loction">
              <div class="title">重复</div>
              <div class="x-axis axis">
                <p>U</p>
                <input type="number" @change="RepeatnormalUvChange($event)" v-model="normalRepeatU" @focus="Focus" @blur="Blur"/>
              </div>
              <div class="y-axis axis">
                <p>V</p>
                <input type="number" @change="RepeatnormalUvChange($event)" v-model="normalRepeatV" @focus="Focus" @blur="Blur"/>
              </div>
            </div>
            <div class="loction">
              <div class="title">平移</div>
              <div class="x-axis axis">
                <p>U</p>
                <input type="number" @change="OffsetnormalUvChange($event)" v-model="normalOffsetU" @focus="Focus" @blur="Blur"/>
              </div>
              <div class="y-axis axis">
                <p>V</p>
                <input type="number" @change="OffsetnormalUvChange($event)" v-model="normalOffsetV" @focus="Focus" @blur="Blur"/>
              </div>
            </div>
            <div class="loction">
              <div class="title">旋转</div>
              <div class="ipt_box">
                <input type="number" @change="RotationnormalUvChange($event)" name="" id="" v-model="normalRotation" @focus="Focus" @blur="Blur"/>
              </div>
            </div>
            <div class="loction">
              <div class="title">GAMMA</div>
              <div class="ipt_box">
                <input type="number" @change="normalGammaChange($event)" name="" id="" v-model="normalGamma" @focus="Focus" @blur="Blur">
              </div>
            </div>
          </div>
        </div>
  
        <div class="content" :class="emissiveActive?'anmDiv':'aaa'">
          <div class="conColor">
            <div class="title">发光</div>
            <div class="chartlet_box">
              <div class="chartlet" id="4"><img :src="emissiveMap" alt="" /></div>
              <input type="radio" name="1" @click="emissiveMapChange($event)" class="input"/>
            </div>
            <div class="color_box"><el-color-picker v-model="emissive" @change="emissiveColorChange()" @active-change="activeColor1" popper-class="picker"></el-color-picker></div>
            <div class="import_color" style="margin-left:1.6276vw">
              <input type="text" v-model="emissive" @focus="Focus" @blur="Blur" @input="colorImport()">
            </div>
            <div class="txt_box">
                <input type="number" class="emissiveIpt" oninput="if(value>9999999)value=9999999;if(value<0)value=0" @change="changeNum($event)" name="" id="" v-model="emissiveIntensity" @focus="Focus" @blur="Blur" />
            </div>
            <div @click="emissiveChange" class="shrink"><i class="el-icon-arrow-down"></i> </div>
            <a title="重置贴图"><div class="reset" @click="emissiveResetChange"><img src="../../assets/Property/reset.png" alt=""> </div></a>
          </div>
         <div v-show="missiveLoction">
             <div class="loction">
              <div class="title">重复</div>
              <div class="x-axis axis">
                <p>U</p>
                <input type="number" @change="RepeatemissiveUvChange($event)" v-model="emissiveRepeatU" @focus="Focus" @blur="Blur"/>
              </div>
              <div class="y-axis axis">
                <p>V</p>
                <input type="number" @change="RepeatemissiveUvChange($event)" v-model="emissiveRepeatV" @focus="Focus" @blur="Blur"/>
              </div>
            </div>
            <div class="loction">
              <div class="title">平移</div>
              <div class="x-axis axis">
                <p>U</p>
                <input type="number" @change="OffsetemissiveUvChange($event)" v-model="emissiveOffsetU" @focus="Focus" @blur="Blur"/>
              </div>
              <div class="y-axis axis">
                <p>V</p>
                <input type="number" @change="OffsetemissiveUvChange($event)" v-model="emissiveOffsetV" @focus="Focus" @blur="Blur"/>
              </div>
            </div>
            <div class="loction">
              <div class="title">旋转</div>
              <div class="ipt_box">
                <input type="number" @change="RotationemissiveUvChange($event)" name="" id="" v-model="emissiveRotation" @focus="Focus" @blur="Blur"/>
              </div>
            </div>
            <div class="loction">
              <div class="title">GAMMA</div>
              <div class="ipt_box">
                <input type="number" @change="emissiveGammaChange($event)" name="" id="" v-model="emissiveGamma" @focus="Focus" @blur="Blur">
              </div>
            </div>
         </div>
        </div>
        <div class="illumination">
          <div class="title">环境</div>
          <div class="chartlet_box" style="margin-left:-0.32552vw">
            <div class="chartlet" id="hdrImg4"><img :src="envMap" alt="" /></div>
            <input type="radio" name="1" @click="envMapChange($event)" class="input"/>
          </div>
          <div class="ipt_box">
            <input type="number" class="ipt" @change="changeNum($event)" v-model="envMapIntensity" @focus="Focus" @blur="Blur" />
          </div>
          <a title="重置贴图"><div class="reset" @click="envMapResetChange"><img src="../../assets/Property/reset.png" alt=""> </div></a>
        </div>
        <div class="illumination">
          <div class="title">光照</div>
          <div class="chartlet_box">
            <div class="chartlet" id="0"><img :src="lightMap" alt="" /></div>
            <input type="radio" class="input" name="1" @click="lightMapChecked($event)"/>
          </div>
          <div class="ipt_box">
            <input type="number" class="ipt" @change="changeNum($event)" v-model="lightMapIntensity" @focus="Focus" @blur="Blur" />
          </div>
          <a title="重置贴图"><div class="reset" @click="LightResetChange"><img src="../../assets/Property/reset.png" alt=""> </div></a>
        </div>
        <div class="illumination">
          <div class="title">环境遮挡</div>
          <div class="chartlet_box" style="margin-left:-0.32552vw">
            <div class="chartlet" id="6"><img :src="aoMap" alt="" /></div>
            <input type="radio" @click="aoMapChange($event)" name="1" class="input"/>
          </div>
          <div class="ipt_box">
            <input type="number" class="ipt" @change="changeNum($event)" v-model="aoMapIntensity" @focus="Focus" @blur="Blur" />
          </div>
          <a title="重置贴图"><div class="reset" @click="aoMapResetChange"><img src="../../assets/Property/reset.png" alt=""> </div></a>
        </div>
        <div class="fog">
          <div class="title">雾影响</div>
          <div class="ipt_box">
            <input type="checkbox" class="input" @change="fogChange($event)" v-model="fog" />
          </div>
        </div>
        <div class="lucency_box">
          <div class="lucency">
            <div class="title">透明</div>
            <div class="ipt_box">
              <input type="checkbox" class="input" @change="transparentChange($event)" v-model="transparent" />
            </div>
          </div>
          <div class="transparency">
            <div class="title">透明度</div>
            <div class="slider">
              <el-slider v-model="opacity" @input="opacityChange()" :min="0" :max="1" :step="0.1" :show-tooltip="false"></el-slider>
            </div>
            <div class="ipt_box">
              <input type="text" readonly="readonly" v-model="opacity" />
            </div>
          </div>
        </div>
        <div class="render">
          <div class="title">渲染面</div>
          <div class="ipt_box">
            <!-- <select
              class="select"
              v-model="renderSelect"
              @change="renderChange1()"
            >
              <option v-for="(item, index) in renderOptionList" :key="index">
                {{ item }}
              </option>
            </select> -->
            <el-select  @change="renderChange1()" class="select" v-model="renderSelect" placeholder="请选择">
              <el-option
                v-for="item in renderOptionList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="render">
          <div class="title">材料</div>
          <div class="ipt_box">
            <el-select  @change="MaterialsChange()" class="select" v-model="MaterialsSelect" placeholder="请选择">
              <el-option
                v-for="item in MaterialsList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <!-- 玻璃 -->
        <div class="render" v-if="MaterialsSelect==1">
          <div class="title">折射率内</div>
          <div class="ipt_box1">
            <input type="number" @change="changeNum($event)" name="" id="" v-model="interiorior" @focus="Focus" @blur="Blur">
          </div>
        </div>
        <div class="render" v-if="MaterialsSelect==1">
          <div class="title">折射率外</div>
          <div class="ipt_box1">
            <input type="number" @change="changeNum($event)" name="" id="" v-model="exterior" @focus="Focus" @blur="Blur">
          </div>
        </div>
        <div class="render" v-if="MaterialsSelect==1">
          <div class="title">色散</div>
          <div class="ipt_box1">
            <input type="number" @change="changeNum($event)" name="" id="" v-model="cauchyb" @focus="Focus" @blur="Blur">
          </div>
        </div>
        <!-- 磨砂玻璃 -->
        <div class="render" v-if="MaterialsSelect==2">
          <div class="title">折射率内</div>
          <div class="ipt_box1">
            <input type="number" @change="changeNum($event)" name="" id="" v-model="interiorior" @focus="Focus" @blur="Blur">
          </div>
        </div>
        <div class="render" v-if="MaterialsSelect==2">
          <div class="title">折射率外</div>
          <div class="ipt_box1">
            <input type="number" @change="changeNum($event)" name="" id="" v-model="exterior" @focus="Focus" @blur="Blur">
          </div>
        </div>
        <!-- 绒布 -->
        <div class="render" v-if="MaterialsSelect==4">
          <div class="title">绒毛高度</div>
          <div class="ipt_box1">
            <input type="number" @change="changeNum($event)" name="" id="" v-model="thickness" @focus="Focus" @blur="Blur">
          </div>
        </div>
    </div>

    <div class="Standard" v-show="PhongShader">
        <div class="content" :class="ColorActive?'anmDiv':'aaa'">
          <div class="conColor">
            <div class="title">颜色</div>
            <div class="chartlet_box">
              <div class="chartlet"><img :src="map" alt="" /></div>
              <input type="radio" name="1" @click="MapChange($event)" class="input"/>
            </div>
            <el-color-picker v-model="color" @change="ColorApplyChange()" @active-change="activeColor" popper-class="picker"></el-color-picker>
            <div class="import_color">
              <input type="text" v-model="color" @focus="Focus" @blur="Blur" @input="colorImport()">
            </div>
            <div @click="ColorChange" class="shrink"><i class="el-icon-arrow-down"></i> </div>
            <a title="重置贴图"><div class="reset" @click="ColorResetChange"><img src="../../assets/Property/reset.png" alt=""> </div></a>
          </div>
          <div v-show="loction">
            <div class="loction">
              <div class="title">重复</div>
              <div class="x-axis axis">
                <p>U</p>  
                <input type="number" @change="RepeatUvChange()" v-model="ColorRepeatU" @focus="Focus" @blur="Blur" />
              </div>
              <div class="y-axis axis">
                <p>V</p>
                <input type="number" @change="RepeatUvChange()" v-model="ColorRepeatV" @focus="Focus" @blur="Blur" />
              </div>
            </div>
            <div class="loction">
              <div class="title">平移</div>
              <div class="x-axis axis">
                <p>U</p>
                <input type="number" @change="OffsetUvChange()" v-model="ColorOffsetU" @focus="Focus" @blur="Blur" />
              </div>
              <div class="y-axis axis">
                <p>V</p>
                <input type="number" @change="OffsetUvChange()" v-model="ColorOffsetV" @focus="Focus" @blur="Blur" />
              </div>
            </div>
            <div class="loction">
              <div class="title">旋转</div>
              <div class="ipt_box">
                <input type="number" @change="RotationUvChange($event)" name="" id="" v-model="ColorRotation" @focus="Focus" @blur="Blur">
              </div>
            </div>
            <div class="loction">
              <div class="title">GAMMA</div>
              <div class="ipt_box">
                <input type="number" @change="colorGammaChange($event)" name="" id="" v-model="colorGamma" @focus="Focus" @blur="Blur">
              </div>
            </div>
          </div>
        </div>
        <div class="content" :class="roughActive?'anmDiv':'aaa'">
          <div class="conColor">
            <div class="title">光泽度</div>
            <div class="slider">
              <el-slider v-model="shininess" @input="shininessChange()" show-input :min="0" :max="100" :step="0.1" :show-tooltip="false"></el-slider>
            </div>
            <!-- <div class="txt_box1">
              <input type="text" readonly="readonly" v-model="roughness" />
            </div> -->
            <!-- <div @click="roughChange" class="shrink"><i class="el-icon-arrow-down"></i> </div> -->
            <a title="重置贴图"><div class="reset" @click="roughResetChange"><img src="../../assets/Property/reset.png" alt=""> </div></a>
          </div>
        </div>
        <div class="content" :class="metalActive?'anmDiv':'aaa'">
          <div class="conColor">
            <div class="title">反射率</div>
            <div class="slider">
              <el-slider id="A" v-model="reflectivity" @input="reflectivitySlider()" show-input :min="0" :max="1" :step="0.01" :show-tooltip="false"></el-slider>
            </div>
            <!-- <div class="txt_box1">
              <input type="text" readonly="readonly" v-model="metalness" />
            </div> -->
            <!-- <div @click="metalChange" class="shrink"><i class="el-icon-arrow-down"></i> </div> -->
            <a title="重置贴图"><div class="reset" @click="MetaResetChange"><img src="../../assets/Property/reset.png" alt=""> </div></a>
          </div>
        </div>
        <div class="content" :class="specularActive?'anmDiv':'aaa'">
          <div class="conColor">
            <div class="title">高光贴图</div>
            <div class="chartlet_box">
              <div class="chartlet" id="4"><img :src="specularMap" alt="" /></div>
              <input type="radio" name="1" @click="specularMapChange($event)" class="input"/>
            </div>
            <div class="color_box"><el-color-picker v-model="specular" @change="specularColorChange()" @active-change="specularActiveColor" popper-class="picker"></el-color-picker></div>
            <div class="import_color" style="margin-left:1.95313vw">
              <input type="text" v-model="specular" @focus="Focus" @blur="Blur" @input="colorImport()">
            </div>
            <div @click="specularChange" class="shrink"><i class="el-icon-arrow-down"></i> </div>
            <a title="重置贴图"><div class="reset" @click="specularResetChange"><img src="../../assets/Property/reset.png" alt=""> </div></a>
          </div>
         <div v-show="missiveLoction">
             <div class="loction">
              <div class="title">重复</div>
              <div class="x-axis axis">
                <p>U</p>
                <input type="number" @change="RepeatemissiveUvChange($event)" v-model="emissiveRepeatU" @focus="Focus" @blur="Blur"/>
              </div>
              <div class="y-axis axis">
                <p>V</p>
                <input type="number" @change="RepeatemissiveUvChange($event)" v-model="emissiveRepeatV" @focus="Focus" @blur="Blur"/>
              </div>
            </div>
            <div class="loction">
              <div class="title">平移</div>
              <div class="x-axis axis">
                <p>U</p>
                <input type="number" @change="OffsetemissiveUvChange($event)" v-model="emissiveOffsetU" @focus="Focus" @blur="Blur"/>
              </div>
              <div class="y-axis axis">
                <p>V</p>
                <input type="number" @change="OffsetemissiveUvChange($event)" v-model="emissiveOffsetV" @focus="Focus" @blur="Blur"/>
              </div>
            </div>
            <div class="loction">
              <div class="title">旋转</div>
              <div class="ipt_box">
                <input type="number" @change="RotationemissiveUvChange($event)" name="" id="" v-model="emissiveRotation" @focus="Focus" @blur="Blur"/>
              </div>
            </div>
            <div class="loction">
              <div class="title">GAMMA</div>
              <div class="ipt_box">
                <input type="number" @change="emissiveGammaChange($event)" name="" id="" v-model="emissiveGamma" @focus="Focus" @blur="Blur">
              </div>
            </div>
         </div>
        </div>
        <div class="content" :class="normalActive?'anmDiv':'aaa'">
          <div class="conColor">
            <div class="title">法线</div>
            <div class="chartlet_box">
              <div class="chartlet" id="2"><img :src="normalMap" alt="" /></div>
              <input type="radio" name="1" @click="normalMapChange($event)" class="input"/>
            </div>
            <div @click="normalChange" class="shrink"><i class="el-icon-arrow-down"></i> </div>
            <a title="重置贴图"><div class="normaReset" @click="NormalResetChange"><img src="../../assets/Property/reset.png" alt=""> </div></a>
          </div>
          <div v-show="normalLoction">
              <div class="loction">
              <div class="title">重复</div>
              <div class="x-axis axis">
                <p>U</p>
                <input type="number" @change="RepeatnormalUvChange($event)" v-model="normalRepeatU" @focus="Focus" @blur="Blur"/>
              </div>
              <div class="y-axis axis">
                <p>V</p>
                <input type="number" @change="RepeatnormalUvChange($event)" v-model="normalRepeatV" @focus="Focus" @blur="Blur"/>
              </div>
            </div>
            <div class="loction">
              <div class="title">平移</div>
              <div class="x-axis axis">
                <p>U</p>
                <input type="number" @change="OffsetnormalUvChange($event)" v-model="normalOffsetU" @focus="Focus" @blur="Blur"/>
              </div>
              <div class="y-axis axis">
                <p>V</p>
                <input type="number" @change="OffsetnormalUvChange($event)" v-model="normalOffsetV" @focus="Focus" @blur="Blur"/>
              </div>
            </div>
            <div class="loction">
              <div class="title">旋转</div>
              <div class="ipt_box">
                <input type="number" @change="RotationnormalUvChange($event)" name="" id="" v-model="normalRotation" @focus="Focus" @blur="Blur"/>
              </div>
            </div>
            <div class="loction">
              <div class="title">GAMMA</div>
              <div class="ipt_box">
                <input type="number" @change="normalGammaChange($event)" name="" id="" v-model="normalGamma" @focus="Focus" @blur="Blur">
              </div>
            </div>
          </div>
        </div>
  
        <div class="content" :class="emissiveActive?'anmDiv':'aaa'">
          <div class="conColor">
            <div class="title">发光</div>
            <div class="chartlet_box">
              <div class="chartlet" id="4"><img :src="emissiveMap" alt="" /></div>
              <input type="radio" name="1" @click="emissiveMapChange($event)" class="input"/>
            </div>
            <div class="color_box"><el-color-picker v-model="emissive" @change="emissiveColorChange()" @active-change="activeColor1" popper-class="picker"></el-color-picker></div>
            <div class="import_color" style="margin-left:1.6276vw">
              <input type="text" v-model="emissive" @focus="Focus" @blur="Blur" @input="colorImport()">
            </div>
            <div class="txt_box">
                <input type="number" class="emissiveIpt" @change="changeNum($event)" oninput="if(value>9999999)value=9999999;if(value<0)value=0" name="" id="" v-model="emissiveIntensity" @focus="Focus" @blur="Blur" />
            </div>
            <div @click="emissiveChange" class="shrink"><i class="el-icon-arrow-down"></i> </div>
            <a title="重置贴图"><div class="reset" @click="emissiveResetChange"><img src="../../assets/Property/reset.png" alt=""> </div></a>
          </div>
         <div v-show="missiveLoction">
             <div class="loction">
              <div class="title">重复</div>
              <div class="x-axis axis">
                <p>U</p>
                <input type="number" @change="RepeatemissiveUvChange($event)" v-model="emissiveRepeatU" @focus="Focus" @blur="Blur"/>
              </div>
              <div class="y-axis axis">
                <p>V</p>
                <input type="number" @change="RepeatemissiveUvChange($event)" v-model="emissiveRepeatV" @focus="Focus" @blur="Blur"/>
              </div>
            </div>
            <div class="loction">
              <div class="title">平移</div>
              <div class="x-axis axis">
                <p>U</p>
                <input type="number" @change="OffsetemissiveUvChange($event)" v-model="emissiveOffsetU" @focus="Focus" @blur="Blur"/>
              </div>
              <div class="y-axis axis">
                <p>V</p>
                <input type="number" @change="OffsetemissiveUvChange($event)" v-model="emissiveOffsetV" @focus="Focus" @blur="Blur"/>
              </div>
            </div>
            <div class="loction">
              <div class="title">旋转</div>
              <div class="ipt_box">
                <input type="number" @change="RotationemissiveUvChange($event)" name="" id="" v-model="emissiveRotation" @focus="Focus" @blur="Blur"/>
              </div>
            </div>
            <div class="loction">
              <div class="title">GAMMA</div>
              <div class="ipt_box">
                <input type="number" @change="emissiveGammaChange($event)" name="" id="" v-model="emissiveGamma" @focus="Focus" @blur="Blur">
              </div>
            </div>
         </div>
        </div>
        <div class="illumination">
          <div class="title">环境</div>
          <div class="chartlet_box" style="margin-left:-0.32552vw">
            <div class="chartlet" id="hdrImg5"><img :src="envMap" alt="" /></div>
            <input type="radio" name="1" @click="envMapChange($event)" class="input"/>
          </div>
          <a title="重置贴图"><div class="reset" @click="envMapResetChange"><img src="../../assets/Property/reset.png" alt=""> </div></a>
        </div>
        <div class="illumination">
          <div class="title">光照</div>
          <div class="chartlet_box">
            <div class="chartlet" id="0"><img :src="lightMap" alt="" /></div>
            <input type="radio" class="input" name="1" @click="lightMapChecked($event)"/>
          </div>
          <div class="ipt_box">
            <input type="number" class="ipt" @change="changeNum($event)" v-model="lightMapIntensity" @focus="Focus" @blur="Blur" />
          </div>
          <a title="重置贴图"><div class="reset" @click="LightResetChange"><img src="../../assets/Property/reset.png" alt=""> </div></a>
        </div>
        <div class="illumination">
          <div class="title">环境遮挡</div>
          <div class="chartlet_box" style="margin-left:-0.32552vw">
            <div class="chartlet" id="6"><img :src="aoMap" alt="" /></div>
            <input type="radio" @click="aoMapChange($event)" name="1" class="input"/>
          </div>
          <div class="ipt_box">
            <input type="number" class="ipt" @change="changeNum($event)" v-model="aoMapIntensity" @focus="Focus" @blur="Blur" />
          </div>
          <a title="重置贴图"><div class="reset" @click="aoMapResetChange"><img src="../../assets/Property/reset.png" alt=""> </div></a>
        </div>
        <div class="fog">
          <div class="title">雾影响</div>
          <div class="ipt_box">
            <input type="checkbox" class="input" @change="fogChange($event)" v-model="fog" />
          </div>
        </div>
        <div class="lucency_box">
          <div class="lucency">
            <div class="title">透明</div>
            <div class="ipt_box">
              <input type="checkbox" class="input" @change="transparentChange($event)" v-model="transparent" />
            </div>
          </div>
          <div class="transparency">
            <div class="title">透明度</div>
            <div class="slider">
              <el-slider v-model="opacity" @input="opacityChange()" :min="0" :max="1" :step="0.1" :show-tooltip="false"></el-slider>
            </div>
            <div class="ipt_box">
              <input type="text" readonly="readonly" v-model="opacity" />
            </div>
          </div>
        </div>
        <div class="content" :class="reflectivityActive?'anmDiv':'aaa'">
          <div class="conColor">
            <div class="title">折射率</div>
            <div class="slider">
              <el-slider v-model="refractionRatio" @input="refractionRatioChange()" show-input :min="0" :max="1" :step="0.01" :show-tooltip="false"></el-slider>
            </div>
            <!-- <div class="txt_box1">
              <input type="text" readonly="readonly" v-model="roughness" />
            </div> -->
            <!-- <div @click="reflectivityChange" class="shrink"><i class="el-icon-arrow-down"></i> </div> -->
            <!-- <a title="重置贴图"><div class="reset" @click="roughResetChange"><img src="../../assets/Property/reset.png" alt=""> </div></a> -->
          </div>
        </div>
        <div class="render">
          <div class="title">渲染面</div>
          <div class="ipt_box">
            <!-- <select
              class="select"
              v-model="renderSelect"
              @change="renderChange2()"
            >
              <option v-for="(item, index) in renderOptionList" :key="index">
                {{ item }}
              </option>
            </select> -->
            <el-select  @change="renderChange2()" class="select" v-model="renderSelect" placeholder="请选择">
              <el-option
                v-for="item in renderOptionList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
    </div>
    <!-- 卡通捕捉材质 -->
    <div class="Standard" v-show="CartoonCapture">
        <div class="content" :class="ColorActive?'anmDiv':'aaa'">
          <div class="conColor">
            <div class="title">颜色</div>
            <div class="chartlet_box">
              <div class="chartlet"><img :src="map" alt="" /></div>
              <input type="radio" name="1" @click="MapChange($event)" class="input"/>
            </div>
            <el-color-picker v-model="color" @change="ColorApplyChange()" @active-change="activeColor" popper-class="picker"></el-color-picker>
            <div class="import_color">
              <input type="text" v-model="color" @focus="Focus" @blur="Blur" @input="colorImport()">
            </div>
            <!-- <div @click="ColorChange" class="shrink"><i class="el-icon-arrow-down"></i> </div> -->
            <a title="重置贴图"><div class="reset" @click="ColorResetChange"><img src="../../assets/Property/reset.png" alt=""> </div></a>
          </div>
          <!-- <div v-show="loction">
            <div class="loction">
              <div class="title">重复</div>
              <div class="x-axis axis">
                <p>U</p>  
                <input type="number" @change="RepeatUvChange()" v-model="ColorRepeatU" @focus="Focus" @blur="Blur" />
              </div>
              <div class="y-axis axis">
                <p>V</p>
                <input type="number" @change="RepeatUvChange()" v-model="ColorRepeatV" @focus="Focus" @blur="Blur" />
              </div>
            </div>
            <div class="loction">
              <div class="title">平移</div>
              <div class="x-axis axis">
                <p>U</p>
                <input type="number" @change="OffsetUvChange()" v-model="ColorOffsetU" @focus="Focus" @blur="Blur" />
              </div>
              <div class="y-axis axis">
                <p>V</p>
                <input type="number" @change="OffsetUvChange()" v-model="ColorOffsetV" @focus="Focus" @blur="Blur" />
              </div>
            </div>
            <div class="loction">
              <div class="title">旋转</div>
              <div class="ipt_box">
                <input type="number" @change="RotationUvChange($event)" name="" id="" v-model="ColorRotation" @focus="Focus" @blur="Blur">
              </div>
            </div>
            <div class="loction">
              <div class="title">GAMMA</div>
              <div class="ipt_box">
                <input type="number" @change="colorGammaChange($event)" name="" id="" v-model="colorGamma" @focus="Focus" @blur="Blur">
              </div>
            </div>
          </div> -->
        </div>
        <div class="content" :class="roughActive?'anmDiv':'aaa'">
          <div class="conColor">
            <div class="title">光泽度</div>
            <div class="slider">
              <el-slider v-model="shininess" @input="shininessChange1()" show-input :min="0" :max="100" :step="0.1" :show-tooltip="false"></el-slider>
            </div>
            <!-- <div class="txt_box1">
              <input type="text" readonly="readonly" v-model="roughness" />
            </div> -->
            <!-- <div @click="roughChange" class="shrink"><i class="el-icon-arrow-down"></i> </div> -->
            <a title="重置贴图"><div class="reset" @click="roughResetChange"><img src="../../assets/Property/reset.png" alt=""> </div></a>
          </div>
        </div>
        <div class="content" :class="metalActive?'anmDiv':'aaa'">
          <div class="conColor">
            <div class="title">反射率</div>
            <div class="slider">
              <el-slider id="A" v-model="reflectivity" @input="reflectivitySlider()" show-input :min="0" :max="1" :step="0.01" :show-tooltip="false"></el-slider>
            </div>
            <!-- <div class="txt_box1">
              <input type="text" readonly="readonly" v-model="metalness" />
            </div> -->
            <!-- <div @click="metalChange" class="shrink"><i class="el-icon-arrow-down"></i> </div> -->
            <a title="重置贴图"><div class="reset" @click="MetaResetChange"><img src="../../assets/Property/reset.png" alt=""> </div></a>
          </div>
        </div>
        <div class="content" :class="specularActive?'anmDiv':'aaa'">
          <div class="conColor">
            <div class="title">高光贴图</div>
            <div class="chartlet_box">
              <div class="chartlet" id="4"><img :src="specularMap" alt="" /></div>
              <input type="radio" name="1" @click="specularMapChange($event)" class="input"/>
            </div>
            <div class="color_box"><el-color-picker v-model="specular" @change="specularColorChange()" @active-change="specularActiveColor" popper-class="picker"></el-color-picker></div>
            <div class="import_color" style="margin-left:1.95313vw">
              <input type="text" v-model="specular" @focus="Focus" @blur="Blur" @input="colorImport()">
            </div>
            <!-- <div @click="specularChange" class="shrink"><i class="el-icon-arrow-down"></i> </div> -->
            <a title="重置贴图"><div class="reset" @click="specularResetChange"><img src="../../assets/Property/reset.png" alt=""> </div></a>
          </div>
         <!-- <div v-show="missiveLoction">
             <div class="loction">
              <div class="title">重复</div>
              <div class="x-axis axis">
                <p>U</p>
                <input type="number" @change="RepeatemissiveUvChange($event)" v-model="emissiveRepeatU" @focus="Focus" @blur="Blur"/>
              </div>
              <div class="y-axis axis">
                <p>V</p>
                <input type="number" @change="RepeatemissiveUvChange($event)" v-model="emissiveRepeatV" @focus="Focus" @blur="Blur"/>
              </div>
            </div>
            <div class="loction">
              <div class="title">平移</div>
              <div class="x-axis axis">
                <p>U</p>
                <input type="number" @change="OffsetemissiveUvChange($event)" v-model="emissiveOffsetU" @focus="Focus" @blur="Blur"/>
              </div>
              <div class="y-axis axis">
                <p>V</p>
                <input type="number" @change="OffsetemissiveUvChange($event)" v-model="emissiveOffsetV" @focus="Focus" @blur="Blur"/>
              </div>
            </div>
            <div class="loction">
              <div class="title">旋转</div>
              <div class="ipt_box">
                <input type="number" @change="RotationemissiveUvChange($event)" name="" id="" v-model="emissiveRotation" @focus="Focus" @blur="Blur"/>
              </div>
            </div>
            <div class="loction">
              <div class="title">GAMMA</div>
              <div class="ipt_box">
                <input type="number" @change="emissiveGammaChange($event)" name="" id="" v-model="emissiveGamma" @focus="Focus" @blur="Blur">
              </div>
            </div>
         </div> -->
        </div>
        <div class="content" :class="normalActive?'anmDiv':'aaa'">
          <div class="conColor">
            <div class="title">法线</div>
            <div class="chartlet_box">
              <div class="chartlet" id="2"><img :src="normalMap" alt="" /></div>
              <input type="radio" name="1" @click="normalMapChange($event)" class="input"/>
            </div>
            <!-- <div @click="normalChange" class="shrink"><i class="el-icon-arrow-down"></i> </div> -->
            <a title="重置贴图"><div class="normaReset" @click="NormalResetChange"><img src="../../assets/Property/reset.png" alt=""> </div></a>
          </div>
          <!-- <div v-show="normalLoction">
              <div class="loction">
              <div class="title">重复</div>
              <div class="x-axis axis">
                <p>U</p>
                <input type="number" @change="RepeatnormalUvChange($event)" v-model="normalRepeatU" @focus="Focus" @blur="Blur"/>
              </div>
              <div class="y-axis axis">
                <p>V</p>
                <input type="number" @change="RepeatnormalUvChange($event)" v-model="normalRepeatV" @focus="Focus" @blur="Blur"/>
              </div>
            </div>
            <div class="loction">
              <div class="title">平移</div>
              <div class="x-axis axis">
                <p>U</p>
                <input type="number" @change="OffsetnormalUvChange($event)" v-model="normalOffsetU" @focus="Focus" @blur="Blur"/>
              </div>
              <div class="y-axis axis">
                <p>V</p>
                <input type="number" @change="OffsetnormalUvChange($event)" v-model="normalOffsetV" @focus="Focus" @blur="Blur"/>
              </div>
            </div>
            <div class="loction">
              <div class="title">旋转</div>
              <div class="ipt_box">
                <input type="number" @change="RotationnormalUvChange($event)" name="" id="" v-model="normalRotation" @focus="Focus" @blur="Blur"/>
              </div>
            </div>
            <div class="loction">
              <div class="title">GAMMA</div>
              <div class="ipt_box">
                <input type="number" @change="normalGammaChange($event)" name="" id="" v-model="normalGamma" @focus="Focus" @blur="Blur">
              </div>
            </div>
          </div> -->
        </div>
  
        <div class="content" :class="emissiveActive?'anmDiv':'aaa'">
          <div class="conColor">
            <div class="title">发光</div>
            <div class="chartlet_box">
              <div class="chartlet" id="4"><img :src="emissiveMap" alt="" /></div>
              <input type="radio" name="1" @click="emissiveMapChange($event)" class="input"/>
            </div>
            <div class="color_box"><el-color-picker v-model="emissive" @change="emissiveColorChange()" @active-change="activeColor1" popper-class="picker"></el-color-picker></div>
            <div class="import_color" style="margin-left:1.6276vw">
              <input type="text" v-model="emissive" @focus="Focus" @blur="Blur" @input="colorImport()">
            </div>
            <div class="txt_box">
                <input type="number" class="emissiveIpt" @change="changeNum($event)" oninput="if(value>9999999)value=9999999;if(value<0)value=0" name="" id="" v-model="emissiveIntensity" @focus="Focus" @blur="Blur" />
            </div>
            <!-- <div @click="emissiveChange" class="shrink"><i class="el-icon-arrow-down"></i> </div> -->
            <a title="重置贴图"><div class="reset" @click="emissiveResetChange"><img src="../../assets/Property/reset.png" alt=""> </div></a>
          </div>
         <!-- <div v-show="missiveLoction">
             <div class="loction">
              <div class="title">重复</div>
              <div class="x-axis axis">
                <p>U</p>
                <input type="number" @change="RepeatemissiveUvChange($event)" v-model="emissiveRepeatU" @focus="Focus" @blur="Blur"/>
              </div>
              <div class="y-axis axis">
                <p>V</p>
                <input type="number" @change="RepeatemissiveUvChange($event)" v-model="emissiveRepeatV" @focus="Focus" @blur="Blur"/>
              </div>
            </div>
            <div class="loction">
              <div class="title">平移</div>
              <div class="x-axis axis">
                <p>U</p>
                <input type="number" @change="OffsetemissiveUvChange($event)" v-model="emissiveOffsetU" @focus="Focus" @blur="Blur"/>
              </div>
              <div class="y-axis axis">
                <p>V</p>
                <input type="number" @change="OffsetemissiveUvChange($event)" v-model="emissiveOffsetV" @focus="Focus" @blur="Blur"/>
              </div>
            </div>
            <div class="loction">
              <div class="title">旋转</div>
              <div class="ipt_box">
                <input type="number" @change="RotationemissiveUvChange($event)" name="" id="" v-model="emissiveRotation" @focus="Focus" @blur="Blur"/>
              </div>
            </div>
            <div class="loction">
              <div class="title">GAMMA</div>
              <div class="ipt_box">
                <input type="number" @change="emissiveGammaChange($event)" name="" id="" v-model="emissiveGamma" @focus="Focus" @blur="Blur">
              </div>
            </div>
         </div> -->
        </div>
        <div class="illumination">
          <div class="title">捕捉纹理</div>
          <div class="chartlet_box" style="margin-left:-0.32552vw">
            <div class="chartlet" id="hdrImg6"><img :src="matMap" alt="" /></div>
            <input type="radio" name="1" @click="captureMapChange($event)" class="input"/>
          </div>
          <a title="重置贴图"><div class="reset" @click="captureMapResetChange"><img src="../../assets/Property/reset.png" alt=""> </div></a>
        </div>
        <div class="content" :class="metalActive?'anmDiv':'aaa'">
          <div class="conColor">
            <div class="title">捕捉强度</div>
            <div class="slider">
              <el-slider id="A" v-model="matcapIntensity" @input="CaptureTextureSlider()" show-input :min="0" :max="1" :step="0.01" :show-tooltip="false"></el-slider>
            </div>
            <a title=""><div class="reset" @click="MetaResetChange"><img src="../../assets/Property/reset.png" alt=""> </div></a>
          </div>
        </div>
        <div class="illumination">
          <div class="title">梯度纹理</div>
          <div class="chartlet_box" style="margin-left:-0.32552vw">
            <div class="chartlet"><img :src="gradientMap" alt="" /></div>
            <input type="radio" name="1" @click="gradientMapChange($event)" class="input"/>
          </div>
          <a title="重置贴图"><div class="reset" @click="gradientMapResetChange"><img src="../../assets/Property/reset.png" alt=""> </div></a>
        </div>
        <div class="illumination">
          <div class="title">光照</div>
          <div class="chartlet_box">
            <div class="chartlet" id="0"><img :src="lightMap" alt="" /></div>
            <input type="radio" class="input" name="1" @click="lightMapChecked($event)"/>
          </div>
          <div class="ipt_box">
            <input type="number" class="ipt" @change="changeNum($event)" v-model="lightMapIntensity" @focus="Focus" @blur="Blur" />
          </div>
          <a title="重置贴图"><div class="reset" @click="LightResetChange"><img src="../../assets/Property/reset.png" alt=""> </div></a>
        </div>
        <div class="illumination">
          <div class="title">环境遮挡</div>
          <div class="chartlet_box" style="margin-left:-0.32552vw">
            <div class="chartlet" id="6"><img :src="aoMap" alt="" /></div>
            <input type="radio" @click="aoMapChange($event)" name="1" class="input"/>
          </div>
          <div class="ipt_box">
            <input type="number" class="ipt" @change="changeNum($event)" v-model="aoMapIntensity" @focus="Focus" @blur="Blur" />
          </div>
          <a title="重置贴图"><div class="reset" @click="aoMapResetChange"><img src="../../assets/Property/reset.png" alt=""> </div></a>
        </div>
        <div class="fog">
          <div class="title">雾影响</div>
          <div class="ipt_box">
            <input type="checkbox" class="input" @change="fogChange($event)" v-model="fog" />
          </div>
        </div>
        <div class="lucency_box">
          <div class="lucency">
            <div class="title">透明</div>
            <div class="ipt_box">
              <input type="checkbox" class="input" @change="transparentChange($event)" v-model="transparent" />
            </div>
          </div>
          <div class="transparency">
            <div class="title">透明度</div>
            <div class="slider">
              <el-slider v-model="opacity" @input="opacityChange()" :min="0" :max="1" :step="0.1" :show-tooltip="false"></el-slider>
            </div>
            <div class="ipt_box">
              <input type="text" readonly="readonly" v-model="opacity" />
            </div>
          </div>
        </div>
        <div class="content" :class="reflectivityActive?'anmDiv':'aaa'">
          <div class="conColor">
            <div class="title">折射率</div>
            <div class="slider">
              <el-slider v-model="refractionRatio" @input="refractionRatioChange()" show-input :min="0" :max="1" :step="0.01" :show-tooltip="false"></el-slider>
            </div>
            <!-- <div class="txt_box1">
              <input type="text" readonly="readonly" v-model="roughness" />
            </div> -->
            <!-- <div @click="reflectivityChange" class="shrink"><i class="el-icon-arrow-down"></i> </div> -->
            <!-- <a title="重置贴图"><div class="reset" @click="roughResetChange"><img src="../../assets/Property/reset.png" alt=""> </div></a> -->
          </div>
        </div>
        <div class="render">
          <div class="title">渲染面</div>
          <div class="ipt_box">
            <!-- <select
              class="select"
              v-model="renderSelect"
              @change="renderChange3()"
            >
              <option v-for="(item, index) in renderOptionList" :key="index">
                {{ item }}
              </option>
            </select> -->
            <el-select  @change="renderChange3()" class="select" v-model="renderSelect" placeholder="请选择">
              <el-option
                v-for="item in renderOptionList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
    </div>
  </div>
</template>
<script>
import { IMaterialPreview } from "../../threejs/interface/IMaterialPreview";
import { ITransform } from "../../threejs/interface/ITransform";
import { ISetting } from "../../threejs/interface/ISetting";
// import { MaterialObject } from "../../threejs/object/MaterialObject";
export default {
  data() {
    return {
      loction:false,
      MetaLoction:false,
      roughnessLoction:false,
      normalLoction:false,
      missiveLoction:false,
      header:true,
      isActive:false,
      ColorActive:false,
      metalActive:false,
      roughActive:false,
      reflectivityActive:false,
      specularActive:false,
      normalActive:false,
      bumpActive:false,
      emissiveActive:false,
      StandardMaterial:true,//标准材质
      BaseMaterial:false,//基础材质
      PhongShader:false,//冯氏材质
      CartoonCapture:false,//卡通捕捉材质
      select: "",
      optionListValue:"",
      optionList: ["标准材质", "基础材质"],
      renderSelect: "",
      renderOptionList: [
        {
          value:0,
          label:"正面"
        },
        {
          value:1,
          label:"背面"
        },
        {
          value:2,
          label:"两者"
        },
      ],
      MaterialsSelect:"",
      MaterialsList:[
        {
          value:0,
          label:"默认"
        },
        {
          value:1,
          label:"玻璃"
        },
        {
          value:2,
          label:"磨砂玻璃"
        },
        {
          value:3,
          label:"镜子"
        },
        {
          value:4,
          label:"绒布"
        }

      ],
      ColorRepeatU:0,//颜色重复U
      ColorRepeatV:0,//颜色重复V
      ColorOffsetU:0,//颜色平移U
      ColorOffsetV:0,//颜色平移V
      ColorRotation:0,//颜色旋转
      MetalnessRepeatU:0,//金属度重复U
      MetalnessRepeatV:0,//金属度平移V
      MetalnessOffsetU:0,//金属平移U
      MetalnessOffsetV:0,//金属平移V
      MetalnessRotation:0,//金属度旋转
      roughnessRepeatU:0,//粗糙度重复U
      roughnessRepeatV:0,//粗糙度重复V
      roughnessOffsetU:0,//粗糙度平移U
      roughnessOffsetV:0,//粗糙度平移V
      roughnessRotation:0,//粗糙度旋转
      normalRepeatU:0,//法线重复U
      normalRepeatV:0,//法线重复V
      normalOffsetU:0,//法线平移U
      normalOffsetV:0,//法线平移V
      normalRotation:0,//法线旋转
      emissiveRepeatU:0,//发光重复U
      emissiveRepeatV:0,//发光重复V
      emissiveOffsetU:0,//发光平移U
      emissiveOffsetV:0,//发光平移V
      emissiveRotation:0,//发光旋转
      colorGamma:0,//颜色伽马
      MetalnessGamma:0,//金属du伽马
      roughnessGamma:0,//粗糙度伽马
      normalGamma:0,//法线伽马
      emissiveGamma:0,//发光伽马


      fog:false,                   //雾影响：是否
      opacity : 0,                  //透明度：在0.0 - 1.0的范围内的浮点数
      transparent : false,          //透明：是 否
      aoMap : '',                 //环境遮挡贴图
      aoMapIntensity : '',           //环境遮挡效果的强度
      color: "#FFFFFF",            //材质的颜色
      envMap : '',                //环境贴图
      lightMap : '',              //光照贴图
      lightMapIntensity : '',     //光照贴图强度
      map: '',                    //颜色贴图
      reflectivity : 0,             //环境贴图对表面的影响程度
      refractionRatio:0,          //折射率（IOR）
      specularMap : '',           //高光贴图
  
      emissive : '#000000',         //发光颜色
      emissiveMap : '',           //发光贴图
      emissiveIntensity : '',        //发光强度
      envMapIntensity : '',          //环境贴图强度
      metalness : 0,                //金属度
      metalnessMap : '',          //金属度贴图
      normalMap : '',             //法线贴图
      roughness: 0,                 //材质的粗糙程度
      roughnessMap : '',          //该纹理的绿色通道用于改变材质的粗糙度
      observer: null,
      sphere:'',
      side:0,
      shininess:0,//发光
      specular:"#000000",//高光贴图颜色
      exterior:0,//折射率外
      interiorior:0,//折射率内
      cauchyb:0,//色散
      thickness:0,//绒毛高度
      matMap:'',//捕捉纹理
      gradientMap:'',//梯度纹理
      matcapIntensity:0,//捕捉纹理强度
    };
  },
  mounted(){
      IMaterialPreview.f_getMaterialCallback = this.IMaterialPreviewChange.bind(this)
      IMaterialPreview.f_updateMaterialCallback = this.IMaterialPreviewChange.bind(this)
      this.optionListValue = this.optionList[0];
      this.renderSelect = this.renderOptionList[0];
      // ITransform.f_selectTransformCallback = this.IMaterialChange.bind(this);
      const targetNode = document.getElementById('app');
      const config = { attributes: true, childList: true, subtree: true }
    // 当节点发生变化时的需要执行的函数
      const callback = function (mutationsList, observer) {
        const obj = document.querySelector(".el-color-dropdown__link-btn");
          if (obj){
              obj.className = "el-color-dropdown__link-btn-qingkong";
              obj.style.display = "none"
          }
      };
    // 创建一个observer示例与回调函数相关联
      this.observer = new MutationObserver(callback);
    //使用配置文件对目标节点进行观测
      this.observer.observe(targetNode, config);
  },
  beforeDestory() {
    this.observer.disconnect();
    this.observer = null;
  },
  methods: {
    IMaterialGlass(){
      let IMteriaList = IMaterialPreview.getMaterial();
      if (IMteriaList != null) {
        // console.log("输出",IMteriaList)
        if(IMteriaList.matType == 0){
          this.MaterialsSelect= this.MaterialsList[0].value;
        }else if(IMteriaList.matType == 1){
          this.MaterialsSelect= this.MaterialsList[1].value;
          this.exterior = IMteriaList.materialMat.m_exteriorior;
          this.interiorior = IMteriaList.materialMat.m_interiorior;
          this.cauchyb = IMteriaList.materialMat.m_cauchyb;
        }else if(IMteriaList.matType == 2){
          this.MaterialsSelect= this.MaterialsList[2].value;
          this.exterior = IMteriaList.materialMat.m_exteriorior;
          this.interiorior = IMteriaList.materialMat.m_interiorior;
        }else if(IMteriaList.matType == 3){
          this.MaterialsSelect= this.MaterialsList[3].value;
        }else if(IMteriaList.matType == 4){
          this.MaterialsSelect= this.MaterialsList[4].value;
          this.thickness = IMteriaList.materialMat.m_thickness;
        }
      }
    },
    IMaterialPreviewChange() {
      let IMteriaList = IMaterialPreview.getMaterial();
      if (IMteriaList != null) {
        this.IMaterialGlass();
        // console.log("11",IMteriaList)
        this.color = IMteriaList.color;
        this.emissive  = IMteriaList.emissive;
        this.fog = IMteriaList.fog;
        this.side = IMteriaList.side;
        this.shininess = IMteriaList.shininess;
        this.specular = IMteriaList.specular;
        this.matcapIntensity = IMteriaList.matcapIntensity;
        if(IMteriaList.side == 0){
          this.renderSelect = this.renderOptionList[0].value;
        }else if(IMteriaList.side == 1){
          this.renderSelect = this.renderOptionList[1].value;
        }else if(IMteriaList.side == 2){
          this.renderSelect = this.renderOptionList[2].value;
        }
        this.opacity =Number(IMteriaList.opacity);
        this.transparent = IMteriaList.transparent;
        if(IMteriaList.type == 0){
          this.optionListValue = "基础材质"
        }else if(IMteriaList.type == 1){
          this.optionListValue = "标准材质"
        }else if(IMteriaList.type == 2){
          this.optionListValue = "冯氏材质"
        }else if( IMteriaList.type == 3 ){
          this.optionListValue = "卡通捕捉材质"
        }else if( IMteriaList.type == 4 ){
          this.optionListValue = "卡通捕捉扭动材质"
        }
        if(IMteriaList.aoMap == null){
          this.aoMap = IMteriaList.aoMap;
        }else{
          this.aoMap = IMteriaList.aoMap.image;
        }
        this.aoMapIntensity = IMteriaList.aoMapIntensity;
        if(IMteriaList.envMap != null && IMteriaList.envMap.image == undefined){
            let hdr = document.querySelector('#hdrImg5');
            let hdr1 = document.querySelector('#hdrImg4');
            let hdr2 = document.querySelector('#hdrImg2');
            hdr.style.background = "#0560FD";
            hdr1.style.background = "#0560FD"
            hdr2.style.background = "#0560FD"
        }
        if(IMteriaList.envMap == null){
          this.envMap = IMteriaList.envMap;
        }else{
          this.envMap = IMteriaList.envMap.image;
        }
        if(IMteriaList.lightMap == null){
           this.lightMap = IMteriaList.lightMap;
        }else{
           this.lightMap = IMteriaList.lightMap.image;
        }
        if(IMteriaList.gradientMap == null || IMteriaList.gradientMap == undefined){
          this.gradientMap = IMteriaList.gradientMap
        }else{
          this.gradientMap = IMteriaList.gradientMap.image
        }

        if(IMteriaList.matcap != null || IMteriaList.matcap != undefined){
          let hdr = document.querySelector('#hdrImg6');
          hdr.style.background = "#0560FD";
        }
        if(IMteriaList.matcap == null || IMteriaList.matcap == undefined){
          this.matMap = IMteriaList.matcap;
        }else{
          this.matMap = IMteriaList.matcap.image;
        }
        this.lightMapIntensity = IMteriaList.lightMapIntensity;
        if(IMteriaList.map == null){
          this.loction = false;
          this.map = IMteriaList.map;
        }else{
          this.loction = true;
          this.map = IMteriaList.map.image;
          this.ColorRepeatU = IMteriaList.map.repeat[0];
          this.ColorRepeatV = IMteriaList.map.repeat[1];
          this.ColorOffsetU = IMteriaList.map.offset[0];
          this.ColorOffsetV = IMteriaList.map.offset[1];
          this.ColorRotation = IMteriaList.map.rotation;
          this.colorGamma = IMteriaList.map.gamma;
          // console.log(IMteriaList.map)
        }
        this.reflectivity = IMteriaList.reflectivity;
        this.refractionRatio = IMteriaList.refractionRatio;
        if(IMteriaList.specularMap == null){
          this.specularMap = IMteriaList.specularMap;
        }else{
           this.specularMap = IMteriaList.specularMap.image;
        }
        if(IMteriaList.emissiveMap == null){
          this.missiveLoction = false;
          this.emissiveMap = IMteriaList.emissiveMap;
        }else{
          this.missiveLoction = true;
          this.emissiveMap = IMteriaList.emissiveMap.image;
          this.emissiveRepeatU = IMteriaList.emissiveMap.repeat[0];
          this.emissiveRepeatV = IMteriaList.emissiveMap.repeat[1];
          this.emissiveOffsetU = IMteriaList.emissiveMap.offset[0];
          this.emissiveOffsetV = IMteriaList.emissiveMap.offset[1];
          this.emissiveRotation = IMteriaList.emissiveMap.rotation;
          this.emissiveGamma = IMteriaList.emissiveMap.gamma;

        }
        this.emissiveIntensity = IMteriaList.emissiveIntensity;
        this.envMapIntensity = IMteriaList.envMapIntensity;
        let toMetalness = IMteriaList.metalness;
        var b = (typeof toMetalness === 'number' && isFinite(toMetalness));
        if(b)
        {
          this.metalness = Number(toMetalness.toFixed(2));
        }
        else
        {
          this.metalness = toMetalness;
        }
 
        if(IMteriaList.metalnessMap == null){
          this.MetaLoction = false;
          this.metalnessMap = IMteriaList.metalnessMap;
        }else{
          this.MetaLoction = true;
          this.metalnessMap = IMteriaList.metalnessMap.image;
          this.MetalnessRepeatU = IMteriaList.metalnessMap.repeat[0];
          this.MetalnessRepeatV = IMteriaList.metalnessMap.repeat[1];
          this.MetalnessOffsetU = IMteriaList.metalnessMap.offset[0];
          this.MetalnessOffsetV = IMteriaList.metalnessMap.offset[1];
          this.MetalnessRotation = IMteriaList.metalnessMap.rotation;
          this.MetalnessGamma = IMteriaList.metalnessMap.gamma;
        }
        if(IMteriaList.normalMap ==null){
          this.normalLoction = false;
          this.normalMap = IMteriaList.normalMap;
        }else{
          this.normalLoction = true;
          this.normalMap = IMteriaList.normalMap.image;
          this.normalRepeatU = IMteriaList.normalMap.repeat[0];
          this.normalRepeatV = IMteriaList.normalMap.repeat[1];
          this.normalOffsetU = IMteriaList.normalMap.offset[0];
          this.normalOffsetV = IMteriaList.normalMap.offset[1];
          this.normalRotation = IMteriaList.normalMap.rotation;
          this.normalGamma  = IMteriaList.normalMap.gamma;
        }
        let toRoughness = IMteriaList.roughness
        b = (typeof toRoughness === 'number' && isFinite(toRoughness));
        if(b)
        {
          this.roughness = Number(toRoughness.toFixed(2));
        }
        else
        {
          this.roughness = toRoughness;
        }
     
        if(IMteriaList.roughnessMap == null){
          this.roughnessLoction =false;
          this.roughnessMap = IMteriaList.roughnessMap;
        }else{
          this.roughnessLoction = true;
          this.roughnessMap = IMteriaList.roughnessMap.image;
          this.roughnessRepeatU = IMteriaList.roughnessMap.repeat[0];
          this.roughnessRepeatV = IMteriaList.roughnessMap.repeat[1];
          this.roughnessOffsetU = IMteriaList.roughnessMap.offset[0];
          this.roughnessOffsetV = IMteriaList.roughnessMap.offset[1];
          this.roughnessRotation = IMteriaList.roughnessMap.rotation;
          this.roughnessGamma = IMteriaList.roughnessMap.gamma;
        }
      }
      if(IMteriaList !=null && this.optionListValue == "标准材质"){
        this.StandardMaterial = true;
        this.BaseMaterial = false;
        this.PhongShader = false;
        this.CartoonCapture = false;
        this.header = true;
      }else if(IMteriaList != null && this.optionListValue == "基础材质"){
        this.BaseMaterial = true;
        this.StandardMaterial = false;
        this.PhongShader = false;
        this.CartoonCapture = false;
        this.header = true;
      }else if(IMteriaList != null && this.optionListValue == "冯氏材质"){
        this.PhongShader = true;
        this.BaseMaterial = false;
        this.StandardMaterial = false;
        this.CartoonCapture = false;
        this.header = true;
      }else if(IMteriaList != null && ( this.optionListValue == "卡通捕捉材质" || this.optionListValue == "卡通捕捉扭动材质")){
        this.CartoonCapture = true;
        this.PhongShader = false;
        this.BaseMaterial = false;
        this.StandardMaterial = false;
        this.header = true;
      }

      if(IMteriaList == null && this.optionListValue == "标准材质" ){
        this.StandardMaterial = false;
        this.header =false;
      }else if(IMteriaList == null && this.optionListValue == "基础材质"){
        this.BaseMaterial = false;
        this.header = false
      }else if(IMteriaList == null && this.optionListValue == "冯氏材质"){
        this.PhongShader = false;
        this.header = false
      }else if(IMteriaList == null && ( this.optionListValue == "卡通捕捉材质" || this.optionListValue == "卡通捕捉扭动材质")){
        this.CartoonCapture = false;
        this.header = false
      }
    },
    chartlet(){
      let IMteriaList = IMaterialPreview.getMaterial();
      if (IMteriaList != null) {
        if(IMteriaList.aoMap == null){
          this.aoMap = IMteriaList.aoMap;
        }else{
          this.aoMap = IMteriaList.aoMap.image;
        }
        if(IMteriaList.envMap == null){
          this.envMap = IMteriaList.envMap;
        }else{
          this.envMap = IMteriaList.envMap.image;
        }
        if(IMteriaList.lightMap == null){
           this.lightMap = IMteriaList.lightMap;
        }else{
           this.lightMap = IMteriaList.lightMap.image;
        }
        if(IMteriaList.map == null){
          this.map = IMteriaList.map;
        }else{
          this.map = IMteriaList.map.image;
        }
        if(IMteriaList.specularMap == null){
          this.specularMap = IMteriaList.specularMap;
        }else{
           this.specularMap = IMteriaList.specularMap.image;
        }
        if(IMteriaList.emissiveMap == null){
          this.emissiveMap = IMteriaList.emissiveMap;
        }else{
          this.emissiveMap = IMteriaList.emissiveMap.image;
        }
        if(IMteriaList.metalnessMap == null){
          this.metalnessMap = IMteriaList.metalnessMap;
        }else{
          this.metalnessMap = IMteriaList.metalnessMap.image;
        }
        if(IMteriaList.normalMap ==null){
          this.normalMap = IMteriaList.normalMap;
        }else{
          this.normalMap = IMteriaList.normalMap.image;
        }
        if(IMteriaList.roughnessMap == null){
          this.roughnessMap = IMteriaList.roughnessMap;
        }else{
          this.roughnessMap = IMteriaList.roughnessMap.image;
        }
      }
    },
    selectChange() {
        if(this.optionListValue == '基础材质'){
            IMaterialPreview.selectorMaterialType(0)
            this.BaseMaterial = true;
            this.StandardMaterial = false;
        }else if(this.optionListValue == '标准材质'){
            IMaterialPreview.selectorMaterialType(1)
            this.BaseMaterial = false;
            this.StandardMaterial = true;
        }else{
            this.BaseMaterial = false;
            this.StandardMaterial = false;
        }
    },
    renderChange() {
      let color = this.color.slice(1);
      let rgba = [
          parseInt('0x'+color.slice(0, 2)),
          parseInt('0x'+color.slice(2, 4)),
          parseInt('0x'+color.slice(4, 6)),
      ];
      let color0 = rgba[0]/255;
      let color1 = rgba[1]/255;
      let color2 = rgba[2]/255;
       if(this.renderSelect == 0 && this.optionListValue == '基础材质'){
         let data = {
          fog:this.fog,
          opacity:this.opacity,
          side:0,
          transparent:this.transparent,
          aoMapIntensity:this.aoMapIntensity,
          color:[color0,color1,color2],
          lightMapIntensity:this.lightMapIntensity,
          refractionRatio:this.refractionRatio,
        }
        IMaterialPreview.setBasicMaterial(data)
       }else if(this.renderSelect == 1 && this.optionListValue == '基础材质'){
         let data = {
            fog:this.fog,
            opacity:this.opacity,
            side:1,
            transparent:this.transparent,
            aoMapIntensity:this.aoMapIntensity,
            color:[color0,color1,color2],
            lightMapIntensity:this.lightMapIntensity,
            refractionRatio:this.refractionRatio,
          }
          IMaterialPreview.setBasicMaterial(data)
       }else if(this.renderSelect == 2 && this.optionListValue == '基础材质'){
         let data = {
            fog:this.fog,
            opacity:this.opacity,
            side:2,
            transparent:this.transparent,
            aoMapIntensity:this.aoMapIntensity,
            color:[color0,color1,color2],
            lightMapIntensity:this.lightMapIntensity,
            refractionRatio:this.refractionRatio,
          }
          IMaterialPreview.setBasicMaterial(data)
       }
    },
    renderChange1(){
      let color = this.color.slice(1);
      let rgba = [
          parseInt('0x'+color.slice(0, 2)),
          parseInt('0x'+color.slice(2, 4)),
          parseInt('0x'+color.slice(4, 6)),
      ];
      let color0 = rgba[0]/255;
      let color1 = rgba[1]/255;
      let color2 = rgba[2]/255;

      let emissiveColor = this.emissive.slice(1);
      let rgb = [
          parseInt('0x'+emissiveColor.slice(0, 2)),
          parseInt('0x'+emissiveColor.slice(2, 4)),
          parseInt('0x'+emissiveColor.slice(4, 6)),
      ];
      let emissiveColor0 = rgb[0]/255;
      let emissiveColor1 = rgb[1]/255;
      let emissiveColor2 = rgb[2]/255;
       if(this.renderSelect == 0 && this.optionListValue == '标准材质'){
         let data = {
          fog:this.fog,
          opacity:Number(this.opacity),
          side:0,
          transparent:this.transparent,
          aoMapIntensity:Number(this.aoMapIntensity),
          color:[color0,color1,color2],
          lightMapIntensity:Number(this.lightMapIntensity),
          refractionRatio:Number(this.refractionRatio),
          emissive:[emissiveColor0,emissiveColor1,emissiveColor2],
          emissiveIntensity:Number(this.emissiveIntensity),
          envMapIntensity:Number(this.envMapIntensity),
          metalness : Number(this.metalness),
          roughness:Number(this.roughness),
          matType:Number(this.MaterialsSelect),
          materialMat:{m_cauchyb:Number(this.cauchyb),m_exteriorior:Number(this.exterior),m_thickness:Number(this.thickness),m_interiorior:Number(this.interiorior)}
        }
        // IMaterialPreview.setStandardMaterial(data)
       }else if(this.renderSelect == 1 && this.optionListValue == '标准材质'){
         let data = {
          fog:this.fog,
          opacity:Number(this.opacity),
          side:1,
          transparent:this.transparent,
          aoMapIntensity:Number(this.aoMapIntensity),
          color:[color0,color1,color2],
          lightMapIntensity:Number(this.lightMapIntensity),
          refractionRatio:Number(this.refractionRatio),
          emissive:[emissiveColor0,emissiveColor1,emissiveColor2],
          emissiveIntensity:Number(this.emissiveIntensity),
          envMapIntensity:Number(this.envMapIntensity),
          metalness : Number(this.metalness),
          roughness:Number(this.roughness),
          matType:Number(this.MaterialsSelect),
           materialMat:{m_cauchyb:Number(this.cauchyb),m_exteriorior:Number(this.exterior),m_thickness:Number(this.thickness),m_interiorior:Number(this.interiorior)}
        }
        IMaterialPreview.setStandardMaterial(data)
       }else if(this.renderSelect == 2 && this.optionListValue == '标准材质'){
          let data = {
          fog:this.fog,
          opacity:Number(this.opacity),
          side:2,
          transparent:this.transparent,
          aoMapIntensity:Number(this.aoMapIntensity),
          color:[color0,color1,color2],
          lightMapIntensity:Number(this.lightMapIntensity),
          refractionRatio:Number(this.refractionRatio),
          emissive:[emissiveColor0,emissiveColor1,emissiveColor2],
          emissiveIntensity:Number(this.emissiveIntensity),
          envMapIntensity:Number(this.envMapIntensity),
          metalness : Number(this.metalness),
          roughness:Number(this.roughness),
          matType:Number(this.MaterialsSelect),
           materialMat:{m_cauchyb:Number(this.cauchyb),m_exteriorior:Number(this.exterior),m_thickness:Number(this.thickness),m_interiorior:Number(this.interiorior)}
        }
        IMaterialPreview.setStandardMaterial(data)
       }
    },
    renderChange2(){
      let color = this.color.slice(1);
      let rgba = [
          parseInt('0x'+color.slice(0, 2)),
          parseInt('0x'+color.slice(2, 4)),
          parseInt('0x'+color.slice(4, 6)),
      ];
      let color0 = rgba[0]/255;
      let color1 = rgba[1]/255;
      let color2 = rgba[2]/255;

      let emissiveColor = this.emissive.slice(1);
      let rgb = [
          parseInt('0x'+emissiveColor.slice(0, 2)),
          parseInt('0x'+emissiveColor.slice(2, 4)),
          parseInt('0x'+emissiveColor.slice(4, 6)),
      ];
      let emissiveColor0 = rgb[0]/255;
      let emissiveColor1 = rgb[1]/255;
      let emissiveColor2 = rgb[2]/255;

      let specular = this.specular.slice(1);
      let specularRgb = [
          parseInt('0x'+specular.slice(0, 2)),
          parseInt('0x'+specular.slice(2, 4)),
          parseInt('0x'+specular.slice(4, 6)),
      ];
      let specularColor0 = specularRgb[0]/255;
      let specularColor1 = specularRgb[1]/255;
      let specularColor2 = specularRgb[2]/255;

       if(this.renderSelect == 0 && this.optionListValue == '冯氏材质'){
        let data = {
          fog:this.fog,
          opacity:this.opacity,
          side:0,
          transparent:this.transparent,
          aoMapIntensity:Number(this.aoMapIntensity),
          color:[color0,color1,color2],
          lightMapIntensity:Number(this.lightMapIntensity),
          refractionRatio:Number(this.refractionRatio),
          shininess:Number(this.shininess),
          reflectivity:Number(this.reflectivity),
          emissive:[emissiveColor0,emissiveColor1,emissiveColor2],
          specular:[specularColor0,specularColor1,specularColor2],

          emissiveIntensity:Number(this.emissiveIntensity),
          envMapIntensity:Number(this.envMapIntensity),
        }
        // console.log(data)
        IMaterialPreview.setPhoneMaterial(data)
       }else if(this.renderSelect == 1 && this.optionListValue == '冯氏材质'){
        let data = {
          fog:this.fog,
          opacity:this.opacity,
          side:1,
          transparent:this.transparent,
          aoMapIntensity:Number(this.aoMapIntensity),
          color:[color0,color1,color2],
          lightMapIntensity:Number(this.lightMapIntensity),
          refractionRatio:Number(this.refractionRatio),
          shininess:Number(this.shininess),
          reflectivity:Number(this.reflectivity),
          emissive:[emissiveColor0,emissiveColor1,emissiveColor2],
          specular:[specularColor0,specularColor1,specularColor2],

          emissiveIntensity:Number(this.emissiveIntensity),
          envMapIntensity:Number(this.envMapIntensity),
        }
        // console.log(data)
        IMaterialPreview.setPhoneMaterial(data)
       }else if(this.renderSelect == 2 && this.optionListValue == '冯氏材质'){
        let data = {
          fog:this.fog,
          opacity:this.opacity,
          side:2,
          transparent:this.transparent,
          aoMapIntensity:Number(this.aoMapIntensity),
          color:[color0,color1,color2],
          lightMapIntensity:Number(this.lightMapIntensity),
          refractionRatio:Number(this.refractionRatio),
          shininess:Number(this.shininess),
          reflectivity:Number(this.reflectivity),
          emissive:[emissiveColor0,emissiveColor1,emissiveColor2],
          specular:[specularColor0,specularColor1,specularColor2],

          emissiveIntensity:Number(this.emissiveIntensity),
          envMapIntensity:Number(this.envMapIntensity),
        }
        // console.log(data)
        IMaterialPreview.setPhoneMaterial(data)
       }
    },
    // 卡通扭动渲染面
    renderChange3(){
      let color = this.color.slice(1);
      let rgba = [
          parseInt('0x'+color.slice(0, 2)),
          parseInt('0x'+color.slice(2, 4)),
          parseInt('0x'+color.slice(4, 6)),
      ];
      let color0 = rgba[0]/255;
      let color1 = rgba[1]/255;
      let color2 = rgba[2]/255;

      let emissiveColor = this.emissive.slice(1);
      let rgb = [
          parseInt('0x'+emissiveColor.slice(0, 2)),
          parseInt('0x'+emissiveColor.slice(2, 4)),
          parseInt('0x'+emissiveColor.slice(4, 6)),
      ];
      let emissiveColor0 = rgb[0]/255;
      let emissiveColor1 = rgb[1]/255;
      let emissiveColor2 = rgb[2]/255;

      let specular = this.specular.slice(1);
      let specularRgb = [
          parseInt('0x'+specular.slice(0, 2)),
          parseInt('0x'+specular.slice(2, 4)),
          parseInt('0x'+specular.slice(4, 6)),
      ];
      let specularColor0 = specularRgb[0]/255;
      let specularColor1 = specularRgb[1]/255;
      let specularColor2 = specularRgb[2]/255;

       if(this.renderSelect == 0 && this.optionListValue == '卡通捕捉材质'){
        let data = {
          fog:this.fog,
          opacity:this.opacity,
          side:0,
          transparent:this.transparent,
          aoMapIntensity:Number(this.aoMapIntensity),
          color:[color0,color1,color2],
          lightMapIntensity:Number(this.lightMapIntensity),
          refractionRatio:Number(this.refractionRatio),
          shininess:Number(this.shininess),
          reflectivity:Number(this.reflectivity),
          emissive:[emissiveColor0,emissiveColor1,emissiveColor2],
          specular:[specularColor0,specularColor1,specularColor2],

          emissiveIntensity:Number(this.emissiveIntensity),
          // envMapIntensity:Number(this.envMapIntensity),
          matcapIntensity:Number(this.matcapIntensity),
        }
        // console.log(data)
        IMaterialPreview.setToonPhoneMaterial(data,3)
       }else if(this.renderSelect == 1 && this.optionListValue == '卡通捕捉材质'){
        let data = {
          fog:this.fog,
          opacity:this.opacity,
          side:1,
          transparent:this.transparent,
          aoMapIntensity:Number(this.aoMapIntensity),
          color:[color0,color1,color2],
          lightMapIntensity:Number(this.lightMapIntensity),
          refractionRatio:Number(this.refractionRatio),
          shininess:Number(this.shininess),
          reflectivity:Number(this.reflectivity),
          emissive:[emissiveColor0,emissiveColor1,emissiveColor2],
          specular:[specularColor0,specularColor1,specularColor2],

          emissiveIntensity:Number(this.emissiveIntensity),
          envMapIntensity:Number(this.envMapIntensity),
        }
        // console.log(data)
        IMaterialPreview.setToonPhoneMaterial(data,3)
       }else if(this.renderSelect == 2 && this.optionListValue == '卡通捕捉材质'){
        let data = {
          fog:this.fog,
          opacity:this.opacity,
          side:2,
          transparent:this.transparent,
          aoMapIntensity:Number(this.aoMapIntensity),
          color:[color0,color1,color2],
          lightMapIntensity:Number(this.lightMapIntensity),
          refractionRatio:Number(this.refractionRatio),
          shininess:Number(this.shininess),
          reflectivity:Number(this.reflectivity),
          emissive:[emissiveColor0,emissiveColor1,emissiveColor2],
          specular:[specularColor0,specularColor1,specularColor2],

          emissiveIntensity:Number(this.emissiveIntensity),
          envMapIntensity:Number(this.envMapIntensity),
        }
        // console.log(data)
        IMaterialPreview.setToonPhoneMaterial(data,3)
       }

       if(this.renderSelect == 0 && this.optionListValue == '卡通捕捉扭动材质'){
        let data = {
          fog:this.fog,
          opacity:this.opacity,
          side:0,
          transparent:this.transparent,
          aoMapIntensity:Number(this.aoMapIntensity),
          color:[color0,color1,color2],
          lightMapIntensity:Number(this.lightMapIntensity),
          refractionRatio:Number(this.refractionRatio),
          shininess:Number(this.shininess),
          reflectivity:Number(this.reflectivity),
          emissive:[emissiveColor0,emissiveColor1,emissiveColor2],
          specular:[specularColor0,specularColor1,specularColor2],

          emissiveIntensity:Number(this.emissiveIntensity),
          // envMapIntensity:Number(this.envMapIntensity),
          matcapIntensity:Number(this.matcapIntensity),
        }
        // console.log(data)
        IMaterialPreview.setToonPhoneMaterial(data,4)
       }else if(this.renderSelect == 1 && this.optionListValue == '卡通捕捉扭动材质'){
        let data = {
          fog:this.fog,
          opacity:this.opacity,
          side:1,
          transparent:this.transparent,
          aoMapIntensity:Number(this.aoMapIntensity),
          color:[color0,color1,color2],
          lightMapIntensity:Number(this.lightMapIntensity),
          refractionRatio:Number(this.refractionRatio),
          shininess:Number(this.shininess),
          reflectivity:Number(this.reflectivity),
          emissive:[emissiveColor0,emissiveColor1,emissiveColor2],
          specular:[specularColor0,specularColor1,specularColor2],

          emissiveIntensity:Number(this.emissiveIntensity),
          envMapIntensity:Number(this.envMapIntensity),
        }
        // console.log(data)
        IMaterialPreview.setToonPhoneMaterial(data,3)
       }else if(this.renderSelect == 2 && this.optionListValue == '卡通捕捉扭动材质'){
        let data = {
          fog:this.fog,
          opacity:this.opacity,
          side:2,
          transparent:this.transparent,
          aoMapIntensity:Number(this.aoMapIntensity),
          color:[color0,color1,color2],
          lightMapIntensity:Number(this.lightMapIntensity),
          refractionRatio:Number(this.refractionRatio),
          shininess:Number(this.shininess),
          reflectivity:Number(this.reflectivity),
          emissive:[emissiveColor0,emissiveColor1,emissiveColor2],
          specular:[specularColor0,specularColor1,specularColor2],

          emissiveIntensity:Number(this.emissiveIntensity),
          envMapIntensity:Number(this.envMapIntensity),
        }
        // console.log(data)
        IMaterialPreview.setToonPhoneMaterial(data,4)
       }
    },
    //材料选择
    MaterialsChange(){
      setTimeout(() => {
        let IMteriaList = IMaterialPreview.getMaterial();
        if (IMteriaList != null) {
          console.log(IMteriaList)
          if(IMteriaList.matType == 1){
            this.exterior = IMteriaList.materialMat.m_exteriorior;
            this.interiorior = IMteriaList.materialMat.m_interiorior;
            this.cauchyb = IMteriaList.materialMat.m_cauchyb;
          }else if(IMteriaList.matType == 2){
            this.exterior = IMteriaList.materialMat.m_exteriorior;
            this.interiorior = IMteriaList.materialMat.m_interiorior;
          }else if(IMteriaList.matType == 4){
            this.thickness = IMteriaList.materialMat.m_thickness;
          }
        }
      }, 300);
      let color = this.color.slice(1);
      let rgba = [
          parseInt('0x'+color.slice(0, 2)),
          parseInt('0x'+color.slice(2, 4)),
          parseInt('0x'+color.slice(4, 6)),
      ];
      let color0 = rgba[0]/255;
      let color1 = rgba[1]/255;
      let color2 = rgba[2]/255;

      let emissiveColor = this.emissive.slice(1);
      let rgb = [
          parseInt('0x'+emissiveColor.slice(0, 2)),
          parseInt('0x'+emissiveColor.slice(2, 4)),
          parseInt('0x'+emissiveColor.slice(4, 6)),
      ];
      let emissiveColor0 = rgb[0]/255;
      let emissiveColor1 = rgb[1]/255;
      let emissiveColor2 = rgb[2]/255;
      let data = {
          fog:this.fog,
          opacity:Number(this.opacity),
          side:2,
          transparent:this.transparent,
          aoMapIntensity:Number(this.aoMapIntensity),
          color:[color0,color1,color2],
          lightMapIntensity:Number(this.lightMapIntensity),
          refractionRatio:Number(this.refractionRatio),
          emissive:[emissiveColor0,emissiveColor1,emissiveColor2],
          emissiveIntensity:Number(this.emissiveIntensity),
          envMapIntensity:Number(this.envMapIntensity),
          metalness : Number(this.metalness),
          roughness:Number(this.roughness),
          matType:Number(this.MaterialsSelect),
          materialMat:{m_cauchyb:Number(this.cauchyb),m_exteriorior:Number(this.exterior),m_thickness:Number(this.thickness),m_interiorior:Number(this.interiorior)}
        }
        // this.IMaterialGlass()
        IMaterialPreview.setStandardMaterial(data)
    },
    shrinkChange(){
        if (this.isActive) {
          this.isActive = false
        } else {
          this.isActive = true;
        }
    },
    ColorChange(){
      if (this.ColorActive) {
        this.ColorActive = false
      } else {
        this.ColorActive = true;
      }
    },
    metalChange(){
      if (this.metalActive) {
        this.metalActive = false
      } else {
        this.metalActive = true;
      }
    },
    roughChange(){
      if (this.roughActive) {
        this.roughActive = false
      } else {
        this.roughActive = true;
      }
    },
    reflectivityChange(){
      if (this.reflectivityActive) {
        this.reflectivityActive = false
      } else {
        this.reflectivityActive = true;
      }
    },
    normalChange(){
      if (this.normalActive) {
        this.normalActive = false
      } else {
        this.normalActive = true;
      }
    },
    specularChange(){
      if (this.specularActive) {
        this.specularActive = false
      } else {
        this.specularActive = true;
      }
    },
    bumpChange(){
      if (this.bumpActive) {
        this.bumpActive = false
      } else {
        this.bumpActive = true;
      }
    },
    emissiveChange(){
      if (this.emissiveActive) {
        this.emissiveActive = false
      } else {
        this.emissiveActive = true;
      }
    },
    //空气折射lv
    ratioChange(){
      let color = this.color.slice(1);
      let rgba = [
          parseInt('0x'+color.slice(0, 2)),
          parseInt('0x'+color.slice(2, 4)),
          parseInt('0x'+color.slice(4, 6)),
      ];
      let color0 = rgba[0]/255;
      let color1 = rgba[1]/255;
      let color2 = rgba[2]/255;
       let data = {
          fog:this.fog,
          opacity:this.opacity,
          side:this.side,
          transparent:this.transparent,
          aoMapIntensity:this.aoMapIntensity,
          color:[color0,color1,color2],
          lightMapIntensity:this.lightMapIntensity,
          refractionRatio:this.refractionRatio,
        }
        // console.log(data)
        IMaterialPreview.setBasicMaterial(data)
    },
    //透明度
    opacityChange(){
      let color = this.color.slice(1);
      let rgba = [
          parseInt('0x'+color.slice(0, 2)),
          parseInt('0x'+color.slice(2, 4)),
          parseInt('0x'+color.slice(4, 6)),
      ];
      let color0 = rgba[0]/255;
      let color1 = rgba[1]/255;
      let color2 = rgba[2]/255;
      
      if(this.optionListValue == '基础材质'){
        let data = {
          fog:this.fog,
          opacity:this.opacity,
          side:this.side,
          transparent:this.transparent,
          aoMapIntensity:this.aoMapIntensity,
          color:[color0,color1,color2],
          lightMapIntensity:this.lightMapIntensity,
          refractionRatio:this.refractionRatio,
        }
        IMaterialPreview.setBasicMaterial(data)
      }else if(this.optionListValue == '标准材质'){
        let emissiveColor = this.emissive.slice(1);
        let rgb = [
            parseInt('0x'+emissiveColor.slice(0, 2)),
            parseInt('0x'+emissiveColor.slice(2, 4)),
            parseInt('0x'+emissiveColor.slice(4, 6)),
        ];
        let emissiveColor0 = rgb[0]/255;
        let emissiveColor1 = rgb[1]/255;
        let emissiveColor2 = rgb[2]/255;

          let data = {
          fog:this.fog,
          opacity:this.opacity,
          side:this.side,
          transparent:this.transparent,
          aoMapIntensity:Number(this.aoMapIntensity),
          color:[color0,color1,color2],
          lightMapIntensity:Number(this.lightMapIntensity),
          refractionRatio:Number(this.refractionRatio),
          emissive:[emissiveColor0,emissiveColor1,emissiveColor2],
          emissiveIntensity:Number(this.emissiveIntensity),
          envMapIntensity:Number(this.envMapIntensity),
          metalness : Number(this.metalness),
          roughness:Number(this.roughness),
          matType:Number(this.MaterialsSelect),
           materialMat:{m_cauchyb:Number(this.cauchyb),m_exteriorior:Number(this.exterior),m_thickness:Number(this.thickness),m_interiorior:Number(this.interiorior)}
        }
        // console.log(data)
        IMaterialPreview.setStandardMaterial(data)
      }else if(this.optionListValue == "冯氏材质"){
        let emissiveColor = this.emissive.slice(1);
        let rgb = [
            parseInt('0x'+emissiveColor.slice(0, 2)),
            parseInt('0x'+emissiveColor.slice(2, 4)),
            parseInt('0x'+emissiveColor.slice(4, 6)),
        ];
        let emissiveColor0 = rgb[0]/255;
        let emissiveColor1 = rgb[1]/255;
        let emissiveColor2 = rgb[2]/255;

        let specular = this.specular.slice(1);
        let specularRgb = [
            parseInt('0x'+specular.slice(0, 2)),
            parseInt('0x'+specular.slice(2, 4)),
            parseInt('0x'+specular.slice(4, 6)),
        ];
        let specularColor0 = specularRgb[0]/255;
        let specularColor1 = specularRgb[1]/255;
        let specularColor2 = specularRgb[2]/255;


        let data = {
          fog:this.fog,
          opacity:this.opacity,
          side:this.side,
          transparent:this.transparent,
          aoMapIntensity:Number(this.aoMapIntensity),
          color:[color0,color1,color2],
          lightMapIntensity:Number(this.lightMapIntensity),
          refractionRatio:Number(this.refractionRatio),
          shininess:Number(this.shininess),
          reflectivity:Number(this.reflectivity),
          emissive:[emissiveColor0,emissiveColor1,emissiveColor2],
          specular:[specularColor0,specularColor1,specularColor2],

          emissiveIntensity:Number(this.emissiveIntensity),
          envMapIntensity:Number(this.envMapIntensity),
        }
        // console.log(data)
        IMaterialPreview.setPhoneMaterial(data)
      }else if(this.optionListValue == "卡通捕捉材质"){
        let emissiveColor = this.emissive.slice(1);
        let rgb = [
            parseInt('0x'+emissiveColor.slice(0, 2)),
            parseInt('0x'+emissiveColor.slice(2, 4)),
            parseInt('0x'+emissiveColor.slice(4, 6)),
        ];
        let emissiveColor0 = rgb[0]/255;
        let emissiveColor1 = rgb[1]/255;
        let emissiveColor2 = rgb[2]/255;

        let specular = this.specular.slice(1);
        let specularRgb = [
            parseInt('0x'+specular.slice(0, 2)),
            parseInt('0x'+specular.slice(2, 4)),
            parseInt('0x'+specular.slice(4, 6)),
        ];
        let specularColor0 = specularRgb[0]/255;
        let specularColor1 = specularRgb[1]/255;
        let specularColor2 = specularRgb[2]/255;


        let data = {
          fog:this.fog,
          opacity:this.opacity,
          side:this.side,
          transparent:this.transparent,
          aoMapIntensity:Number(this.aoMapIntensity),
          color:[color0,color1,color2],
          lightMapIntensity:Number(this.lightMapIntensity),
          refractionRatio:Number(this.refractionRatio),
          shininess:Number(this.shininess),
          reflectivity:Number(this.reflectivity),
          emissive:[emissiveColor0,emissiveColor1,emissiveColor2],
          specular:[specularColor0,specularColor1,specularColor2],

          emissiveIntensity:Number(this.emissiveIntensity),
          // envMapIntensity:Number(this.envMapIntensity),
          matcapIntensity:Number(this.matcapIntensity),
        }
        // console.log(data)
        IMaterialPreview.setToonPhoneMaterial(data,3)
      }else if(this.optionListValue == "卡通捕捉扭动材质"){
        let emissiveColor = this.emissive.slice(1);
        let rgb = [
            parseInt('0x'+emissiveColor.slice(0, 2)),
            parseInt('0x'+emissiveColor.slice(2, 4)),
            parseInt('0x'+emissiveColor.slice(4, 6)),
        ];
        let emissiveColor0 = rgb[0]/255;
        let emissiveColor1 = rgb[1]/255;
        let emissiveColor2 = rgb[2]/255;

        let specular = this.specular.slice(1);
        let specularRgb = [
            parseInt('0x'+specular.slice(0, 2)),
            parseInt('0x'+specular.slice(2, 4)),
            parseInt('0x'+specular.slice(4, 6)),
        ];
        let specularColor0 = specularRgb[0]/255;
        let specularColor1 = specularRgb[1]/255;
        let specularColor2 = specularRgb[2]/255;


        let data = {
          fog:this.fog,
          opacity:this.opacity,
          side:this.side,
          transparent:this.transparent,
          aoMapIntensity:Number(this.aoMapIntensity),
          color:[color0,color1,color2],
          lightMapIntensity:Number(this.lightMapIntensity),
          refractionRatio:Number(this.refractionRatio),
          shininess:Number(this.shininess),
          reflectivity:Number(this.reflectivity),
          emissive:[emissiveColor0,emissiveColor1,emissiveColor2],
          specular:[specularColor0,specularColor1,specularColor2],

          emissiveIntensity:Number(this.emissiveIntensity),
          // envMapIntensity:Number(this.envMapIntensity),
          matcapIntensity:Number(this.matcapIntensity),
        }
        // console.log(data)
        IMaterialPreview.setToonPhoneMaterial(data,4)
      }
    },
    //金属度滑条
    metalnessChange(){
      let color = this.color.slice(1);
      let rgba = [
          parseInt('0x'+color.slice(0, 2)),
          parseInt('0x'+color.slice(2, 4)),
          parseInt('0x'+color.slice(4, 6)),
      ];
      let color0 = rgba[0]/255;
      let color1 = rgba[1]/255;
      let color2 = rgba[2]/255;

      let emissiveColor = this.emissive.slice(1);
      let rgb = [
          parseInt('0x'+emissiveColor.slice(0, 2)),
          parseInt('0x'+emissiveColor.slice(2, 4)),
          parseInt('0x'+emissiveColor.slice(4, 6)),
      ];
      let emissiveColor0 = rgb[0]/255;
      let emissiveColor1 = rgb[1]/255;
      let emissiveColor2 = rgb[2]/255;
      let data = {
          fog:this.fog,
          opacity:this.opacity,
          side:this.side,
          transparent:this.transparent,
          aoMapIntensity:Number(this.aoMapIntensity),
          color:[color0,color1,color2],
          lightMapIntensity:Number(this.lightMapIntensity),
          refractionRatio:Number(this.refractionRatio),
          emissive:[emissiveColor0,emissiveColor1,emissiveColor2],
          emissiveIntensity:Number(this.emissiveIntensity),
          envMapIntensity:Number(this.envMapIntensity),
          metalness : Number(this.metalness),
          roughness:Number(this.roughness),
          matType:Number(this.MaterialsSelect),
           materialMat:{m_cauchyb:Number(this.cauchyb),m_exteriorior:Number(this.exterior),m_thickness:Number(this.thickness),m_interiorior:Number(this.interiorior)}
        }
        // console.log(data)
        IMaterialPreview.setStandardMaterial(data)
    },
    //粗糙度滑条
    roughnessChange(){
      let color = this.color.slice(1);
      let rgba = [
          parseInt('0x'+color.slice(0, 2)),
          parseInt('0x'+color.slice(2, 4)),
          parseInt('0x'+color.slice(4, 6)),
      ];
      let color0 = rgba[0]/255;
      let color1 = rgba[1]/255;
      let color2 = rgba[2]/255;

      let emissiveColor = this.emissive.slice(1);
      let rgb = [
          parseInt('0x'+emissiveColor.slice(0, 2)),
          parseInt('0x'+emissiveColor.slice(2, 4)),
          parseInt('0x'+emissiveColor.slice(4, 6)),
      ];
      let emissiveColor0 = rgb[0]/255;
      let emissiveColor1 = rgb[1]/255;
      let emissiveColor2 = rgb[2]/255;
      let data = {
          fog:this.fog,
          opacity:this.opacity,
          side:this.side,
          transparent:this.transparent,
          aoMapIntensity:Number(this.aoMapIntensity),
          color:[color0,color1,color2],
          lightMapIntensity:Number(this.lightMapIntensity),
          refractionRatio:Number(this.refractionRatio),
          emissive:[emissiveColor0,emissiveColor1,emissiveColor2],
          emissiveIntensity:Number(this.emissiveIntensity),
          envMapIntensity:Number(this.envMapIntensity),
          metalness : Number(this.metalness),
          roughness:Number(this.roughness),
          matType:Number(this.MaterialsSelect),
           materialMat:{m_cauchyb:Number(this.cauchyb),m_exteriorior:Number(this.exterior),m_thickness:Number(this.thickness),m_interiorior:Number(this.interiorior)}
        }
        // console.log(data)
        IMaterialPreview.setStandardMaterial(data)
    },
    //折射比滑条
    refractionRatioChange(){
      if(this.optionListValue == "冯氏材质"){
        let color = this.color.slice(1);
        let rgba = [
            parseInt('0x'+color.slice(0, 2)),
            parseInt('0x'+color.slice(2, 4)),
            parseInt('0x'+color.slice(4, 6)),
        ];
        let color0 = rgba[0]/255;
        let color1 = rgba[1]/255;
        let color2 = rgba[2]/255;

        let emissiveColor = this.emissive.slice(1);
        let rgb = [
            parseInt('0x'+emissiveColor.slice(0, 2)),
            parseInt('0x'+emissiveColor.slice(2, 4)),
            parseInt('0x'+emissiveColor.slice(4, 6)),
        ];
        let emissiveColor0 = rgb[0]/255;
        let emissiveColor1 = rgb[1]/255;
        let emissiveColor2 = rgb[2]/255;

        let specular = this.specular.slice(1);
        let specularRgb = [
            parseInt('0x'+specular.slice(0, 2)),
            parseInt('0x'+specular.slice(2, 4)),
            parseInt('0x'+specular.slice(4, 6)),
        ];
        let specularColor0 = specularRgb[0]/255;
        let specularColor1 = specularRgb[1]/255;
        let specularColor2 = specularRgb[2]/255;

        let data = {
            fog:this.fog,
            opacity:this.opacity,
            side:this.side,
            transparent:this.transparent,
            aoMapIntensity:Number(this.aoMapIntensity),
            color:[color0,color1,color2],
            lightMapIntensity:Number(this.lightMapIntensity),
            refractionRatio:Number(this.refractionRatio),
            shininess:Number(this.shininess),
            reflectivity:Number(this.reflectivity),
            emissive:[emissiveColor0,emissiveColor1,emissiveColor2],
            specular:[specularColor0,specularColor1,specularColor2],

            emissiveIntensity:Number(this.emissiveIntensity),
            envMapIntensity:Number(this.envMapIntensity),
          }
          // console.log(data)
          IMaterialPreview.setPhoneMaterial(data)
        }else if(this.optionListValue == "卡通捕捉材质"){
          let color = this.color.slice(1);
        let rgba = [
            parseInt('0x'+color.slice(0, 2)),
            parseInt('0x'+color.slice(2, 4)),
            parseInt('0x'+color.slice(4, 6)),
        ];
        let color0 = rgba[0]/255;
        let color1 = rgba[1]/255;
        let color2 = rgba[2]/255;

        let emissiveColor = this.emissive.slice(1);
        let rgb = [
            parseInt('0x'+emissiveColor.slice(0, 2)),
            parseInt('0x'+emissiveColor.slice(2, 4)),
            parseInt('0x'+emissiveColor.slice(4, 6)),
        ];
        let emissiveColor0 = rgb[0]/255;
        let emissiveColor1 = rgb[1]/255;
        let emissiveColor2 = rgb[2]/255;

        let specular = this.specular.slice(1);
        let specularRgb = [
            parseInt('0x'+specular.slice(0, 2)),
            parseInt('0x'+specular.slice(2, 4)),
            parseInt('0x'+specular.slice(4, 6)),
        ];
        let specularColor0 = specularRgb[0]/255;
        let specularColor1 = specularRgb[1]/255;
        let specularColor2 = specularRgb[2]/255;

        let data = {
            fog:this.fog,
            opacity:this.opacity,
            side:this.side,
            transparent:this.transparent,
            aoMapIntensity:Number(this.aoMapIntensity),
            color:[color0,color1,color2],
            lightMapIntensity:Number(this.lightMapIntensity),
            refractionRatio:Number(this.refractionRatio),
            shininess:Number(this.shininess),
            reflectivity:Number(this.reflectivity),
            emissive:[emissiveColor0,emissiveColor1,emissiveColor2],
            specular:[specularColor0,specularColor1,specularColor2],

            emissiveIntensity:Number(this.emissiveIntensity),
            // envMapIntensity:Number(this.envMapIntensity),
            matcapIntensity:Number(this.matcapIntensity),
          }
          // console.log(data)
          IMaterialPreview.setToonPhoneMaterial(data,3)
        }else if(this.optionListValue == "卡通捕捉扭动材质" ){
          let color = this.color.slice(1);
        let rgba = [
            parseInt('0x'+color.slice(0, 2)),
            parseInt('0x'+color.slice(2, 4)),
            parseInt('0x'+color.slice(4, 6)),
        ];
        let color0 = rgba[0]/255;
        let color1 = rgba[1]/255;
        let color2 = rgba[2]/255;

        let emissiveColor = this.emissive.slice(1);
        let rgb = [
            parseInt('0x'+emissiveColor.slice(0, 2)),
            parseInt('0x'+emissiveColor.slice(2, 4)),
            parseInt('0x'+emissiveColor.slice(4, 6)),
        ];
        let emissiveColor0 = rgb[0]/255;
        let emissiveColor1 = rgb[1]/255;
        let emissiveColor2 = rgb[2]/255;

        let specular = this.specular.slice(1);
        let specularRgb = [
            parseInt('0x'+specular.slice(0, 2)),
            parseInt('0x'+specular.slice(2, 4)),
            parseInt('0x'+specular.slice(4, 6)),
        ];
        let specularColor0 = specularRgb[0]/255;
        let specularColor1 = specularRgb[1]/255;
        let specularColor2 = specularRgb[2]/255;

        let data = {
            fog:this.fog,
            opacity:this.opacity,
            side:this.side,
            transparent:this.transparent,
            aoMapIntensity:Number(this.aoMapIntensity),
            color:[color0,color1,color2],
            lightMapIntensity:Number(this.lightMapIntensity),
            refractionRatio:Number(this.refractionRatio),
            shininess:Number(this.shininess),
            reflectivity:Number(this.reflectivity),
            emissive:[emissiveColor0,emissiveColor1,emissiveColor2],
            specular:[specularColor0,specularColor1,specularColor2],

            emissiveIntensity:Number(this.emissiveIntensity),
            // envMapIntensity:Number(this.envMapIntensity),
            matcapIntensity:Number(this.matcapIntensity),
          }
          // console.log(data)
          IMaterialPreview.setToonPhoneMaterial(data,4)
        }
    },
    //反射率滑条
    reflectivitySlider(){
      let color = this.color.slice(1);
      let rgba = [
          parseInt('0x'+color.slice(0, 2)),
          parseInt('0x'+color.slice(2, 4)),
          parseInt('0x'+color.slice(4, 6)),
      ];
      let color0 = rgba[0]/255;
      let color1 = rgba[1]/255;
      let color2 = rgba[2]/255;

      let emissiveColor = this.emissive.slice(1);
      let rgb = [
          parseInt('0x'+emissiveColor.slice(0, 2)),
          parseInt('0x'+emissiveColor.slice(2, 4)),
          parseInt('0x'+emissiveColor.slice(4, 6)),
      ];
      let emissiveColor0 = rgb[0]/255;
      let emissiveColor1 = rgb[1]/255;
      let emissiveColor2 = rgb[2]/255;

      let specular = this.specular.slice(1);
      let specularRgb = [
          parseInt('0x'+specular.slice(0, 2)),
          parseInt('0x'+specular.slice(2, 4)),
          parseInt('0x'+specular.slice(4, 6)),
      ];
      let specularColor0 = specularRgb[0]/255;
      let specularColor1 = specularRgb[1]/255;
      let specularColor2 = specularRgb[2]/255;

      let data = {
          fog:this.fog,
          opacity:this.opacity,
          side:this.side,
          transparent:this.transparent,
          aoMapIntensity:Number(this.aoMapIntensity),
          color:[color0,color1,color2],
          lightMapIntensity:Number(this.lightMapIntensity),
          refractionRatio:Number(this.refractionRatio),
          shininess:Number(this.shininess),
          reflectivity:Number(this.reflectivity),
          emissive:[emissiveColor0,emissiveColor1,emissiveColor2],
          specular:[specularColor0,specularColor1,specularColor2],

          emissiveIntensity:Number(this.emissiveIntensity),
          envMapIntensity:Number(this.envMapIntensity),
        }
        // console.log(data)
        IMaterialPreview.setPhoneMaterial(data)
    },
    //捕捉纹理滑条
    CaptureTextureSlider(){
      let color = this.color.slice(1);
      let rgba = [
          parseInt('0x'+color.slice(0, 2)),
          parseInt('0x'+color.slice(2, 4)),
          parseInt('0x'+color.slice(4, 6)),
      ];
      let color0 = rgba[0]/255;
      let color1 = rgba[1]/255;
      let color2 = rgba[2]/255;

      let emissiveColor = this.emissive.slice(1);
      let rgb = [
          parseInt('0x'+emissiveColor.slice(0, 2)),
          parseInt('0x'+emissiveColor.slice(2, 4)),
          parseInt('0x'+emissiveColor.slice(4, 6)),
      ];
      let emissiveColor0 = rgb[0]/255;
      let emissiveColor1 = rgb[1]/255;
      let emissiveColor2 = rgb[2]/255;

      let specular = this.specular.slice(1);
      let specularRgb = [
          parseInt('0x'+specular.slice(0, 2)),
          parseInt('0x'+specular.slice(2, 4)),
          parseInt('0x'+specular.slice(4, 6)),
      ];
      let specularColor0 = specularRgb[0]/255;
      let specularColor1 = specularRgb[1]/255;
      let specularColor2 = specularRgb[2]/255;
      if(this.optionListValue == "卡通捕捉材质"){
        let data = {
          fog:this.fog,
          opacity:this.opacity,
          side:this.side,
          transparent:this.transparent,
          aoMapIntensity:Number(this.aoMapIntensity),
          color:[color0,color1,color2],
          lightMapIntensity:Number(this.lightMapIntensity),
          refractionRatio:Number(this.refractionRatio),
          shininess:Number(this.shininess),
          reflectivity:Number(this.reflectivity),
          emissive:[emissiveColor0,emissiveColor1,emissiveColor2],
          specular:[specularColor0,specularColor1,specularColor2],

          emissiveIntensity:Number(this.emissiveIntensity),
          // envMapIntensity:Number(this.envMapIntensity),
          matcapIntensity:Number(this.matcapIntensity),
        }
        // console.log(data)
        IMaterialPreview.setToonPhoneMaterial(data,3)
      }else if(this.optionListValue == "卡通捕捉扭动材质"){
        let data = {
          fog:this.fog,
          opacity:this.opacity,
          side:this.side,
          transparent:this.transparent,
          aoMapIntensity:Number(this.aoMapIntensity),
          color:[color0,color1,color2],
          lightMapIntensity:Number(this.lightMapIntensity),
          refractionRatio:Number(this.refractionRatio),
          shininess:Number(this.shininess),
          reflectivity:Number(this.reflectivity),
          emissive:[emissiveColor0,emissiveColor1,emissiveColor2],
          specular:[specularColor0,specularColor1,specularColor2],

          emissiveIntensity:Number(this.emissiveIntensity),
          // envMapIntensity:Number(this.envMapIntensity),
          matcapIntensity:Number(this.matcapIntensity),
        }
        // console.log(data)
        IMaterialPreview.setToonPhoneMaterial(data,4)
      }
    },
    // 卡通光泽度滑条
    shininessChange1(){
      let color = this.color.slice(1);
      let rgba = [
          parseInt('0x'+color.slice(0, 2)),
          parseInt('0x'+color.slice(2, 4)),
          parseInt('0x'+color.slice(4, 6)),
      ];
      let color0 = rgba[0]/255;
      let color1 = rgba[1]/255;
      let color2 = rgba[2]/255;

      let emissiveColor = this.emissive.slice(1);
      let rgb = [
          parseInt('0x'+emissiveColor.slice(0, 2)),
          parseInt('0x'+emissiveColor.slice(2, 4)),
          parseInt('0x'+emissiveColor.slice(4, 6)),
      ];
      let emissiveColor0 = rgb[0]/255;
      let emissiveColor1 = rgb[1]/255;
      let emissiveColor2 = rgb[2]/255;

      let specular = this.specular.slice(1);
      let specularRgb = [
          parseInt('0x'+specular.slice(0, 2)),
          parseInt('0x'+specular.slice(2, 4)),
          parseInt('0x'+specular.slice(4, 6)),
      ];
      let specularColor0 = specularRgb[0]/255;
      let specularColor1 = specularRgb[1]/255;
      let specularColor2 = specularRgb[2]/255;
      if(this.optionListValue == "卡通捕捉材质"){
        let data = {
          fog:this.fog,
          opacity:this.opacity,
          side:this.side,
          transparent:this.transparent,
          aoMapIntensity:Number(this.aoMapIntensity),
          color:[color0,color1,color2],
          lightMapIntensity:Number(this.lightMapIntensity),
          refractionRatio:Number(this.refractionRatio),
          shininess:Number(this.shininess),
          reflectivity:Number(this.reflectivity),
          emissive:[emissiveColor0,emissiveColor1,emissiveColor2],
          specular:[specularColor0,specularColor1,specularColor2],

          emissiveIntensity:Number(this.emissiveIntensity),
          // envMapIntensity:Number(this.envMapIntensity),
          matcapIntensity:Number(this.matcapIntensity),
        }
        // console.log(data)
        IMaterialPreview.setToonPhoneMaterial(data,3)
      }else if(this.optionListValue == "卡通捕捉扭动材质"){
        let data = {
          fog:this.fog,
          opacity:this.opacity,
          side:this.side,
          transparent:this.transparent,
          aoMapIntensity:Number(this.aoMapIntensity),
          color:[color0,color1,color2],
          lightMapIntensity:Number(this.lightMapIntensity),
          refractionRatio:Number(this.refractionRatio),
          shininess:Number(this.shininess),
          reflectivity:Number(this.reflectivity),
          emissive:[emissiveColor0,emissiveColor1,emissiveColor2],
          specular:[specularColor0,specularColor1,specularColor2],

          emissiveIntensity:Number(this.emissiveIntensity),
          // envMapIntensity:Number(this.envMapIntensity),
          matcapIntensity:Number(this.matcapIntensity),
        }
        // console.log(data)
        IMaterialPreview.setToonPhoneMaterial(data,4)
      }
    },
    //光泽度滑条
    shininessChange(){
      let color = this.color.slice(1);
      let rgba = [
          parseInt('0x'+color.slice(0, 2)),
          parseInt('0x'+color.slice(2, 4)),
          parseInt('0x'+color.slice(4, 6)),
      ];
      let color0 = rgba[0]/255;
      let color1 = rgba[1]/255;
      let color2 = rgba[2]/255;

      let emissiveColor = this.emissive.slice(1);
      let rgb = [
          parseInt('0x'+emissiveColor.slice(0, 2)),
          parseInt('0x'+emissiveColor.slice(2, 4)),
          parseInt('0x'+emissiveColor.slice(4, 6)),
      ];
      let emissiveColor0 = rgb[0]/255;
      let emissiveColor1 = rgb[1]/255;
      let emissiveColor2 = rgb[2]/255;

      let specular = this.specular.slice(1);
      let specularRgb = [
          parseInt('0x'+specular.slice(0, 2)),
          parseInt('0x'+specular.slice(2, 4)),
          parseInt('0x'+specular.slice(4, 6)),
      ];
      let specularColor0 = specularRgb[0]/255;
      let specularColor1 = specularRgb[1]/255;
      let specularColor2 = specularRgb[2]/255;

      let data = {
          fog:this.fog,
          opacity:this.opacity,
          side:this.side,
          transparent:this.transparent,
          aoMapIntensity:Number(this.aoMapIntensity),
          color:[color0,color1,color2],
          lightMapIntensity:Number(this.lightMapIntensity),
          refractionRatio:Number(this.refractionRatio),
          shininess:Number(this.shininess),
          reflectivity:Number(this.reflectivity),
          emissive:[emissiveColor0,emissiveColor1,emissiveColor2],
          specular:[specularColor0,specularColor1,specularColor2],

          emissiveIntensity:Number(this.emissiveIntensity),
          envMapIntensity:Number(this.envMapIntensity),
        }
        // console.log(data)
        IMaterialPreview.setPhoneMaterial(data)
    },
    //input框加减
    changeNum(){
      // let checkPlan = '' + this.metalness;
      // checkPlan = checkPlan
      //   .replace(/[^\d.]/g, '') // 清除“数字”和“.”以外的字符
      //   .replace(/\.{2,}/g, '.') // 只保留第一个. 清除多余的
      //   .replace(/^\./g, '') // 保证第一个为数字而不是.
      //   .replace('.', '$#$')
      //   .replace(/\./g, '')
      //   .replace('$#$', '.')
      // if (checkPlan.indexOf('.') < 0 && checkPlan !== '') {
      //   // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
      //   checkPlan = parseFloat(checkPlan) + ''
      // } else if (checkPlan.indexOf('.') >= 0) {
      //   checkPlan = checkPlan
      //     .replace(/^()*(\d+)\.(\d\d).*$/, '$1$2.$3') // 只能输入两个小数
      // }
      // this.metalness = checkPlan

      // let checkPlan1 = '' + Number(this.roughness);
      // checkPlan1 = checkPlan1
      //   .replace(/[^\d.]/g, '') // 清除“数字”和“.”以外的字符
      //   .replace(/\.{2,}/g, '.') // 只保留第一个. 清除多余的
      //   .replace(/^\./g, '') // 保证第一个为数字而不是.
      //   .replace('.', '$#$')
      //   .replace(/\./g, '')
      //   .replace('$#$', '.')
      // if (checkPlan1.indexOf('.') < 0 && checkPlan1 !== '') {
      //   // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
      //   checkPlan1 = parseFloat(checkPlan1) + ''
      // } else if (checkPlan1.indexOf('.') >= 0) {
      //   checkPlan1 = checkPlan1
      //     .replace(/^()*(\d+)\.(\d\d).*$/, '$1$2.$3') // 只能输入两个小数
      // }
      // this.roughness = checkPlan1


      let color = this.color.slice(1);
      let rgba = [
          parseInt('0x'+color.slice(0, 2)),
          parseInt('0x'+color.slice(2, 4)),
          parseInt('0x'+color.slice(4, 6)),
      ];
      let color0 = rgba[0]/255;
      let color1 = rgba[1]/255;
      let color2 = rgba[2]/255;
  
      if(this.optionListValue == '基础材质'){
        let data = {
          fog:this.fog,
          opacity:this.opacity,
          side:0,
          transparent:this.transparent,
          aoMapIntensity:Number(this.aoMapIntensity),
          color:[color0,color1,color2],
          lightMapIntensity:Number(this.lightMapIntensity),
          refractionRatio:Number(this.refractionRatio),
        }
        IMaterialPreview.setBasicMaterial(data)
      }else if(this.optionListValue == '标准材质'){
        let emissiveColor = this.emissive.slice(1);
        let rgb = [
            parseInt('0x'+emissiveColor.slice(0, 2)),
            parseInt('0x'+emissiveColor.slice(2, 4)),
            parseInt('0x'+emissiveColor.slice(4, 6)),
        ];
        let emissiveColor0 = rgb[0]/255;
        let emissiveColor1 = rgb[1]/255;
        let emissiveColor2 = rgb[2]/255;

        let data = {
          fog:Number(this.fog),
          opacity:Number(this.opacity),
          side:0,
          transparent:this.transparent,
          aoMapIntensity:Number(this.aoMapIntensity),
          color:[color0,color1,color2],
          lightMapIntensity:Number(this.lightMapIntensity),
          refractionRatio:Number(this.refractionRatio),

          emissive:[emissiveColor0,emissiveColor1,emissiveColor2],

          emissiveIntensity:Number(this.emissiveIntensity),
          envMapIntensity:Number(this.envMapIntensity),
          metalness : Number(this.metalness),
          roughness:Number(this.roughness),
          matType:Number(this.MaterialsSelect),
           materialMat:{m_cauchyb:Number(this.cauchyb),m_exteriorior:Number(this.exterior),m_thickness:Number(this.thickness),m_interiorior:Number(this.interiorior)}
        }
        console.log(data)
        IMaterialPreview.setStandardMaterial(data)
      }else if(this.optionListValue == "冯氏材质"){
        let emissiveColor = this.emissive.slice(1);
        let rgb = [
            parseInt('0x'+emissiveColor.slice(0, 2)),
            parseInt('0x'+emissiveColor.slice(2, 4)),
            parseInt('0x'+emissiveColor.slice(4, 6)),
        ];
        let emissiveColor0 = rgb[0]/255;
        let emissiveColor1 = rgb[1]/255;
        let emissiveColor2 = rgb[2]/255;

        let specular = this.specular.slice(1);
        let specularRgb = [
            parseInt('0x'+specular.slice(0, 2)),
            parseInt('0x'+specular.slice(2, 4)),
            parseInt('0x'+specular.slice(4, 6)),
        ];
        let specularColor0 = specularRgb[0]/255;
        let specularColor1 = specularRgb[1]/255;
        let specularColor2 = specularRgb[2]/255;
        let data = {
          fog:this.fog,
          opacity:this.opacity,
          side:this.side,
          transparent:this.transparent,
          aoMapIntensity:Number(this.aoMapIntensity),
          color:[color0,color1,color2],
          lightMapIntensity:Number(this.lightMapIntensity),
          refractionRatio:Number(this.refractionRatio),
          shininess:Number(this.shininess),
          reflectivity:Number(this.reflectivity),
          emissive:[emissiveColor0,emissiveColor1,emissiveColor2],
          specular:[specularColor0,specularColor1,specularColor2],

          emissiveIntensity:Number(this.emissiveIntensity),
          envMapIntensity:Number(this.envMapIntensity),
        }
        // console.log(data)
        IMaterialPreview.setPhoneMaterial(data)
      }else if(this.optionListValue == "卡通捕捉材质"){
        let emissiveColor = this.emissive.slice(1);
        let rgb = [
            parseInt('0x'+emissiveColor.slice(0, 2)),
            parseInt('0x'+emissiveColor.slice(2, 4)),
            parseInt('0x'+emissiveColor.slice(4, 6)),
        ];
        let emissiveColor0 = rgb[0]/255;
        let emissiveColor1 = rgb[1]/255;
        let emissiveColor2 = rgb[2]/255;

        let specular = this.specular.slice(1);
        let specularRgb = [
            parseInt('0x'+specular.slice(0, 2)),
            parseInt('0x'+specular.slice(2, 4)),
            parseInt('0x'+specular.slice(4, 6)),
        ];
        let specularColor0 = specularRgb[0]/255;
        let specularColor1 = specularRgb[1]/255;
        let specularColor2 = specularRgb[2]/255;
        let data = {
          fog:this.fog,
          opacity:this.opacity,
          side:this.side,
          transparent:this.transparent,
          aoMapIntensity:Number(this.aoMapIntensity),
          color:[color0,color1,color2],
          lightMapIntensity:Number(this.lightMapIntensity),
          refractionRatio:Number(this.refractionRatio),
          shininess:Number(this.shininess),
          reflectivity:Number(this.reflectivity),
          emissive:[emissiveColor0,emissiveColor1,emissiveColor2],
          specular:[specularColor0,specularColor1,specularColor2],

          emissiveIntensity:Number(this.emissiveIntensity),
          // envMapIntensity:Number(this.envMapIntensity),
          matcapIntensity:Number(this.matcapIntensity),
        }
        // console.log(data)
        IMaterialPreview.setToonPhoneMaterial(data,3)
      }else if(this.optionListValue == "卡通捕捉扭动材质"){
        let emissiveColor = this.emissive.slice(1);
        let rgb = [
            parseInt('0x'+emissiveColor.slice(0, 2)),
            parseInt('0x'+emissiveColor.slice(2, 4)),
            parseInt('0x'+emissiveColor.slice(4, 6)),
        ];
        let emissiveColor0 = rgb[0]/255;
        let emissiveColor1 = rgb[1]/255;
        let emissiveColor2 = rgb[2]/255;

        let specular = this.specular.slice(1);
        let specularRgb = [
            parseInt('0x'+specular.slice(0, 2)),
            parseInt('0x'+specular.slice(2, 4)),
            parseInt('0x'+specular.slice(4, 6)),
        ];
        let specularColor0 = specularRgb[0]/255;
        let specularColor1 = specularRgb[1]/255;
        let specularColor2 = specularRgb[2]/255;
        let data = {
          fog:this.fog,
          opacity:this.opacity,
          side:this.side,
          transparent:this.transparent,
          aoMapIntensity:Number(this.aoMapIntensity),
          color:[color0,color1,color2],
          lightMapIntensity:Number(this.lightMapIntensity),
          refractionRatio:Number(this.refractionRatio),
          shininess:Number(this.shininess),
          reflectivity:Number(this.reflectivity),
          emissive:[emissiveColor0,emissiveColor1,emissiveColor2],
          specular:[specularColor0,specularColor1,specularColor2],

          emissiveIntensity:Number(this.emissiveIntensity),
          // envMapIntensity:Number(this.envMapIntensity),
          matcapIntensity:Number(this.matcapIntensity),
        }
        // console.log(data)
        IMaterialPreview.setToonPhoneMaterial(data,4)
      }
    },
    //雾影响
    fogChange(){
      if(this.renderSelect == "正面"){
        this.side = 0;
      }else if(this.renderSelect == "背面"){
        this.side = 1;
      }else{
        this.side = 2;
      }
      
      if(this.optionListValue == '基础材质'){
        let color = this.color.slice(1);
        let rgba = [
            parseInt('0x'+color.slice(0, 2)),
            parseInt('0x'+color.slice(2, 4)),
            parseInt('0x'+color.slice(4, 6)),
        ];
        let color0 = rgba[0]/255;
        let color1 = rgba[1]/255;
        let color2 = rgba[2]/255;
        let data = {
          fog:this.fog,
          opacity:this.opacity,
          side:this.side,
          transparent:this.transparent,
          aoMapIntensity:(Number),
          color:[color0,color1,color2],
          lightMapIntensity:Number(this.lightMapIntensity),
          refractionRatio:Number(this.refractionRatio),
        }
        IMaterialPreview.setBasicMaterial(data)
      }else if(this.optionListValue == '标准材质'){
        let color = this.color.slice(1);
        let rgba = [
            parseInt('0x'+color.slice(0, 2)),
            parseInt('0x'+color.slice(2, 4)),
            parseInt('0x'+color.slice(4, 6)),
        ];
        let color0 = rgba[0]/255;
        let color1 = rgba[1]/255;
        let color2 = rgba[2]/255;

        let emissiveColor = this.emissive.slice(1);
        let rgb = [
            parseInt('0x'+emissiveColor.slice(0, 2)),
            parseInt('0x'+emissiveColor.slice(2, 4)),
            parseInt('0x'+emissiveColor.slice(4, 6)),
        ];
        let emissiveColor0 = rgb[0]/255;
        let emissiveColor1 = rgb[1]/255;
        let emissiveColor2 = rgb[2]/255;

        let data = {
          fog:this.fog,
          opacity:Number(this.opacity),
          side:this.side,
          transparent:this.transparent,
          aoMapIntensity:Number(this.aoMapIntensity),
          color:[color0,color1,color2],
          lightMapIntensity:Number(this.lightMapIntensity),
          refractionRatio:Number(this.refractionRatio),

          emissive:[emissiveColor0,emissiveColor1,emissiveColor2],

          emissiveIntensity:Number(this.emissiveIntensity),
          envMapIntensity:Number(this.envMapIntensity),
          metalness : Number(this.metalness),
          roughness:Number(this.roughness),
          matType:Number(this.MaterialsSelect),
           materialMat:{m_cauchyb:Number(this.cauchyb),m_exteriorior:Number(this.exterior),m_thickness:Number(this.thickness),m_interiorior:Number(this.interiorior)}
        }
        IMaterialPreview.setStandardMaterial(data)
      }else if(this.optionListValue == "冯氏材质"){
        let color = this.color.slice(1);
        let rgba = [
            parseInt('0x'+color.slice(0, 2)),
            parseInt('0x'+color.slice(2, 4)),
            parseInt('0x'+color.slice(4, 6)),
        ];
        let color0 = rgba[0]/255;
        let color1 = rgba[1]/255;
        let color2 = rgba[2]/255;

        let emissiveColor = this.emissive.slice(1);
        let rgb = [
            parseInt('0x'+emissiveColor.slice(0, 2)),
            parseInt('0x'+emissiveColor.slice(2, 4)),
            parseInt('0x'+emissiveColor.slice(4, 6)),
        ];
        let emissiveColor0 = rgb[0]/255;
        let emissiveColor1 = rgb[1]/255;
        let emissiveColor2 = rgb[2]/255;

        let specular = this.specular.slice(1);
        let specularRgb = [
            parseInt('0x'+specular.slice(0, 2)),
            parseInt('0x'+specular.slice(2, 4)),
            parseInt('0x'+specular.slice(4, 6)),
        ];
        let specularColor0 = specularRgb[0]/255;
        let specularColor1 = specularRgb[1]/255;
        let specularColor2 = specularRgb[2]/255;

        let data = {
          fog:this.fog,
          opacity:this.opacity,
          side:this.side,
          transparent:this.transparent,
          aoMapIntensity:Number(this.aoMapIntensity),
          color:[color0,color1,color2],
          lightMapIntensity:Number(this.lightMapIntensity),
          refractionRatio:Number(this.refractionRatio),
          shininess:Number(this.shininess),
          reflectivity:Number(this.reflectivity),
          emissive:[emissiveColor0,emissiveColor1,emissiveColor2],
          specular:[specularColor0,specularColor1,specularColor2],

          emissiveIntensity:Number(this.emissiveIntensity),
          envMapIntensity:Number(this.envMapIntensity),
        }
        // console.log(data)
        IMaterialPreview.setPhoneMaterial(data)
      }else if(this.optionListValue == "卡通捕捉材质"){
        let color = this.color.slice(1);
        let rgba = [
            parseInt('0x'+color.slice(0, 2)),
            parseInt('0x'+color.slice(2, 4)),
            parseInt('0x'+color.slice(4, 6)),
        ];
        let color0 = rgba[0]/255;
        let color1 = rgba[1]/255;
        let color2 = rgba[2]/255;

        let emissiveColor = this.emissive.slice(1);
        let rgb = [
            parseInt('0x'+emissiveColor.slice(0, 2)),
            parseInt('0x'+emissiveColor.slice(2, 4)),
            parseInt('0x'+emissiveColor.slice(4, 6)),
        ];
        let emissiveColor0 = rgb[0]/255;
        let emissiveColor1 = rgb[1]/255;
        let emissiveColor2 = rgb[2]/255;

        let specular = this.specular.slice(1);
        let specularRgb = [
            parseInt('0x'+specular.slice(0, 2)),
            parseInt('0x'+specular.slice(2, 4)),
            parseInt('0x'+specular.slice(4, 6)),
        ];
        let specularColor0 = specularRgb[0]/255;
        let specularColor1 = specularRgb[1]/255;
        let specularColor2 = specularRgb[2]/255;
        let data = {
          fog:this.fog,
          opacity:this.opacity,
          side:this.side,
          transparent:this.transparent,
          aoMapIntensity:Number(this.aoMapIntensity),
          color:[color0,color1,color2],
          lightMapIntensity:Number(this.lightMapIntensity),
          refractionRatio:Number(this.refractionRatio),
          shininess:Number(this.shininess),
          reflectivity:Number(this.reflectivity),
          emissive:[emissiveColor0,emissiveColor1,emissiveColor2],
          specular:[specularColor0,specularColor1,specularColor2],

          emissiveIntensity:Number(this.emissiveIntensity),
          // envMapIntensity:Number(this.envMapIntensity),
          matcapIntensity:Number(this.matcapIntensity)
        }
        // console.log(data)
        IMaterialPreview.setToonPhoneMaterial(data,3)
      }else if(this.optionListValue == "卡通捕捉扭动材质"){
        let color = this.color.slice(1);
        let rgba = [
            parseInt('0x'+color.slice(0, 2)),
            parseInt('0x'+color.slice(2, 4)),
            parseInt('0x'+color.slice(4, 6)),
        ];
        let color0 = rgba[0]/255;
        let color1 = rgba[1]/255;
        let color2 = rgba[2]/255;

        let emissiveColor = this.emissive.slice(1);
        let rgb = [
            parseInt('0x'+emissiveColor.slice(0, 2)),
            parseInt('0x'+emissiveColor.slice(2, 4)),
            parseInt('0x'+emissiveColor.slice(4, 6)),
        ];
        let emissiveColor0 = rgb[0]/255;
        let emissiveColor1 = rgb[1]/255;
        let emissiveColor2 = rgb[2]/255;

        let specular = this.specular.slice(1);
        let specularRgb = [
            parseInt('0x'+specular.slice(0, 2)),
            parseInt('0x'+specular.slice(2, 4)),
            parseInt('0x'+specular.slice(4, 6)),
        ];
        let specularColor0 = specularRgb[0]/255;
        let specularColor1 = specularRgb[1]/255;
        let specularColor2 = specularRgb[2]/255;
        let data = {
          fog:this.fog,
          opacity:this.opacity,
          side:this.side,
          transparent:this.transparent,
          aoMapIntensity:Number(this.aoMapIntensity),
          color:[color0,color1,color2],
          lightMapIntensity:Number(this.lightMapIntensity),
          refractionRatio:Number(this.refractionRatio),
          shininess:Number(this.shininess),
          reflectivity:Number(this.reflectivity),
          emissive:[emissiveColor0,emissiveColor1,emissiveColor2],
          specular:[specularColor0,specularColor1,specularColor2],

          emissiveIntensity:Number(this.emissiveIntensity),
          // envMapIntensity:Number(this.envMapIntensity),
          matcapIntensity:Number(this.matcapIntensity)
        }
        // console.log(data)
        IMaterialPreview.setToonPhoneMaterial(data,4)
      }
    },
    //透明
    transparentChange(event){
      if(this.renderSelect == "正面"){
        this.side = 0;
      }else if(this.renderSelect == "背面"){
        this.side = 1;
      }else{
        this.side = 2;
      }
      let color = this.color.slice(1);
      let rgba = [
          parseInt('0x'+color.slice(0, 2)),
          parseInt('0x'+color.slice(2, 4)),
          parseInt('0x'+color.slice(4, 6)),
      ];
      let color0 = rgba[0]/255;
      let color1 = rgba[1]/255;
      let color2 = rgba[2]/255;

      let emissiveColor = this.emissive.slice(1);
      let rgb = [
          parseInt('0x'+emissiveColor.slice(0, 2)),
          parseInt('0x'+emissiveColor.slice(2, 4)),
          parseInt('0x'+emissiveColor.slice(4, 6)),
      ];
      let emissiveColor0 = rgb[0]/255;
      let emissiveColor1 = rgb[1]/255;
      let emissiveColor2 = rgb[2]/255;

      let specular = this.specular.slice(1);
      let specularRgb = [
          parseInt('0x'+specular.slice(0, 2)),
          parseInt('0x'+specular.slice(2, 4)),
          parseInt('0x'+specular.slice(4, 6)),
      ];
      let specularColor0 = specularRgb[0]/255;
      let specularColor1 = specularRgb[1]/255;
      let specularColor2 = specularRgb[2]/255;

      if(this.optionListValue == '基础材质'){
        let data = {
          fog:this.fog,
          opacity:this.opacity,
          side:this.side,
          transparent:this.transparent,
          aoMapIntensity:this.aoMapIntensity,
          color:[color0,color1,color2],
          lightMapIntensity:this.lightMapIntensity,
          refractionRatio:this.refractionRatio,
        }
        IMaterialPreview.setBasicMaterial(data)
      }else if(this.optionListValue == '标准材质'){
        let data = {
          fog:this.fog,
          opacity:this.opacity,
          side:this.side,
          transparent:this.transparent,
          aoMapIntensity:Number(this.aoMapIntensity),
          color:[color0,color1,color2],
          lightMapIntensity:Number(this.lightMapIntensity),
          refractionRatio:Number(this.refractionRatio),

          emissive:[emissiveColor0,emissiveColor1,emissiveColor2],

          emissiveIntensity:Number(this.emissiveIntensity),
          envMapIntensity:Number(this.envMapIntensity),
          metalness : Number(this.metalness),
          roughness:Number(this.roughness),
          matType:Number(this.MaterialsSelect),
           materialMat:{m_cauchyb:Number(this.cauchyb),m_exteriorior:Number(this.exterior),m_thickness:Number(this.thickness),m_interiorior:Number(this.interiorior)}
        }
        IMaterialPreview.setStandardMaterial(data)
      }else if(this.optionListValue == "冯氏材质"){
        let data = {
          fog:this.fog,
          opacity:this.opacity,
          side:this.side,
          transparent:this.transparent,
          aoMapIntensity:Number(this.aoMapIntensity),
          color:[color0,color1,color2],
          lightMapIntensity:Number(this.lightMapIntensity),
          refractionRatio:Number(this.refractionRatio),
          shininess:Number(this.shininess),
          reflectivity:Number(this.reflectivity),
          emissive:[emissiveColor0,emissiveColor1,emissiveColor2],
          specular:[specularColor0,specularColor1,specularColor2],

          emissiveIntensity:Number(this.emissiveIntensity),
          envMapIntensity:Number(this.envMapIntensity),
        }
        // console.log(data)
        IMaterialPreview.setPhoneMaterial(data)
      }else if(this.optionListValue == "卡通捕捉材质"){
        let data = {
          fog:this.fog,
          opacity:this.opacity,
          side:this.side,
          transparent:this.transparent,
          aoMapIntensity:Number(this.aoMapIntensity),
          color:[color0,color1,color2],
          lightMapIntensity:Number(this.lightMapIntensity),
          refractionRatio:Number(this.refractionRatio),
          shininess:Number(this.shininess),
          reflectivity:Number(this.reflectivity),
          emissive:[emissiveColor0,emissiveColor1,emissiveColor2],
          specular:[specularColor0,specularColor1,specularColor2],

          emissiveIntensity:Number(this.emissiveIntensity),
          // envMapIntensity:Number(this.envMapIntensity),
          matcapIntensity:Number(this.matcapIntensity),
        }
        // console.log(data)
        IMaterialPreview.setToonPhoneMaterial(data,3)
      }else if(this.optionListValue == "卡通捕捉扭动材质"){
        let data = {
          fog:this.fog,
          opacity:this.opacity,
          side:this.side,
          transparent:this.transparent,
          aoMapIntensity:Number(this.aoMapIntensity),
          color:[color0,color1,color2],
          lightMapIntensity:Number(this.lightMapIntensity),
          refractionRatio:Number(this.refractionRatio),
          shininess:Number(this.shininess),
          reflectivity:Number(this.reflectivity),
          emissive:[emissiveColor0,emissiveColor1,emissiveColor2],
          specular:[specularColor0,specularColor1,specularColor2],

          emissiveIntensity:Number(this.emissiveIntensity),
          // envMapIntensity:Number(this.envMapIntensity),
          matcapIntensity:Number(this.matcapIntensity),
        }
        // console.log(data)
        IMaterialPreview.setToonPhoneMaterial(data,4)
      }
    },
    activeColor(value){
      let value1 =  this.transitionColror(value)
      this.color = value1;
      let color = value1.slice(1);
      let rgba = [
          parseInt('0x'+color.slice(0, 2)),
          parseInt('0x'+color.slice(2, 4)),
          parseInt('0x'+color.slice(4, 6)),
      ];
      let color0 = rgba[0]/255;
      let color1 = rgba[1]/255;
      let color2 = rgba[2]/255;

      if(this.optionListValue == "基础材质"){
        let data = {
          fog:this.fog,
          opacity:this.opacity,
          side:this.side,
          transparent:this.transparent,
          aoMapIntensity:this.aoMapIntensity,
          color:[color0,color1,color2],
          lightMapIntensity:this.lightMapIntensity,
          refractionRatio:this.refractionRatio,
        }
        IMaterialPreview.setBasicMaterial(data)
      }else if(this.optionListValue =="标准材质"){
        let emissiveColor = this.emissive.slice(1);
        let rgb = [
            parseInt('0x'+emissiveColor.slice(0, 2)),
            parseInt('0x'+emissiveColor.slice(2, 4)),
            parseInt('0x'+emissiveColor.slice(4, 6)),
        ];
        let emissiveColor0 = rgb[0]/255;
        let emissiveColor1 = rgb[1]/255;
        let emissiveColor2 = rgb[2]/255;

        let data = {
          fog:this.fog,
          opacity:this.opacity,
          side:this.side,
          transparent:this.transparent,
          aoMapIntensity:Number(this.aoMapIntensity),
          color:[color0,color1,color2],
          lightMapIntensity:Number(this.lightMapIntensity),
          refractionRatio:Number(this.refractionRatio),

          emissive:[emissiveColor0,emissiveColor1,emissiveColor2],

          emissiveIntensity:Number(this.emissiveIntensity),
          envMapIntensity:Number(this.envMapIntensity),
          metalness : Number(this.metalness),
          roughness:Number(this.roughness),
          matType:Number(this.MaterialsSelect),
           materialMat:{m_cauchyb:Number(this.cauchyb),m_exteriorior:Number(this.exterior),m_thickness:Number(this.thickness),m_interiorior:Number(this.interiorior)}
        }
        // console.log(data)
        IMaterialPreview.setStandardMaterial(data)
      }else if(this.optionListValue == "冯氏材质"){
        let emissiveColor = this.emissive.slice(1);
        let rgb = [
            parseInt('0x'+emissiveColor.slice(0, 2)),
            parseInt('0x'+emissiveColor.slice(2, 4)),
            parseInt('0x'+emissiveColor.slice(4, 6)),
        ];
        let emissiveColor0 = rgb[0]/255;
        let emissiveColor1 = rgb[1]/255;
        let emissiveColor2 = rgb[2]/255;

        let specular = this.specular.slice(1);
        let specularRgb = [
            parseInt('0x'+specular.slice(0, 2)),
            parseInt('0x'+specular.slice(2, 4)),
            parseInt('0x'+specular.slice(4, 6)),
        ];
        let specularColor0 = specularRgb[0]/255;
        let specularColor1 = specularRgb[1]/255;
        let specularColor2 = specularRgb[2]/255;
        let data = {
          fog:this.fog,
          opacity:this.opacity,
          side:this.side,
          transparent:this.transparent,
          aoMapIntensity:Number(this.aoMapIntensity),
          color:[color0,color1,color2],
          lightMapIntensity:Number(this.lightMapIntensity),
          refractionRatio:Number(this.refractionRatio),
          shininess:Number(this.shininess),
          reflectivity:Number(this.reflectivity),
          emissive:[emissiveColor0,emissiveColor1,emissiveColor2],
          specular:[specularColor0,specularColor1,specularColor2],

          emissiveIntensity:Number(this.emissiveIntensity),
          envMapIntensity:Number(this.envMapIntensity),
        }
        // console.log(data)
        IMaterialPreview.setPhoneMaterial(data)
      }else if(this.optionListValue == "卡通捕捉材质"){
        let emissiveColor = this.emissive.slice(1);
        let rgb = [
            parseInt('0x'+emissiveColor.slice(0, 2)),
            parseInt('0x'+emissiveColor.slice(2, 4)),
            parseInt('0x'+emissiveColor.slice(4, 6)),
        ];
        let emissiveColor0 = rgb[0]/255;
        let emissiveColor1 = rgb[1]/255;
        let emissiveColor2 = rgb[2]/255;

        let specular = this.specular.slice(1);
        let specularRgb = [
            parseInt('0x'+specular.slice(0, 2)),
            parseInt('0x'+specular.slice(2, 4)),
            parseInt('0x'+specular.slice(4, 6)),
        ];
        let specularColor0 = specularRgb[0]/255;
        let specularColor1 = specularRgb[1]/255;
        let specularColor2 = specularRgb[2]/255;
        let data = {
          fog:this.fog,
          opacity:this.opacity,
          side:this.side,
          transparent:this.transparent,
          aoMapIntensity:Number(this.aoMapIntensity),
          color:[color0,color1,color2],
          lightMapIntensity:Number(this.lightMapIntensity),
          refractionRatio:Number(this.refractionRatio),
          shininess:Number(this.shininess),
          reflectivity:Number(this.reflectivity),
          emissive:[emissiveColor0,emissiveColor1,emissiveColor2],
          specular:[specularColor0,specularColor1,specularColor2],

          emissiveIntensity:Number(this.emissiveIntensity),
          // envMapIntensity:Number(this.envMapIntensity),
          matcapIntensity:Number(this.matcapIntensity),
        }
        // console.log(data)
        IMaterialPreview.setToonPhoneMaterial(data,3)
      }else if(this.optionListValue == "卡通捕捉扭动材质"){
        let emissiveColor = this.emissive.slice(1);
        let rgb = [
            parseInt('0x'+emissiveColor.slice(0, 2)),
            parseInt('0x'+emissiveColor.slice(2, 4)),
            parseInt('0x'+emissiveColor.slice(4, 6)),
        ];
        let emissiveColor0 = rgb[0]/255;
        let emissiveColor1 = rgb[1]/255;
        let emissiveColor2 = rgb[2]/255;

        let specular = this.specular.slice(1);
        let specularRgb = [
            parseInt('0x'+specular.slice(0, 2)),
            parseInt('0x'+specular.slice(2, 4)),
            parseInt('0x'+specular.slice(4, 6)),
        ];
        let specularColor0 = specularRgb[0]/255;
        let specularColor1 = specularRgb[1]/255;
        let specularColor2 = specularRgb[2]/255;
        let data = {
          fog:this.fog,
          opacity:this.opacity,
          side:this.side,
          transparent:this.transparent,
          aoMapIntensity:Number(this.aoMapIntensity),
          color:[color0,color1,color2],
          lightMapIntensity:Number(this.lightMapIntensity),
          refractionRatio:Number(this.refractionRatio),
          shininess:Number(this.shininess),
          reflectivity:Number(this.reflectivity),
          emissive:[emissiveColor0,emissiveColor1,emissiveColor2],
          specular:[specularColor0,specularColor1,specularColor2],

          emissiveIntensity:Number(this.emissiveIntensity),
          // envMapIntensity:Number(this.envMapIntensity),
          matcapIntensity:Number(this.matcapIntensity),
        }
        // console.log(data)
        IMaterialPreview.setToonPhoneMaterial(data,4)
      }
    },
    ColorApplyChange(){
      let color = this.color.slice(1);
      let rgba = [
          parseInt('0x'+color.slice(0, 2)),
          parseInt('0x'+color.slice(2, 4)),
          parseInt('0x'+color.slice(4, 6)),
      ];
      let color0 = rgba[0]/255;
      let color1 = rgba[1]/255;
      let color2 = rgba[2]/255;

      if(this.optionListValue == "基础材质"){
        let data = {
          fog:this.fog,
          opacity:this.opacity,
          side:this.side,
          transparent:this.transparent,
          aoMapIntensity:this.aoMapIntensity,
          color:[color0,color1,color2],
          lightMapIntensity:this.lightMapIntensity,
          refractionRatio:this.refractionRatio,
        }
        IMaterialPreview.setBasicMaterial(data)
      }else if(this.optionListValue == "标准材质"){
        let emissiveColor = this.emissive.slice(1);
        let rgb = [
            parseInt('0x'+emissiveColor.slice(0, 2)),
            parseInt('0x'+emissiveColor.slice(2, 4)),
            parseInt('0x'+emissiveColor.slice(4, 6)),
        ];
        let emissiveColor0 = rgb[0]/255;
        let emissiveColor1 = rgb[1]/255;
        let emissiveColor2 = rgb[2]/255;

        let data = {
          fog:this.fog,
          opacity:this.opacity,
          side:this.side,
          transparent:this.transparent,
          aoMapIntensity:Number(this.aoMapIntensity),
          color:[color0,color1,color2],
          lightMapIntensity:Number(this.lightMapIntensity),
          refractionRatio:Number(this.refractionRatio),

          emissive:[emissiveColor0,emissiveColor1,emissiveColor2],

          emissiveIntensity:Number(this.emissiveIntensity),
          envMapIntensity:Number(this.envMapIntensity),
          metalness : Number(this.metalness),
          roughness:Number(this.roughness),
          matType:Number(this.MaterialsSelect),
           materialMat:{m_cauchyb:Number(this.cauchyb),m_exteriorior:Number(this.exterior),m_thickness:Number(this.thickness),m_interiorior:Number(this.interiorior)}
        }
        // console.log(data)
        IMaterialPreview.setStandardMaterial(data)
      }else if(this.optionListValue == "冯氏材质"){
        let emissiveColor = this.emissive.slice(1);
        let rgb = [
            parseInt('0x'+emissiveColor.slice(0, 2)),
            parseInt('0x'+emissiveColor.slice(2, 4)),
            parseInt('0x'+emissiveColor.slice(4, 6)),
        ];
        let emissiveColor0 = rgb[0]/255;
        let emissiveColor1 = rgb[1]/255;
        let emissiveColor2 = rgb[2]/255;
        
        let specular = this.specular.slice(1);
        let specularRgb = [
            parseInt('0x'+specular.slice(0, 2)),
            parseInt('0x'+specular.slice(2, 4)),
            parseInt('0x'+specular.slice(4, 6)),
        ];
        let specularColor0 = specularRgb[0]/255;
        let specularColor1 = specularRgb[1]/255;
        let specularColor2 = specularRgb[2]/255;

        let data = {
          fog:this.fog,
          opacity:this.opacity,
          side:this.side,
          transparent:this.transparent,
          aoMapIntensity:Number(this.aoMapIntensity),
          color:[color0,color1,color2],
          lightMapIntensity:Number(this.lightMapIntensity),
          refractionRatio:Number(this.refractionRatio),
          shininess:Number(this.shininess),
          reflectivity:Number(this.reflectivity),
          emissive:[emissiveColor0,emissiveColor1,emissiveColor2],
          specular:[specularColor0,specularColor1,specularColor2],

          emissiveIntensity:Number(this.emissiveIntensity),
          envMapIntensity:Number(this.envMapIntensity),
        }
        // console.log(data)
        IMaterialPreview.setPhoneMaterial(data)
      }else if(this.optionListValue == "卡通捕捉材质"){
        let emissiveColor = this.emissive.slice(1);
        let rgb = [
            parseInt('0x'+emissiveColor.slice(0, 2)),
            parseInt('0x'+emissiveColor.slice(2, 4)),
            parseInt('0x'+emissiveColor.slice(4, 6)),
        ];
        let emissiveColor0 = rgb[0]/255;
        let emissiveColor1 = rgb[1]/255;
        let emissiveColor2 = rgb[2]/255;
        
        let specular = this.specular.slice(1);
        let specularRgb = [
            parseInt('0x'+specular.slice(0, 2)),
            parseInt('0x'+specular.slice(2, 4)),
            parseInt('0x'+specular.slice(4, 6)),
        ];
        let specularColor0 = specularRgb[0]/255;
        let specularColor1 = specularRgb[1]/255;
        let specularColor2 = specularRgb[2]/255;

        let data = {
          fog:this.fog,
          opacity:this.opacity,
          side:this.side,
          transparent:this.transparent,
          aoMapIntensity:Number(this.aoMapIntensity),
          color:[color0,color1,color2],
          lightMapIntensity:Number(this.lightMapIntensity),
          refractionRatio:Number(this.refractionRatio),
          shininess:Number(this.shininess),
          reflectivity:Number(this.reflectivity),
          emissive:[emissiveColor0,emissiveColor1,emissiveColor2],
          specular:[specularColor0,specularColor1,specularColor2],

          emissiveIntensity:Number(this.emissiveIntensity),
          // envMapIntensity:Number(this.envMapIntensity),
          matcapIntensity:Number(this.matcapIntensity)
        }
        // console.log(data)
        IMaterialPreview.setToonPhoneMaterial(data,3)
      }else if(this.optionListValue == "卡通捕捉扭动材质"){
        let emissiveColor = this.emissive.slice(1);
        let rgb = [
            parseInt('0x'+emissiveColor.slice(0, 2)),
            parseInt('0x'+emissiveColor.slice(2, 4)),
            parseInt('0x'+emissiveColor.slice(4, 6)),
        ];
        let emissiveColor0 = rgb[0]/255;
        let emissiveColor1 = rgb[1]/255;
        let emissiveColor2 = rgb[2]/255;
        
        let specular = this.specular.slice(1);
        let specularRgb = [
            parseInt('0x'+specular.slice(0, 2)),
            parseInt('0x'+specular.slice(2, 4)),
            parseInt('0x'+specular.slice(4, 6)),
        ];
        let specularColor0 = specularRgb[0]/255;
        let specularColor1 = specularRgb[1]/255;
        let specularColor2 = specularRgb[2]/255;

        let data = {
          fog:this.fog,
          opacity:this.opacity,
          side:this.side,
          transparent:this.transparent,
          aoMapIntensity:Number(this.aoMapIntensity),
          color:[color0,color1,color2],
          lightMapIntensity:Number(this.lightMapIntensity),
          refractionRatio:Number(this.refractionRatio),
          shininess:Number(this.shininess),
          reflectivity:Number(this.reflectivity),
          emissive:[emissiveColor0,emissiveColor1,emissiveColor2],
          specular:[specularColor0,specularColor1,specularColor2],

          emissiveIntensity:Number(this.emissiveIntensity),
          // envMapIntensity:Number(this.envMapIntensity),
          matcapIntensity:Number(this.matcapIntensity)
        }
        // console.log(data)
        IMaterialPreview.setToonPhoneMaterial(data,4)
      }
    },
    colorImport(){
      let color = this.color.slice(1);
      let rgba = [
          parseInt('0x'+color.slice(0, 2)),
          parseInt('0x'+color.slice(2, 4)),
          parseInt('0x'+color.slice(4, 6)),
      ];
      let color0 = rgba[0]/255;
      let color1 = rgba[1]/255;
      let color2 = rgba[2]/255;

      let emissiveColor = this.emissive.slice(1);
      let rgb = [
          parseInt('0x'+emissiveColor.slice(0, 2)),
          parseInt('0x'+emissiveColor.slice(2, 4)),
          parseInt('0x'+emissiveColor.slice(4, 6)),
      ];
      let emissiveColor0 = rgb[0]/255;
      let emissiveColor1 = rgb[1]/255;
      let emissiveColor2 = rgb[2]/255;

      let specular = this.specular.slice(1);
      let specularRgb = [
          parseInt('0x'+specular.slice(0, 2)),
          parseInt('0x'+specular.slice(2, 4)),
          parseInt('0x'+specular.slice(4, 6)),
      ];
      let specularColor0 = specularRgb[0]/255;
      let specularColor1 = specularRgb[1]/255;
      let specularColor2 = specularRgb[2]/255;

      if(this.optionListValue == "基础材质"){
        let data = {
          fog:this.fog,
          opacity:this.opacity,
          side:this.side,
          transparent:this.transparent,
          aoMapIntensity:this.aoMapIntensity,
          color:[color0,color1,color2],
          lightMapIntensity:this.lightMapIntensity,
          refractionRatio:this.refractionRatio,
        }
        IMaterialPreview.setBasicMaterial(data)
      }else if(this.optionListValue == "标准材质"){
        let data = {
          fog:this.fog,
          opacity:this.opacity,
          side:this.side,
          transparent:this.transparent,
          aoMapIntensity:Number(this.aoMapIntensity),
          color:[color0,color1,color2],
          lightMapIntensity:Number(this.lightMapIntensity),
          refractionRatio:Number(this.refractionRatio),

          emissive:[emissiveColor0,emissiveColor1,emissiveColor2],

          emissiveIntensity:Number(this.emissiveIntensity),
          envMapIntensity:Number(this.envMapIntensity),
          metalness : Number(this.metalness),
          roughness:Number(this.roughness),
          matType:Number(this.MaterialsSelect),
           materialMat:{m_cauchyb:Number(this.cauchyb),m_exteriorior:Number(this.exterior),m_thickness:Number(this.thickness),m_interiorior:Number(this.interiorior)}
        }
        IMaterialPreview.setStandardMaterial(data)
      }else if(this.optionListValue == "冯氏材质"){
        let data = {
          fog:this.fog,
          opacity:this.opacity,
          side:this.side,
          transparent:this.transparent,
          aoMapIntensity:Number(this.aoMapIntensity),
          color:[color0,color1,color2],
          lightMapIntensity:Number(this.lightMapIntensity),
          refractionRatio:Number(this.refractionRatio),
          shininess:Number(this.shininess),
          reflectivity:Number(this.reflectivity),
          emissive:[emissiveColor0,emissiveColor1,emissiveColor2],
          specular:[specularColor0,specularColor1,specularColor2],

          emissiveIntensity:Number(this.emissiveIntensity),
          envMapIntensity:Number(this.envMapIntensity),
        }
        // console.log(data)
        IMaterialPreview.setPhoneMaterial(data)
      }else if(this.optionListValue == "卡通捕捉材质"){
        let data = {
          fog:this.fog,
          opacity:this.opacity,
          side:this.side,
          transparent:this.transparent,
          aoMapIntensity:Number(this.aoMapIntensity),
          color:[color0,color1,color2],
          lightMapIntensity:Number(this.lightMapIntensity),
          refractionRatio:Number(this.refractionRatio),
          shininess:Number(this.shininess),
          reflectivity:Number(this.reflectivity),
          emissive:[emissiveColor0,emissiveColor1,emissiveColor2],
          specular:[specularColor0,specularColor1,specularColor2],

          emissiveIntensity:Number(this.emissiveIntensity),
          // envMapIntensity:Number(this.envMapIntensity),
          matcapIntensity:Number(this.matcapIntensity),
        }
        // console.log(data)
        IMaterialPreview.setToonPhoneMaterial(data)
      }else if(this.optionListValue == "卡通捕捉扭动材质"){
        let data = {
          fog:this.fog,
          opacity:this.opacity,
          side:this.side,
          transparent:this.transparent,
          aoMapIntensity:Number(this.aoMapIntensity),
          color:[color0,color1,color2],
          lightMapIntensity:Number(this.lightMapIntensity),
          refractionRatio:Number(this.refractionRatio),
          shininess:Number(this.shininess),
          reflectivity:Number(this.reflectivity),
          emissive:[emissiveColor0,emissiveColor1,emissiveColor2],
          specular:[specularColor0,specularColor1,specularColor2],

          emissiveIntensity:Number(this.emissiveIntensity),
          // envMapIntensity:Number(this.envMapIntensity),
          matcapIntensity:Number(this.matcapIntensity),
        }
        // console.log(data)
        IMaterialPreview.setToonPhoneMaterial(data,4)
      }
    },
    //发光颜色
    activeColor1(value){
      let value1 =  this.transitionColror(value)
      this.emissive = value1;
      let color = value1.slice(1);
      let rgba = [
          parseInt('0x'+color.slice(0, 2)),
          parseInt('0x'+color.slice(2, 4)),
          parseInt('0x'+color.slice(4, 6)),
      ];
      let emissiveColor0 = rgba[0]/255;
      let emissiveColor1 = rgba[1]/255;
      let emissiveColor2 = rgba[2]/255;

      let emissiveColor = this.color.slice(1);
      let rgb = [
          parseInt('0x'+emissiveColor.slice(0, 2)),
          parseInt('0x'+emissiveColor.slice(2, 4)),
          parseInt('0x'+emissiveColor.slice(4, 6)),
      ];
      let color0 = rgb[0]/255;
      let color1 = rgb[1]/255;
      let color2 = rgb[2]/255;
      

      if(this.optionListValue == "标准材质"){
        let data = {
          fog:this.fog,
          opacity:this.opacity,
          side:this.side,
          transparent:this.transparent,
          aoMapIntensity:Number(this.aoMapIntensity),
          color:[color0,color1,color2],
          lightMapIntensity:Number(this.lightMapIntensity),
          refractionRatio:Number(this.refractionRatio),

          emissive:[emissiveColor0,emissiveColor1,emissiveColor2],

          emissiveIntensity:Number(this.emissiveIntensity),
          envMapIntensity:Number(this.envMapIntensity),
          metalness : Number(this.metalness),
          roughness:Number(this.roughness),
          matType:Number(this.MaterialsSelect),
           materialMat:{m_cauchyb:Number(this.cauchyb),m_exteriorior:Number(this.exterior),m_thickness:Number(this.thickness),m_interiorior:Number(this.interiorior)}
        }
        // console.log(data)
        IMaterialPreview.setStandardMaterial(data)
      }else if(this.optionListValue == "冯氏材质"){
        let specular = this.specular.slice(1);
        let specularRgb = [
            parseInt('0x'+specular.slice(0, 2)),
            parseInt('0x'+specular.slice(2, 4)),
            parseInt('0x'+specular.slice(4, 6)),
        ];
        let specularColor0 = specularRgb[0]/255;
        let specularColor1 = specularRgb[1]/255;
        let specularColor2 = specularRgb[2]/255;
        let data = {
          fog:this.fog,
          opacity:this.opacity,
          side:this.side,
          transparent:this.transparent,
          aoMapIntensity:Number(this.aoMapIntensity),
          color:[color0,color1,color2],
          lightMapIntensity:Number(this.lightMapIntensity),
          refractionRatio:Number(this.refractionRatio),
          shininess:Number(this.shininess),
          reflectivity:Number(this.reflectivity),
          emissive:[emissiveColor0,emissiveColor1,emissiveColor2],
          specular:[specularColor0,specularColor1,specularColor2],

          emissiveIntensity:Number(this.emissiveIntensity),
          envMapIntensity:Number(this.envMapIntensity),
        }
        // console.log(data)
        IMaterialPreview.setPhoneMaterial(data)
      }else if(this.optionListValue == "卡通捕捉材质"){
        let specular = this.specular.slice(1);
        let specularRgb = [
            parseInt('0x'+specular.slice(0, 2)),
            parseInt('0x'+specular.slice(2, 4)),
            parseInt('0x'+specular.slice(4, 6)),
        ];
        let specularColor0 = specularRgb[0]/255;
        let specularColor1 = specularRgb[1]/255;
        let specularColor2 = specularRgb[2]/255;
        let data = {
          fog:this.fog,
          opacity:this.opacity,
          side:this.side,
          transparent:this.transparent,
          aoMapIntensity:Number(this.aoMapIntensity),
          color:[color0,color1,color2],
          lightMapIntensity:Number(this.lightMapIntensity),
          refractionRatio:Number(this.refractionRatio),
          shininess:Number(this.shininess),
          reflectivity:Number(this.reflectivity),
          emissive:[emissiveColor0,emissiveColor1,emissiveColor2],
          specular:[specularColor0,specularColor1,specularColor2],

          emissiveIntensity:Number(this.emissiveIntensity),
          // envMapIntensity:Number(this.envMapIntensity),
          matcapIntensity:Number(this.matcapIntensity)
        }
        // console.log(data)
        IMaterialPreview.setToonPhoneMaterial(data,3)
      }else if(this.optionListValue == "卡通捕捉扭动材质"){
        let specular = this.specular.slice(1);
        let specularRgb = [
            parseInt('0x'+specular.slice(0, 2)),
            parseInt('0x'+specular.slice(2, 4)),
            parseInt('0x'+specular.slice(4, 6)),
        ];
        let specularColor0 = specularRgb[0]/255;
        let specularColor1 = specularRgb[1]/255;
        let specularColor2 = specularRgb[2]/255;
        let data = {
          fog:this.fog,
          opacity:this.opacity,
          side:this.side,
          transparent:this.transparent,
          aoMapIntensity:Number(this.aoMapIntensity),
          color:[color0,color1,color2],
          lightMapIntensity:Number(this.lightMapIntensity),
          refractionRatio:Number(this.refractionRatio),
          shininess:Number(this.shininess),
          reflectivity:Number(this.reflectivity),
          emissive:[emissiveColor0,emissiveColor1,emissiveColor2],
          specular:[specularColor0,specularColor1,specularColor2],

          emissiveIntensity:Number(this.emissiveIntensity),
          // envMapIntensity:Number(this.envMapIntensity),
          matcapIntensity:Number(this.matcapIntensity)
        }
        // console.log(data)
        IMaterialPreview.setToonPhoneMaterial(data,4)
      }
      
    },
    emissiveColorChange(){
      let color = this.color.slice(1);
      let rgba = [
          parseInt('0x'+color.slice(0, 2)),
          parseInt('0x'+color.slice(2, 4)),
          parseInt('0x'+color.slice(4, 6)),
      ];
      let color0 = rgba[0]/255;
      let color1 = rgba[1]/255;
      let color2 = rgba[2]/255;

      let emissiveColor = this.emissive.slice(1);
      let rgb = [
          parseInt('0x'+emissiveColor.slice(0, 2)),
          parseInt('0x'+emissiveColor.slice(2, 4)),
          parseInt('0x'+emissiveColor.slice(4, 6)),
      ];
      let emissiveColor0 = rgb[0]/255;
      let emissiveColor1 = rgb[1]/255;
      let emissiveColor2 = rgb[2]/255;

      if(this.optionListValue == "标准材质"){
        let data = {
          fog:this.fog,
          opacity:this.opacity,
          side:this.side,
          transparent:this.transparent,
          aoMapIntensity:Number(this.aoMapIntensity),
          color:[color0,color1,color2],
          lightMapIntensity:Number(this.lightMapIntensity),
          refractionRatio:Number(this.refractionRatio),

          emissive:[emissiveColor0,emissiveColor1,emissiveColor2],

          emissiveIntensity:Number(this.emissiveIntensity),
          envMapIntensity:Number(this.envMapIntensity),
          metalness : Number(this.metalness),
          roughness:Number(this.roughness),
          matType:Number(this.MaterialsSelect),
           materialMat:{m_cauchyb:Number(this.cauchyb),m_exteriorior:Number(this.exterior),m_thickness:Number(this.thickness),m_interiorior:Number(this.interiorior)}
        }
        // console.log(data)
        IMaterialPreview.setStandardMaterial(data)
      }else if(this.optionListValue == "冯氏材质"){
        let specular = this.specular.slice(1);
        let specularRgb = [
            parseInt('0x'+specular.slice(0, 2)),
            parseInt('0x'+specular.slice(2, 4)),
            parseInt('0x'+specular.slice(4, 6)),
        ];
        let specularColor0 = specularRgb[0]/255;
        let specularColor1 = specularRgb[1]/255;
        let specularColor2 = specularRgb[2]/255;
        let data = {
          fog:this.fog,
          opacity:this.opacity,
          side:this.side,
          transparent:this.transparent,
          aoMapIntensity:Number(this.aoMapIntensity),
          color:[color0,color1,color2],
          lightMapIntensity:Number(this.lightMapIntensity),
          refractionRatio:Number(this.refractionRatio),
          shininess:Number(this.shininess),
          reflectivity:Number(this.reflectivity),
          emissive:[emissiveColor0,emissiveColor1,emissiveColor2],
          specular:[specularColor0,specularColor1,specularColor2],

          emissiveIntensity:Number(this.emissiveIntensity),
          envMapIntensity:Number(this.envMapIntensity),
        }
   
        IMaterialPreview.setPhoneMaterial(data)
      }else if(this.optionListValue == "卡通捕捉材质"){
        let specular = this.specular.slice(1);
        let specularRgb = [
            parseInt('0x'+specular.slice(0, 2)),
            parseInt('0x'+specular.slice(2, 4)),
            parseInt('0x'+specular.slice(4, 6)),
        ];
        let specularColor0 = specularRgb[0]/255;
        let specularColor1 = specularRgb[1]/255;
        let specularColor2 = specularRgb[2]/255;
        let data = {
          fog:this.fog,
          opacity:this.opacity,
          side:this.side,
          transparent:this.transparent,
          aoMapIntensity:Number(this.aoMapIntensity),
          color:[color0,color1,color2],
          lightMapIntensity:Number(this.lightMapIntensity),
          refractionRatio:Number(this.refractionRatio),
          shininess:Number(this.shininess),
          reflectivity:Number(this.reflectivity),
          emissive:[emissiveColor0,emissiveColor1,emissiveColor2],
          specular:[specularColor0,specularColor1,specularColor2],

          emissiveIntensity:Number(this.emissiveIntensity),
          // envMapIntensity:Number(this.envMapIntensity),
          matcapIntensity:Number(this.matcapIntensity),
        }
   
        IMaterialPreview.setToonPhoneMaterial(data,3)
      }else if(this.optionListValue == "卡通捕捉扭动材质"){
        let specular = this.specular.slice(1);
        let specularRgb = [
            parseInt('0x'+specular.slice(0, 2)),
            parseInt('0x'+specular.slice(2, 4)),
            parseInt('0x'+specular.slice(4, 6)),
        ];
        let specularColor0 = specularRgb[0]/255;
        let specularColor1 = specularRgb[1]/255;
        let specularColor2 = specularRgb[2]/255;
        let data = {
          fog:this.fog,
          opacity:this.opacity,
          side:this.side,
          transparent:this.transparent,
          aoMapIntensity:Number(this.aoMapIntensity),
          color:[color0,color1,color2],
          lightMapIntensity:Number(this.lightMapIntensity),
          refractionRatio:Number(this.refractionRatio),
          shininess:Number(this.shininess),
          reflectivity:Number(this.reflectivity),
          emissive:[emissiveColor0,emissiveColor1,emissiveColor2],
          specular:[specularColor0,specularColor1,specularColor2],

          emissiveIntensity:Number(this.emissiveIntensity),
          // envMapIntensity:Number(this.envMapIntensity),
          matcapIntensity:Number(this.matcapIntensity),
        }
   
        IMaterialPreview.setToonPhoneMaterial(data,4)
      }
    },
    //高光贴图颜色
    specularColorChange(){
      let color = this.color.slice(1);
      let rgba = [
          parseInt('0x'+color.slice(0, 2)),
          parseInt('0x'+color.slice(2, 4)),
          parseInt('0x'+color.slice(4, 6)),
      ];
      let color0 = rgba[0]/255;
      let color1 = rgba[1]/255;
      let color2 = rgba[2]/255;

      let emissiveColor = this.emissive.slice(1);
      let rgb = [
          parseInt('0x'+emissiveColor.slice(0, 2)),
          parseInt('0x'+emissiveColor.slice(2, 4)),
          parseInt('0x'+emissiveColor.slice(4, 6)),
      ];
      let emissiveColor0 = rgb[0]/255;
      let emissiveColor1 = rgb[1]/255;
      let emissiveColor2 = rgb[2]/255;

      let specular = this.specular.slice(1);
      let specularRgb = [
          parseInt('0x'+specular.slice(0, 2)),
          parseInt('0x'+specular.slice(2, 4)),
          parseInt('0x'+specular.slice(4, 6)),
      ];
      let specularColor0 = specularRgb[0]/255;
      let specularColor1 = specularRgb[1]/255;
      let specularColor2 = specularRgb[2]/255;

      let data = {
          fog:this.fog,
          opacity:this.opacity,
          side:this.side,
          transparent:this.transparent,
          aoMapIntensity:Number(this.aoMapIntensity),
          color:[color0,color1,color2],
          lightMapIntensity:Number(this.lightMapIntensity),
          refractionRatio:Number(this.refractionRatio),
          shininess:Number(this.shininess),
          reflectivity:Number(this.reflectivity),
          emissive:[emissiveColor0,emissiveColor1,emissiveColor2],
          specular:[specularColor0,specularColor1,specularColor2],

          emissiveIntensity:Number(this.emissiveIntensity),
          envMapIntensity:Number(this.envMapIntensity),
        }
        // console.log(data)
        IMaterialPreview.setPhoneMaterial(data)
    },
    specularActiveColor(value){
      let value1 =  this.transitionColror(value)
      this.specular =value1;
      let specular = value1.slice(1);
      let specularRgb = [
          parseInt('0x'+specular.slice(0, 2)),
          parseInt('0x'+specular.slice(2, 4)),
          parseInt('0x'+specular.slice(4, 6)),
      ];
      let specularColor0 = specularRgb[0]/255;
      let specularColor1 = specularRgb[1]/255;
      let specularColor2 = specularRgb[2]/255;
      
      let color = this.color.slice(1);
      let rgba = [
          parseInt('0x'+color.slice(0, 2)),
          parseInt('0x'+color.slice(2, 4)),
          parseInt('0x'+color.slice(4, 6)),
      ];
      let color0 = rgba[0]/255;
      let color1 = rgba[1]/255;
      let color2 = rgba[2]/255;

      let emissiveColor = this.emissive.slice(1);
      let rgb = [
          parseInt('0x'+emissiveColor.slice(0, 2)),
          parseInt('0x'+emissiveColor.slice(2, 4)),
          parseInt('0x'+emissiveColor.slice(4, 6)),
      ];
      let emissiveColor0 = rgb[0]/255;
      let emissiveColor1 = rgb[1]/255;
      let emissiveColor2 = rgb[2]/255;
      
      let data = {
          fog:this.fog,
          opacity:this.opacity,
          side:this.side,
          transparent:this.transparent,
          aoMapIntensity:Number(this.aoMapIntensity),
          color:[color0,color1,color2],
          lightMapIntensity:Number(this.lightMapIntensity),
          refractionRatio:Number(this.refractionRatio),
          shininess:Number(this.shininess),
          reflectivity:Number(this.reflectivity),
          emissive:[emissiveColor0,emissiveColor1,emissiveColor2],
          specular:[specularColor0,specularColor1,specularColor2],

          emissiveIntensity:Number(this.emissiveIntensity),
          envMapIntensity:Number(this.envMapIntensity),
        }
        // console.log(data)
        IMaterialPreview.setPhoneMaterial(data)
    },
    //颜色
    MapChange(event){
      let checked = event.target.checked
      if(checked == true){
        this.$emit('passVaule','map')
        this.$store.commit("terrainTypeChange",'modelType')
      }
    },
    //光照
    lightMapChecked(event){
      let checked = event.target.checked
      if(checked == true){
        this.$emit('passVaule','lightMap')
        this.$store.commit("terrainTypeChange",'modelType')
      }
    },
    //环境
    envMapChange(event){
      let checked = event.target.checked
      if(checked == true){
        this.$emit('passVaule','envMap')
        this.$store.commit("terrainTypeChange",'modelType')
      }
    },
    // 捕捉纹理
    captureMapChange(event){
      let checked = event.target.checked;
      if(checked == true){
        this.$emit('passVaule','matcap')
        this.$store.commit("terrainTypeChange",'modelType')
      }
    },
    //梯度纹理
    gradientMapChange(event){
      let checked = event.target.checked;
      if(checked == true){
        
        this.$emit('passVaule','gradientMap')
        this.$store.commit("terrainTypeChange",'modelType')
      }
    },
    //环境遮挡
    aoMapChange(event){
      let checked = event.target.checked
      if(checked == true){
        this.$emit('passVaule','aoMap')
        this.$store.commit("terrainTypeChange",'modelType')
      }
    },
    //高光
    specularMapChange(event){
      let checked = event.target.checked
      if(checked == true){
        this.$emit('passVaule','specularMap')
        this.$store.commit("terrainTypeChange",'modelType')
      }
    },
    //金属度
    metalnessMapChange(event){
      let checked = event.target.checked
      if(checked == true){
        this.$emit('passVaule','metalnessMap')
        this.$store.commit("terrainTypeChange",'modelType')
      }
    },
    //粗糙度
    roughnessMapChange(event){
      let checked = event.target.checked
      if(checked == true){
        this.$emit('passVaule','roughnessMap')
        this.$store.commit("terrainTypeChange",'modelType')
      }
    },
    //法线
    normalMapChange(event){
      let checked = event.target.checked
      if(checked == true){
        this.$emit('passVaule','normalMap')
        this.$store.commit("terrainTypeChange",'modelType')
      }
    },
    //发光
    emissiveMapChange(event){
      let checked = event.target.checked
      if(checked == true){
        this.$emit('passVaule','emissiveMap')
        this.$store.commit("terrainTypeChange",'modelType')
      }
    },
    //颜色重置
    ColorResetChange(){
      IMaterialPreview.setMap(null,'map')
    },
    //金属度重置
    MetaResetChange(){
      IMaterialPreview.setMap(null,'metalnessMap')
    },
    //粗糙度重置
    roughResetChange(){
      IMaterialPreview.setMap(null,'roughnessMap')
    },
    //法线重置
    NormalResetChange(){
      IMaterialPreview.setMap(null,'normalMap')
    },
    //发光重置
    emissiveResetChange(){
      IMaterialPreview.setMap(null,'emissiveMap')
    },
    // //高光重置
    // specularResetChange(){
    //   IMaterialPreview.setMap(null,'specular')
    // },
    //环境重置
    envMapResetChange(){
      IMaterialPreview.setMap(null,'envMap')
      let hdr = document.querySelector('#hdrImg5');
      let hdr1 = document.querySelector('#hdrImg4');
      let hdr2 = document.querySelector('#hdrImg2');
      hdr.style.background = "#fff";
      hdr1.style.background = "#fff"
      hdr2.style.background = "#fff"
    },
    aoMapResetChange(){
      IMaterialPreview.setMap(null,'aoMap')
    },
    //光照重置
    LightResetChange(){
      IMaterialPreview.setMap(null,'lightMap')
    },
    gradientMapResetChange(){
      IMaterialPreview.setMap(null,'gradientMap')
    },
    captureMapResetChange(){
      IMaterialPreview.setMap(null,'matcap')
      let hdr = document.querySelector('#hdrImg6');
      hdr.style.background = "#fff";
    },
    //高光重置
    specularResetChange(){
      IMaterialPreview.setMap(null,'specularMap')
      // console.log("重置")
    },
    RepeatUvChange(){
      let data  = {
          offset:[Number(this.ColorOffsetU), Number(this.ColorOffsetV)],
          repeat:[Number(this.ColorRepeatU), Number(this.ColorRepeatV)],
          rotation: Number(this.ColorRotation)
      }
      IMaterialPreview.setTextureUv(data,'map',0)
    },
    OffsetUvChange(){
      let data  = {
          offset: [Number(this.ColorOffsetU), Number(this.ColorOffsetV)],
          repeat: [Number(this.ColorRepeatU), Number(this.ColorRepeatV)],
          rotation: Number(this.ColorRotation)
      }
      IMaterialPreview.setTextureUv(data,'map',1)
    },
    RotationUvChange(){
      let data  = {
          offset: [Number(this.ColorOffsetU), Number(this.ColorOffsetV)],
          repeat: [Number(this.ColorRepeatU), Number(this.ColorRepeatV)],
          rotation: Number(this.ColorRotation)
      }
      IMaterialPreview.setTextureUv(data,'map',2)
    },
    RepeatMetaUvChange(){
      let data  = {
          offset:[Number(this.MetalnessOffsetU), Number(this.MetalnessOffsetV)],
          repeat:[Number(this.MetalnessRepeatU), Number(this.MetalnessRepeatV)],
          rotation: Number(this.MetalnessRotation)
      }
      IMaterialPreview.setTextureUv(data,'metalnessMap',0);
    },
    OffsetMetaUvChange(){
      let data  = {
          offset: [Number(this.MetalnessOffsetU), Number(this.MetalnessOffsetV)],
          repeat: [Number(this.MetalnessRepeatU), Number(this.MetalnessRepeatV)],
          rotation: Number(this.MetalnessRotation)
      }
      IMaterialPreview.setTextureUv(data,'metalnessMap',1);
    },
    RotationMetaUvChange(){
      let data  = {
          offset: [Number(this.MetalnessOffsetU), Number(this.MetalnessOffsetV)],
          repeat: [Number(this.MetalnessRepeatU), Number(this.MetalnessRepeatV)],
          rotation: Number(this.MetalnessRotation)
      }
      IMaterialPreview.setTextureUv(data,'metalnessMap',2);
    },
    RepeatroughnessUvChange(){
      let data  = {
          offset: [Number(this.roughnessOffsetU), Number(this.roughnessOffsetV)],
          repeat: [Number(this.roughnessRepeatU), Number(this.roughnessRepeatV)],
          rotation: Number(this.roughnessRotation)
      }
      IMaterialPreview.setTextureUv(data,'roughnessMap',0)
    },
    OffsetroughnessUvChange(){
      let data  = {
          offset: [Number(this.roughnessOffsetU), Number(this.roughnessOffsetV)],
          repeat: [Number(this.roughnessRepeatU), Number(this.roughnessRepeatV)],
          rotation: Number(this.roughnessRotation)
      }
      IMaterialPreview.setTextureUv(data,'roughnessMap',1)
    },
    RotationroughnessUvChange(){
      let data  = {
          offset: [Number(this.roughnessOffsetU), Number(this.roughnessOffsetV)],
          repeat: [Number(this.roughnessRepeatU), Number(this.roughnessRepeatV)], 
          rotation: Number(this.roughnessRotation)
      }
      IMaterialPreview.setTextureUv(data,'roughnessMap',2)
    },
    RepeatnormalUvChange(){
      let data  = {
          offset: [Number(this.normalOffsetU), Number(this.normalOffsetV)],
          repeat: [Number(this.normalRepeatU), Number(this.normalRepeatV)], 
          rotation: Number(this.normalRotation)
      }
      IMaterialPreview.setTextureUv(data,'normalMap',0)
    },
    OffsetnormalUvChange(){
      let data  = {
          offset: [Number(this.normalOffsetU), Number(this.normalOffsetV)],
          repeat: [Number(this.normalRepeatU), Number(this.normalRepeatV)], 
          rotation: Number(this.normalRotation)
      }
      IMaterialPreview.setTextureUv(data,'normalMap',1)
    },
    RotationnormalUvChange(){
      let data  = {
          offset: [Number(this.normalOffsetU), Number(this.normalOffsetV)],
          repeat: [Number(this.normalRepeatU), Number(this.normalRepeatV)], 
          rotation: Number(this.normalRotation)
      }
      IMaterialPreview.setTextureUv(data,'normalMap',2)
    },
    RepeatemissiveUvChange(){
      let data  = {
          offset: [Number(this.emissiveOffsetU), Number(this.emissiveOffsetUV)],
          repeat: [Number(this.emissiveRepeatU), Number(this.emissiveRepeatV)],
          rotation: Number(this.emissiveRotation)
      }
      IMaterialPreview.setTextureUv(data,'emissiveMap',0)
    },
    OffsetemissiveUvChange(){
      let data  = {
          offset: [Number(this.emissiveOffsetU), Number(this.emissiveOffsetV)],
          repeat: [Number(this.emissiveRepeatU), Number(this.emissiveRepeatV)],
          rotation: Number(this.emissiveRotation)
      }
      IMaterialPreview.setTextureUv(data,'emissiveMap',1)
    },
    RotationemissiveUvChange(){
      let data  = {
          offset: [Number(this.emissiveOffsetU), Number(this.emissiveOffsetV)],
          repeat: [Number(this.emissiveRepeatU), Number(this.emissiveRepeatV)],
          rotation: Number(this.emissiveRotation)
      }
      IMaterialPreview.setTextureUv(data,'emissiveMap',2)
    },
    colorGammaChange(){
      let data  = {
          offset: [Number(this.ColorOffsetU), Number(this.ColorOffsetV)],
          repeat: [Number(this.ColorRepeatU), Number(this.ColorRepeatV)],
          rotation: Number(this.ColorRotation),
          gamma:Number(this.colorGamma)
      }
     
      IMaterialPreview.setTextureUv(data,'map',3)
    },
    RotationGammaChange(){
      let data  = {
          offset: [Number(this.ColorOffsetU), Number(this.ColorOffsetV)],
          repeat: [Number(this.ColorRepeatU), Number(this.ColorRepeatV)],
          rotation: Number(this.ColorRotation),
          gamma:Number(this.MetalnessGamma)
      }
      IMaterialPreview.setTextureUv(data,'metalnessMap',3)
    },
    roughnessGammaChange(){
      let data  = {
          offset: [Number(this.ColorOffsetU), Number(this.ColorOffsetV)],
          repeat: [Number(this.ColorRepeatU), Number(this.ColorRepeatV)],
          rotation: Number(this.ColorRotation),
          gamma:Number(this.roughnessGamma)
      }
      IMaterialPreview.setTextureUv(data,'roughnessMap',3)
    },
    normalGammaChange(){
      let data  = {
          offset: [Number(this.ColorOffsetU), Number(this.ColorOffsetV)],
          repeat: [Number(this.ColorRepeatU), Number(this.ColorRepeatV)],
          rotation: Number(this.ColorRotation),
          gamma:Number(this.normalGamma)
      }
      IMaterialPreview.setTextureUv(data,'normalMap',3)
    },
    emissiveGammaChange(){
      let data  = {
          offset: [Number(this.ColorOffsetU), Number(this.ColorOffsetV)],
          repeat: [Number(this.ColorRepeatU), Number(this.ColorRepeatV)],
          rotation: Number(this.ColorRotation),
          gamma:Number(this.emissiveGamma)
      }
      IMaterialPreview.setTextureUv(data,'emissiveMap',3)
    },
    //材料：色散、玻璃
    exteriorChange(){

    },
    //键盘事件
    Focus(){
        ISetting.isInput(true);
    },
    Blur(){
        ISetting.isInput(false);
    },
    //rgb转十六进制
    transitionColror(color){
          var values = color
        .replace(/rgba?\(/, '')
        .replace(/\)/, '')
        .replace(/[\s+]/g, '')
        .split(',');
        var a = parseFloat(values[3] || 1),
            r = Math.round(a * parseInt(values[0]) + (1 - a) * 255),
            g = Math.round(a * parseInt(values[1]) + (1 - a) * 255),
            b = Math.round(a * parseInt(values[2]) + (1 - a) * 255);
        return "#" +
            ("0" + r.toString(16)).slice(-2) +
            ("0" + g.toString(16)).slice(-2) +
            ("0" + b.toString(16)).slice(-2);
    },
    
  },
};
</script>
  <style scoped>
  .header_box{
    width: 95%;
    height: 87%;
    margin-top: 52px;
    /* border: 1px solid red; */
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);
    padding-top:15px;
    margin-left: 2px;
    border-radius: 5px;
  }
  .box {
    width: 95%;
    height: 380px;
    /* box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1); */
    border-radius: 5px;
    margin-top: 8px;
    margin-bottom: 30px;
    font-size: 10px;
    background: #ffff;
    overflow-y: auto;
    padding-bottom: 30px;
  }
  .box::-webkit-scrollbar {
    display: none; 
  }
  .box{
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  .Standard::-webkit-scrollbar {
    display: none;  
  }
  .Standard{
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  .Standard {
    width: 95%;
    height: 380px;
    /* box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1); */
    border-radius: 5px;
    margin-top: 8px;
    margin-bottom: 30px;
    font-size: 10px;
    background: #ffff;
    overflow-y: auto;
    padding-bottom: 30px;
  }
  .active{
    background: red;
  }
  .header {
    display: flex;
    width: 90%;
    height: 25px;
    /* margin: 20px auto; */
    align-items: center;
    color: #000;
    font-weight: bold;
    font-size: 12px;
    margin-top: 12px;
  }
 .header .ipt_box {
    width: 270px;
    height: 30px;
    box-shadow: 0px 0px 2px 0px rgba(33, 35, 49, 0.1);
    border-radius: 6px;
    display: flex;
    align-items: center;
    padding-left: 10px;
  }
  .header .select {
    width: 260px;
    height: 28px;
    outline: none;
    color: #808080;
    border: none;
    border-radius: 4px;
    margin-left: 10px;
  }
  .header p {
    width: 60px;
    overflow: hidden;
    height: 30px;
    line-height: 30px;
    margin-left: 20px;
  }
  .content {
    width: 95%;
    height: 240px;
    background: #ffffff;
    box-shadow: 0px 0px 6px 0px rgba(33, 35, 49, 0.1);
    border-radius: 10px;
    margin: auto;
    margin-top: 20px;
    position: relative;
    overflow: hidden;
    margin-left: 25px;
  }
  .anmDiv {
    transition: 1s;
  }
  .aaa {
    height: 60px;
    transition: 1s;
  }
  .content .conColor {
    display: flex;
    width: 95%;
    height: 30px;
    align-items: center;
    margin: auto;
    padding-top: 15px;
  }
  .conColor .title {
    width: 70px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-weight: bold;
    overflow: hidden;
  }
  .conColor .chartlet {
    width: 18px;
    height: 18px;
    border-radius: 4px;
    border: 1px solid #161a1e;
    margin-left: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .conColor .colorIpt{
    width: 18px;
    height: 18px;
    margin-left: 15px;
  }
  .conColor .chartlet img {
    width: 100%;
    height: 100%;
  }
  .conColor >>> .el-color-picker__trigger {
    height: 30px;
    width: 135px;
    margin-left: 20px;
    margin-top: 5px;
    border-radius: 6px;
  }
  .color_box{
    width: 80px;
    height: 32px;
    display: flex;
    align-items: center;
  }
  .color_box >>> .el-color-picker__trigger{
    width: 80px;
    height: 32px;
  }
  .conColor .ipt_box {
    width: 140px;
    height: 30px;
    box-shadow: 0px 0px 2px 0px rgba(33, 35, 49, 0.1);
    border-radius: 6px;
    display: flex;
    align-items: center;
    margin-left: 15px;
  }
  .conColor .ipt {
    width: 135px;
    height: 28px;
    outline: none;
    border: none;
    color: #808080;
    border-radius: 5px;
  }
  .conColor .txt_box1 {
  width: 55px;
  height: 25px;
  box-shadow: 0px 0px 2px 0px rgba(33, 35, 49, 0.1);
  border-radius: 6px;
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.conColor .txt_box1 input {
  width: 50px;
  height: 22px;
  outline: none;
  border: none;
  color: #808080;
  border-radius: 5px;
  text-align: center;
}
.conColor .slider{
  width: 180px;
  margin-left: 20px;
  margin-right: 10px;
}
  .uv {
    display: flex;
    width: 90%;
    height: 25px;
    margin: auto;
    align-items: center;
    color: #000;
    font-weight: bold;
    font-size: 12px;
  }
  .uv i {
    font-weight: bold;
    cursor: pointer;
  }
  .uv .title {
    width: 60px;
    overflow: hidden;
    height: 25px;
    line-height: 25px;
  }
  .illumination {
    display: flex;
    width: 95%;
    height: 62px;
    background: #ffffff;
    box-shadow: 0px 0px 6px 0px rgba(33, 35, 49, 0.1);
    border-radius: 10px;
    align-items: center;
    margin: 20px auto;
    position: relative;
    margin-left: 25px;
  }
  .illumination .title {
    width: 85px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-weight: bold;
    overflow: hidden;
  }
  .illumination .chartlet {
    width: 18px;
    height: 18px;
    border-radius: 4px;
    border: 1px solid #161a1e;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .illumination .chartlet img {
    width: 100%;
    height: 100%;
  }
  .illumination .ipt_box {
    width: 140px;
    height: 30px;
    box-shadow: 0px 0px 2px 0px rgba(33, 35, 49, 0.1);
    border-radius: 6px;
    display: flex;
    align-items: center;
    margin-left: 15px;
  }
  .illumination .ipt {
    width: 135px;
    height: 28px;
    outline: none;
    border: none;
    color: #808080;
    border-radius: 5px;
  }
  .chartlet_box{
    display: flex;
    width: 80px;
    height: 35px;
    box-shadow: 0px 0px 6px 0px rgba(33, 35, 49, 0.1);
    align-items: center;
    border-radius: 8px;
    justify-content: space-evenly;
  }
  .refractive {
    display: flex;
    width: 95%;
    height: 62px;
    background: #ffffff;
    box-shadow: 0px 0px 6px 0px rgba(33, 35, 49, 0.1);
    border-radius: 10px;
    align-items: center;
    margin: 20px auto;
    margin-left: 25px;
  }
  .refractive .title {
    width: 85px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-weight: bold;
    overflow: hidden;
  }
  .refractive .slider {
    width: 150px;
    margin-right: 10px;
    margin-left: 20px;
  }
  .conColor .slider{
  width: 300px;
  margin-left: 20px;
  margin-right: 10px;
}
.slider >>> .el-slider__input{
  width:110px !important;
  position: absolute !important;
  right: 55px !important;
}
  .refractive .ipt_box {
    width: 55px;
    height: 25px;
    box-shadow: 0px 0px 2px 0px rgba(33, 35, 49, 0.1);
    border-radius: 6px;
    display: flex;
    text-align: center;
    align-items: center;
  }
  .refractive .ipt_box input {
    width: 50px;
    height: 22px;
    outline: none;
    border: none;
    color: #808080;
    border-radius: 5px;
    text-align: center;
  }
  .fog {
    display: flex;
    width: 95%;
    height: 62px;
    background: #ffffff;
    box-shadow: 0px 0px 6px 0px rgba(33, 35, 49, 0.1);
    border-radius: 10px;
    align-items: center;
    margin: 20px auto;
    margin-left: 25px;
  }
  .fog .title {
    width: 85px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-weight: bold;
    overflow: hidden;
  }
  .fog .ipt_box {
    width: 65px;
    height: 25px;
    box-shadow: 0px 0px 2px 0px rgba(33, 35, 49, 0.1);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .lucency_box {
    width: 95%;
    height: 104px;
    background: #ffffff;
    box-shadow: 0px 0px 6px 0px rgba(33, 35, 49, 0.1);
    border-radius: 10px;
    margin: 20px auto;
    margin-left: 25px;
  }
  .lucency_box .lucency {
    width: 100%;
    height: 50%;
    display: flex;
    align-items: center;
  }
  .lucency_box .lucency .title {
    width: 85px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-weight: bold;
    overflow: hidden;
  }
  .lucency_box .lucency .ipt_box {
    width: 65px;
    height: 25px;
    box-shadow: 0px 0px 2px 0px rgba(33, 35, 49, 0.1);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .lucency_box .transparency {
    width: 100%;
    height: 50%;
    display: flex;
    align-items: center;
  }
  .lucency_box .transparency .title {
    width: 85px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-weight: bold;
    overflow: hidden;
  }
  .lucency_box .transparency .slider {
    width: 150px;
    margin-right: 10px;
  }
  .slider >>>.el-slider__button{
    width: 10px;
    height: 10px;
    background: #0560FD;
  }
  .slider >>>.el-slider__bar{
    background: #0560FD;
  }
  .lucency_box .transparency .ipt_box {
    width: 55px;
    height: 25px;
    box-shadow: 0px 0px 2px 0px rgba(33, 35, 49, 0.1);
    border-radius: 6px;
    display: flex;
    align-items: center;
  }
  .lucency_box .transparency .ipt_box input {
    width: 50px;
    height: 22px;
    outline: none;
    border: none;
    color: #808080;
    border-radius: 5px;
    text-align: center;
  }
  .render {
    display: flex;
    width: 95%;
    height: 62px;
    background: #ffffff;
    box-shadow: 0px 0px 6px 0px rgba(33, 35, 49, 0.1);
    border-radius: 10px;
    align-items: center;
    margin: 20px auto;
    margin-left: 25px;
  }
  .render .title {
    width: 85px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-weight: bold;
    overflow: hidden;
  }
  .render .ipt_box {
    width: 200px;
    height: 30px;
    box-shadow: 0px 0px 2px 0px rgba(33, 35, 49, 0.1);
    border-radius: 6px;
    display: flex;
    align-items: center;
  }
  .render .select {
    width: 195px;
    height: 28px;
    outline: none;
    color: #808080;
    border: none;
    border-radius: 4px;
  }
  .render .ipt_box1{
    width: 200px;
    height: 30px;
    box-shadow: 0px 0px 2px 0px rgba(33, 35, 49, 0.1);
    border-radius: 6px;
    display: flex;
    align-items: center;
    margin-right: 10px;
  }
  .render .ipt_box1 input{
    width: 95%;
    height: 25px;
    outline: none;
    border: none;
    background-color: #fff;
    border-radius: 4px;
    font-weight: bold;
  }
.render .select /deep/ .el-input{
   width: 195px !important;
   height: 35px !important;
   outline: none !important;
}
.render .select /deep/ .el-input__inner{
   width: 195px !important;
   height: 28px !important;
   outline: none !important;
}
.render .select /deep/ .el-select__caret{
  height: 28px !important;
  line-height: 28px !important;
}
  .loction {
    width: 95%;
    margin: 20px auto;
    display: flex;
    height: 25px;
    align-items: center;
    justify-content: space-around;
  }
  .loction .title {
    width: 50px;
    height: 25px;
    text-align: center;
    line-height: 25px;
    font-weight: bolder;
    overflow: hidden;
  }
  .loction .ipt_box{
    width: 65%;
    height: 30px;
    box-shadow: 0px 0px 2px 0px rgba(33, 35, 49, 0.1);
    border-radius: 6px;
    display: flex;
    align-items: center;
    margin-right: 10px;
  }
  .loction .ipt_box input{
    width: 95%;
    height: 25px;
    outline: none;
    border: none;
    background-color: #fff;
    border-radius: 4px;
    font-weight: bold;
  }
  .axis {
    width: 25%;
    height: 100%;
    display: flex;
    align-items: center;
    background-color: #f7f7f7;
    border-radius: 5px;
    margin-right: 15px;
  }
  .axis p {
    width: 25px;
    height: 20px;
    line-height: 20px;
    color: #808080;
    background-color: #fff;
    margin-left: 2px;
    border-radius: 6px;
    float: left;
    text-align: center;
    color: #000;
  }
  .axis input {
    width: 80%;
    height: 18px;
    text-align: center;
    outline: none;
    border: none;
    background-color: #f7f7f7;
    border-radius: 4px;
    font-weight: bold;
  }
  .input:checked {
    background: #1673ff;
  }
  .input {
    width: 16px;
    height: 16px;
    outline: none;
    background-color: rgba(125, 128, 133, 0.4);
    border: solid 1px #dddddd;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    font-size: 0.8rem;
    padding: 0;
    position: relative;
    display: inline-block;
    vertical-align: top;
    cursor: default;
    -webkit-appearance: none;
    -webkit-user-select: none;
    user-select: none;
    -webkit-transition: background-color ease 0.1s;
    transition: background-color ease 0.1s;
    outline: none;
  }
  .input:checked::after {
    content: "";
    top: 3px;
    left: 3px;
    position: absolute;
    background: transparent;
    border: #fff solid 2px;
    border-top: none;
    border-right: none;
    height: 4px;
    width: 6px;
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  img[src=""],img:not([src]){
      opacity:0;
  }
  .shrink{
    position: absolute;
    left: 10px;
    top: 22px;
  }
  .shrink i{
    font-weight: bold;
    cursor: pointer;
  }
  input{
    text-indent: 5px;
  }
  .reset{
      width: 30px;
      height: 20px;
      text-align: center;
      line-height: 25px;
      box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
      border-radius: 5px;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      /* margin-left: 10px; */
      position: absolute;
      right: 10px;
      top: 20px;
      cursor: pointer;
  }
  .reset img{
      width: 16px;
      height: 12px;
  }
  .normaReset{
      width: 30px;
      height: 20px;
      text-align: center;
      line-height: 25px;
      box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
      border-radius: 5px;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 10px;
      top: 20px;
      cursor: pointer;
  }
  .normaReset img{
    width: 16px;
    height: 12px;
  }
  .txt_box{
    width: 80px;
    height: 30px;
    box-shadow: 0px 0px 2px 0px rgba(33, 35, 49, 0.1);
    border-radius: 6px;
    display: flex;
    align-items: center;
    margin-left: 15px;
  }
  .emissiveIpt{
    width: 75px;
    height: 28px;
    outline: none;
    border: none;
    color: #808080;
    border-radius: 5px;
  }
  .import_color{
  width: 70px;
  height: 30px;
  box-shadow: 0px 0px 2px 0px rgba(33, 35, 49, 0.1);
  border-radius: 6px;
  display: flex;
  text-align: center;
  align-items: center;
  margin-left: 10px;
}
.import_color input{
  width: 65px;
  height: 28px;
  outline: none;
  border: none;
  color: #808080;
  border-radius: 5px;
  text-align: center;
}
  </style>