<template>
  <!-- @contextmenu.prevent="rightClick()" -->
  <div>
    <div class="box" v-loading="isLoad">

      <div class="content" :class="hide ? 'anmDiv' : 'aaa'" v-if="dataList.length != 0">


        <!-- <div class="title">层级</div> -->
        <!-- 给非3d -->
        <div v-if="isThree == 0" class="tree">
          <el-tree :draggable="($store.state.userPermissionData.SCENE_FOLDER_MOVE && resourceType == 1) ||
            ($store.state.userPermissionData.MODEL_FOLDER_MOVE && resourceType == 2) ||
            ($store.state.userPermissionData.PICTUREMATERIAL_FOLDER_MOVE && resourceType == 3) ||
            ($store.state.userPermissionData.MEDIA_FOLDER_MOVE && resourceType == 8)

            " :data="dataList" node-key="id" :props="defaultProps" @node-drag-start="handleDragStart"
            @node-drop="handleDrop" :allow-drop="allowDrop" :allow-drag="allowDrag" @node-click="handleNodeClick"
            @node-contextmenu="rightClick" @node-expand="handleNodeExpand" @node-collapse="handleNodeCollapse"
            :default-expanded-keys="defaultShowNodes" :expand-on-click-node="false" :highlight-current="true" ref="tree"
            :indent="6">
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <!-- 非编辑状态 -->

              <span class="custom-tree-node-content">
                <!-- <i v-if="data.type == 1" class="el-icon-document fileIcon"></i> -->

                <!-- <img :src="data.imageUrl|filtrationImg" alt class="fileIcon" /> -->
                <el-tooltip class="item" effect="dark" :content="node.label" placement="bottom">
                  <span class="tree_label" @dblclick="rechristen(node, data)">{{ node.label }} </span>
                </el-tooltip>

                <!-- $store.state.UserRank== 3 -->

                <img v-if="data.local == 0 && resourceType != 6 && $store.state.chosenWarehouse.type == 0"
                  src="../../assets/Resource/icon_unlocking.png" alt class="lock" @click="cutLock(data)" />
                <img v-if="data.local == 1 && resourceType != 6 && $store.state.chosenWarehouse.type == 0"
                  src="../../assets/Resource/icon_lock.png" alt class="lock" @click="cutLock(data)" />
                <!-- -->
                <img
                  v-if="resourceType != 6 && resourceType != 1 && data.isUpload == '1' && $store.state.chosenWarehouse.type == 0"
                  src="../../assets/sharedFile/uploading.png" alt class="share" @click="cutLock(data)" />

              </span>
            </span>
          </el-tree>
        </div>
      </div>

      <div v-if="($store.state.userPermissionData.SCENE_FOLDER_CREATION && resourceType == 1) ||

        ($store.state.userPermissionData.MODEL_FOLDER_CREATION && resourceType == 2) ||
        ($store.state.userPermissionData.PICTUREMATERIAL_FOLDER_CREATION && resourceType == 3) ||
        ($store.state.userPermissionData.MEDIA_FOLDER_CREATION && resourceType == 8) ||
        resourceType == 5



        ">
        <div v-show="resourceType != 6 && $store.state.chosenWarehouse.type == 0" class="empty" @click="myAddRotFolder()">
          <img class="empty_img" src="../../assets/Resource/empty.png" alt />
        </div>
      </div>


      <!-- <div class="contentTxt" @click="tabChange">{{text}}</div> -->
      <div class="myMenu">
        <div v-show="menu">
          <el-menu id="menu" @select="handleSelect" text-color="#000" class="menu" ref="menu">

            <div v-if="($store.state.userPermissionData.SCENE_FOLDER_CREATION && resourceType == 1 && FolderLocal == 0 && $store.state.chosenWarehouse.type == 0) ||

              ($store.state.userPermissionData.MODEL_FOLDER_CREATION && resourceType == 2 && FolderLocal == 0 && $store.state.chosenWarehouse.type == 0) ||
              ($store.state.userPermissionData.PICTUREMATERIAL_FOLDER_CREATION && resourceType == 3 && FolderLocal == 0 && $store.state.chosenWarehouse.type == 0) ||
              ($store.state.userPermissionData.MEDIA_FOLDER_CREATION && resourceType == 8 && FolderLocal == 0 && $store.state.chosenWarehouse.type == 0) ||
              (resourceType == 5 && FolderLocal == 0 && $store.state.chosenWarehouse.type == 0)






              ">


              <el-menu-item v-show="(recycle == false && rightClickType == 0) ||
                isThree == 1 ||
                dataList.length == 0
                " index="1" class="menuItem">
                <span slot="title">
                  <img src="../../assets/Resource/created_folder.png" alt class="menuIcon" />
                  新建文件夹
                </span>
              </el-menu-item>
            </div>

            <!-- <div v-if="($store.state.userPermissionData.SCENE_FOLDER_CREATION_SCENE && resourceType == 1) ||
            
              ($store.state.userPermissionData.MODEL_FOLDER_UPLOAD_MODEL && resourceType == 2) ||
              ($store.state.userPermissionData.PICTUREMATERIAL_FOLDER_UPLOAD_PICTURE && resourceType == 3) ||
              ($store.state.userPermissionData.MEDIA_FOLDER_CREATION && resourceType == 8)
            
            
            ">

              <el-menu-item v-show="
                (recycle == false && rightClickType == 0 && !$store.state.textureBallShow) ||
                (recycle == false && dataList.length != 0 && !$store.state.textureBallShow)
              " index="2" class="menuItem">
                <span slot="title">
                  <img v-if="establishType == '上传模型' || establishType == '上传贴图'"
                    src="../../assets/Resource/uploading.png" alt class="menuIcon" />

                  <img v-else-if="establishType == '上传媒体'" src="../../assets/Resource/multimedia.png" alt
                    class="menuIcon" />

                  <img v-else src="../../assets/Resource/CreateScenario.png" alt class="menuIcon" />
                  {{ establishType }}
                </span>
              </el-menu-item>
            </div>
            <div
              v-if="($store.state.userPermissionData.PICTUREMATERIAL_FOLDER_CREATION_BASICS_MATERIAL && resourceType == 3)">


              <el-menu-item v-show="
                (recycle == false && rightClickType == 0 && resourceType == 3 && !$store.state.textureBallShow) ||
                (recycle == false && dataList.length != 0 && resourceType == 3 && !$store.state.textureBallShow)
              " index="6" class="menuItem">
                <span slot="title">
                  <img src="../../assets/Resource/baseMaterial.png" alt class="menuIcon" />
                  创建基础材质
                </span>
              </el-menu-item>
            </div>

            <div
              v-if="($store.state.userPermissionData.PICTUREMATERIAL_FOLDER_CREATION_STANDARD_MATERIAL && resourceType == 3)">
              <el-menu-item v-show="
                (recycle == false && rightClickType == 0 && resourceType == 3 && !$store.state.textureBallShow) ||
                (recycle == false && dataList.length != 0 && resourceType == 3 && !$store.state.textureBallShow)
              " index="7" class="menuItem">
                <span slot="title">
                  <img src="../../assets/Resource/icon_save_ball .png" alt class="menuIcon" />
                  创建标准材质
                </span>
              </el-menu-item>

            </div>
             -->
            <div v-if="($store.state.userPermissionData.SCENE_FOLDER_RENAME && resourceType == 1 && FolderLocal == 0 && $store.state.chosenWarehouse.type == 0) ||
              ($store.state.userPermissionData.MODEL_FOLDER_RENAME && resourceType == 2 && FolderLocal == 0 && $store.state.chosenWarehouse.type == 0) ||
              ($store.state.userPermissionData.PICTUREMATERIAL_FOLDER_RENAME && resourceType == 3 && FolderLocal == 0 && $store.state.chosenWarehouse.type == 0) ||
              ($store.state.userPermissionData.MEDIA_FOLDER_RENAME && resourceType == 8 && FolderLocal == 0 && $store.state.chosenWarehouse.type == 0) ||
              (this.resourceType == 5)









              ">



              <el-menu-item index="3" v-show="dataList.length != 0 && recycle == false" class="menuItem">
                <img src="../../assets/Resource/newName.png" alt class="menuIcon" />
                <span slot="title">重命名</span>
              </el-menu-item>

            </div>
            <div v-if="($store.state.userPermissionData.SCENE_FOLDER_DELETE && resourceType == 1 && FolderLocal == 0 && $store.state.chosenWarehouse.type == 0) ||
              ($store.state.userPermissionData.MODEL_FOLDER_DELETE && resourceType == 2 && FolderLocal == 0 && $store.state.chosenWarehouse.type == 0) ||
              ($store.state.userPermissionData.PICTUREMATERIAL_FOLDER_DELETE && resourceType == 3 && FolderLocal == 0 && $store.state.chosenWarehouse.type == 0) ||
              ($store.state.userPermissionData.MEDIA_FOLDER_DELETE && resourceType == 8 && FolderLocal == 0 && $store.state.chosenWarehouse.type == 0) ||
              (this.resourceType == 5)



              ">

              <el-menu-item index="4" class="menuItem" v-show="dataList.length != 0 && recycle == false">
                <img src="../../assets/Resource/icon-67.png" alt class="menuIcon" />
                <span slot="title">删除</span>
              </el-menu-item>
            </div>

            <div
              v-if="(resourceType == 6 && $store.state.userPermissionData.RECYCLED && $store.state.chosenWarehouse.type == 0)">
              <el-menu-item v-if="recycle" index="5" class="menuItem">

                <img src="../../assets/Resource/renew.png" alt class="menuIcon" />

                <span slot="title">恢复</span>
              </el-menu-item>
            </div>





            <!-- <el-menu-item  index="8" class="menuItem">
            <img src="../../assets/Resource/move.png" alt class="menuIcon" />

              <span slot="title">移动到根目录</span>
            </el-menu-item>-->

            <!-- <el-menu-item  index="9" class="menuItem">
            <img src="../../assets/Resource/move.png" alt class="menuIcon" />

              <span slot="title">选择移动节点</span>
            </el-menu-item>
            <el-menu-item  index="10" class="menuItem">
            <img src="../../assets/Resource/move.png" alt class="menuIcon" />

              <span slot="title">移动到此节点上方</span>
            </el-menu-item>
            <el-menu-item  index="11" class="menuItem">
            <img src="../../assets/Resource/move.png" alt class="menuIcon" />

              <span slot="title">移动到此节点下方</span>
            </el-menu-item>-->

            <div
              v-if="($store.state.chosenWarehouse.type == 0 && resourceType != 1 && resourceType != 6 && $store.state.UserRank == 3)">

              <el-menu-item index="13" class="menuItem">
                <img src="../../assets/Resource/uploading.png" alt class="menuIcon" />
                <span slot="title">上传共享库</span>
              </el-menu-item>
            </div>

            <div
              v-if="($store.state.chosenWarehouse.type == 1 && Node.id != 2) || ($store.state.chosenWarehouse.type == 2 && Node.id != 2)">
              <el-menu-item index="14" class="menuItem">
                <img src="../../assets/sharedFile/privateIcon.png" alt class="menuIcon" />

                <span slot="title">同步本地库</span>
              </el-menu-item>
            </div>

            <div
              v-if="($store.state.chosenWarehouse.type == 1 && Node.id != 2) || ($store.state.chosenWarehouse.type == 2 && Node.id != 2)">
              <el-menu-item index="15" class="menuItem">
                <img src="../../assets/sharedFile/remove.png" alt class="menuIcon" />

                <span slot="title">从共享库移除</span>
              </el-menu-item>
            </div>

            <div
              v-if="(resourceType == 6 && $store.state.userPermissionData.RECYCLED && $store.state.chosenWarehouse.type == 0)">
              <el-menu-item v-if="recycle" index="17" class="menuItem">

                <img src="../../assets/Resource/emptyRecycleBin.png" alt class="menuIcon" />


                <span slot="title">清空回收站</span>
              </el-menu-item>
            </div>


          </el-menu>
        </div>
      </div>
      <!-- <div v-if="recycle==false">
      <div
        class="recycle"
        v-for=" item in queryFile"
        :key="item.id"
        @contextmenu.prevent="rightClick(item,item)"
      >
    
        <i class="el-icon-document"></i>
        <div class="fileName">{{ item.name }}</div>
      </div>
      </div>-->
      <!-- js编辑 -->
      <div class="codeEditor" v-show="codeEditor">
        <div class="close" @click="close">X</div>
        <CodeEditor ref="CodeEditor" @close="close" />
      </div>
    </div>
    <div class="masking" v-show="codeEditor" @contextmenu.prevent="maskingHint()" @click="maskingHint()"></div>

    <div class="masking" v-show="deleteShow"></div>
    <div class="delete" v-show="deleteShow">
      <p class="close" @click="deleteCancel">
        <img src="../../assets/Project/close.png" alt />
      </p>
      <div class="deletNameDiv">
        <p class="deleteName">此操作会将该文件移到回收站, 是否继续?</p>
      </div>
      <div class="enter">
        <div class="set verify deleteOK" @click="deleteOk">确认</div>
        <div class="set cancel deleteCancel" @click="deleteCancel">取消</div>
      </div>
    </div>




  </div>
</template>
<script>
import { IScene } from "../../threejs/interface/IScene";
import { INetwork } from "../../threejs/interface/INetwork";
import { ISetting } from "../../threejs/interface/ISetting";
import CodeEditor from "../property/CodeEditor";

// 保存回调

export default {
  name: "Tire",
  data() {
    return {
      hide: true,
      text: "收起",
      id: "",
      dataList: [],
      threeData: [],
      defaultProps: {
        children: "subCatalog",
        label: "name",
        imageUrl: "imageUrl",
        local: "local"
      },
      // 这里存放要默认展开的节点 id
      defaultShowNodes: [],
      threeDefaultProps: {
        children: "children",
        label: "name",
        local: "local",
        imageUrl: "imageUrl"
      },

      //右击显示与否
      menu: false,
      //存储node
      Node: "",
      //存储当前数据
      nodedata: {},
      //存储修改过后的名字
      newName: "",
      //拖动节点
      dragNode: {},
      //目标节点
      targetNode: {},
      //根据id获取到的文件目录
      FileDirectoryList: [],
      //右击文件的类型
      rightClickType: 1,

      //查询的文件
      queryFile: [],

      //新文件的id
      fileId: "",

      //文件夹id
      rootFolderId: "",

      dialogFormVisible: false,

      //路径列表
      temppathList: [],

      //js编辑器
      codeEditor: false,
      // js文件id
      jsFileId: "",

      //js文件名字
      jsName: "",
      //当前选中的节点
      currentNodekey: "",

      //左击选中的文件夹
      leftClickNode: "",
      //删除的对话框
      deleteShow: false,
      //是否是创建
      isEstablish: false,
      //是否删除成功
      successfullyDelete: false,
      // 场景名
      sceneName: "",
      //被移动的节点
      mobileNode: {},
      isLoad: false,
      //右击文件夹的锁定状态
      FolderLocal: "",


    };
  },
  components: {
    CodeEditor
  },
  props: [
    //结构树
    // "dataList",
    //是否是给3D对接
    "isThree",
    //资源类型  1场景，2模型，3贴图，4材质，5脚本，6 回收站,8媒体
    "resourceType",
    //设置新名字
    //是否是回收站
    "recycle",
    //搜索的名字
    "searchName",
    //创建类型 /*创建场景，等 */
    "establishType"
  ],

  created() {
    window.addEventListener("beforeunload", e => this.beforeunloadFn(e));
    this.getUserProjectAuthority()
  },
  mounted() {
    window.addEventListener("click", e => {
      this.menuConceal();
    });

    //定时器判断3d挂载完成
    let pMountedTimer = window.setInterval(() => {
      if (window.parentMounted) {
        // IScene.f_saveSceneCallback = this.SaveScene.bind(this)
        // ISetting.exit()
        IScene.f_saveSceneCallback = this.updataSaveScene.bind(this);

        this.fileId = localStorage.getItem("presentSceneId");

        window.clearInterval(pMountedTimer);
        //获取展开
        var defaultShowNodes = localStorage.getItem("unfoldIdList");
        if (
          defaultShowNodes !== null &&
          defaultShowNodes != undefined &&
          defaultShowNodes != ""
        ) {
          this.defaultShowNodes = JSON.parse(defaultShowNodes);
        }
        var fileDirectoryId = localStorage.getItem("FileDirectoryId");


        if (fileDirectoryId !== "null" && fileDirectoryId != "undefined" && fileDirectoryId != undefined) {


          this.$emit("setFileDirectoryId", fileDirectoryId);
        }

        if (this.recycle == true) {
          this.delFolderList();
        } else if (this.recycle == false) {
          // this.findByName();
          this.getDataTree().then(() => {
            var presentSceneId = localStorage.getItem("openSceneId");
            if (
              presentSceneId != undefined &&
              presentSceneId != null &&
              presentSceneId != ""
            ) {
            } else {
              var leftClickNode = localStorage.getItem("leftClickNode");


              // leftClickNode= JSON.parse(leftClickNode)
              //判断是第一次打开
              if (this.resourceType == 1) {
                if (leftClickNode) {
                } else {
                  if (this.dataList.length > 0) {
                    this.handleNodeClick(this.dataList[0]);
                  }
                }
              }
            }
          });
        }
      }
    }, 500);

    // document.onclick = () => {
    //   this.menu = false;
    //   if(this.resourceType==6){
    //   this.$parent.setDragShow(true);

    //   }
    // };
  },

  destroyed() {
    window.removeEventListener("beforeunload", e => this.beforeunloadFn(e));
  },

  methods: {
    //清空回收站
    async emptyRecycleBin(id) {
      let data = {
        projectId: id
      }

      const res = await this.$https.emptyRecycleBin(data)

      if (res.success == true) {
        // this.isLoad = false;
        this.getDataTree().then(() => {

          this.handleNodeClick(this.dataList[0])
        })
      }

    },


    //隐藏菜单
    mouseLeave() {
      this.menu = false;






    },
    //确认删除
    deleteOk() {
      this.delFile().then(() => {
        if (this.successfullyDelete) {
          this.$emit("setFileDirectoryId", "");

          // this.setPath(this.Node.parentId, this.dataList)
          //通过条件获取节点

          var id = this.Node.parentId;


          var parentNode = this.inquireNode(this.dataList, id);



          if (parentNode) {
            //调用点击父节点
            this.handleNodeClick(parentNode);
          } else {
            this.$emit("getPath", []);
          }
          this.successfullyDelete = false;
        }
      });

      this.deleteShow = false;
      this.$parent.setDragShow(true);
    },
    //取消
    deleteCancel() {
      this.deleteShow = false;
      this.$parent.setDragShow(true);

      this.$message({
        // type: "info",
        message: "已取消"
      });
    },

    //打开js
    openJs() {
      this.$parent.setDragShow(false);

      this.codeEditor = true;
    },
    //关闭js
    close() {
      this.$parent.setDragShow(true);

      this.codeEditor = false;
      var CodeEditor = this.$refs.CodeEditor;
      CodeEditor.setCode("");
      CodeEditor.setJsFileId("");
    },

    //监听页面刷新或关闭
    beforeunloadFn(e) { },

    //获取树状结构
    async getDataTree() {
      if (this.recycle == true) {
        await this.delFolderList();
      } else {
        await this.getFolderList(this.resourceType).then(() => {
          //判断是场景
          if (this.resourceType == 1) {
            //  吧更新过数据的this给3d
            IScene.f_saveSceneCallback = this.updataSaveScene.bind(this);
          }
        });
      }
    },
    //收起还是展开
    tabChange() {
      if (this.hide) {
        this.hide = false;
        this.text = "展开";
      } else {
        this.hide = true;
        this.text = "收起";
      }
    },
    //菜单隐藏
    menuConceal() {
      var textureBallMasking = this.$store.state.textureBallMasking;

      if (
        this.menu == true &&
        textureBallMasking == false &&
        this.deleteShow == false
      ) {
        this.$parent.setDragShow(true);
      }
      this.menu = false;
    },

    // 节点被点击
    handleNodeClick(data) {
      this.$store.commit("setSelectNode", {});

      this.$parent.setChecked();
      this.$parent.ControlHidden();
      this.$store.commit("setResourceNode", data);

      if (this.resourceType == 1) {
        this.$store.commit("setsceneNode", data);
      }

      // localStorage.setItem("ResourceType", this.resourceType);




      this.menuConceal();
      this.menu = false;

      //存储左击点击的节点
      this.leftClickNode = data;
      var leftClickNode = JSON.stringify(this.leftClickNode);

      localStorage.setItem("leftClickNode", leftClickNode);

      this.$emit("setFileDirectoryId", data.id);

      this.$emit("setResourceType", this.resourceType);
      this.Node = data;

      var arr = this.dataList;
      var id = data.id;

      // }
      this.setPath(id, arr);

      //判断是模型存储url不是模型移除url
      if (this.resourceType == 2) {
        localStorage.setItem("modelId", data.id);
        // localStorage.setItem("modelId",data.id);

        this.getPathById(id, arr[0], temppath => {

          var temppathList = temppath.split(">");
          temppathList = temppathList.slice(0, -1);

          // localStorage.setItem("modelUrl", JSON.stringify(temppathList));

          localStorage.setItem("modelId", data.id);
        });
      } else {
        // localStorage.removeItem("modelUrl");
      }
      if (this.resourceType == 3) {
        this.getPathById(id, arr[0], temppath => {

          var temppathList = temppath.split(">");
          temppathList = temppathList.slice(0, -1);
          // localStorage.setItem("textureUrl", JSON.stringify(temppathList));

          localStorage.setItem("textureId", this.Node.id);
        });
      } else {
        // localStorage.removeItem("textureUrl");
      }
    },
    //路径设置
    setPath(id, arr) {
      var queryTree = {};

      for (let index = 0; index < arr.length; index++) {
        const element = arr[index].id;
        //

        if (id == element) {
          queryTree = arr[index];
        } else if (arr[index].subCatalog) {
          var tree = this.inquireNode(arr[index].subCatalog, id);
          if (tree) {
            queryTree = arr[index];
          }

        }
      }



      this.getPathById(id, queryTree, temppath => {

        var temppathList = temppath.split(">");
        temppathList = temppathList.slice(0, -1);



        this.$emit("getPath", temppathList);

        if (this.resourceType == 2) {
          localStorage.setItem("modelUrl", JSON.stringify(temppathList));
        } else {
          localStorage.removeItem("modelUrl");
        }

        if (this.resourceType == 3) {
          localStorage.setItem("textureUrl", JSON.stringify(temppathList));
        } else {
          localStorage.removeItem("textureUrl");
        }
      });
    },

    //通过id查找节点

    clickInquireNode(tree, id) {


      if (tree.id == id) return tree;
      if (tree.subCatalog) {
        const res = this.clickInquireNode(tree.subCatalog, id);
        if (res) return res;
      }

      return null;
    },

    //锁切换
    cutLock(node) {

      if (this.$store.state.UserRank == 3) {


        if (node.local == 0) {
          this.projectLocal(node, "1").then(() => {
            this.handleNodeClick(this.Node);
          });
        } else if (node.local == 1) {
          this.projectLocal(node, "0").then(() => {
            this.handleNodeClick(this.Node);
          });
        }
      } else {
        this.$message({
          message: "您没有权限对其锁定或解锁"
          // type: "warning"
        });
      }
    },

    //节点拖拽时触发
    handleDragStart(node, ev) {
      this.dragNode = node;



    },
    //拖拽成功时
    handleDrop(before, after, dropType, ev) {


      if (dropType == "before") {
        this.myMobileNode(before.data, after.data, 0);
      } else if (dropType == "after") {
        this.myMobileNode(before.data, after.data, 1);
      } else if (dropType == "inner") {
        this.moveFolderFile(before.data.id, before.data.type, after.data.id);
      }
      this.getDataTree();


    },
    /**拖拽时判定目标节点能否被放置。type 参数有三种情况：'prev'、'inner' 和 'next'，分别表示放置在目标节点前、插入至目标节点和放置在目标节点后 */
    allowDrop(draggingNode, dropNode, type) {
      if (dropNode.data.label === "二级 3-1") {
        return type !== "inner";
      } else {
        return true;
      }
    },
    //判断节点能否被拖拽
    allowDrag(draggingNode) {
      return draggingNode.data.name.indexOf("三级 3-2-2") === -1;
    },
    //添加
    append(data) {
      let id = "id" + Date.now();

      const newChild = { id: id, label: "testtest", children: [] };

      if (!data.children) {
        this.$set(data, "children", []);
      }
      data.children.push(newChild);
    },

    //删除
    remove(node, data) {


      const parent = node.parent;
      const children = parent.data.children || parent.data;
      const index = children.findIndex(d => d.id === data.id);
      children.splice(index, 1);
    },

    //右击
    rightClick(object, Node) {
      this.getFolderLocal(Node.id)
      this.getDataTree()

      this.getUserProjectAuthority()

      this.$parent.ControlHidden();
      if (object == {}) {
        //获取到菜单
        let menu = this.$refs.menu;
        let x = event.clientX;
        let y = event.clientY;
        let h = menu.style.height;

        if (y > 740) {
          if (this.recycle) {
            menu.$el.style.top = y - 50 + "px";
          } else {
            menu.$el.style.top = y - 150 + "px";
          }
        } else {
          menu.$el.style.top = y + "px";
        }
        // if (x > 1280) {
        //   menu.$el.style.left = x - 100 + "px";
        // } else {
        menu.$el.style.left = x + "px";
        // }
        this.nodedata = {
          id: ""
        }; //存当前数据
        this.Node = {
          id: ""
        }; //存当前节点信息
        this.menu = true; //让菜单显示
        this.$parent.setDragShow(false);
        this.id = "";
      } else {

        console.log(Node.local, "Node.local");
        // if (Node.local == 1) {
        //   this.$message({
        //     message: "已锁定请联系管理员解锁"
        //     // type: "warning"
        //   });
        // } else {
        this.rightClickType = Node.type;
        //获取到菜单
        let menu = this.$refs.menu;

        let x = event.clientX;
        let y = event.clientY;


        if (y > 740) {
          if (this.recycle) {
            menu.$el.style.top = y - 50 + "px";
          } else {
            menu.$el.style.top = y - 150 + "px";
          }
        } else {
          menu.$el.style.top = y + "px";
        }

        // if (x > 1280) {
        //   menu.$el.style.left = x - 100 + "px";
        // } else {
        menu.$el.style.left = x + "px";
        // }
        this.nodedata = object; //存当前数据
        this.Node = Node; //存当前节点信息
        this.menu = true; //让菜单显示
        this.$parent.setDragShow(false);
        this.id = Node.id;

      }
      // }


    },

    //查询文件夹状态
    async getFolderLocal(id) {
      let data = {
        folderId: id
      }

      const res = await this.$https.getFolderLocal(data)
      if (res.success == true) {
        this.FolderLocal = res.data
      }

    },

    //菜单
    async handleSelect(key) {
      await this.getUserProjectAuthority()

      let tree = this.$refs.tree;
      let data = this.Node;


      if (key == 1) {

        if ((this.$store.state.userPermissionData.SCENE_FOLDER_CREATION && this.resourceType == 1) ||

          (this.$store.state.userPermissionData.MODEL_FOLDER_CREATION && this.resourceType == 2) ||
          (this.$store.state.userPermissionData.PICTUREMATERIAL_FOLDER_CREATION && this.resourceType == 3) ||
          (this.$store.state.userPermissionData.MEDIA_FOLDER_CREATION && this.resourceType == 8) ||
          this.resourceType == 5

        ) {





          //新建文件夹
          ISetting.isInput(true);
          var containSpecial = RegExp(
            /[(\ )(\~)(\!)(\@)(\#)(\$)(\%)(\^)(\&)(\*)(\()(\))(\+)(\=)(\[)(\])(\{)(\})(\|)(\\)(\;)(\:)(\')(\")(\——)(\,)(\.)(\/)(\<)(\>)(\?)(\)]+/
          );

          this.$prompt(``, `${this.establishType}提示`, {
            cancelButtonText: "取消",
            confirmButtonText: "确定",
            inputPlaceholder: `请输入${this.establishType}的文件夹名称`,
            // center: false,
            inputErrorMessage: "输入不能为空，且不能有特殊字符 最大20字符",
            customClass: "reset-message-box",
            closeOnClickModal: false,
            inputValidator: value => {
              // 点击按钮时，对文本框里面的值进行验证
              if (!value) {
                return "输入不能为空";
              } else if (containSpecial.test(value)) {
                return "输入不能为特殊字符";
              } else if (value.length > 20) {
                return "输入文字不能超过20个字符";
              }
            }
          })
            .then(({ value }) => {
              ISetting.isInput(false);

              this.addFolder(value).then(() => {
                if (this.Node) {
                  this.$emit("setFileDirectoryId", this.Node.id);

                  // var defaultShowNodes = localStorage.getItem("unfoldIdList");
                  this.defaultShowNodes.push(this.Node.id);
                  var defaultShowNode = JSON.stringify(this.defaultShowNodes);

                  localStorage.setItem("unfoldIdList", defaultShowNode);
                }
                //判断是场景
                if (this.resourceType == 1) {
                  //  吧更新过数据的this给3d
                  IScene.f_saveSceneCallback = this.updataSaveScene.bind(this);
                }
              });
            })
            .catch(() => {
              ISetting.isInput(false);

              this.$message({
                // type: "info",
                message: "取消输入"
              });
            });

        } else {
          this.$message("您没有权限")

        }


      } else if (key == 2) {


        //创建文件
        var leftClickNode = localStorage.getItem("leftClickNode");
        leftClickNode = JSON.parse(leftClickNode);



        // if (leftClickNode) {
        //   this.handleNodeClick(leftClickNode);
        // } else {
        this.handleNodeClick(this.Node);
        // }

        this.newFile(1);
      } else if (key == 3) {
        //重命名

        if ((this.$store.state.userPermissionData.SCENE_FOLDER_RENAME && this.resourceType == 1) ||
          (this.$store.state.userPermissionData.MODEL_FOLDER_RENAME && this.resourceType == 2) ||
          (this.$store.state.userPermissionData.PICTUREMATERIAL_FOLDER_RENAME && this.resourceType == 3) ||
          (this.$store.state.userPermissionData.MEDIA_FOLDER_RENAME && this.resourceType == 8) ||
          this.resourceType == 5
        ) {

          ISetting.isInput(true);
          var containSpecial = RegExp(
            /[(\ )(\~)(\!)(\@)(\#)(\$)(\%)(\^)(\&)(\*)(\()(\))(\+)(\=)(\[)(\])(\{)(\})(\|)(\\)(\;)(\:)(\')(\")(\——)(\,)(\.)(\/)(\<)(\>)(\?)(\)]+/
          );

          this.$prompt(``, `请输入新的名字`, {
            confirmButtonText: "确定",
            showClose: true,
            // center: true,
            closeOnPressEscape: false,
            closeOnClickModal: false,

            inputPlaceholder: `在此输入名字`,
            inputValue: this.Node.name,
            inputErrorMessage: "输入不能为空，且不能有特殊字符 最大20字符",
            inputValidator: value => {
              // 点击按钮时，对文本框里面的值进行验证
              if (!value) {
                return "输入不能为空";
              } else if (containSpecial.test(value)) {
                return "输入不能为特殊字符";
              } else if (value.length > 20) {
                return "输入文字不能超过20个字符";
              }
            }
          })
            .then(({ value }) => {
              ISetting.isInput(false);
              this.newName = value;
              this.updateFileName().then(() => {
                this.getDataTree();
                this.handleNodeClick(this.leftClickNode);
              });
            })
            .catch(() => {
              ISetting.isInput(false);

              this.$message({
                // type: "info",
                message: "已取消"
              });
            });
        } else {
          this.$message("您没有权限")
        }
      } else if (key == 4) {
        if ((this.$store.state.userPermissionData.SCENE_FOLDER_DELETE && this.resourceType == 1) ||
          (this.$store.state.userPermissionData.MODEL_FOLDER_DELETE && this.resourceType == 2) ||
          (this.$store.state.userPermissionData.PICTUREMATERIAL_FOLDER_DELETE && this.resourceType == 3) ||
          (this.$store.state.userPermissionData.MEDIA_FOLDER_DELETE && this.resourceType == 8) ||
          (this.resourceType == 5)

        ) {


          this.$parent.setDragShow(false);

          this.deleteShow = true;
        } else {
          this.$message("您没有权限")

        }
      } else if (key == 5) {
        //恢复被删除的文件

        if (
          (this.resourceType == 6 && this.$store.state.userPermissionData.RECYCLED)

        ) {


          if (this.isThree == 0) {
            this.recoverFolder();
          } else {

          }
        } else {
          this.$message("您没有权限")

        }
      } else if (key == 6) {
        // 创建基础
        this.$store.commit("setCreatingMaterialTypes", 0);

        this.newFile(2);
      } else if (key == 7) {
        // 创建标准
        this.$store.commit("setCreatingMaterialTypes", 1);

        this.newFile(2);
      } else if (key == 8) {
        this.moveFolderFile(this.Node.id, this.Node.type, "");
        this.getDataTree();
      } else if (key == 9) {
        this.mobileNode = this.Node;
      } else if (key == 10) {
        this.myMobileNode(this.mobileNode, this.Node, 0);
      } else if (key == 11) {
        this.myMobileNode(this.mobileNode, this.Node, 1);
      } else if (key == 12) {


        // 创建冯氏材质
        this.$store.commit("setCreatingMaterialTypes", 2);
        this.newFile(2);

      } else if (key == 13) {


        // 上传到共享库
        this.$store.commit("changeHistoryShow", true)
        this.$store.commit("changeHistoryData", this.Node)
        this.$store.commit("changeUploadingParentType", this.Node.property)


      } else if (key == 14) {


        // 同步到本地库
        this.$store.commit("changeFolderType", this.resourceType)
        this.$store.commit("changeDownloadData", this.Node)


        this.$store.commit("changeDownloadShow", true)
        this.$store.commit("changeSynchronization", '1')


      } else if (key == 15) {
        // 从共享库移除


        this.$confirm("此操作会将其移出共享库, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {

            this.deleteUpload().then(() => {

              this.$emit("setFileDirectoryId", "");

              // this.setPath(this.Node.parentId, this.dataList)
              //通过条件获取节点

              var id = this.Node.parentId;


              var parentNode = this.inquireNode(this.dataList, id);



              if (parentNode) {
                //调用点击父节点
                this.handleNodeClick(parentNode);
              } else {
                this.$emit("getPath", []);
              }


            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消"
            });
          });






      } else if (key == 16) {
        //创建粒子

        this.$store.commit("setCreatingMaterialTypes", 3);
        this.newFile(2);
      } else if (key == 17) {
        //清空回收站

        this.$confirm("确认后将清空所有回收站内容", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {

            let projectId = localStorage.getItem("projectId")
            this.emptyRecycleBin(projectId)
            //调用点击父节点
            // this.handleNodeClick(parentNode)
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消"
            });
          });
      } else if (key == 18) {
        // 卡通捕捉材质
        this.$store.commit("setCreatingMaterialTypes", 3);
        this.newFile(2);

      }else if (key == 19) {
        // 卡通捕捉材质
        this.$store.commit("setCreatingMaterialTypes", 4);
        this.newFile(2);

      }
    },

    //从共享库移除

    async deleteUpload() {
      let data = {
        libraryId: this.$store.state.chosenWarehouse.id,
        fId: this.Node.id,
        type: 0
      }
      const res = await this.$https.deleteUpload(data)
      if (res.success == true) {
        this.$message({
          message: "操作成功"
          // type: "success"
        });
        this.getDataTree()
      } else {
        this.$message({
          message: res.errMessage
          // type: "success"
        });
      }
    },

    //移动到节点
    myMobileNode(oldNode, targetNode, type) {
      this.isLoad = true;


      if (oldNode.parentId == targetNode.parentId) {
        this.moveFolderLocation(oldNode.id, targetNode.id, type);
      } else {
        // this.moveFolderFile(oldNode.id,oldNode.type,targetNode.parentId).then(()=>{

        this.moveFolderLocation(oldNode.id, targetNode.id, type);
        // })
      }
    },
    //同级别移动
    async moveFolderLocation(folder, targetNode, location) {
      let data = {
        //被移动的
        folderId: folder,
        //目标的
        target: targetNode,
        location: location
      };
      const res = await this.$https.moveFolderLocation(data);

      if (res.success == true) {
        this.isLoad = false;
        this.getDataTree();
      } else {
        this.isLoad = false;
        this.getDataTree();
        this.$message({
          message: res.errMessage
          // type: "warning"
        });
      }
    },

    //编辑
    handleInput(_node, _data) {
      // 修改节点

      // 退出编辑状态
      if (_data.isEdit) {
        this.$set(_data, "isEdit", false);
      }
      this.updateFileName();

    },
    //双击重命名
    rechristen(_node, _data) {
      // // 修改节点
    },

    //新建文件夹接口
    async addFolder(_name, _type) {


      var parent;
      if (this.dataList.length != 0) {

        parent = this.Node.id ? this.Node.id : "";
      } else {
        parent = "";
      }
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      const res = await this.$https.addFolder({
        projectId: localStorage.getItem("projectId"),
        name: _name,
        type: _type ? _type : this.resourceType,
        // groupId: "c943db89-a6c1-4079-8fd4-06346dbab6b5",
        // parent:this.id,

        parent: parent
      });
      if (res.success == true) {

        this.rootFolderId = res.data;
        this.getDataTree();
        this.$message({
          message: "创建成功"
          // type: "success"
        });

        loading.close();
        return res.data;
      } else {
        loading.close();
        var token = localStorage.getItem("token");
        if (token) {
          this.$message({
            message: res.errMessage
            // type: "warning"
          });
        }
        return false;
      }
    },
    myAddRotFolder() {
      this.getUserProjectAuthority().then(() => {

        if ((this.$store.state.userPermissionData.SCENE_FOLDER_CREATION && this.resourceType == 1) ||

          (this.$store.state.userPermissionData.MODEL_FOLDER_CREATION && this.resourceType == 2) ||
          (this.$store.state.userPermissionData.PICTUREMATERIAL_FOLDER_CREATION && this.resourceType == 3) ||
          (this.$store.state.userPermissionData.MEDIA_FOLDER_CREATION && this.resourceType == 8) ||
          this.resourceType == 5

        ) {




          //新建文件夹
          ISetting.isInput(true);
          var containSpecial = RegExp(
            /[(\ )(\~)(\!)(\@)(\#)(\$)(\%)(\^)(\&)(\*)(\()(\))(\+)(\=)(\[)(\])(\{)(\})(\|)(\\)(\;)(\:)(\')(\")(\——)(\,)(\.)(\/)(\<)(\>)(\?)(\)]+/
          );

          this.$prompt(``, `${this.establishType}提示`, {
            cancelButtonText: "取消",
            confirmButtonText: "确定",
            inputPlaceholder: `请输入${this.establishType}的文件夹名称`,
            // center: false,
            inputErrorMessage: "输入不能为空，且不能有特殊字符 最大20字符",
            customClass: "reset-message-box",
            closeOnClickModal: false,
            inputValidator: value => {
              // 点击按钮时，对文本框里面的值进行验证
              if (!value) {
                return "输入不能为空";
              } else if (containSpecial.test(value)) {
                return "输入不能为特殊字符";
              } else if (value.length > 20) {
                return "输入文字不能超过20个字符";
              }
            }
          })
            .then(({ value }) => {
              ISetting.isInput(false);

              this.addRotFolder(value).then(() => {
                if (this.Node) {
                  this.$emit("setFileDirectoryId", this.Node.id);

                  // var defaultShowNodes = localStorage.getItem("unfoldIdList");
                  this.defaultShowNodes.push(this.Node.id);
                  var defaultShowNode = JSON.stringify(this.defaultShowNodes);

                  localStorage.setItem("unfoldIdList", defaultShowNode);
                }
                //判断是场景
                if (this.resourceType == 1) {
                  //  吧更新过数据的this给3d
                  IScene.f_saveSceneCallback = this.updataSaveScene.bind(this);
                }
              });
            })
            .catch(() => {
              ISetting.isInput(false);

              this.$message({
                // type: "info",
                message: "取消输入"
              });
            });
        } else {
          this.$message("您没有权限")

        }


      })
    },

    async addRotFolder(_name, _type) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      const res = await this.$https.addFolder({
        projectId: localStorage.getItem("projectId"),

        name: _name,
        type: _type ? _type : this.resourceType,
        // groupId: "c943db89-a6c1-4079-8fd4-06346dbab6b5",
        // parent:this.id,

        parent: ""
      });
      if (res.success == true) {

        this.rootFolderId = res.data;
        this.getDataTree();
        this.$message({
          message: "创建成功"
          // type: "success"
        });

        loading.close();
        return res.data;
      } else {
        loading.close();
        var token = localStorage.getItem("token");
        if (token) {
          this.$message({
            message: res.errMessage
            // type: "warning"
          });
        }
        return false;
      }
    },

    //获取文件列表
    async getFolderList(_type) {
      const res = await this.$https.getFolderList({
        // projectId: localStorage.getItem("projectId"),
        projectId: this.$store.state.chosenWarehouse.id ? this.$store.state.chosenWarehouse.id : localStorage.getItem("projectId"),
        type: _type,
        show: this.$store.state.chosenWarehouse.type,
        // groupId: "c943db89-a6c1-4079-8fd4-06346dbab6b5"
      });

      // const loading = this.$loading({
      //   lock: true,
      //   text: "Loading",
      //   spinner: "el-icon-loading",
      //   background: "rgba(0, 0, 0, 0.7)"
      // });

      if (res.errCode == null) {

        // loading.close();
        if (_type == "1") {


          //场景数据
          this.dataList = res.data;
        } else if (_type == "2") {

          //模型数据
          this.dataList = res.data;
        } else if (_type == "3") {

          //贴图
          this.dataList = res.data;
        } else if (_type == "4") {


          //材质
          this.dataList = res.data;
        } else if (_type == "5") {


          //脚本
          this.dataList = res.data;
        } else if (_type == "8") {
          //媒体
          this.dataList = res.data;
        }
      } else {
        // loading.close();
        var token = localStorage.getItem("token");
        if (token) {
          this.$message({
            message: res.errMessage
            // type: "warning"
          });
        }
      }
    },

    //查询列表
    async findByName() {
      if (this.searchName == "") {
        this.getDataTree();
      } else {
        const loading = this.$loading({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)"
        });

        const res = await this.$https.findByName({
          projectId: localStorage.getItem("projectId"),
          type: this.resourceType,
          name: this.searchName
        });
        // loading.close();
        if (res.errCode == null) {
          loading.close();

          this.dataList = res.data;
        } else {
          loading.close();
          var token = localStorage.getItem("token");
          if (token) {
            this.$message({
              message: res.errMessage
              // type: "warning"
            });
          }
        }
      }
    },

    //查询文件夹列表

    //查询列表
    async folderByName(isAccurate) {
      if (this.searchName == "") {
        this.getDataTree();
      } else {
        const loading = this.$loading({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)"
        });

        const res = await this.$https.findFolderByName({
          projectId: localStorage.getItem("projectId"),

          type: this.resourceType,
          name: this.searchName,

          isDelete: this.resourceType == 6 ? 1 : 0,
          isAccurate: isAccurate
        });
        // loading.close();
        if (res.errCode == null) {
          loading.close();

          this.dataList = res.data;
        } else {
          loading.close();
          var token = localStorage.getItem("token");
          if (token) {
            this.$message({
              message: res.errMessage
              // type: "warning"
            });
          }
        }
      }
    },

    //回收站
    async delFolderList(_type) {
      // const loading = this.$loading({
      //   lock: true,
      //   text: "Loading",
      //   spinner: "el-icon-loading",
      //   background: "rgba(0, 0, 0, 0.7)"
      // });
      const res = await this.$https.delFolderList({
        projectId: localStorage.getItem("projectId")
      });
      // loading.close();

      if (res.errCode == null) {

        this.dataList = res.data;
      } else {
        // loading.close();
        if (res.errCode != 400) {

          var token = localStorage.getItem("token");
          if (token) {
            this.$message({
              message: res.errMessage
              // type: "warning"
            });
          }
        }

      }
    },

    //文件删除
    async delFile() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });

      const res = await this.$https.delFile({
        projectId: localStorage.getItem("projectId"),
        // projectId:
        type: this.Node.type,
        id: this.id
      });

      if (res.errCode == null) {
        loading.close();

        this.$message({
          message: "删除成功"
          // type: "success"
        });

        this.getDataTree();
        this.successfullyDelete = true;
      } else {
        loading.close();
        var token = localStorage.getItem("token");
        if (token) {
          this.$message({
            message: res.errMessage
            // type: "warning"
          });
        }
      }
    },
    //文件夹改名字
    async updateFileName() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });

      const res = await this.$https.updateFileName({
        // projectId: localStorage.getItem("projectId"),

        id: this.id,
        name: this.newName,
        type: this.Node.type
      });

      if (res.errCode == null) {
        loading.close();
        this.getDataTree();

      } else {
        loading.close();
        var token = localStorage.getItem("token");
        if (token) {
          this.$message({
            message: res.errMessage
            // type: "warning"
          });
        }
      }
    },
    //根据文件夹id获取子目录
    async getSubCatalog(id) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });

      const res = await this.$https.getSubCatalog({


        projectId: this.$store.state.chosenWarehouse.id ? this.$store.state.chosenWarehouse.id : localStorage.getItem("projectId"),
        type: this.$store.state.chosenWarehouse.type,

        id: id,

        isDelete: this.resourceType == 6 ? 1 : 0,


      });

      if (res.errCode == null) {
        loading.close();
        var FileDirectoryList = res.data;

        for (let index = 0; index < FileDirectoryList.length; index++) {
          var url = FileDirectoryList[index].imageUrl;
          var realUrl = INetwork.getUrl(url);


          FileDirectoryList[index].imageUrl = realUrl;
        }
        this.FileDirectoryList = FileDirectoryList;

        //将文件夹列表值传递给父组件
        this.$emit("setFileDirectoryList", this.FileDirectoryList);


      } else {
        loading.close();
        var token = localStorage.getItem("token");
        if (token) {
          this.$message({
            message: res.errMessage
            // type: "warning"
          });
        }
      }
    },
    //拖动文件或文件夹
    async moveFolderFile(_id, _type, _folderId) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      const res = await this.$https.moveFolderFile({
        id: _id,
        type: _type,
        folderId: _folderId
      });
      if (res.success == true) {
        loading.close();

        this.getDataTree();
      } else {
        loading.close();
        var token = localStorage.getItem("token");
        if (token) {
          this.$message({
            message: res.errMessage
            // type: "warning"
          });
        }
      }
    },
    //文件夹恢复
    async recoverFolder() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });

      const res = await this.$https.recoverFolder({
        id: this.Node.id,
        type: this.Node.type
      });
      if (res.success == true) {
        loading.close();

        // this.getDataTree()
        this.delFolderList();
      } else {
        loading.close();
        var token = localStorage.getItem("token");
        if (token) {
          this.$message({
            message: res.errMessage
            // type: "warning"
          });
        }
      }
    },

    //调用父组件上传
    // type1 表示普通上传或添加2 表示材质球上传或添加
    newFile(type) {
      // 判断是 模型材质或者 贴图
      if (
        this.resourceType == 2 ||
        this.resourceType == 3 ||
        this.resourceType == 4
      ) {
        //调用父组件的上传
        this.$parent.newFile(1, this.resourceType, this.Node.id, type);
      } else if (this.resourceType == 5) {
        //脚本
        ISetting.isInput(true);
        var containSpecial = RegExp(
          /[(\ )(\~)(\!)(\@)(\#)(\$)(\%)(\^)(\&)(\*)(\()(\))(\+)(\=)(\[)(\])(\{)(\})(\|)(\\)(\;)(\:)(\')(\")(\——)(\,)(\.)(\/)(\<)(\>)(\?)(\)]+/
        );

        this.$prompt(``, `${this.establishType}提示`, {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          // center: true,
          inputPlaceholder: `请输入${this.establishType}的文件名称`,
          closeOnClickModal: false,
          inputErrorMessage: "输入不能为空，且不能有特殊字符 最大20字符",
          inputValidator: value => {
            // 点击按钮时，对文本框里面的值进行验证
            if (!value) {
              return "输入不能为空";
            } else if (containSpecial.test(value)) {
              return "输入不能为特殊字符";
            } else if (value.length > 20) {
              return "输入文字不能超过20个字符";
            }
          }
        })
          .then(({ value }) => {
            ISetting.isInput(false);

            this.jsName = value;

            // var id=this.GenNonDuplicateID(64)

            this.addJs(value).then(() => {
              // 根据id查询子列表
              this.$emit("setFileDirectoryId", this.Node.id);

              var defaultData;
              INetwork.getDefultJs(data => {
                defaultData = data;
              });
              var data = INetwork.uploadJsTSql(
                this.jsFileId,
                defaultData,
                () => {

                }
              );



              this.updateJs(data);

              this.codeEditor = true;
              this.$parent.setDragShow(false);

              var CodeEditor = this.$refs.CodeEditor;
              CodeEditor.setCode(defaultData);
              CodeEditor.setJsFileId(this.jsFileId);
            });
          })
          .catch(() => {
            ISetting.isInput(false);
            this.$message({
              // type: "info",
              message: "取消输入"
            });
          });
      }
      //判断是场景
      else if (this.resourceType == 1) {
        var presentSceneId = localStorage.getItem("presentSceneId");


        if (
          presentSceneId != "" &&
          presentSceneId != null &&
          presentSceneId != undefined
        ) {
          // IScene.save();
        }
        ISetting.isInput(true);

        var containSpecial = RegExp(
          /[(\ )(\~)(\!)(\@)(\#)(\$)(\%)(\^)(\&)(\*)(\()(\))(\+)(\=)(\[)(\])(\{)(\})(\|)(\\)(\;)(\:)(\')(\")(\——)(\,)(\.)(\/)(\<)(\>)(\?)(\)]+/
        );

        this.$prompt(
          ``,
          `${this.establishType}提示`,

          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            // center: true,
            inputPlaceholder: `请输入${this.establishType}的文件名称`,
            closeOnClickModal: false,
            inputErrorMessage: "输入不能为空，且不能有特殊字符 最大20字符",
            inputValidator: value => {
              // 点击按钮时，对文本框里面的值进行验证
              if (!value) {
                return "输入不能为空";
              } else if (containSpecial.test(value)) {
                return "输入不能为特殊字符";
              } else if (value.length > 20) {
                return "输入文字不能超过20个字符";
              }
            }
          }
        )
          .then(({ value }) => {
            ISetting.isInput(false);

            this.fileName = value;

            localStorage.setItem("presentSceneId", this.fileId);

            // var id=this.GenNonDuplicateID(64)

            this.addFile(value).then(data => {


              // localStorage.setItem("presentSceneId", this.fileId);
              // localStorage.setItem("sceneName", this.sceneName);
              // this.$parent.setCheckedId(this.fileId);

              if (data != false) {
                var json = {};
                var data = INetwork.uploadJsonToSql(
                  this.fileId,
                  "scene",
                  JSON.stringify(json),
                  () => { }
                );
                // console.log(this.fileId,"this.fileId")

                if (this.fileId != "null" && this.fileId != "") {


                  this.updateFile(data).then(() => {
                    // var setFileDirectoryId = this.Node.id
                    //   ? this.Node.id
                    //   : this.rootFolderId;


                    this.$emit("setFileDirectoryId", this.Node.id);

                    this.$emit("setResourceType", this.resourceType);
                  });

                  // 根据id查询子列表
                  // this.$emit("setFileDirectoryId", this.Node.id);

                  //存储父文件夹id
                  localStorage.setItem("FileDirectoryId", this.Node.id);

                  // ISetting.exit()

                  //  IScene.save();

                  // location.reload();

                  if (this.resourceType == 1) {
                    //  吧更新过数据的this给3d
                    IScene.f_saveSceneCallback = this.updataSaveScene.bind(this);
                  }
                }
              }

            });

          })
          .catch(() => {
            ISetting.isInput(false);

            this.$message({
              // type: "info",
              message: "取消输入"
            });
          });
      } else if (this.resourceType == 8) {
        //判断是上传媒体、

        //1是新建
        this.$parent.addMultimedia(1, this.Node.id);
      }
    },

    //创建js
    async addJs(value) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      const res = await this.$https.addFile({
        //文件夹id
        // folderId:this.id,
        folderId: this.Node.id == undefined ? this.rootFolderId : this.Node.id,

        name: value,
        projectId: localStorage.getItem("projectId"),
        type: 5,
        fileUrl: "",
        fileSize: 0,
        imageUrl: "res/ui/scene.png",
        deputyUrl: ""
      });
      if (res.success == true) {
        loading.close();
        this.jsFileId = res.data;



        this.$message({
          message: "创建成功"
          // type: "success"
        });

        this.getDataTree();
        return res.data;
      } else {
        loading.close();
        var token = localStorage.getItem("token");
        if (token) {
          this.$message({
            message: res.errMessage
            // type: "warning"
          });
        }
        return false;
      }
    },
    //更新js

    async updateJs(_data) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });

      //获取真实的数据 这里需要传文件类型


      const res = await this.$https.updateFile({
        // folderId:this.id,
        projectId: localStorage.getItem("projectId"),
        id: this.jsFileId,
        // name: this.jsName,
        fileUrl: _data.mainName,
        imageUrl: _data.imageName,
        deputyUrl: _data.otherNameList,
        fileSize: 0
      });

      if (res.success == true) {
        loading.close();
        this.$message({
          message: "保存成功"
          // type: "success"
        });

        this.getDataTree();
      } else {
        loading.close();
        var token = localStorage.getItem("token");
        if (token) {
          this.$message({
            message: res.errMessage
            // type: "warning"
          });
        }
      }
    },
    //新建场景
    async addFile(value) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });

      const res = await this.$https.addFile({
        //文件夹id
        // folderId:this.id,
        folderId: this.Node.id == undefined ? this.rootFolderId : this.Node.id,

        name: value,
        projectId: localStorage.getItem("projectId"),
        type: 1,
        fileUrl: "",
        fileSize: 0,
        imageUrl: "res/ui/scene.png",
        deputyUrl: ""
      });

      if (res.success == true) {
        loading.close();

        this.fileId = res.data;

        // localStorage.setItem("presentSceneId", this.fileId);
        // localStorage.setItem("sceneName", this.sceneName);
        // this.$parent.setCheckedId(this.fileId);

        this.sceneName = value;

        // IScene.f_saveSceneCallback = this.updataSaveScene.bind(this);

        this.$message({
          message: "创建成功"
          // type: "success"
        });
        this.isEstablish = true;

        this.getDataTree();

        return res.data;
      } else {
        loading.close();
        var token = localStorage.getItem("token");
        if (token) {
          this.$message({
            message: res.errMessage
            // type: "warning"
          });
          return false;
        }
        return false;
      }
    },

    //调用后台接口 更改文件
    async updateFile(_data) {
      if (this.fileId != "null" && this.fileId != "") {
        const loading = this.$loading({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)"
        });
        //获取真实的数据 这里需要传文件类型



        const res = await this.$https.updateFile({
          // folderId:this.id,
          projectId: localStorage.getItem("projectId"),
          id: this.fileId,
          // name: this.fileName,
          fileUrl: _data.mainName,
          imageUrl: _data.imageName,
          deputyUrl: _data.otherNameList,
          fileSize: 0
        });

        if (res.success == true) {
          loading.close();

          if (!this.isEstablish) {
            this.$message({
              message: "保存成功"
              // type: "success"
            });
          }
          this.isEstablish = false;
          this.getDataTree();
        } else {
          loading.close();
          var token = localStorage.getItem("token");
          if (token) {
            this.$message({
              message: res.errMessage
              // type: "warning"
            });
          }
        }
      }

    },

    //切换锁

    async projectLocal(node, value) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      const res = await this.$https.projectLocal({
        id: node.id,
        local: value,
        type: node.type
      });
      if (res.success == true) {
        loading.close();
        this.$message({
          message: "操作成功"
          // type: "success"
        });
        this.getDataTree();
      } else {
        loading.close();

        var token = localStorage.getItem("token");
        if (token) {
          this.$message({
            message: res.errMessage
            // type: "warning"
          });
        }
      }
    },

    // 树节点展开
    handleNodeExpand(data) {
      // 保存当前展开的节点
      let flag = false;
      this.defaultShowNodes.some(item => {
        if (item === data.id) {
          // 判断当前节点是否存在， 存在不做处理
          flag = true;
          return true;
        }
      });
      if (!flag) {
        // 不存在则存到数组里
        this.defaultShowNodes.push(data.id);
      }

      var defaultShowNodes = this.defaultShowNodes;
      var unfoldIdList = JSON.stringify(defaultShowNodes);
      localStorage.setItem("unfoldIdList", unfoldIdList);
    },
    // 树节点关闭
    handleNodeCollapse(data) {
      this.defaultShowNodes.some((item, i) => {
        if (item === data.id) {
          // 删除关闭节点
          this.defaultShowNodes.length = i;
        }
      });
      var defaultShowNodes = this.defaultShowNodes;
      var unfoldIdList = JSON.stringify(defaultShowNodes);
      localStorage.setItem("unfoldIdList", unfoldIdList);
    },
    //ctrl+s保存
    SaveScene(type) {

    },

    //获取用户项目权限
    async getUserProjectAuthority() {
      let userId = localStorage.getItem("phone")
      let groupId = localStorage.getItem("teamId")


      let data = {

        userId: userId,
        groupId: groupId

      }
      const res = await this.$https.getUserProjectAuthority(data)

      if (res.success == true) {
        let list = res.data
        let userPermissionData = {}

        for (let index = 0; index < list.length; index++) {

          userPermissionData[list[index]] = true

        }

        this.$store.commit("changeUserPermissionData", userPermissionData)


      } else {
        this.$message(res.errMessage)
      }



    },




    //保存场景
    async updataSaveScene(_json) {


      //判断有无文件







      var presentSceneId = localStorage.getItem("presentSceneId");

      this.fileId = localStorage.getItem("presentSceneId");

      if (
        this.fileId == "" ||
        this.fileId == null ||
        this.fileId == undefined ||
        this.fileId == "null" ||
        this.fileId == "undefined"
      ) {
        //判断有无文件夹

        if (this.rootFolderId == "") {

          if (this.dataList.length == 0) {
            // 没文件，没选择文件夹，且 文件夹树为空

            ISetting.isInput(true);
            var containSpecial = RegExp(
              /[(\ )(\~)(\!)(\@)(\#)(\$)(\%)(\^)(\&)(\*)(\()(\))(\+)(\=)(\[)(\])(\{)(\})(\|)(\\)(\;)(\:)(\')(\")(\——)(\,)(\.)(\/)(\<)(\>)(\?)(\)]+/
            );

            this.$prompt(``, "创建场景文件夹提示", {
              confirmButtonText: "确定",
              showClose: true,
              closeOnPressEscape: false,
              closeOnClickModal: false,
              // center: true,
              inputErrorMessage: "输入不能为空，且不能有特殊字符 最大20字符",
              inputPlaceholder: "您还没有文件夹请输入文件夹名称，创建文件夹",
              inputValidator: value => {
                // 点击按钮时，对文本框里面的值进行验证
                if (!value) {
                  return "输入不能为空";
                } else if (containSpecial.test(value)) {
                  return "输入不能为特殊字符";
                } else if (value.length > 20) {
                  return "输入文字不能超过20个字符";
                }
              }
            })
              .then(({ value }) => {
                this.addFolder(value, 1).then(() => {
                  var containSpecial = RegExp(
                    /[(\ )(\~)(\!)(\@)(\#)(\$)(\%)(\^)(\&)(\*)(\()(\))(\+)(\=)(\[)(\])(\{)(\})(\|)(\\)(\;)(\:)(\')(\")(\——)(\,)(\.)(\/)(\<)(\>)(\?)(\)]+/
                  );

                  this.$prompt(``, "创建场景文件提示", {
                    confirmButtonText: "确定",
                    showClose: false,
                    closeOnPressEscape: false,
                    closeOnClickModal: false,

                    // center: true,
                    inputPlaceholder: "您将保存请输入保存的文件名称",
                    inputErrorMessage:
                      "输入不能为空，且不能有特殊字符 最大20字符",

                    inputValidator: value => {
                      // 点击按钮时，对文本框里面的值进行验证
                      if (!value) {
                        return "输入不能为空";
                      } else if (containSpecial.test(value)) {
                        return "输入不能为特殊字符";
                      } else if (value.length > 20) {
                        return "输入文字不能超过20个字符";
                      }
                    }
                  })
                    .then(({ value }) => {
                      ISetting.isInput(false);

                      this.fileName = value;

                      //  var id=this.GenNonDuplicateID(64)

                      this.addFile(value).then(() => {
                        localStorage.setItem("openSceneId", this.Node.id);
                        localStorage.setItem("presentSceneId", this.fileId);
                        localStorage.setItem("sceneName", this.sceneName);
                        this.$parent.setCheckedId(this.fileId);

                        var data = INetwork.uploadJsonToSql(
                          this.fileId,
                          "scene",
                          _json,
                          () => { }
                        );

                        if (this.fileId != "null" && this.fileId != "") {


                          this.updateFile(data).then(() => {
                            this.$parent.setSceneName(this.fileName);
                            this.handleNodeClick(this.dataList[0]);
                            var ResourceNode = this.$store.state.ResourceNode;
                            var sceneNode = this.$store.state.sceneNode;



                            this.setSceneLocal(this.fileId, 1);
                            //打开的场景id
                            localStorage.setItem("openSceneId", this.fileId);
                            //打开的文件夹id
                            localStorage.setItem(
                              "FileDirectoryId",
                              this.FileDirectoryId
                            );
                            //打开的树的类型
                            localStorage.setItem(
                              "ResourceType",
                              this.ResourceType
                            );
                            //路径
                            localStorage.setItem("temppath", this.temppath);

                            if (
                              ResourceNode == sceneNode &&
                              this.resourceType == 1 &&
                              ResourceNode != undefined
                            ) {
                              this.getFolderList(1);

                              this.$emit("setFileDirectoryId", this.rootFolderId);
                              this.$emit("setResourceType", this.resourceType);
                            }
                          });
                        }

                      });



                    })
                    .catch(() => {
                      ISetting.isInput(false);
                      // this.$message({
                      //   // type: "info",
                      //   message: "取消输入"
                      // });
                      this.$message({
                        message: "保存失败"
                        // type: "info"
                      });
                    });
                });
              })
              .catch(() => {
                ISetting.isInput(false);

                // this.$message({
                //   // type: "info",
                //   message: "取消输入"
                // });
                this.$message({
                  message: "保存失败"
                  // type: "info"
                });
              });
          } else {
            var rootFolderId = this.dataList[0].id;
            this.rootFolderId = rootFolderId;

            ISetting.isInput(true);
            var containSpecial = RegExp(
              /[(\ )(\~)(\!)(\@)(\#)(\$)(\%)(\^)(\&)(\*)(\()(\))(\+)(\=)(\[)(\])(\{)(\})(\|)(\\)(\;)(\:)(\')(\")(\——)(\,)(\.)(\/)(\<)(\>)(\?)(\)]+/
            );

            this.$prompt(``, "创建场景文件提示", {
              confirmButtonText: "确定",
              showClose: false,
              closeOnPressEscape: false,
              // center: true,
              inputPlaceholder: "您将保存请输入文件名称",
              closeOnClickModal: false,
              inputErrorMessage: "输入不能为空，且不能有特殊字符 最大20字符",

              inputValidator: value => {
                // 点击按钮时，对文本框里面的值进行验证
                if (!value) {
                  return "输入不能为空";
                } else if (containSpecial.test(value)) {
                  return "输入不能为特殊字符";
                } else if (value.length > 20) {
                  return "输入文字不能超过20个字符";
                }
              }
            })
              .then(({ value }) => {

                ISetting.isInput(false);
                this.fileName = value;
                //  var id=this.GenNonDuplicateID(64)

                this.addFile(value).then(() => {

                  localStorage.setItem("presentSceneId", this.fileId);
                  localStorage.setItem("openSceneId", this.Node.id);

                  localStorage.setItem("presentSceneId", this.fileId);
                  localStorage.setItem("sceneName", this.sceneName);
                  this.$parent.setCheckedId(this.fileId);

                  var data = INetwork.uploadJsonToSql(
                    this.fileId,
                    "scene",
                    _json,
                    () => { }
                  );
                  console.log(this.fileId != "null", "this.fileId")

                  if (this.fileId) {

                    this.updateFile(data).then(() => {
                      this.$parent.setSceneName(this.fileName);
                      this.handleNodeClick(this.dataList[0]);

                      var ResourceNode = this.$store.state.ResourceNode;
                      var sceneNode = this.$store.state.sceneNode;
                      if (ResourceNode == sceneNode && this.resourceType == 1) {
                        this.getFolderList(1);

                        this.$emit("setFileDirectoryId", this.rootFolderId);
                        this.$emit("setResourceType", this.resourceType);
                      }

                      this.setSceneLocal(this.fileId, 1);
                      //打开的场景id
                      localStorage.setItem("openSceneId", this.fileId);
                      //打开的文件夹id
                      localStorage.setItem(
                        "FileDirectoryId",
                        this.FileDirectoryId
                      );
                      //打开的树的类型
                      localStorage.setItem("ResourceType", this.ResourceType);
                      //路径
                      localStorage.setItem("temppath", this.temppath);

                      this.$message({
                        message: "文件已放入您的第一个文件夹"
                        // type: "success"
                      });
                    });
                  } else {
                    ISetting.isInput(false);

                    // this.$message({
                    //   // type: "info",
                    //   message: "取消输入"
                    // });
                    this.$message({
                      message: "保存失败"
                      // type: "info"
                    });
                  }
                });
              })
              .catch(() => {
                ISetting.isInput(false);

                // this.$message({
                //   // type: "info",
                //   message: "取消输入"
                // });
                this.$message({
                  message: "保存失败"
                  // type: "info"
                });
              });
          }
        } else {
          ISetting.isInput(true);
          var containSpecial = RegExp(
            /[(\ )(\~)(\!)(\@)(\#)(\$)(\%)(\^)(\&)(\*)(\()(\))(\+)(\=)(\[)(\])(\{)(\})(\|)(\\)(\;)(\:)(\')(\")(\——)(\,)(\.)(\/)(\<)(\>)(\?)(\)]+/
          );

          this.$prompt(``, "创建场景文件提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            showClose: false,
            closeOnPressEscape: false,
            // center: true,
            closeOnClickModal: false,
            inputPlaceholder: "请输入文件名字",
            inputErrorMessage: "输入不能为空，且不能有特殊字符 最大20字符",

            inputValidator: value => {
              // 点击按钮时，对文本框里面的值进行验证
              if (!value) {
                return "输入不能为空";
              } else if (containSpecial.test(value)) {
                return "输入不能为特殊字符";
              } else if (value.length > 20) {
                return "输入文字不能超过20个字符";
              }
            }
          })
            .then(({ value }) => {
              ISetting.isInput(false);

              this.fileName = value;
              var id = this.GenNonDuplicateID(64);

              this.addFile(value).then(() => {


                if (this.fileId != "null" && this.fileId != "") {

                  localStorage.setItem("openSceneId", this.Node.id);

                  localStorage.setItem("presentSceneId", this.fileId);
                  localStorage.setItem("sceneName", this.sceneName);
                  this.$parent.setCheckedId(this.fileId);

                  var data = INetwork.uploadJsonToSql(
                    this.fileId,
                    "scene",
                    _json,
                    () => { }
                  );
                  localStorage.setItem("presentSceneId", this.fileId);
                  this.updateFile(data).then(() => {
                    this.$parent.setSceneName(this.fileName);
                    this.handleNodeClick(this.dataList[0]);

                    var ResourceNode = this.$store.state.ResourceNode;
                    var sceneNode = this.$store.state.sceneNode;

                    this.setSceneLocal(this.fileId, 1);
                    //打开的场景id
                    localStorage.setItem("openSceneId", this.fileId);
                    //打开的文件夹id
                    localStorage.setItem("FileDirectoryId", this.FileDirectoryId);
                    //打开的树的类型
                    localStorage.setItem("ResourceType", this.ResourceType);
                    //路径
                    localStorage.setItem("temppath", this.temppath);

                    if (ResourceNode == sceneNode && this.resourceType == 1) {
                      this.getFolderList(1);

                      this.$emit("setFileDirectoryId", this.rootFolderId);
                      this.$emit("setResourceType", this.resourceType);
                    }
                  });
                }
              });
            })

            .catch(() => {
              ISetting.isInput(false);

              // this.$message({
              //   // type: "info",

              //   message: "取消输入"
              // });
              this.$message({
                message: "保存失败"
                // type: "success"
              });
            });
        }
      } else {

        this.fileId = localStorage.getItem("presentSceneId");
        var data = INetwork.uploadJsonToSql(
          this.fileId,
          "scene",
          _json,
          () => {

            this.updateFile(data).then(() => {
              // if ( this.node.id) {
              //   var oldId= localStorage.setItem("openSceneId", this.Node.id);
              // }

              var setFileDirectoryId = this.Node.id
                ? this.Node.id
                : this.rootFolderId;


              var FileDirectoryId = localStorage.getItem("FileDirectoryId");
              if (FileDirectoryId != undefined && FileDirectoryId != "null") {
              }


              var ResourceNode = this.$store.state.ResourceNode;
              var sceneNode = this.$store.state.sceneNode;
              if (ResourceNode == sceneNode && this.resourceType == 1) {
                this.getFolderList(1);

                this.$emit("setFileDirectoryId", FileDirectoryId);
                this.$emit("setResourceType", this.resourceType);
              }
            });



          }
        );


      }







    },

    //生成路径

    getPathById(id, catalog, callback) {
      //定义变量保存当前结果路径
      var temppath = "";
      try {
        function getNodePath(node) {


          temppath += node.name + ">";
          //找到符合条件的节点，通过throw终止掉递归
          if (node.id == id) {
            throw "GOT IT!";
          }
          if (node.subCatalog && node.subCatalog.length > 0) {
            for (var i = 0; i < node.subCatalog.length; i++) {
              getNodePath(node.subCatalog[i]);
            }

            //当前节点的子节点遍历完依旧没找到，则删除路径中的该节点
            temppath = temppath.substring(0, temppath.length - 1);
            temppath = temppath.substring(0, temppath.lastIndexOf(">")) + ">";
          } else {
            //找到叶子节点时，删除路径当中的该叶子节点
            temppath = temppath.substring(0, temppath.length - 1);
            temppath = temppath.substring(0, temppath.lastIndexOf(">")) + ">";
          }
        }

        getNodePath(catalog);
      } catch (e) {

        callback(temppath);
      }
    },
    //通过id查找节点
    inquireNode(tree, id) {
      for (let index = 0; index < tree.length; index++) {
        if (tree[index].id == id) return tree[index];
        if (tree[index].subCatalog) {
          const res = this.inquireNode(tree[index].subCatalog, id);
          if (res) return res;
        }
      }

      return null;
    },
    //设置场景锁状态
    async setSceneLocal(_id, _local) {
      // const loading = this.$loading({
      //   lock: true,
      //   text: "Loading",
      //   spinner: "el-icon-loading",
      //   background: "rgba(0, 0, 0, 0.7)"
      // });

      const res = await this.$https.sceneLocal({
        id: _id,
        local: _local
      });
      if (res.success == true) {
        // loading.close();
      } else {
        // loading.close();
        var token = localStorage.getItem("token");
        if (token) {
          this.$message({
            message: res.errMessage
            // type: "warning"
          });
        }
      }
    },

    //设置选中节点
    // setCurrentNodekey(){
    //     this.architectureData =this.dataList[0].id

    //     if (this.architectureData.length > 0) {
    //       this.currentNodekey = this.architectureData[0].id;
    //       this.$nextTick(() => {
    //         this.$refs.treeRef.setCurrentKey(this.currentNodekey); //一定要加这个选中了否则样式没有出来
    //        });

    //     }
    // },

    //蒙版提示
    maskingHint() {
      this.$message({
        message: "请在js编辑框内编写js代码"
        // type: "warning"
      });
    },
    //生成id
    GenNonDuplicateID(randomLength) {
      let idStr = Date.now().toString(36);
      idStr += Math.random()
        .toString(36)
        .substr(3, randomLength);
      return idStr;
    }
  },

  filters: {
    ///图标过滤器
    filtrationImg: function (value) {
      var realUrl = INetwork.getUrl(value);
      return realUrl;
    }
  }
};
</script>
<style scoped>
.content {
  /* width: 420px; */
  /* height: 450px; */
  /* height: 500px; */
  background: #fff;
  /* box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1); */
  /* border-radius: 5px; */
  overflow-y: auto;
}

.content::-webkit-scrollbar {
  display: none;
}

.content {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.anmDiv {
  transition: 1s;
}

.aaa {
  width: 45px;
  transition: 1s;
}

.contentTxt {
  width: 45px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  background: #c33;
  border-radius: 5px;
  color: #fff;
  font-size: 8px;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}

.title {
  width: 90%;
  height: 25px;
  line-height: 25px;
  color: #000;
  font-size: 10px;
  border-bottom: 2px solid #ccc;
  text-align: left;
  margin: auto;
}

.tree {
  width: 100%;
  height: 100%;
  /* background-color: black; */
  /* margin: 10px auto; */
  /* overflow: hidden; */
}

.tree /deep/ .el-tree-node:before {
  content: "";
  position: absolute;
  left: 30px;
  bottom: 0px;
  right: 30px;
  /* border-top: 1px solid #F7F7F7;; */
}

/* /deep/ .tree .el-tree-node:checked {
  
border: 2px solid #0560FD;
border-radius: 10px;
} */
.tree /deep/ .el-tree-node__content {
  height: 30px;
  margin-top: 8px;
}

.tree /deep/ .el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
  /* background: rgba(75, 79, 82, 0); */
  background: #e6efff;

  /* border: 2px solid rgba(5, 96, 253, 0.7); */
  border-radius: 10px;
}

.tree /deep/.el-icon-caret-right:before {
  content: "";
  font-size: 25px;
  width: 10px;
  height: 10px;
  display: block;
  background: url("../../assets/Resource/arrows.png") no-repeat center;
  background-size: 10px 10px;
}

.tree /deep/ .el-tree .el-tree-node__expand-icon.expanded .el-icon-caret-right:before {
  content: "";
  font-size: 25px;
  width: 10px;
  height: 10px;
  display: block;
  background: url("../../assets/Resource/arrows.png") no-repeat center;
  background-size: 10px 10px;
}

.el-tree /deep/.el-tree-node__expand-icon.is-leaf::before {
  background: #ffffff;
  content: "";
  display: block;
  width: 0px;
  height: 0px;
  font-size: 16px;
  background-size: 16px;
}

/* el-checkbox  */

/* 滚动条 */
/* .tree::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: rgba(240, 240, 240, 1);
}
.tree::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0px rgba(240, 240, 240, 0.5);
  border-radius: 10px;
  background-color: rgba(240, 240, 240, 0.5);
}
.tree::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 0px rgba(240, 240, 240, 0.5);
  background-color: rgba(240, 240, 240, 0.5);
} */

/* 设置鼠标悬停显示 */
.custom-tree-node {
  width: 100%;
  height: 70px;
}

.custom-tree-node-content {
  width: 80%;
  float: left;
  height: 50px;
}

.operation {
  visibility: hidden;
  margin-right: 30%;
  float: right;
}

.custom-tree-node:hover>.operation {
  visibility: visible;
}

/* 设置鼠标悬停完毕  */

/**设置右击 */

.menu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999999999999;
}

.menuItem {
  /* text-align:center; */
  line-height: 60px;
}

.myMenu /deep/ .menu {
  border-radius: 10px;

  padding: 10px 0;
  box-shadow: 0px 0px 6px 0px rgba(33, 35, 49, 0.1);
}

.myMenu /deep/.el-menu-item {
  height: 40px;
  line-height: 40px;
  position: relative;

  white-space: nowrap;
  list-style: none;
  text-align: left;
}

.myMent /deep/ .el-menu-item.is-active {
  color: #000;
}

/* 菜单图标 */
.menuIcon {
  width: 19px;
  height: 17px;

  margin-right: 20px;
  margin-top: -4px;
}

/* 图标 */
.icon {
  width: 19px;
  height: 17px;
  margin: 0 2px;
}

/* 文件图标 */
.fileIcon {
  width: 19px;
  height: 17px;
  position: relative;
  top: -17px;
}

/* 锁 */
.lock {
  width: 12px;
  height: 12px;
  /* float: right;
   */
  position: absolute;
  right: 20px;
  margin: 28px -5px;
  padding: 3px;

  /* border-radius: 2px;; */
}

.share {
  width: 12px;
  height: 12px;
  /* float: right;
   */
  position: absolute;
  right: 50px;
  margin: 28px -5px;
  padding: 3px;

  /* border-radius: 2px;; */
}






.recycle {
  width: 250px;
}

.fileName {
  display: inline-block;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tree_label {
  display: inline-block;
  width: 80%;
  height: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* width: 80px; */
  /* height: 16px; */
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 70px;
  /* margin-bottom: -2px;; */
  margin-left: 8px;
}

.empty {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  box-shadow: 0px 0px 4px 0px rgba(33, 35, 49, 0.1);
  position: relative;
  margin: 80px auto;
  margin-top: 50px;
  /* position: absolute; */

  /* background:red; */
  /* background-position: 50%,50%; */
  background-size: 100px 100px;
  padding: 10px;

  /* background: url(../../assets/Resource/empty.png) no-repeat ; */
}

.empty_img {
  width: 20px;
  height: 20px;
}

/* js编辑器 */
.codeEditor {
  width: 1300px;
  height: 700px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #282c34;
  border-radius: 12px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
}

.close {
  color: #fff;
  position: absolute;
  z-index: 100;
  right: 10px;
  top: 8px;
  cursor: pointer;
}

.masking {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  /* background: red; */
  opacity: 0;
  z-index: 9;
}

.reset-message-box {
  text-align: center;
}

.delete {
  width: 350px;
  height: 150px;
  position: fixed;
  top: 50%;
  left: 40%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0px 0px 6px 0px rgba(33, 35, 49, 0.1);
  z-index: 9999999;
}

.delete .close {
  position: absolute;
  right: 20px;
  top: 0px;
  cursor: pointer;
}

.delete .close img {
  width: 15px;
  height: 15px;
}

.deletNameDiv {
  margin-top: 25px;
}

.deleteName {
  width: 250px;
  height: 46px;
  line-height: 46px;
  text-align: center;
  font-size: 12px;
  font-weight: bolder;
  margin: 0 auto;
}

.enter {
  width: 100%;
  height: 80px;
  position: fixed;
  bottom: 0;
  font-size: 12px;
  display: flex;
}

.enter .set {
  width: 220px;
  height: 35px;
  line-height: 35px;
  border-radius: 8px;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
}

.verify {
  background-color: #0560fd;
  color: #fff;
  margin-left: 20px;
  margin-top: 15px;
  margin-left: 35px;
}

.cancel {
  color: #999;
  background: #ffffff;
  box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
  margin-top: 15px;
  margin-left: 30px;
}

.deleteOK {
  background-color: #0560fd;
  color: #fff;
  margin-left: 20px;
  margin-top: 15px;
  margin-left: 30px;
}

.deleteCancel {
  color: #999;
  background: #ffffff;
  box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
  margin-top: 15px;
  margin-left: 20px;
  margin-right: 30px;
}

.delete .ipt_box {
  width: 360px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.delete .ipt_box .input {
  width: 350px;
  height: 35px;
  border: none;
  border-radius: 5px;
  outline: none;
  font-size: 10px;
  background-color: rgba(0, 0, 0, 0);
  padding-left: 15px;
}
</style>

