import { GLTFExporter } from '../object/GLTFExporter';
import { MainObjects } from "../common/MainObjects"
import { GameObject } from '../object/GameObject';
import * as THREE from "three"
import { ISelector } from './ISelector';
class IExport {
    static exportGLTF(input, callback) {
        const link = document.createElement( 'a' );
        link.style.display = 'none';
        document.body.appendChild( link ); // Firefox workaround, see #6594
        function save(blob, filename) {

            link.href = URL.createObjectURL(blob);
            link.download = filename;
            link.click();

            // URL.revokeObjectURL( url ); breaks Firefox...

        }

        function saveString(text, filename) {

            save(new Blob([text], { type: 'text/plain' }), filename);

        }


        function saveArrayBuffer(buffer, filename) {

            save(new Blob([buffer], { type: 'application/octet-stream' }), filename);

        }


        const params = {
            trs: false,
            onlyVisible: true,
            binary: false,
            maxTextureSize: 4096,
        };

        const gltfExporter = new GLTFExporter();

        const options = {
            trs: params.trs,
            onlyVisible: params.onlyVisible,
            binary: params.binary,
            maxTextureSize: params.maxTextureSize
        };
        gltfExporter.parse(
            input,
            function (result) {

                if (result instanceof ArrayBuffer) {

                    saveArrayBuffer(result, 'scene.glb');

                } else {

                    const output = JSON.stringify(result, null, 2);
                    console.log(output);
                    saveString(output, 'scene.gltf');

                }
                callback();
            },
            function (error) {

                console.log('An error happened during parsing', error);

            },
            options
        );

    }

    static exportSceneGLTF() {
        var scene = new THREE.Scene();
        var list = new Map();
        MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjects(function (obj) {
            obj.traverse(function (child) {
                if (child != null && child.userData != null) {
                    if (child.userData.m_gameObjectType && child.userData.m_gameObjectType == GameObject.GameObjectType.e_GLTF) {
                        list.set(child, child.parent);
                    }
                }
    
            });
        });
       
        for (var [kay, value] of list) {
            scene.attach(kay);
        }
        IExport.exportGLTF(scene, function () {
            for (var [kay, value] of list) {
                value.attach(kay);
            }
        });
    }
}

export { IExport };