import {ParticleEmitter} from "three.quarks";

const state = {
    bezierCurve: null,
    curParticleSystem: null,
}
const mutations = {
    updateProperties() {
        console.log('更新属性')
    },
    updateEmitterShape(state, data) {
        console.log(state, data,'更新发射器形状')
        data.emitter.traverse(obj => {
            if (obj.type === "ParticleSystemPreview") {
                obj.update();
            }
        });
    },
    setEditableBezier(state, data){
        state.bezierCurve = data
    },
    onChangeBezierCurve(state, data){

    },
    changeCurrentParticleSystem(state, data) {
        if(!data instanceof ParticleEmitter) return
        state.curParticleSystem = data.system
    }


}
const getters = {

}
const actions = {

}
export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}