import { type } from "os";
import { MainObjects } from "../common/MainObjects"
import { Saver } from "../loader/Saver"
import { GameObject } from "../object/GameObject"




class IScene {

    static f_saveSceneCallback = null;
    static f_copyProcessCallback = null;
    static f_sceneTreeCallback = null;

    static TreeMode =
    {
        e_ALL: 0,
        e_LIGHT: 1,
        e_CAMERA: 2,
        e_SELECT_OBJ: 3,
        e_COLLISIONS: 4,
        e_PARTICLE: 5
    }
    static m_treeMode = IScene.TreeMode.e_ALL;

    static getSceneTree(mode = IScene.TreeMode.e_ALL) {
        IScene.m_treeMode = mode;
        if(mode == IScene.TreeMode.e_ALL)
        {
            return MainObjects.Blueprint.m_sceneManagerBp.getTree(MainObjects.Scene.m_userGroup);
        }
        else if(mode == IScene.TreeMode.e_LIGHT)
        {
            return MainObjects.Blueprint.m_sceneManagerBp.getLightTree(MainObjects.Scene.m_userGroup);
        }
        else if(mode == IScene.TreeMode.e_CAMERA)
        {
            return MainObjects.Blueprint.m_sceneManagerBp.getCameraTree(MainObjects.Scene.m_userGroup);
        } 
        else if(mode == IScene.TreeMode.e_SELECT_OBJ)
        {
            return MainObjects.Blueprint.m_sceneManagerBp.getSelectTree();
        }
        else if(mode == IScene.TreeMode.e_COLLISIONS)
        {
            return MainObjects.Blueprint.m_sceneManagerBp.getCollisions(MainObjects.Scene.m_userGroup);
        }
        else if(mode == IScene.TreeMode.e_PARTICLE)
        {
            return MainObjects.Blueprint.m_sceneManagerBp.getParticle(MainObjects.Scene.m_userGroup);  
        }
    }
    static getSceneGroup()
    {
        var data = new Array();
        var list = MainObjects.Blueprint.m_sceneManagerBp.getTypeObjects(GameObject.GameObjectType.e_GROUP);
       
        
        list.forEach(element => {
            var temp = {
                name : element.userData.m_name,
                object : element,
                id:element.uuid
            }
            data.push(temp);
        });
        return data;
    }
    //移动
    static addTo(parent, child) {
        if(child.userData.m_gameObjectType == GameObject.GameObjectType.e_GEOMETRY_CURVE 
            || child.userData.m_gameObjectType == GameObject.GameObjectType.e_GLTF_MESH 
            || child.userData.m_gameObjectType == GameObject.GameObjectType.e_SKY 
            || child.userData.m_gameObjectType == GameObject.GameObjectType.e_REAL_TIME_SKY)
        {
            return;
        }
        var undo = MainObjects.Blueprint.m_sceneManagerBp.addUndoRedoAddTo(child.parent, child);
        if(parent == null)
        {
            MainObjects.Blueprint.m_sceneManagerBp.addTo(parent, child);
            MainObjects.Blueprint.m_sceneManagerBp.addUndoRedoAddTo(parent, child, undo, true);
            if(IScene.f_sceneTreeCallback != null)
            {
                IScene.f_sceneTreeCallback();
            }
            return true;
        }
        else if (parent.userData.m_gameObjectType == GameObject.GameObjectType.e_GROUP) {
            MainObjects.Blueprint.m_sceneManagerBp.addTo(parent, child);
            MainObjects.Blueprint.m_sceneManagerBp.addUndoRedoAddTo(parent, child, undo, true);
            if(IScene.f_sceneTreeCallback != null)
            {
                IScene.f_sceneTreeCallback();
            }
            return true;
        }
        if(IScene.f_sceneTreeCallback != null)
        {
            IScene.f_sceneTreeCallback();
        }
        return false;
    }

    // 获取类型的数组
    static getTypeObjects(type){
        var list = MainObjects.Blueprint.m_sceneManagerBp.getTypeObjects(type);

        return list
    }


    static save() {
        Saver.toSave();
    }
}

export { IScene };