<template>
  <div class="behavior-item">
    <div class="behavior-item-header">
      <div>{{ t(behavior.type) }}</div>
      <!--
      <Checkbox edge="end" @change="handleToggle" v-model="checked" />
      -->
      <div @click="onDelete">
        <i class="el-icon-delete"></i>
      </div>
    </div>
    <div>
      <FieldEditor
          v-for="([varName, type]) in entry.params.filter(([varName, type]) => type !== 'self')"
          :key="varName"
          :fieldName="varName"
          :target="behavior"
          :onChange="updateProperties"
          :type="type"
      />
    </div>
  </div>
</template>

<script>
import {t} from "../../../util/lang";
import FieldEditor from "@/views/ParticleSystem/Editors/FieldEditor.vue";
import {BehaviorTypes} from "three.quarks";

export default {
  components: {FieldEditor},
  props: {
    behavior: Object,
    onDelete: Function,
    updateProperties: Function
  },
  data() {
    return {
      checked: true,
    };
  },
  methods: {
    t,
    handleToggle(event) {
      this.checked = event.target.checked;
    },
  },
  computed: {
    entry() {
      return BehaviorTypes[this.behavior.type];
    },
  },
};
</script>
<style lang="less">
.behavior-item {
  box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
  border-radius: 10px;
  margin-right: 10px;

  padding: 15px;
  margin-top: 20px;
  .behavior-item-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .el-icon-delete {
      font-size: 20px;
    }
  }
}

</style>
