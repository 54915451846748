import { MainObjects } from "../common/MainObjects";
import { GameObject } from "../object/GameObject";
import { CurveBehavior } from "../behavior/CurveBehavior";
import { HudBehavior } from "../behavior/HudBehavior";
import { NavMeshBehavior } from "../behavior/NavMeshBehavior";
import { MutilTextBehavior } from "../behavior/MutilTextBehavior";
import { TextureBehavior } from "../behavior/TextureBehavior";
import { DymaticBehavior } from "../behavior/DymaticBehavior";
import { MapBehavior } from "../behavior/MapBehavior";

class IBehavior {
    static m_mutilTextBehavior = "MutilTextBehavior";
    static m_curveBehavior = "CurveBehavior";
    static m_hudBehavior = "HudBehavior";
    static m_navMeshBehavior = "NavMeshBehavior";
    static m_textureBehavior = "TextureBehavior";
    static m_dymaticBehavior = "DymaticBehavior";
    static m_mapBehavior = "MapBehavior";
    static m_behavior = new Map();

    static createBehavior(key){
       
        if(key == IBehavior.m_mutilTextBehavior)
        {
            this.m_behavior.set(key, new MutilTextBehavior())
        }
        else if(key == IBehavior.m_curveBehavior)
        {
            this.m_behavior.set(key, new CurveBehavior())
        }
        else if(key == IBehavior.m_hudBehavior)
        {
            this.m_behavior.set(key, new HudBehavior())
        }
        else if(key == IBehavior.m_navMeshBehavior)
        {
            this.m_behavior.set(key, new NavMeshBehavior())
        }
        else if(key == IBehavior.m_textureBehavior)
        {
            this.m_behavior.set(key, new TextureBehavior())
        }
        else if(key == IBehavior.m_dymaticBehavior)
        {
            this.m_behavior.set(key, new DymaticBehavior())
        }
        else if(key == IBehavior.m_mapBehavior)
        {
            this.m_behavior.set(key, new MapBehavior())
        }
    }

    static getBehaviorJson(key){
        var be = this.m_behavior.get(key);
        if(be)
        {
          return be.getJson();
            
        }
        else{
            return {};
        }
    }

    static setBehaviorJson(json,key)
    {
        var be = this.m_behavior.get(key);
        if(be)
        {
            be.setJson(json);
        }
    }

    static addBehavior(type)
    {
        MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjects(function (obj) {
            obj.userData.setBehavior(type);
        });
    }

    static removeBehavior(type)
    {
        MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjects(function (obj) {
            obj.userData.removeBehavior(type);
        });
    }

    static getBehavior()
    {
        var length = MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjectsCount();
        if(length == 1)
        {
            const firstObj = MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjectFirst();
            return firstObj.userData.m_behavior;
        }
        return null;
    }

    static setMutilTextBehaviorText(i, text)
    {
        var length = MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjectsCount();
        if(length == 1)
        {
            const firstObj = MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjectFirst();
            firstObj.userData.m_behavior.get(IBehavior.m_mutilTextBehavior).setText(i, text);
        }
    }

    static getCurveBehaviorList()
    {
        var listF = new Array();
        var list = MainObjects.Blueprint.m_sceneManagerBp.getTypeObjects(GameObject.GameObjectType.e_CURVE);
        list.forEach(element => {
            var data = {
                id : element.name,
                name : element.userData.m_name
            }
            listF.push(data)
        });
        return listF;
    }

    static setCurveBehavior(data)
    {
        // data = {
        //     curve_name : id,
        //     speed : 0.1,
        //     bloop : false,
        //     sim : false
        ///    percent : percent
        // }
        var length = MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjectsCount();
        if(length == 1)
        {
            const firstObj = MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjectFirst();
            var be = firstObj.userData.m_behavior.get(IBehavior.m_curveBehavior);
            be.setJson(data);
            if(data.sim)
            {
                be.init(firstObj);
                MainObjects.Blueprint.m_sceneManagerBp.m_gameScene.m_simRepos.add(firstObj.id, firstObj);
            }
            else
            {
                MainObjects.Blueprint.m_sceneManagerBp.m_gameScene.m_simRepos.remove(firstObj.id);
            }
        }
    }

    static setTextureBehavior(data)
    {
        var length = MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjectsCount();
        if(length == 1)
        {
            const firstObj = MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjectFirst();
            var be = firstObj.userData.m_behavior.get(IBehavior.m_textureBehavior);
            be.setJson(data);
            be.init(firstObj);
        }
    }

    static setDymaticBehavior(data)
    {
        var length = MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjectsCount();
        if(length == 1)
        {
            const firstObj = MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjectFirst();
            var be = firstObj.userData.m_behavior.get(IBehavior.m_dymaticBehavior);
            be.setJson(data);
            be.init(firstObj);
        }
    }

    static setHudBehavior(data)
    {
        var length = MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjectsCount();
        if(length == 1)
        {
            const firstObj = MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjectFirst();
            var be = firstObj.userData.m_behavior.get(IBehavior.m_hudBehavior);
            be.setJson(data);
            be.init(firstObj);
        }
    }

    static setNavMeshBehavior(data)
    {
        var length = MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjectsCount();
        if(length == 1)
        {
            const firstObj = MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjectFirst();
            var be = firstObj.userData.m_behavior.get(IBehavior.m_navMeshBehavior);
            be.setJson(data);
        }
    }

    static setMapBehavior(data){
        var length = MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjectsCount();
        if(length == 1)
        {
            const firstObj = MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjectFirst();
            var be = firstObj.userData.m_behavior.get(IBehavior.m_mapBehavior);
            be.setJson(data);
        }
    }
}

export { IBehavior };