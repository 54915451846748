import { MainObjects } from "../common/MainObjects"
import { Language } from "../common/Language"
import { Loader } from "../loader/Loader"
import { INetwork } from "./INetwork";
import { IMaterial } from "./IMaterial"
import { IMaterialPreview } from "./IMaterialPreview";
import { ISelector } from "./ISelector";
import { IScene } from "./IScene";
import { SceneFactory } from "../factory/SceneFactory";
import { ITransform } from "./ITransform";
import { GameObject } from "../object/GameObject"
import { PositionalAudioHelper } from 'three/examples/jsm/helpers/PositionalAudioHelper';

import * as THREE from 'three';


class ILoader {
    static FileType =
        {
            e_SCENE: 1,
            e_MODLE: 2,
            e_TEXTURE: 3,
            e_MATERIAL: 4,
            e_JS: 5
        }

    static getBaseName(extension) {
        switch (extension) {

            case "scene":
                {
                    return Language.LOCAL_TEXT("scene", "场景");
                }
            case 'gltf':
            case 'obj':
                {
                    return Language.LOCAL_TEXT("modle", "模型");
                }
            case "jpg":
            case "jpeg":
            case "png":
                {
                    return Language.LOCAL_TEXT("texture", "纹理");
                }
            case "hdr":
                {
                    return Language.LOCAL_TEXT("hdr", "HDR");
                }
            case "js":
                {
                    return Language.LOCAL_TEXT("js", "脚本");
                }
            case "group":
                {
                    return Language.LOCAL_TEXT("js", "组");
                }
            case "material":
                {
                    return Language.LOCAL_TEXT("js", "材质");
                }
            case "mp3":
                {
                    return Language.LOCAL_TEXT("audio", "音频");
                }
            default:
                return Language.LOCAL_TEXT("resouse", "资源");
        }
    }

    static loadModelToScene(name, id, file_name, processCallback, finishCallback) {

        var url = INetwork.getUrl(name);


        Loader.loadFileToScene(url, name, id, processCallback, function (res) {
            MainObjects.Blueprint.m_sceneManagerBp.m_gameScene.getAllSelectObjects(res);
            res.position.copy(MainObjects.Blueprint.m_cameraListenerBp.getCameraDirPosition(res));
            SceneFactory.addToUserGroup(res);
            if (IScene.f_sceneTreeCallback != null) {
                IScene.f_sceneTreeCallback();
            }
            ISelector.selectObjectByTree(res, false);
            ISelector.sceneTreeSelectCallback();
            finishCallback(res);
        }, null, file_name);
    }


    static loadGroupToScene(name, file_name, processCallback, finishCallback) {

        INetwork.downloadJson(name, function (data) {
            Loader.loadGroupToScene(data, processCallback, finishCallback, null, file_name)
        });
    }

    static loadTextureToMat(name, id, type, processCallback, finishCallback) {
        var url = INetwork.getUrl(name);


        Loader.loadFileToScene(url, name, id, processCallback, function (res) {
            IMaterial.setMap(res, type);
            finishCallback(res);
        });
    }

    static loadTextureToTarget(url, name, id, processCallback, finishCallback) {
        var url = INetwork.getUrl(url);
        Loader.loadFileToScene(url, name, id, processCallback, function (res) {
            finishCallback(res);
        });
    }

    //type: heigh_map ,base_texture, blend_texture,  grass_map # subTexIndex start 1`
    static loadTextureToTerrain(name, id, type, subTexIndex, processCallback, finishCallback) {
        MainObjects.Blueprint.m_terrainBp.setTexture(name, id, type, subTexIndex, processCallback, function () {
            ITransform.f_selectTransformCallback();
            finishCallback();
        });
    }

    static loadTextureToEnvmap(name, image_name) {
        var url_image = image_name;
        MainObjects.Setting.m_envMap = name;
        MainObjects.Setting.m_envMapImage = url_image;
        SceneFactory.initDefultHdrInScene();
    }

    static loadTextureToUserMat(name, id, type, processCallback, finishCallback) {
        var url = INetwork.getUrl(name);


        Loader.loadFileToScene(url, name, id, processCallback, function (res) {
            IMaterialPreview.setMap(res, type);
            finishCallback(res);
        });
    }

    static loadMaterialToObject(name, id, processCallback, finishCallback) {
        var url = INetwork.getUrl(name);
        INetwork.downloadJson(name, function (data) {
            MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjects(function (obj) {
                Loader.loadMaterialToObject(url, name, id, data, obj, processCallback, finishCallback, null);
            });
        });

    }

    static loadAudioToScene(name, id, file_name, processCallback, finishCallback) {
        ILoader.loadModelToScene(name, id, file_name, processCallback, finishCallback);
    }

    static loadParticleSystemExampleToScene(name, id, file_name, processCallback, finishCallback) {
        processCallback(0);

        INetwork.downloadJson(name, function (data) {
            Loader.loadParticleSystemToScene(name, id, file_name, data, processCallback, function (res) {
                MainObjects.Blueprint.m_sceneManagerBp.m_gameScene.getAllSelectObjects(res);
                res.position.copy(MainObjects.Blueprint.m_cameraListenerBp.getCameraDirPosition(res));
                SceneFactory.addToUserGroup(res);
                if (IScene.f_sceneTreeCallback != null) {
                    IScene.f_sceneTreeCallback();
                }
                ISelector.selectObjectByTree(res, false);
                ISelector.sceneTreeSelectCallback();
                finishCallback(res);
            });
        });
    }
    static clearCache(key, finishCallback) {
        var gameScene = MainObjects.Blueprint.m_sceneManagerBp.m_gameScene;
        gameScene.m_mp3Repos.remove(key)

        if (finishCallback) {
            finishCallback()
        }

    }

    static upDateAudio(oldUrl, newUrl,finishCallback) {


        var url = INetwork.getUrl(newUrl);
        var manager = new THREE.LoadingManager();


        const extension = newUrl.split('.').pop().toLowerCase();


        Loader.loadFile(url, extension, manager, function (res, extension) {


            var audioList = MainObjects.Blueprint.m_sceneManagerBp.getTypeObjects(GameObject.GameObjectType.e_AUDIO)

            for (let index = 0; index < audioList.length; index++) {
                const element = audioList[index];
                if (element.userData.m_url == oldUrl) {
                    element.userData.m_sound.pause();
                    element.userData.m_sound.setBuffer(res);
                    // console.log(element, oldUrl, res, "element");
                    if (ITransform.f_selectTransformCallback != null) {
                        var sound = new THREE.PositionalAudio(MainObjects.Scene.m_listener);
                        sound.setBuffer(res);
                        sound.name="sound"
                        element.userData.m_sound=sound;
                        const positionaHelper = new PositionalAudioHelper(sound);
                        sound.add(positionaHelper);
                        element.userData.name = sound.uuid;
                        ITransform.f_selectTransformCallback(true);
                    }
                }
            }

            if (finishCallback) {
                finishCallback()
            }

        })


    }



}

export { ILoader };