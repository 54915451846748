class MapBehavior {
    constructor() {
        this.m_map = new Map();
    }

    setText(key, text)
    {
        this.m_map.set(key, text)
    }

    removeText(key)
    {
        this.m_map.delete(key)
    }
    getJson()
    {
        var json = {
            keys:[],
            values:[]
        };
        for(var [key, value] of this.m_map)
        {
            json.keys.push(key);
            json.values.push(value);
        }
        return json;
    }
    
    setJson(json, isMap = true)
    {
        if(isMap)
        {
            this.m_map.clear();
            for(var [key, value] of json)
            {
                if(key != "" && value != "")
                {
                    this.m_map.set(key, value);
                }
            }
        }
        else{
            if(json && json.keys){
                this.m_map.clear();
                for (let index = 0; index < json.keys.length; index++) {
                    const key = json.keys[index];
                    const value = json.values[index];
                    this.m_map.set(key, value);
                }
            }
        }

    }

    init(object){

    }
    destory()
    {
 
    }
}

export { MapBehavior };
