import * as THREE from 'three';
import { MainObjects } from "../common/MainObjects"
import { MaterialObject } from "../object/MaterialObject"
import { TextureObject } from "../object/TextureObject"
import { Loader } from "../loader/Loader"
import { INetwork } from "../interface/INetwork"
import { Saver } from '../loader/Saver';
import { GameObject } from '../object/GameObject';
import { MaterialFactory } from '../factory/MaterialFactory';

class MaterialEditorBp {
    constructor() {
    }

    getTextureJson(textureMap) {
        if (textureMap != null) {
            var data = {
                type: TextureObject.TextureObjectType.e_DEFLUT_MAP,
                id: "-",
                name: "-",
                url: "-",
                image: textureMap.image.src,
                offset: [0, 0],
                repeat: [1, 1],
                rotation: 0,
                gamma: 2.2
            }
            data.offset[0] = textureMap.offset.x;
            data.offset[1] = textureMap.offset.y;
            data.repeat[0] = textureMap.repeat.x;
            data.repeat[1] = textureMap.repeat.y;
            data.rotation = textureMap.rotation;

            if (textureMap.userData != undefined) {
                var textureObject = textureMap.userData;
                data.type = textureObject.m_textureObjectType;
                data.id = textureObject.m_id;
                data.name = textureObject.m_name;
                data.url = textureObject.m_url;
                data.gamma = textureObject.m_gamma;
            }
            return data;
        }
        return null;
    }

    getTextureFromJson(texture, textureData, callback, manager) {
        if (textureData == null) {
            texture = null;
            return;
        }
      
        if (textureData.type == TextureObject.TextureObjectType.e_DEFLUT_MAP) {
            if (texture != null) {
                texture.offset.fromArray(textureData.offset);
                texture.repeat.fromArray(textureData.repeat);
                texture.rotation = textureData.rotation;
                texture.userData.m_gamma = textureData.gamma;
                callback(texture);
            }
        }
        else if (textureData.type == TextureObject.TextureObjectType.e_NET_MAP) {

            var url = INetwork.getUrl(textureData.url);
            
            Loader.loadFileToScene(url, textureData.url, textureData.id, function (p) { }, function (res) {
                res.offset.fromArray(textureData.offset);
                res.repeat.fromArray(textureData.repeat);
                res.rotation = textureData.rotation;
                res.userData.m_gamma = textureData.gamma;
                callback(res);
            }, manager);
        }
    }

    getMaterialJson(child) {

        var material = {
            objectName: child.name,
            type: MaterialObject.MaterialType.e_BASIC,
            edType : MaterialObject.MaterialEditorType.e_DEFLUT,
            matType : MaterialObject.MaterialMatType.e_DEFLUT,
            materialMat : {},
            url: null,
            name: null, 
            id: null,
            fog: true,                   //雾影响：是否
            opacity: 1,                  //透明度：在0.0 - 1.0的范围内的浮点数
            side: THREE.FrontSide,       //渲染面：正面，背面或两者（下拉）
            transparent: false,          //透明：是 否

            aoMap: null,                 //环境遮挡贴图
            aoMapIntensity: 1,           //环境遮挡效果的强度
            color: [1, 1, 1],            //材质的颜色
            envMap: null,                //环境贴图
            lightMap: null,              //光照贴图
            lightMapIntensity: null,     //光照贴图强度
            map: null,                    //颜色贴图
            reflectivity: 1,             //环境贴图对表面的影响程度
            refractionRatio: 1,          //空气的折射率（IOR）
            specularMap: null,           //高光贴图
            specular: [0, 0, 0],         //高光

            emissive: [1, 1, 1],         //发光颜色
            emissiveMap: null,           //发光贴图
            emissiveIntensity: 1,        //发光强度
            envMapIntensity: 1,          //环境贴图强度
            metalness: 0,                //金属度
            metalnessMap: null,          //金属度贴图
            normalMap: null,             //法线贴图
            roughness: 0,                 //材质的粗糙程度
            roughnessMap: null,          //该纹理的绿色通道用于改变材质的粗糙度

            matcap: null,                //matcap光照贴图
            matcapIntensity:1,           //matcap光照强度
            gradientMap: null            //toon过度图

        }
        if (child.isMesh) {

            material.type = child.material.userData.m_materialObjectType;
            material.edType = child.material.userData.m_materialEditorType;
            material.url =  child.material.userData.m_url;
            material.name = child.material.userData.m_name;
            material.id =  child.material.userData.m_id;

            material.fog = child.material.fog;
            material.opacity = child.material.opacity;
            material.side = child.material.side;
            material.transparent = child.material.transparent;

            switch (child.material.userData.m_materialObjectType) {
                case MaterialObject.MaterialType.e_BASIC:
                    material.aoMap = this.getTextureJson(child.material.aoMap);
                    material.aoMapIntensity = child.material.aoMapIntensity;
                    child.material.color.toArray(material.color);
                    material.envMap = this.getTextureJson(child.material.envMap);
                    material.lightMap = this.getTextureJson(child.material.lightMap);
                    material.lightMapIntensity = child.material.lightMapIntensity;
                    material.map = this.getTextureJson(child.material.map);
                    material.reflectivity = child.material.reflectivity;
                    material.refractionRatio = child.material.refractionRatio;
                    material.specularMap = this.getTextureJson(child.material.specularMap);
                    break;
                case MaterialObject.MaterialType.e_STANDARD:
                    material.matType = child.material.userData.m_materialMatType;
                    material.materialMat = child.material.userData.getMaterialMat();
                    material.aoMap = this.getTextureJson(child.material.aoMap);
                    material.aoMapIntensity = child.material.aoMapIntensity;
                    child.material.color.toArray(material.color);
                    material.envMap = this.getTextureJson(child.material.envMap);
                    material.lightMap = this.getTextureJson(child.material.lightMap);
                    material.lightMapIntensity = child.material.lightMapIntensity;
                    material.map = this.getTextureJson(child.material.map);
                    material.refractionRatio = child.material.refractionRatio;

                    child.material.emissive.toArray(material.emissive);
                    material.emissiveMap = this.getTextureJson(child.material.emissiveMap);
                    material.emissiveIntensity = child.material.emissiveIntensity;
                    material.envMapIntensity = child.material.envMapIntensity;
                    material.metalness = child.material.metalness;
                    material.metalnessMap = this.getTextureJson(child.material.metalnessMap);
                    material.normalMap = this.getTextureJson(child.material.normalMap);
                    material.roughness = child.material.roughness;
                    material.roughnessMap = this.getTextureJson(child.material.roughnessMap);
                    break;
                case MaterialObject.MaterialType.e_PHONE:
                    material.aoMap = this.getTextureJson(child.material.aoMap);
                    material.aoMapIntensity = child.material.aoMapIntensity;
                    child.material.color.toArray(material.color);
                    material.envMap = this.getTextureJson(child.material.envMap);
                    material.lightMap = this.getTextureJson(child.material.lightMap);
                    material.lightMapIntensity = child.material.lightMapIntensity;
                    material.map = this.getTextureJson(child.material.map);
                    material.shininess  = child.material.shininess;
                    material.reflectivity = child.material.reflectivity;
                    material.refractionRatio = child.material.refractionRatio;
                    child.material.emissive.toArray(material.emissive);
                    material.emissiveMap = this.getTextureJson(child.material.emissiveMap);
                    material.emissiveIntensity = child.material.emissiveIntensity;
                    material.specularMap = this.getTextureJson(child.material.specularMap);
                    child.material.specular.toArray(material.specular);
                    material.normalMap = this.getTextureJson(child.material.normalMap);
                    break;
                case MaterialObject.MaterialType.e_TOON_PHONE:
                case MaterialObject.MaterialType.e_TOON_PHONE_TWIST:
                    material.aoMap = this.getTextureJson(child.material.aoMap);
                    material.aoMapIntensity = child.material.aoMapIntensity;
                    child.material.color.toArray(material.color);

                    ///toon
                    material.matcap = this.getTextureJson(child.material.matcap);
                    material.matcapIntensity = child.material.matcapIntensity;
                    material.gradientMap = this.getTextureJson(child.material.gradientMap);

                    material.lightMap = this.getTextureJson(child.material.lightMap);
                    material.lightMapIntensity = child.material.lightMapIntensity;
                    material.map = this.getTextureJson(child.material.map);
                    material.shininess  = child.material.shininess;
                    material.reflectivity = child.material.reflectivity;
                    material.refractionRatio = child.material.refractionRatio;
                    child.material.emissive.toArray(material.emissive);
                    material.emissiveMap = this.getTextureJson(child.material.emissiveMap);
                    material.emissiveIntensity = child.material.emissiveIntensity;
                    material.specularMap = this.getTextureJson(child.material.specularMap);
                    child.material.specular.toArray(material.specular);
                    material.normalMap = this.getTextureJson(child.material.normalMap);
                    break;
            }

        }
        return material;
    }

    getMaterialFromJson(object, element, manager = null) { 
        if (element.type == MaterialObject.MaterialType.e_BASIC) {
            object.material.reflectivity = element.reflectivity;
            this.getTextureFromJson(object.material.specularMap, element.specularMap, function (res) {
                object.material.specularMap = res;
                object.material.needsUpdate = true;
                if(object.material.specularMap.userData.m_gamma == undefined)
                {
                    object.material.specularMap.userData.m_gamma = 1;
                }
            }, manager);
            this.getTextureFromJson(object.material.envMap, element.envMap, function (res) {
                object.material.envMap = res;
                object.material.needsUpdate = true;
                if(object.material.envMap.userData.m_gamma == undefined)
                {
                    object.material.envMap.userData.m_gamma = 1;
                }
            }, manager);
        }
        else if(element.type == MaterialObject.MaterialType.e_PHONE)
        {
            object.material.shininess = element.shininess;
            object.material.reflectivity = element.reflectivity;
            object.material.refractionRatio = element.refractionRatio;

            object.material.specular.fromArray(element.specular);

            this.getTextureFromJson(object.material.specularMap, element.specularMap, function (res) {
                object.material.specularMap = res;
                object.material.needsUpdate = true;
                if(object.material.specularMap.userData.m_gamma == undefined)
                {
                    object.material.specularMap.userData.m_gamma = 1;
                }
            }, manager);

            object.material.emissive.fromArray(element.emissive);
            this.getTextureFromJson(object.material.emissiveMap, element.emissiveMap, function (res) {
                object.material.emissiveMap = res;
                object.material.emissiveMap.encoding = THREE.sRGBEncoding;
                object.material.needsUpdate = true;
                if(object.material.emissiveMap.userData.m_gamma == undefined)
                {
                    object.material.emissiveMap.userData.m_gamma = 1;
                }
            }, manager);
            object.material.emissiveIntensity = element.emissiveIntensity;
            object.material.envMapIntensity = element.envMapIntensity;
            this.getTextureFromJson(object.material.envMap, element.envMap, function (res) {
                object.material.envMap = res;
                object.material.needsUpdate = true;
                if(object.material.envMap.userData.m_gamma == undefined)
                {
                    object.material.envMap.userData.m_gamma = 1;
                }
            }, manager);
            this.getTextureFromJson(object.material.normalMap, element.normalMap, function (res) {
                object.material.normalMap = res;
                object.material.needsUpdate = true;
                if(object.material.normalMap.userData.m_gamma == undefined)
                {
                    object.material.normalMap.userData.m_gamma = 1;
                }
            }, manager);
        }
        else if(element.type == MaterialObject.MaterialType.e_TOON_PHONE || element.type == MaterialObject.MaterialType.e_TOON_PHONE_TWIST)
        {
            object.material.shininess = element.shininess;
            object.material.reflectivity = element.reflectivity;
            object.material.refractionRatio = element.refractionRatio;

            object.material.specular.fromArray(element.specular);

            this.getTextureFromJson(object.material.specularMap, element.specularMap, function (res) {
                object.material.specularMap = res;
                object.material.needsUpdate = true;
                if(object.material.specularMap.userData.m_gamma == undefined)
                {
                    object.material.specularMap.userData.m_gamma = 1;
                }
            }, manager);

            object.material.emissive.fromArray(element.emissive);
            this.getTextureFromJson(object.material.emissiveMap, element.emissiveMap, function (res) {
                object.material.emissiveMap = res;
                object.material.emissiveMap.encoding = THREE.sRGBEncoding;
                object.material.needsUpdate = true;
                if(object.material.emissiveMap.userData.m_gamma == undefined)
                {
                    object.material.emissiveMap.userData.m_gamma = 1;
                }
            }, manager);
            object.material.emissiveIntensity = element.emissiveIntensity;
      
            this.getTextureFromJson(object.material.normalMap, element.normalMap, function (res) {
                object.material.normalMap = res;
                object.material.needsUpdate = true;
                if(object.material.normalMap.userData.m_gamma == undefined)
                {
                    object.material.normalMap.userData.m_gamma = 1;
                }
            }, manager);

                  
            this.getTextureFromJson(object.material.gradientMap, element.gradientMap, function (res) {
                object.material.gradientMap = res;
                object.material.needsUpdate = true;
                if(object.material.gradientMap.userData.m_gamma == undefined)
                {
                    object.material.gradientMap.userData.m_gamma = 1;
                }
            }, manager);
            this.getTextureFromJson(object.material.matcap, element.matcap, function (res) {
                object.material.matcap = res;
                object.material.needsUpdate = true;
                if(object.material.matcap.userData.m_gamma == undefined)
                {
                    object.material.matcap.userData.m_gamma = 1;
                }
            }, manager);
            object.material.matcapIntensity = element.matcapIntensity;
        }
        else if (element.type == MaterialObject.MaterialType.e_STANDARD) {
            object.material.emissive.fromArray(element.emissive);
            this.getTextureFromJson(object.material.emissiveMap, element.emissiveMap, function (res) {
                object.material.emissiveMap = res;
                object.material.emissiveMap.encoding = THREE.sRGBEncoding;
                object.material.needsUpdate = true;
                if(object.material.emissiveMap.userData.m_gamma == undefined)
                {
                    object.material.emissiveMap.userData.m_gamma = 1;
                }
            }, manager);
            object.material.emissiveIntensity = element.emissiveIntensity;
            object.material.envMapIntensity = element.envMapIntensity;
            this.getTextureFromJson(object.material.envMap, element.envMap, function (res) {
                object.material.envMap = res;
                object.material.needsUpdate = true;
                if(object.material.envMap.userData.m_gamma == undefined)
                {
                    object.material.envMap.userData.m_gamma = 1;
                }
            }, manager);
            object.material.metalness = element.metalness;

            this.getTextureFromJson(object.material.metalnessMap, element.metalnessMap, function (res) {
                object.material.metalnessMap = res;
                object.material.needsUpdate = true;
                if(object.material.metalnessMap.userData.m_gamma == undefined)
                {
                    object.material.metalnessMap.userData.m_gamma = 1;
                }
            }, manager);

            this.getTextureFromJson(object.material.normalMap, element.normalMap, function (res) {
                object.material.normalMap = res;
                object.material.needsUpdate = true;
                if(object.material.normalMap.userData.m_gamma == undefined)
                {
                    object.material.normalMap.userData.m_gamma = 1;
                }
            }, manager);

            object.material.roughness = element.roughness;
            this.getTextureFromJson(object.material.roughnessMap, element.roughnessMap, function (res) {
                object.material.roughnessMap = res;
                object.material.needsUpdate = true;
                if(object.material.roughnessMap.userData.m_gamma == undefined)
                {
                    object.material.roughnessMap.userData.m_gamma = 1;
                }
            }, manager);

            object.material.userData.setMaterialMatType(element.matType);
            object.material.userData.setMaterialMat(element.materialMat);
        }
        if (object.material != null) {
            this.getTextureFromJson(object.material.aoMap, element.aoMap, function (res) {
                object.material.aoMap = res;
                object.material.needsUpdate = true;
                if(object.material.aoMap.userData.m_gamma == undefined)
                {
                    object.material.aoMap.userData.m_gamma = 1;
                }
            }, manager);

            object.material.aoMapIntensity = element.aoMapIntensity;
            object.material.color.fromArray(element.color);

            this.getTextureFromJson(object.material.lightMap, element.lightMap, function (res) {
                object.material.lightMap = res;
                object.material.needsUpdate = true;
                if(object.material.lightMap.userData.m_gamma == undefined)
                {
                    object.material.lightMap.userData.m_gamma = 1;
                }
            }, manager);

            object.material.lightMapIntensity = element.lightMapIntensity;

            this.getTextureFromJson(object.material.map, element.map, function (res) {
                object.material.map = res;
                object.material.map.encoding = THREE.sRGBEncoding;
                object.material.needsUpdate = true;
                if(object.material.map.userData.m_gamma == undefined)
                {
                    object.material.map.userData.m_gamma = 2.2;
                }
            }, manager);

            object.material.refractionRatio = element.refractionRatio;
            object.material.fog = element.fog;
            object.material.opacity = element.opacity;
            object.material.side = element.side;
            object.material.transparent = element.transparent;
            object.material.needsUpdate = true;
        }
    }

    addUndoSelectMeshMaterial(objects, mode, undoData = null, end = false) {
        var _this = this;
        var _data = {
            objectNames: new Array(),
            objectIds: new Array(),
            mode: mode,
        };
        const length = objects.length;
        for (let index = 0; index < length; index++) {
            const obj = objects[index];
            _data.objectNames.push(obj.name);
            _data.objectIds.push(obj.id);
        }

        if (end) {
            MainObjects.Blueprint.m_historyListenerBp.addUndoRedo(undoData, _data, function (data) {
                for (let index = 0; index < data.objectNames.length; index++) {
                    const id = data.objectIds[index];
                    const obj = MainObjects.Scene.m_userGroup.getObjectById(id);
                    _this.changeMaterial(obj, data.mode);
                }
            });
        }
        return _data;
    }

    updateSceneMaterial(key, name, id, json)
    {
        var _this = this;
        var manager = new THREE.LoadingManager();
        manager.onProgress = function (url, itemsLoaded, itemsTotal) {

        };

        manager.onError = function (url) {
            console.log('There was an error loading ' + url);
        };

        manager.onLoad = function () {
            console.log('Loading complete!');
            var gameScene = MainObjects.Blueprint.m_sceneManagerBp.m_gameScene;
            var material = gameScene.m_matRepos.get(key);

            if(material != undefined && material != null)
            {
                if (json.type == MaterialObject.MaterialType.e_STANDARD) {
                    MaterialFactory.createStandardMaterial(material, MaterialObject.MaterialEditorType.e_NET, key, name, id);
                }
                else if (json.type == MaterialObject.MaterialType.e_BASIC){
                    MaterialFactory.createBasicMaterial(material, MaterialObject.MaterialEditorType.e_NET, key, name, id);
                }
                else if (json.type == MaterialObject.MaterialType.e_PHONE){
                    MaterialFactory.createPhoneMaterial(material, MaterialObject.MaterialEditorType.e_NET, key, name, id);
                }
                else if (json.type == MaterialObject.MaterialType.e_TOON_PHONE){
                    MaterialFactory.createToonPhoneMaterial(material, MaterialObject.MaterialEditorType.e_NET, key, name, id);
                }
                else if (json.type == MaterialObject.MaterialType.e_TOON_PHONE_TWIST){
                    MaterialFactory.createToonPhoneTwistMaterial(material, MaterialObject.MaterialEditorType.e_NET, key, name, id);
                }

                var object = {
                    material : material,
                }
                _this.getMaterialFromJson(object, json, manager);
            }
        };
        Saver.m_resMap.clear();
        Saver.getMaterialFromJson(json);
        if (Saver.m_resMap.size > 0) {
            

            Saver.loadFileToScene(manager);
        }
        else {
            manager.onLoad();
        } 
    }
}

export { MaterialEditorBp };