<template>
  <div>
    <el-color-picker :value="processedColor" @change="handleColorChange" :show-alpha="true" color-format="rgb"  popper-class="picker"></el-color-picker>
  </div>

</template>
<script>

import {Vector4} from "three";

export default {
  props: {
    color: Object,
    onChange: Function
  },
  data() {
    return {
      processedColor: ''

    }
  },
  watch: {
    color: {
      handler(newVal) {
        console.log(newVal,'props color')

        const {w, x, y, z} = newVal
        this.$data.processedColor = `rgba(${255 * x}, ${255 * y},${255 * z},${w})`
      },
      immediate: true
    }
  },
  methods: {
    handleColorChange(value) {
      const result = this.deProcessedColor(value)
      console.log(value,result)

      this.$props.onChange(result)

    },
    deProcessedColor(rgbaString) {
      const rgbaValues = rgbaString.match(/rgba\((\d+),\s*(\d+),\s*(\d+),\s*([\d.]+)\)/);

      if (rgbaValues) {
        const [, x, y, z, w] = rgbaValues;
        console.log('x:', x);
        console.log('y:', y);
        console.log('z:', z);
        console.log('w:', w);
        return new Vector4( parseInt(x) / 255, parseInt(y) / 255 , parseInt(z) / 255, parseFloat(w))
      } else {
        console.log('Invalid RGBA string');
        return new Vector4()
      }

    }
  },
}
</script>
