<template>
  <div :style="{width: '100%'}">
    <div class="property">
      <div class="name">{{ t('85d80') }}</div>
      <el-select @change="onChangeLooping"
                   :value="particleSystem?.looping">
        <el-option
          v-for="item in optionsTF"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
    </div>
    <div class="property">
      <div  class="name">{{ t('f8a11') }}</div>
      <NumberInput :onChange="onChangeDuration"
                   :value="particleSystem?.duration"
                   variant="short"/>
    </div>
<!--    被其他系统使用-->
<!--    <div class="property">-->
<!--      <div  class="name">{{ t('3a195') }}</div>-->
<!--      <el-select :onChange="onChangeOnlyUsedByOther"-->
<!--                   :value="particleSystem?.onlyUsedByOther">-->
<!--        <el-option-->
<!--            v-for="item in [{value:true,label: t('true')}, {value:false,label: t('false')}]"-->
<!--            :key="item.value"-->
<!--            :label="item.label"-->
<!--            :value="item.value">-->
<!--        </el-option>-->
<!--      </el-select>-->
<!--    </div>-->
    <GeneratorEditor :name="'Emit Over Time'"
                     :allowedType="valueFunctionTypes"
                     :value="particleSystem?.emissionOverTime"
                     :onChange="onChangeEmissionOverTime"/>
    <GeneratorEditor :name="'Emit Over Distance'"
                     :allowedType="valueFunctionTypes"
                     :value="particleSystem?.emissionOverDistance"
                     :onChange="onChangeEmissionOverDistance"/>
    <div class="property emission-property">
      <div class="name">{{ t('0a0c6') }}
        <i class="el-icon-plus"  @click="addBurst">
        </i>
      </div>
    </div>
    <div>
      <div v-for="(burst, index) in particleSystem?.emissionBursts">
        <BurstEmitterEditor :key="index"
                            :params="burst"
                            :onDelete="() => deleteBurst(index)"
                            :updateProperties="editTime"/>
      </div>
    </div>
  </div>
</template>
<script>
import {t} from "@/util/lang";
import NumberInput from "@/views/component/NumberInput.vue";
import GeneratorEditor from "@/views/ParticleSystem/Editors/GeneratorEditor.vue";
import BurstEmitterEditor from "@/views/ParticleSystem/Editors/BurstEmitterEditor.vue";

export default {
  components: {BurstEmitterEditor, GeneratorEditor, NumberInput},
  props: {
    particleSystem: Object,
    updateProperties: Function
  },
  data() {
    return {
      valueFunctionTypes: ['value', 'valueFunc'],
      optionsTF: [{value:true,label: t('true')}, {value:false,label: t('false')}]

    }
  },
  methods: {
    t,
    addBurst(event) {
      const props = this.$props
      props.particleSystem.emissionBursts.push({
        time: 0,
        count: 10,
        cycle: 0,
        interval: 0,
        probability: 1
      });
      props.updateProperties();
    },

    deleteBurst(index) {
      const props = this.$props

      props.particleSystem.emissionBursts.splice(index, 1);
      props.updateProperties();
    },

    editTime() {
      const props = this.$props

      props.particleSystem.emissionBursts.sort((a, b) => a.time - b.time);
      props.updateProperties();
    },

    onChangeLooping(value) {
      const props = this.$props

      props.particleSystem.looping = value;
      props.updateProperties();
    },
    onChangeDuration(value) {
      const props = this.$props

      props.particleSystem.duration = value;
      props.updateProperties();
    },
    onChangeOnlyUsedByOther(value) {
      const props = this.$props

      props.particleSystem.onlyUsedByOther = value;
      props.updateProperties();
    },

    onChangeEmissionOverTime(g) {
      const props = this.$props

      props.particleSystem.emissionOverTime = g
      props.updateProperties();
    },

    onChangeEmissionOverDistance(g) {
      const props = this.$props

      props.particleSystem.emissionOverDistance = g
      props.updateProperties();
    },
  }
}
</script>
<style lang="less">

.emission-property {
  font-weight: 700;
  .el-icon-plus {
    margin-top: 30px;
    font-size: 20px;
  }
  .name {
    width: 200px;
    white-space: nowrap;
  }
}

</style>