<template>
  <div class="code">
    <prism-editor
      class="my-editor"
      v-model="code"
      :highlight="highlighter"
      line-numbers
      @focus="Focus"
      @blur="Blur"
    ></prism-editor>
    <div class="save" @click="saveChange">保存</div>



  </div>
</template>
<script>
import { ISetting } from "../../threejs/interface/ISetting";
import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism-tomorrow.css";
import { INetwork } from "../../threejs/interface/INetwork";
export default {
  data() {
    return {
      code: 'console.log("Hello World")',
      //js文件id
      jsFileId:''
    };
  },

  components: {
    PrismEditor,
    languages,
    highlight
  },
  mounted() {
    
  },
  methods: {
    highlighter(code) {
      return highlight(code, languages.js);
    },
    saveChange() {
      // console.log('保存的东西：',this.code);
      var data = INetwork.uploadJsTSql(this.jsFileId,this.code , () => {
        // console.log('js传完成')
      });

      this.code=""
      this.jsFileId=""
       this.$parent.close();
    },
    Focus() {
      ISetting.isInput(true);
    },
    Blur() {
      ISetting.isInput(false);
    },
    //设置code
    setCode(msg) {
      this.code = msg;
    },
    //设置jsId
    setJsFileId(msg) {
      this.jsFileId = msg;
    }
  }
};
</script>
<style scoped>
.save {
  width: 140px;
  height: 40px;
  line-height: 40px;
  background: rgba(5, 96, 253, 1);
  border-radius: 10px;
  text-align: center;
  font-size: 14px;
  position: absolute;
  left: 50%;
  bottom: 25px;
  transform: translate(-50%);
  color: #fff;
  cursor: pointer;
}
.my-editor {
  width: 1250px;
  height: 650px;
  background: #282c34;
  color: #ccc;
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
  border-radius: 12px;
}
.my-editor::-webkit-scrollbar {
  display: none;  
}
.my-editor{
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.prism-editor__textarea:focus {
  outline: none;
}
</style>