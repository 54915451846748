<template>
    <div class="container">
        <div class="service_box" :class="active?'unfold':'shrink'">
            <div class="header">
               <div @click="unfoldChange"><i class="el-icon-arrow-down"></i> </div>
               <p>服务设置</p>
           </div>
           <div class="start">
               <div class="title">启动</div>
               <div class="ipt_box">
                   <input class="input" @change="startChange($event)" type="checkbox" />
               </div>
           </div>
           <div class="play">
                <div class="title">数据</div>
                <!-- <div @click="renderChange">
                    <div class="not">全部同步</div>
                </div> -->
                <!-- <div @click="renderLight">
                    <div class="not">同步灯光</div>
                </div> -->
                <div @click="renderCamera">
                    <div class="not">渲染同步</div>
                </div>
                <!-- <div @click="renderObject">
                    <div class="not">同步模型及灯光</div>
                </div> -->
            </div>
           <div class="log">
               <div class="title">日志</div>
               <div class="log_box">
                   <!-- <input class="log" type="text" /> -->
                   <textarea name="" id="textarea" @change="textAreacChange" @keyup="keyUp" :value="logTxt"></textarea>
               </div>
           </div>
           <div class="render1">
                <div class="title">IP地址</div>
                <div class="txt_box">
                    <input type="text"  name="" id="" v-model="ipAddress" @change="ipChange($event)">
                </div>
                <div class="title">端口号</div>
                <div class="txt_box">
                    <input type="text"  name="" id="" v-model="port" @change="PortChange($event)">
                </div>
            </div>
        </div>
    
        <div class="boxOne" :class="cur?'anmDiv':'aaa'">
           <div class="header">
               <div @click="curChange"><i class="el-icon-arrow-down"></i> </div>
               <p>渲染环境设置</p>
           </div>
           <div>
               <div class="img_box">
                   <p>HDR</p>
                   <div class="list1">
                        <div class="fileBox">
                            <input type="file" value="选择文件" ref="file" @change="fileChange()" style="filter: alpha(opacity=0); opacity: 0; width: 0; height: 0"/>
                            <div @click="btnChange()" class="file">选择文件</div>
                            <div class="txt">{{this.fileName}}</div>
                        </div>
                    </div>
               </div>
               <!-- <div class="img_box">
                   <p>背景</p>
                   <div class="list" @click="imgListChange">
                        <img v-show="img0" :src="image" alt="">
                    </div>
               </div> -->
               <div class="img_hdr" v-show="hdr">
                   <img v-for="(item,index) in hdrList" :key="index" :src="item" alt="" @click="imgChange(index)">
               </div>
                <div class="metallicity">
                    <div class="title">角度</div>
                    <div class="slider">
                        <el-slider v-model="rot" @change="rotChange()" show-input :min="0" :max="360" :show-tooltip="false"></el-slider>
                    </div>
                    <!-- <div class="fov_box">
                         <input class="ipt" type="text" readonly="readonly" v-model="rot" />
                    </div> -->
                </div>
                <div class="render">
                    <div class="title">强度</div>
                    <div class="txt_box">
                        <input type="number"  name="" id="" v-model="gain" @change="ChangeNum($event)">
                    </div>
                </div>
            </div>
        </div>
        <div class="box" :class="isActive?'anmDiv':'aaa'">
            <div class="header">
                <div @click="shrinkChange"><i class="el-icon-arrow-down"></i> </div>
                <p>渲染设置</p>
            </div>
           <div>
                 <div class="render">
                     <div class="title">采样</div>
                     <div class="txt_box">
                         <input type="number"  name="" id="" @change="ChangeNum($event)" v-model="haltspp">
                     </div>
                 </div>
                 <div class="render">
                     <div class="title">总反射</div>
                     <div class="txt_box">
                         <input type="number"  name="" id="" @change="ChangeNum($event)" v-model="total">
                     </div>
                 </div>
                 <div class="render">
                     <div class="title">漫反射</div>
                     <div class="txt_box">
                         <input type="number"  name="" id="" @change="ChangeNum($event)" v-model="diffuse">
                     </div>
                 </div>
                 <div class="render">
                     <div class="title">光泽反射</div>
                     <div class="txt_box">
                         <input type="number"  name="" id="" @change="ChangeNum($event)" v-model="glossy">
                     </div>
                 </div>
                 <div class="render">
                     <div class="title">镜面反射</div>
                     <div class="txt_box">
                         <input type="number"  name="" id="" @change="ChangeNum($event)" v-model="specular">
                     </div>
                 </div>
             </div>
         </div>
    </div>
</template>
<script>
import { ISetting } from "../../threejs/interface/ISetting";
import { INetwork } from "../../threejs/interface/INetwork"
import { IRender } from "../../threejs/interface/IRender";
import hdr0 from "../../assets/hdr/1347_v5.png";
import hdr1 from "../../assets/hdr/1927_v5.png";
import hdr2 from "../../assets/hdr/canary_wharf_2k.png";
import hdr3 from "../../assets/hdr/kloofendal_48d_partly_cloudy_2k.png";
import hdr4 from "../../assets/hdr/kloppenheim_04_2k.png";
import hdr5 from "../../assets/hdr/misty_pines_2k.png";
import hdr6 from "../../assets/hdr/schadowplatz_2k.png";
import hdr7 from "../../assets/hdr/vatican_road_2k.png";
import hdr8 from "../../assets/hdr/venetian_crossroads_2k.png";

export default {
    data(){
        return{
            isActive:true,
            active:true,
            cur:true,
            play:true,//播放
            logTxt:"",
            select:"",
            hdr:false,
            image:'',
            optionList:["0","1","2","3","4","5","6","7","8"],
            hdrList:[hdr0,hdr1,hdr2,hdr3,hdr4,hdr5,hdr6,hdr7,hdr8],
            img0:true,img1:false,img2:false,img3:false,img4:false,img5:false,img6:false,img7:false,img8:false,
            rot:0,//角度
            haltspp:"",//采样
            total:"",//总反射
            diffuse:"",//漫反射
            glossy:"",//光泽反射
            specular:"",//镜面反射
            gain:"",//强度
            env_image:"",
            port:"",//端口号
            fileName:"",
            ipAddress:"",//ip地址
        }
    },
    mounted(){
        IRender.f_renderLogCallback = this.LogCallback.bind(this)
        this.select = this.optionList[0];
        // this.hdrSelect();
    },
    components:{
        
    },
    methods:{
        RenderValueChange(){
           let getRenderValueList =  IRender.getRenderValue();
        //    console.log(getRenderValueList)
           this.rot = getRenderValueList.m_env_rot;
           this.haltspp = getRenderValueList.m_haltspp;
           this.total = getRenderValueList.m_total;
           this.diffuse = getRenderValueList.m_diffuse;
           this.glossy = getRenderValueList.m_glossy;
           this.specular = getRenderValueList.m_specular;
           this.gain = getRenderValueList.m_env_gain;
           this.env_image = getRenderValueList.m_env_image;
           this.fileName = getRenderValueList.m_env_image;
           this.port = IRender.getNetPort();
           this.ipAddress = IRender.getNetIp();
           if(this.env_image == '1347_v5'){
                this.image = hdr0;
           }else if(this.env_image == '1927_v5'){
                this.image = hdr1;
           }else if(this.env_image == 'canary_wharf_2k'){
                this.image = hdr2;
           }else if(this.env_image == 'kloofendal_48d_partly_cloudy_2k'){
                this.image = hdr3;
           }else if(this.env_image == 'kloppenheim_04_2k'){
                this.image = hdr4;
           }else if(this.env_image == 'misty_pines_2k'){
                this.image = hdr5;
           }else if(this.env_image == 'schadowplatz_2k'){
                this.image = hdr6;
           }else if(this.env_image == 'vatican_road_2k'){
                this.image = hdr7;
           }else if(this.env_image == 'venetian_crossroads_2k'){
                this.image = hdr8;
           }
        },
        LogCallback(result){
            this.logTxt = this.logTxt + "\n" +  result;
        },
        shrinkChange(){
            if (this.isActive) {
              this.isActive = false
            } else {
              this.isActive = true;
            }
        },
        unfoldChange(){
            if (this.active) {
              this.active = false
            } else {
              this.active = true;
            }
        },
        curChange(){
            if (this.cur) {
              this.cur = false
            } else {
              this.cur = true;
            }
        },
        textAreacChange(){
            let txt = document.getElementById("textarea");
            // console.log(txt.value)
            ISetting.isInput(false);
        },
        keyUp(){
            ISetting.isInput(true);
        },
        //是否启动
        startChange(event){
            let checked = event.target.checked;//选中状态
            if(checked ==false){
                this.logTxt = " "
            }
            INetwork.startSocket(checked);
        },
        //数据
        renderChange(){
            this.logTxt = " ";
            IRender.synchronization(0)
        },
        renderLight(){
            this.logTxt = " ";
            IRender.synchronization(1)
        },
        renderObject(){
            this.logTxt = " ";
            IRender.synchronization(2)
        },
        renderCamera(){
            this.logTxt = " ";
            IRender.synchronization()
        },
        //端口号
        PortChange(){
            IRender.setNetPort(this.port)
        },
        //IP地址
        ipChange(){
            IRender.setNetIp(this.ipAddress)
        },
        rotChange(){
            let data = {
                m_env_rot:Number(this.rot),
                m_haltspp:Number(this.haltspp),
                m_total:Number(this.total),
                m_diffuse:Number(this.diffuse),
                m_glossy:Number(this.glossy),
                m_specular:Number(this.specular),
                m_env_gain:Number(this.gain),
                m_env_image:this.env_image
            }
            IRender.setRenderValue(data);
        },
        imgListChange(){
            this.hdr = !this.hdr;
            if(this.hdr){
                this.$parent.setDragShow(false);
            }else{
                this.$parent.setDragShow(true);
            }
        },
        imgChange(index){
            this.image = this.hdrList[index]
            this.hdr = false;
            // this.$parent.setDragShow(true);
            if(index == 0){
                this.env_image = "1347_v5";
            }else if(index == 1){
                this.env_image = "1927_v5"
            }else if(index == 2){
                this.env_image = "canary_wharf_2k"
            }else if(index == 3){
                this.env_image = "kloofendal_48d_partly_cloudy_2k"
            }else if(index == 4){
                this.env_image = "kloppenheim_04_2k"
            }else if(index == 5){
                this.env_image = "misty_pines_2k"
            }else if(index == 6){
                this.env_image = "schadowplatz_2k"
            }else if(index == 7){
                this.env_image = "vatican_road_2k"
            }else if(index == 8){
                this.env_image = "venetian_crossroads_2k"
            }
            let data = {
                m_env_rot:Number(this.rot),
                m_haltspp:Number(this.haltspp),
                m_total:Number(this.total),
                m_diffuse:Number(this.diffuse),
                m_glossy:Number(this.glossy),
                m_specular:Number(this.specular),
                m_env_gain:Number(this.gain),
                m_env_image:this.env_image,
            }
            IRender.setRenderValue(data);
        },
        ChangeNum(){
            let data = {
                m_env_rot:Number(this.rot),
                m_haltspp:Number(this.haltspp),
                m_total:Number(this.total),
                m_diffuse:Number(this.diffuse),
                m_glossy:Number(this.glossy),
                m_specular:Number(this.specular),
                m_env_gain:Number(this.gain),
                m_env_image:this.env_image,
            }
            IRender.setRenderValue(data);
        },
        fileChange(){
            if(this.$refs.file.files.length > 0 ){
                this.fileName = this.$refs.file.files[0].name;   
            }
            const extension = this.fileName.split('.').pop().toLowerCase();
            this.fileName =this.fileName.replace("." + extension, "");
            let data = {
                m_env_rot:Number(this.rot),
                m_haltspp:Number(this.haltspp),
                m_total:Number(this.total),
                m_diffuse:Number(this.diffuse),
                m_glossy:Number(this.glossy),
                m_specular:Number(this.specular),
                m_env_gain:Number(this.gain),
                m_env_image:this.fileName,
            }
            IRender.setRenderValue(data);
        },
        btnChange(){
           this.$refs.file.click()
        }
    }
}
</script>
<style scoped>
.container{
    width: 100%;
    height: 1500px;
}
.box{
    width: 80%;
    height: 350px;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    border-radius: 5px;
    margin-top: 20px;
    margin-left:70px;
    font-size: 10px;
    background: #ffff;
    overflow: hidden;
    margin-bottom: 50px;
}
.service_box{
    width: 80%;
    height: 350px;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    border-radius: 5px;
    margin-top: 20px;
    margin-left:70px;
    font-size: 10px;
    background: #ffff;
    overflow: hidden;
}
.boxOne{
    width: 80%;
    height: 300px;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    border-radius: 5px;
    margin-top: 20px;
    margin-left:70px;
    font-size: 10px;
    background: #ffff;
    overflow: hidden;
}
.anmDiv{
transition: 1S;
 
}
.aaa{
height: 40px;
transition: 1S;
}
.unfold{
transition: 1S;
 
}
.shrink{
height: 40px;
transition: 1S;
}
.header{
    display: flex;
    width: 90%;
    height: 40px;
    margin: auto;
    align-items: center;
    color: #000;
    font-weight: bold;
    font-size: 12px;
}
.header p{
    margin-left: 10px;
    overflow: hidden;
}
.header i{
    font-weight: bold;
    cursor: pointer;
}
.render{
    display: flex;
    width: 100%;
    height: 30px;
    align-items: center;
    margin-top: 25px;
}
.render .title{
    width: 95px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-weight: bold;
    overflow: hidden;
}
.render .txt_box{
    width: 50%;
    height: 30px;
    box-shadow: 0px 0px 2px 0px rgba(33, 35, 49, 0.1);
    border-radius: 6px;
    display: flex;
    align-items: center;
}
.render input{
    width: 95%;
    height: 20px;
    outline: none;
    color: #808080;
    border: none;
    border-radius:4px ;
    text-align: center;
}

.render1{
    display: flex;
    width: 100%;
    height: 30px;
    align-items: center;
    margin-top: 25px;
}
.render1 .title{
    margin-left: 25px;
    width: 80px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-weight: bold;
    overflow: hidden;
}
.render1 .txt_box{
    width: 25%;
    height: 30px;
    box-shadow: 0px 0px 2px 0px rgba(33, 35, 49, 0.1);
    border-radius: 6px;
    display: flex;
    align-items: center;
}
.render1 input{
    width: 95%;
    height: 20px;
    outline: none;
    color: #808080;
    border: none;
    border-radius:4px ;
    text-align: center;
}

.start{
    display: flex;
    width: 90%;
    height: 20px;
    align-items: center;
    margin: 25px auto;
}
.start .title{
    width: 60px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-weight: bold;
    overflow: hidden;
}
.start .ipt_box{
    width: 60px;
    height: 28px;
    background: #FFFFFF;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
}
.log{
    display: flex;
    width: 90%;
    /* height: 150px; */
}
.log .title{
    width: 115px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-weight: bold;
    overflow: hidden;
}
.log_box{
    width: 80%;
    height: 120px;
    background: #FFFFFF;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    border-radius: 6px;
}
.log textarea{
    width: 100%;
    height: 95%;
    outline: none;
    border: none;
    border-radius:6px;
    color: #808080;
    resize:none;
    scrollbar-width: none;
    
}
textarea::-webkit-scrollbar {
  /*滚动条整体样式*/
  width : 8px;  
  height: 1px;
  }
textarea::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 8px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background:#BEC2C9;
  }
textarea::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  /* box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2); */
  border-radius: 10px;
  background:#eff3f4;
}
.input:checked {
	background:#1673ff
}
.input {
	width:16px;
	height:16px;
    outline: none;
	background-color:rgba(125, 128, 133, 0.4);
	border:solid 1px #dddddd;
	-webkit-border-radius:50%;
	border-radius:50%;
	font-size:0.8rem;
	padding:0;
	position:relative;
	display:inline-block;
	vertical-align:top;
	cursor:default;
	-webkit-appearance:none;
	-webkit-user-select:none;
	user-select:none;
	-webkit-transition:background-color ease 0.1s;
	transition:background-color ease 0.1s;
	outline: none;
}
.input:checked::after {
	content:'';
	top:3px;
	left:3px;
	position:absolute;
	background:transparent;
	border:#fff solid 2px;
	border-top:none;
	border-right:none;
	height:4px;
	width:6px;
	-moz-transform:rotate(-45deg);
	-ms-transform:rotate(-45deg);
	-webkit-transform:rotate(-45deg);
	transform:rotate(-45deg);
}
.play{
    display: flex;
    width: 90%;
    height: 30px;
    align-items: center;
    margin:20px auto;
}
.play .title{
    width: 60px;
    height: 30px;
    line-height: 30px;
    background: #fff;
    text-align: center;
    font-weight: bold;
    overflow: hidden;
}
.play .not{
    width: 125px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    margin-left: 15px;
    border-radius: 6px;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    cursor: pointer;
    margin-left: 20px;
}
.not:active{
    background: #1673ff;
    color: #fff;
}
.boxOne .list{
    width: 200px;
    height: 100px;
    margin-top: 20px;
}
.boxOne .list img{
    width: 200px;
    height: 100px;
    cursor: pointer;
}
.boxOne .list1{
    width: 400px;
    height: 50px;
    display: flex;
    align-items: center;
}
.list1 .fileBox{
    display: flex;
    align-items: center;
}
.list1 .file{
    width: 70px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #fff;
    background: #1673ff;
    cursor: pointer;
    border-radius: 4px;
}
.list1 .fileBox .txt{
    margin-left: 10px;
}
.img_box{
    display: flex;
}
.img_box p{
    width: 105px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    font-weight: bold;
}
.select_box{
    width: 270px;
    height: 30px;
    box-shadow: 0px 0px 2px 0px rgba(33, 35, 49, 0.1);
    border-radius: 6px;
    display: flex;
    align-items: center;
}
.select{
    width: 260px;
    height: 28px;
    outline: none;
    color: #808080;
    border: none;
    border-radius:4px;
    margin-left: 10px;
}
.select img{
    width: 100%;
    height: 100%;
}
.metallicity{
    display: flex;
    width: 90%;
    height: 20px;
    align-items: center;
    margin-top: 25px;
}
.metallicity .title{
    width: 110px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-weight: bold;
    overflow: hidden;
}
.fov_box{
    width: 62px;
    height: 30px;
    box-shadow: 0px 0px 2px 0px rgba(33, 35, 49, 0.1);
    border-radius: 6px;
    display: flex;
    align-items: center;
    margin-left: 10px;
}
.metallicity .ipt{
    width: 60px;
    height: 28px;
    border-radius: 6px;
    outline: none;
    border: none;
    color: #929292;
}
.slider{
    width: 350px;
    /* margin-left: 15px; */
}
.img_hdr{
    position: fixed;
    top: 30%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 640px;
    height: 345px;
    background: #f0f0f0;
    border-radius: 5px;
    z-index: 999;
}
.img_hdr img{
    width: 200px;
    height: 100px;
    margin-left: 10px;
    margin-top: 10px;
    cursor: pointer;
}
</style>