<template>
  <div class="SharedLibrary">

    <div class="sequenceList">
      <el-tabs v-model="activeName" @tab-click="handleClick" type="card">



        <el-tab-pane v-for="item in warehouseList" :warehouse="item" :key="item.id" :label="item.name" :name="item.id">
          <div slot="label" class="warehouseTitle" @contextmenu.prevent="rightClick(item)">
            <div class="selectBg">

              <img v-if="item.type=='0'&&$store.state.chosenWarehouse.id!=item.id" src="../../assets/sharedFile/privateIcon.png" alt="">
              <img v-else-if="item.type=='0'&&$store.state.chosenWarehouse.id==item.id" src="../../assets/sharedFile/selectPrivateIcon.png" alt="">

              <img v-else-if="item.type==1&&$store.state.chosenWarehouse.id!=item.id" src="../../assets/sharedFile/shared.png" alt="">

              <img v-else-if="item.type==1&&$store.state.chosenWarehouse.id==item.id" src="../../assets/sharedFile/selectShared.png" alt="">


              <img v-else-if="item.type==2&&$store.state.chosenWarehouse.id!=item.id" src="../../assets/sharedFile/OthersLibrary.png" alt="">
              <img v-else-if="item.type==2&&$store.state.chosenWarehouse.id==item.id" src="../../assets/sharedFile/selectOthersLibrary.png" alt="">




              {{ item.name }}



            </div>

            <img v-if="item.type=='0'" src="../../assets/sharedFile/privateIcon.png" alt="">

            <img v-else-if="item.type==1" src="../../assets/sharedFile/OthersLibrary.png" alt="">

            <img v-else-if="item.type==2" src="../../assets/sharedFile/shared.png" alt="">




            {{ item.name }}




          </div>

        </el-tab-pane>







      </el-tabs>

      <!-- 
      <div class="sequencetable">
          <div class="sequencetableContent">
            <div class="sequenceItem" v-for="item in warehouseList" :warehouse="item"  :key="item.id" >
           
    

                <span>{{item.name}}</span>
        


              </div>
       


          </div>


        </div> -->



    </div>

    <div class="paging">
      <div class="block">
        <div class=""></div>
        <!-- <i class="el-icon-d-arrow-left"></i>
        <i class="el-icon-arrow-left"></i> -->
        <!-- <el-button class="newSequence" :disabled="page<=1" @click="beginningOrend(1,size)" size="mini"
          icon="el-icon-d-arrow-left">
        </el-button> -->
        <div class="cut">
          <el-button class="newSequence" style=" border-color: #fff;  font-size: 0.84635vw;" :disabled="page<=1"
            @click="beginningOrend(page-1,size)" size="mini" icon="el-icon-caret-left">
          </el-button>
          <span>{{page}}/{{total}}</span>
          <!-- <i class="el-icon-arrow-right"></i>
        <i class="el-icon-d-arrow-right"></i> -->
          <el-button class="newSequence" style=" border-color: #fff;  font-size: 0.84635vw;" :disabled="page>=total"
            @click="beginningOrend(page+1,size)" size="mini" icon="el-icon-caret-right">
          </el-button>
        </div>


        <!-- <el-button class="newSequence" :disabled="page>=total" @click="beginningOrend(total,size)" size="mini"
          icon="el-icon-d-arrow-right">
        </el-button> -->
        <el-button v-if="$store.state.UserRank== 3" class="newSequence"
          style="background:#0560FD; border-radius: 0.58594vw;  font-size: 0.78125vw; color: #fff;" size="mini" @click="addTab()"
          icon="el-icon-plus">
        </el-button>
      </div>

    </div>



    <div class="myMenu">
      <div v-show="menu">
        <el-menu id="menu" @select="handleSelect" text-color="#000" class="menu" ref="menu">
          <div
            v-if="(rightClickWarehouse.type==1&&$store.state.UserRank== 3)||(rightClickWarehouse.type==2&&$store.state.UserRank== 3)">



            <el-menu-item index="1" class="menuItem">
              <img src="../../assets/Resource/newName.png" alt class="menuIcon" />
              <span slot="title">重命名</span>
            </el-menu-item>

          </div>

          <div
            v-if="(rightClickWarehouse.type==1&&$store.state.UserRank== 3)||(rightClickWarehouse.type==2&&$store.state.UserRank== 3)">
            <el-menu-item index="2" class="menuItem">
              <img src="../../assets/Resource/icon-67.png" alt class="menuIcon" />
              <span slot="title">删除库</span>
            </el-menu-item>
          </div>
          <div
            v-if="(rightClickWarehouse.type==1&&$store.state.UserRank== 3)||(rightClickWarehouse.type==2&&$store.state.UserRank== 3)">
            <el-menu-item index="3" class="menuItem">
              <img src="../../assets/sharedFile/link.png" alt class="menuIcon" />
              <span slot="title">分享库</span>
            </el-menu-item>
          </div>



        </el-menu>
      </div>
    </div>
    <!-- 分享库 -->
    <el-dialog :visible="shareHistory" width="25%" :modal="true" title="分享库" center append-to-body
      :before-close="shareHistoryClose" top="23vh">

      <el-form :model="ruleForm" status-icon :rules="rules" label-position="left" ref="ruleForm" label-width="5.85938vw"
        class="demo-ruleForm">


        <el-form-item label="分享链接地址" prop="">
          {{url}}

        </el-form-item>


        <el-form-item label="分享库名称" prop="">

          {{rightClickWarehouse.name}}
        </el-form-item>



        <el-form-item label="设置访问密码" prop="password">


          <el-input :disabled="share" v-model="ruleForm.password" placeholder="请输入6位数字组合" maxlength="6"></el-input>

        </el-form-item>


        <el-form-item label="分享链接有效期">


          <el-radio-group v-model="ruleForm.resource" :disabled="share">
            <el-radio label="1">7天</el-radio>
            <el-radio label="2">14天</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item>
          <el-button @click="shareHistoryClose">取消</el-button>

          <el-button type="primary" @click="submitForm('ruleForm')">分享</el-button>
        </el-form-item>
      </el-form>

    </el-dialog>

  </div>
</template>

<script>
import { ISetting } from "../../threejs/interface/ISetting";
export default {
  data() {


    return {
      activeName: '',
      menu: false,
      //分享
      shareHistory: false,
      //库列表
      warehouseList: [],

      //分页-总页数
      total: 10,
      //分页-当前页数
      page: 1,
      //每页几条
      size: 10,
      //右击选中的库
      rightClickWarehouse: {},
      shareUrl: "点击分享后生成链接",
      url: "",
      //是否存到过后台
      share: false,

      //分享数据

      ruleForm: {
        password: "",
        resource: "1",

      },
      rules: {
        password: [
          { required: true, message: '请输入密码', trigger: 'change' }
        ]
      }



    }
  },

  watch: {
    "ruleForm.password": {
      handler(newV, oldV) {
        // console.log(this.ruleForm.imageName, "ruleForm.imageName变化前");

        this.ruleForm.password = this.ruleForm.password.replace(
          /[^\d]/g, ''
        );


        // console.log(this.ruleForm.imageName, "ruleForm.imageName变化后");
      },
      immediate: true
    },
  },
  mounted() {
    // document.onclick = () => {
    //   this.menu = false;
    // };

    let activeName = localStorage.getItem("projectId")
    this.activeName = activeName

    window.addEventListener("click", e => {
      if (this.menu == true) {
        this.menu = false;
        this.$parent.setDragShow(true);
      }
    });

    this.beginningOrend(this.page, this.size).then(() => {
      this.$store.commit("changeChosenWarehouse", this.warehouseList[0])

    }
    )


  },
  methods: {

    handleClick(tab, event) {



      // console.log(tab.name, tab.label,tab.$attrs.warehouse);  
      //设置选中的库
      this.$store.commit("changeChosenWarehouse", tab.$attrs.warehouse)


    },
    //页码切换
    async beginningOrend(page, size) {
      this.page = page
      await this.getWarehouseList(page, size)
    },

    lastOrNext() {

    },

    // 创建共享库
    async addSharedLibrary(name) {
      let projectId = localStorage.getItem("projectId")
      const data = {
        name: name,
        projectId: projectId,
      }

      const res = await this.$https.addSharedLibrary(data)
      if (res.success == true) {
   

        this.getWarehouseList(this.page, this.size)

      }



    },
    // 删除共享库
    async deleteSharedLibrary(id) {
      
      let projectId = localStorage.getItem("projectId")
      const data = {
        id: id,
        projectId: projectId
      }

      const res = await this.$https.deleteSharedLibrary(data)
      if (res.success == true) {

        this.$store.commit("changeChosenWarehouse", this.warehouseList[0])

        this.$message({
          message: "恭喜您删除成功"
          // type: "warning"
        });
        this.getWarehouseList(this.page, this.size)


      }

    },
    //重命名库
    async updateSharedLibraryName(id, name) {

      const data = {
        id: id,
        name: name,
        projectId: localStorage.getItem("projectId"),
      }

      const res = await this.$https.updateSharedLibraryName(data)
      if (res.success == true) {


        this.$message({
          message: "恭喜您修改成功"
          // type: "warning"
        });

        this.getWarehouseList(this.page, this.size)


      } else {

        this.$message({
          message: res.errMessage
          // type: "warning"
        });

      }

    },
    // 


    //获取库列表
    async getWarehouseList(page, size) {
      let projectId = localStorage.getItem("projectId")
      const data = {
        page: page,
        count: size,
        projectId: projectId,
        type: 1

      }

      const res = await this.$https.sharedLibraryList(data)
      if (res.success == true) {
    
        this.warehouseList = res.data.sharedLibraryListCOS
        this.total = Math.ceil(res.data.total / this.size)


      }
    },




    // 分享
    submitForm(formName) {

      this.$refs[formName].validate((valid) => {
        if (valid) {

          let thisUrl = window.location.href.split('#')[0] + '#'



          let url = thisUrl + "/index/user/ChainedAddress"


          let id = this.rightClickWarehouse.id
          let time = new Date().getTime()
          let stochastic = this.getNum(10)


          if (this.share == false) {

            this.shareUrl = url + '?id=' + id + '&&time=' + time + '&&stochastic=' + stochastic

          }

          this.share = true

          let limit = this.ruleForm.resource == "1" ? 7 : this.ruleForm.resource == "2" ? "14" : null



          this.saveShareLink(this.rightClickWarehouse.id, time, limit, this.ruleForm.password, stochastic)


        } else {

          return false;
        }
      });









    },

    //分享接口
    async saveShareLink(id, time, days, password, num) {
      let data = {
        id: id,
        time: time,
        days: days,
        password: password,
        num: num + ""
      }
      const res = await this.$https.saveShareLink(data)

      if (res.success == true) {

        // "这是已复制的文本，使用ctrl+v即可粘贴
      
        let myText = `共享库：${this.rightClickWarehouse.name}的访问地址是：${this.shareUrl} 链接有效期 ${days}天；访问码：${password}`

        let { text } = this.$copyText(myText);
        this.$message("复制成功")
        this.shareHistory = false
      }

    },




    //取消分享
    shareHistoryClose() {
      this.ruleForm.resource = "1"
      this.ruleForm.password = ""
      this.shareUrl = "点击分享后生成链接"
      this.shareHistory = false
      this.share = false

    },
    //生成随机数
    getNum(num) {
      var random = Math.floor(
        (Math.random() + Math.floor(Math.random() * 9 + 1)) *
        Math.pow(10, num - 1)
      );
      return random;
    },
    //选择共享库功能
    shareHistoryShow() {
      let thisUrl = window.location.href.split('#')[0] + '#'



      let url = thisUrl + "/index/user/ChainedAddress"

      this.url = url



      this.shareHistory = true

    },


    //新增共享库
    addTab() {
      var containSpecial = RegExp(
        /[(\ )(\~)(\!)(\@)(\#)(\$)(\%)(\^)(\&)(\*)(\()(\))(\+)(\=)(\[)(\])(\{)(\})(\|)(\\)(\;)(\:)(\')(\")(\——)(\,)(\.)(\/)(\<)(\>)(\?)(\)]+/
      );

      ISetting.isInput(true);
      this.$prompt(``, "请输入共享库名称", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",

        // center: true,
        inputErrorMessage: "输入不能为空，且不能有特殊字符 最大20字符",
        closeOnClickModal: false,
        inputValidator: value => {
          // 点击按钮时，对文本框里面的值进行验证
          if (!value) {
            return "输入不能为空";
          } else if (containSpecial.test(value)) {
            return "输入不能为特殊字符";
          } else if (value.length > 20) {
            return "输入文字不能超过20个字符";
          }
        }

      })
        .then(({ value }) => {
          ISetting.isInput(false);
          this.addSharedLibrary(value)
        })
        .catch(() => {
          ISetting.isInput(false);

          this.$message({
            // type: "info",
            message: "取消输入"
          });
        });
    },
    handleSelect(key) {
      if (key == 1) {
        //重命名
        this.rechristen()

      } else if (key == 2) {
        //删除库
        this.deleteLibrary()
      } else if (key == 3) {
        this.shareHistoryShow()
      }
    },
    //删除库
    deleteLibrary() {
      //删除
      this.$confirm("此操作将永久删除该库, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.deleteSharedLibrary(this.rightClickWarehouse.id)
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });




    },






    //重命名
    rechristen() {
      ISetting.isInput(true);
      var containSpecial = RegExp(
        /[(\ )(\~)(\!)(\@)(\#)(\$)(\%)(\^)(\&)(\*)(\()(\))(\+)(\=)(\[)(\])(\{)(\})(\|)(\\)(\;)(\:)(\')(\")(\——)(\,)(\.)(\/)(\<)(\>)(\?)(\)]+/
      );

      this.$prompt(``, "请输入新名称", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputValue:this.rightClickWarehouse.name,
        closeOnClickModal: false,

        // center: true,
        inputErrorMessage: "输入不能为空，且不能有特殊字符 最大20字符",
        inputValidator: value => {
          // 点击按钮时，对文本框里面的值进行验证
          if (!value) {
            return "输入不能为空";
          } else if (containSpecial.test(value)) {
            return "输入不能为特殊字符";
          } else if (value.length > 20) {
            return "输入文字不能超过20个字符";
          }
        }
      })
        .then(({ value }) => {
          ISetting.isInput(false);

          this.updateSharedLibraryName(this.rightClickWarehouse.id, value)



        })
        .catch(() => {
          ISetting.isInput(false);

          this.$message({
            // type: "info",
            message: "取消输入"
          });
        });
    },




    rightClick(rightClickWarehouse) {
      this.rightClickWarehouse = rightClickWarehouse


      let menu = this.$refs.menu;
      let x = event.clientX;
      let y = event.clientY;
      if (y > 700) {
        menu.$el.style.top = y - 150 + "px";
      } else {
        menu.$el.style.top = y + "px";
      }
      if (x > 1280) {
        menu.$el.style.left = x - 100 + "px";
      } else {
        menu.$el.style.left = x + "px";
      }
      this.menu = true; //让菜单显示
    }

  }



}
</script>

<style scoped>
.SharedLibrary {

  width: 99%;
  height: 40px;
  background: #F7F7F7;
  overflow: hidden;

}

.paging {
  float: right;
  /* width: 100px; */
  min-width: 200px;
  height: 40px;
  /* background: #000; */

  /* display: flex;
  justify-content: center;
  align-items: center; */
  overflow: hidden;
  padding-top: 5px;


}

.paging .block {
  /* width: 100%; */
}

.paging .newSequence {
  display: inline-block;
}

.cut {
  float: left;
  background: #fff;
  /* width: 140px; */
  border-radius: 8px;
  margin: 10px;
  margin-top: 0;
  margin-bottom: 0;

  padding: 0 10px 0 10px;
}

.sequenceList {
  width: 80%;
  height: 40px;
  /* background:blue; */
  float: left;
  /* line-height: 40px; */


}

.sequenceList .warehouseTitle {
  display: inline-block;
  width: 100%;
  min-width: 60px;

  /* padding: 10px;
  padding-top:0 ; */
  /* padding-bottom:0 ; */

  /* height: 80%;
  width: 90%;
  padding-left: 15px; */
  position: relative;


}

.sequenceList .warehouseTitle .selectBg {
  position: absolute;
  top: 10px;
  background: #F7F7F7;
  width: 100%;
  padding: 0 10px 0 10px;
  height: 60%;
  line-height: 170%;
  text-align: center;

  /* z-index:-1; */

}

.sequenceList .warehouseTitle img {
  width: 10px;
}






.sequenceList /deep/ .el-tabs__item {

  background: #F7F7F7;

  border: none !important;

}

.sequenceList /deep/ .is-active {
  /* background-color:#fff; */
  background: #F7F7F7;


}

.sequenceList /deep/ .is-active .warehouseTitle .selectBg {
  /* background: #fff;*/
  border-radius: 5px;
  background-color: #fff;
}

.menu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999999999999;
}

.menuItem {

  line-height: 60px;
}

.menuIcon {
  width: 19px;
  height: 17px;

  margin-right: 20px;
}

.myMenu /deep/ .menu {
  border-radius: 10px;

  padding: 10px 0;
  box-shadow: 0px 0px 6px 0px rgba(33, 35, 49, 0.1);
}

.myMenu /deep/.el-menu-item {
  height: 40px;
  line-height: 40px;
  position: relative;

  white-space: nowrap;
  list-style: none;
}

.myMent /deep/ .el-menu-item.is-active {
  color: #000;
}

.sequencetable {
  width: 100%;

}

.sequencetable .sequencetableContent {
  overflow: hidden;
  /* background: red; */
}

.sequencetable .sequencetableContent .sequenceItem {
  /* background: #444; */
  float: left;
  padding: 0 10px 0 10px;

}
</style>