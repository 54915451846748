import * as TWEEN from "@tweenjs/tween.js"

class ITween {
    static tweenUpdate(time, callback) {
        const coords = { x: 0 }
        const tween = new TWEEN.Tween(coords)
            .to({ x: 1 }, time)
            .easing(TWEEN.Easing.Quadratic.Out)
            .onUpdate(() => {
                callback(coords.x);
            })
            .start()
    }
}

export { ITween };