<template>
  <input type="number"  :value="displayValue" @change="onInputChange" @blur="onInputBlur"
         @focus="onInputFocus" @keydown="onKeyDown" />
</template>
<script>
export default {
  props: {
    label: String,
    value: Number,
    variant: String,
    onChange: Function,
    unitConversion: Number,
  },
  data() {
    return {
      focus: false,
      inputValue: null
    }
  },
  watch: {

  },
  computed: {
    unitConversionCom() {
      return this.$props.unitConversion ?? 1
    },
    displayValue() {
      const unitConversion = this.$props.unitConversion ?? 1
      return this.$props.value * unitConversion
    }
  },
  methods: {

    onInputChange(e) {
      if (focus) {
        this.inputValue = e.target.value;
      }
    },

    onInputBlur(e) {
      const { onChange } = this.$props
      const x = parseFloat(this.inputValue);
      if (x !== this.displayValue)
        onChange(x / this.unitConversionCom);
      this.focus = false
    },

    onInputFocus(e) {

      if (this.inputValue !== this.displayValue + '') {
        this.inputValue = this.displayValue + ''
      }
      this.focus = true

    },

    onKeyDown(e) {
      const { onChange } = this.$props

      if (e.key === 'Enter') {
        const x = parseFloat(this.inputValue);
        if (x !== this.displayValue)
          onChange(x / this.unitConversionCom);
      }
    },
  }
}
</script>
<style lang="less" scoped>

</style>