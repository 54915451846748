import * as THREE from 'three';
import * as TWEEN from "@tweenjs/tween.js"

class TextureBehavior {
    constructor() {
        this.m_u_start = 0;               //U开始
        this.m_v_start = 0;               //V开始
        this.m_u_end = 1;                 //U结束
        this.m_v_end = 1;                 //V结束
        this.m_rot_start = 0;             //旋转开始
        this.m_rot_end = 0;               //旋转结束
        this.m_time = 1000;               //速度
        this.m_tween = null;
        this.m_textursList = [];
        this.m_yoyo = false;
    }

    getJson()
    {
        var data = {
            m_u_start: this.m_u_start,
            m_v_start: this.m_v_start,
            m_u_end: this.m_u_end,
            m_v_end: this.m_v_end,
            m_rot_start: this.m_rot_start,
            m_rot_end: this.m_rot_end,
            m_time: this.m_time,
            m_yoyo: this.m_yoyo
        }
        return data;
    }

    setJson(json)
    {
        this.m_u_start = json.m_u_start;
        this.m_v_start = json.m_v_start;

        this.m_u_end = json.m_u_end;
        this.m_v_end = json.m_v_end;

        this.m_rot_start = json.m_rot_start;
        this.m_rot_end = json.m_rot_end;

        this.m_time = json.m_time;
        this.m_yoyo = json.m_yoyo;
    }

    init(object){
       
        var _this = this;
        _this.m_textursList = [];
        object.traverse(function (child) {
            if (child.isMesh || child.isSkinnedMesh) {
               if(child.material)
               {
                _this.m_textursList.push(child.material)
               }
            }
        });
 
        if(_this.m_textursList.length > 0){
            _this.destory();
            var coords = {
                m_u_start: this.m_u_start,
                m_v_start: this.m_v_start,
                m_rot_start: this.m_rot_start,
            }
            this.m_tween = new TWEEN.Tween(coords);
            this.m_tween.yoyo(this.m_yoyo);
            this.m_tween.repeat(Infinity);
            this.m_tween.to({
                m_u_start: this.m_u_end,
                m_v_start: this.m_v_end,
                m_rot_start: this.m_rot_end,
            }, this.m_time);
            this.m_tween.onUpdate(() => {
                for(var i = 0; i < _this.m_textursList.length; i++)
                {
                    var material = _this.m_textursList[i];
                    if(material.map)
                    {
                        var texture = material.map;
                        texture.offset.set(coords.m_u_start, coords.m_v_start);
                        texture.rotation = coords.m_rot_start;
                        texture.center.set(0.5, 0.5);
                    }
                    if(material.normalMap)
                    {
                        var texture = material.normalMap;
                        texture.offset.set(coords.m_u_start, coords.m_v_start);
                        texture.rotation = coords.m_rot_start;
                        texture.center.set(0.5, 0.5);
                    }
                }
    
            })
            this.m_tween.start();
        }

    }

    destory()
    {
        var _this = this;
        if(this.m_tween !=  null){
            this.m_tween.stop();
            for(var i = 0; i < _this.m_textursList.length; i++)
            {
                var material = _this.m_textursList[i];
                if(material.map)
                {
                    var texture = material.map;
                    texture.offset.set(0, 0);
                    texture.rotation = 0;
                }
                if(material.normalMap){
                    var texture = material.normalMap;
                    texture.offset.set(0, 0);
                    texture.rotation = 0;
                }
            }
            this.m_tween = null;
        }
    }
}

export { TextureBehavior };