import { MainObjects } from "../common/MainObjects"

class IRender {
    
    static RenderMode =
    {
        e_SOLID: 0,
        e_WIRE: 1,
    }

    static UpdateType = {
        e_ALL : 0,     
        e_UPDATE_LIGHT : 1,
        e_UPDATE_OBJECT: 2,
        e_UPDATE_CAMERA : 3
    }
    
    static f_renderModeCallback = null;
    static f_renderLogCallback = null;

    static synchronization(updateType = IRender.UpdateType.e_ALL) {
        MainObjects.Blueprint.m_renderManagerBp.sentMessage(updateType);
    }

    static getNetIp(){
        return MainObjects.Setting.m_ip;
    }

    static getNetPort(){
        return MainObjects.Setting.m_port;
    }

    static setNetIp(ip){
        console.log(ip);
        MainObjects.Setting.m_ip = ip;
    }

    static setNetPort(port){
        MainObjects.Setting.m_port = port
    }

    static getRenderValue(){
        var data = {
            m_total : MainObjects.Setting.m_total,//总反射
            m_diffuse : MainObjects.Setting.m_diffuse,//漫反射
            m_glossy : MainObjects.Setting.m_glossy,//光泽反射
            m_specular : MainObjects.Setting.m_specular,//镜面反射
            m_haltspp : MainObjects.Setting.m_haltspp,
            m_env_rot : MainObjects.Setting.m_env_rot,
            m_env_gain : MainObjects.Setting.m_env_gain,
            m_env_image : MainObjects.Setting.m_env_image,
        }
        return data;
    }

    static setRenderValue(data){
        MainObjects.Setting.m_total = data.m_total;
        MainObjects.Setting.m_diffuse = data.m_diffuse;
        MainObjects.Setting.m_glossy = data.m_glossy;
        MainObjects.Setting.m_specular = data.m_specular;
        MainObjects.Setting.m_haltspp = data.m_haltspp;
        MainObjects.Setting.m_env_rot = data.m_env_rot;
        MainObjects.Setting.m_env_gain = data.m_env_gain;
        MainObjects.Setting.m_env_image = data.m_env_image;
    }

    static setRenderMode(b) {
        MainObjects.Blueprint.m_sceneManagerBp.changeMaterialMode(b);
    }

}

export { IRender };