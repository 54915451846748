<template>
  <div class="particle-renderer  property-container">
    <div class="property">
      <div class="name">{{ t('a2f41') }}</div>
      <el-select @change="onChangeBlending"
                   :value="getValueOfBlending(particleSystem.blending)">
        <el-option
            v-for="item in blendOptions"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>
    </div>
    <div class="property">
      <div class="name">{{ t('64c28') }}:</div>
      <NumberInput :value="particleSystem.renderOrder" :onChange="onChangeRenderOrder"/>
    </div>
    <div class="property">
      <div class="name">{{ t('62a7d') }}</div>
      <div >{{ t('4017d') }}</div>
      <NumberInput variant="short" :value="particleSystem.uTileCount" :onChange="onChangeUTileCount"/>
      <div >{{ t('dcfcc') }}</div>
      <NumberInput variant="short" :value="particleSystem.vTileCount" :onChange="onChangeVTileCount"/>
    </div>
    <GeneratorEditor name="Start Tile Index" :allowedType="['value']" :value="particleSystem.startTileIndex" :onChange="onChangeStartTile"/>

    <div class="property">
      <div  class="name">{{ t('3615b') }}</div>
      <div v-if="particleSystem?.texture">
        <img class="texture-preview" :src="particleSystem?.texture?.image.src"
             :style="{ objectPosition : `-${(index % particleSystem.uTileCount) * gridWidth}px -${Math.floor(index / particleSystem.uTileCount) * gridHeight}px`,
             width: `${gridWidth}px`, height: `${gridHeight}px` }"/>
      </div>
      {{particleSystem?.texture?.userData?.m_url ?? "texture1" }}
      <div class="render-pos" @click="handleChangeTexture" >
        <div>{{ t('定位：') }} </div><i class="el-icon-picture"   ></i>
      </div>
    </div>
<!--    <TexturePicker :handleClose="() => setTexturePickerOpen(false)"-->
<!--                   :handleSelect="onChangeTexture"-->
<!--                   :handleUpload="onUploadTexture(context)"-->
<!--                   :open="texturePickerOpen"-->
<!--                   :textures="context.textures"/>-->
  </div>
</template>

<script>
import {t} from "../../util/lang";
import {AdditiveBlending, NormalBlending} from "three";
import {ConstantValue, RenderMode} from "three.quarks";
import NumberInput from "@/views/component/NumberInput.vue";
import GeneratorEditor from "@/views/ParticleSystem/Editors/GeneratorEditor.vue";
import {MainObjects} from "@/threejs/common/MainObjects";
import {mapState} from "vuex";

export default {
  components: {NumberInput, GeneratorEditor},

  props: {
    particleSystem: Object,
    updateProperties: Function,
    textures: Object
  },
  computed: {
    index() {
      console.log(this.$props.particleSystem.startTileIndex.genValue())
      return this.$props.particleSystem.startTileIndex.genValue();
    },
    gridWidth() {
      return this.$props.particleSystem?.texture?.image.width / this.$props.particleSystem.uTileCount;
    },
    gridHeight() {
      return this.$props.particleSystem?.texture?.image.height / this.$props.particleSystem.vTileCount;
    },
    ...mapState('particleSystem', ['curParticleSystem']),


  },
  data() {
    const blendOptions = [{value: 'Normal',label: t('Normal')}, {value: 'Additive',label: t('Additive')}]
    return {
      texturePickerOpen: false,
      blendOptions: blendOptions,
      style_vw_loader_is_a_piece_of_shit: ''
    };
  },
  methods: {
    t,
    onChangeSpeedFactor(order) {
      this.$props.particleSystem.speedFactor = order;
      this.$props.updateProperties();
    },
    onChangeRenderOrder(order) {
      this.$props.particleSystem.renderOrder = order;
      this.$props.updateProperties();
    },
    onChangeStartTile(index) {
      this.$props.particleSystem.startTileIndex = index;
      this.$props.updateProperties();
    },
    onChangeUTileCount(u) {
      this.$props.particleSystem.uTileCount = u;
      this.$props.updateProperties();
    },
    onChangeVTileCount(v) {
      this.$props.particleSystem.vTileCount = v;
      this.$props.updateProperties();
    },
    onChangeBlending(value) {
      switch (value) {
        case "Normal":
          this.$props.particleSystem.blending = NormalBlending;
          break;
        case "Additive":
          this.$props.particleSystem.blending = AdditiveBlending;
          break;
      }
      this.$props.updateProperties();
    },

    getValueOfBlending(blending) {
      switch (blending) {
        case NormalBlending:
          return "Normal"
        case AdditiveBlending:
          return "Additive";
      }
      return "Normal";
    },

    onChangeRenderMode(value) {
      switch (value) {
        case "BillBoard":
          this.$props.particleSystem.renderMode = RenderMode.BillBoard;
          this.$props.particleSystem.speedFactor = 0;
          break;
        case "Mesh":
          this.$props.particleSystem.renderMode = RenderMode.Mesh;
          this.$props.particleSystem.speedFactor = 0;
          break;
        case "StretchedBillBoard":
          this.$props.particleSystem.renderMode = RenderMode.StretchedBillBoard;
          break;
        case "Trail":
          this.$props.particleSystem.renderMode = RenderMode.Trail;
          this.$props.particleSystem.speedFactor = 0;
          break;
      }
      this.$props.updateProperties();
    },

    onChangeGeometry(value) {
      this.$props.particleSystem.instancingGeometry = value;
      this.$props.updateProperties();
    },

    onChangeWorldSpace(value) {
      switch (value) {
        case "True":
          this.$props.particleSystem.worldSpace = true;
          break;
        case "False":
          this.$props.particleSystem.worldSpace = false;
          break;
      }
      this.$props.updateProperties();
    },

    getValueOfBoolean(worldSpace) {
      return worldSpace ? 'True' : 'False';
    },


    onChangeTexture(textureImage) {
      this.$props.particleSystem.texture = textureImage.texture;
      this.$props.updateProperties();
    },
    handleChangeTexture() {
      // console.log()
      const fileId = this.$props.particleSystem?.material?.map?.userData?.m_id
      if(fileId) {
        this.$EventBus.$emit('orientationTexture',fileId)

      }

    }

  },
  mounted() {
    console.log()
    // Implement the necessary logic to calculate gridWidth, gridHeight, index, and texture
  },
}
</script>
<style lang="less" scoped>

.texture-preview {
  object-fit: none;
  background-color: #9e9e9e;
  max-width: 300px;
  max-height: 300px;
}

.particle-renderer {
  .render-pos {
    display: flex;
    align-items: center;
    .el-icon-picture {
      font-size: 20px;
    }
  }

}
</style>