<template>
    <div>
        <div class="box" v-show="boxState" :class="isActive?'anmDiv':'aaa'">
           <div class="header">
               <div @click="shrinkChange"><i class="el-icon-arrow-down"></i> </div>
               <p>骨骼动画</p>
           </div>
           <div class="content">
               <div class="shadow">
                    <div class="title">展示骨骼</div>
                    <div class="ipt_box">
                        <input class="input" type="checkbox" @change="bHelperChange($event)" v-model="bHelper" />
                    </div>
                </div>
                <div class="play">
                    <div class="title">播放</div>
                    <div @click="startChange">
                        <div class="start" v-show="!play">{{textPlay}}</div>
                        <div class="stop" v-show="play">{{textStop}}</div>
                    </div>
                </div>
                <div class="speed">
                    <div class="title">动画速度</div>
                    <div class="slider">
                        <el-slider v-model="timescale" @change="timescaleChange()" :min="0" :max="2" :step="0.1" :show-tooltip="false"></el-slider>
                    </div>
                    <div class="ipt_box">
                        <input type="text" readonly="readonly" v-model="timescale">
                    </div>
                </div>
                <div class="movement">
                   <div class="title">动作列表</div>
                   <div class="walk_box" >
                       <div v-for="(item,index) in movementList" :key="index" @click="movementChange(index)" style="display:flex;align-items: center;">
                            <div class="walk" :id="'walk' + index" :class="{active1:currentType==index}">{{item}} </div>
                            <div class="reset" v-clipboard:copy="`${item}`"  v-clipboard:success="onCopy" ><img src="../../assets/Property/copy.png" alt=""> </div>
                        </div>
                   </div>
                </div>
           </div>
        </div>
        <div class="boxOne" v-show="boxOneState" :class="Active?'anmDiv':'aaa'">
           <div class="header">
               <div @click="shrinkChange1"><i class="el-icon-arrow-down"></i> </div>
               <p>设置骨骼</p>
           </div>
           <div>
                <div class="selectBox">
                   <p>设置骨骼</p>
                   <div class="ipt_box">
                        <!-- <select class="select" v-model="select" @change="SelectChange()">
                            <option v-for="(item,index) in selectList" :key="index">{{item}} </option>
                        </select> -->
                        <el-select class="select" @change="SelectChange()" v-model="select" placeholder="请选择">
                            <el-option
                                v-for="item in selectList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.name">
                            </el-option>
                        </el-select>
                    </div>
               </div>
           </div>
        </div>
        <div class="boxOne1" v-show="MorpState" :class="Active1?'anmDiv':'aaa'">
           <div class="header">
               <div @click="shrinkChange2"><i class="el-icon-arrow-down"></i> </div>
               <p>变形动画</p>
           </div>
           <div v-for="(item,index) in SelectMorphList" :key="index">
                <div class="angry">
                   <p>{{item.name}}</p>
                    <div class="slider block">
                        <el-slider v-model="item.value" @input="metalnessChange(index,item)" show-input :min="0" :max="1" :step="0.01" :show-tooltip="false"></el-slider>
                    </div>
               </div>
           </div>
        </div>
    </div>
</template>
<script>
import { IAnimation } from "../../threejs/interface/IAnimation";
export default {
    name:"SkeletalAnimation",
    data(){
        return{
            boxState:false,
            boxOneState:false,
            isActive:true,
            Active:true,
            Active1:true,
            cur:-1,
            currentType:'',
            play:true,//播放
            textPlay:'开始',
            textStop:'暂停',
            movementList:[],
            bHelper: false,//展示骨骼
            timescale: 1,//动画速度
            animationList: 0,//动作列表
            selectList:[],
            select:'',
            Angry:0,
            MorpState:false,
            SelectMorphList:[],
            SelectMorph:"",

        }
    },
    methods:{
        shrinkChange(){
            if (this.isActive) {
              this.isActive = false
            } else {
              this.isActive = true;
            }
        },
        shrinkChange1(){
            if (this.Active) {
              this.Active = false
            } else {
              this.Active = true;
            }
        },
        shrinkChange2(){
            if (this.Active1) {
              this.Active1 = false
            } else {
              this.Active1 = true;
            }
        },
        //播放
        startChange(){
            this.play = !this.play;
            IAnimation.setPlay(this.play)
        },
        //点击动作列表
        movementChange(index){
            this.currentType = index;
            // console.log(this.movementList[index])
            IAnimation.setCurrentAnimation(this.movementList[index]);
        },
        onCopy(){
            this.$message({
             message:"复制成功"
            })
        },
        //展示骨骼
        bHelperChange(event){
            let checked = event.target.checked;
            IAnimation.setHelper(checked)
        },
        //动画速度
        timescaleChange(){
            IAnimation.setTimeScale(this.timescale)
        },
        IAnimationChange(){
            // console.log(IAnimationList)
            let IAnimationList = IAnimation.getSelectAnimation();
            if(IAnimationList !=null){
                this.boxState = true;
                this.movementList = IAnimationList.animationList;
                this.movementList[this.currentType] = IAnimationList.currentAnimation;
                this.bHelper = IAnimationList.bHelper;
                this.play = IAnimationList.bPlay;
                this.timescale = IAnimationList.timeScale;
                this.selectList = IAnimationList.skeletonList;
                this.select = IAnimationList.currentSkeleton;
            }else{
                this.boxState = false;
            }
            let getSelectSkeletonList = IAnimation.getSelectSkeleton();
            if(getSelectSkeletonList != null){
                this.boxOneState = true;
                this.selectList = getSelectSkeletonList.skeletonList;
                this.select = getSelectSkeletonList.currentSkeleton;

            }else{
                this.boxOneState = false;
            }
            let getSelectMorphList  = IAnimation.getSelectMorph();
            // console.log("11",getSelectMorphList)
            if(getSelectMorphList  != null){
                this.MorpState = true;
                this.SelectMorphList = getSelectMorphList.morphNameValueList;
            }else{
                this.MorpState = false;
            }
        },
        SelectChange(){
            // console.log(this.select)
            IAnimation.setCurrentSkeleton(this.select)
        },
        metalnessChange(index,item){
            let name = item.name;
            let value =  item.value
            IAnimation.setSelectMorph(name,Number(value))
            // console.log(name,value)
        }
    }
}
</script>
<style scoped>
.box{
    width: 80%;
    height: 500px;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    border-radius: 5px;
    margin-top:8px;
    margin-left: 70px;
    font-size: 10px;
    background: #ffff;
    overflow:auto;
}
.box::-webkit-scrollbar {
  display: none;  
}
.box{
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.boxOne::-webkit-scrollbar{
    display: none;  
}
.boxOne{
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.boxOne1::-webkit-scrollbar{
    display: none;  
}
.boxOne1{
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.boxOne{
    width: 80%;
    height: 150px;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    border-radius: 5px;
    margin-top:8px;
    margin-left: 70px;
    font-size: 10px;
    background: #ffff;
    overflow:auto;
    /* margin-bottom: 30px; */
}
.boxOne1{
    width: 80%;
    height: 400px;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    border-radius: 5px;
    margin-top:8px;
    margin-left: 70px;
    font-size: 10px;
    background: #ffff;
    overflow:auto;
    margin-bottom: 30px;
}
.anmDiv{
transition: 1S;
 
}
.aaa{
height: 40px;
transition: 1S;
}
.header{
    display: flex;
    width: 90%;
    height: 40px;
    margin: auto;
    align-items: center;
    color: #000;
    font-weight: bold;
    font-size: 12px;
}
.header p{
    margin-left: 10px;
}
.header i{
    font-weight: bold;
    cursor: pointer;
}
.content{
    width: 100%;
    height: calc(100% - 45px);
    background: #fff;
    margin: auto;
}
.shadow{
    display: flex;
    width: 90%;
    height: 25px;
    align-items: center;
    margin:15px auto;
}
.shadow .title{
    width: 70px;
    height: 20px;
    line-height: 20px;
    background: #fff;
    text-align: center;
    font-weight: bold;
    overflow: hidden;
}
.play{
    display: flex;
    width: 90%;
    height: 30px;
    align-items: center;
    margin:20px auto;
}
.play .title{
    width: 70px;
    height: 30px;
    line-height: 30px;
    background: #fff;
    text-align: center;
    font-weight: bold;
    overflow: hidden;
}
.play .start{
    width: 60px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    margin-left: 30px;
    border-radius: 6px;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    background: #1673ff;
    color: #fff;
    cursor: pointer;
}
.play .stop{
    width: 60px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    margin-left: 30px;
    border-radius: 6px;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    cursor: pointer;
}
.active{
    width: 60px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    color: #fff;
    background: #1673ff;
    border-radius: 6px;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
}
.speed{
    display: flex;
    width: 90%;
    height: 30px;
    align-items: center;
    margin:15px auto;
}
.speed .title{
    width: 70px;
    height: 30px;
    line-height: 30px;
    background: #fff;
    text-align: center;
    font-weight: bold;
    overflow: hidden;
}
.speed .slider{
    width: 150px;
    margin-left: 30px;
}
.speed .ipt_box{
    width: 55px;
    height: 30px;
    box-shadow: 0px 0px 2px 0px rgba(33, 35, 49, 0.1);
    border-radius: 6px;
    display: flex;
    align-items: center;
}
.speed .ipt_box input{
  width: 50px;
  height: 28px;
  outline: none;
  border: none;
  color: #929292;
  border-radius: 5px;
  text-align: center;
}
.movement{
    width: 90%;
    height: 40px;
    align-items: center;
    margin:20px auto;
}
.movement .title{
    width: 70px;
    height: 40px;
    line-height: 40px;
    background: #fff;
    text-align: center;
    font-weight: bold;
    overflow: hidden;
}
.walk_box{
    margin-top: -40px;
}
.movement .walk{
    width: 280px;
    height: 28px;
    line-height: 30px;
    text-align: center;
    margin-left: 15px;
    border-radius: 6px;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    cursor: pointer;
    margin-top: 10px;
    margin-left: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.reset{
    width: 30px;
    height: 25px;
    text-align: center;
    line-height: 25px;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    border-radius: 5px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left:5px;
}
.reset img{
    width: 14px;
    height: 12px;
}
.active1{
    width: 80px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #fff;
    background: rgba(5, 96, 253, 1);
    border-radius: 6px;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
}
.input:checked {
	background:#1673ff
}
.input {
	width:16px;
	height:16px;
    outline: none;
	background-color:rgba(125, 128, 133, 0.4);
	border:solid 1px #dddddd;
	-webkit-border-radius:50%;
	border-radius:50%;
	font-size:0.8rem;
	padding:0;
	position:relative;
	display:inline-block;
	vertical-align:top;
	cursor:default;
	-webkit-appearance:none;
	-webkit-user-select:none;
	user-select:none;
	-webkit-transition:background-color ease 0.1s;
	transition:background-color ease 0.1s;
	outline: none;
}
.input:checked::after {
	content:'';
	top:3px;
	left:3px;
	position:absolute;
	background:transparent;
	border:#fff solid 2px;
	border-top:none;
	border-right:none;
	height:4px;
	width:6px;
	-moz-transform:rotate(-45deg);
	-ms-transform:rotate(-45deg);
	-webkit-transform:rotate(-45deg);
	transform:rotate(-45deg);
}
.ipt_box{
    width: 65px;
    height: 25px;
    margin-left: 30px;
    background: #FFFFFF;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.slider >>>.el-slider__button{
  width: 10px;
  height: 10px;
  background: #0560FD;
}
.slider >>>.el-slider__bar{
  background: #0560FD;
}
.selectBox{
    display: flex;
    width: 90%;
    height: 25px;
    margin: 8px auto;
    align-items: center;
    color: #000;
    font-weight: bold;
    font-size: 12px;
    margin-top: 20px;
}
.selectBox .ipt_box{
    width: 260px;
    height: 30px;
    box-shadow: 0px 0px 2px 0px rgba(33, 35, 49, 0.1);
    border-radius: 6px;
    display: flex;
    align-items: center;
}
.selectBox .select{
    width: 260px;
    height: 28px;
    outline: none;
    color: #808080;
    border: none;
    border-radius:4px;
}
.selectBox .select /deep/ .el-input{
   width: 260px !important;
   height: 35px !important;
   outline: none !important;
}
.selectBox .select /deep/ .el-input__inner{
   width: 260px !important;
   height: 28px !important;
   outline: none !important;
}
.selectBox .select /deep/ .el-select__caret{
  height: 28px !important;
  line-height: 28px !important;
}
.selectBox p{
    width: 70px;
    height: 30px;
    line-height: 30px;
    overflow: hidden;
    text-align: center;
}
.angry{
    display: flex;
    width: 90%;
    height: 25px;
    margin: 8px auto;
    align-items: center;
    color: #000;
    font-weight: bold;
    font-size: 12px;
    margin-top: 20px;
}
.angry p{
    width: 200px;
    height: 30px;
    line-height: 30px;
    overflow: hidden;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.angry .slider{
  width: 350px;
  margin-left: 20px;
  margin-right: 10px;
}
.angry .slider >>> .el-slider__input{
  width:110px !important;
  /* position: absolute !important;
  right: 55px !important; */
}
</style>