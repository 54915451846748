const canvas = document.createElement('canvas');
const ctx = canvas.getContext('2d');

let specialFlag = false

class CubeFace {
  constructor(faceName) {
    this.faceName = faceName;

    // this.anchor = document.createElement('a');
    // this.anchor.style.position='absolute';
    // this.anchor.title = faceName;
    //
    // this.img = document.createElement('img');
    // this.img.style.filter = 'blur(4px)';
    //
    // this.anchor.appendChild(this.img);
  }

  setPreview(blob, x, y) {
    // this.img.src = url;
    const url = URL.createObjectURL(blob)

    resultPre.push(url)
    if(resultPre.length === 6){
      _callback(resultPre)
    }

    // this.anchor.style.left = `${x}px`;
    // this.anchor.style.top = `${y}px`;
  }

  setDownload(blob, fileExtension) {
    const url = URL.createObjectURL(blob)

    if(specialFlag){
      switch (this.faceName) {
        case 'px':
          this.faceName = 'nx'
          break
        case 'pz':
          this.faceName = 'nz'
          break
        case 'nx':
          this.faceName = 'px'
          break
        case 'nz':
          this.faceName = 'pz'
          break
      }
    }
    const file = new File([blob], `${this.faceName}.${setting.format}`);
    resultUrlFile.push({file,url})
    if(resultUrlFile.length === 6){
      _callbackCompletion(resultUrlFile)
    }
    // this.anchor.href = url;
    // result.push(url)

    // this.anchor.download = `${this.faceName}.${fileExtension}`;
    // this.img.style.filter = '';
  }
}

const mimeType = {
  'jpg': 'image/jpeg',
  'png': 'image/png'
};

function getDataURL(imgData, extension) {
  canvas.width = imgData.width;
  canvas.height = imgData.height;
  ctx.putImageData(imgData, 0, 0);
  return new Promise(resolve => {
    canvas.toBlob(blob => {
      resolve(blob)
    }, mimeType[extension], 0.92);
  });
}
const setting = {

}
export const resultPre = []
export const resultUrlFile = []
function clamp(value, min, max) {
  return Math.min(Math.max(value, min), max);
}


const facePositions = {
  pz: {x: 1, y: 1},
  nz: {x: 3, y: 1},
  px: {x: 2, y: 1},
  nx: {x: 0, y: 1},
  py: {x: 1, y: 0},
  ny: {x: 1, y: 2}
};

function loadImage(fileParam) {
  // const file = dom.imageInput.files[0];
  const file = fileParam;

  if (!file) {
    return;
  }

  const img = new Image();

  img.src = URL.createObjectURL(file);

  img.addEventListener('load', () => {
    const {width, height} = img;
    canvas.width = width;
    canvas.height = height;
    ctx.drawImage(img, 0, 0);
    const data = ctx.getImageData(0, 0, width, height);

    processImage(data);
  });
}

let finished = 0;
let workers = [];

function processImage(data) {
  for (let worker of workers) {
    worker.terminate();
  }

  for (let [faceName, position] of Object.entries(facePositions)) {
    renderFace(data, faceName, position);
  }
}

function renderFace(data, faceName, position) {
  const face = new CubeFace(faceName);
  let offset = 0
  if(faceName === 'py' || faceName === 'ny' && specialFlag){
    offset = 180
  }
  const options = {
    data: data,
    face: faceName,
    rotation: Math.PI * (setting.cubeRotation + offset) / 180,
    interpolation: setting.interpolation,
    channel: setting.format === 'png' ? 4 : 3
  };

  const worker = new Worker('convert.js');

  const setDownload = ({data: imageData}) => {
    const extension = setting.format;
    getDataURL(imageData, extension)
      .then(url => face.setDownload(url, extension));

    finished++;

    if (finished === 6) {
      // dom.generating.style.visibility = 'hidden';
      finished = 0;
      workers = [];
    }
  };

  const setPreview = ({data: imageData}) => {
    const x = imageData.width * position.x;
    const y = imageData.height * position.y;

    getDataURL(imageData, 'jpg')
      .then(url => face.setPreview(url, x, y));

    worker.onmessage = setDownload;
    worker.postMessage(options);
  };

  worker.onmessage = setPreview;
  worker.postMessage(Object.assign({}, options, {
    maxWidth: 200,
    interpolation: 'linear',
  }));

  workers.push(worker);
}
const methods = ['lanczos','cubic','linear']
const types = ['jpg','png']
let _callback = null
let _callbackCompletion = null
export const sphereToCub = (file, callback, callback2, options = {}) => {
  options.cubeRotation = clamp(options.cubeRotation ?? 180, 0, 359 )
  options.interpolation = methods.find(item => item === options.interpolation) || methods[0]
  options.format = types.find(item => item === options.format) || types[0]
  Object.assign(setting, options)
  resultPre.length = 0
  resultUrlFile.length = 0

  _callback = callback
  _callbackCompletion = callback2
  loadImage(file)
}

export const sphereToCubSpecial = (file, callback, callback2, options = {}) => {
  specialFlag = true
  options.cubeRotation = clamp(options.cubeRotation ?? 180, 0, 359 )
  options.interpolation = methods.find(item => item === options.interpolation) || methods[0]
  options.format = types.find(item => item === options.format) || types[0]
  Object.assign(setting, options)
  resultPre.length = 0
  resultUrlFile.length = 0



  _callback = callback
  _callbackCompletion = callback2
  loadImage(file)
}