<template>
    <div v-show="hide">
        <div class="Name">
            <div class="title">模型名称</div>
            <div class="ipt_box">
                <input v-model="name" @change="nameChange($event)" @focus="Focus" @blur="Blur" />
            </div>
        </div>
        <div class="scene">
            <div class="title">模型状态</div>
            <div class="ipt_box1">
                <!-- <select class="select" v-model="select" @change="selectChange()">
                    <option v-for="(item,index) in optionList" :key="index">{{item}} </option>
                </select> -->
                <el-select class="select" @change="selectChange()" v-model="select" placeholder="请选择">
                    <el-option
                        v-for="item in optionList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </div>
        </div>
        <div class="scene">
            <div class="title">场景标识</div>
            <div class="ipt_box1">
                <div class="id">{{sceneId}} </div>
                <div id="copy" v-clipboard:copy="sceneId" v-clipboard:success="copy"><img src="../../assets/Property/copy.png" alt=""> </div>
            </div>
        </div>
        <div class="type">
            <div class="title">
                <div @click="hideChange()" style="display:flex;align-items: center;">
                    <img class="img" src="../../assets/Property/show.png" alt="" v-show="hideState">
                    <img class="img" src="../../assets/Property/hide.png" alt="" v-show="!hideState">
                </div>
                <span>类型</span>
            </div>
            <div class="iptBox">
                <input type="text" :value="type" readonly="readonly">
                <div @click="lockChange()" class="lock">
                    <img class="img1" src="../../assets/Property/unlocking.png" alt="" v-show="lockState">
                    <img class="img1" src="../../assets/Property/lock.png" alt="" v-show="!lockState">
                </div>
            </div>
        </div>
        <div class="scene">
            <div class="title">图层</div>
            <div class="ipt_box1">
                <el-select class="select" @change="layerChange()" v-model="layerSelect" placeholder="请选择">
                    <el-option
                        v-for="item in layerList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </div>
        </div>
        <div class="box" :class="isActive?'anmDiv':'aaa'" v-show="boxState">
           <div class="header">
               <div @click="shrinkChange"><i class="el-icon-arrow-down"></i> </div>
               <p>变换</p>
           </div>
           <div class="adjust">
               <div class="loction">
                   <div class="title">位置</div>
                   <div class="x-axis axis">
                       <p>X</p>
                       <input v-model="positionX" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @change="changeNum0($event)"  @focus="Focus" @blur="Blur" />
                   </div>
                   <div class="y-axis axis">
                       <p>Y</p>
                       <input v-model="positionY" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @change="changeNum0($event)"  @focus="Focus" @blur="Blur"/>
                   </div>
                   <div class="z-axis axis">
                       <p>Z</p>
                       <input v-model="positionZ" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @change="changeNum0($event)"  @focus="Focus" @blur="Blur"/>
                   </div>
                   <div class="reset" @click="resetChange()"><img src="../../assets/Property/reset.png" alt=""> </div>
                   <div class="reset" v-clipboard:copy="`${positionX},${positionY},${positionZ}`"  v-clipboard:success="onCopy" ><img src="../../assets/Property/copy.png" alt=""> </div>
                   <div class="reset" @click="copyChange()"><img src="../../assets/Property/paste.png" alt=""> </div>
               </div>
               <div class="loction">
                   <div class="title">旋转</div>
                   <div class="x-axis axis">
                       <p>X</p>
                       <input v-model="rotateX" type="number" oninput="if(value>360)value=360;if(value<-360)value=-360" @change="changeNum1($event)"  @focus="Focus" @blur="Blur"/>
                   </div>
                   <div class="y-axis axis">
                       <p>Y</p>
                       <input v-model="rotateY" type="number" oninput="if(value>360)value=360;if(value<-360)value=-360" @change="changeNum1($event)"  @focus="Focus" @blur="Blur"/>
                   </div>
                   <div class="z-axis axis">
                       <p>Z</p>
                       <input v-model="rotateZ" type="number" oninput="if(value>360)value=360;if(value<-360)value=-360" @change="changeNum1($event)"  @focus="Focus" @blur="Blur"/>
                   </div>
                   <div class="reset" @click="rotateChange()"><img src="../../assets/Property/reset.png" alt=""></div>
                   <div class="reset" v-clipboard:copy="`${rotateX},${rotateY},${rotateZ}`"  v-clipboard:success="onCopy" ><img src="../../assets/Property/copy.png" alt=""> </div>
                   <div class="reset" @click="copyChange1()"><img src="../../assets/Property/paste.png" alt=""> </div>
               </div>
               <div class="loction">
                   <div class="title">缩放</div>
                   <div class="x-axis axis">
                       <p>X</p>
                       <input :disabled="disabled" @keydown="inputValue" v-model="scaleX" :step="0.1" oninput="if(value>999999999)value=999999999;if(value<-999999999)value=-999999999" type="number" @change="changeNum2($event)"  @focus="Focus" @blur="Blur"/>
                   </div>
                   <div class="y-axis axis">
                       <p>Y</p>
                       <input :disabled="disabled" @keydown="inputValue" v-model="scaleY" :step="0.1" oninput="if(value>999999999)value=999999999;if(value<-999999999)value=-999999999" type="number" @change="changeNum2($event)"  @focus="Focus" @blur="Blur"/>
                   </div>
                   <div class="z-axis axis">
                       <p>Z</p>
                       <input :disabled="disabled" @keydown="inputValue" v-model="scaleZ" :step="0.1" oninput="if(value>999999999)value=999999999;if(value<-999999999)value=-999999999" type="number" @change="changeNum2($event)"  @focus="Focus" @blur="Blur"/>
                   </div>
                   <div class="reset" @click="scaleChange()"><img src="../../assets/Property/reset.png" alt=""></div>
                   <div class="reset" v-clipboard:copy="`${scaleX},${scaleY},${scaleZ}`"  v-clipboard:success="onCopy" ><img src="../../assets/Property/copy.png" alt=""> </div>
                   <div class="reset" @click="copyChange2()"><img src="../../assets/Property/paste.png" alt=""> </div>
               </div>
               <div class="loction" v-show="selectState">
                   <div class="title">尺寸</div>
                   <div class="x-axis axis">
                       <p>X</p>
                       <input :disabled="disabled" class="size" v-model="sizeX" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @change="changeNum3($event)"  @focus="Focus" @blur="Blur"/>
                   </div>
                   <div class="y-axis axis">
                       <p>Y</p>
                       <input :disabled="disabled" class="size" v-model="sizeY" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @change="changeNum3($event)"  @focus="Focus" @blur="Blur"/>
                   </div>
                   <div class="z-axis axis">
                       <p>Z</p>
                       <input :disabled="disabled" class="size" v-model="sizeZ" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @change="changeNum3($event)"  @focus="Focus" @blur="Blur"/>
                   </div>
                   <div class="reset" style="margin-right:0.32552vw" @click="sizeChange()"><img src="../../assets/Property/reset.png" alt=""></div>
                   <div class="reset1"></div>
                   <div class="reset1"></div>
               </div>
               <div class="loction" v-show="selectState">
                   <div class="title">距离</div>
                   <div class="x-axis axis">
                       <p>X</p>
                       <input :disabled="disabled" v-model="distanceX" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @change="changeNum4($event)"  @focus="Focus" @blur="Blur"/>
                   </div>
                   <div class="y-axis axis">
                       <p>Y</p>
                       <input :disabled="disabled" v-model="distanceY" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @change="changeNum4($event)"  @focus="Focus" @blur="Blur"/>
                   </div>
                   <div class="z-axis axis">
                       <p>Z</p>
                       <input :disabled="disabled" v-model="distanceZ" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @change="changeNum4($event)"  @focus="Focus" @blur="Blur"/>
                   </div>
                   <div class="reset1" style="margin-right:0.32552vw"></div>
                   <div class="reset1"></div>
                   <div class="reset1"></div>
               </div>
               <div class="loction" v-show="selectState">
                   <div class="title"></div>
                   <div class="x-axis axis">
                       <p>-X</p>
                       <input :disabled="disabled" v-model="minusX"  oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @change="changeNum4($event)"  @focus="Focus" @blur="Blur"/>
                   </div>
                   <div class="y-axis axis">
                       <p>-Y</p>
                       <input :disabled="disabled" v-model="minusY" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @change="changeNum4($event)"  @focus="Focus" @blur="Blur"/>
                   </div>
                   <div class="z-axis axis">
                       <p>-Z</p>
                       <input :disabled="disabled" v-model="minusZ" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @change="changeNum4($event)"  @focus="Focus" @blur="Blur"/>
                   </div>
                   <div class="reset1" style="margin-right:0.32552vw"></div>
                   <div class="reset1"></div>
                   <div class="reset1"></div>
               </div>
                <div class="loction">
                   <div class="title">平移</div>
                   <div class="x-axis axis">
                       <p>X</p>
                       <input v-model="offsetX" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @change="changeNum5($event)"  @focus="Focus" @blur="Blur"/>
                   </div>
                   <div class="y-axis axis">
                       <p>Y</p>
                       <input v-model="offsetY" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @change="changeNum5($event)"  @focus="Focus" @blur="Blur"/>
                   </div>
                   <div class="z-axis axis">
                       <p>Z</p>
                       <input v-model="offsetZ" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @change="changeNum5($event)"  @focus="Focus" @blur="Blur"/>
                   </div>
                   <div class="reset1" style="margin-right:0.32552vw"></div>
                   <div class="reset1"></div>
                   <div class="reset1"></div>
               </div>
           </div>
        </div>
    </div>
</template>
<script>
import { ITransform } from "../../threejs/interface/ITransform";
import { ISetting } from "../../threejs/interface/ISetting";
import { ISelector } from "../../threejs/interface/ISelector";
export default {
    data(){
        return{
            boxState:true,
            hide:false,
            select:'',
            isActive:true,
            lockState:true,
            hideState:true,
            name:'',
            sceneId:"",
            modelState:"",
            positionX:0,
            positionY:0,
            positionZ:0,
            rotateX:0,
            rotateY:0,
            rotateZ:0,
            scaleX:0,
            scaleY:0,
            scaleZ:0,
            sizeX:0,
            sizeY:0,
            sizeZ:0,
            distanceX:0,
            distanceY:0,
            distanceZ:0,
            minusX:0,
            minusY:0,
            minusZ:0,
            offsetX:0,
            offsetY:0,
            offsetZ:0,
            type:"",//类型
            disabled:false,
            selectState:true,
            optionList:[
                {
                    value:1,
                    label:"动态实例"
                },
                {
                    value:2,
                    label:"静态实例"
                },

            ],
            layerSelect:'',
            layerList:[
                {
                    value:0,
                    label:"0"
                },
                {
                    value:1,
                    label:"1"
                },
                {
                    value:2,
                    label:"2"
                },
                {
                    value:3,
                    label:"3"
                },
                {
                    value:4,
                    label:"4"
                },
                {
                    value:5,
                    label:"5"
                },
                {
                    value:6,
                    label:"6"
                },
                {
                    value:7,
                    label:"7"
                },
                {
                    value:8,
                    label:"8"
                },
                {
                    value:9,
                    label:"9"
                },
                {
                    value:10,
                    label:"10"
                },

            ]
        }
    },
    mounted(){
        ITransform.f_selectTransformCallback = this.selectTransformChange.bind(this);
        ITransform.f_selectTransformNeedUpdateCallback = this.selectTransformNeedUpdatChange.bind(this);
    },
    methods:{
        shrinkChange(){
            if (this.isActive) {
              this.isActive = false
            } else {
              this.isActive = true;
            }
        },
        selectTransformChange(result){
            if(result){
                let transfrom = ITransform.getSelectTransform();
                if(transfrom != null){
                    // console.log(transfrom)
                    if(ISelector.m_transformMode == 0 || ISelector.m_transformMode == 1){
                        this.selectState = false;
                    }else{
                        this.selectState = true;
                    }
                    this.hide = true;
                    let block = transfrom.block;
                    let bvisible = transfrom.bvisible;
                    if(block){
                        this.lockState = false;
                    }else{
                        this.lockState = true;
                    }
                    if(bvisible){
                        this.hideState = true;
                    }else{
                        this.hideState = false;
                    }
                    this.name = transfrom.name;
                    this.sceneId = transfrom.id;
                    if(transfrom.instanceType == 0){
                        this.select = this.optionList[0].value;
                    }else if(transfrom.instanceType == 1){
                        this.select = this.optionList[0].value;
                    }else if(transfrom.instanceType == 2){
                        this.select = this.optionList[1].value;
                    }
                    //图层
                    this.layerSelect = transfrom.layer;
                    //位置
                    this.positionX = (Math.round(transfrom.position[0]*100)/100).toFixed(1);
                    this.positionY = (Math.round(transfrom.position[1]*100)/100).toFixed(1);
                    this.positionZ = (Math.round(transfrom.position[2]*100)/100).toFixed(1);
                    //旋转
                    this.rotateX = (Math.round(transfrom.rotation[0]*100)/100).toFixed(1);
                    this.rotateY = (Math.round(transfrom.rotation[1]*100)/100).toFixed(1);
                    this.rotateZ = (Math.round(transfrom.rotation[2]*100)/100).toFixed(1);
                    //缩放
                    this.scaleX = (Math.round(transfrom.scale[0]*100000)/100000).toFixed(4);
                    this.scaleY = (Math.round(transfrom.scale[1]*100000)/100000).toFixed(4);
                    this.scaleZ = (Math.round(transfrom.scale[2]*100000)/100000).toFixed(4);
                    //尺寸
                    this.sizeX = (Math.round(transfrom.size[0]*100)/100).toFixed(0);
                    this.sizeY = (Math.round(transfrom.size[1]*100)/100).toFixed(0);
                    this.sizeZ = (Math.round(transfrom.size[2]*100)/100).toFixed(0);
                    //距离正值
                    this.distanceX = (Math.round(transfrom.dirlength[0]*100)/100).toFixed(0);
                    this.distanceY = (Math.round(transfrom.dirlength[1]*100)/100).toFixed(0);
                    this.distanceZ = (Math.round(transfrom.dirlength[2]*100)/100).toFixed(0);
                    //距离负值
                    this.minusX = (Math.round(transfrom.dirlength[3]*100)/100).toFixed(0);
                    this.minusY = (Math.round(transfrom.dirlength[4]*100)/100).toFixed(0);
                    this.minusZ = (Math.round(transfrom.dirlength[5]*100)/100).toFixed(0);
                    //平移
                    this.offsetX = (Math.round(transfrom.move[0]*100)/100).toFixed(0);
                    this.offsetY = (Math.round(transfrom.move[1]*100)/100).toFixed(0);
                    this.offsetZ = (Math.round(transfrom.move[2]*100)/100).toFixed(0);

                    let type = transfrom.type;
                    // console.log(type)
                    if(type == 0){
                        this.type = "默认";
                        this.disabled = false;
                    }else if(type == 1){
                        this.type = "组";
                        this.disabled = false;
                    }else if(type == 2){
                        this.type = "网格";
                        this.disabled = false;
                    }else if(type == 3){
                        this.type = "平行光";
                        this.disabled = true;
                    }else if(type == 4){
                        this.type = "点光源"
                        this.disabled = true;
                    }else if(type == 5){
                        this.type = "聚光灯";
                        this.disabled = true;
                    }else if(type == 6){
                        this.type = "区域光";
                        this.disabled = true;
                    }else if(type == 7){
                        this.type = "透视相机";
                        this.disabled = false;
                    }else if(type == 8){
                        this.type = "正交相机";
                        this.disabled = false;
                    }else if(type == 9){
                        this.type = "物品";
                        this.disabled = false;
                    }else if(type == 10){
                        this.type = "模型";
                        this.disabled = false;
                    }else if(type == 11){
                        this.type = "模型网格";
                        this.disabled = false;
                    }else if(type == 12){
                        this.type = "曲线";
                        this.disabled = false;
                    }else if(type == 18){
                        this.type = "天空";
                        this.disabled = false;
                    }else if(type == 19){
                        this.type = "光晕";
                    }else if(type == 21){
                        this.type = "实时天空"
                    }else if(type == 22){
                        this.type = "天空平行光"
                    }else if(type == 23){
                        this.type = "粒子"
                    }else if(type == 24){
                        this.type = "地形"
                    }else if(type == 25){
                        this.type = "基础水"
                    }
                    if(type == 12 || type == 18 || type == 21 || type ==22){
                        this.boxState = false;
                    }else{
                        this.boxState = true;
                    }
                }else{
                    this.hide = false;
                }
                
            }else if(!result){
                this.hide = false;
            }
        },
        selectTransformNeedUpdatChange(){
            let transfrom = ITransform.getSelectTransform();
            if(transfrom != null){
                 //位置
                 //位置
                    this.positionX = (Math.round(transfrom.position[0]*100)/100).toFixed(1);
                    this.positionY = (Math.round(transfrom.position[1]*100)/100).toFixed(1);
                    this.positionZ = (Math.round(transfrom.position[2]*100)/100).toFixed(1);
                    //旋转
                    this.rotateX = (Math.round(transfrom.rotation[0]*100)/100).toFixed(1);
                    this.rotateY = (Math.round(transfrom.rotation[1]*100)/100).toFixed(1);
                    this.rotateZ = (Math.round(transfrom.rotation[2]*100)/100).toFixed(1);
                    //缩放
                    this.scaleX = (Math.round(transfrom.scale[0]*100000)/100000).toFixed(4);
                    this.scaleY = (Math.round(transfrom.scale[1]*100000)/100000).toFixed(4);
                    this.scaleZ = (Math.round(transfrom.scale[2]*100000)/100000).toFixed(4);
                    //尺寸
                    this.sizeX = (Math.round(transfrom.size[0]*100)/100).toFixed(0);
                    this.sizeY = (Math.round(transfrom.size[1]*100)/100).toFixed(0);
                    this.sizeZ = (Math.round(transfrom.size[2]*100)/100).toFixed(0);
                    //距离正值
                    this.distanceX = (Math.round(transfrom.dirlength[0]*100)/100).toFixed(0);
                    this.distanceY = (Math.round(transfrom.dirlength[1]*100)/100).toFixed(0);
                    this.distanceZ = (Math.round(transfrom.dirlength[2]*100)/100).toFixed(0);
                    //距离负值
                    this.minusX = (Math.round(transfrom.dirlength[3]*100)/100).toFixed(0);
                    this.minusY = (Math.round(transfrom.dirlength[4]*100)/100).toFixed(0);
                    this.minusZ = (Math.round(transfrom.dirlength[5]*100)/100).toFixed(0);
                    //平移
                    this.offsetX = (Math.round(transfrom.move[0]*100)/100).toFixed(0);
                    this.offsetY = (Math.round(transfrom.move[1]*100)/100).toFixed(0);
                    this.offsetZ = (Math.round(transfrom.move[2]*100)/100).toFixed(0);
            }
        },
        lockChange(){
            this.lockState = !this.lockState;
            ITransform.changeLock(false);
        },
        hideChange(){
            this.hideState = !this.hideState;
            ITransform.changeVisible(false)
        },
        changeNum0(){
            let transform = {
                position : [Number(this.positionX),Number(this.positionY),Number(this.positionZ)],
                rotation : [Number(this.rotateX),Number(this.rotateY), Number(this.rotateZ)],
                scale : [Number(this.scaleX),Number(this.scaleY), Number(this.scaleZ)],
                size:[Number(this.sizeX),Number(this.sizeY),Number(this.sizeZ)],
                dirlength:[Number(this.distanceX),Number(this.distanceY),Number(this.distanceZ),Number(this.minusX),Number(this.minusY),Number(this.minusZ)],
                move:[Number(this.offsetX),Number(this.offsetY),Number(this.offsetZ)]
            }
            ITransform.setSelectObjectTransform(transform,0);
        },
        changeNum1(){
            let transform = {
                position : [Number(this.positionX),Number(this.positionY),Number(this.positionZ)],
                rotation : [Number(this.rotateX),Number(this.rotateY), Number(this.rotateZ)],
                scale : [Number(this.scaleX),Number(this.scaleY), Number(this.scaleZ)],
                size:[Number(this.sizeX),Number(this.sizeY),Number(this.sizeZ)],
                dirlength:[Number(this.distanceX),Number(this.distanceY),Number(this.distanceZ),Number(this.minusX),Number(this.minusY),Number(this.minusZ)],
                move:[Number(this.offsetX),Number(this.offsetY),Number(this.offsetZ)]
            }
            ITransform.setSelectObjectTransform(transform,1);
        },
        changeNum2(e){
            // console.log(e)
            // if( this.scaleX == '-'){
            //     e.target.value = 1;
            // }
            let transform = {
                position : [Number(this.positionX),Number(this.positionY),Number(this.positionZ)],
                rotation : [Number(this.rotateX),Number(this.rotateY), Number(this.rotateZ)],
                scale : [Number(this.scaleX),Number(this.scaleY), Number(this.scaleZ)],
                size:[Number(this.sizeX),Number(this.sizeY),Number(this.sizeZ)],
                dirlength:[Number(this.distanceX),Number(this.distanceY),Number(this.distanceZ),Number(this.minusX),Number(this.minusY),Number(this.minusZ)],
                move:[Number(this.offsetX),Number(this.offsetY),Number(this.offsetZ)]
            }
            // console.log(transform)
            ITransform.setSelectObjectTransform(transform,2);
        },
        changeNum3(){
            let transform = {
                position : [Number(this.positionX),Number(this.positionY),Number(this.positionZ)],
                rotation : [Number(this.rotateX),Number(this.rotateY), Number(this.rotateZ)],
                scale : [Number(this.scaleX),Number(this.scaleY), Number(this.scaleZ)],
                size:[Number(this.sizeX),Number(this.sizeY),Number(this.sizeZ)],
                dirlength:[Number(this.distanceX),Number(this.distanceY),Number(this.distanceZ),Number(this.minusX),Number(this.minusY),Number(this.minusZ)],
                move:[Number(this.offsetX),Number(this.offsetY),Number(this.offsetZ)]
            }
            ITransform.setSelectObjectTransform(transform,3);
        },
        changeNum4(){
            let transform = {
                position : [Number(this.positionX),Number(this.positionY),Number(this.positionZ)],
                rotation : [Number(this.rotateX),Number(this.rotateY), Number(this.rotateZ)],
                scale : [Number(this.scaleX),Number(this.scaleY), Number(this.scaleZ)],
                size:[Number(this.sizeX),Number(this.sizeY),Number(this.sizeZ)],
                dirlength:[Number(this.distanceX),Number(this.distanceY),Number(this.distanceZ),Number(this.minusX),Number(this.minusY),Number(this.minusZ)],
                move:[Number(this.offsetX),Number(this.offsetY),Number(this.offsetZ)]
            }
            ITransform.setSelectObjectTransform(transform,4);
        },
        changeNum5(){
            let transform = {
                position : [Number(this.positionX),Number(this.positionY),Number(this.positionZ)],
                rotation : [Number(this.rotateX),Number(this.rotateY), Number(this.rotateZ)],
                scale : [Number(this.scaleX),Number(this.scaleY), Number(this.scaleZ)],
                size:[Number(this.sizeX),Number(this.sizeY),Number(this.sizeZ)],
                dirlength:[Number(this.distanceX),Number(this.distanceY),Number(this.distanceZ),Number(this.minusX),Number(this.minusY),Number(this.minusZ)],
                move:[Number(this.offsetX),Number(this.offsetY),Number(this.offsetZ)]
            }
            // console.log(transform)
            ITransform.setSelectObjectTransform(transform,5);
        },
        nameChange(){
            ITransform.setSelectObjectName(this.name)
        },
        Focus(){
            ISetting.isInput(true);
        },
        Blur(){
            ISetting.isInput(false);
        },
        resetChange(){
            this.positionX = 0;
            this.positionY = 0;
            this.positionZ = 0;
            let transform = {
                position : [Number(this.positionX),Number(this.positionY),Number(this.positionZ)],
                rotation : [Number(this.rotateX),Number(this.rotateY), Number(this.rotateZ)],
                scale : [Number(this.scaleX),Number(this.scaleY), Number(this.scaleZ)],
                size:[Number(this.sizeX),Number(this.sizeY),Number(this.sizeZ)],
                dirlength:[Number(this.distanceX),Number(this.distanceY),Number(this.distanceZ),Number(this.minusX),Number(this.minusY),Number(this.minusZ)],
                move:[Number(this.offsetX),Number(this.offsetY),Number(this.offsetZ)]
            }
            ITransform.setSelectObjectTransform(transform,0);
        },
        rotateChange(){
            this.rotateX = 0;
            this.rotateY = 0;
            this.rotateZ = 0;
            let transform = {
                position : [Number(this.positionX),Number(this.positionY),Number(this.positionZ)],
                rotation : [Number(this.rotateX),Number(this.rotateY), Number(this.rotateZ)],
                scale : [Number(this.scaleX),Number(this.scaleY), Number(this.scaleZ)],
                size:[Number(this.sizeX),Number(this.sizeY),Number(this.sizeZ)],
                dirlength:[Number(this.distanceX),Number(this.distanceY),Number(this.distanceZ),Number(this.minusX),Number(this.minusY),Number(this.minusZ)],
                move:[Number(this.offsetX),Number(this.offsetY),Number(this.offsetZ)]
            }
            ITransform.setSelectObjectTransform(transform,1);
        },
        scaleChange(){
            this.scaleX = 1;
            this.scaleY = 1;
            this.scaleZ = 1;
            let transform = {
                position : [Number(this.positionX),Number(this.positionY),Number(this.positionZ)],
                rotation : [Number(this.rotateX),Number(this.rotateY), Number(this.rotateZ)],
                scale : [Number(this.scaleX),Number(this.scaleY), Number(this.scaleZ)],
                size:[Number(this.sizeX),Number(this.sizeY),Number(this.sizeZ)],
                dirlength:[Number(this.distanceX),Number(this.distanceY),Number(this.distanceZ),Number(this.minusX),Number(this.minusY),Number(this.minusZ)],
                move:[Number(this.offsetX),Number(this.offsetY),Number(this.offsetZ)]
            }
            ITransform.setSelectObjectTransform(transform,2);
        },
        inputValue(e){
            let key = e.key;
            // if(key === "-"){
            //     e.returnValue = false;
            //     this.scaleX = 1;
            // }
            // if(key === "e" || key === "E" || key === "+"  || key === "-"){
            //      e.returnValue = false;
            // }else{
            //     e.returnValue = true;
            // }
        },
        sizeChange(){
            this.sizeX = 1;
            this.sizeY = 1;
            this.sizeZ = 1;
            let transform = {
                position : [Number(this.positionX),Number(this.positionY),Number(this.positionZ)],
                rotation : [Number(this.rotateX),Number(this.rotateY), Number(this.rotateZ)],
                scale : [Number(this.scaleX),Number(this.scaleY), Number(this.scaleZ)],
                size:[Number(this.sizeX),Number(this.sizeY),Number(this.sizeZ)],
                dirlength:[Number(this.distanceX),Number(this.distanceY),Number(this.distanceZ),Number(this.minusX),Number(this.minusY),Number(this.minusZ)],
                move:[Number(this.offsetX),Number(this.offsetY),Number(this.offsetZ)]
            }
            ITransform.setSelectObjectTransform(transform);
        },
        minusChange(){
            // this.minusX = 0;
            // this.minusY = 0;
            // this.minusZ = 0;
            // let transform = {
            //     position : [Number(this.positionX),Number(this.positionY),Number(this.positionZ)],
            //     rotation : [Number(this.rotateX),Number(this.rotateY), Number(this.rotateZ)],
            //     scale : [Number(this.scaleX),Number(this.scaleY), Number(this.scaleZ)],
            //     size:[Number(this.sizeX),Number(this.sizeY),Number(this.sizeZ)],
            //     dirlength:[Number(this.distanceX),Number(this.distanceY),Number(this.distanceZ),Number(this.minusX),Number(this.minusY),Number(this.minusZ)]
            // }
            // ITransform.setSelectObjectTransform(transform);
        },
        copy(){

            this.$message({
                message:"复制成功"
            })
        },
        selectChange(){
            if(this.select == 1){
                ITransform.setInstanceType(1)
            }else if(this.select == 2){
                ITransform.setInstanceType(2)
            }
        },
        layerChange(){
            ITransform.setLayer(this.layerSelect)
            // console.log(this.layerSelect)
        },
        onCopy(){
         this.$message({
             message:"复制成功"
            })
      },
        async copyChange(){
            var val = await navigator.clipboard.readText();
            this.positionX = Number(val.split(',')[0]);
            this.positionY = Number(val.split(',')[1]);
            this.positionZ = Number(val.split(',')[2]);
            let transform = {
                position : [Number(this.positionX),Number(this.positionY),Number(this.positionZ)],
                rotation : [Number(this.rotateX),Number(this.rotateY), Number(this.rotateZ)],
                scale : [Number(this.scaleX),Number(this.scaleY), Number(this.scaleZ)],
                size:[Number(this.sizeX),Number(this.sizeY),Number(this.sizeZ)],
                dirlength:[Number(this.distanceX),Number(this.distanceY),Number(this.distanceZ),Number(this.minusX),Number(this.minusY),Number(this.minusZ)],
                move:[Number(this.offsetX),Number(this.offsetY),Number(this.offsetZ)]
            }
            ITransform.setSelectObjectTransform(transform,0);
        },
        async copyChange1(){
            var val = await navigator.clipboard.readText();
            // console.log(val)
            this.rotateX = Number(val.split(',')[0]);
            this.rotateY = Number(val.split(',')[1]);
            this.rotateZ = Number(val.split(',')[2]);
            let transform = {
                position : [Number(this.positionX),Number(this.positionY),Number(this.positionZ)],
                rotation : [Number(this.rotateX),Number(this.rotateY), Number(this.rotateZ)],
                scale : [Number(this.scaleX),Number(this.scaleY), Number(this.scaleZ)],
                size:[Number(this.sizeX),Number(this.sizeY),Number(this.sizeZ)],
                dirlength:[Number(this.distanceX),Number(this.distanceY),Number(this.distanceZ),Number(this.minusX),Number(this.minusY),Number(this.minusZ)],
                move:[Number(this.offsetX),Number(this.offsetY),Number(this.offsetZ)]
            }
            ITransform.setSelectObjectTransform(transform,1);
        },
        async copyChange2(){
            var val = await navigator.clipboard.readText();
            // console.log(val)
            this.scaleX = Number(val.split(',')[0]);
            this.scaleY = Number(val.split(',')[1]);
            this.scaleZ = Number(val.split(',')[2]);
            let transform = {
                position : [Number(this.positionX),Number(this.positionY),Number(this.positionZ)],
                rotation : [Number(this.rotateX),Number(this.rotateY), Number(this.rotateZ)],
                scale : [Number(this.scaleX),Number(this.scaleY), Number(this.scaleZ)],
                size:[Number(this.sizeX),Number(this.sizeY),Number(this.sizeZ)],
                dirlength:[Number(this.distanceX),Number(this.distanceY),Number(this.distanceZ),Number(this.minusX),Number(this.minusY),Number(this.minusZ)],
                move:[Number(this.offsetX),Number(this.offsetY),Number(this.offsetZ)]
            }
            ITransform.setSelectObjectTransform(transform,2);
        },

    }
}
</script>
<style scoped>
.box{
    width: 80%;
    height: 380px;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    border-radius: 5px;
    margin-top: 20px;
    margin-left:70px;
    font-size: 10px;
    background: #ffff;
    overflow: hidden;
    margin-bottom: 50px;
}
.Name{
    width: 80%;
    height: 30px;
    border: 1px solid #fff;
    border-radius: 5px;
    margin-top: 8px;
    margin-left:70px;
    display: flex;
    align-items: center;
    background: #fff;
    overflow: hidden;
}
.Name .title{
    width: 70px;
    height: 20px;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    font-weight: bold;
    overflow: hidden;
}
.ipt_box{
    width: 81%;
    height: 28px;
    background: #FFFFFF;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
}
.Name input{
    width: 95%;
    height: 25px;
    border: none;
    border-radius: 5px;
    outline: none;
    font-size: 10px;
    background-color: rgba(0, 0, 0, 0);
    color: #808080;
}
.scene{
    width: 80%;
    height: 30px;
    border: 1px solid #fff;
    border-radius: 5px;
    margin-top: 20px;
    margin-left:70px;
    display: flex;
    align-items: center;
    background: #fff;
    overflow: hidden;
}
.scene .title{
    width: 70px;
    height: 20px;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    font-weight: bold;
    overflow: hidden;
}
.scene .id{
    font-size: 10px;
    color: #808080;
    margin-left: 9px;
}
.scene #copy{
    width: 50px;
    height: 28px;
    text-align: center;
    cursor: pointer;
    background: #f8f8f8;
    /* box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1); */
    border-right: 5px;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.scene .select{
    width: 100%;
    height: 28px;
    outline: none;
    color: #808080;
    border: none;
    border-radius:4px;
    font-size: 10px;
}
.select /deep/ .el-input{
   width: 100% !important;
   height: 28px !important;
   outline: none !important;
   color: #808080;
   font-size: 10px;
}
.select /deep/ .el-input__inner{
   width: 100% !important;
   height: 28px !important;
   outline: none !important;
   color: #808080;
}
.select /deep/ .el-select__caret{
  height: 100% !important;
  line-height: 28px !important;
}
#copy img{
    width: 15px;
    height: 15px;
}
.ipt_box1{
    width: 81%;
    height: 28px;
    background: #FFFFFF;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #808080;
}
.type{
    width: 88%;
    height: 30px;
    border: 1px solid #fff;
    border-radius: 5px;
    margin-top: 20px;
    margin-left:70px;
    display: flex;
    align-items: center;
    background: #fff;
    overflow: hidden;
}
.type .title{
    width: 60px;
    height: 20px;
    line-height: 20px;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    display: flex;
    align-items: center;
    overflow: hidden;
}
.type .title span{
    margin-left: 3px;
}
.type .img{
    width: 25px;
    height: 25px;
    /* margin-left: 10px; */
    cursor: pointer;
}
.type .img1{
    width: 14px;
    height: 14px;
    padding-left: 25px; 
    cursor: pointer;
}
.iptBox{
    width: 74%;
    height: 28px;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    border-radius: 6px;
    background-color: rgba(0, 0, 0, 0);
    display: flex;
    align-items: center;
    margin-left: 10px;
}
.type input{
    width: 85%;
    height: 25px;
    border: none;
    color: #808080;
    outline: none;
    font-size: 10px;
    text-indent: 7px;
}
.anmDiv{
transition: 1S;
 
}
.aaa{
height: 40px;
transition: 1S;
}
.header{
    display: flex;
    width: 90%;
    height: 40px;
    margin: auto;
    align-items: center;
    color: #000;
    font-weight: bold;
    font-size: 12px;
}
.header p{
    margin-left: 10px;
    overflow: hidden;
}
.header i{
    font-weight: bold;
    cursor: pointer;
}
.adjust{
    width: 100%;
    height: calc(100% - 30px);
    background: #fff;
    margin: 5px auto;
    overflow: hidden;
}
.loction{
    width: 95%;
    margin: 20px auto;
    display: flex;
    height: 25px;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
}
.loction .title{
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    font-weight: bolder;
    overflow: hidden;
}
.axis{
    width: 20%;
    height: 100%;
    display: flex;
    align-items: center;
    background-color:#F7F7F7;
    border-radius: 5px;

}
.axis p{
    width: 22px;
    height: 18px;
    line-height: 20px;
    text-align: center;
    color: #929292;
    background-color: #fff;
    margin-left: 2px;
    border-radius: 4px;
    float: left;
    color: #000000;
    font-size: 12px;
}
.axis input{
    width: 90%;
    height: 18px;
    text-align: center;
    outline: none;
    border: none;
    background-color: #F7F7F7;
    border-radius: 4px;
    font-weight: bold;
    font-size: 8px;
}
.x-axis p{
    background-color:#F20004;
}
.y-axis p{
    background-color: #43BD00;
}
.z-axis p {
    background-color: #5180E9;
}
.reset{
    width: 30px;
    height: 25px;
    text-align: center;
    line-height: 25px;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    border-radius: 5px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left:5px;
}
.reset img{
    width: 14px;
    height: 12px;
}
.reset1{
     width: 30px;
    height: 25px;
    text-align: center;
    line-height: 25px;
    /* box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1); */
    border-radius: 5px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.size::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
.lock{
    height: 100%;
    display: flex;
    align-items: center;
}
</style>