<template>
    <div>
        <div class="box">
            <div class="camera">
               <div class="title">选择类型</div>
               <select class="select">
                   <option value="0">投影相机</option>
                   <option value="0">正交相机</option>
               </select>
           </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{

        }
    }
}
</script>
<style scoped>
.box{
    width: 90%;
    height: 25px;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    border-radius: 5px;
    margin:8px auto;
    display: flex;
    align-items: center;
    background: #fff;
    font-size: 12px;
}
.box input{
    border: 0;
    background-color: none;
    outline: none;
    font-size: 10px;
    background-color: rgba(0, 0, 0, 0);
}
.camera{
    display: flex;
    width: 100%;
    height: 20px;
    align-items: center;
    margin: 7px auto;
    font-size: 12px;
}
.camera .title{
    width: 70px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-weight: bolder;
}
.camera .select{
    width: 185px;
    height: 20px;
    outline: none;
    color: #ccc;
    border: 1px solid #ccc;
    border-radius:4px ;
}
</style>