<template>
  <div class="boxOne" ref="ydCollapse" :style="{height: height}">
    <div class="header"  @click="handleActiveChange">
      <div><i class="el-icon-arrow-down" :class="active ? 'iconA' : 'iconU'" ></i> </div>
      <p>{{ title }}</p>
    </div>
    <slot></slot>
  </div>
</template>
<script>

export default {
  props: {
    activeProps: Boolean,
    title: String
  },
  data() {
    return {
      active: this.$props.activeProps,
      height: 'auto'
    }
  },
  methods: {
    handleActiveChange() {
      if(this.active) {
        this.height = this.$refs.ydCollapse.scrollHeight + 'px'

      }else {
        this.height = '2.5rem'
        console.log()
      }
      this.active = !this.active
    },
    updateHeight() {
      this.height = 'auto'

      console.log(this.height, this.$refs.ydCollapse.scrollHeight)
      this.height = this.$refs.ydCollapse.scrollHeight + 'px'
    }
  },
  mounted() {
    this.$EventBus.$on('updateYDCollapse', this.updateHeight)
  }
}
</script>
<style lang="less" scoped>
.boxOne{
  width: 80%;
  box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
  border-radius: 5px;
  margin-top: 20px;
  margin-left:70px;
  font-size: 10px;
  background: #ffff;
  transition: 0.8s;
  margin-bottom:20px;
  overflow: hidden;
  position: relative;
}
.header{
  display: flex;
  width: 90%;
  height: 25px;
  margin: 8px auto;
  align-items: center;
  color: #000;
  font-weight: bold;
  font-size: 12px;
}
.boxOne .header p{
  width: 100px;
  margin-left: 10px;
}
.header i{
  font-weight: bold;
  cursor: pointer;
}
.el-icon-arrow-down {
  transition: 0.8s;
}
.iconA {
  transform: rotate(-90deg);
}
.iconU {
  transform: rotate(0);
}
</style>