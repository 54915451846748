import * as THREE from "three";
import {GameObject} from "@/threejs/object/GameObject";

class ParticleSystemData {
    constructor() {
        // this.m_color = new THREE.Color(1, 1, 1);
        // this.m_scale = 4;
        // this.m_flowX = 1,
        //     this.m_flowY = 1
    }
    //
    // setJson(json)
    // {
    //     this.m_color.fromArray(json.m_color);
    //     this.m_scale = json.m_scale;
    //     this.m_flowX = json.m_flowX;
    //     this.m_flowY = json.m_flowY;
    // }
    //
    getJson(iterator)
    {
        const group = new THREE.Group()
        iterator.traverse(item => {
            if(item.type === "ParticleEmitter"){
                group.children.push(item)
            }
        })
        return group.toJSON()

    }

    getJsonForUI()
    {
        var json = {
            type:GameObject.GameObjectType.e_PARTICLE_SYS_EMITTER,
            m_data: {
                name: 'particle'
            }
            // m_color: "#" + this.m_color.getHexString(),
            // m_scale: this.m_scale,
            // m_flowX: this.m_flowX,
            // m_flowY: this.m_flowY
        }

        return json;
    }
}
export { ParticleSystemData };