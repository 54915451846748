// children: [{
//     value: 'ShipSmoke',
//     label: '烟雾'
// }, {
//     value: 'Levelup',
//     label: '升级'
// }, {
//     value: 'ShipTrail',
//     label: '尾迹'
// }, {
//     value: 'Explosion',
//     label: '小型爆炸'
// }, {
//     value: 'BigExplosion',
//     label: '大型爆炸'
// }, {
//     value: 'BulletMuzzle',
//     label: '喷射火花'
// }]

export default class IParticleExample {
    static TypeExample = {
        ShipSmoke: 0,
        Levelup: 1,
        ShipTrail: 2,
        Explosion: 3,
        BigExplosion: 4,
        BulletMuzzle: 5,

    }
    static TypeExampleName = ['烟雾','升级','尾迹','小型爆炸','大型爆炸','喷射火花']
}