import { RenderWebSocket } from "../network/RenderWebSocket"
import { MainObjects } from "../common/MainObjects"
import { GameObject } from "../object/GameObject"
import { ObjectsRepos } from "../factory/ObjectsRepos"
import { RenderLoader } from "../loader/RenderLoader"
import { INetwork } from "../interface/INetwork"
import { IRender } from "../interface/IRender"

class RenderManagerBp {
    constructor() {
        this.m_webSocket = null;
        this.m_geoRepos = new ObjectsRepos();
        this.m_imageRepos = new ObjectsRepos();
        this.m_renderLoaderRepos = new ObjectsRepos();
        this.m_data = null;
        this.m_mainCamera = null;
    }

    createSocket(b) {
        if(b)
        {
            if(this.m_webSocket != null)
            {
                this.m_webSocket.m_websocket.close();
            }
            console.log(MainObjects.Setting.m_ip, MainObjects.Setting.m_port)
            this.m_webSocket = new RenderWebSocket(MainObjects.Setting.m_ip, MainObjects.Setting.m_port);
            this.m_webSocket.m_openCallback = this.open.bind(this);
            this.m_webSocket.m_messageCallback = this.message.bind(this);
            this.m_webSocket.m_errorCallback = this.error.bind(this);
        }
        else{
            if(this.m_webSocket != null)
            {
                this.m_webSocket.m_websocket.close();
                this.m_webSocket = null;
            }
        }
    
    }

    open() {
        var s = "链接成功";
        var _this = this;
        this.clearAll();
        if(INetwork.f_startSocketCallback != null)
        {
            INetwork.f_startSocketCallback(true);
        }
        if(IRender.f_renderLogCallback != null)
        {  
            IRender.f_renderLogCallback(s);
        }
      
    }

    sentMessage(type)
    {
        if(this.m_webSocket == null)
        {
            if(IRender.f_renderLogCallback != null)
            {  
                IRender.f_renderLogCallback("需要先启动链接");
            }
            return;
        }
        if(this.m_webSocket.getState() != 1)
        {
            if(IRender.f_renderLogCallback != null)
            {  
                IRender.f_renderLogCallback("链接未建立");
            }
            return;
        }
        RenderLoader.sentStop(this.m_webSocket, true, true);
        RenderLoader.sentEnv(this.m_webSocket);
        for (const iterator of MainObjects.Scene.m_userGroup.children) {
            if(iterator.userData != null)
            {
                this.getRenderObjects(iterator, type);
            }
        }
        if(IRender.f_renderLogCallback != null)
        {
            var s = "完成数据发送";
            IRender.f_renderLogCallback(s);
        }
        RenderLoader.sentStart(this.m_webSocket);
    }

    clearAll(){
        this.m_geoRepos.clear();
        this.m_imageRepos.clear();
        this.m_renderLoaderRepos.clear();
        this.m_mainCamera = MainObjects.Camera.m_renderCamera;
        RenderLoader.sentStop(this.m_webSocket, true, true);
    }

    getRenderObjects(iterator, type)
    {
        if (iterator.userData.m_gameObjectType == GameObject.GameObjectType.e_GROUP) {
            for (const child of iterator.children) {
                if(child.userData != null)
                {
                    this.getRenderObjects(child, type);
                }
            }
        }
        else if(iterator.userData.m_gameObjectType == GameObject.GameObjectType.e_GLTF)
        {
            for (var mesh of iterator.userData.m_gltf_meshList) {
                this.sentBaseData(mesh);
            }
        }
        else if(iterator.userData.m_gameObjectType == GameObject.GameObjectType.e_CAMERA_PERSP || 
            iterator.userData.m_gameObjectType == GameObject.GameObjectType.e_CAMERA_ORTHO || 
            iterator.userData.m_gameObjectType == GameObject.GameObjectType.e_CAMERA_EQUIRECT)
        {
            if(iterator.userData.m_mainCamera){
                RenderLoader.sentCamera(iterator, this.m_webSocket);
            }
        }
        else if(iterator.userData.m_gameObjectType == GameObject.GameObjectType.e_DIR_LIGHT 
            || iterator.userData.m_gameObjectType == GameObject.GameObjectType.e_SKY_DIR_LIGHT)
        {
            RenderLoader.sentDirLight(iterator, this.m_webSocket);
        }
        else if(iterator.userData.m_gameObjectType == GameObject.GameObjectType.e_AREA_LIGHT)
        {
            RenderLoader.createAreaLight(iterator, this.m_webSocket);
        }
        else if(iterator.userData.m_gameObjectType == GameObject.GameObjectType.e_SPOT_LIGHT)
        {
            RenderLoader.sentSpotLight(iterator, this.m_webSocket);
        }
        else if(iterator.userData.m_gameObjectType == GameObject.GameObjectType.e_POINT_LIGHT)
        {
            RenderLoader.sentPointLight(iterator, this.m_webSocket);
        }
    }

    sentBaseData(mesh)
    {
        var rlder = new RenderLoader(mesh, this.m_webSocket);
        this.m_renderLoaderRepos.add(rlder.m_id, rlder);
        if(this.m_geoRepos.get(mesh.geometry.id) == null || this.m_geoRepos.get(mesh.geometry.id) == undefined)
        {
            rlder.sentMesh(mesh.geometry);
            this.m_geoRepos.add(mesh.geometry.id, mesh.geometry);
        }
        if(mesh.material.map != null)
        {
            if(this.m_imageRepos.get(rlder.getImageId(mesh.material.map)) == null || this.m_imageRepos.get(rlder.getImageId(mesh.material.map)) == undefined)
            {
                rlder.sentImage(mesh.material.map);
                this.m_imageRepos.add(rlder.getImageId(mesh.material.map), mesh.material.map);
            }
            rlder.sentTexture(mesh.material.map);
        }

        if(mesh.material.roughnessMap != null)
        {
            if(this.m_imageRepos.get(rlder.getImageId(mesh.material.roughnessMap)) == null || this.m_imageRepos.get(rlder.getImageId(mesh.material.roughnessMap)) == undefined)
            {
                rlder.sentImage(mesh.material.roughnessMap);
                this.m_imageRepos.add(rlder.getImageId(mesh.material.roughnessMap), mesh.material.roughnessMap);
            }
            rlder.sentTexture(mesh.material.roughnessMap);
        }

        if(mesh.material.metalnessMap != null)
        {
            if(this.m_imageRepos.get(rlder.getImageId(mesh.material.metalnessMap)) == null || this.m_imageRepos.get(rlder.getImageId(mesh.material.metalnessMap)) == undefined)
            {
                rlder.sentImage(mesh.material.metalnessMap);
                this.m_imageRepos.add(rlder.getImageId(mesh.material.metalnessMap), mesh.material.metalnessMap);
            }
            rlder.sentTexture(mesh.material.metalnessMap);
        }

        if(mesh.material.emissiveMap != null)
        {
            if(this.m_imageRepos.get(rlder.getImageId(mesh.material.emissiveMap)) == null || this.m_imageRepos.get(rlder.getImageId(mesh.material.emissiveMap)) == undefined)
            {
                
                rlder.sentImage(mesh.material.emissiveMap);
                this.m_imageRepos.add(rlder.getImageId(mesh.material.emissiveMap), mesh.material.emissiveMap);
            }
            rlder.sentTexture(mesh.material.emissiveMap);
        }

        if(mesh.material.normalMap != null)
        {
            if(this.m_imageRepos.get(rlder.getImageId(mesh.material.normalMap)) == null || this.m_imageRepos.get(rlder.getImageId(mesh.material.normalMap)) == undefined)
            {
                rlder.sentImage(mesh.material.normalMap);
                this.m_imageRepos.add(rlder.getImageId(mesh.material.normalMap), mesh.material.normalMap);
            }
            rlder.sentTexture(mesh.material.normalMap);
        }
        rlder.sentMaterial(mesh.material);
        rlder.sentObject();
    }

    error(){
        var s = "链接失败";
        if(INetwork.f_startSocketCallback != null)
        {
            INetwork.f_startSocketCallback(false);
        }
        if(IRender.f_renderLogCallback != null)
        {
            IRender.f_renderLogCallback(s);
        }
    }

    message(ev) {
        var message = ev.data;
        var data = JSON.parse(message);
        if (this.m_data == null) {
            this.m_data = data;
        }
        if (data.bEnd) {
            var cmd_message = data.cmd;
            this.m_data = null
        }
        else {
            this.m_data.data = this.m_data.data + data.data;
        }
    }


}

export { RenderManagerBp };
