import * as THREE from 'three';
import { MainObjects } from '../common/MainObjects';
import { GameObject } from '../object/GameObject';
class AudioListenerBp {
    constructor() {
        MainObjects.Scene.m_listener = new THREE.AudioListener();
        MainObjects.Camera.m_renderCamera.add(MainObjects.Scene.m_listener);
    }

    addToCamera(){
        MainObjects.Camera.m_renderCamera.add(MainObjects.Scene.m_listener);
    }

    getAudioProperty(obj)
    {
        var audioProp = {
            type: obj.userData.m_gameObjectType,
            bShowRange : obj.userData.m_gameObjectHelper.visible,
            bPlay : obj.userData.m_sound.isPlaying,
            bLoop : false,
            rolloffFactor : 0,
            refDistance : 0,
        }

        audioProp.rolloffFactor = obj.userData.m_sound.getRolloffFactor();
        audioProp.refDistance = obj.userData.m_sound.getRefDistance();
        audioProp.bLoop = obj.userData.m_sound.getLoop ();
        return audioProp;
    }
    setAudioProperty(obj, audioProp)
    {
        if(audioProp == undefined)
        {
            return;
        }
        obj.userData.m_sound.setRolloffFactor(audioProp.rolloffFactor);
        obj.userData.m_sound.setRefDistance(audioProp.refDistance);
        if(audioProp.bShowRange != undefined)
        {
            obj.userData.m_gameObjectHelper.visible = audioProp.bShowRange;
        }
        if(audioProp.bPlay != undefined)
        {
            if(audioProp.bPlay)
            {
                obj.userData.m_sound.play();
            }
            else
            {
                obj.userData.m_sound.pause();
            }
        }
        if(audioProp.bLoop != undefined)
        {
            obj.userData.m_sound.setLoop(audioProp.bLoop);
        }
    }

    remove(obj)
    {
        obj.userData.m_sound.pause();
    }

      
    closeAllAudio()
    {
        //1: get all audio
        var list = MainObjects.Blueprint.m_sceneManagerBp.getTypeObjects(GameObject.GameObjectType.e_AUDIO);
        
        if (list) {
            
            for (let index = 0; index < list.length; index++) {
                this.remove(list[index])

            }
        }

        //FOR
        //2: this.remove
    }

}

export { AudioListenerBp };