<template>
  <div class="main" @pointerup.stop>
    <div class="main-controller">
      <div class="controller-speed">
        <div class="input">
          <div>请输入数值</div>
          <input type="number" @change="handleInputChange" :value="playSpeed"/>
        </div>
      </div>
      <div class="controller-buttons">
        <div class="restart" @click.stop="handleRestartClick">
          <img :src="'/img/restart.png'">
        </div>
        <div class="playOrPause" @click.stop="handleToggleStatusClick">
          <img v-show="!playState" :src="'/img/pause.png'">
          <img v-show="playState" :src="'/img/play.png'">

        </div>

        <div class="stop" @click.stop="handleStopClick">
          <img :src="'/img/stop.png'">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {MainObjects} from "@/threejs/common/MainObjects";
import {ParticleEmitter} from "three.quarks";

export default {
  props: {
    playSpeed: Number,
    setPlaySpeed: Function,
  },
  data() {
    return {
      playState: false
    }
  },
  methods: {
    handleToggleStatusClick() {
      this.playState = !this.playState
      const systems = this.getSystems();
      const paused = this.checkPause(systems);
      if (paused) {
        systems.forEach(system => {
          system.play();
        });
      } else {
        systems.forEach(system => {
          system.pause();
        });
      }
    },
    checkPause(systems) {
      let paused = true;
      systems.forEach(system => {
        if (!system.paused) {
          paused = false;
        }
      });
      return paused;
    },
    handleRestartClick() {
      this.getSystems().forEach((system) => {
        system.restart();
      });

    },
    handleStopClick() {
      this.getSystems().forEach((system) => {
        system.restart();
        system.pause();
      });
    },
    handleInputChange(event) {
      const value = parseFloat(event.currentTarget.value)
      if(typeof value !== 'number' || isNaN(value)) return
      this.$props.setPlaySpeed(value)
      MainObjects.Setting.m_batchRender_speed = value
    },

    getSystems() {
      const object3d = MainObjects.Render.m_outlinePass.selectedObjects[0]
      const systems = [];
      if (object3d) {
        if (object3d instanceof ParticleEmitter) {
          systems.push(object3d.system);
        }
        object3d.traverse(object => {
          if (object instanceof ParticleEmitter) {
            systems.push(object.system);
          }
        });
      }

      return systems;
    }

  }

}

</script>

<style lang="less" scoped>
.main {
  padding-bottom: 50px;
  .main-controller {
    color: black;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    .controller-speed {
      .title {

      }
      .input {
        margin-top: 10px;
        display: flex;
        align-items: center;
        input {
          width: 240px;
          margin-left: 20px;
        }
      }
    }
    .controller-buttons {
      margin-top: 20px;
      display: flex;
      box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);

      .playOrPause, .restart, .stop{
        width: 115px;
        height: 40px;
        border-radius: 5px;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 5px;
        cursor: pointer;

        img {
          width: 18px;
          height: 18px;
        }
      }
      .playOrPause {

      }
      .restart {

      }
      .stop {
        margin-right: 10px;

      }
    }
  }
  input {
    outline: none;
    color: #808080;
    border: none;
    box-shadow: 0 0 0.125rem 0 rgba(33, 35, 49, 0.1);
    border-radius: 0.375rem;
  }

}


</style>
