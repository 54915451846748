<template>


<!--  <Object3DSelect v-else-if="type === 'mesh'" :name="fieldName" :listableTypes="['Mesh', 'ParticleEmitter']" @change="onChangeObject3D" :value="target[fieldName]"/>-->
<!--  <Object3DSelect v-else-if="type === 'particleSystem'" :name="fieldName" :listableTypes="['ParticleEmitter']" @change="onChangeObject3D" :value="target[fieldName]"/>-->
  <div>
    <div v-if="type === 'radian'" class="property">
      <label class="name"><FunI18n :text="fieldName" /></label>
      <NumberInput  :unitConversion="180 / Math.PI"  :value="target[fieldName]" :onChange="onChangeNumber"/>

    </div>
    <div v-else-if="type === 'number'" class="property">
      <label class="name"><FunI18n :text="fieldName"/></label>
      <NumberInput :value="target[fieldName]" :onChange="onChangeNumber"/>

    </div>
    <div v-else-if="type === 'boolean'" class="property">
      <label class="name"><FunI18n :text="fieldName"/></label>
      <el-select :value="target[fieldName]" @change="onChangeBoolean" >
        <el-option
            v-for="item in boolOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>

      </el-select>
    </div>
     <Vector3Editor v-else-if="type === 'vec3'" :name="fieldName"
                     :x="target[fieldName].x"
                     :y="target[fieldName].y"
                     :z="target[fieldName].z" :onChange="onChangeVec3" />
    <GeneratorEditor v-else-if="type === 'valueFunc'"
                     :name="fieldName"
                     :allowedType="[type, 'value']"
                     :value="target[fieldName]"
                     :onChange="onChangeBehaviorFunc"/>
    <GeneratorEditor v-if="['colorFunc', 'rotationFunc', 'value'].includes(type)"
                     :name="fieldName"
                     :allowedType="[type]"
                     :value="target[fieldName]"
                     :onChange="onChangeBehaviorFunc"/>
  </div>
</template>
<script>
import FunI18n from "@/views/component/FunI18n.vue";
import GeneratorEditor from "@/views/ParticleSystem/Editors/GeneratorEditor.vue";
import NumberInput from "@/views/component/NumberInput.vue";
import {t} from "@/util/lang";
import Vector3Editor from "@/views/ParticleSystem/Editors/Vector3Editor.vue";

export default {
  props: {
    target: Object,
    onChange: Function,
    fieldName: String,
    type: String,
  },
  data() {
    const boolOptions =  [{value: true, label: t('true')}, {value: false, label: t('false')}]

    return {
      boolOptions
    }
  },
  components: {
    NumberInput,
    GeneratorEditor,
    FunI18n,
    Vector3Editor
  },
  methods: {
    onChangeBehaviorFunc(generator) {
      const { target, onChange, fieldName, type} = this.$props

      target[fieldName] = generator;
      onChange(target[fieldName]);
    },
    onChangeNumber(x) {
      const { target, onChange, fieldName, type} = this.$props
      console.log(x)
      target[fieldName] = x;
      onChange(target[fieldName]);

    },
    onChangeBoolean(x) {
      const { target, onChange, fieldName, type} = this.$props

      target[fieldName] = x;
      onChange(target[fieldName]);
    },
    onChangeObject3D(x) {
      const { target, onChange, fieldName, type} = this.$props

      target[fieldName] = x;
      onChange(target[fieldName]);
    },
    onChangeVec3(x, y, z) {
      const { target, onChange, fieldName, type} = this.$props

      target[fieldName].x = x;
      target[fieldName].y = y;
      target[fieldName].z = z;
      onChange(target[fieldName]);
    },
  },
};
</script>
<style lang="less">


</style>