<template>
  <div class="property vector3-editor">
    <div class="label name">
      <FunI18n :text="name" />
    </div>
    <div class="label">x:</div>
    <NumberInput class="short-input" :value="x" :unitConversion="unitConversion" :onChange="handleXChange" />
    <div class="label">y:</div>
    <NumberInput class="short-input" :value="y" :unitConversion="unitConversion" :onChange="handleYChange" />
    <div class="label">z:</div>
    <NumberInput class="short-input" :value="z" :unitConversion="unitConversion" :onChange="handleZChange" />
  </div>
</template>

<script>
import GeneratorEditor from "@/views/ParticleSystem/Editors/GeneratorEditor.vue";
import FunI18n from "@/views/component/FunI18n.vue";
import NumberInput from "@/views/component/NumberInput.vue";

export default {
  name: 'Vector3Editor',
  props: {
    x: Number,
    y: Number,
    z: Number,
    unitConversion: {
      type: Number,
      default: 1
    },
    name: String,
    onChange: Function
  },
  methods: {
    handleXChange(value) {
      this.onChange(value, this.y, this.z);
    },
    handleYChange(value) {
      this.onChange(this.x, value, this.z);
    },
    handleZChange(value) {
      this.onChange(this.x, this.y, value);
    }
  },
  components: {
    FunI18n,
    NumberInput
  }
};
</script>
<style lang="less" scoped>
.label {
  font-size: 15px;
}
.vector3-editor {
  width: 100%;
  .short-input {
    width: 10%;
    margin-left: 5px;
    margin-right: 15px;
  }

}
</style>