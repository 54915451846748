import { ObjectsRepos } from "../factory/ObjectsRepos"
import { GameObject } from "./GameObject";
import { MainObjects } from "../common/MainObjects";
import { IRender } from "../interface/IRender";
import { IBehavior } from "../interface/IBehavior";
import * as THREE from 'three';

class GameScene {
    static MatKey =
        {
            e_CAMERA_ICON : "cameraMaterial",
            e_CAMERA_EQUIRECT_ICON : "cameraEquirectMaterial",
            e_CAMERA_PROJECT_EQUIRECT_ICON : "cameraProjectEquirectMaterial",
            e_CAMERA_PROJECT_ICON : "cameraProjectMaterial",
            e_LIGHT_ICON: "lightMaterial",
            e_AUDIO_ICON: "audioMaterial",
            e_POINT_ICON : "pointMaterial",
            e_LIT_POINT_ICON : "litPointMaterial",
            e_CURVE_ICON: "curveMaterial",
            e_LINE: "lineMaterial",
            e_BASE_MAT : "baseMaterial",
            e_BASE_WIREFRAME_MAT : "baseWireframeMaterial",
            e_BASE_DOUBLE_MAT : "baseDoubleMaterial",
            e_COLLISION_WIREFRAME_MAT : "collisionWireframeMaterial",
        };

    static GeoKey =
        {
            e_CUBE_0_1: "cubeGeometry0_1",
            e_LINE: "lineGeometry",
            e_LINE_PLAN_0_1: "linePlanGeometry0_1",
            e_LINE_PLAN_1: "linePlanGeometry1",
            e_LINE_0_3: "lineGeometry0_3",
            e_SPHERE: "sphereGeometry",
            e_LINE_CONE: "lineConeGeometry"
        };

    constructor() {
        //url / gltf
        this.m_gltfRepos = new ObjectsRepos();
        //url / texture
        this.m_textureRepos = new ObjectsRepos();
        //type / mesh
        this.m_geoRepos = new ObjectsRepos();
        //id / mat
        this.m_matRepos = new ObjectsRepos();
        //url / mp3
        this.m_mp3Repos = new ObjectsRepos();
        //can select mesh
        this.m_selectAllMeshList = new Array();
        //bounding box list
        this.m_boundingBoxList = new Array();
        //animation mix
        this.m_animationMixList = new Array();
        //all camera
        this.m_cameraRepos = new ObjectsRepos();
        //all sim
        this.m_simRepos = new ObjectsRepos();
        //all real Light
        this.m_realLightList = new Array();
        //id / geo shap
        this.m_geoShapRepos = new ObjectsRepos();
    }

    removeRepos(key)
    {
        this.m_textureRepos.remove(key);
        this.m_gltfRepos.remove(key);
    }

    getRealLight(light)
    {
        var _this = this;
        if(light.isLight && light.visible)
        {
            var s = _this.m_realLightList.indexOf(light);
            if(s == -1){
                _this.m_realLightList.push(light);  
            }
        }
    }

    removeRealLight(light)
    {
        var _this = this;
        if(light.isLight)
        {
            var s = _this.m_realLightList.indexOf(light);
            if(s != -1)
            {
                _this.m_realLightList.splice(s, 1);
            }
        }
    }

    getAllSelectObjects(group) {
        if(group.userData.m_gameObjectType == GameObject.GameObjectType.e_GROUP)
        {
            return;
        }
        var _this = this;
        group.traverse(function (child) {
            if ((child.isMesh || child.isSprite) && !child.isSkinnedMesh && child.type != 'BoundingBoxHelper') {
                var s = _this.m_selectAllMeshList.indexOf(child);
                if(s == -1){
                    if (child.visible) {
                        _this.m_selectAllMeshList.push(child);
                    }
                    else {
                        if (child.parent != null && child.parent.userData != null && child.parent.userData.m_gameObjectType != undefined) {
                            if (child.parent.userData.m_gameObjectType == GameObject.GameObjectType.e_GLTF) {
                                if (child.parent.visible) {
                                    _this.m_selectAllMeshList.push(child);
                                }
                            }
                        }
                    }
                }
                if(child.isMesh)
                {
                    if(child.userData)
                    {
                        if(child.userData.m_gameObjectType != GameObject.GameObjectType.e_SELECT_HELPER_BOX)
                        {
                            if(MainObjects.Setting.m_renderMode == IRender.RenderMode.e_SOLID)
                            {
                                child.material.wireframe = false;
                            }
                            else
                            {
                                child.material.wireframe = true;
                            }
                        }
                    }
                   
                }
            }
        });
        if(group.userData.m_gameObjectType == GameObject.GameObjectType.e_GLTF)
        {
            if(group.userData.m_animationMixer != null)
            {
                var s = _this.m_animationMixList.indexOf(group);
                if(s == -1){
                    _this.m_animationMixList.push(group);
                }
            }
        }
        if(!group.isLight && !group.isCamera)
        {
            if(group.userData.m_boudingBoxHelper != null){
                var s = _this.m_boundingBoxList.indexOf(group.userData.m_boudingBoxHelper);
                if(s == -1){
                    _this.m_boundingBoxList.push(group.userData.m_boudingBoxHelper.m_cube);
                }
            }
        }
        else
        {
            this.getRealLight(group);
        }
    }

    removeAllSelectObjects(group)
    {
        if(group.userData.m_gameObjectType == GameObject.GameObjectType.e_GROUP)
        {
            return;
        }
        var _this = this;
        group.traverse(function (child) {
            if ((child.isMesh || child.isSprite) && !child.isSkinnedMesh && child.type != 'BoundingBoxHelper') {

                var s = _this.m_selectAllMeshList.indexOf(child);
                if(s != -1)
                {
                    _this.m_selectAllMeshList.splice(s, 1);
                }
            }
        });
        if(group.userData.m_gameObjectType == GameObject.GameObjectType.e_GLTF)
        {
            if(group.userData.m_animationMixer != null)
            {
                var s = _this.m_animationMixList.indexOf(group);
                if(s != -1){
                    _this.m_animationMixList.splice(s, 1);
                }
            }
        }
        if(!group.isLight && !group.isCamera)
        {
            if(group.userData.m_boudingBoxHelper != null){
                var s = _this.m_boundingBoxList.indexOf(group.userData.m_boudingBoxHelper.m_cube);
                if(s != -1){
                    _this.m_boundingBoxList.splice(s, 1);
                }
            }
        }
        else
        {
            this.removeRealLight(group);
        }
    }

    getMainCameraOnlyOne(id)
    {
        if(this.m_cameraRepos.m_objectsMap.size == 1)
        {
            if(this.m_cameraRepos.get(id) != null && this.m_cameraRepos.get(id) != undefined)
            {
                return true;
            }
        }
        return false;
    }

    setMainCamera(id)
    {
        var array = new Array();
        for(var [key, value] of this.m_cameraRepos.m_objectsMap)
        {
            value.userData.m_mainCamera = false;
            if(key == id)
            {
                value.userData.m_mainCamera = true;
            }
            else
            {
                array.push(key);
            }
        }
        return array;
    }

    getMainCamera()
    {
        for(var [key, value] of this.m_cameraRepos.m_objectsMap)
        {
            if(value.userData.m_mainCamera)
            {
                return value;
            }
        }
        return null;
    }

    update(delta)
    {
        for(var mix of this.m_animationMixList)
        {
            if(mix.visible)
            {
                var posTemp = new THREE.Vector3();
                mix.getWorldPosition(posTemp)
                var length = MainObjects.Camera.m_renderCamera.position.distanceTo (posTemp);
                if(length < 30)
                {
                    mix.userData.m_animationMixer.update(delta);
                    mix.updateMatrixWorld(true);
                }
            }
        }
        for(var [key, value] of this.m_simRepos.m_objectsMap)
        {
            var behavior = value.userData.m_behavior;
            if(behavior.get(IBehavior.m_curveBehavior) != null && behavior.get(IBehavior.m_curveBehavior) != undefined)
            {
                var posTemp = new THREE.Vector3();
                value.getWorldPosition(posTemp)
                var length = MainObjects.Camera.m_renderCamera.position.distanceTo (posTemp);
                if(length < 50)
                {
                    behavior.get(IBehavior.m_curveBehavior).update(value);
                }
            }
        }
        var lightCount = 0;
        for(var light of this.m_realLightList)
        {
            if(lightCount < 5)
            {
                light.visible = true;
            }
            else
            {
                light.visible = false;
            }
            lightCount++;
        }
    }
}
export { GameScene };