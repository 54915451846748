<template>
  <div id="app">
   <!-- <router-view/> -->
   <router-view v-if="isRouterAlive"></router-view>
  </div>
</template>

<script>


export default {
  name: 'app',
  provide(){
    return{
      reload:this.reload
    }
  },
  data(){
    return{
      isRouterAlive:true,
      timer:null
    }
  },
  mounted(){

    this.getOSSToken()
    this.timer = setInterval(()=>{
      this.getOSSToken()
    }, 1000*60*10);

  },
  created() {
    // // 页面加载时，读取sessionStorage中的状态信息
    // if(sessionStorage.getItem("store")) {
    //   this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem('store'))))
    // }
    // // 在页面刷新时，将vuex中的信息保存到sessionStorage里
    // window.addEventListener("beforeunload", () => {
    //   sessionStorage.setItem("store", JSON.stringify(this.$store.state))
    // })


  },
  befordestory(){
      if (this.timer) {
        clearInterval(this.timer)
      }
  },
  methods:{
    reload(){
      this.isRouterAlive=false;
      this.$nextTick(function() {
        this.isRouterAlive=true
        
      })
    },
    // 获取oss临时Token
    getOSSToken(){
      console.log("huoql");
      this.$https.getOSSToken({
          sessionName:"yida"
        }).then((res)=>{
          if(res.success==true){
              var OssTokenObg={}
    
              OssTokenObg.accessKeyId=res.data.accessKeyId
              OssTokenObg.accessKeySecret=res.data.accessKeySecret
              OssTokenObg.stsToken=res.data.securityToken
    
              
              localStorage.setItem("OssTokenObg",JSON.stringify(OssTokenObg))
    
              
          }else{
              console.error("获取临时ossToken失败");
          }

        })
    }

  },
  components: {
    
  }
}
</script>

<style>
html,body {
  height: 100%;
}
#app {
  height: 100%;

  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.el-message-box{
  border-radius: 10px!important;
  width: 420px!important;
  height: 200px!important;
  
}
.el-message-box .el-input__inner{
  background: #fff;
  border:none;
  box-shadow: 0px 0px 4px 0px rgba(33, 35, 49, 0.2);
  border-radius: 8px;
   font-size: 12px!important;
}

.el-message-box  input::-webkit-input-placeholder {
    color: rgba(80, 80, 80, .8); 
  }

 .el-message-box .el-message-box__errormsg{
   margin-top: 6px;
   margin-left: 2px;
 }

.el-message-box .el-button{
  width: 150px!important;
  height: 50px!important;
  
}
.el-message-box__content{
  height: 70px;
  width: 380px;
  margin: 0 auto;
}
.el-message-box__btns{
  margin-top: 0px;
}
.el-message-box__btns .el-button:first-child {
    width: 150px!important;
  height: 40px!important;
  transform: translateX(140px);
  border-radius: 8px;
  border:none;
  box-shadow: 0px 0px 4px 0px rgba(33, 35, 49, 0.1);
}
.el-message-box__btns .el-button:last-child {
  width: 150px!important;
  height: 40px!important;
  transform: translateX(-220px);
 background-color: #0560fd;
  border-radius: 8px;
  border:none;
  box-shadow: 0px 0px 4px 0px rgba(33, 35, 49, 0.2);
}

.el-message-box--center .el-message-box__header{
  padding-top: 15px!important;
  padding-bottom: 15px!important;

}

.el-message-box__title{
   font-size: 14px!important;
   margin-top: 5px;

}
.el-message-box__headerbtn{
  font-size: 22px!important;
  width: 20px;
  color: #333333!important;
}
.el-message-box__headerbtn .el-message-box__close{
    font-size: 22px!important;
  width: 20px;
  color: #333333!important;
}

/* 轮播指示 */

.el-carousel__indicator--horizontal .el-carousel__button {
    width: 10px;
    height: 10px;
    background: transparent;
    border: 1px solid #ffffff;
    border-radius: 50%;
    /* opacity: 0.5; */
    background: #ffffff;


  }  
 .el-carousel__indicator--horizontal.is-active .el-carousel__button{
    width: 10px;
    height: 10px;
    background: RGBA(17, 104, 253, 1);
    border-radius: 50%;
    opacity: 1;
  }
</style>
