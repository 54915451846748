import { ObjectsRepos } from "../factory/ObjectsRepos";

class BehaviorBp {
    constructor() {
        this.m_behavior = new ObjectsRepos();
    }

    addBehavior(object) {
        this.m_behavior.add(object, object);
    }

    init() {
        for (var [key, value] of this.m_behavior.m_objectsMap) {
            value.userData.initBehavior(value);
        }
    }
}

export { BehaviorBp }