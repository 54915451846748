<template>
  <div class="property-container">
    <div class="property">
      <div class="name"><FunI18n :text="'c7e57'"></FunI18n> </div>
      <el-select
          v-model="selectedType"
          @change="onChangeShape"
      >
        <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>

    </div>
    <div v-for="([varName, type]) in entry?.params" :key="varName">
      <FieldEditor :fieldName="varName" :target="particleSystem?.emitterShape" :onChange="onChangeKeyValue" :type="type" />
    </div>
  </div>
</template>
<script>
import FunI18n from "@/views/component/FunI18n.vue";
import {EmitterShapes} from "three.quarks";
import {mapMutations, mapState} from "vuex";
import {t} from "@/util/lang";
import FieldEditor from "@/views/ParticleSystem/Editors/FieldEditor.vue";

export default {

  components: {
    FunI18n,
    FieldEditor
  },
  props: {
    particleSystem: Object
  },
  data() {
    return {
      selectedType: this.$props.particleSystem?.emitterShape.type,
      options: Object.keys(EmitterShapes).map(shape => {return {value: shape, label: t(shape)}})

    }
  },
  watch: {
    particleSystem() {
      this.$data.selectedType = this.$props.particleSystem?.emitterShape.type
    }
  },
  computed: {
    entry() {
      return EmitterShapes[this.$props.particleSystem?.emitterShape.type]
    }
  },
  methods: {
    t,
    ...mapMutations('particleSystem', [
        'updateEmitterShape',
        'updateProperties'
    ]),
    onChangeShape(value) {
      const { particleSystem } = this.$props
      console.log(particleSystem,'????')
      if (particleSystem.emitterShape.type !== value) {
        const entry = EmitterShapes[value];
        if (entry) {
          particleSystem.emitterShape = new entry.constructor();
          this.updateEmitterShape(particleSystem);
          this.updateProperties();
        }
      }
    },

    onChangeKeyValue() {
      const { particleSystem } = this.$props

      this.updateEmitterShape(particleSystem);

    }
  }
}
</script>
<style lang="less" scoped>

</style>