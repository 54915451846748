<template>
  <span>{{ getZhFromText()}}</span>
</template>
<script>


import zh from "../../util/zh.json"

const textToZh =  zh
export default {
  props: {
    text: String
  },
  data() {
    return {

    }
  },
  methods: {
    getZhFromText() {
      const { text } = this.$props
      const result = textToZh[text]
      return result || text || '---'
    }
  }
}
</script>