import * as THREE from 'three';
class CubeData {
    constructor() {
        this.m_id = ""
        this.size = {}
        this.pos = {}
        this.rot = {}
        this.m_url = "";
        this.m_object = null;
    }
    createCube(object) {

        object.updateMatrixWorld(true, true);

        this.m_url = object.userData.m_url;
        this.m_id = object.name

        var pos = new THREE.Vector3();
        object.getWorldPosition (pos );
        this.pos.x = pos.x
        this.pos.y = pos.y
        this.pos.z = pos.z

        var rot = new THREE.Quaternion();
        object.getWorldQuaternion (rot)
        this.rot.x = rot.x
        this.rot.y = rot.y
        this.rot.z = rot.z
        this.rot.w = rot.w

        var scale = new THREE.Vector3();
        object.getWorldScale ( scale );
        this.size.x = scale.x
        this.size.y = scale.y
        this.size.z = scale.z
    }

    setData(cube)
    {
        this.m_id = cube.m_id;
        this.size =  cube.size;
        this.pos = cube.pos;
        this.rot =  cube.rot;
        this.m_url = cube.m_url;
    }

    createGltf(object)
    {
        this.m_object = object;
        this.m_object.position.set(this.pos.x, this.pos.y, this.pos.z);
        this.m_object.quaternion.set(this.rot.x, this.rot.y, this.rot.z, this.rot.w);
        this.m_object.scale.set(this.size.x, this.size.y, this.size.z);
        this.m_object.updateMatrixWorld(true, true);
        return this.m_object;
    }

    getAttributesCount(mesh)
    {
      var index = [];
      for ( const name in mesh.geometry.attributes ) {
        index = index + name;
      }
      return index;
    }
}

export { CubeData };
