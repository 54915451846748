import { MainObjects } from "../common/MainObjects";
import { MaterialObject } from "../object/MaterialObject";

class IResousePreview {
    static loadFile(files, ext, process, callback) {
        MainObjects.MainRender.m_previewResouse.load(files, ext, process, callback);
    }

    static loadGroup(object, callback){
        MainObjects.MainRender.m_previewResouse.loadGroup(object, callback);
    }

    static loadMaterial(json, callback, type = MaterialObject.MaterialType.e_STANDARD){   
        MainObjects.MainRender.m_previewResouse.loadMaterial(json, callback, type);
    }

    static close(){
        MainObjects.MainRender.m_previewResouse.close();
    }
}

export { IResousePreview };