<template>
    <div class="container">
        <div class="caption">颜色</div>
        <div class="color_box">
            <div class="color">
                <div class="colorA">
                    <div class="title">A</div>
                    <el-color-picker v-model="colorA" @change="ColorA()" @active-change="activeColorA" popper-class="color_poper" ></el-color-picker>
                </div>
                <div class="colorA">
                    <div class="title">B</div>
                    <el-color-picker v-model="colorB" @change="ColorB()" @active-change="activeColorB" popper-class="color_poper"></el-color-picker>
                </div>
            </div>
            <div class="easing">
                <div class="title">Easing</div>
                <el-select class="select" @change="ColorSelect()"   v-model="color" placeholder="请选择">
                    <el-option
                        v-for="item in colorList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </div>
        </div>
        <div class="caption">透明度</div>
        <div class="alpha_box">
            <div class="alpha">
                <div class="particles">
                    <div class="txt_box">
                        <input v-model="alphaA" placeholder="0" @change="changeNum($event)" @focus="Focus" @blur="Blur" />
                        <span class="txt">A</span>
                    </div>
                    <div class="txt_box">
                        <input v-model="alphaB" placeholder="0" @change="changeNum($event)" @focus="Focus" @blur="Blur" />
                        <span class="txt">B</span>
                    </div>
                </div>
            </div>
            <div class="easing">
                <div class="title">Easing</div>
                <el-select class="select" @change="alphaSelect()"   v-model="alpha" placeholder="请选择">
                    <el-option
                        v-for="item in alphaList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </div>
        </div>
        <div class="caption">缩放</div>
        <div class="alpha_box">
            <div class="alpha">
                <div class="particles">
                    <div class="txt_box">
                        <input v-model="alphaA" placeholder="0" @change="changeNum($event)" @focus="Focus" @blur="Blur" />
                        <span class="txt">A</span>
                    </div>
                    <div class="txt_box">
                        <input v-model="alphaB" placeholder="0" @change="changeNum($event)" @focus="Focus" @blur="Blur" />
                        <span class="txt">B</span>
                    </div>
                </div>
            </div>
            <div class="easing">
                <div class="title">Easing</div>
                <el-select class="select" @change="scaleSelect()"   v-model="scale" placeholder="请选择">
                    <el-option
                        v-for="item in scaleList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </div>
        </div>
        <div class="caption">Spring</div>
        <div class="alpha_box">
            <div class="loction1">
                <div class="x-axis axis">
                    <p>X</p>
                    <input v-model="positionX" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @change="changeNum($event)"  @focus="Focus" @blur="Blur" />
                </div>
                <div class="y-axis axis">
                    <p>Y</p>
                    <input v-model="positionY" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @change="changeNum($event)"  @focus="Focus" @blur="Blur"/>
                </div>
                <div class="z-axis axis">
                    <p>Z</p>
                    <input v-model="positionZ" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @change="changeNum($event)"  @focus="Focus" @blur="Blur"/>
                </div>
            </div>
            <div class="alpha">
                <div class="particles">
                    <div class="txt_box txt_box1">
                        <input v-model="alphaA" placeholder="0" @change="changeNum($event)" @focus="Focus" @blur="Blur" />
                        <span class="txt1">SPRING</span>
                    </div>
                    <div class="txt_box txt_box1">
                        <input v-model="alphaB" placeholder="0" @change="changeNum($event)" @focus="Focus" @blur="Blur" />
                        <span class="txt1">FRICTION</span>
                    </div>
                </div>
            </div>
            <div class="easing">
                <div class="title">Easing</div>
                <el-select class="select" @change="springSelect()"   v-model="spring" placeholder="请选择">
                    <el-option
                        v-for="item in springList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </div>
        </div>
        <div class="caption">Force</div>
        <div class="alpha_box">
            <div class="alpha">
                <div class="particles">
                    <div class="txt_box2">
                        <input v-model="alphaA" placeholder="0" @change="changeNum($event)" @focus="Focus" @blur="Blur" />
                        <span class="txt">FX</span>
                    </div>
                    <div class="txt_box2">
                        <input v-model="alphaB" placeholder="0" @change="changeNum($event)" @focus="Focus" @blur="Blur" />
                        <span class="txt">FY</span>
                    </div>
                    <div class="txt_box2">
                        <input v-model="alphaB" placeholder="0" @change="changeNum($event)" @focus="Focus" @blur="Blur" />
                        <span class="txt">FZ</span>
                    </div>
                </div>
            </div>
            <div class="easing">
                <div class="title">Easing</div>
                <el-select class="select" @change="forceSelect()"   v-model="force" placeholder="请选择">
                    <el-option
                        v-for="item in forceList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </div>
        </div>
        <div class="caption">随机</div>
        <div class="alpha_box">
            <div class="alpha">
                <div class="particles">
                    <div class="txt_box2">
                        <input v-model="alphaA" placeholder="0" @change="changeNum($event)" @focus="Focus" @blur="Blur" />
                        <span class="txt">DX</span>
                    </div>
                    <div class="txt_box2">
                        <input v-model="alphaB" placeholder="0" @change="changeNum($event)" @focus="Focus" @blur="Blur" />
                        <span class="txt">DY</span>
                    </div>
                    <div class="txt_box2">
                        <input v-model="alphaB" placeholder="0" @change="changeNum($event)" @focus="Focus" @blur="Blur" />
                        <span class="txt">DZ</span>
                    </div>
                </div>
            </div>
            <div class="ipt_box">
                <input v-model="delay" @change="changeNum($event)" @focus="Focus" @blur="Blur" placeholder="" />
                <span class="txt">DELAY</span>
            </div>
            <div class="easing">
                <div class="title">Easing</div>
                <el-select class="select" @change="randomSelect()"   v-model="random" placeholder="请选择">
                    <el-option
                        v-for="item in randomList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </div>
        </div>
        <div class="caption">旋转</div>
        <div class="alpha_box">
            <div class="loction1">
                <div class="x-axis axis">
                    <p>X</p>
                    <input v-model="positionX" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @change="changeNum($event)"  @focus="Focus" @blur="Blur" />
                </div>
                <div class="y-axis axis">
                    <p>Y</p>
                    <input v-model="positionY" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @change="changeNum($event)"  @focus="Focus" @blur="Blur"/>
                </div>
                <div class="z-axis axis">
                    <p>Z</p>
                    <input v-model="positionZ" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @change="changeNum($event)"  @focus="Focus" @blur="Blur"/>
                </div>
            </div>
            <div class="easing">
                <div class="title">Easing</div>
                <el-select class="select" @change="rotateSelect()"   v-model="rotate" placeholder="请选择">
                    <el-option
                        v-for="item in rotateList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </div>
        </div>
    </div>
</template>
<script>
import { ISetting } from "../../threejs/interface/ISetting";
export default {
    data(){
        return{
            colorA:"#409EFF",
            colorB:"#409EFF",
            color:'',
            alpha:'',
            scale:'',
            spring:'',
            force:'',
            random:'',
            rotate:'',
            colorList:[
                {
                    value:1,
                    label:"动态纹理"
                },
            ],
            alphaList:[
                {
                    value:1,
                    label:"动态纹理"
                },
            ],
            scaleList:[
                {
                    value:1,
                    label:"动态纹理"
                },
            ],
            springList:[
                {
                    value:1,
                    label:"动态纹理"
                },
            ],
            forceList:[
                {
                    value:1,
                    label:"动态纹理"
                },
            ],
            randomList:[
                {
                    value:1,
                    label:"动态纹理"
                },
            ],
            rotateList:[
                {
                    value:1,
                    label:"动态纹理"
                },
            ],
            alphaA:0,
            alphaB:0,
            positionX:0,
            positionY:0,
            positionZ:0,
            delay:0,
        }
    },
    mounted(){

    },
    methods:{
        //颜色
        ColorSelect(){

        },
        //透明度
        alphaSelect(){

        },
        scaleSelect(){

        },
        springSelect(){

        }, 
        forceSelect(){

        },
        randomSelect(){

        },
        rotateSelect(){

        },
        ColorA(){

        },
        activeColorA(value){

        },
        ColorB(){

        },
        activeColorB(value){

        },
        textureSelect(){

        },
        changeNum(){

        },
        Focus(){
            ISetting.isInput(true);
        },
        Blur(){
            ISetting.isInput(false);
        },
        transitionColror(color){
            var values = color
            .replace(/rgba?\(/, '')
            .replace(/\)/, '')
            .replace(/[\s+]/g, '')
            .split(',');
            var a = parseFloat(values[3] || 1),
                r = Math.round(a * parseInt(values[0]) + (1 - a) * 255),
                g = Math.round(a * parseInt(values[1]) + (1 - a) * 255),
                b = Math.round(a * parseInt(values[2]) + (1 - a) * 255);
            return "#" +
                ("0" + r.toString(16)).slice(-2) +
                ("0" + g.toString(16)).slice(-2) +
                ("0" + b.toString(16)).slice(-2);
        },
    },
}
</script>
<style scoped>
.container{
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding-bottom: 30px;
}
.container::-webkit-scrollbar{
  display: none;
}
.caption{
    width: 100%;
    height: 30px;
    line-height: 30px;
    background: #F7F7F7;
    font-weight: bolder;
    font-size: 12px;
    text-align: center;
    margin-top: 10px;
}
.color{
    width: 100%;
    display: flex;
}
.colorA{
    display: flex;
    width: 50%;
    height: 20px;
    align-items: center;
    margin-top: 25px;
    position: relative;
}
.colorA .title{
    width: 50px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-weight: bold;
    overflow: hidden;
    font-size: 14px;
}
.colorA >>>.el-color-picker__trigger{
    height: 30px;
    width: 120px;
    margin-top: 5px;
}
.easing{
    width: 100%;
    display: flex;
    margin-top: 20px;
    justify-content: space-around;
}
.easing .title{
    width: 60px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    font-weight: bold;
    overflow: hidden;
    font-size: 14px;
}
.select{
    width: 240px;
    height: 28px;
    /* position: relative; */
}
.select /deep/ .el-input{
   width: 100% !important;
   height: 28px !important;
   outline: none !important;
}
.select /deep/ .el-input__inner{
   width: 100% !important;
   height: 28px !important;
   outline: none !important;
}
.select /deep/ .el-select__caret{
  height: 100% !important;
  line-height: 28px !important;
}
/* .el-select /deep/ .select_popper{
    z-index: 99999999 !important;
    top: auto !important;
    left: auto !important
} */
.alpha {
    width: 100%;
}
.alpha .particles {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 15px;
}
.particles .title{
    width: 50px;
    height: 30px;
    font-size: 12px;
    line-height: 30px;
    text-align: center;
    font-weight: bold;
    overflow: hidden;
    font-size: 14px;
}
.particles .txt_box{
    width: 150px;
    height: 28px;
    background: #FFFFFF;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left:10px ;
}
.particles .txt_box .txt{
    display: inline-block;
    width: 40px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    font-size: 12px;
    color: #808080;
}
.particles .txt_box input{
    width: 110px;
    height: 25px;
    border: none;
    border-radius: 5px;
    outline: none;
    font-size: 10px;
    background-color: rgba(0, 0, 0, 0);
    color: #808080;
    text-indent: 5px;
}
.particles .txt_box1 .txt1{
    display: inline-block;
    width: 80px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    font-size: 10px;
    color: #808080;
}
.particles .txt_box1 input{
    width: 70px;
    height: 25px;
    border: none;
    border-radius: 5px;
    outline: none;
    font-size: 10px;
    background-color: rgba(0, 0, 0, 0);
    color: #808080;
    text-indent: 5px;
}
.particles .txt_box2{
    width: 95px;
    height: 28px;
    background: #FFFFFF;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-left:10px ; */
}
.particles .txt_box2 .txt{
    display: inline-block;
    width: 40px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    font-size: 10px;
    color: #808080;
}
.particles .txt_box2 input{
    width: 55px;
    height: 25px;
    border: none;
    border-radius: 5px;
    outline: none;
    font-size: 10px;
    background-color: rgba(0, 0, 0, 0);
    color: #808080;
    text-indent: 5px;
}
.loction1{
    width: 100%;
    display: flex;
    height: 25px;
    align-items: center;
    justify-content: space-around;
    overflow: hidden;
    margin:10px auto;
}
.axis{
    width: 25%;
    height: 100%;
    display: flex;
    align-items: center;
    background-color:#F7F7F7;
    border-radius: 5px;
}
.axis p{
    width: 25px;
    height: 22px;
    line-height: 22px;
    text-align: center;
    color: #929292;
    background-color: #fff;
    margin-left: 2px;
    border-radius: 4px;
    float: left;
    color: #000000;
    font-size: 12px;
}
.axis input{
    width: 90%;
    height: 22px;
    text-align: center;
    outline: none;
    border: none;
    background-color: #F7F7F7;
    border-radius: 4px;
    font-weight: bold;
    font-size: 8px;
}
.x-axis p{
    background-color:#F20004;
}
.y-axis p{
    background-color: #43BD00;
}
.z-axis p {
    background-color: #5180E9;
}
.ipt_box{
    width: 240px;
    height: 28px;
    background: #FFFFFF;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    margin-left: 15px;
}
.ipt_box .txt{
    display: inline-block;
    width: 50px;
    height: 28px;
    text-align: center;
    line-height: 28px;
    font-size: 12px;
    font-size: 12px;
    color: #808080;
}
.ipt_box input{
    width: 190px;
    height: 25px;
    border: none;
    border-radius: 5px;
    outline: none;
    font-size: 10px;
    background-color: rgba(0, 0, 0, 0);
    color: #808080;
    text-indent: 5px;
}
</style>