import * as THREE from 'three';
import { Box3 } from 'three';
import { LineSegments } from 'three';
import { Vector3 } from 'three';
import { BufferAttribute } from 'three';
import { BufferGeometry } from 'three';
import { MainObjects } from '../common/MainObjects';
import { GameObject } from '../object/GameObject';
import { GameScene } from '../object/GameScene';

class BoxHelper extends LineSegments {

	constructor( object, color = 0xffff00 ) {

		const indices = new Uint16Array( [ 0, 1, 1, 2, 2, 3, 3, 0, 4, 5, 5, 6, 6, 7, 7, 4, 0, 4, 1, 5, 2, 6, 3, 7 ] );
		const positions = new Float32Array( 8 * 3 );

		const geometry = new BufferGeometry();
		geometry.setIndex( new BufferAttribute( indices, 1 ) );
		geometry.setAttribute( 'position', new BufferAttribute( positions, 3 ) );

		var gameScene = MainObjects.Blueprint.m_sceneManagerBp.m_gameScene;
		var line_material = gameScene.m_matRepos.get(GameScene.MatKey.e_LINE);

		super( geometry, line_material );

		this.object = object;
		this.object.updateMatrixWorld(true);
		this.type = 'BoundingBoxHelper';
		this.layers.set(10);
		this.userData = new GameObject();
		this.userData.m_gameObjectType = GameObject.GameObjectType.e_SELECT_HELPER_BOX;
		this.matrixAutoUpdate = false;
		this.object_list = new Array();
		for(var i = 0; i < 26; i++)
		{
			const obj = new THREE.Object3D();
			this.add(obj);
			this.object_list.push(obj);
		}
		this.visible = false;
		this.m_xLen = 1;
        this.m_yLen = 1;
        this.m_zLen = 1;

        // const c_geometry = new THREE.BoxGeometry( 1, 1, 1 );
		// var c_material = gameScene.m_matRepos.get(GameObject.GameObjectType.e_MESH);

        // this.m_cube = new THREE.Mesh( c_geometry, c_material );
		// this.m_cube.visible = false;
		// this.m_cube.layers.set(10);
		// this.m_cube.type = 'BoundingBoxHelper';
		// this.m_cube.userData = new GameObject();
		// this.m_cube.userData.m_gameObjectType = GameObject.GameObjectType.e_SELECT_HELPER_BOX;
		this.m_cube = new THREE.Object3D();
        this.add( this.m_cube );
		this.renderOrder = 100;
		this.update();
	}

	update() {

		this.removeFromParent();
		var pos = new Vector3();
		var scale = new THREE.Vector3();
		var rot = new THREE.Euler();

		var parent = this.object.parent;
		if(parent != null && parent.id != MainObjects.Scene.m_userGroup.id)
		{
			MainObjects.Scene.m_userGroup.attach(this.object);
		}
		else
		{
			parent = null;
		}
		const _box = new Box3();
		if ( this.object !== undefined ) {

			pos.copy(this.object.position);
			scale.copy(this.object.scale);
			rot.copy(this.object.rotation);
			this.object.position.set(0, 0, 0);
			this.object.scale.set(1, 1 , 1);
			this.object.rotation.set(0, 0, 0);
			this.object.updateMatrixWorld(true);
			_box.setFromObject( this.object );
		}

		var min = new Vector3();
		var max = new Vector3();
		if ( _box.isEmpty() ) 
		{

		}
		else
		{
			var min = _box.min;
			var max = _box.max;
		}
		/*
			5____4
		1/___0/|
		| 6__|_7
		2/___3/

		0: max.x, max.y, max.z
		1: min.x, max.y, max.z
		2: min.x, min.y, max.z
		3: max.x, min.y, max.z
		4: max.x, max.y, min.z
		5: min.x, max.y, min.z
		6: min.x, min.y, min.z
		7: max.x, min.y, min.z
		8:Y:0154：(0 + 5) * 0.5
		9:-Y:3267：(3 + 6) * 0.5
		10:X:0473：(0 + 7) * 0.5
		11:-X:1562：(1 + 6) * 0.5
		12:Z:0123：(0 + 2) * 0.5
		13:-Z:4567：(4 + 6) * 0.5
		14:01:(0 + 1) * 0.5
		15:04:(0 + 4) * 0.5
		16:51:(5 + 1) * 0.5
		17:54:(5 + 4) * 0.5
		18:32:(3 + 2) * 0.5
		19:37:(3 + 7) * 0.5
		20:62:(6 + 2) * 0.5
		21:67:(6 + 7) * 0.5
		22:03:(0 + 3) * 0.5
		23:12:(1 + 2) * 0.5
		24:56:(5 + 6) * 0.5
		25:47:(4 + 7) * 0.5
		*/

		const position = this.geometry.attributes.position;
		const array = position.array;

		array[ 0 ] = max.x; array[ 1 ] = max.y; array[ 2 ] = max.z;
		array[ 3 ] = min.x; array[ 4 ] = max.y; array[ 5 ] = max.z;
		array[ 6 ] = min.x; array[ 7 ] = min.y; array[ 8 ] = max.z;
		array[ 9 ] = max.x; array[ 10 ] = min.y; array[ 11 ] = max.z;
		array[ 12 ] = max.x; array[ 13 ] = max.y; array[ 14 ] = min.z;
		array[ 15 ] = min.x; array[ 16 ] = max.y; array[ 17 ] = min.z;
		array[ 18 ] = min.x; array[ 19 ] = min.y; array[ 20 ] = min.z;
		array[ 21 ] = max.x; array[ 22 ] = min.y; array[ 23 ] = min.z;


        this.object_list[0].position.set(max.x, max.y, max.z)
		this.object_list[1].position.set(min.x, max.y, max.z)
        this.object_list[2].position.set(min.x, min.y, max.z)
        this.object_list[3].position.set(max.x, min.y, max.z)
        this.object_list[4].position.set(max.x, max.y, min.z)
		this.object_list[5].position.set(min.x, max.y, min.z)
		this.object_list[6].position.set(min.x, min.y, min.z)
		this.object_list[7].position.set(max.x, min.y, min.z)

		this.object_list[8].position.set(0.5 * (max.x + min.x), max.y, 0.5 * (max.z + min.z));
		this.object_list[9].position.set(0.5 * (max.x + min.x), min.y, 0.5 * (max.z + min.z));
		this.object_list[10].position.set(max.x, 0.5 * (max.y + min.y), 0.5 * (max.z + min.z));
		this.object_list[11].position.set(min.x, 0.5 * (max.y + min.y), 0.5 * (max.z + min.z));
		this.object_list[12].position.set(0.5 * (max.x + min.x), 0.5 * (max.y + min.y), max.z);
		this.object_list[13].position.set(0.5 * (max.x + min.x), 0.5 * (max.y + min.y), min.z);

		this.object_list[14].position.set(0.5 * (max.x + min.x), max.y, max.z);
		this.object_list[15].position.set(max.x, max.y, 0.5 * (max.z + min.z));
		this.object_list[16].position.set(min.x, max.y,  0.5 * (max.z + min.z));
		this.object_list[17].position.set(0.5 * (min.x + max.x), max.y, min.z);
		this.object_list[18].position.set(0.5 * (min.x + max.x), min.y, max.z);
		this.object_list[19].position.set(max.x, min.y, 0.5 * (max.z + min.z));
		this.object_list[20].position.set(min.x, min.y, 0.5 * (max.z + min.z));
		this.object_list[21].position.set(0.5 * (min.x + max.x), min.y, min.z);
		this.object_list[22].position.set(max.x, 0.5 * (min.y + max.y), max.z);
		this.object_list[23].position.set(min.x, 0.5 * (min.y + max.y), max.z);
		this.object_list[24].position.set(min.x, 0.5 * (min.y + max.y), min.z);
		this.object_list[25].position.set(max.x, 0.5 * (min.y + max.y), min.z);

		this.m_xLen = this.object_list[10].position.distanceTo (this.object_list[11].position);
        this.m_yLen = this.object_list[8].position.distanceTo (this.object_list[9].position);
        this.m_zLen = this.object_list[12].position.distanceTo (this.object_list[13].position);

		this.m_cube.position.set(0.5 * (min.x + max.x), 0.5 * (min.y + max.y), 0.5 * (max.z + min.z));
		this.m_cube.scale.set(this.m_xLen, this.m_yLen, this.m_zLen);

		this.m_max = Math.max(this.m_xLen, this.m_yLen, this.m_zLen);
		this.m_min = Math.min(this.m_xLen, this.m_yLen, this.m_zLen);

		if(this.parent == null)
		{
			this.object.attach(this);
		}
		else
		{
			if(this.parent.id != this.object.id)
			{
				this.object.attach(this);
			}
		}

		this.object.position.copy(pos);
		this.object.scale.copy(scale);
		this.object.rotation.copy(rot);
		this.object.updateMatrixWorld(true);
		if(parent != null && parent.id != this.object.parent.id)
		{
			parent.attach(this.object);
		}
		this.updateMatrixWorld(true);
		position.needsUpdate = true;
		this.geometry.computeBoundingSphere();
	}

	setFromObject( object ) {

		this.object = object;
		this.update();

		return this;

	}

	copy( source ) {

		LineSegments.prototype.copy.call( this, source );

		this.object = source.object;

		return this;

	}

	dispose()
	{
		this.geometry.dispose();
	}
}


export { BoxHelper };