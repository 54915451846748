<template>
  <VueGpickr class="vueGpicker" v-model="gradient" @change="handlePickerChange" />

</template>
<script>
import { VueGpickr, LinearGradient } from 'vue-gpickr';
import {ColorRange, Gradient} from "three.quarks";
import {Vector4} from "three";

const gradient = new LinearGradient({
  angle: 0,
  stops: [
    ['#0359b5', 0],
    ['#f6ce01', 1]
  ]
});
function hexToVector4(hex, alpha) {
  // 移除可能存在的 '#' 字符
  hex = hex.replace("#", "");
  let r, g, b;

  // 根据颜色值的长度进行不同的处理
  if (hex.length === 3) {
    // 处理 3 位颜色值（例如 #abc）
    r = parseInt(hex[0] + hex[0], 16) / 255;
    g = parseInt(hex[1] + hex[1], 16) / 255;
    b = parseInt(hex[2] + hex[2], 16) / 255;
  } else if (hex.length === 6) {
    // 处理 6 位颜色值（例如 #aabbcc）
    r = parseInt(hex.substring(0, 2), 16) / 255;
    g = parseInt(hex.substring(2, 4), 16) / 255;
    b = parseInt(hex.substring(4, 6), 16) / 255;
  } else if (hex.length === 8) {
    // 处理 8 位颜色值（例如 #aabbccdd）
    r = parseInt(hex.substring(0, 2), 16) / 255;
    g = parseInt(hex.substring(2, 4), 16) / 255;
    b = parseInt(hex.substring(4, 6), 16) / 255;
    // 提取 alpha 值（范围为 0 到 1）
    alpha = parseInt(hex.substring(6, 8), 16) / 255;
  } else {
    // 不支持的颜色值格式
    throw new Error("Invalid hex color value");
  }

  // 根据给定的透明度计算 RGBA 值
  // const rgba = `rgba(${r}, ${g}, ${b}, ${alpha || 1})`;

  return new Vector4(r, g, b, alpha || 1);
}

function vector4ToHex(vector4) {
  const r = Math.round(vector4.x * 255);
  const g = Math.round(vector4.y * 255);
  const b = Math.round(vector4.z * 255);
  const a = Math.round(vector4.w * 255);

  const hexR = r.toString(16).padStart(2, '0');
  const hexG = g.toString(16).padStart(2, '0');
  const hexB = b.toString(16).padStart(2, '0');
  const hexA = a.toString(16).padStart(2, '0');

  return `#${hexR}${hexG}${hexB}${hexA}`;
}

function generateGradientColor(originArr) {
  const sortStops = originArr.stops.sort((a, b) => a[1] - b[1])
  const rangeArray = []
  sortStops.forEach((item, index) => {
    const colorRange = new ColorRange(hexToVector4(item[0]), hexToVector4(sortStops[index === sortStops.length - 1 ? index : index + 1][0]))
    const rangeItem = [colorRange, item[1]]
    rangeArray.push(rangeItem)
  })
  return new Gradient(rangeArray)
}
function parseGradientColor(target) {
  const resultStops = []
  target.functions.forEach((item, index) => {
    const stops0 = vector4ToHex(item[0].a)
    const stops1 = item[1]
    resultStops.push([stops0, stops1])
  })
  return resultStops
}
export default {
  components: {VueGpickr},
  props: {
    value: Object,
    onChange: Function,
  },
  data() {
    return {
      gradient: gradient

    }
  },
  watch: {
    value: {
      handler(newValue, oldValue) {
        this.$data.gradient.stops = parseGradientColor(newValue)
      },
      immediate: true
    },
    gradient(newVal) {
      this.$props.onChange(generateGradientColor(newVal))
    }
  },
  methods: {
    handlePickerChange(value) {
      console.log(value,'value')
    }
  }
}

</script>
<style lang="less">
.vueGpicker {
  box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1) !important;

}
</style>