import { POST, GET, GET2, POST2, POST3 } from './service'
import { host1, host2 } from './host'


export let server = {

    //获取oss上传查看token
    async getOSSToken(paramObj) {
        return await POST(host1, 'file/oss/getOSSToken', paramObj)
    },
    //获取验证码
    async getPhoneVerificationCode(paramObj) {
        return await POST(host1, 'metaverse/getPhoneVerificationCode', paramObj)
    },
    //登录接口
    async phoneLogin(paramObj) {

        return await POST(host1, 'metaverse/phoneLogin', paramObj)
    },
    //权限接口
    async getOperationMessageById(paramObj) {
        return await GET(host1, 'operation/operationManager/getOperationMessageById', paramObj)

    },
    // 登出接口
    async logout(paramObj) {
        return await POST(host1, 'metaverse/logout', paramObj)

    },
    // 文件夹列表展示
    async getFolderList(paramObj) {
        return await POST(host1, 'folder/folderList', paramObj)
    },

    // 文件假删除
    async delFile(paramObj) {
        return await POST(host1, 'folder/delFolder', paramObj)

    },

    // 新建文件夹
    async addFolder(paramObj) {
        return await POST(host1, 'folder/addFolder', paramObj)

    },
    // 文件夹改名字
    async updateFileName(paramObj) {
        return await POST(host1, 'folder/updateFolderName', paramObj)

    },
    // 根据文件夹id获取目录
    async getSubCatalog(paramObj) {
        return await POST(host1, 'folder/getSubCatalog', paramObj)

    },
    // 拖动文件
    async moveFolderFile(paramObj) {
        return await POST(host1, 'folder/moveFolderFile', paramObj)

    },
    // 回收文件列表
    async delFolderList(paramObj) {
        return await POST(host1, 'folder/delFolderList', paramObj)
    },
    // 添加项目
    async addProject(paramObj) {
        return await POST(host1, 'project/addProject', paramObj)
    },
    // 删除项目
    async delProject(paramObj) {
        return await POST(host1, 'project/delProject', paramObj)
    },



    // 项目列表展示
    async projectList(paramObj) {
        return await POST(host1, 'project/projectList', paramObj)
    },
    // 项目重命名接口
    async updateProjectName(paramObj) {
        return await POST(host1, 'project/updateProjectName', paramObj)
    },

    // 文件上传
    async addFile(paramObj) {
        return await POST(host1, 'file/addFile', paramObj)
    },
    //恢复
    async recoverFolder(paramObj) {
        return await POST(host1, 'folder/recoverFolder', paramObj)
    },
    //查询
    async findByName(paramObj) {
        return await POST(host1, 'file/findByName', paramObj)

    },
    //返回存储大小
    async useMemory(paramObj) {
        return await POST(host1, 'userGroup/useMemory', paramObj)
    },
    //切换锁定状态
    async projectLocal(paramObj) {
        return await POST(host1, 'folder/Local', paramObj)
    },

    //更新文件
    async updateFile(paramObj) {
        return await POST(host1, 'file/updateFile', paramObj)
    },
    //项目上锁解锁
    // async projectLocal(paramObj) {
    //     return await POST(host1, 'project/projectLocal', paramObj)
    // },
    //获取锁状态
    async getLocalType(paramObj) {
        return await POST(host1, 'project/getLocalType', paramObj)
    },

    //场景上锁
    async sceneLocal(paramObj) {
        return await POST(host1, 'file/sceneLocal', paramObj)
    },

    //场景锁状态
    async sceneStatus(paramObj) {
        return await POST(host1, 'file/sceneStatus', paramObj)
    },


    //文件夹搜索
    async findFolderByName(paramObj) {
        return await POST(host1, 'folder/findFolderByName', paramObj)
    },

    //添加项目打开状态
    async openProject(paramObj) {
        return await POST(host1, 'project/openProject', paramObj)
    },
    //用户个人资料
    async userInformation1(paramObj) {
        return await POST(host1, 'user/userInformation', paramObj)
    },
    //更改用户手机号
    async updateUserPhone(paramObj) {
        return await POST(host1, 'user/updateUserPhone', paramObj)
    },
    //验证码判断
    async codeEstimate(paramObj) {
        return await POST(host1, "user/codeEstimate", paramObj);
    },
    //更改用户昵称
    async updateUserName(paramObj) {
        return await POST(host1, 'user/updateUserName', paramObj)
    },
    //用户头像更改
    async updateUserImage(paramObj) {
        return await POST(host1, 'user/updateUserImage', paramObj)
    },


    //根据id定位文件位置
    async getFolderByFileId(paramObj) {
        return await POST(host1, 'file/getFolderByFileId', paramObj)
    },

    //新建团队
    async createUserGroup(paramObj) {
        return await POST(host1, 'userGroup/createUserGroup', paramObj)
    },
    //用户添加团队列表
    async userGroupList(paramObj) {
        return await POST(host1, 'userGroup/userGroupList', paramObj)
    },
    //添加成员
    async addUser(paramObj) {
        return await POST(host1, 'userGroup/addUser', paramObj)
    },

    //重命名
    async updateGroupName(paramObj) {
        return await POST(host1, 'userGroup/updateGroupName', paramObj)
    },

    //团队用户列表
    async groupUserList(paramObj) {
        return await POST(host1, 'userGroup/groupUserList', paramObj)
    },

    //移交团队
    async transferGroup(paramObj) {
        return await POST(host1, 'userGroup/transferGroup', paramObj)
    },

    //退出团队
    async exitGroup(paramObj) {
        return await POST(host1, 'userGroup/exitGroup', paramObj)
    },
    //删除团队
    async deleteGroup(paramObj) {
        return await POST(host1, 'userGroup/deleteGroup', paramObj)
    },
    //用户信息日志回显
    async userInformation(paramObj) {
        return await POST(host1, 'userGroup/userInformation', paramObj)
    },
    //更改用户权限
    async updateUserLevel(paramObj) {
        return await POST(host1, 'userGroup/updateUserLevel', paramObj)
    },
    //权限列表
    async levelList(paramObj) {
        return await POST(host1, 'userGroup/levelList', paramObj)
    },
    //删除部门内用户
    async deleteUser(paramObj) {
        return await POST(host1, 'userGroup/deleteUser', paramObj)
    },
    //获取管理员列表
    async adminList(paramObj) {
        return await POST(host1, 'userGroup/adminList', paramObj)
    },

    //获取消息列表
    async logList(paramObj) {
        return await POST(host1, 'userGroup/log/logList', paramObj)
    },

    //单条查看
    async clickLog(paramObj) {
        return await POST(host1, 'userGroup/log/clickLog', paramObj)
    },

    //全部已读
    async logAllLook(paramObj) {
        return await POST(host1, 'userGroup/log/logAllLook', paramObj)
    },
    //是否存在未读
    async isNotLook(paramObj) {
        return await POST(host1, 'userGroup/log/isNotLook', paramObj)
    },

    //官网banner
    async bannerDate(paramObj) {
        return await POST(host1, 'website/bannerDate', paramObj)
    },
    //点击banner
    async clickBanner(paramObj) {
        return await POST(host1, 'website/clickBanner', paramObj)
    },
    //官网视频数据
    async videoDate(paramObj) {
        return await POST(host1, 'website/videoDate', paramObj)
    },
    //视频点击
    async videoClick(paramObj) {
        return await POST(host1, 'website/videoClick', paramObj)
    },
    //主题列表
    async subjectList(paramObj) {
        return await POST(host1, 'website/subjectList', paramObj)
    },
    //主题列表信息
    async subjectInformation(paramObj) {
        return await POST(host1, 'website/subjectInformation', paramObj)
    },
    // 获取 UserSig（腾讯im需要用到）    
    async getUserSig(paramObj) {
        return await POST(host1, 'userGroup/getUserSig', paramObj)
    },
    // 文件/文件夹交换位置
    async transpositionFolderFile(paramObj) {
        return await POST(host1, 'folder/transpositionFolderFile', paramObj)
    },

    //移动文件夹位置
    async moveFolderLocation(paramObj) {
        return await POST(host1, 'folder/moveFolderLocation', paramObj)
    },

    //获取权限树
    async projectAuthority(paramObj) {
        return await POST(host1, 'userGroup/projectAuthority', paramObj)
    },
    //获取用户在项目中的权限
    async getUserProjectAuthority(paramObj) {
        return await POST(host1, 'userGroup/getUserProjectAuthority', paramObj)
    },
    //添加权限
    async addUserProjectAuthority(paramObj) {
        return await POST2(host1, 'userGroup/addUserProjectAuthority', paramObj)
    },

    //
    async fileFolderLocalState(paramObj) {
        return await POST(host1, 'file/fileFolderLocalState', paramObj)
    },


    //添加权限
    async getFolderLocal(paramObj) {
        return await POST(host1, 'folder/getFolderLocal', paramObj)
    },

    //共享库列表
    async sharedLibraryList(paramObj) {
        return await POST(host1, 'project/sharedLibrary/sharedLibraryList', paramObj)
    },

    //创建共享库
    async addSharedLibrary(paramObj) {
        return await POST(host1, 'project/sharedLibrary/addSharedLibrary', paramObj)
    },

    //删除共享库
    async deleteSharedLibrary(paramObj) {
        return await POST(host1, 'project/sharedLibrary/deleteSharedLibrary', paramObj)
    },
    //重命名库
    async updateSharedLibraryName(paramObj) {
        return await POST(host1, 'project/sharedLibrary/updateSharedLibraryName', paramObj)
    },
    //上传文件夹文件
    async folderFileUpload(paramObj) {
        return await POST2(host1, 'folder/folderFileUpload', paramObj)
    },

    //保存分享共享库
    async saveShareLink(paramObj) {
        return await POST(host1, 'project/sharedLibrary/saveShareLink', paramObj)
    },
    //共享库链接校验
    async judgeShareLink(paramObj) {
        return await POST(host1, 'project/sharedLibrary/judgeShareLink', paramObj)
    },
    //共享库链接校验
    async shareSharedLibrary(paramObj) {
        return await POST2(host1, 'project/sharedLibrary/shareSharedLibrary', paramObj)
    },

    //文件夹同步到本地库
    async copyFolder(paramObj) {
        return await POST2(host1, 'folder/copyFolder', paramObj)
    },

    //获取需要同步的文件列表
    async allFile(paramObj) {
        return await POST(host1, 'file/allFile', paramObj)
    },

    //修改文件oss
    async updateFileOSSUrl(paramObj) {
        return await POST2(host1, 'file/updateFileOSSUrl', paramObj)
    },

    //重名的文件列表
    async copyReplace(paramObj) {
        return await POST2(host1, 'folder/copyReplace', paramObj)
    },

    //文件夹文件替换
    async folderFileReplace(paramObj) {
        return await POST2(host1, 'folder/folderFileReplace', paramObj)
    },
    //校验链接
    async openLink(paramObj) {
        return await POST(host1, 'project/sharedLibrary/openLink', paramObj)
    },
    //删除文件关系
    async deleteUpload(paramObj) {
        return await POST(host1, 'folder/deleteUpload', paramObj)
    },

    //清空回收站
    async emptyRecycleBin(paramObj) {
        return await POST(host1, 'folder/emptyRecycleBin', paramObj)
    },
    //登录账号被删除是否恢复
    async whetherRecover(paramObj) {
        return await POST(host1, 'metaverse/whetherRecover', paramObj)
    },


    //云店 -- 新建文件夹
    async addCloudStoreFolder(paramObj) {
        return await POST(host1, 'user/cloudStore/addCloudStoreFolder', paramObj)
    },

    //云店 -- 云店列表
    async cloudStoreList(paramObj) {
        return await POST(host1, 'user/cloudStore/cloudStoreList', paramObj)
    },

    //云店 -- 删除文件
    async deleteFolderFile(paramObj) {
        return await POST2(host1, 'user/cloudStore/deleteFolderFile', paramObj)
    },
    //云店 -- 重命名
    async updateName(paramObj) {
        return await POST(host1, 'user/cloudStore/updateName', paramObj)
    },
    //云店 -- 回收站删除
    async deleteDelFolderFile(paramObj) {
        return await POST(host1, 'user/cloudStore/deleteDelFolderFile', paramObj)
    },
    //云店 -- 文件文件夹搜索
    async searchFolderFile(paramObj) {
        return await POST(host1, 'user/cloudStore/searchFolderFile', paramObj)
    },
    //云店 -- 回收站恢复
    async recoverFolderFile(paramObj) {
        return await POST2(host1, 'user/cloudStore/recoverFolderFile', paramObj)
    },
    //云店 -- 清空回收站
    async cleanRecycleBin(paramObj) {
        return await POST(host1, 'user/cloudStore/cleanRecycleBin', paramObj)
    },
    //云店 -- 复制黏贴树
    async moveFolderTree(paramObj) {
        return await POST(host1, 'user/cloudStore/moveFolderTree', paramObj)
    },
    //云店 -- 移动
    async moveStoreFolderFile(paramObj) {
        return await POST2(host1, 'user/cloudStore/moveStoreFolderFile', paramObj)
    },
    //云店 -- 复制
    async copyCloudFolderFile(paramObj) {
        return await POST(host1, 'user/cloudStore/copyCloudFolderFile', paramObj)
    },

    // 云店复制改url
    async updateStoreUrl(paramObj) {
        return await POST2(host1, 'user/cloudStore/updateStoreUrl', paramObj)
    },
    // 添加修改云店
    async addCloudStore(paramObj) {
        return await POST2(host1, 'user/cloudStore/addCloudStore', paramObj)
    },
    // 覆盖云店
    async cloudStoreReplace(paramObj) {
        return await POST2(host1, 'user/cloudStore/cloudStoreReplace', paramObj)
    },







    //  获取场景列表
    async getAllScene(paramObj) {
        return await POST(host1, 'file/getAllScene', paramObj)
    },
    //  编辑回显
    async getCloudStore(paramObj) {
        return await POST(host1, 'user/cloudStore/getCloudStore', paramObj)
    },

    //  分享
    async cloudStoreShare(paramObj) {
        return await POST(host1, 'user/cloudStore/cloudStoreShare', paramObj)
    },

    // //  云店消息
    async cloudStoreLogList(paramObj) {
        return await POST(host1, 'user/cloudStore/logList', paramObj)
    },
    // //  云店消息 单条读
    async cloudStoreReadLog(paramObj) {
        return await POST(host1, 'user/cloudStore/readLog', paramObj)
    },
    // //  云店消息 全读
    async cloudStoreAllRead(paramObj) {
        return await POST(host1, 'user/cloudStore/allRead', paramObj)
    },
    // //  云店消息 是否未读
    async haveNotLook(paramObj) {
        return await POST(host1, 'user/cloudStore/haveNotLook', paramObj)
    },
    // //  店铺类型列表
    async storePropertyList(paramObj) {
        return await POST(host1, 'user/cloudStore/storePropertyList', paramObj)
    },
    // //  店铺类型列表
    async storeEnvironmentList(paramObj) {
        return await POST(host1, '/user/cloudStore/storeEnvironmentList', paramObj)
    },

    // //  店铺外部环境
    async storeEnvironmentList(paramObj) {
        return await POST(host1, 'user/cloudStore/storeEnvironmentList', paramObj)
    },
    // //  店铺外部环境
    async deleteIsRead(paramObj) {
        return await POST(host1, 'user/cloudStore/deleteIsRead', paramObj)
    },
    // //  获取文件夹路径
    async folderPath(paramObj) {
        return await POST(host1, 'user/cloudStore/folderPath', paramObj)
    },

    //  判断保存还是新建
    async judgeStoreSave(paramObj) {
        return await POST(host1, 'user/cloudStore/judgeStoreSave', paramObj)
    },

    //  判断保存还是新建
    async folderStoreInformation(paramObj) {
        return await POST(host1, 'user/cloudStore/folderStoreInformation', paramObj)
    },
    //  云店商品家具数据存储
    async addStoreModel(paramObj) {
        return await POST2(host1, 'user/cloudStore/addStoreModel', paramObj)
    },
    //  云店商品家具数据存储回显
    async storeModelInformation(paramObj) {
        return await POST2(host1, 'user/cloudStore/storeModelInformation', paramObj)
    },
    // 有模型的项目
    async modelProjectList(paramObj) {
        return await POST(host1, 'user/cloudStore/modelProjectList', paramObj)
    },
    // 云店模型列表
    async projectModelList(paramObj) {
        return await POST(host1, 'user/cloudStore/projectModelList', paramObj)
    },
    // 修改模型数据
    async updateProjectMode(paramObj) {
        return await POST(host1, 'user/cloudStore/updateProjectMode', paramObj)
    },
    //模型团队回显
    async modelGroupList(paramObj) {
        return await POST(host1, 'user/cloudStore/modelGroupList', paramObj)
    },


    //保存实景云店
    async addSceneryCloudStore(paramObj) {
        return await POST2(host1, 'user/cloudStore/addSceneryCloudStore', paramObj)
    },




    //实景云店分享
    async sceneryCloudStoreShare(paramObj) {
        return await POST(host1, 'user/cloudStore/sceneryCloudStoreShare', paramObj)
    },
    //云店锁
    async storeLocal(paramObj) {
        return await POST(host1, 'user/cloudStore/storeLocal', paramObj)
    },

    //云店状态
    async storeState(paramObj) {
        return await POST(host1, 'user/cloudStore/storeState', paramObj)
    },
    //刷新云店锁
    async refreshStoreLocal(paramObj) {
        return await POST(host1, 'user/cloudStore/refreshStoreLocal', paramObj)
    },

    // 云店商品回显
    async commodityManage(paramObj) {
        return await POST(host1, 'user/cloudStore/commodityManage', paramObj)
    },

    // 云店链接回显
    async linkManage(paramObj) {
        return await POST(host1, 'user/cloudStore/linkManage', paramObj)
    },

    // 关闭云店链接
    async closeLink(paramObj) {
        return await POST(host1, 'user/cloudStore/closeLink', paramObj)
    },
    // 云店预览
    async cloudStorePreview(paramObj) {
        return await POST2(host1, 'user/cloudStore/cloudStorePreview', paramObj)
    },



    // CAD- 获取文件夹树数据
    async folderTreeCDA(paramObj) {
        return await POST(host1, 'project/CAD/folderTreeCDA', paramObj)
    },

    // CAD- 创建文件夹
    async addCADFolder(paramObj) {
        return await POST(host1, 'project/CAD/addCADFolder', paramObj)
    },
    // CAD- 删除文件文件夹
    async CADdeleteFolderFile(paramObj) {
        return await POST(host1, 'project/CAD/deleteFolderFile', paramObj)
    },
    // CAD- 修改文件夹名称
    async updateFolderName(paramObj) {
        return await POST(host1, 'project/CAD/updateFolderName', paramObj)
    },
    // CAD- 新增物品数据
    async addCADFile(paramObj) {
        return await POST(host1, 'project/CAD/addCADFile', paramObj)
    },
    // CAD- 文件夹内文件列表
    async getSubdirectory(paramObj) {
        return await POST(host1, 'project/CAD/getSubdirectory', paramObj)
    },
    // CAD- 图纸新增
    async addCADDrawing(paramObj) {
        return await POST(host1, 'project/CAD/addCADDrawing', paramObj)
    },


}