<template>
  <div>
    <div class="" >
      <div class="property" >
        <FunI18n class="name" :text="name">


        </FunI18n>
        <div v-if="currentEditor === 'color'" class="editor-color-item" >

          <ColorEditor :color="value.color" :onChange="this.changeColor" />

        </div>

        <el-select class="editor" @change="changeEditor" :value="currentEditor">
          <el-option
              v-for="item in editorTypes.map(editorType => {return {value: editorType, label: t(editorType)}})"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>

      <div v-if="currentEditor === 'constant'" class="editor-item" >
        <NumberInput  :value="value.value" :onChange="changeValue" />
      </div>
      <div v-if="currentEditor === 'intervalValue'" class="editor-item" >
        <NumberInput type="number" :value="value.a" :onChange="changeValueA" />
        <NumberInput type="number" :value="value.b" :onChange="changeValueB" />
      </div>
      <div v-if="currentEditor === 'piecewiseBezier'" class="editor-item" >
              <BezierCurvesViewer :height="40" :width="240" :value="value"
                                  :onChange="changeCurve"/>
      </div>

      <!--    <div v-if="currentEditor === 'randomColor'" class="editor-item" >-->
      <!--    </div>-->
      <div v-if="currentEditor === 'colorRange'" class="editor-item" >
<!--        <VueGpickr v-model="gradient" />-->
      </div>
      <div v-if="currentEditor === 'gradient'" class="editor-item gradient-color-editor" >
        <GradientColorEditor :value="value" :onChange="changeGradient" />

        <!--      <GradientEditor gradient={(value as Gradient)} onChange={this.changeGradient}/>-->
      </div>
      <div v-if="currentEditor === 'vec3'" class="editor-item" >
      </div>
      <!--    <div v-if="currentEditor === 'randomQuat'" class="editor-item" >-->
      <!--    </div>-->
      <div v-if="currentEditor === 'axisAngle'" class="editor-item" >
      </div>
      <div v-if="currentEditor === 'euler'" class="editor-item" >
      </div>
      <div v-if="currentEditor === 'geometry'" class="editor-item" >
      </div>


    </div>



  </div>
</template>
<script>
import {
  AxisAngleGenerator,
  ColorRange,
  ConstantColor,
  ConstantValue, EulerGenerator,
  Gradient,
  IntervalValue,
  PiecewiseBezier,
  RandomColor, RandomQuatGenerator
} from "three.quarks";
import {Vector3, Vector4} from "three";
import NumberInput from "@/views/component/NumberInput.vue";
import FunI18n from "@/views/component/FunI18n.vue";
import {t} from "@/util/lang";
import BezierCurvesViewer from "@/views/component/bezier/BezierCurvesViewer.vue";
import ColorEditor from "@/views/ParticleSystem/Editors/ColorEditor.vue";
import GradientColorEditor from "@/views/ParticleSystem/Editors/GradientColorEditor.vue";

const EditorType = [
  'constant',
  'intervalValue',
  'piecewiseBezier',
  'color',
  'randomColor',
  'colorRange',
  'gradient',
  'vec3',
  'randomQuat',
  'axisAngle',
  'euler',
  'geometry'
];

const ValueType = ['value', 'valueFunc', 'color', 'colorFunc', 'vec3', 'rotationFunc', 'geometry'];

const ValueToEditor = {
  'value': ['constant', 'intervalValue'],
  'valueFunc': ['piecewiseBezier'],
  'color': ['color', 'randomColor'],
  'colorFunc': ['colorRange', 'gradient'],
  'rotationFunc': ['randomQuat', 'axisAngle', 'euler'],
  'vec3': ['vec3'],
  'geometry': ['geometry']
};
// const gradient = new LinearGradient({
//   angle: 0,
//   stops: [
//     ['#0359b5', 0],
//     ['#f6ce01', 1]
//   ]
// });
// function hexToVector4(hex, alpha) {
//   // 移除可能存在的 '#' 字符
//   hex = hex.replace("#", "");
//   let r, g, b;
//
//   // 根据颜色值的长度进行不同的处理
//   if (hex.length === 3) {
//     // 处理 3 位颜色值（例如 #abc）
//     r = parseInt(hex[0] + hex[0], 16) / 255;
//     g = parseInt(hex[1] + hex[1], 16) / 255;
//     b = parseInt(hex[2] + hex[2], 16) / 255;
//   } else if (hex.length === 6) {
//     // 处理 6 位颜色值（例如 #aabbcc）
//     r = parseInt(hex.substring(0, 2), 16) / 255;
//     g = parseInt(hex.substring(2, 4), 16) / 255;
//     b = parseInt(hex.substring(4, 6), 16) / 255;
//   } else if (hex.length === 8) {
//     // 处理 8 位颜色值（例如 #aabbccdd）
//     r = parseInt(hex.substring(0, 2), 16) / 255;
//     g = parseInt(hex.substring(2, 4), 16) / 255;
//     b = parseInt(hex.substring(4, 6), 16) / 255;
//     // 提取 alpha 值（范围为 0 到 1）
//     alpha = parseInt(hex.substring(6, 8), 16) / 255;
//   } else {
//     // 不支持的颜色值格式
//     throw new Error("Invalid hex color value");
//   }
//
//   // 根据给定的透明度计算 RGBA 值
//   // const rgba = `rgba(${r}, ${g}, ${b}, ${alpha || 1})`;
//
//   return new Vector4(r, g, b, alpha || 1);
// }
export default {
  computed: {
    PiecewiseBezier() {
      return PiecewiseBezier
    }
  },
  components: {BezierCurvesViewer, FunI18n, NumberInput, ColorEditor, GradientColorEditor},
  props: {
    name: String,
    value: Object,
    allowedType: Array,
    onChange: Function,
  },
  watch: {
    value: {
      handler(newValue, oldValue) {
        this.currentEditor = this.getEditorType(newValue)
      },
      immediate: true
    },
    allowedType: {
      handler(newValue, oldValue) {
        this.editorTypes.length = 0
        for (const valueType of newValue) {
          for (const editorType of ValueToEditor[valueType]) {
            this.editorTypes.push(editorType);
          }
        }
      },
      immediate: true
    },
    // gradient(newVal) {
    //   const sortStops = newVal.stops.sort((a, b) => a[1] - b[1])
    //   const rangeArray = []
    //   sortStops.forEach((item, index) => {
    //     console.log(item[0],sortStops[index === sortStops.length - 1 ? index : index + 1][0])
    //     const colorRange = new ColorRange(hexToVector4(item[0]), hexToVector4(sortStops[index === sortStops.length - 1 ? index : index + 1][0]))
    //     const rangeItem = [colorRange, item[1]]
    //     rangeArray.push(rangeItem)
    //   })
    //   const gradient = new Gradient(rangeArray)
    //   this.$props.onChange(gradient)
    //   console.log(gradient, this.$props.onChange)
    //
    //
    //   // console.log(hexToVector4(newVal.stops[2][0]),'AAAA')
    // }

  },
  data() {
    return {
      currentEditor: null,
      editorTypes: [],
    }
  },
  methods: {
    t,
    changeEditor(value) {
      console.log(value,'value', this.$props.value)
      const editorType = value;
      let generator = null;
      switch(editorType) {
        case "constant":
          generator = new ConstantValue(0);
          break;
        case "color":
          generator = new ConstantColor(new Vector4(1,1,1,1));
          break;
        case "intervalValue":
          generator = new IntervalValue(0, 1);
          break;
        case "colorRange":
          generator = new ColorRange(new Vector4(0,0,0,1), new Vector4(1,1,1,1));
          break;
        case "randomColor":
          generator = new RandomColor(new Vector4(0,0,0,1), new Vector4(1,1,1,1));
          break;
        case "gradient":
          generator = new Gradient([[new ColorRange(new Vector4(0,0,0,1), new Vector4(1,1,1,1)), 0],
            [new ColorRange(new Vector4(1,1,1,1), new Vector4(1,1,1,1)), 1]]);
          break;
        case "piecewiseBezier":
          generator = new PiecewiseBezier();
          break;
        case "randomQuat":
          generator = new RandomQuatGenerator();
          break;
        case "axisAngle":
          generator = new AxisAngleGenerator(new Vector3(0, 1, 0), new ConstantValue(Math.PI / 2));
          break;
        case "euler":
          generator = new EulerGenerator(new ConstantValue(0), new ConstantValue(0), new ConstantValue(0));
          break;
      }
      if (generator)
        this.$props.onChange(generator);
    },

    changeValue(x) {
      this.$props.onChange(new ConstantValue(x));
    },

    changeColor(x) {
      this.$props.onChange(new ConstantColor(x));
    },

    changeValueA(x) {
      const interval = this.$props.value;
      this.$props.onChange(new IntervalValue(x, interval.b));
    },

    changeValueB(x) {
      const interval = this.$props.value;
      this.$props.onChange(new IntervalValue(interval.a, x));
    },

    changeColorRangeA(x) {
      const colorRange = this.$props.value;
      this.$props.onChange(new ColorRange(x, colorRange.b));
    },

    changeColorRangeB(x) {
      const colorRange = this.$props.value;
      this.$props.onChange(new ColorRange(colorRange.a, x));
    },

    changeRandomColorA(x) {
      const randomColor = this.$props.value;
      this.$props.onChange(new RandomColor(x, randomColor.b));
    },
    changeRandomColorB(x) {
      const randomColor = this.$props.value;
      this.$props.onChange(new RandomColor(randomColor.a, x));
    },

    changeGradient(x) {
      this.$props.onChange(x);
    },

    changeCurve(x) {
      this.$props.onChange(new PiecewiseBezier(x.functions));
    },

    changeAxis(x, y, z) {
      this.$props.onChange(new AxisAngleGenerator(new Vector3(x, y, z), this.$props.value.angle));
    },

    changeAngle(x) {
      this.$props.onChange(new AxisAngleGenerator(this.$props.value.axis, x));
    },

    changeEulerAngle(pos) {
      const value = this.$props.value;
      switch (pos) {
        case 0:
          return function(changedValue) {
            this.$props.onChange(new EulerGenerator(changedValue, value.angleY, value.angleZ));
          };
        case 1:
          return function(changedValue) {
            this.$props.onChange(new EulerGenerator(value.angleX, changedValue, value.angleZ));
          };
        case 2:
          return function(changedValue) {
            this.$props.onChange(new EulerGenerator(value.angleX, value.angleY, changedValue));
          };
      }
    },

    getEditorType(generator) {
      if (generator instanceof ConstantValue) {
        return 'constant';
      } else if (generator instanceof IntervalValue) {
        return 'intervalValue';
      } else if (generator instanceof PiecewiseBezier) {
        return 'piecewiseBezier';
      } else if (generator instanceof ConstantColor) {
        return 'color';
      } else if (generator instanceof RandomColor) {
        return 'randomColor';
      } else if (generator instanceof ColorRange) {
        return 'colorRange';
      } else if (generator instanceof Gradient) {
        return 'gradient';
      } else if (generator instanceof RandomQuatGenerator) {
        return 'randomQuat';
      } else if (generator instanceof AxisAngleGenerator) {
        return 'axisAngle';
      } else if (generator instanceof EulerGenerator) {
        return 'euler';
      }
      return 'constant';
    }
  }
}
</script>
<style lang="less">
.editor-item {
  margin: 20px 0px 20px 150px;
}
.gradient-color-editor {
  margin-left: 0px;
  z-index: 999;
  display: flex;
  justify-content: center;
}
.editor-color-item {
  margin-right: 55px;

  border: none;

  .el-color-picker__trigger {
    border: none;
    width: 150px;
    height: 28px;


    .el-color-picker__color {
      border-radius: 6px;
      overflow: hidden;
      border: 1px solid black;
      .el-color-picker__color-inner {
      }
    }
    .el-color-picker__icon {
      display: none;
    }



  }

}
</style>