
import * as THREE from 'three';
import { MainObjects } from "../common/MainObjects"
import { ObjectFactory } from '../factory/ObjectFactory';
import { INetwork } from '../interface/INetwork';
import { IRender } from '../interface/IRender';
import { IScene } from "../interface/IScene"
import { ISetting } from '../interface/ISetting';
import { GameObject } from '../object/GameObject';
import { MaterialObject } from '../object/MaterialObject';
import { TextureObject } from '../object/TextureObject';
import { Loader } from './Loader';
import { IImage } from "../interface/IRes"

class Saver {
    static m_tempSaveName = 'tempSave';
    static m_resMap = new Map();
    static toSave() {
        var data = {
            camera: MainObjects.Blueprint.m_cameraListenerBp.getCameraJson(),
            setting: MainObjects.Setting,
            settingData: {
                ambientSetting : undefined,
                hemisphereSetting : undefined,
                fogSetting : undefined
            },
            userData: new Array()
        }

        data.settingData.ambientSetting = ISetting.getAmbientSetting(true);
        data.settingData.hemisphereSetting = ISetting.getHemisphereSetting(true);
        data.settingData.fogSetting = ISetting.getFogValue();
        
        for (let index = 0; index < MainObjects.Scene.m_userGroup.children.length; index++) {
            const element = MainObjects.Scene.m_userGroup.children[index];
            if(element.userData != null)
            {
                var objData = MainObjects.Blueprint.m_sceneManagerBp.getObjectJsonInfo(element);
                data.userData.push(objData);
            }
        }
        let json = JSON.stringify(data);
        MainObjects.Blueprint.m_sceneManagerBp.m_db.scene.clear();
        MainObjects.Blueprint.m_sceneManagerBp.m_db.scene.add({id: Saver.m_tempSaveName, value: json}).then(function() {
            if (IScene.f_saveSceneCallback != null) {
                IScene.f_saveSceneCallback(json);
            }
        });

    }

    static saveToLocal(data, callback) {
        let json = JSON.stringify(data);
        if (json == "{}") {
            callback();
            return;
        }
        MainObjects.Blueprint.m_sceneManagerBp.m_db.scene.clear();
        MainObjects.Blueprint.m_sceneManagerBp.m_db.scene.add({id: Saver.m_tempSaveName, value: json}).then(function() {
            callback();
        });
    }

    static autoLoadSave() {

        Saver.getSaveInDb(function(array)
        {
            var data = JSON.parse(array.value);
            var manager = new THREE.LoadingManager();
            manager.onProgress = function (url, itemsLoaded, itemsTotal) {
    
            };
    
            manager.onError = function (url) {
                console.log('There was an error loading ' + url);
            };
    
            manager.onLoad = function () {
                console.log('Loading complete!');

                var manager2 = new THREE.LoadingManager();
                manager2.onLoad = function () {

                }
                for (let index = 0; index < data.userData.length; index++) {
                    const element = data.userData[index];
                    MainObjects.Blueprint.m_sceneManagerBp.getObjectFromJson(element, function (object) { }, false, manager2);
                }
                MainObjects.Blueprint.m_animationManagerBp.initSkeleton();
                MainObjects.Blueprint.m_collisionsManagerBp.initCollisions();
                MainObjects.Blueprint.m_behaviorBp.init();
                setTimeout(function(){
                    if(IScene.f_sceneTreeCallback != null)
                    {
                        IScene.f_sceneTreeCallback();
                    }
                }, 100);
                if(INetwork.f_loadSceneFinishCallback != null)
                {
                    INetwork.f_loadSceneFinishCallback();
                }
            };
            Saver.m_resMap.clear();
            MainObjects.Blueprint.m_collisionsManagerBp.m_needColliderList.clear();
            MainObjects.Blueprint.m_behaviorBp.m_behavior.clear();
            for (let index = 0; index < data.userData.length; index++) {
                const element = data.userData[index];
                Saver.getObjectFromJson(element);
            }
            if(Saver.m_resMap.size > 0)
            {
                Saver.loadFileToScene(manager);
            }
            else
            {
                manager.onLoad();
            }
            if(data.camera != undefined)
            {
                MainObjects.Blueprint.m_cameraListenerBp.setCameraJson(data.camera);
            }
            if(data.setting != undefined)
            {
                Object.assign(MainObjects.Setting, data.setting)
                if(MainObjects.Setting.m_ip == undefined)
                {
                    MainObjects.Setting.m_ip = "127.0.0.1";
                }
                if (IRender.f_renderModeCallback != null) {
                    IRender.f_renderModeCallback(MainObjects.Setting.m_renderMode);
                }
                if(MainObjects.Setting.m_grideVisible != undefined)
                {
                    ISetting.setGrideVisible(MainObjects.Setting.m_grideVisible);
                }
                if(MainObjects.Setting.m_exposure != undefined)
                {
                    ISetting.setExposure(MainObjects.Setting.m_exposure);
                }
                if(MainObjects.Setting.m_turnPhysicsHelper == undefined)
                {
                    MainObjects.Setting.m_turnPhysicsHelper = true;
                }
                if(MainObjects.Setting.m_envMap == undefined)
                {
                    MainObjects.Setting.m_envMap = IImage.hdr
                }   
                if(MainObjects.Setting.m_envMapImage == undefined)
                {
                    MainObjects.Setting.m_envMapImage = IImage.hdr_image
                } 
                if(MainObjects.Setting.m_turnFog == undefined)
                {
                    MainObjects.Setting.m_turnFog = false; 
                }
            }
    
            if(data.settingData != undefined)
            {
                ISetting.setEnvLightMode(MainObjects.Setting.m_envLightMode);
                ISetting.setAmbientSetting(data.settingData.ambientSetting);
                ISetting.setHemisphereSetting(data.settingData.hemisphereSetting);
                ISetting.setTurnFog(MainObjects.Setting.m_turnFog);
                if(data.settingData.fogSetting)
                {
                    ISetting.setFogValue(data.settingData.fogSetting);

                } 
            }
        });
    }

    static async getSaveInDb(callback)
    {
       var temp = await MainObjects.Blueprint.m_sceneManagerBp.m_db.scene.where('id').equals(Saver.m_tempSaveName).first();
       callback(temp)
    }

    static isHavaSave(callback) {
        Saver.getSaveInDb(function(temp)
        {
            if(temp == undefined)
            {
                callback(false);
            }
            else
            {
                callback(true);
            }
        });
    }

    static removeSave() {
        if(MainObjects.Blueprint.m_sceneManagerBp != null && MainObjects.Blueprint.m_sceneManagerBp.m_db != null)
        {
            if(MainObjects.Blueprint.m_sceneManagerBp.m_db.scene != null)
            {
                MainObjects.Blueprint.m_sceneManagerBp.m_db.scene.clear();
            }      
        }
    } 

    static getObjectFromJson(iterator) {
        if (iterator.userData.m_gameObjectType == GameObject.GameObjectType.e_GLTF) {
            var url = INetwork.getUrl(iterator.userData.m_url);
            var gameScene = MainObjects.Blueprint.m_sceneManagerBp.m_gameScene;
            var gltfCopy = gameScene.m_gltfRepos.get(url);
            if(gltfCopy == undefined || gltfCopy == null)
            {
                Saver.m_resMap.set(url, {
                    m_url : iterator.userData.m_url,
                    m_id : iterator.userData.m_id
                });
            }
            for (let index = 0; index < iterator.materials.length; index++) {
                const element = iterator.materials[index];
                Saver.getMaterialFromJson(element);
            }
        }
        else if(iterator.userData.m_gameObjectType == GameObject.GameObjectType.e_AUDIO)
        {
            var url = INetwork.getUrl(iterator.userData.m_url);
            var gameScene = MainObjects.Blueprint.m_sceneManagerBp.m_gameScene;
            var mp3Copy = gameScene.m_mp3Repos.get(url);
            if(mp3Copy == undefined || mp3Copy == null)

            {
                // Saver.m_resMap.set(url, {
                //     m_url : iterator.userData.m_url,
                //     m_id : iterator.userData.m_id
                // });
            }
        }  
        else if (iterator.userData.m_gameObjectType == GameObject.GameObjectType.e_GROUP) {
            if (iterator.children != undefined && iterator.children.length > 0) {
                for (const child of iterator.children) {
                    if(child.userData != null)
                    {
               

                        Saver.getObjectFromJson(child);
                    }
                }
            }
        }
    }

    static getMaterialFromJson(element) {
        if (element.type == MaterialObject.MaterialType.e_BASIC) {
            Saver.getTextureFromJson(element.specularMap);
        }
        else if (element.type == MaterialObject.MaterialType.e_STANDARD) {
            Saver.getTextureFromJson(element.emissiveMap);
            Saver.getTextureFromJson(element.metalnessMap);
            Saver.getTextureFromJson(element.normalMap);
            Saver.getTextureFromJson(element.roughnessMap);
        }
        Saver.getTextureFromJson(element.aoMap);
        Saver.getTextureFromJson(element.envMap);
        Saver.getTextureFromJson(element.lightMap);
        Saver.getTextureFromJson(element.map);
    }

    
    static getTextureFromJson(textureData) {
        if (textureData == null) {
            return;
        }
        if (textureData.type == TextureObject.TextureObjectType.e_NET_MAP) {
            var gameScene = MainObjects.Blueprint.m_sceneManagerBp.m_gameScene;
            var url = INetwork.getUrl(textureData.url);
            var textureCopy = gameScene.m_textureRepos.get(url);   
            if(textureCopy == undefined || textureCopy == null)
            {
                Saver.m_resMap.set(url, {
                    m_url : textureData.m_url,
                    m_id : textureData.m_id
                });
            }
          
        }
    }

    static loadFileToScene(manager)
    {   
        for(var [key, value] of Saver.m_resMap)
        {
            Loader.loadFileToScene(key, value.m_url, value.m_id, function (p) { }, function (res) {
            }, manager, "model", new THREE.Scene());
        }

    }
}

export { Saver };