<template>
  <!-- <div class="EditorBox"> -->
  <div class="box">
    <div class="title redactTitle" v-if="upgrading!=1">
      <img src="../../assets//Project/file.png" alt />
      <div class="changeFileName">
        <!-- <el-input v-if="upgrading==1" v-model="fileName" placeholder="在此编辑文件名称" @focus="Focus" @blur="Blur"></el-input> -->
        <div class="updateName">{{upDataNode.name}}</div>
      </div>
    </div>
    <div class="title" v-if="upgrading==1">
      <img src="../../assets//Project/file.png" alt />
      <div class="createChangeFileName changeFileName">
        <el-input
          v-if="upgrading==1"
          v-model="fileName"
          placeholder="在此编辑文件名称"
          @focus="Focus"
          @blur="Blur"
        ></el-input>
        <!-- <div v-else class="updateName">{{upDataNode.name}}</div> -->
      </div>
    </div>

    <div class="topAddress">
      <div class="catalogue">
        <!-- <p>目录回显：</p>  -->
        <div :class="{address:true,
            pitchAddress: upgrading==1
            } ">
          <div class="path">
            <div class="pathIcon">
              <img class="iconImg" src="../../assets/Resource/project_icon.png" alt />
            </div>
            <div class="projectName">{{ projectName }}</div>
            <div class="point">
              <img class="iconImg path_point" src="../../assets/Resource/path_point.png" alt />
            </div>
          </div>

          <div class="path" v-if="$store.state.creatingMaterialTypes==1||$store.state.creatingMaterialTypes==2">
            <div class="pathIcon">
            </div>
            <img class="iconImg" src="../../assets/Resource/icon_model.png" alt />
            
            <div class="projectName">模型</div>

            
            <div class="point" v-if="$store.state.creatingMaterialTypes==3" >
              <img class="iconImg path_point" src="../../assets/Resource/path_point.png" alt />
            </div>
          </div>

          <div class="path">
            <div class="pathIcon">
              <img class="iconImg" src="../../assets/Resource/icon_chartlet.png" alt />

            </div>
            <div class="projectName">贴图</div>

            
            <div class="point">
              <img class="iconImg path_point" src="../../assets/Resource/path_point.png" alt />
            </div>
          </div>



          <div class="path" v-for="(item, index) in textureUrl" :key="index">
            <div class="pathIcon" v-show="index != textureUrl.length - 1">
              <img
                class="iconImg"
                v-show="index != textureUrl.length - 1"
                src="../../assets/Resource/path_file_list.png"
                alt
              />
            </div>

            <div class="pathIcon" v-show="index == textureUrl.length - 1">
              <img
                v-show="index == textureUrl.length - 1"
                class="iconImg Path_file"
                src="../../assets/Resource/Path_file_list_checked.png"
                alt
              />
            </div>

            <div
              :class="{
                    pathTitle: index != textureUrl.length - 1,
                    endpathTitle: index == textureUrl.length - 1,
                  }"
            >{{ item }}</div>
            <div class="point" v-show="index != textureUrl.length - 1">
              <img
                v-show="index != textureUrl.length - 1"
                class="iconImg path_point"
                src="../../assets/Resource/path_point.png"
                alt
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="header_box">
      <div class="preview" id="preview-container"></div>

      <div class="button" @click="affirm">保存</div>
    </div>
  </div>
  <!-- </div> -->
</template>
<script>
import { PreviewResouse } from "../../threejs/common/PreviewResouse";
import { MainObjects } from "../../threejs/common/MainObjects";
import { INetwork } from "../../threejs/interface/INetwork";
import { ISetting } from "../../threejs/interface/ISetting";
import { IResousePreview } from "../../threejs/interface/IResousePreview";

export default {
  props: {
    sid: {
      type: String,
      default: "0"
    }
  },
  data() {
    return {
      //父节点id
      fatherNodeid: "",

      //上传文件列表
      fileUelList: [],
      //进度条
      progressBar: 0,
      //主url
      mainUrl: "",
      //所有url
      url_list: "",

      //加载进度条显示隐藏
      progressBarShow: false,

      totalSize: 0,

      //对话框
      dialogFormVisible: true,

      name_list: [],
      //修改的文件id
      fileId: "",
      //进度条文字提示
      progressText: "正在上传",

      fileName: "",
      //点击多次只触发一次
      state: false,

      title: "上传模型",

      loadingProgressBar: 0,

      //路径
      textureUrl: [],

      projectName: ""
    };
  },
  props: [
    //拿到文件url现在没用到
    "fileUrl",
    //拿到上传的文件
    "files",
    //模型还是材质现在没用到
    "ext",

    "upDataId",
    //更改还是上传 调用不同的接口
    "upgrading",
    //父文件id
    "FileDirectoryId",
    // 传过来的右侧树的类型
    "ResourceType",

    //传过来的更新node
    "upDataNode"
  ],
  mounted() {

    // console.log(
    //   "fileUrl",
    //   this.fileUrl,
    //   "files",
    //   this.files,
    //   "ext",
    //   this.ext,
    //   "upDataId",
    //   this.upDataId,
    //   "upgrading",
    //   this.upgrading,
    //   "FileDirectoryId",
    //   this.FileDirectoryId
    // );

    var option = {};
    option.container = document.getElementById("preview-container");

    if (MainObjects.MainRender.m_previewResouse == null) {
      MainObjects.MainRender.m_previewResouse = new PreviewResouse(option);

    } else {
      MainObjects.MainRender.m_previewResouse.addRenderDom(option);


    }
    this.uploading();

    this.projectName = localStorage.getItem("projectName");

    var textureUrl = localStorage.getItem("textureUrl");
    this.textureUrl = JSON.parse(textureUrl);
  },
  methods: {
    //确认资源
    affirm() {
      // this.debounce(this.onOk(),1000)

      if (this.state == false) {
        let that = this;
        that.state = true;

        this.onOk();
        
        setTimeout(() => {
          //请求数据。。。
          //请求成功后在返回的状态里面加上下面这句话
          that.state = false;
        }, 3000);
      }
    },
    //点击确认执行
    onOk() {
      this.$store.commit("setTextureBallShow", false);

      ISetting.isInput(false);


      // 节流
      ISetting.isInput(false);



      if (this.upgrading == 2) {
        // 确认更改

        //更改
        var fileId;
        var updateFileId = localStorage.getItem("updateFileId");
        if (
          updateFileId !== undefined &&
          updateFileId !== null &&
          updateFileId !== ""
        ) {
          fileId = updateFileId;
        } else {
          fileId = this.fileId;
        }
   
        // INetwork.copyFilesToSql(this.files, fileId, this.ext, () => {
        this.updateFile()

          .then(() => {
            //更新完成
            this.$emit("transfer", false);
            // this.$emit("setFileDirectoryId", this.FileDirectoryId);
          })
          .catch(() => {

            this.$emit("setFileDirectoryId", this.FileDirectoryId);
          });
        // });

        // this.$emit("setFileDirectoryId", this.FileDirectoryId);

        // this.dialogFormVisible = false;
        // this.$emit("transfer", false);
      } else if (this.upgrading == 1) {
        if (this.fileName == "") {
          this.$message({
            message: "文件名不为空"
            // type: "warning"
          });
        } else {
          this.addFile();
        }
        // 处理数据 先传 空  然后 调用 更新
        // 上传

        // var id = this.GenNonDuplicateID(64);

        // INetwork.copyFilesToSql(this.files, id, this.ext, () => {});
        // var data = INetwork.getFilesNameToSql(this.files, id, this.ext);
      }
    },

    //回显
    uploading() {

      // INetwork.uploadFilesToPreview(
      //   this.files,
      //   this.process.bind(this),
      //   this.finish.bind(this)
      // );


      if (this.upgrading == 1) {
        let creatingMaterialTypes = this.$store.state.creatingMaterialTypes;
        if (creatingMaterialTypes != null) {
    


          IResousePreview.loadMaterial(null, () => {},creatingMaterialTypes);
        } else {
          console.error("创建材质类型错误");
        }
      } else if (this.upgrading == 2) {

        INetwork.downloadJson(this.upDataNode.fileUrl, data => {
          var json = data;

          IResousePreview.loadMaterial(json, () => {});
        });
      }
    },
    formatData(percentage) {


      return `${percentage}%`;
    },

    process(p) {

      this.progressBar = parseInt(p * 100);
      this.progressText = "正在上传";
    },

    processLoad(p) {


      // if (this.progressBar==100) {
      //   this.progressBar=0
      // }
      this.loadingProgressBar = parseInt(p * 100);
      this.progressText = "正在加载";
      //加载进度
      //屏蔽快捷键
      ISetting.isInput(true);
    },

    finish(data) {

 
      var url_list = new Array();
      var totalSize = 0;
      var name_list = new Array();
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        url_list.push(element.url);
        name_list.push(element.name);
        totalSize = totalSize + element.size;
      }
      this.name_list = name_list;

      this.totalSize = totalSize;

      // this.progressBar = 0;
      this.progressBar = 100;

      //显示加载进度条 拿到大小 totalSize url  mainName 附属url  name_list

      IResousePreview.loadFile(
        url_list,
        this.ext,
        this.processLoad.bind(this),
        this.finishLoad.bind(this)
      );
    },
    finishLoad() {
      this.loadingProgressBar = 100;
      this.dialogFormVisible = true;


    },

    //取消 点击弹框的 X
    cancel() {
      var fileId = this.fileId;

      if (fileId != "") {
        //删除
        this.$parent.ondelFile(1, this.fileId, this.FileDirectoryId);
      }

      ISetting.isInput(false);

      //更新父组件中的值 让弹框隐藏
      this.dialogFormVisible = false;

      this.$emit("transfer", false);

      IResousePreview.close();

      INetwork.deleteFiles(this.name_list);
      this.fileName = "";
    },
    //加载
    openFullScreen() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      setTimeout(() => {
        loading.close();
      }, 2000);
    },

    //调用后台接口 添加文件
    async addFile() {
      // let fileName = this.mainUrl.split("/");
      // let name = fileName[fileName.length - 1];
      // name = name.split("?")[0];


      let creatingMaterialTypes = this.$store.state.creatingMaterialTypes;
      let type=7
      if (creatingMaterialTypes != null) {
        if (creatingMaterialTypes==0) {
          type=9
        }else if (creatingMaterialTypes==1) {
          type=7
        }else if(creatingMaterialTypes==2){
          //冯氏材质
          type=10
        }else if(creatingMaterialTypes==3){
          //卡通捕捉
          type=19
        }else if(creatingMaterialTypes==4){
          //卡通捕捉
          type=20
        }
        
      }
      

      const res = await this.$https.addFile({
        //    this.url_list=url_list
        // this.mainUrl=mainUrl
        //文件夹id
        // folderId:this.id,
        projectId: localStorage.getItem("projectId"),
        folderId: this.upDataId,
        name: this.fileName,
        type: type,
        fileUrl: "",
        fileSize: 0,
        imageUrl: "",
        deputyUrl: ""
      });
      if (res.success == true) {
        // 上传成功
        this.fileId = res.data;
    
        // INetwork.copyFilesToSql(this.files, res.data, this.ext, () => {
        //   this.updateFile().then(() => {
        //     this.$emit("setFileDirectoryId", this.FileDirectoryId);
        //   });
        // });

        //  INetwork.copyFilesToSql( res.data, this.ext, () => {
        this.updateFile().then(() => {
          // this.$emit("setFileDirectoryId", this.FileDirectoryId);
        });

        // });

        // .catch(()=>{
        //     this.$emit("setFileDirectoryId", this.FileDirectoryId);

        // })

        //获取文件

        // this.$parent.getSubCatalog(this.upDataId);

        this.dialogFormVisible = false;
        // this.$emit("transfer", false);
        this.$parent.closeChange();
      } else {
        var token = localStorage.getItem("token");
        if (token) {
          this.$message({
            message: res.errMessage
            // type: "warning"
          });
        }
      }
    },
    // setFileId(msg) {
  
    //   this.fileId = msg;
    // },

    //调用后台接口 更改文件
    async updateFile() {
      var fileId;
      var updateFileId = localStorage.getItem("updateFileId");
      if (
        updateFileId !== undefined &&
        updateFileId !== null &&
        updateFileId !== ""
      ) {
        fileId = updateFileId;
      } else {
        fileId = this.fileId;
      }

      var name;
      if (this.upgrading == 1) {
        name = this.fileName;
      } else if (this.upgrading == 2) {
        name = this.upDataNode.name;
      }


      //获取真实的数据 这里需要传文件类型
      // var data = INetwork.getFilesNameToSql(this.files, fileId, this.ext);

      //TODO: CALLBACK FINSIH
      var data = INetwork.getMaterialJsonToSql(fileId, () => {});



      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      var time = new Date().getTime();

      const res = await this.$https.updateFile({
        //文件夹id
        // folderId:this.id,
        projectId: localStorage.getItem("projectId"),
        id: fileId,
        // name: name,
        fileUrl: data.mainName,
        imageUrl: data.imageName + "?" + "time=" + time,
        deputyUrl: data.otherNameList.toString(),
        fileSize: this.totalSize
      });

      if (res.errCode == null) {
        IResousePreview.close();
        loading.close();
        this.fileName = "";


        this.$parent.setFileDirectoryId(this.FileDirectoryId);

        localStorage.removeItem("updateFileId");

        this.dialogFormVisible = false;

        // this.$emit("transfer", false);
        this.$parent.closeChange();

      

        if (this.upgrading == 2) {
          this.$parent.getSubCatalog(this.upDataNode.parentId);
        } else {
          this.$parent.getSubCatalog(this.upDataId);
        }
      } else {
        loading.close();
      }
    },

    //生成id
    GenNonDuplicateID(randomLength) {
      let idStr = Date.now().toString(36);
      idStr += Math.random()
        .toString(36)
        .substr(3, randomLength);
      return idStr;
    },

    //防抖
    debounce(fn, delay) {
      var delay = delay || 200;
      var timer;
      return function() {
        var th = this;
        var args = arguments;
        if (timer) {
          clearTimeout(timer);
        }
        timer = setTimeout(function() {
          timer = null;
          fn.apply(th, args);
        }, delay);
      };
    },
    Focus() {
      // ISetting.isInput(true);
    },
    Blur() {
      // ISetting.isInput(false);
    },

    //通过id查找节点
    inquireNode(tree, id) {
      for (let index = 0; index < tree.length; index++) {
        if (tree[index].id == id) return tree[index];
        if (tree[index].subCatalog) {
          const res = this.inquireNode(tree[index].subCatalog, id);
          if (res) return res;
        }
      }

      return null;
    }

    // ok() {
    //   this.$parent.closeChange();
    // }
  }
};
</script>




<style scoped>
.box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}

/* .EditorBox {
  width: 60%;
  height: 600px;
  background: #fff;
  border-radius: 10px;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999999999999999999999999;
  display: flex;
  justify-content: space-between;
} */

.title {
  width: 95%;
  height: 55px;
  line-height: 55px;
  padding-left: 30px;
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-top: 40px;
}
.redactTitle {
  height: 33px;

  margin-top: 7px;
  margin-bottom: 6px;
}

.title img {
  width: 15px;
  height: 15px;
  margin-right: 10px;
  margin-left: 10px;
}
.header_box {
  width: 91%;
  height: 86%;
  /* border: 1px solid red; */
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);
  padding-top: 20px;
  /* margin-left: 10px; */
  border-radius: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
  /* justify-content: center; */
  /* margin-top: 45px; */
}
.preview {
  width: 400px;
  height: 330px;
  box-shadow: 1px 1px 6px 1px rgba(33, 35, 49, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin-top: 3px;
}
.topAddress {
  width: 90%;
  margin: 0 auto;
  margin-top: -10px;
}
.catalogue {
  display: flex;
  width: 100%;
  height: 40px;
  align-items: center;
  /* padding-left: 50px; */
  font-weight: bold;
  /* margin: 20px; */

  margin: 10px 2px;
  overflow: auto;
}
.button {
  width: 140px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  background: #0560fd;
  border-radius: 4px;
  color: #fff;
  margin-top: 20px;
}
/* 修改文件名字 */
.changeFileName {
  /* width: 120px; */
  /* margin-top: -100px; */
  /* margin: 40px auto;
  margin-bottom: 40px; */
  margin-right: 20px;
  width: 88%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.createChngeFileName {
  width: 320px;
}

/* 路径 */
.address {
  /* padding-left: 10px; */
  width: 100%;
  height: 38px;
  background: #f7f7f7;
  border-radius: 6px;
  text-align: left;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #929292;
  line-height: 38px;
  overflow: hidden;

  /* margin-left: 85px; */
}
.pitchAddress {
  width: 100%;
  margin-left: 1px;
}
.path {
  box-sizing: border-box;

  height: 40px;

  display: inline-block;
  padding: 7px 5px;
}
.path .pathIcon {
  margin-left: 2px;
  width: 32px;
  height: 16px;
  float: left;
}

.path .pathTitle {
  float: left;
  line-height: 23px;
  font-size: 14px;
  margin-left: 6px;
  margin-right: 10px;
  color: #636363;
}

.projectName {
  float: left;
  line-height: 1.4375rem;
  font-size: 0.875rem;
  margin-left: 0.375rem;
  margin-right: 0.625rem;

  color: #000;
}

.endpathTitle {
  float: left;
  line-height: 23px;
  font-size: 14px;
  margin-left: 10px;
  color: #0560fd;
}

.point {
  float: left;
}
.iconImg {
  float: left;
  margin-top: 4px;

  width: 16px;
  height: 16px;
}
.Path_file {
  width: 15px;
  height: 15px;
}

.path_point {
  margin-top: 7px;

  width: 10px;
  height: 10px;
}
</style>