
import { ParticleFactory } from '../factory/ParticleFactory';
import json from "../mock/particle.json"
import { ObjectsRepos } from '../factory/ObjectsRepos';
import {MainObjects} from "@/threejs/common/MainObjects";
import {BatchedParticleRenderer} from "three.quarks";

class ParticleSystemBp {
    constructor() {
        this.m_particleObject = new ObjectsRepos();
        //inti particleSystem batchrender
        MainObjects.MainRender.m_batchRender = new BatchedParticleRenderer()
        // 依赖SceneFactory.setRenderScene(scene);函数的执行
        MainObjects.Scene.m_renderScene.add(MainObjects.MainRender.m_batchRender)
    }

    init(object)
    {
        this.m_particleObject.add(object.id, object);
    }

    update(delta)
    {
        MainObjects.MainRender.m_batchRender.update(delta * MainObjects.Setting.m_batchRender_speed)

        for(var [id, value] of this.m_particleObject.m_objectsMap)
        {
            ParticleFactory.update(value);
        }
    }

    destory(object)
    {
        this.m_particleObject.remove(object.id);
        ParticleFactory.destory(object);
    }
}

export { ParticleSystemBp };