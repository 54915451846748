import { MainObjects } from "../common/MainObjects"
import { ObjectFactory } from "../factory/ObjectFactory"
import { GameObject } from "../object/GameObject"
import { IScene } from "./IScene"
import { ITransform } from "./ITransform"
import { ObjectsRepos } from "../factory/ObjectsRepos"
class ISelector {
    static TransformMode =
        {
            e_TRANSLAST: 0,
            e_ROTATE: 1,
            e_STRECH : 2,
            e_MAGNETIC : 3,
            e_ALIGN : 4
        }

    static TransformSpace =
        {
            e_WORLD: 0,
            e_LOCAL: 1
        }

    static SelectMode =
        {
            e_S_CLICK: 0,
            e_S_BOX: 1
        }

    static f_transformModeCallback = null;
    static f_transformSpaceCallback = null;
    static f_selectModeCallback = null;
    static f_selectLayerCallback = null;
    static f_turnoffSizeCallback = null;
    static f_sceneTreeSelectCallback = null;
    static m_transformMode = ISelector.TransformMode.e_TRANSLAST;
    static m_selectShiftIdList = [];
    static m_selectShiftStart = false;
    static f_getCurrentO3dCallback = null
    static f_cancelCurrentO3dCallback = null

    static sceneTreeSelectCallback()
    {
        if(IScene.m_treeMode == IScene.TreeMode.e_SELECT_OBJ)
        {
            if(IScene.f_sceneTreeCallback != null)
            {
                IScene.f_sceneTreeCallback();
            }
        }
        if (ISelector.f_sceneTreeSelectCallback != null) {
            const length = MainObjects.Render.m_outlinePass.selectedObjects.length;
            var object_ids = new Array();
            for (let index = 0; index < length; index++) {
                const obj = MainObjects.Render.m_outlinePass.selectedObjects[index];
                object_ids.push(obj.id)
                
            }
            ISelector.f_sceneTreeSelectCallback(object_ids);
        }
    }

    static setTransformMode(mode) {
        switch (mode) {
            case ISelector.TransformMode.e_TRANSLAST: // W
                {
                    MainObjects.Control.m_dragControls.enabled = false;
                    MainObjects.Control.m_dragBoxControls.enabled = false;
                    MainObjects.Control.m_transformControl.enabled = true;
                    MainObjects.Blueprint.m_boundingBoxBp.m_enable = false;
                    MainObjects.Control.m_transformControl.setMode('translate');
                }
                break;
            case ISelector.TransformMode.e_ROTATE: // E
                {
                    MainObjects.Control.m_dragControls.enabled = false;
                    MainObjects.Control.m_dragBoxControls.enabled = false;
                    MainObjects.Control.m_transformControl.enabled = true;
                    MainObjects.Blueprint.m_boundingBoxBp.m_enable = false;
                    MainObjects.Control.m_transformControl.setMode('rotate');
                }
                break;
            case ISelector.TransformMode.e_STRECH:
                {
                    MainObjects.Control.m_transformControl.enabled = false;
                    MainObjects.Control.m_dragControls.enabled = true;
                    MainObjects.Control.m_dragBoxControls.enabled = false;
                    MainObjects.Blueprint.m_boundingBoxBp.m_enable = false;
                }
                break;
            case ISelector.TransformMode.e_MAGNETIC:
                {
                    MainObjects.Control.m_transformControl.enabled = false;
                    MainObjects.Control.m_dragBoxControls.enabled = true;
                    MainObjects.Blueprint.m_boundingBoxBp.m_enable = true;
                    MainObjects.Control.m_dragControls.enabled = false;
                }
                break;
            case ISelector.TransformMode.e_ALIGN:
                {
                    MainObjects.Control.m_transformControl.enabled = false;
                    MainObjects.Control.m_dragBoxControls.enabled = true;
                    MainObjects.Blueprint.m_boundingBoxBp.m_enable = true;
                    MainObjects.Control.m_dragControls.enabled = false;
                }
                break;
        }
        ISelector.m_transformMode = mode;
        MainObjects.Blueprint.m_selectListenerBp.setAxis();
    }

    static setTransformSpace(space) {
        switch (space) //Q
        {
            case ISelector.TransformSpace.e_WORLD:
                {
                    MainObjects.Control.m_transformControl.setSpace('world');
                }
                break;
            case ISelector.TransformSpace.e_LOCAL:
                {
                    MainObjects.Control.m_transformControl.setSpace('local');
                }
                break;
        }
    }

    static setSelectMode() {
        MainObjects.Blueprint.m_selectListenerBp.changeSelectMode()
    }
    static getAllParent(object, callback)
    {
        if(object.parent != null)
        {
            callback(object.parent);
            ISelector.getAllParent(object.parent, callback);
        }
    }
    //选择实例树 
    static selectObjectByTree(object, bMutle = null) {
        if(bMutle == null)
        {
            if(MainObjects.Blueprint.m_eventListenerBp.m_bCtrl == true 
                || MainObjects.Blueprint.m_selectListenerBp.m_selectMode == 1 
                || MainObjects.Blueprint.m_eventListenerBp.m_bShift == true)
            {
                bMutle = true;
            }
            else
            {
                bMutle = false;
            }
        }
        if (!bMutle) {
            if(MainObjects.Render.m_outlinePass.selectedObjects.length == 1)
            {
                if(MainObjects.Render.m_outlinePass.selectedObjects[0].id == object.id)
                {
                    return;
                }
                else
                {
                    MainObjects.Blueprint.m_selectListenerBp.clearSelectObjects();
                }
            }   
            if(MainObjects.Render.m_outlinePass.selectedObjects.length > 1)
            {
                MainObjects.Blueprint.m_selectListenerBp.clearSelectObjects();
            }   
        }
        else{
            var res = false;
            ISelector.getAllParent(object, function(parent){
                let b = MainObjects.Blueprint.m_selectListenerBp.m_selectMap.get(parent.id);
                if(b != null && b != undefined)
                {
                    res = true;
                }
            });

            object.traverse(function(child){
                let bc = MainObjects.Blueprint.m_selectListenerBp.m_selectMap.get(child.id);
                if(bc != null && bc != undefined)
                {
                    res = true;
                }
            });

            if(res)
            {
                return;
            }
        }
        let b = MainObjects.Blueprint.m_selectListenerBp.m_selectMap.add(object.id, object);
        var objectIndexMap = new ObjectsRepos();
        //all shift
        var f_getObjectIndex = function(value, callback)
        {
            for (let index = 0; index < value.parent.children.length; index++) {
                const element = value.parent.children[index];
                if(element.id == value.id)
                {
                    callback(index);
                }
            }
        }
        var f_selectObject = function(b, object)
        {
            if (b) {
                MainObjects.Render.m_outlinePass.selectedObjects.push(object);
                if(object.userData.m_gameObjectType != GameObject.GameObjectType.e_OBJECT){
                    b = object.visible;
                    if(object.isLight){
                        b = object.userData.m_gameObjectHelper.visible;
                    }
                    if(b)
                    {
                        if(object.userData.m_gameObjectType != GameObject.GameObjectType.e_CURVE)
                        {
                            ObjectFactory.changeSpriteSelectColor(object, true);
                            MainObjects.Blueprint.m_selectListenerBp.setAxis();
                        } 
                    }
                }
                if (ITransform.f_selectTransformCallback != null) {
                    ITransform.f_selectTransformCallback(true);
                }
                ISelector.sceneTreeSelectCallback();
                ISelector.f_getCurrentO3dCallback()
            }
        }
        if(MainObjects.Blueprint.m_eventListenerBp.m_bShift)
        {
            for(var [key, value] of  MainObjects.Blueprint.m_selectListenerBp.m_selectMap.m_objectsMap)
            {
                if(objectIndexMap.get(value.parent.id))
                {
                    var minMax = objectIndexMap.get(value.parent.id);
                    f_getObjectIndex(value, function(index){
                        if(index < minMax.min)
                        {
                            minMax.min = index;
                        }
                        if(index > minMax.max)
                        {
                            minMax.max = index;
                        }
                        objectIndexMap.add(value.parent.id, minMax);
                    })
                }
                else
                {
                    var minMax = {
                        min : 100000,
                        max : -1,
                        parent : value.parent
                    };
                    f_getObjectIndex(value, function(index){
                        if(index < minMax.min)
                        {
                            minMax.min = index;
                        }
                        if(index > minMax.max)
                        {
                            minMax.max = index;
                        }
                        objectIndexMap.add(value.parent.id, minMax);
                    })
                }
            }
           
            for(var [key, value] of objectIndexMap.m_objectsMap)
            {
                if(value.max > 0)
                {
                    for (let index = value.min; index < value.max + 1; index++) {
                        const element = value.parent.children[index];
                        let shiftb = MainObjects.Blueprint.m_selectListenerBp.m_selectMap.add(element.id, element);
                        f_selectObject(shiftb, element);
                    }
                }
            }
        }
        f_selectObject(b, object);
       
    }

    static removeSelectObjectByTree(object){
        MainObjects.Blueprint.m_selectListenerBp.m_selectMap.remove(object.id);
        var s = MainObjects.Render.m_outlinePass.selectedObjects.indexOf(object);
        if(s != -1){
            MainObjects.Render.m_outlinePass.selectedObjects.splice(s, 1);
            ObjectFactory.changeSpriteSelectColor(object, false);
            MainObjects.Blueprint.m_boundingBoxBp.setMagneticSprite(object, false);
            MainObjects.Blueprint.m_selectListenerBp.setAxis();
            if(MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjectsCount() == 0)
            {
                if (ITransform.f_selectTransformCallback != null) {
                    ITransform.f_selectTransformCallback(false);
                }
            }
        }
    }

    static setSizeEnable(){
        MainObjects.Blueprint.m_scaleBoxBp.setVisible();
    }

    static setSelectLayer(mode)
    {
        MainObjects.Blueprint.m_selectListenerBp.m_selectLayer = mode;
    }
}

export { ISelector };