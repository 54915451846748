class AnimationBehavior {
    constructor() {
        this.m_animationArray = new Array();
    }

    getJson()
    {
        return this.m_animationArray;
    }
    
    setJson(json)
    {
        this.m_animationArray = json;
    }

    init(object){

    }

    destory()
    {
        
    }
}

export { AnimationBehavior };
