<template>
  <!-- 预览 -->
  <div class="box">
    <!-- 上传预览 -->
    <el-dialog
      width="42.31771vw"
      :close-on-click-modal="false"
      :visible.sync="dialogFormVisible"
      append-to-body
      @opened="openMethods()"
      @close="cancel()"
      center
     
    >
      <div class="title">
        <img class="icon" src="../../assets/Resource/preview_icon.png" alt />

        <p class="title_p">保存到资源</p>

      </div>
      <div class="changeFileName">
        <el-input  v-if="revampResource==false" v-model="fileName" placeholder="在此编辑文件名称" ></el-input>

        <!-- <div v-else class="updateName">{{upDataNode.name}}</div> -->
      </div>

      <div class="examine" slot="footer" id="preview-container"></div>
      
      <div class="masking" v-show="false">
        <div v-show="loadingProgressBar != 100" class="uploading_process">
          <el-progress
            :percentage="loadingProgressBar"
            color=" #0560FD"
            :stroke-width="18"
            class="progressBar"
          ></el-progress>
          <div class="uploading_text">正在加载...</div>

        </div>
      </div>

      <div class="masking" v-show="false">
        <div v-show="progressBar != 100" class="uploading_process">
          <el-progress
            :percentage="progressBar"
            color=" #0560FD"
            :stroke-width="18"
            class="progressBar"
            :format="formatData"
          ></el-progress>
          <div class="uploading_text">正在上传...</div>
        </div>
      </div>
    <!-- <div class="catalogue">
 
            <div class="address">
              <div class="path">
                <div class="pathIcon">
                  <img class="iconImg" src="../../assets/Resource/project_icon.png" alt />
                </div>
                <div class="projectName">{{ projectName }}</div>
                <div class="point">
                  <img class="iconImg path_point" src="../../assets/Resource/path_point.png" alt />
                </div>
              </div>

              <div class="path">
                <div class="pathIcon">
                  <img class="iconImg" src="../../assets/Resource/icon_model.png" alt />
                </div>
                <div class="projectName">模型</div>
                <div class="point" >
                  <img class="iconImg path_point" src="../../assets/Resource/path_point.png" alt />
                </div>
 

              </div>

              <div class="path" v-for="(item, index) in modelUrl" :key="index">
                <div class="pathIcon" v-show="index != modelUrl.length - 1">
                  <img
                    class="iconImg"
                    v-show="index != modelUrl.length - 1"
                    src="../../assets/Resource/path_file_list.png"
                    alt
                  />
                </div>

                <div class="pathIcon" v-show="index == modelUrl.length - 1">
                  <img
                    v-show="index == modelUrl.length - 1"
                    class="iconImg Path_file"
                    src="../../assets/Resource/Path_file_list_checked.png"
                    alt
                  />
                </div>

                <div
                  :class="{
                    pathTitle: index != modelUrl.length - 1,
                    endpathTitle: index == modelUrl.length - 1,
                  }"
                >{{ item }}</div>
                <div class="point" v-show="index != modelUrl.length - 1">
                  <img
                    v-show="index != modelUrl.length - 1"
                    class="iconImg path_point"
                    src="../../assets/Resource/path_point.png"
                    alt
                  />
                </div>
              </div>
            </div>


    </div> -->



      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel()">取 消</el-button>

        <el-button type="primary" @click="affirm()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { PreviewResouse } from "../../threejs/common/PreviewResouse";
import { MainObjects } from "../../threejs/common/MainObjects";
import { INetwork } from "../../threejs/interface/INetwork";
import { ISetting } from "../../threejs/interface/ISetting";
import { IResousePreview } from "../../threejs/interface/IResousePreview"

export default {
  name: "Resource",
  data() {
    return {
      //父节点id
      fatherNodeid: "",

      //上传文件列表
      fileUelList: [],
      //进度条
      progressBar: 0,
      //主url
      mainUrl: "",
      //所有url
      url_list: "",

      //加载进度条显示隐藏
      progressBarShow: false,

      totalSize: 0,

      //对话框
      dialogFormVisible: true,

      name_list: [],
      //修改的文件id
      fileId: "",
      //进度条文字提示
      progressText: "正在上传",

      fileName: "",
      //点击多次只触发一次
      state: false,

      title: "上传模型",

      loadingProgressBar: 0,
      //模型的文件夹id
      modelId:"",
      //路径
      modelUrl:[],
          //项目名称
      projectName: "",
    };
  },
  props: [
    //拿到文件url现在没用到
    "fileUrl",
    //拿到上传的文件
    "files",
    //模型还是材质现在没用到
    "ext",
    "upDataId",
    //更改还是上传 调用不同的接口
    "upgrading",
    //父文件id
    "FileDirectoryId",
    // 传过来的右侧树的类型
    "ResourceType",

    //传过来的更新node
    "upDataNode",
    //创建组
    "Node",
    //是否是更新
    "revampResource"
  ],
  components: {},
  created() {},
  mounted() {
    var option = {};

    option.container = document.getElementById("preview-container");

    if (MainObjects.MainRender.m_previewResouse == null) {
      MainObjects.MainRender.m_previewResouse = new PreviewResouse(option);

    } else {
      MainObjects.MainRender.m_previewResouse.addRenderDom(option);


    }
    
    this.uploading();
    
    this.projectName = localStorage.getItem("projectName");

     var  modelUrl=    localStorage.getItem("modelUrl")
    this.modelUrl= JSON.parse( modelUrl );
  

  },
  methods: {
    //确认资源
    affirm() {
      // this.debounce(this.onOk(),1000)

      if (this.state == false) {

        let that = this;
        that.state = true;

      if (this.revampResource==true) {
        this.fileId=this.$store.state.selectNode.id
      
        this.updateFile()
        ISetting.isInput(false);
        
      }else{
        this.onOk();

      }


        setTimeout(() => {
          //请求数据。。。
          //请求成功后在返回的状态里面加上下面这句话
          that.state = false;
        }, 3000);
      }
    },
    
    //点击确认执行
    onOk() {
      // 节流
      ISetting.isInput(false);


      // if (this.upgrading == 2) {
 

      //   //更改
      //   var fileId;
      //   var updateFileId = localStorage.getItem("updateFileId");
      //   if (
      //     updateFileId !== undefined &&
      //     updateFileId !== null &&
      //     updateFileId !== ""
      //   ) {
      //     fileId = updateFileId;
      //   } else {
      //     fileId = this.fileId;
      //   }

      //   INetwork.copyFilesToSql(this.files, fileId, this.ext, () => {
      //     this.updateFile()

      //       .then(() => {
      
      //         this.$emit("setFileDirectoryId", this.FileDirectoryId);
      //       })
      //       .catch(() => {
      //         this.$emit("setFileDirectoryId", this.FileDirectoryId);
      //       });
      //   });

      //   // this.$emit("setFileDirectoryId", this.FileDirectoryId);

      //   // this.dialogFormVisible = false;
      //   // this.$emit("transfer", false);
      // } else if (this.upgrading == 1) {
      //   if (this.fileName == "") {
      //     this.$message({
      //       message: "文件名不为空"
      //       // type: "warning"
      //     });
      //   } else {
          this.addFile();
        // }

      // }
    },



    //上传
    uploading() {
   
      // INetwork.uploadFilesToPreview(
      //   this.files,
      //   this.process.bind(this),
      //   this.finish.bind(this)
      // );
  

        IResousePreview.loadGroup(this.Node.object,()=>{

          })
          var modelId= localStorage.getItem("modelId")
          if (modelId) {
            this.modelId=modelId
          }          
    },

    formatData(percentage) {


      return `${percentage}%`;
    },

    process(p) {

      this.progressBar = parseInt(p * 100);
      this.progressText = "正在上传";
    },

    processLoad(p) {


      // if (this.progressBar==100) {
      //   this.progressBar=0
      // }
      this.loadingProgressBar = parseInt(p * 100);
      this.progressText = "正在加载";
      //加载进度
      //屏蔽快捷键
      ISetting.isInput(true);
    },

    finish(data) {


      var url_list = new Array();
      var totalSize = 0;
      var name_list = new Array();
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        url_list.push(element.url);
        name_list.push(element.name);
        totalSize = totalSize + element.size;
      }
      this.name_list = name_list;

      this.totalSize = totalSize;

      // this.progressBar = 0;
      this.progressBar = 100;

      //显示加载进度条 拿到大小 totalSize url  mainName 附属url  name_list
      
      IResousePreview.loadFile(
        url_list,
        this.ext,
        this.processLoad.bind(this),
        this.finishLoad.bind(this)
      );
    },
    finishLoad() {
      this.loadingProgressBar = 100;
      this.dialogFormVisible = true;


    },

    //取消 点击弹框的 X
    cancel() {
      var fileId = this.fileId;

      // if (fileId != "") {
      //   //删除
      //   this.$parent.ondelFile(1, this.fileId, this.FileDirectoryId);
      // }

      ISetting.isInput(false);

      //更新父组件中的值 让弹框隐藏
      this.dialogFormVisible = false;

      this.$emit("transfer", false);
   
      IResousePreview.close();
      
      // INetwork.deleteFiles(this.name_list);
      this.fileName = "";
    },

    //加载
    openFullScreen() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      setTimeout(() => {
        loading.close();
      }, 2000);
    },

    //调用后台接口 添加文件
    async addFile() {
      // let fileName = this.mainUrl.split("/");
      // let name = fileName[fileName.length - 1];
      // name = name.split("?")[0];


      const res = await this.$https.addFile({
        //    this.url_list=url_list
        // this.mainUrl=mainUrl
        //文件夹id
        // folderId:this.id,
        projectId: localStorage.getItem("projectId"),
        // folderId: this.modelId ,
        folderId: this.$store.state.selectFolderId ,

        // name: this.Node.name,
        name: this.fileName,
        type: 6,
        fileUrl: "",
        fileSize: 0,
        imageUrl: "",
        deputyUrl: ""
      });
      if (res.success == true) {
        // 上传成功
        this.fileId = res.data;
        INetwork.getFileList(this.fileId, () => {
          this.updateFile().then(() => {
            // this.$emit("setFileDirectoryId", this.FileDirectoryId);
          });
        });
        // .catch(()=>{
        //     this.$emit("setFileDirectoryId", this.FileDirectoryId);
        // })

        //获取文件
        // this.$parent.getSubCatalog(this.$store.state.selectFolderId);
        this.dialogFormVisible = false;
        this.$emit("transfer", false);
      } else {
        var token = localStorage.getItem("token");
        if (token) {
          this.$message({
            message: res.errMessage
            // type: "warning"
          });
        }
      }
    },
    // setFileId(msg) {

    //   this.fileId = msg;
    // },

    //调用后台接口 更改文件
    async updateFile() {
      var fileId;
      // var updateFileId = localStorage.getItem("updateFileId");
      // if (
      //   updateFileId !== undefined &&
      //   updateFileId !== null &&
      //   updateFileId !== ""
      // ) {
      //   fileId = updateFileId;  
      // } else {
        fileId = this.fileId;
      // }
      // var name;
      // if (this.upgrading == 1) {
      //   name = this.fileName;
      // } else if (this.upgrading == 2) {
      //   name = this.upDataNode.name;
      // }
      //获取真实的数据 这里需要传文件类型

      var data = INetwork.getGroupJsonToSql(fileId,()=>{});

      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });

      var time = new Date().getTime();

      const res = await this.$https.updateFile({
        //文件夹id
        // folderId:this.id,
        projectId: localStorage.getItem("projectId"),
        id: fileId,
        // name: name,
        fileUrl: data.mainName,
        imageUrl: data.imageName + "?" + "time=" + time,
        deputyUrl: data.otherNameList.toString(),
        fileSize: this.totalSize
      });

      if (res.errCode == null) {
        IResousePreview.close();
        loading.close();
        this.fileName = "";
        console.log("加载", this.FileDirectoryId);
        this.$parent.setFileDirectoryId(this.FileDirectoryId);

        localStorage.removeItem("updateFileId");

        this.dialogFormVisible = false;

        this.$emit("transfer", false);

         this.$parent.getSubCatalog(this.$store.state.selectFolderId);
      } else {
        loading.close();
      }
    },



    //防抖
    debounce(fn, delay) {
      var delay = delay || 200;
      var timer;
      return function() {
        var th = this;
        var args = arguments;
        if (timer) {
          clearTimeout(timer);
        }
        timer = setTimeout(function() {
          timer = null;
          fn.apply(th, args);
        }, delay);
      };
    }

  }
};
</script>
<style scoped>
.box {
  width: 100%;
  height: 100%;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
/* 上传 */
.preview {
  width: 650px;
  height: 650px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  box-shadow: 0px 0px 4px 0px rgba(33, 35, 49, 0.1);
  border-radius: 10px;
  z-index: 999999999;
}
.fileName {
  position: relative;
  top: 20px;
  left: 70px;
}
/* 3D预览 */
.examine {
  width: 400px;
  height: 400px;
  border-radius: 10px;
  margin: 0 auto;
  margin-top: -70px;
  margin-bottom: 10px;
  box-shadow: 0px 0px 4px 0px rgba(33, 35, 49, 0.1);
}
.affirm {
  width: 100%;
  position: absolute;
}
.affirmOk {
  position: relative;
  width: 100px;
  height: 40px;
  margin: 0 auto;
  left: 50%;
  transform: translate(-50%, -50%);
}
.upload-demo {
  width: 100px;
  height: 50px;
  margin: 0 auto;
}
.cancel {
  position: absolute;
  right: 20px;
  top: 10px;
  /* width: 50px;
  height: 50px; */
  /* background-color: red ; */
  /* text-align: center;
  line-height: 50px */
}
/* 蒙版 */
.masking {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999999999;
}
/* 上传进度 */
.uploading_process {
  width: 1039px;
  height: 169px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  box-shadow: 0px 0px 17px 1px rgba(33, 35, 49, 0.1);
  border-radius: 20px;
  z-index: 9999999999999;
}
.progressBar {
  width: 971px;
  /* height: 57px; */
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 20px;
}
.progressBar /deep/.el-progress__text {
  position: absolute;
  top: -130%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 58px;
  height: 15px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 30px;
}
.progressBar /deep/.el-progress-bar {
  padding-right: 0px;
  width: 100%;
  margin-right: 0px;
  box-sizing: border-box;
}

/* /deep/.el-dialog__body{
  width: 0;
  height: 0;
  padding: 0;
  margin: 0;
} */
.uploading_text {
  width: 146px;
  height: 23px;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
  margin: 0 auto;
}




.updateName {
  text-align: center;
}

.title {
  width: 300px;
  height: 45px;
  position: absolute;
  top: 0px;
  font-size: 14px;
  color: #000;
  margin-left: -6px;
  margin-top: 8px;
}

.icon {
  width: 12px;
  height: 12px;
  /* margin: 2px 2px; */
  margin-right: 6px;
  margin-top: 14px;
}
.title_p {
  height: 12px;

  display: inline-block;
  height: 17px;
  line-height: 13px;
  text-align: center;
}

/deep/.el-dialog__footer .el-button:first-child {
  width: 180px !important;
  height: 40px !important;
  transform: translateX(210px);
  border-radius: 8px;
  border: none;
  box-shadow: 0px 0px 4px 0px rgba(33, 35, 49, 0.1);
}
/deep/.el-dialog__footer .el-button:last-child {
  width: 180px !important;
  height: 40px !important;
  transform: translateX(-210px);
  background-color: #0560fd;
  border-radius: 8px;
  border: none;
  box-shadow: 0px 0px 4px 0px rgba(33, 35, 49, 0.2);
}


/* 修改文件名字 */

.changeFileName {
  width: 300px;
  /* margin-top: -100px; */
  margin: 0 auto;
  margin-bottom: 40px;
}
/deep/.el-dialog__body .el-input__inner {
  background: #fff;
  border: none;
  box-shadow: 0px 0px 4px 0px rgba(33, 35, 49, 0.2);
  border-radius: 8px;
  font-size: 12px !important;
  width: 400px;
  margin-left: -50px;
}

/deep/.el-dialog__body input::-webkit-input-placeholder{
  color: rgba(80, 80, 80, 0.8);
}

/deep/.el-dialog {
  border-radius: 10px;
}
.dialog-footer {
  margin-top: 70px;
  margin-bottom: -5px;
}
.catalogue{
    display: flex;
  width: 90%;
  height: 60px;
  align-items: center;
  padding-left: 30px;
  font-weight: bold;
  position: absolute;
  overflow:hidden;
  bottom: 60px;
  left: 30px;
}

/* 路径 */
.address {
  padding-left: 10px;
  /* width: 925px; */
  width: 53%;
  height: 38px;
  background: #f7f7f7;
  border-radius: 10px;
  text-align: left;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #929292;
  line-height: 38px;
  overflow: hidden;
  margin-left: 150px;
  /* overflow-x: auto; */
}
.path {
  box-sizing: border-box;
  /* width: 120px; */
  height: 40px;
  /* background-color: red; */
  /* float: left; */
  display: inline-block;
  padding: 7px 5px;
}
.path .pathIcon {
  margin-left: 2px;
  width: 16px;
  height: 16px;
  float: left;
}

.path .pathTitle {
  /* width: 100px; */
  float: left;
  line-height: 23px;
  font-size: 14px;
  margin-left: 6px;
  margin-right: 10px;
  color: #636363;
}

.projectName {
  float: left;
  line-height: 1.4375rem;
  font-size: 0.875rem;
  margin-left: 0.375rem;
  margin-right: 0.625rem;

  color: #000;
}

.endpathTitle {
  float: left;
  line-height: 23px;
  font-size: 14px;
  margin-left: 10px;
  color: #0560fd;
}

.point {
  float: left;
}
.iconImg {
  float: left;
  margin-top: 4px;

  width: 16px;
  height: 16px;
}
.Path_file {
  width: 15px;
  height: 15px;
}

.path_point {
  margin-top: 7px;

  width: 10px;
  height: 10px;
}





</style>