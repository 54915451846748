import { MainObjects } from '../common/MainObjects';
import { GameObject } from '../object/GameObject';
import * as T from '../object/Terrain';

class TerrainData {
    constructor() {
        this.m_easing = T.THREE.Terrain.Linear;
        this.m_frequency = 100;
        this.m_maxHeight = 5;
        this.m_minHeight = -10;
        this.m_steps = 1;
        this.m_xSegments = 100;
        this.m_xSize = 512;
        this.m_ySegments = 100;
        this.m_ySize = 512;

        this.m_heightmap_id = null
        this.m_heightmap_name = null
        this.m_heightmap_image = null
        this.m_texture_list = [];

        this.m_grassmap_id = null
        this.m_grassmap_name = null
        this.m_grassmap_image = null

        this.m_grass_num = 1000;
        this.m_grass_height = 0.5;
    }

    getData()
    {
        var json = {
            textures: this.m_texture_list,
            xS : this.m_xSegments,
            yS: this.m_ySegments,
            easing: this.m_easing,
            frequency: this.m_frequency,
            heightmap: this.m_heightmap_image,
            maxHeight: this.m_maxHeight,
            minHeight: this.m_minHeight,
            steps: this.m_steps,
            xSize: this.m_xSize,
            ySize: this.m_ySize,
            grassmap_image: this.m_grassmap_image,
            grass_num: this.m_grass_num,
            grass_height: this.m_grass_height
        }
        return json;
    }

    getJson()
    {
        var json = {
            m_easing : this.m_easing,
            m_frequency: this.m_frequency,
            m_maxHeight: this.m_maxHeight,
            m_minHeight: this.m_minHeight,
            m_steps: this.m_steps,
            m_xSegments: this.m_xSegments,
            m_xSize: this.m_xSize,
            m_ySegments: this.m_ySegments,
            m_ySize: this.m_ySize,

            m_heightmap_id: this.m_heightmap_id,
            m_heightmap_name: this.m_heightmap_name,

            m_grassmap_id: this.m_grassmap_id,
            m_grassmap_name: this.m_grassmap_name,

            m_grass_num : this.m_grass_num,
            m_grass_height : this.m_grass_height,

            m_texture_list:[]
        }
        for (let index = 0; index < this.m_texture_list.length; index++) {
            const element = this.m_texture_list[index];

            if(index == 0)
            {
                var texture = {};
                texture.texture = MainObjects.Blueprint.m_materialEditorBp.getTextureJson(element["texture"]);
                json.m_texture_list.push(texture);
            }
            else
            {
                if(element["levels"])
                {
                    var texture = {};
                    texture.texture = MainObjects.Blueprint.m_materialEditorBp.getTextureJson(element["texture"]);
                    texture.levels = element["levels"];
                    json.m_texture_list.push(texture);
                }
            }
        }
        return json;
    }
    
    setJson(json)
    {
   
    }

    getJsonForUI()
    {
        var json = {
            type:GameObject.GameObjectType.e_TERRAIN_SYS,
            m_easing : this.m_easing,
            m_frequency: this.m_frequency,
            m_maxHeight: this.m_maxHeight,
            m_minHeight: this.m_minHeight,
            m_steps: this.m_steps,
            m_xSegments: this.m_xSegments,
            m_xSize: this.m_xSize,
            m_ySegments: this.m_ySegments,
            m_ySize: this.m_ySize,
    
            m_heightmap_src: this.m_heightmap_image,
            m_texture_list: [],
            m_base_texture_image: null,

            m_grassmap_src: this.m_grassmap_image,
            m_grass_num : this.m_grass_num,
            m_grass_height : this.m_grass_height,
        }
        for (let index = 0; index < this.m_texture_list.length; index++) {
            const element = this.m_texture_list[index];

            if(index == 0)
            {
                json.m_base_texture_image = element["texture"].image
            }
            else
            {
                if(element["levels"])
                {
                    var texture = {};
                    texture.texture = MainObjects.Blueprint.m_materialEditorBp.getTextureJson(element["texture"]);
                    texture.levels = element["levels"];
                    json.m_texture_list.push(texture);
                }
            }
        }
        return json;
    }
}

export { TerrainData };
