
import Vue from 'vue'
import Router from 'vue-router'

// import rolesTest from './roles'

const layOut = () => import('../layout/layout') // layout
const AppMain = () => import('../views/manager/Project.vue') // layout



//获取动态路由方法
function getRouterList(successCallback) {
    var rolesTest = JSON.parse(localStorage.getItem('router_roles'))
   
    // Vue.prototype.$https.GetAuthority({
    //   operationId: localStorage.getItem('ID')
    //   // operationId: 'f48c69de-148d-4545-8877-09c01231'
    // }).then((res) => {

    //   if (res.success == true) {
    //  
    //    }

    let router = [
        // 用户 模块
        {
            path: '/index/user',
            component: AppMain,
            redirect: '/index/user/User',
            name: 'AppMain',
            meta: {
                title: '用户',
                hidden: false,
                key: 'ROLE_MANAGE_USER_PAGE',
                requireAuth: true,
                roles: []
            },
            children: [{
                path: '/index/user/User',
                component: AppMain,
                name: 'AppMain',
                meta: {

                    labelName: '用户管理',
                    title: '用户列表',
                    hidden: false, // 是否隐藏
                    requireAuth: true,
                    icon: 'el-icon-setting',
                }
            },
            ]
        },
    ]



    router.forEach((item, index) => {
        for (var i = 0; i < rolesTest.length; i++) {
      
            // var key = Object.keys(Object.values(rolesTest.authority[i])[0])[0];
        
            if (item.meta.key === rolesTest[i].authorityName) {
                if (rolesTest[i].authorityDesc != undefined) {
                    item.meta.roles = rolesTest[i].authorityDesc.split(',')
                    item.meta.hidden = false
                } else {
                    item.meta.hidden = true
                }
            }
        }
    })

    successCallback([
        {
            path: '/',
            name: 'layOut',
            redirect: {
                name: 'login'
            },
            meta: {
                title: '主页'
            },
            component: layOut,
            children: router
        },
        {
            path: '*',
            redirect: '/404',
            component: layOut,
            meta: {
                title: '页面找不到',
                hidden: false, // 是否隐藏
                requireAuth: false, // 是否需要登录（检验token）
                roles: []
            }
        },
    ]) 

    //  })

}

export default {
    getRouterList
}
