import * as THREE from 'three';
import { Language } from '../common/Language';
import { MainObjects } from '../common/MainObjects';
import { GameObject } from '../object/GameObject';
import { GameScene } from '../object/GameScene';
import {createBulletMuzzle} from "@/threejs/factory/ParticleExample/BulletMuzzle";
import IParticleExample from "@/threejs/interface/IParticleExample";
import {createLevelUp} from "@/threejs/factory/ParticleExample/Levelup";
import {createExplosion} from "@/threejs/factory/ParticleExample/Explosion";
import {createShipTrail} from "@/threejs/factory/ParticleExample/ShipTrail";
import {createShipSmoke} from "@/threejs/factory/ParticleExample/ShipSmoke";
import {createBigExplosion} from "@/threejs/factory/ParticleExample/BigExplosion";
import {QuarksLoader} from "three.quarks";
import {ParticleSystemPreviewObject} from "@/threejs/helper/ParticleSystemPreviewObject";

class ParticleFactory {

    static createParticleSystemByJson(json,callback) {
        const loader = new QuarksLoader()
        if(!json) return
        let object = null
        try {
            object = loader.parse(json)
        }catch (e) {
            throw new Error('old particle???')
        }
        const mainParticleSys = new THREE.Object3D();
        mainParticleSys.name = mainParticleSys.uuid;
        mainParticleSys.userData = new GameObject();
        mainParticleSys.userData.m_gameObjectType = GameObject.GameObjectType.e_PARTICLE_SYS;
        mainParticleSys.userData.m_name = Language.LOCAL_TEXT("Particle System", "粒子");
        const improveCode = (item) => {
            ParticleFactory.processParticleSystems(item)
            if (item.type === "ParticleEmitter") {
                MainObjects.MainRender.m_batchRender.addSystem(item.system);
                item.userData = new GameObject()
                item.userData.m_gameObjectType = GameObject.GameObjectType.e_PARTICLE_SYS_EMITTER
                item.userData.m_name = item.name
                mainParticleSys.add(item)
            }
        }
        const list = [];
        object.children.forEach(item => {
            item.traverse(child => {
                list.push(child)
            })
            list.push(item)
        })
        list.forEach(item => {
            improveCode(item)
        })
        const gameScene = MainObjects.Blueprint.m_sceneManagerBp.m_gameScene;
        const geometry = gameScene.m_geoRepos.get(GameScene.GeoKey.e_CUBE_0_1);
        const material = gameScene.m_matRepos.get(GameScene.MatKey.e_BASE_MAT);

        const cube = new THREE.Mesh(geometry, material);
        cube.visible = true;
        cube.name = "particle_sprite";
        cube.userData = new GameObject();
        cube.userData.m_gameObjectType = GameObject.GameObjectType.e_SELECT_HELPER_BOX;

        mainParticleSys.add(cube)
        callback(mainParticleSys)
    }
    static processParticleSystems = (object3d) => {
        object3d.traverse(obj => {
            if (obj.type === "ParticleEmitter") {
                const particleSystem = obj.system
                const mesh = new ParticleSystemPreviewObject(particleSystem);
                obj.add(mesh);
            }
        });
    }
    static getParticleSystemExampleJson(index) {
        let emitterGroup
        switch (parseInt(index)) {
            case IParticleExample.TypeExample.BulletMuzzle:
                emitterGroup = createBulletMuzzle(MainObjects.DefaultTexture.particleText1)
                break
            case IParticleExample.TypeExample.Levelup:
                emitterGroup = createLevelUp(MainObjects.DefaultTexture.particleText1)
                break
            case IParticleExample.TypeExample.Explosion:
                emitterGroup = createExplosion(MainObjects.DefaultTexture.particleText1)
                break
            case IParticleExample.TypeExample.ShipSmoke:
                emitterGroup = createShipSmoke(MainObjects.DefaultTexture.particleText1)
                break
            case IParticleExample.TypeExample.BigExplosion:
                emitterGroup = createBigExplosion(MainObjects.DefaultTexture.particleText1)
                break
            case IParticleExample.TypeExample.ShipTrail:
                emitterGroup = createShipTrail(MainObjects.DefaultTexture.particleText1)
                break
        }
        emitterGroup.userData.type = 'quarks-Group'
        return emitterGroup.toJSON()

    }

    static destory(object)
    {
        if(object.userData.m_particleSystem)
        {
            object.userData.m_particleSystem.destroy();
        }
    }

    static update(object)
    {
        if(object.userData.m_particleSystem)
        {
            object.userData.m_particleSystem.update();
            object.updateMatrixWorld(true, true);
        }
    }
}

export { ParticleFactory };