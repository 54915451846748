var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[(_vm.upgrading!=1)?_c('div',{staticClass:"title redactTitle"},[_c('img',{attrs:{"src":require("../../assets//Project/file.png"),"alt":""}}),_c('div',{staticClass:"changeFileName"},[_c('div',{staticClass:"updateName"},[_vm._v(_vm._s(_vm.upDataNode.name))])])]):_vm._e(),(_vm.upgrading==1)?_c('div',{staticClass:"title"},[_c('img',{attrs:{"src":require("../../assets//Project/file.png"),"alt":""}}),_c('div',{staticClass:"createChangeFileName changeFileName"},[(_vm.upgrading==1)?_c('el-input',{attrs:{"placeholder":"在此编辑文件名称"},on:{"focus":_vm.Focus,"blur":_vm.Blur},model:{value:(_vm.fileName),callback:function ($$v) {_vm.fileName=$$v},expression:"fileName"}}):_vm._e()],1)]):_vm._e(),_c('div',{staticClass:"topAddress"},[_c('div',{staticClass:"catalogue"},[_c('div',{class:{address:true,
          pitchAddress: _vm.upgrading==1
          }},[_c('div',{staticClass:"path"},[_vm._m(0),_c('div',{staticClass:"projectName"},[_vm._v(_vm._s(_vm.projectName))]),_vm._m(1)]),(_vm.$store.state.creatingMaterialTypes==1||_vm.$store.state.creatingMaterialTypes==2)?_c('div',{staticClass:"path"},[_c('div',{staticClass:"pathIcon"}),_c('img',{staticClass:"iconImg",attrs:{"src":require("../../assets/Resource/icon_model.png"),"alt":""}}),_c('div',{staticClass:"projectName"},[_vm._v("模型")]),(_vm.$store.state.creatingMaterialTypes==3)?_c('div',{staticClass:"point"},[_c('img',{staticClass:"iconImg path_point",attrs:{"src":require("../../assets/Resource/path_point.png"),"alt":""}})]):_vm._e()]):_vm._e(),_vm._m(2),_vm._l((_vm.textureUrl),function(item,index){return _c('div',{key:index,staticClass:"path"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(index != _vm.textureUrl.length - 1),expression:"index != textureUrl.length - 1"}],staticClass:"pathIcon"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(index != _vm.textureUrl.length - 1),expression:"index != textureUrl.length - 1"}],staticClass:"iconImg",attrs:{"src":require("../../assets/Resource/path_file_list.png"),"alt":""}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(index == _vm.textureUrl.length - 1),expression:"index == textureUrl.length - 1"}],staticClass:"pathIcon"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(index == _vm.textureUrl.length - 1),expression:"index == textureUrl.length - 1"}],staticClass:"iconImg Path_file",attrs:{"src":require("../../assets/Resource/Path_file_list_checked.png"),"alt":""}})]),_c('div',{class:{
                  pathTitle: index != _vm.textureUrl.length - 1,
                  endpathTitle: index == _vm.textureUrl.length - 1,
                }},[_vm._v(_vm._s(item))]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(index != _vm.textureUrl.length - 1),expression:"index != textureUrl.length - 1"}],staticClass:"point"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(index != _vm.textureUrl.length - 1),expression:"index != textureUrl.length - 1"}],staticClass:"iconImg path_point",attrs:{"src":require("../../assets/Resource/path_point.png"),"alt":""}})])])})],2)])]),_c('div',{staticClass:"header_box"},[_c('div',{staticClass:"preview",attrs:{"id":"preview-container"}}),_c('div',{staticClass:"button",on:{"click":_vm.affirm}},[_vm._v("保存")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pathIcon"},[_c('img',{staticClass:"iconImg",attrs:{"src":require("../../assets/Resource/project_icon.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"point"},[_c('img',{staticClass:"iconImg path_point",attrs:{"src":require("../../assets/Resource/path_point.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"path"},[_c('div',{staticClass:"pathIcon"},[_c('img',{staticClass:"iconImg",attrs:{"src":require("../../assets/Resource/icon_chartlet.png"),"alt":""}})]),_c('div',{staticClass:"projectName"},[_vm._v("贴图")]),_c('div',{staticClass:"point"},[_c('img',{staticClass:"iconImg path_point",attrs:{"src":require("../../assets/Resource/path_point.png"),"alt":""}})])])
}]

export { render, staticRenderFns }