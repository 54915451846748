<template>
    <div>
        <div class="addition">
            <div class="tab">
                <div class="list" @click.stop="tabChange(index)" v-for="(item,index) in tabMain" :key="index" :class="{active:cur==index}">
                    <img :src="item.src" alt="" :id=" 'img' + index">
                    <div class="text">{{item.label}} </div>
                </div>
            </div>
            <div class="tabContent" v-for="(item,index) in tabMain" :key="index" v-show="cur==index">
                <div class="tabList" :id="'tabList' + index " v-for="(Iatem,index) in item.children" :key="index" @click="createChange(index)" :class="{isActive:checked == index}">
                    <img :src="Iatem.src" alt="" :id="'Iimg' + index">
                    <div class="text" :id="'text' + index ">{{Iatem.label}}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Basics from "../../assets/ToolBar/basics.png";
import Lights from "../../assets/ToolBar/lights.png";
import Slr from "../../assets/ToolBar/slr.png";
import CheckedBasics from "../../assets/ToolBar/checkedBasics.png";
import CheckedLights from "../../assets/ToolBar/checkedLights.png";
import CheckedSlr from "../../assets/ToolBar/checkedSlr.png";

import diamond from "../../assets/ToolBar/diamond.png";
import ball from "../../assets/ToolBar/ball.png";
import cylinder from "../../assets/ToolBar/cylinder.png";
import cone from "../../assets/ToolBar/cone.png";
import plane from "../../assets/ToolBar/plane.png";
import annulus from "../../assets/ToolBar/annulus.png"
import dot from "../../assets/ToolBar/dot.png";
import spotlight from "../../assets/ToolBar/spotlight.png";
import parallel from "../../assets/ToolBar/parallel.png";
import AreaLight from "../../assets/ToolBar/AreaLight.png";
import Panorama from "../../assets/ToolBar/panorama.png";
import Projection from "../../assets/ToolBar/projection.png";
import PanProjection from "../../assets/ToolBar/PanProjection.png";

import perspectivity from "../../assets/ToolBar/perspectivity.png";
import quadrature from "../../assets/ToolBar/quadrature.png";

import group from "../../assets/ToolBar/group.png";
import checkedGroup from "../../assets/ToolBar/checkedGroup.png";
import curve from "../../assets/ToolBar/curve.png";
import lod from "../../assets/ToolBar/lod.png";
import ring from "../../assets/ToolBar/ring.png";
import sky from "../../assets/ToolBar/sky.png";
import context from "../../assets/ToolBar/context.png";
import checkedCon from "../../assets/ToolBar/checkedCon.png";
import halo from "../../assets/ToolBar/halo.png";
import collision from "../../assets/Property/Collision.png";
import checkedCollision from "../../assets/Property/checkedCollision.png" 
import realTime from "../../assets/ToolBar/realTime.png";
import terrain from "../../assets/ToolBar/terrain.png";
import water from "../../assets/ToolBar/water.png";
import { IFactory } from "../../threejs/interface/IFactory";

export default {
    data(){
        return{
            cur:0,
            checked:0,
            tabMain:[
            //    {
            //        id:0,
            //        label:"基础形状",
            //        src:CheckedBasics,
            //        children:[
            //            {
            //                id:0,
            //                label:"方块",
            //                src:diamond,
            //            },
            //            {
            //                id:1,
            //                label:"球",
            //                src:ball,
            //            },
            //            {
            //                id:2,
            //                label:"圆柱",
            //                src:cylinder,
            //            },
            //            {
            //                id:3,
            //                label:"圆锥",
            //                src:cone,
            //            },
            //            {
            //                id:4,
            //                label:"平面",
            //                src:plane
            //            },
            //            {
            //                id:5,
            //                label:"圆环",
            //                src:annulus
            //            },
            //            {
            //                id:6,
            //                label:"圆环面",
            //                src:ring,
            //            }
            //        ]
            //    },
               {
                   id:0,
                   label:"灯光",
                   src:Lights,
                   children:[
                       {
                           id:0,
                           label:"平行光",
                           src:parallel
                       },
                       {
                           id:1,
                           label:"点光",
                           src:dot
                       },
                       {
                           id:2,
                           label:"聚光",
                           src:spotlight
                       },
                       {
                           id:3,
                           label:"区域光",
                           src:AreaLight
                       },
                       
                   ]
               },
               {
                    id:1,
                    label:"相机",
                    src:Slr,
                    children:[
                       {
                           id:0,
                           label:"透视",
                           src:perspectivity
                       },
                       {
                           id:1,
                           label:"正交",
                           src:quadrature
                       },
                       {
                           id:2,
                           label:"全景",
                           src:Panorama
                       },
                       {
                           id:3,
                           label:"投影",
                           src:Projection
                       },
                       {
                           id:4,
                           label:"全景投影",
                           src:PanProjection
                       }
                       
                   ]
               },
               {
                    id:2,
                    label:"创建组",
                    src:group,
                    children:[
                       {
                           id:0,
                           label:"创建组",
                           src:group
                       },
                       {
                           id:1,
                           label:"曲线组",
                           src:curve
                       },
                       {
                           id:2,
                           label:"LOD组",
                           src:lod
                       }
                   ]
               },
               {
                id:3,
                label:"环境",
                src:context,
                children:[
                       {
                           id:0,
                           label:"纯色天空",
                           src:sky
                       },
                       {
                           id:1,
                           label:"实时天空",
                           src:realTime
                       },
                       {
                           id:2,
                           label:"光晕",
                           src:halo
                       },
                       {
                        id:3,
                        label:"创建地形",
                        src:terrain
                       },
                       {
                        id:4,
                        label:"创建水",
                        src:water,
                       }
                   ]
               },
            //    {
            //     id:4,
            //     label:"碰撞",
            //     src:collision,
            //     children:[
            //            {
            //                id:0,
            //                label:"盒体碰撞",
            //                src:collision
            //            },
            //        ]
            //    }
            ]
        }
    },
    methods:{
        tabChange(index){
            this.cur = index;
            this.checked = 0;
            if(this.cur == 0){
                this.tabMain[0].src = CheckedLights;
                this.tabMain[1].src = Slr;
                this.tabMain[2].src = group;
                this.tabMain[3].src = context;
                // this.tabMain[4].src = collision;
            }else if(this.cur == 1){
                this.tabMain[0].src = Lights;
                this.tabMain[1].src = CheckedSlr;
                this.tabMain[2].src = group;
                this.tabMain[3].src = context;
                // this.tabMain[4].src = collision;
            }else if(this.cur == 2){
                this.tabMain[0].src = Lights;
                this.tabMain[1].src = Slr;
                this.tabMain[2].src = checkedGroup;
                this.tabMain[3].src = context;
                // this.tabMain[4].src = collision;
            }else if(this.cur == 3){
                this.tabMain[0].src = Lights;
                this.tabMain[1].src = Slr;
                this.tabMain[2].src = group;
                this.tabMain[3].src = checkedCon;
                // this.tabMain[4].src = collision;
            }
        },
        createChange(index){
            this.checked = index;
            this.$parent.addChange();
    
            if(index==0 && this.cur==0){
                IFactory.createDirLight()
            }else if(index==1 && this.cur==0){
                IFactory.createPointLight();
            }else if(index == 2 && this.cur==0){
                IFactory.createSpotLight();
            }else if(index==3 && this.cur==0){
                IFactory.createRectAreaLight();
            }

            if(index==0 && this.cur==1){
                IFactory.creatPerspectiveCamera()
            }else if(index==1 && this.cur==1){
                IFactory.creatOrthographicCamera();
            }else if(index==2 && this.cur==1){
                IFactory.createEquirectangularCamera();
            }else if(index==3 && this.cur==1){
                IFactory.createProjectCamera();
            }else if(index==4 && this.cur==1){
                IFactory.createProjectEquirectangularCamera();
            }

            if(index == 0 && this.cur == 2){
                IFactory.createGroup('组')
            }else if(index == 1 && this.cur == 2){
                IFactory.createCurve();
            }else if(index == 2 && this.cur == 2){
                IFactory.createLod();
            }

            if(index == 0 && this.cur == 3){
                IFactory.createSky()
            }else if(index == 1 && this.cur == 3){
                IFactory.createRealTimeSky();
            }else if(index == 2 && this.cur == 3){
                IFactory.createLensFlare();
            }else if(index == 3 && this.cur == 3){
                IFactory.createTerrain();
            }else if(index == 4 && this.cur == 3){
                IFactory.createWaterBase();
            }
        }
    },
}
</script>
<style scoped>
.addition{
  width: 270px;
  height: 190px;
  border-radius:10px ;
  box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
  /* position: absolute;
  right: 10%;
  top: 90px; */
  display: flex;
}
.isActive{
    background: #fff;
}
.tab{
    height: 100%;
    background: #fff;
    border-bottom-left-radius:10px;
}
.list{
    width: 130px;
    height: 40px;
    align-items: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
    margin-top: 5px;
    font-size: 12px;
    font-weight: bold;
}
.list:hover{
    background: #cddfff;
}
.active{
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    color: royalblue;
}
.list #img0{
    width: 15px;
    height: 15px;
}
.list #img1{
    width: 16px;
    height: 16px;
}
.list #img2{
    width: 15px;
    height: 15px;
}
.list #img3{
    width: 15px;
    height: 15px;
}
.list #img4{
    width: 15px;
    height: 15px;
}
.list .text{
    width: 80px;
    text-align: center;
}
.tabContent{
    background: #fff;
    /* margin-top: 15px; */
    height: 190px;
    width: 140px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0px 0px 3px 0px rgba(16, 55, 39, 0.16);
}
.tabList{
    width: 140px;
    height: 32px;
    align-items: center;
    display: flex;
    justify-content: space-evenly;
    cursor: pointer;
    font-size: 12px;
    font-weight: bolder;
}
.tabList:hover{
    background: #cddfff;
}
.tabList img{
    width: 15px;
    height: 15px;
}
.tabList #Iimg1{
    width: 15px;
    height: 15px;
}
.tabList #Iimg2{
    width: 15px;
    height: 15px;
}
.tabList #Iimg3{
    width: 15px;
    height: 15px;
}
.tabList #Iimg4{
    width: 15px;
    height: 15px;
}
.tabList .text{
    width: 60px;
    text-align: center;
}
.tabList #text4{
    /* margin-left: 10px; */
}
#tabList0{
   border-top-right-radius: 10px;
}
</style>