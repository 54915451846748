<template>
    <div>
        <!--  -->
        <div class="box" :class="isActive ? 'anmDiv' : 'aaa'" v-if="scriptType == 1">
            <div class="header">
                <div @click="shrinkChange"><i class="el-icon-arrow-down"></i> </div>
                <p>纹理控制器</p>
                <!-- <div class="del" @click="reduce()"><i class="el-icon-circle-close"></i> </div> -->
            </div>
            <div>


                <div class="projection">
                    <div class="title">纹理控制器名称：</div>
                    <div class="txt_box">
                        <input class="input" type="text" v-model="fileName" @focus="Focus" @blur="Blur" placeholder="">
                    </div>
                </div>
                <div class="loction">
                    <div class="title">平移开始</div>
                    <div class="x-axis axis">
                        <p>U</p>
                        <input v-model="m_u_start" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999"
                            type="number" placeholder="U开始" @focus="Focus" @blur="Blur" />
                    </div>
                    <div class="y-axis axis" style="margin-left:0.65104vw">
                        <p>V</p>
                        <input v-model="m_v_start" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999"
                            type="number" placeholder="V开始" @focus="Focus" @blur="Blur" />
                    </div>
                </div>
                <div class="loction">
                    <div class="title">平移结束</div>
                    <div class="x-axis axis">
                        <p>U</p>
                        <input v-model="m_u_end" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999"
                            type="number" placeholder="U结束" @focus="Focus" @blur="Blur" />
                    </div>
                    <div class="y-axis axis" style="margin-left:0.65104vw">
                        <p>V</p>
                        <input v-model="m_v_end" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999"
                            type="number" placeholder="V结束" @focus="Focus" @blur="Blur" />
                    </div>
                </div>
                <div class="projection">
                    <div class="title">旋转</div>
                    <div class="txt_box">
                        <input class="input" type="text" v-model="m_rot_start" @focus="Focus" @blur="Blur" placeholder="开始">
                    </div>
                    <div class="txt_box" style="margin-left:1.30208vw">
                        <input class="input" type="text" v-model="m_rot_end" @focus="Focus" @blur="Blur" placeholder="结束">
                    </div>
                </div>
                <div class="shadow">
                    <div class="title">悠悠</div>
                    <div class="ipt_box">
                        <input class="input1" type="checkbox" v-model="textureModel" />
                    </div>
                </div>

                <div class="projection">
                    <div class="title">时间</div>
                    <div class="txt_box">
                        <input class="input" type="text" v-model="m_time" @focus="Focus" @blur="Blur" placeholder="请输入时间">
                    </div>
                </div>
            </div>
        </div>


        <div class="box3" :class="isActive1 ? 'anmDiv' : 'aaa'" v-if="scriptType == 2">
            <div class="header">
                <div @click="shrinkChange1"><i class="el-icon-arrow-down"></i> </div>
                <p>动态控制器</p>
                <!-- <div class="del" @click="reduce1()"><i class="el-icon-circle-close"></i> </div> -->
            </div>
            <div>
                <div class="projection">
                    <div class="title">动态控制器名称：</div>
                    <div class="txt_box">
                        <input class="input" type="text" v-model="fileName" @focus="Focus" @blur="Blur" placeholder="">
                    </div>
                </div>
                <div class="loction1">
                    <div class="title">移动开始</div>
                    <div class="x-axis axis">
                        <p>X</p>
                        <input v-model="m_pos_startX" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999"
                            type="number" @focus="Focus" @blur="Blur" />
                    </div>
                    <div class="y-axis axis">
                        <p>Y</p>
                        <input v-model="m_pos_startY" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999"
                            type="number" @focus="Focus" @blur="Blur" />
                    </div>
                    <div class="z-axis axis">
                        <p>Z</p>
                        <input v-model="m_pos_startZ" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999"
                            type="number" @focus="Focus" @blur="Blur" />
                    </div>
                </div>
                <div class="loction1">
                    <div class="title">移动结束</div>
                    <div class="x-axis axis">
                        <p>X</p>
                        <input v-model="m_pos_endX" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999"
                            type="number" @focus="Focus" @blur="Blur" />
                    </div>
                    <div class="y-axis axis">
                        <p>Y</p>
                        <input v-model="m_pos_endY" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999"
                            type="number" @focus="Focus" @blur="Blur" />
                    </div>
                    <div class="z-axis axis">
                        <p>Z</p>
                        <input v-model="m_pos_endZ" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999"
                            type="number" @focus="Focus" @blur="Blur" />
                    </div>
                </div>
                <div class="loction1">
                    <div class="title">旋转开始</div>
                    <div class="x-axis axis">
                        <p>X</p>
                        <input v-model="m_rot_startX" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999"
                            type="number" @focus="Focus" @blur="Blur" />
                    </div>
                    <div class="y-axis axis">
                        <p>Y</p>
                        <input v-model="m_rot_startY" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999"
                            type="number" @focus="Focus" @blur="Blur" />
                    </div>
                    <div class="z-axis axis">
                        <p>Z</p>
                        <input v-model="m_rot_startZ" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999"
                            type="number" @focus="Focus" @blur="Blur" />
                    </div>
                </div>
                <div class="loction1">
                    <div class="title">旋转结束</div>
                    <div class="x-axis axis">
                        <p>X</p>
                        <input v-model="m_rot_endX" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999"
                            type="number" @focus="Focus" @blur="Blur" />
                    </div>
                    <div class="y-axis axis">
                        <p>Y</p>
                        <input v-model="m_rot_endY" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999"
                            type="number" @focus="Focus" @blur="Blur" />
                    </div>
                    <div class="z-axis axis">
                        <p>Z</p>
                        <input v-model="m_rot_endZ" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999"
                            type="number" @focus="Focus" @blur="Blur" />
                    </div>
                </div>
                <div class="loction1">
                    <div class="title">缩放开始</div>
                    <div class="x-axis axis">
                        <p>X</p>
                        <input v-model="m_scale_startX"
                            oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number"
                            @focus="Focus" @blur="Blur" />
                    </div>
                    <div class="y-axis axis">
                        <p>Y</p>
                        <input v-model="m_scale_startY"
                            oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number"
                            @focus="Focus" @blur="Blur" />
                    </div>
                    <div class="z-axis axis">
                        <p>Z</p>
                        <input v-model="m_scale_startZ"
                            oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number"
                            @focus="Focus" @blur="Blur" />
                    </div>
                </div>
                <div class="loction1">
                    <div class="title">缩放结束</div>
                    <div class="x-axis axis">
                        <p>X</p>
                        <input v-model="m_scale_endX" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999"
                            type="number" @focus="Focus" @blur="Blur" />
                    </div>
                    <div class="y-axis axis">
                        <p>Y</p>
                        <input v-model="m_scale_endY" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999"
                            type="number" @focus="Focus" @blur="Blur" />
                    </div>
                    <div class="z-axis axis">
                        <p>Z</p>
                        <input v-model="m_scale_endZ" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999"
                            type="number" @focus="Focus" @blur="Blur" />
                    </div>
                </div>
                <div class="shadow">
                    <div class="title">悠悠</div>
                    <div class="ipt_box">
                        <input class="input1" type="checkbox" v-model="DynamicMode" />
                    </div>
                </div>
                <div class="projection">
                    <div class="title">时间</div>
                    <div class="txt_box">
                        <input class="input" type="text" v-model="d_time" @focus="Focus" @blur="Blur" placeholder="">
                    </div>
                </div>

            </div>
        </div>
        <div class="box2" :class="isActive2 ? 'anmDiv' : 'aaa'" v-if="false">
            <div class="header">
                <!-- <div @click="shrinkChange2"><i class="el-icon-arrow-down"></i> </div> -->
                <p>目标控制器</p>
                <!-- <div class="del" @click="reduce2()"><i class="el-icon-circle-close"></i> </div> -->
            </div>
            <div>
                <div class="projection">
                    <div class="title">距离目标高度</div>
                    <div class="txt_box">
                        <input class="input" type="text" v-model="heightTarget" @focus="Focus" @blur="Blur" placeholder="">
                    </div>
                </div>
                <div class="projection">
                    <div class="title">观察目标高度</div>
                    <div class="txt_box">
                        <input class="input" type="text" v-model="observeTarget" @focus="Focus" @blur="Blur" placeholder="">
                    </div>
                </div>
                <div class="projection">
                    <div class="title">旋转速度</div>
                    <div class="txt_box">
                        <input class="input" type="text" v-model="rotationalSpeed" @focus="Focus" @blur="Blur"
                            placeholder="">
                    </div>
                </div>
            </div>
        </div>
        <div class="box4" :class="isActive3 ? 'anmDiv' : 'aaa'" v-if="scriptType == 3">
            <div class="header">
                <!-- <div @click="shrinkChange3"><i class="el-icon-arrow-down"></i> </div> -->
                <p>文本控制器</p>
                <!-- <div class="del" @click="reduce3()"><i class="el-icon-circle-close"></i> </div> -->
            </div>
            <div>
                <div class="projection">
                    <div class="title">文本控制器名称：</div>
                    <div class="txt_box">
                        <input class="input" type="text" v-model="fileName" @focus="Focus" @blur="Blur" placeholder="">
                    </div>
                </div>
                <div class="textarea_box">
                    <el-input type="textarea" class="textarea" v-model="text0" :show-word-limit="true" resize="none"
                        @focus="Focus" @blur="Blur"></el-input>
                </div>
                <div class="textarea_box">
                    <el-input type="textarea" class="textarea" v-model="text1" :show-word-limit="true" resize="none"
                        @focus="Focus" @blur="Blur"></el-input>
                </div>
                <div class="textarea_box">
                    <el-input type="textarea" class="textarea" v-model="text2" :show-word-limit="true" resize="none"
                        @focus="Focus" @blur="Blur"></el-input>
                </div>
            </div>
        </div>
        <div class="box2" :class="isActive4 ? 'anmDiv' : 'aaa'" v-if="scriptType == 4">
            <div class="header">
                <div @click="shrinkChange4"><i class="el-icon-arrow-down"></i> </div>
                <p>曲线控制器</p>
                <!-- <div class="del" @click="reduce4()"><i class="el-icon-circle-close"></i> </div> -->
            </div>
            <div>
                <div class="projection">
                    <div class="title">路径模拟器名称：</div>
                    <div class="txt_box">
                        <input class="input" type="text" v-model="fileName" @focus="Focus" @blur="Blur" placeholder="">
                    </div>
                </div>
                <!-- <div class="projection">
                    <div class="title">曲线</div>
                    <div class="txt_box">
                        <el-select class="select" @change="selectChange()" v-model="select" placeholder="请选择">
                            <el-option v-for="item in selectList" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                </div> -->
                <div class="projection">
                    <div class="title">速度</div>
                    <div class="txt_box">
                        <input class="input" type="number" v-model="speed" @focus="Focus" @blur="Blur" placeholder="">
                    </div>
                </div>
                <div class="shadow">
                    <div class="title">循环</div>
                    <div class="txt_box">
                        <el-select class="select" v-model="bloop" placeholder="请选择">
                            <el-option v-for="item in loopList" :key="item.id" :label="item.label" :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="shadow">
                    <div class="title">模拟</div>
                    <div class="ipt_box">
                        <input class="input1" type="checkbox" v-model="sim" />
                    </div>
                </div>
                <div class="projection">
                    <div class="title">起始</div>
                    <div class="slider">
                        <el-slider v-model="percent" :min="0" :max="100" :step="1" show-input
                            :show-tooltip="false"></el-slider>
                    </div>
                </div>
            </div>
        </div>
        <div class="box4" :class="isActive5 ? 'anmDiv' : 'aaa'" v-if="scriptType == 5">
            <div class="header">
                <div @click="shrinkChange5"><i class="el-icon-arrow-down"></i> </div>
                <p>HUD</p>
                <!-- <div class="del" @click="reduce5()"><i class="el-icon-circle-close"></i> </div> -->
            </div>
            <div>
                <div class="projection">
                    <div class="title">HUD名称：</div>
                    <div class="txt_box">
                        <input class="input" type="text" v-model="fileName" @focus="Focus" @blur="Blur" placeholder="">
                    </div>
                </div>
                <div class="loction1">
                    <div class="title">位置</div>
                    <div class="x-axis axis">
                        <p>X</p>
                        <input v-model="positionX" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999"
                            type="number" @focus="Focus" @blur="Blur" />
                    </div>
                    <div class="y-axis axis">
                        <p>Y</p>
                        <input v-model="positionY" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999"
                            type="number" @focus="Focus" @blur="Blur" />
                    </div>
                    <div class="z-axis axis">
                        <p>Z</p>
                        <input v-model="positionZ" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999"
                            type="number" @focus="Focus" @blur="Blur" />
                    </div>
                </div>
                <div class="projection">
                    <div class="title" style="margin-left:0.32552vw">尺寸</div>
                    <div class="txt_box" style="margin-left:0.45573vw">
                        <input class="input" type="number" v-model="size" @focus="Focus" @blur="Blur" placeholder="">
                    </div>
                </div>
                <div class="color color1">
                    <div class="title">颜色</div>
                    <el-color-picker v-model="Color" @active-change="ActiveColor" popper-class="picker"></el-color-picker>
                    <div class="import_color">
                        <input type="text" v-model="Color" @focus="Focus" @blur="Blur" @input="colorImport()">
                    </div>
                </div>
                <div class="textarea_box1">
                    <div class="title">文本</div>
                    <el-input type="textarea" class="textarea" v-model="hubText" maxlength="100" :show-word-limit="true"
                        resize="none" @focus="Focus" @blur="Blur"></el-input>
                </div>
            </div>
        </div>
        <div class="box5" :class="isActive6 ? 'anmDiv' : 'aaa'" v-if="scriptType == 6">
            <div class="header">
                <!-- <div @click="shrinkChange6"><i class="el-icon-arrow-down"></i> </div> -->
                <p>AI寻路</p>
                <!-- <div class="del" @click="reduce6()"><i class="el-icon-circle-close"></i> </div> -->
            </div>
            <div>
                <div class="projection">
                    <div class="title">AI寻路名称：</div>
                    <div class="txt_box">
                        <input class="input" type="text" v-model="fileName" @focus="Focus" @blur="Blur" placeholder="">
                    </div>
                </div>
                <div class="projection">
                    <div class="title">寻路资源url</div>
                    <div class="txt_box">
                        <input class="input" type="text" @change="AiNum($event)" v-model="m_navMeshPath" @focus="Focus"
                            @blur="Blur" placeholder="">
                    </div>
                </div>
                <div class="projection">
                    <div class="title">可移动最大速度</div>
                    <div class="txt_box">
                        <input class="input" type="number" @change="AiNum($event)" v-model="m_maxSpeed" @focus="Focus"
                            @blur="Blur" placeholder="">
                    </div>
                </div>
                <div class="projection">
                    <div class="title">可移动动力力量</div>
                    <div class="txt_box">
                        <input class="input" type="number" @change="AiNum($event)" v-model="m_maxForce" @focus="Focus"
                            @blur="Blur" placeholder="">
                    </div>
                </div>
                <div class="projection">
                    <div class="title">寻点距离</div>
                    <div class="txt_box">
                        <input class="input" type="number" @change="AiNum($event)" v-model="m_nextWaypointDistance"
                            @focus="Focus" @blur="Blur" placeholder="">
                    </div>
                </div>
                <div class="shadow">
                    <div class="title">动画开关</div>
                    <div class="ipt_box">
                        <input class="input1" type="checkbox" @change="animChange($event)" v-model="m_b_anim" />
                    </div>
                </div>
                <div class="projection">
                    <div class="title">动画骨骼节点</div>
                    <div class="txt_box">
                        <input class="input" type="text" @change="AiNum($event)" v-model="m_group_anim_name" @focus="Focus"
                            @blur="Blur" placeholder="">
                    </div>
                </div>
                <div class="projection">
                    <div class="title">待机动画</div>
                    <div class="txt_box">
                        <input class="input" type="text" @change="AiNum($event)" v-model="m_idle_anim" @focus="Focus"
                            @blur="Blur" placeholder="">
                    </div>
                </div>
                <div class="projection">
                    <div class="title">移动动画</div>
                    <div class="txt_box">
                        <input class="input" type="text" @change="AiNum($event)" v-model="m_run_anim" @focus="Focus"
                            @blur="Blur" placeholder="">
                    </div>
                </div>

                <div class="loction1">
                   <div class="title" style="width:2.27865vw">偏移</div>
                   <div class="x-axis axis">
                       <p>X</p>
                       <input v-model="offsetX" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number"   @focus="Focus" @blur="Blur" />
                   </div>
                   <div class="y-axis axis">
                       <p>Y</p>
                       <input v-model="offsetY" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number"   @focus="Focus" @blur="Blur"/>
                   </div>
                   <div class="z-axis axis">
                       <p>Z</p>
                       <input v-model="offsetZ" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number"  @focus="Focus" @blur="Blur"/>
                   </div>
                </div>


                <div class="projection">
                    <div class="title">自定义数据</div>
                    <el-input type="textarea" class="textarea" @change="AiNum()" v-model="m_userData" maxlength="100"
                        :show-word-limit="true" resize="none" @focus="Focus" @blur="Blur"></el-input>
                </div>
            </div>
        </div>

        <!-- <div class="videoCover">
            <div class="add" @click.stop="tabState = !tabState"><i class="el-icon-circle-plus-outline"></i> </div>
           
            <div class="tab" v-show="tabState">
                <div class="list" @click.stop="tabChange(index)" v-for="(item, index) in list" :key="index"
                    :class="{ active: cur == index }">
                    <div class="img"><img :src="item.src" alt=""/></div>
                    <div class="text">{{ item.txt }} </div>
                </div>
            </div>
        </div> -->
    </div>
</template>
<script>
import { ISetting } from "../../threejs/interface/ISetting";
import { IBehavior } from "../../threejs/interface/IBehavior";
import { INetwork } from "../../threejs/interface/INetwork";

import texture from "../../assets/Property/texture.png";
import checkedTex from "../../assets/Property/checkedTex.png";
import controller from "../../assets/Property/controller.png";
import checkedCon from "../../assets/Property/checkedCon.png";
import target from "../../assets/Property/target.png";
import checkedTar from "../../assets/Property/checkedTar.png"
import text from "../../assets/Property/text.png";
import checkedText from "../../assets/Property/checkedText.png"
import path from "../../assets/Property/path.png"
import checkedPath from "../../assets/Property/checkedPath.png"
import hud from "../../assets/Property/hud.png";
import CheckedHud from "../../assets/Property/checkedHud.png";
import Ai from "../../assets/Property/Ai.png";
import CheckedAi from "../../assets/Property/checkedAi.png"
export default {

    name: "Video",
    props: ["scriptType", "folderId", "ResourceType", "scriptEditId", "node"],

    data() {
        return {
            boxState: false,
            isActive: true,
            isActive1: true,
            isActive2: true,
            isActive3: true,
            isActive4: true,
            isActive5: true,
            isActive6: true,
            value: 50,
            list: [
                {
                    txt: "动态纹理",
                    src: texture,
                },
                {
                    txt: "轨道控制器",
                    src: controller,
                },
                {
                    txt: "文本控制器",
                    src: text,
                },
                {
                    txt: "路径模拟器",
                    src: path,
                },
                {
                    txt: "HUD",
                    src: hud,
                },
                {
                    txt: "AI寻路",
                    src: Ai,
                }
            ],
            currentType: 0,
            autorotation: false,
            textureState: false,
            controllerState: false,
            targetState: false,
            tabState: false,
            textState: false,
            pathState: false,
            hudState: false,
            NavMeshState: false,
            cur: -1,
            uSpeed: 0,//u速度
            vSpeed: 0,//v速度
            AutomaticSpeed: 0,//自动旋转速度
            ControlSpeed: 0,//控制旋转速度
            upRange: 0,//上角度范围
            downRange: 0,//下角度范围
            leftRange: 0,//左角度范围
            rightRange: 0,//右角度范围
            zoomRange: 0,//缩放范围
            zoomRange1: 0,//缩放范围
            heightTarget: 0,//距离目标高度
            observeTarget: 0,//观察目标高度
            rotationalSpeed: 0,//旋转速度
            text0: "",
            text1: "",
            text2: "",
            speed: "",//速度
            sim: false,//模拟
            selectList: [
                {
                    value: 1,
                    id: "",
                    label: ""
                },
            ],
            select: "",
            loopList: [
                {
                    value: 0,
                    id: 0,
                    label: "无"
                },
                {
                    value: 1,
                    id: 1,
                    label: "反复"
                },
                {
                    value: 2,
                    id: 2,
                    label: "循环"
                },
            ],
            bloop: 0,
            percent: 0,//起始
            positionX: "",
            positionY: "",
            positionZ: "",
            Color: "#FFFFFF",//颜色
            hubText: "",//hub文本
            size: "",//尺寸
            m_navMeshPath: "",//寻路资源url
            m_maxSpeed: 0,//可以移动的最大速度
            m_maxForce: 0,//可以移动动力力量
            m_nextWaypointDistance: "",//寻点距离
            m_idle_anim: "",//待机动画
            m_run_anim: "",//移动动画
            m_group_anim_name: "",//动画骨骼节点
            m_b_anim: false,//动画开关
            m_userData: "",//自定义数据


            //纹理控制器
            m_u_start: '',//U开始
            m_v_start: '',//V开始
            m_u_end: '',//U结束
            m_v_end: '',//V结束
            m_time: "",//时间
            m_rot_start: '',//旋转开始
            m_rot_end: '',//旋转结束
            //动态控制器
            m_pos_startX: "",//移动x
            m_pos_startY: "",//移动y
            m_pos_startZ: "",//移动z
            m_pos_endX: "",//移动EndX
            m_pos_endY: "",//移动EndY
            m_pos_endZ: "",//移动EndZ
            m_rot_startX: "",//旋转X
            m_rot_startY: "",//旋转Y
            m_rot_startZ: "",//旋转Z
            m_rot_endX: "",//旋转EndX
            m_rot_endY: "",//旋转EndY
            m_rot_endZ: "",//旋转EndZ
            m_scale_startX: "",//缩放X
            m_scale_startY: "",//缩放Y
            m_scale_startZ: "",//缩放Z
            m_scale_endX: "",//缩放EndX
            m_scale_endY: "",//缩放EndY
            m_scale_endZ: "",//缩放EndZ
            d_time: "",//时间
            DynamicMode: false,//动态悠悠
            textureModel: false,//纹理悠悠

            fileName: "",

            scriptTypeMap: {
                "1": "TextureBehavior",
                "2": "DymaticBehavior",
                "3": "MutilTextBehavior",
                "4": "CurveBehavior",
                "5": "HudBehavior",
                "6": "NavMeshBehavior",

            },
            scriptTypeToSqlType: {
                "1": 13,
                "2": 14,
                "3": 15,
                "4": 16,
                "5": 17,
                "6": 18,
            },
            offsetX:"",//偏移X
            offsetY:"",//偏移Y
            offsetZ:"",//偏移Z

        }
    },
    mounted() {
        window.addEventListener("click", this.addChange);

        this.initData(this.scriptType)
    },
    methods: {
        initData(type) {

            if (this.scriptEditId != "") {
                this.fileName = this.node.name
            }
            if (type == 1) {
                if (this.scriptEditId != "") {
                    INetwork.openBehaviorJson(this.node.fileUrl, IBehavior.m_textureBehavior, (data) => {
                        this.m_u_start = data.m_u_start;
                        this.m_v_start = data.m_v_start;
                        this.m_u_end = data.m_u_end;
                        this.m_v_end = data.m_v_end;
                        this.m_time = data.m_time;
                        this.m_rot_start = data.m_rot_start;
                        this.m_rot_end = data.m_rot_end;
                        this.fileName = this.node.name;
                        this.textureModel = data.m_yoyo;
                        console.log(data, "data");
                    })
                } else {
                    let data = IBehavior.getBehaviorJson(IBehavior.m_textureBehavior)

                    this.m_u_start = data.m_u_start;
                    this.m_v_start = data.m_v_start;
                    this.m_u_end = data.m_u_end;
                    this.m_v_end = data.m_v_end;
                    this.m_time = data.m_time;
                    this.m_rot_start = data.m_rot_start;
                    this.textureModel = data.m_yoyo;

                    this.m_rot_end = data.m_rot_end;

                }
            } else if (type == 2) {
                if (this.scriptEditId != "") {
                    // fileUrl
                    INetwork.openBehaviorJson(this.node.fileUrl, IBehavior.m_dymaticBehavior, (data) => {

                        this.m_pos_startX = data.m_pos_start[0];
                        this.m_pos_startY = data.m_pos_start[1];
                        this.m_pos_startZ = data.m_pos_start[2];
                        this.m_pos_endX = data.m_pos_end[0];
                        this.m_pos_endY = data.m_pos_end[1];
                        this.m_pos_endZ = data.m_pos_end[2];
                        this.m_rot_startX = data.m_rot_start[0];
                        this.m_rot_startY = data.m_rot_start[1];
                        this.m_rot_startZ = data.m_rot_start[2];
                        this.m_rot_endX = data.m_rot_end[0];
                        this.m_rot_endY = data.m_rot_end[1];
                        this.m_rot_endZ = data.m_rot_end[2];
                        this.m_scale_startX = data.m_scale_start[0];
                        this.m_scale_startY = data.m_scale_start[1];
                        this.m_scale_startZ = data.m_scale_start[2];
                        this.m_scale_endX = data.m_scale_end[0];
                        this.m_scale_endY = data.m_scale_end[1];
                        this.m_scale_endZ = data.m_scale_end[2];
                        this.d_time = data.m_time;
                        this.fileName = this.node.name
                        this.DynamicMode = data.m_yoyo

                    })

                } else {
                    let data = IBehavior.getBehaviorJson(IBehavior.m_dymaticBehavior)
                    this.m_pos_startX = data.m_pos_start[0];
                    this.m_pos_startY = data.m_pos_start[1];
                    this.m_pos_startZ = data.m_pos_start[2];
                    this.m_pos_endX = data.m_pos_end[0];
                    this.m_pos_endY = data.m_pos_end[1];
                    this.m_pos_endZ = data.m_pos_end[2];
                    this.m_rot_startX = data.m_rot_start[0];
                    this.m_rot_startY = data.m_rot_start[1];
                    this.m_rot_startZ = data.m_rot_start[2];
                    this.m_rot_endX = data.m_rot_end[0];
                    this.m_rot_endY = data.m_rot_end[1];
                    this.m_rot_endZ = data.m_rot_end[2];
                    this.m_scale_startX = data.m_scale_start[0];
                    this.m_scale_startY = data.m_scale_start[1];
                    this.m_scale_startZ = data.m_scale_start[2];
                    this.m_scale_endX = data.m_scale_end[0];
                    this.m_scale_endY = data.m_scale_end[1];
                    this.m_scale_endZ = data.m_scale_end[2];
                    this.d_time = data.m_time;
                    this.DynamicMode = data.m_yoyo

                }



            }






            else if (type == 3) {
                if (this.scriptEditId != "") {
                    // fileUrl
                    INetwork.openBehaviorJson(this.node.fileUrl, IBehavior.m_mutilTextBehavior, (json) => {

                        this.text0 = json[0]
                        this.text1 = json[1]
                        this.text2 = json[2]
                        this.fileName = this.node.name
                        // console.log(json,"json");
                    })

                } else {
                    let data = IBehavior.getBehaviorJson(IBehavior.m_mutilTextBehavior)

                    this.text0 = data[0]
                    this.text1 = data[1]
                    this.text2 = data[2]
                }



            } else if (type == 4) {

                if (this.scriptEditId != "") {
                    INetwork.openBehaviorJson(this.node.fileUrl, IBehavior.m_curveBehavior, (data) => {

                        this.speed = data.speed;
                        this.bloop = data.bloop;
                        this.sim = data.sim;
                        this.select = data.curve_name;
                        this.percent = data.percent;


                    })


                } else {
                    let data = IBehavior.getBehaviorJson(IBehavior.m_curveBehavior)
                    this.speed = data.speed;
                    this.bloop = data.bloop;
                    this.sim = data.sim;
                    this.select = data.curve_name;
                    this.percent = data.percent;
                }



            } else if (type == 5) {

                if (this.scriptEditId != "") {

                    INetwork.openBehaviorJson(this.node.fileUrl, IBehavior.m_hudBehavior, (data) => {
                        if (data != null && data != undefined) {
                            this.positionX = data.m_colorArray[0];
                            this.positionY = data.m_colorArray[1];
                            this.positionZ = data.m_colorArray[2];
                            this.Color = data.m_color;
                            this.size = data.m_size;
                            this.hubText = data.m_text;
                        }
                    }
                    )


                } else {
                    let data = IBehavior.getBehaviorJson(IBehavior.m_hudBehavior)
                    if (data != null && data != undefined) {


                        this.positionX = data.m_colorArray[0];
                        this.positionY = data.m_colorArray[1];
                        this.positionZ = data.m_colorArray[2];
                        this.Color = data.m_color;
                        this.size = data.m_size;
                        this.hubText = data.m_text;
                    }
                }


            } else if (type == 6) {

                if (this.scriptEditId != "") {
                    INetwork.openBehaviorJson(this.node.fileUrl, IBehavior.m_navMeshBehavior, (data) => {
                        if (data != null && data != undefined) {
                            this.m_b_anim = data.m_b_anim;
                            this.m_group_anim_name = data.m_group_anim_name;
                            this.m_idle_anim = data.m_idle_anim;
                            this.m_maxForce = data.m_maxForce;
                            this.m_maxSpeed = data.m_maxSpeed;
                            this.m_navMeshPath = data.m_navMeshPath;
                            this.m_nextWaypointDistance = data.nextWaypointDistance;
                            this.m_run_anim = data.m_run_anim;
                            this.m_userData = data.m_userData;
                            this.offsetX = data.m_offset[0];
                            this.offsetY = data.m_offset[1];
                            this.offsetZ = data.m_offset[2];

                            
                            
                        }

                    })

                } else {



                    let data = IBehavior.getBehaviorJson(IBehavior.m_navMeshBehavior)
                    if (data != null && data != undefined) {
                        this.m_b_anim = data.m_b_anim;
                        this.m_group_anim_name = data.m_group_anim_name;
                        this.m_idle_anim = data.m_idle_anim;
                        this.m_maxForce = data.m_maxForce;
                        this.m_maxSpeed = data.m_maxSpeed;
                        this.m_navMeshPath = data.m_navMeshPath;
                        this.m_nextWaypointDistance = data.nextWaypointDistance;
                        this.m_run_anim = data.m_run_anim;
                        this.m_userData = data.m_userData;
                        this.offsetX = data.m_offset[0];
                            this.offsetY = data.m_offset[1];
                            this.offsetZ = data.m_offset[2];


                    }
                }
            }
        },


        setData(type) {
            if (type == 1) {


                let data = {}


                data.m_u_start = this.m_u_start;
                data.m_v_start = this.m_v_start;
                data.m_u_end = this.m_u_end;
                data.m_v_end = this.m_v_end;
                data.m_time = this.m_time;
                data.m_rot_start = this.m_rot_start;
                data.m_yoyo = this.textureModel;

                data.m_rot_end = this.m_rot_end;


                console.log(data, "data");

                IBehavior.setBehaviorJson(data, IBehavior.m_textureBehavior)

            } else if (type == 2) {

                let data = {
                    m_pos_start: [0, 0, 0],
                    m_pos_end: [0, 0, 0],

                    m_rot_start: [0, 0, 0],
                    m_rot_end: [0, 0, 0],
                    m_scale_start: [0, 0, 0],
                    m_scale_end: [0, 0, 0],

                }
                data.m_pos_start[0] = Number(this.m_pos_startX);
                data.m_pos_start[1] = Number(this.m_pos_startY);
                data.m_pos_start[2] = Number(this.m_pos_startZ);
                data.m_pos_end[0] = Number(this.m_pos_endX);
                data.m_pos_end[1] = Number(this.m_pos_endY);
                data.m_pos_end[2] = Number(this.m_pos_endZ);
                data.m_rot_start[0] = Number(this.m_rot_startX);
                data.m_rot_start[1] = Number(this.m_rot_startY);
                data.m_rot_start[2] = Number(this.m_rot_startZ);
                data.m_rot_end[0] = Number(this.m_rot_endX);
                data.m_rot_end[1] = Number(this.m_rot_endY);
                data.m_rot_end[2] = Number(this.m_rot_endZ);
                data.m_scale_start[0] = Number(this.m_scale_startX);
                data.m_scale_start[1] = Number(this.m_scale_startY);
                data.m_scale_start[2] = Number(this.m_scale_startZ);
                data.m_scale_end[0] = Number(this.m_scale_endX);
                data.m_scale_end[1] = Number(this.m_scale_endY);
                data.m_scale_end[2] = Number(this.m_scale_endZ);
                data.m_time = this.d_time;
                data.m_yoyo = this.DynamicMode


                IBehavior.setBehaviorJson(data, IBehavior.m_dymaticBehavior)

            } else if (type == 3) {

                let data = []

                data[0] = this.text0
                data[1] = this.text1
                data[2] = this.text2


                IBehavior.setBehaviorJson(data, IBehavior.m_mutilTextBehavior)

            } else if (type == 4) {
                let data = {}
                data.speed = this.speed;
                data.bloop = this.bloop;
                data.sim = this.sim;
                data.curve_name = this.select;
                data.percent = this.percent;

                IBehavior.setBehaviorJson(data, IBehavior.m_curveBehavior)


            } else if (type == 5) {


                let data = {}
                let m_center = []
                m_center[0] = this.positionX;
                m_center[1] = this.positionY;
                m_center[2] = this.positionZ;
                data.m_center = m_center

                let color = this.Color.slice(1);
                let rgba = [
                    parseInt('0x' + color.slice(0, 2)),
                    parseInt('0x' + color.slice(2, 4)),
                    parseInt('0x' + color.slice(4, 6)),
                ];
                let color0 = rgba[0] / 255;
                let color1 = rgba[1] / 255;
                let color2 = rgba[2] / 255;
                let data2 = {
                    m_center: [Number(this.positionX), Number(this.positionY), Number(this.positionZ)],
                    m_colorArray: [color0, color1, color2],
                    m_text: this.hubText,
                    m_size: Number(this.size)
                }
                data.m_colorArray = data2.m_colorArray;
                data.m_color = this.Color

                data.m_size = this.size;
                data.m_text = this.hubText;
                console.log(data, this.Color, "data");
                IBehavior.setBehaviorJson(data, IBehavior.m_hudBehavior)

            } else if (type == 6) {
                let data = {}
                data.m_b_anim = this.m_b_anim;
                data.m_group_anim_name = this.m_group_anim_name;
                data.m_idle_anim = this.m_idle_anim;
                data.m_maxForce = this.m_maxForce;

                data.m_maxSpeed = this.m_maxSpeed;
                data.m_navMeshPath = this.m_navMeshPath;
                data.nextWaypointDistance = this.m_nextWaypointDistance;
                data.m_run_anim = this.m_run_anim;
                data.m_userData = this.m_userData;
                data.m_offset=[Number(this.offsetX),Number(this.offsetY),Number(this.offsetZ)]


                IBehavior.setBehaviorJson(data, IBehavior.m_navMeshBehavior)

            }
        },

        newFile() {

            if (this.scriptEditId != "") {
                this.setData(this.scriptType)

                this.updateFile(this.scriptEditId)

            } else {
                this.setData(this.scriptType)

                this.addFile(this.scriptTypeToSqlType[this.scriptType])
            }


        },
        // 保存到数据库
        //调用后台接口 添加文件
        async addFile(type) {
            // let fileName = this.mainUrl.split("/");
            // let name = fileName[fileName.length - 1];
            // name = name.split("?")[0];


            const res = await this.$https.addFile({
                //    this.url_list=url_list
                // this.mainUrl=mainUrl
                //文件夹id
                // folderId:this.id,
                projectId: localStorage.getItem("projectId"),
                folderId: this.folderId,
                name: this.fileName,
                type: type,
                fileUrl: "",
                fileSize: 0,
                imageUrl: "",
                deputyUrl: ""
            });
            if (res.success == true) {
                //上传成功


                this.updateFile(res.data)
            } else {
                var token = localStorage.getItem("token");
                if (token) {
                    this.$message({
                        message: res.errMessage
                        // type: "warning"
                    });
                }
            }
        },

        async updateFile(fileId) {

            //获取真实的数据 这里需要传文件类型
            var data = INetwork.getBehaviorJsonToSql(fileId, this.scriptTypeMap[this.scriptType], () => { });
            const loading = this.$loading({
                lock: true,
                text: "Loading",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            });
            var time = new Date().getTime();

            const res = await this.$https.updateFile({
                //文件夹id
                // folderId:this.id,
                projectId: localStorage.getItem("projectId"),
                id: fileId,
                // name: name,
                fileUrl: data.mainName,
                imageUrl: data.imageName + "?" + "time=" + time,
                deputyUrl: data.otherNameList.toString(),
                fileSize: 0
            });

            if (res.errCode == null) {
                loading.close();
                this.fileName = "";
                this.$parent.$parent.$parent.setFileDirectoryId(this.folderId)
            } else {
                loading.close();
            }
        },


        IBehaviorData() {
            //文本控制器
            const getBehaviorList = IBehavior.getBehavior();
            // console.log("--", getBehaviorList)
            if (getBehaviorList != null) {
                this.boxState = true;
                var mt = getBehaviorList.get(IBehavior.m_mutilTextBehavior);
                if (mt != null && mt != undefined) {
                    var list = mt.m_mutilTextArray;
                    list.forEach(element => {
                        // console.log(element)
                    });

                    this.textState = true;
                    this.text0 = "";
                    this.text1 = "";
                    this.text2 = "";
                    this.text0 = list[0];
                    this.text1 = list[1];
                    this.text2 = list[2];
                } else {
                    this.textState = false;
                }
                //路径模拟器
                var cb = getBehaviorList.get(IBehavior.m_curveBehavior);
                if (cb != null && cb != undefined) {
                    // console.log("11",cb)
                    this.speed = cb.m_speed;
                    this.bloop = cb.m_bloop;
                    this.sim = cb.m_sim;
                    this.select = cb.m_curve_name;
                    this.pathState = true;
                    this.percent = cb.m_percent;
                    const CurveBehaviorList = IBehavior.getCurveBehaviorList();
                    if (CurveBehaviorList != null) {
                        // console.log("---",CurveBehaviorList)
                        this.selectList = CurveBehaviorList;
                    }
                } else {
                    this.pathState = false;
                }
                //HUB
                let hub = getBehaviorList.get(IBehavior.m_hudBehavior);
                if (hub != null && hub != undefined) {
                    this.positionX = hub.m_center.x;
                    this.positionY = hub.m_center.y;
                    this.positionZ = hub.m_center.z;
                    this.Color = hub.m_color;
                    this.size = hub.m_size;
                    this.hubText = hub.m_text;
                    this.hudState = true;
                } else {
                    this.hudState = false;
                }
                //AI寻路
                let nav = getBehaviorList.get(IBehavior.m_navMeshBehavior);
                if (nav != null && nav != undefined) {
                    // console.log("ai",nav)
                    this.m_b_anim = nav.m_b_anim;
                    this.m_group_anim_name = nav.m_group_anim_name;
                    this.m_idle_anim = nav.m_idle_anim;
                    this.m_maxForce = nav.m_maxForce;
                    this.m_maxSpeed = nav.m_maxSpeed;
                    this.m_navMeshPath = nav.m_navMeshPath;
                    this.m_nextWaypointDistance = nav.m_nextWaypointDistance;
                    this.m_run_anim = nav.m_run_anim;
                    this.m_userData = nav.m_userData;
                    this.NavMeshState = true;
                } else {
                    this.NavMeshState = false;
                }
            } else {
                this.boxState = false;
            }


        },
        // loopChange(){
        //     let data = {
        //         curve_name:this.select,
        //         speed:Number(this.speed),
        //         bloop:this.bloop,
        //         sim:this.sim,
        //         percent:Number(this.percent)
        //     }
        //     IBehavior.setCurveBehavior(data);
        // },
        simChange() {
            let data = {
                curve_name: this.select,
                speed: Number(this.speed),
                bloop: this.bloop,
                sim: this.sim,
                percent: Number(this.percent)
            }
            IBehavior.setCurveBehavior(data);
        },
        textareaChnage0() {
            IBehavior.setMutilTextBehaviorText(0, this.text0)
        },
        textareaChnage1() {
            IBehavior.setMutilTextBehaviorText(1, this.text1)
        },
        textareaChnage2() {
            IBehavior.setMutilTextBehaviorText(2, this.text2)
        },
        addChange() {
            this.tabState = false;
        },
        reduce() {
            this.$confirm("您确认关闭动态纹理控制器吗！", "提示", {
                confirmButtonText: "确认",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(async () => {
                    this.textureState = false;
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消"
                    });
                });
        },
        reduce1() {
            this.$confirm("您确认关闭轨道控制器吗！", "提示", {
                confirmButtonText: "确认",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(async () => {
                    this.controllerState = false;
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消"
                    });
                });
        },
        reduce2() {
            this.targetState = false;
        },
        reduce3() {
            this.$confirm("您确认关闭文本控制器吗！", "提示", {
                confirmButtonText: "确认",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(async () => {
                    this.textState = false;
                    IBehavior.removeBehavior(IBehavior.m_mutilTextBehavior)
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消"
                    });
                });
        },
        reduce4() {
            this.$confirm("您确认关闭路径模拟器吗！", "提示", {
                confirmButtonText: "确认",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(async () => {
                    this.pathState = false;
                    IBehavior.removeBehavior(IBehavior.m_curveBehavior)
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消"
                    });
                });
        },
        reduce5() {
            this.$confirm("您确认关闭HUD吗！", "提示", {
                confirmButtonText: "确认",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(async () => {
                    this.hudState = false;
                    IBehavior.removeBehavior(IBehavior.m_hudBehavior)
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消"
                    });
                });
        },
        reduce6() {
            this.$confirm("您确认关闭AI寻路吗！", "提示", {
                confirmButtonText: "确认",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(async () => {
                    this.NavMeshState = false;
                    IBehavior.removeBehavior(IBehavior.m_navMeshBehavior)
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消"
                    });
                });
        },
        tabChange(index) {
            this.cur = index;
            this.tabState = false;
            if (index == 0) {
                this.textureState = true;
                this.list[0].src = checkedTex;
                this.list[1].src = controller;
                this.list[2].src = text;
                this.list[3].src = path;
                this.list[4].src = hud;
                this.list[5].src = Ai;
            } else if (index == 1) {
                this.controllerState = true;
                this.list[0].src = texture;
                this.list[1].src = checkedCon;
                this.list[2].src = text;
                this.list[3].src = path;
                this.list[4].src = hud;
                this.list[5].src = Ai;
            } else if (index == 2) {
                this.list[0].src = texture;
                this.list[1].src = controller;
                this.list[2].src = checkedText;
                this.list[3].src = path;
                this.list[4].src = hud;
                this.list[5].src = Ai;
                this.text0 = "";
                this.text1 = "";
                this.text2 = "";
                this.textState = true;
                IBehavior.addBehavior(IBehavior.m_mutilTextBehavior);
            } else if (index == 3) {
                this.list[0].src = texture;
                this.list[1].src = controller;
                this.list[2].src = text;
                this.list[3].src = checkedPath;
                this.list[4].src = hud;
                this.list[5].src = Ai;
                this.pathState = true;
                IBehavior.addBehavior(IBehavior.m_curveBehavior);
                this.IBehaviorData()
            } else if (index == 4) {
                this.list[0].src = texture;
                this.list[1].src = controller;
                this.list[2].src = text;
                this.list[3].src = path;
                this.list[4].src = CheckedHud;
                this.list[5].src = Ai;
                this.hudState = true;
                IBehavior.addBehavior(IBehavior.m_hudBehavior);
                this.IBehaviorData()
            } else if (index == 5) {
                this.list[0].src = texture;
                this.list[1].src = controller;
                this.list[2].src = text;
                this.list[3].src = path;
                this.list[4].src = hud;
                this.list[5].src = CheckedAi;
                this.NavMeshState = true;
                IBehavior.addBehavior(IBehavior.m_navMeshBehavior);
                this.IBehaviorData()
            }
        },
        selectChange() {
            let data = {
                curve_name: this.select,
                speed: Number(this.speed),
                bloop: this.bloop,
                sim: this.sim,
                percent: Number(this.percent)
            }
            // console.log(data)
            IBehavior.setCurveBehavior(data);
        },
        selectLoop() {
            let data = {
                curve_name: this.select,
                speed: Number(this.speed),
                bloop: this.bloop,
                sim: this.sim,
                percent: Number(this.percent)
            }
            // console.log(data)
            IBehavior.setCurveBehavior(data);
        },
        startChange() {
            let data = {
                curve_name: this.select,
                speed: Number(this.speed),
                bloop: this.bloop,
                sim: this.sim,
                percent: Number(this.percent)
            }
            // console.log(data)
            IBehavior.setCurveBehavior(data);
        },
        animChange() {
            let data = {
                navMeshPath: this.m_navMeshPath,
                maxSpeed: Number(this.m_maxSpeed),
                maxForce: Number(this.m_maxForce),
                nextWaypointDistance: Number(this.m_nextWaypointDistance),
                anim: this.m_b_anim,
                group_anim_name: this.m_group_anim_name,
                idle_anim: this.m_idle_anim,
                run_anim: this.m_run_anim,
                userData: this.m_userData,
            }
            // console.log(data)
            IBehavior.setNavMeshBehavior(data)
        },

        changeNum2() {
            let data = {
                curve_name: this.select,
                speed: Number(this.speed),
                bloop: this.bloop,
                sim: this.sim,
                percent: Number(this.percent)
            }
            // console.log(data)
            IBehavior.setCurveBehavior(data);
        },
        AiNum() {
            let data = {
                navMeshPath: this.m_navMeshPath,
                maxSpeed: Number(this.m_maxSpeed),
                maxForce: Number(this.m_maxForce),
                nextWaypointDistance: Number(this.m_nextWaypointDistance),
                anim: this.m_b_anim,
                group_anim_name: this.m_group_anim_name,
                idle_anim: this.m_idle_anim,
                run_anim: this.m_run_anim,
                userData: this.m_userData,
            }
            // console.log(data)
            IBehavior.setNavMeshBehavior(data)
        },
        shrinkChange() {
            if (this.isActive) {
                this.isActive = false
            } else {
                this.isActive = true;
            }
        },
        shrinkChange1() {
            if (this.isActive1) {
                this.isActive1 = false
            } else {
                this.isActive1 = true;
            }
        },
        shrinkChange2() {
            if (this.isActive2) {
                this.isActive2 = false
            } else {
                this.isActive2 = true;
            }
        },
        shrinkChange3() {
            if (this.isActive3) {
                this.isActive3 = false
            } else {
                this.isActive3 = true;
            }
        },
        shrinkChange4() {
            if (this.isActive4) {
                this.isActive4 = false
            } else {
                this.isActive4 = true;
            }
        },
        shrinkChange5() {
            if (this.isActive5) {
                this.isActive5 = false
            } else {
                this.isActive5 = true;
            }
        },
        shrinkChange6() {
            if (this.isActive6) {
                this.isActive6 = false
            } else {
                this.isActive6 = true;
            }
        },
        changeNum0() {
            let color = this.Color.slice(1);
            let rgba = [
                parseInt('0x' + color.slice(0, 2)),
                parseInt('0x' + color.slice(2, 4)),
                parseInt('0x' + color.slice(4, 6)),
            ];
            let color0 = rgba[0] / 255;
            let color1 = rgba[1] / 255;
            let color2 = rgba[2] / 255;
            let data = {
                m_center: [Number(this.positionX), Number(this.positionY), Number(this.positionZ)],
                m_colorArray: [color0, color1, color2],
                m_text: this.hubText,
                m_size: Number(this.size)
            }
            // console.log(data)
            IBehavior.setHudBehavior(data);
        },
        sizeNum() {
            let color = this.Color.slice(1);
            let rgba = [
                parseInt('0x' + color.slice(0, 2)),
                parseInt('0x' + color.slice(2, 4)),
                parseInt('0x' + color.slice(4, 6)),
            ];
            let color0 = rgba[0] / 255;
            let color1 = rgba[1] / 255;
            let color2 = rgba[2] / 255;
            let data = {
                m_center: [Number(this.positionX), Number(this.positionY), Number(this.positionZ)],
                m_colorArray: [color0, color1, color2],
                m_text: this.hubText,
                m_size: Number(this.size)
            }
            // console.log(data)
            IBehavior.setHudBehavior(data);
        },
        hubChange() {
            let color = this.Color.slice(1);
            let rgba = [
                parseInt('0x' + color.slice(0, 2)),
                parseInt('0x' + color.slice(2, 4)),
                parseInt('0x' + color.slice(4, 6)),
            ];
            let color0 = rgba[0] / 255;
            let color1 = rgba[1] / 255;
            let color2 = rgba[2] / 255;
            let data = {
                m_center: [Number(this.positionX), Number(this.positionY), Number(this.positionZ)],
                m_colorArray: [color0, color1, color2],
                m_text: this.hubText,
                m_size: Number(this.size)
            }
            // console.log(data)
            IBehavior.setHudBehavior(data);
        },
        ColorChange() {
            let color = this.Color.slice(1);
            let rgba = [
                parseInt('0x' + color.slice(0, 2)),
                parseInt('0x' + color.slice(2, 4)),
                parseInt('0x' + color.slice(4, 6)),
            ];
            let color0 = rgba[0] / 255;
            let color1 = rgba[1] / 255;
            let color2 = rgba[2] / 255;
            let data = {
                m_center: [Number(this.positionX), Number(this.positionY), Number(this.positionZ)],
                m_colorArray: [color0, color1, color2],
                m_text: this.hubText,
                m_size: Number(this.size)
            }
            // console.log(data)
            IBehavior.setHudBehavior(data);
        },
        colorImport() {
            let color = this.Color.slice(1);
            let rgba = [
                parseInt('0x' + color.slice(0, 2)),
                parseInt('0x' + color.slice(2, 4)),
                parseInt('0x' + color.slice(4, 6)),
            ];
            let color0 = rgba[0] / 255;
            let color1 = rgba[1] / 255;
            let color2 = rgba[2] / 255;
            let data = {
                m_center: [Number(this.positionX), Number(this.positionY), Number(this.positionZ)],
                m_colorArray: [color0, color1, color2],
                m_text: this.hubText,
                m_size: Number(this.size)
            }
            // console.log(data)
            IBehavior.setHudBehavior(data);
        },
        ActiveColor(value) {
            let value1 = this.transitionColror(value);
            this.Color = value1;
            let color = value1.slice(1);
            let rgba = [
                parseInt('0x' + color.slice(0, 2)),
                parseInt('0x' + color.slice(2, 4)),
                parseInt('0x' + color.slice(4, 6)),
            ];
            let color0 = rgba[0] / 255;
            let color1 = rgba[1] / 255;
            let color2 = rgba[2] / 255;
            let data = {
                m_center: [Number(this.positionX), Number(this.positionY), Number(this.positionZ)],
                m_colorArray: [color0, color1, color2],
                m_text: this.hubText,
                m_size: Number(this.size)
            }
            // console.log(data)
            IBehavior.setHudBehavior(data);
        },
        Focus() {
            ISetting.isInput(true);
        },
        Blur() {
            ISetting.isInput(false);
        },
        //rgb转十六进制
        transitionColror(color) {
            var values = color
                .replace(/rgba?\(/, '')
                .replace(/\)/, '')
                .replace(/[\s+]/g, '')
                .split(',');
            var a = parseFloat(values[3] || 1),
                r = Math.round(a * parseInt(values[0]) + (1 - a) * 255),
                g = Math.round(a * parseInt(values[1]) + (1 - a) * 255),
                b = Math.round(a * parseInt(values[2]) + (1 - a) * 255);
            return "#" +
                ("0" + r.toString(16)).slice(-2) +
                ("0" + g.toString(16)).slice(-2) +
                ("0" + b.toString(16)).slice(-2);
        },
    }
}
</script>
<style scoped>
.box {
    width: 90%;
    height: 440px;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    border-radius: 5px;
    margin-top: 8px;
    margin-left: 70px;
    font-size: 10px;
    background: #fff;
    overflow: hidden;
}

.box3 {
    width: 90%;
    height: 460px;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    border-radius: 5px;
    margin-top: 8px;
    margin-left: 70px;
    font-size: 10px;
    background: #fff;
    overflow: hidden;
}

.box2 {
    width: 90%;
    height: 260px;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    border-radius: 5px;
    margin-top: 8px;
    margin-left: 70px;
    font-size: 10px;
    background: #fff;
    overflow: hidden;
}

.box4 {
    width: 90%;
    height: 500px;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    border-radius: 5px;
    margin-top: 8px;
    margin-left: 70px;
    font-size: 10px;
    background: #fff;
    overflow: hidden;
}

.box5 {
    width: 90%;
    height: 600px;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    border-radius: 5px;
    margin-top: 8px;
    margin-left: 70px;
    font-size: 10px;
    background: #fff;
    overflow: hidden;
}

.anmDiv {
    transition: 1S;

}

.aaa {
    height: 40px;
    transition: 1S;
}

.header {
    display: flex;
    width: 95%;
    height: 40px;
    margin: auto;
    align-items: center;
    color: #000;
    font-weight: bold;
    font-size: 12px;
}

.header p {
    margin-left: 10px;
}

.header i {
    font-weight: bold;
    cursor: pointer;
}

.projection {
    display: flex;
    width: 100%;
    height: 20px;
    align-items: center;
    margin: 20px auto;
    font-size: 12px;
}

.projection .title {
    width: 100px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-weight: bolder;
    overflow: hidden;
}

.projection .textarea {
    width: 70%;
    height: 100px;
    outline: none;
    border: none;
    border-radius: 6px;
    color: #808080;
    resize: none;
    scrollbar-width: none;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    margin-left: 0px;
}

.txt_box {
    width: 220px;
    height: 30px;
    box-shadow: 0px 0px 2px 0px rgba(33, 35, 49, 0.1);
    border-radius: 6px;
    display: flex;
    align-items: center;
}

.projection .input {
    width: 210px;
    height: 28px;
    outline: none;
    color: #808080;
    border: none;
    border-radius: 4px;
    text-indent: 5px;
}

.videoCover {
    display: flex;
    width: 100%;
    height: 150px;
    align-items: center;
    margin: auto;
    font-size: 12px;
    margin-bottom: 230px;
}

.videoCover .title {
    width: 70px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-weight: bolder;
    overflow: hidden;
}

.shadow {
    display: flex;
    width: 90%;
    height: 25px;
    align-items: center;
    margin-top: 15px;
}

.shadow .title {
    width: 100px;
    height: 20px;
    line-height: 20px;
    background: #fff;
    text-align: center;
    font-weight: bold;
    overflow: hidden;
}

.input1:checked {
    background: #1673ff
}

.input1 {
    width: 16px;
    height: 16px;
    outline: none;
    background-color: rgba(125, 128, 133, 0.4);
    border: solid 1px #dddddd;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    font-size: 0.8rem;
    padding: 0;
    position: relative;
    display: inline-block;
    vertical-align: top;
    cursor: default;
    -webkit-appearance: none;
    -webkit-user-select: none;
    user-select: none;
    -webkit-transition: background-color ease 0.1s;
    transition: background-color ease 0.1s;
    outline: none;
}

.input1:checked::after {
    content: '';
    top: 3px;
    left: 3px;
    position: absolute;
    background: transparent;
    border: #fff solid 2px;
    border-top: none;
    border-right: none;
    height: 4px;
    width: 6px;
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.ipt_box {
    width: 65px;
    height: 25px;
    /* margin-left: 30px; */
    background: #FFFFFF;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.add {
    margin: auto;
    cursor: pointer;
}

.add i {
    font-size: 30px;
    margin: auto;
}

.tab {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 62%);
    width: 140px;
    height: 240px;
    border-radius: 10px;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
}

.tab .list {
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
    font-size: 12px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tab .list .img {
    height: 100%;
    display: flex;
    align-items: center;
    justify-items: center;
}

.tab .list img {
    width: 15px;
    height: 15px;
}

.tab .list .text {
    width: 70%;
    height: 100%;
}

.list:hover {
    background: #cddfff;
}

.active {
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    color: royalblue;
}

.del {
    position: absolute;
    right: 20px;
}

.del i {
    font-size: 18px;
}

.textarea_box {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.textarea_box1 {
    width: 90%;
    display: flex;
    justify-content: space-around;
    margin: auto;
}

.textarea_box1 .title {
    width: 60px;
    height: 20px;
    line-height: 20px;
    background: #fff;
    text-align: center;
    font-weight: bold;
    overflow: hidden;
}

.textarea {
    width: 90%;
    height: 100px;
    outline: none;
    border: none;
    border-radius: 6px;
    color: #808080;
    resize: none;
    scrollbar-width: none;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    margin: auto;
}

.textarea_box1 .textarea {
    width: 80%;
    height: 100px;
    outline: none;
    border: none;
    border-radius: 6px;
    color: #808080;
    resize: none;
    scrollbar-width: none;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    margin-left: 30px;
}

.textarea /deep/ .el-textarea__inner {
    height: 100px !important;
}

textarea::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 8px;
    height: 1px;
}

textarea::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 8px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #BEC2C9;
}

textarea::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    /* box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2); */
    border-radius: 10px;
    background: #eff3f4;
}

.el-message-box__headerbtn .el-message-box__close {
    font-size: 8px !important;
}

.select {
    width: 100%;
    height: 28px;
    outline: none;
    color: #808080;
    border: none;
    border-radius: 4px;
}

.select /deep/ .el-input {
    width: 100% !important;
    height: 28px !important;
    outline: none !important;
}

.select /deep/ .el-input__inner {
    width: 100% !important;
    height: 28px !important;
    outline: none !important;
}

.select /deep/ .el-select__caret {
    height: 100% !important;
    line-height: 28px !important;
}

.slider {
    width: 350px;
    /* margin-left: 20px; */
    /* margin-right: 10px; */
}

.loction1 {
    width: 90%;
    margin: 20px auto;
    display: flex;
    height: 25px;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
}

.loction1 .title {
    width: 60px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    font-weight: bolder;
    overflow: hidden;
}

.axis {
    width: 24%;
    height: 100%;
    display: flex;
    align-items: center;
    background-color: #F7F7F7;
    border-radius: 5px;

}

.axis p {
    width: 22px;
    height: 18px;
    line-height: 20px;
    text-align: center;
    color: #929292;
    background-color: #fff;
    margin-left: 2px;
    border-radius: 4px;
    float: left;
    color: #000000;
    font-size: 12px;
}

.axis input {
    width: 90%;
    height: 18px;
    text-align: center;
    outline: none;
    border: none;
    background-color: #F7F7F7;
    border-radius: 4px;
    font-weight: bold;
    font-size: 8px;
}

.x-axis p {
    background-color: #F20004;
}

.y-axis p {
    background-color: #43BD00;
}

.z-axis p {
    background-color: #5180E9;
}

.color {
    display: flex;
    width: 90%;
    height: 40px;
    align-items: center;
    /* margin-top: 20px; */
    margin: auto;
    margin-bottom: 10px;
}

.color .title {
    width: 60px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-weight: bold;
    overflow: hidden;
}

.color>>>.el-color-picker__trigger {
    height: 30px;
    width: 245px;
    margin-top: 5px;
    margin-left: 30px;
}

.import_color {
    width: 70px;
    height: 30px;
    box-shadow: 0px 0px 2px 0px rgba(33, 35, 49, 0.1);
    border-radius: 6px;
    display: flex;
    text-align: center;
    align-items: center;
    margin-left: 10px;
}

.import_color input {
    width: 65px;
    height: 28px;
    outline: none;
    border: none;
    color: #808080;
    border-radius: 5px;
    text-align: center;
}

.loction {
    width: 100%;
    /* margin: 20px auto; */
    display: flex;
    height: 25px;
    align-items: center;
    justify-content: left;
    overflow: hidden;
    margin-top: 10px;
}

.loction .title {
    width: 100px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    font-weight: bolder;
    overflow: hidden;
}
</style>
<style>
.picker .el-input__inner {
    display: none !important;
}

.el-color-picker__mask {
    width: 0px !important;
}
</style>