import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Runtime from "../views/layout/Runtime.vue"



Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    // redirect: '/login'
    redirect: '/OfficialWebsite/HomePage/HomePageContent'
    


    

  },

  {
    path: '/Home',
    name: 'Home',
    component: Home
  },
  {
    path: "/Runtime",
    name: "Runtime",
    component: Runtime
  },
  {
    // path: '/Project',
    path: '/index/user/User',


    name: 'Project',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // path:'/Project',
    component: () => import(/* webpackChunkName: "about" */ '../views/manager/Project.vue')
  },
  {
    // path: '/Project',
    path: '/index/user/ChainedAddress',


    name: 'ChainedAddress',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // path:'/Project',
    component: () => import(/* webpackChunkName: "about" */ '../views/manager/ChainedAddress.vue')
  },



  
  {
    path: '/login',
    // path: '/',

    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/manager/Login.vue')

  },

  //官网
  {
    path: '/OfficialWebsite/HomePage',
    // path: '/',

    name: 'HomePage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // redirect: "/OfficialWebsite/HomePage/HomePageContent",

    // component: () => import(/* webpackChunkName: "about" */ '../views/OfficialWebsite/FirstHomPage.vue'),
    component: () => import(/* webpackChunkName: "about" */ '../views/OfficialWebsite/HomePage.vue'),


    

    children: [
      //官网主页
      {
        path: '/OfficialWebsite/HomePage/HomePageContent',
        name: "HomePageContent",
        component: () => import(/* webpackChunkName: "about" */ '../views/OfficialWebsite/HomePageContent.vue'),
      },
      //学习社区
      {
        path: '/OfficialWebsite/HomePage/LearningCommunity',
        name: "LearningCommunity",
        component: () => import(/* webpackChunkName: "about" */ '../views/OfficialWebsite/LearningCommunity.vue'),

      },

      //学习视频列表
      {
        path: '/OfficialWebsite/HomePage/LearningVideoList',
        name: "LearningVideoList",
        component: () => import(/* webpackChunkName: "about" */ '../views/OfficialWebsite/LearningVideoList.vue'),

      },
      //官方资源
      {
        path: '/OfficialWebsite/HomePage/OfficialResources',
        name: "OfficialResources",
        component: () => import(/* webpackChunkName: "about" */ '../views/OfficialWebsite/OfficialResources.vue'),

      }
      ,
      //功能介绍
      {
        path: '/OfficialWebsite/HomePage/introduce',
        name: "introduce",
        component: () => import(/* webpackChunkName: "about" */ '../views/OfficialWebsite/introduce.vue'),

      },
      //案例展示
      {
        path: '/OfficialWebsite/HomePage/case',
        name: "case",
        component: () => import(/* webpackChunkName: "about" */ '../views/OfficialWebsite/case.vue'),

      },//文档
      {
        path: '/OfficialWebsite/HomePage/document',
        name: "document",
        component: () => import(/* webpackChunkName: "about" */ '../views/OfficialWebsite/document.vue'),

      }
      ,//我们
      {
        path: '/OfficialWebsite/HomePage/ourselves',
        name: "ourselves",
        component: () => import(/* webpackChunkName: "about" */ '../views/OfficialWebsite/ourselves.vue'),

      }

    ]

  },
  //服务条款
  {
    path: '/termsOfService',
    name: 'termsOfService',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/service/UserService.vue')

  },
  //隐私政策
  {
    path: '/privacyPolicy',
    name: 'privacyPolicy',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/service/UserPrivacy.vue')

  },
  //项目条款
  {
    path: '/TermsOfProject',
    name: 'TermsOfProject',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/service/TermsOfProject.vue')

  },
  {
    path: "/PersonalData",
    name: "PersonalData",
    component: () => import('../views/manager/PersonalData.vue')
  },

//  云店-分享
  {
    path: '/shareStorefront',
    name: 'shareStorefront',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/PlugIn/CloudStores/cloudStorefront/shareStorefront.vue')

  },

  {
    path: '/Boxselection',
    name: 'Boxselection',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/PlugIn/CloudStores/cloudStorefront/Boxselection.vue')

  },



  

//  云店-test
{
  path: '/storefront',
  name: 'storefront',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "about" */ '../views/PlugIn/CloudStores/cloudStorefront/storefront.vue')

},

//  云店-首页
{
  path: '/PlugIn/CloudStores/FileDirectory',
  name: 'FileDirectory',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "about" */ '../views/PlugIn/CloudStores/FileDirectory.vue')
},

//  云店-编辑

{
  path: '/PlugIn/CloudStores/compileDirectory',
  name: 'compileDirectory',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "about" */ '../views/PlugIn/CloudStores/CompileDirectoryNew.vue')

},

//  实景云店-编辑

{
  path: '/PlugIn/CealisticCloudStores/compileDirectory',
  name: 'compileDirectory',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "about" */ '../views/PlugIn/CealisticCloudStores/CompileDirectoryNew.vue')

},
//  cad插件-首页

{
  path: '/PlugIn/Cad/CadHome',
  name: 'CadHome',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "about" */ '../views/PlugIn/Cad/CadHome.vue')

},







]







const router = new VueRouter({
  routes
})


router.beforeEach((to, from, next) => {
  // to 将要访问的路径
  // from 代表从哪一个路径跳转而来
  // next 是一个函数，表示放行
  // next() 放行 next('/login') 跳转
  if (to.path === '/login') return next();
  if (to.path === '/') return next();


  if (to.path === '/termsOfService') return next();
  if (to.path === '/privacyPolicy') return next();
  if (to.path === '/TermsOfProject') return next();
  if (to.path === '/OfficialWebsite/HomePage') return next();
  if (to.path === '/OfficialWebsite/HomePage/HomePageContent') return next();
  if (to.path === '/OfficialWebsite/HomePage/LearningCommunity') return next();
  if (to.path === '/OfficialWebsite/HomePage/LearningVideoList') return next();
  if (to.path === '/OfficialWebsite/HomePage/OfficialResources') return next();
  if (to.path === '/OfficialWebsite/HomePage/introduce') return next();
  if (to.path === '/OfficialWebsite/HomePage/case') return next();
  if (to.path === '/OfficialWebsite/HomePage/document') return next();
  if (to.path === '/OfficialWebsite/HomePage/ourselves') return next();

  if (to.path === '/index/user/ChainedAddress') return next();
  if (to.path === '/shareStorefront') return next();
  if (to.path === '/Boxselection') return next();

  


  if (to.path === '/Runtime') return next();

  
  //获取token
  const tokenStr = localStorage.getItem('token')

  const userSig=localStorage.getItem("userSig")

  
  
  if (!userSig) {
    localStorage.clear()
    return next('/OfficialWebsite/HomePage/HomePageContent')
  }

  //没有token，强制跳转登录页
  if (!tokenStr) return next('/OfficialWebsite/HomePage/HomePageContent')
  // 放行
  next()
});

export default router
