import * as THREE from 'three';

class NavMeshBehavior {
    constructor() {
        this.m_navMeshPath = "";             //寻路资源URL
        this.m_maxSpeed = 1.5;               //可以移动的最大速度
        this.m_maxForce = 10;                //可以移动动力力量
        this.m_nextWaypointDistance = 0.5;   //寻点距离
        this.m_b_anim = false;               //动画开关
        this.m_group_anim_name = "";         //动画骨骼节点
        this.m_idle_anim = "";               //待机动画
        this.m_run_anim = "";                //移动动画
        this.m_userData = ""                 //自定义数据
        this.m_offset = [0, 0, 0]
    }

    getJson()
    {
        var data = {
           m_navMeshPath: this.m_navMeshPath,
           m_maxSpeed: this.m_maxSpeed,
           m_maxForce: this.m_maxForce,
           m_nextWaypointDistance: this.m_nextWaypointDistance,
           m_idle_anim: this.m_idle_anim,
           m_run_anim: this.m_run_anim,
           m_group_anim_name: this.m_group_anim_name,
           m_b_anim: this.m_b_anim,
           m_userData: this.m_userData,
           m_offset: this.m_offset
        }
        return data;
    }

    setJson(json)
    {
        this.m_navMeshPath = json.m_navMeshPath;
        this.m_maxSpeed = json.m_maxSpeed;
        this.m_maxForce = json.m_maxForce;
        this.m_nextWaypointDistance = json.m_nextWaypointDistance;
        this.m_idle_anim = json.m_idle_anim;
        this.m_run_anim = json.m_run_anim;
        this.m_group_anim_name = json.m_group_anim_name;
        this.m_b_anim = json.m_b_anim;
        if(json.m_offset)
        {
            this.m_offset = json.m_offset;
        }
     
    }
    init(object){

    }
    destory()
    {
 
    }
}

export { NavMeshBehavior };