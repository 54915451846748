class CameraData{
    constructor() {
        this.m_id = null;
        this.m_FOV=0;
        // 垂直角度
        this.m_vertical=90;
        // 水平视角
        this.m_horizontal=90;
        this.m_initialCamera=false;
        this.m_name="";
        this.m_img=["","","","","",""];
        this.m_pos = {}
        this.m_Far=100
        this.m_Near=45


    }
    getCameraData(){
        let obj={
            m_id:this.m_id,
            m_FOV: this.m_FOV,
            m_vertical:this.m_vertical,
            m_horizontal:this.m_horizontal,
            m_initialCamera: this.m_initialCamera,
            m_Far:this.m_Far,

            m_Near:this.m_Near,
 


        }
        return obj;
    }

    setCameraData(data){

        this.m_FOV=data.m_FOV;
        this.m_vertical=data.m_vertical;
        this.m_horizontal=data.m_horizontal;
        this.m_pos.x = data.m_pos.x
        this.m_pos.y = data.m_pos.y
        this.m_pos.z = data.m_pos.z
        this.m_img=data.m_img
        this.m_Far=data.m_Far
        this.m_Near=data.m_Near


        

    }
    
    
}
export { CameraData };
