import OSS from 'ali-oss'
import { MainObjects } from "../common/MainObjects"

class OssManager {
    constructor(bucket) {
        let OssTokenObg = localStorage.getItem("OssTokenObg")
        if (OssTokenObg) {
            OssTokenObg = JSON.parse(OssTokenObg)

            this.m_client = new OSS({
                region: "oss-cn-hangzhou",

                accessKeyId: OssTokenObg.accessKeyId,
                accessKeySecret: OssTokenObg.accessKeySecret,
                stsToken: OssTokenObg.stsToken,

                bucket: bucket,
                secure: true,
            });
            this.m_updateCount = 1;
            this.m_process = 0;
            this.f_progressCallback = null;
            this.f_finishCallback = null;
            this.m_dataList = new Array();

        }else{
            console.error("需要获取osstoken")
        }

    }

    upload(file, storeFolder) {
        var _this = this;
        var process = 0;
        const storeAs = storeFolder + file.name;
        // 调用上传接口
        this.m_client.multipartUpload(storeAs, file, {
            progress(p) {
                var pp = p - process;
                _this.m_process = _this.m_process + pp;
                process = p;
                if (_this.f_progressCallback != null) {
                    _this.f_progressCallback(_this.m_process / _this.m_updateCount);
                }
            }
        }).then(function (response) {
            if (response.res.status === 200) {
                var particularsimg = _this.m_client.signatureUrl(response.name);
                var data = {
                    "url": particularsimg,
                    'name': response.name,
                    'size': response.res.size,
                    'pureName': file.name.substr(0,file.name.indexOf('.'))
                }
                _this.m_dataList.push(data);
                if (_this.m_dataList.length == _this.m_updateCount) {
                    if (_this.f_finishCallback != null) {
                        _this.f_finishCallback(_this.m_dataList);
                    }
                }
            }
        }).catch(function (err) {
            console.log("UPDATE OSS ERROR", err);
        });
    }

    uploadFileList(_files, _storeFolder) {
        this.m_updateCount = _files.length;
        for (let index = 0; index < _files.length; index++) {
            const _file = _files[index];
            this.upload(_file, _storeFolder);
        }
    }
    

    copy(srcUrl, dstUrl) {
        var _this = this;
        this.m_client.copy(dstUrl, srcUrl).then(function (response) {
            if (response.res.status === 200) {
                _this.m_dataList.push(response);
                if (_this.m_dataList.length == _this.m_updateCount) {
                    if (_this.f_finishCallback != null) {
                        _this.f_finishCallback();
                    }
                }
            }
        }).catch(function (err) {
            console.log("COPY OSS ERROR", err);
        });
    }

    copyFileList(srcUrlList, dstUrlList) {
        this.m_updateCount = srcUrlList.length;
        for (let index = 0; index < srcUrlList.length; index++) {
            const srcUrl = srcUrlList[index];
            const dstUrl = dstUrlList[index];
            this.copy(srcUrl, dstUrl);
        }
    }

    uploadImageBuffer(name, buffer, callback) {
        var _this = this;
        var storeAs = name;
        this.m_client.put(storeAs, buffer).then(function (response) {
            if (response.res.status === 200) {
                var data = {
                    'name': response.name,
                    'size': response.res.size,
                }
                callback(data);
            }
        }).catch(function (err) {
            console.log("PUT IMAGE OSS ERROR", err);
        });
    }

    uploadJs(name, js){
        var _this = this;
        var storeAs = name;
        var blob = new Blob([js], { type: 'application/js' });
        this.m_client.put(storeAs, blob).then(function (response) {
            if (response.res.status === 200) {
                var data = {
                    'name': response.name,
                    'size': response.res.size,
                }
                if (_this.f_finishCallback != null) {
                    _this.f_finishCallback(data);
                }
            }
        }).catch(function (err) {
            console.log("PUT OSS ERROR", err);
        });
    }

    uploadJson(name, json) {
        var _this = this;
        var storeAs = name;
        var blob = new Blob([json], { type: 'application/javascript' });
        this.m_client.put(storeAs, blob).then(function (response) {
            if (response.res.status === 200) {
                var data = {
                    'name': response.name,
                    'size': response.res.size,
                }
                if (_this.f_finishCallback != null) {
                    _this.f_finishCallback(data);
                }
            }
        }).catch(function (err) {
            _this.f_finishCallback(null)

            console.log("PUT OSS ERROR", err);
        });
    }

    downloadJson(name) {
        var _this = this;
        this.m_client.get(name).then(function (response) {
       

            if (response.res.status === 200) {

                const newBlob = new Blob([response.content], { type: response.res.headers['content-type'] });
                var reader = new FileReader();
                reader.readAsText(newBlob, 'utf-8');
                reader.onload = function (e) {
                    let readerres = reader.result;
                    let parseObj = JSON.parse(readerres);
                    
                    if (_this.f_finishCallback != null) {
                        _this.f_finishCallback(parseObj);


                    }
                }
            }
        }).catch(function (err) {
            console.log("DOWNLOAD OSS ERROR", err);
        });
    }

    downloadJs(name) {
        var _this = this;
        this.m_client.get(name).then(function (response) {
            if (response.res.status === 200) {

                const newBlob = new Blob([response.content], { type: response.res.headers['content-type'] });
                var reader = new FileReader();
                reader.readAsText(newBlob, 'utf-8');
                reader.onload = function (e) {
                    let readerres = reader.result;
                    if (_this.f_finishCallback != null) {
                        _this.f_finishCallback(readerres);
                    }
                }
            }
        }).catch(function (err) {
            console.log("DOWNLOAD OSS ERROR", err);
        });
    }

    deleteFiles(urls) {
        this.m_client.deleteMulti(urls);
    }

    getPreviewFloder() {
        const storeAs = MainObjects.Network.m_folderName + MainObjects.Network.m_resName + MainObjects.Network.m_projectId + "/" + MainObjects.Network.m_previewFloder + "/" + MainObjects.Network.m_userPhone + "/";
        return storeAs;
    }

    getSqlFloder(sqlId) {
        const storeAs = MainObjects.Network.m_folderName + MainObjects.Network.m_resName + MainObjects.Network.m_projectId + "/" + sqlId + "/";
        return storeAs;
    }
    getUserFloder(userId){
        const storeAs = MainObjects.Network.m_folderName + "user/" + userId + "/";
        return storeAs;
    }
    getS2CFolder(name) {
        return MainObjects.Network.m_folderName + 's2c/' + name + '/'
    }


    //云店图片声音视频上传路径
    getCloudShopFloder(action,shopId){
        const storeAs = MainObjects.Network.m_folderName + "CloudShop/"+ shopId + "/"+action+"/";
        return storeAs;
    }
    getCloudShopJson(shopId){
        const storeAs = MainObjects.Network.m_folderName + "CloudShop/"+"json/"+ shopId+"/";
        return storeAs;
    }
    // cad插件 文件上传
    getCADFloder(action,shopId){
        const storeAs = MainObjects.Network.m_folderName + "CAD/"+ shopId + "/"+action+"/";
        return storeAs;
    }

    getListFile(dir)
    {
        var _this = this;
        this.m_client.list({
            prefix: dir,
            // 设置正斜线（/）为文件夹的分隔符。
            delimiter: '/'
        }).then(function (response) {
            if (response.res.status === 200) {
                if (_this.f_finishCallback != null) {
                    _this.f_finishCallback(response.objects);
                }
            }
        }).catch(function (err) {
            _this.f_finishCallback(null)
            console.log("DOWNLOAD OSS ERROR", err);
        });
    }





}

export { OssManager };