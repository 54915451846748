/**
 * @author HypnosNova / https://www.threejs.org.cn/gallery
 * This is a class to check whether objects are in a selection area in 3D space
 */

import {
	Frustum,
	Vector3,
	Box3,
} from "three";
import { MainObjects } from "../common/MainObjects"
import * as THREE from 'three';

var SelectionBox = (function () {

	var frustum = new Frustum();
	var center = new Vector3();
	var box = new Box3();
	var _v1 = new Vector3();

	function SelectionBox() {

		this.startPoint = new Vector3();
		this.endPoint = new Vector3();
		this.collection = [];
		const geometry = new THREE.PlaneGeometry( 100000, 100000 );
        const material = new THREE.MeshBasicMaterial( {color: 0xffff00, side: THREE.DoubleSide} );
        this.plane = new THREE.Mesh( geometry, material );
        MainObjects.Scene.m_helperGroup.add( this.plane );
		this.plane.visible = false;
		this.raycaster = new THREE.Raycaster();
        this.pointer = new THREE.Vector2();
		this.mode = 0;
		const geometry_cube = new THREE.BoxGeometry( 1, 1, 1 );
        const material_cube = new THREE.MeshBasicMaterial( {color: 0x00ff00, side: THREE.DoubleSide} );
        this.cube = new THREE.Mesh( geometry_cube, material_cube );
		this.cube.visible = false;
		MainObjects.Scene.m_helperGroup.add( this.cube );
		this.deep = Number.MAX_VALUE;
	}

	SelectionBox.prototype.select = function (startPoint, endPoint) {

		this.startPoint = startPoint || this.startPoint;
		this.endPoint = endPoint || this.endPoint;
		this.collection = [];
		const d = this.startPoint.distanceTo( this.endPoint );
		if(d < 0.01)
		{
			return this.collection;
		}
		if(this.endPoint.x > this.startPoint.x)
		{
			this.mode = 0;
			MainObjects.Blueprint.m_selectListenerBp.clearSelectObjects();
		}
		else
		{
			this.mode = 1;
		}

		this.updateFrustum(this.startPoint, this.endPoint);
		this.searchChildInFrustum(frustum);

		return this.collection;

	};

	SelectionBox.prototype.updateFrustum = function (startPoint, endPoint) {

		startPoint = startPoint || this.startPoint;
		endPoint = endPoint || this.endPoint;
		var camera = MainObjects.Camera.m_renderCamera;

		if(camera.isPerspectiveCamera){
			camera.updateProjectionMatrix();
			camera.updateMatrixWorld();
	
			var tmpPoint = startPoint.clone();
			tmpPoint.x = Math.min(startPoint.x, endPoint.x);
			tmpPoint.y = Math.max(startPoint.y, endPoint.y);
			endPoint.x = Math.max(startPoint.x, endPoint.x);
			endPoint.y = Math.min(startPoint.y, endPoint.y);
	
			var vecNear = camera.position.clone();
			var vecTopLeft = tmpPoint.clone();
			var vecTopRight = new Vector3(endPoint.x, tmpPoint.y, 0);
			var vecDownRight = endPoint.clone();
			var vecDownLeft = new Vector3(tmpPoint.x, endPoint.y, 0);
			vecTopLeft.unproject(camera);
			vecTopRight.unproject(camera);
			vecDownRight.unproject(camera);
			vecDownLeft.unproject(camera);
	
			var vectemp1 = vecTopLeft.clone().sub(vecNear);
			var vectemp2 = vecTopRight.clone().sub(vecNear);
			var vectemp3 = vecDownRight.clone().sub(vecNear);
			vectemp1.normalize();
			vectemp2.normalize();
			vectemp3.normalize();
	
			vectemp1.multiplyScalar(this.deep);
			vectemp2.multiplyScalar(this.deep);
			vectemp3.multiplyScalar(this.deep);
			vectemp1.add(vecNear);
			vectemp2.add(vecNear);
			vectemp3.add(vecNear);
	
			var planes = frustum.planes;
	
			planes[0].setFromCoplanarPoints(vecNear, vecTopLeft, vecTopRight);
			planes[1].setFromCoplanarPoints(vecNear, vecTopRight, vecDownRight);
			planes[2].setFromCoplanarPoints(vecDownRight, vecDownLeft, vecNear);
			planes[3].setFromCoplanarPoints(vecDownLeft, vecTopLeft, vecNear);
			planes[4].setFromCoplanarPoints(vecTopRight, vecDownRight, vecDownLeft);
			planes[5].setFromCoplanarPoints(vectemp3, vectemp2, vectemp1);
			planes[5].normal.multiplyScalar(- 1);
		}
		else{
						
			this.cube.scale.set(0, 0, 0);
			this.cube.updateMatrixWorld();
			this.cube.geometry.computeBoundingBox();
			this.cube.geometry.computeBoundingSphere();

			var pos = new THREE.Vector3();
			var target = new THREE.Vector3();
			camera.getWorldDirection(target);
			pos.copy(camera.position);
			pos = pos.add(target.multiplyScalar(100));
			this.plane.position.set(pos.x, pos.y, pos.z);
			this.plane.lookAt(camera.position);
			this.plane.updateMatrixWorld();
			
			var start = new THREE.Vector3();
			var end = new THREE.Vector3();
			var conter = new THREE.Vector3();
	
			this.pointer.x = startPoint.x;
			this.pointer.y = startPoint.y;
			// update the picking ray with the camera and pointer position
			this.raycaster.setFromCamera( this.pointer, camera );
			// calculate objects intersecting the picking ray
			var intersects = this.raycaster.intersectObject( this.plane );
			if(intersects.length > 0)
			{
				start.copy(intersects[0].point);
			}
	
			this.pointer.x = endPoint.x;
			this.pointer.y = endPoint.y;
			// update the picking ray with the camera and pointer position
			this.raycaster.setFromCamera( this.pointer, camera );
			// calculate objects intersecting the picking ray
			intersects = this.raycaster.intersectObject( this.plane );
			if(intersects.length > 0)
			{
				end.copy(intersects[0].point);
			}
	
			this.pointer.x = endPoint.x;
			this.pointer.y = startPoint.y;
			// update the picking ray with the camera and pointer position
			this.raycaster.setFromCamera( this.pointer, camera );
			// calculate objects intersecting the picking ray
			intersects = this.raycaster.intersectObject( this.plane );
			if(intersects.length > 0)
			{
				conter.copy(intersects[0].point);
			}
	
			var center = new THREE.Vector3();
			center = center.copy(start).add(end).multiplyScalar(0.5);
			this.cube.position.set(center.x, center.y, center.z);
			
			var dir = new THREE.Vector3();
			dir.copy(center).add(target.multiplyScalar(-1));
			this.cube.lookAt(dir);
	
			this.cube.updateMatrixWorld();
			var z = 1000;
			var x = conter.distanceTo(start);
			var y = conter.distanceTo(end);
			
			this.cube.scale.set(x, y, z);
			this.cube.updateMatrixWorld();
			this.cube.geometry.computeBoundingBox();
			this.cube.geometry.computeBoundingSphere();
		}

	};

	SelectionBox.prototype.searchChildInFrustum = function (frustum) {

		var camera = MainObjects.Camera.m_renderCamera;
		var frustum_box;
		if(camera.isPerspectiveCamera){
			frustum_box = frustum;
		}
		else
		{
			frustum_box = new THREE.Box3();
			frustum_box.copy( this.cube.geometry.boundingBox ).applyMatrix4( this.cube.matrixWorld );
		}
	
		for (let index = 0; index < MainObjects.Blueprint.m_sceneManagerBp.m_gameScene.m_selectAllMeshList.length; index++) {
			const object = MainObjects.Blueprint.m_sceneManagerBp.m_gameScene.m_selectAllMeshList[index];
			if (object.material !== undefined) {

				object.geometry.computeBoundingBox();
				object.geometry.computeBoundingSphere();

				center.copy(object.geometry.boundingSphere.center);
				center.applyMatrix4(object.matrixWorld);

				if(this.mode == 0)
				{
					if (frustum_box.containsPoint(center)) {

						box.copy(object.geometry.boundingBox).applyMatrix4(object.matrixWorld);
						if(!camera.isPerspectiveCamera){
							if(frustum_box.containsBox(box))
							{
								this.collection.push(object);
							}
						}
						else
						{
							this.collection.push(object);
						}
					}
				}
				else
				{
					if (frustum_box.containsPoint(center)) {

						this.collection.push(object);
	
					}
					else {
						box.copy(object.geometry.boundingBox).applyMatrix4(object.matrixWorld);
						if (frustum_box.intersectsBox(box)) {
	
							//trangle
							const objPos = object.geometry.attributes.position;
							const matrixWorld = object.matrixWorld;
							for (let j = 0, jl = objPos.count; j < jl; j++) {
	
								_v1.set(objPos.getX(j), objPos.getY(j), objPos.getZ(j)).applyMatrix4(matrixWorld);
								if (frustum_box.containsPoint(_v1)) {
	
									this.collection.push(object);
									break;
								}
							}
						}
					}
				}

				
			}
		}
	};

	return SelectionBox;

})();

export { SelectionBox };