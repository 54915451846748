<template>
    <div>
        <div class="box" :class="isActive?'anmDiv':'aaa'">
           <div class="header">
               <div @click="shrinkChange"><i class="el-icon-arrow-down"></i> </div>
               <p>胶囊碰撞器</p>
           </div>
           <div class="loction">
                <div>中心</div>
                <div class="x-axis axis">
                    <p>X</p>
                    <input/>
                </div>
                <div class="y-axis axis">
                    <p>Y</p>
                    <input/>
                </div>
                <div class="z-axis axis">
                    <p>Z</p>
                    <input/>
                </div>
            </div>
            <div class="strength">
               <div class="title">半径</div>
               <div class="txt_box">
                    <input type="text" name="" id="">
                </div>
           </div>
           <div class="strength">
               <div class="title">高度</div>
               <div class="txt_box">
                    <input type="text" name="" id="">
                </div>
           </div>
        </div>
    </div>
</template>
<script>
export default {
    name:"CapsuleCollider",
    data(){
        return{
            isActive:true,
            value:50,
        }
    },
    methods:{
        shrinkChange(){
            if (this.isActive) {
              this.isActive = false
            } else {
              this.isActive = true;
            }
        }
    }
}
</script>
<style scoped>
.box{
    width: 80%;
    height: 220px;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    border-radius: 5px;
    margin-top:8px;
    margin-left: 70px;
    font-size: 10px;
    background: #fff;
    overflow: hidden;
}
.anmDiv{
transition: 1S;
 
}
.aaa{
height: 40px;
transition: 1S;
}
.header{
    display: flex;
    width: 90%;
    height: 40px;
    margin: auto;
    align-items: center;
    color: #000;
    font-weight: bold;
    font-size: 12px;
}
.header p{
    margin-left: 10px;
}
.header i{
    font-weight: bold;
    cursor: pointer;
}
.loction{
    width: 90%;
    margin: auto;
    display: flex;
    height: 25px;
    align-items: center;
    justify-content: space-around;
    font-weight: bolder;
    margin-top: 20px;
}
.axis{
    width: 25%;
    height: 100%;
    display: flex;
    align-items: center;
    background-color:#F7F7F7;
    border-radius: 5px;
}
.axis p{
    width: 22px;
    height: 18px;
    line-height: 18px;
    color: #929292;
    background-color: #fff;
    margin-left: 2px;
    border-radius: 6px;
    float: left;
    text-align: center;
    color: #000;
}
.axis input{
    width:45px;
    height: 18px;
    text-align: center;
    outline: none;
    border: none;
    background-color: #F7F7F7;
    border-radius: 4px;
    font-weight: bold;
}
.strength{
    display: flex;
    width: 100%;
    height: 30px;
    align-items: center;
    margin-top: 25px;
}
.strength .title{
    width: 70px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-weight: bold;
    overflow: hidden;
}
.txt_box{
    width: 240px;
    height: 30px;
    box-shadow: 0px 0px 2px 0px rgba(33, 35, 49, 0.1);
    border-radius: 6px;
    display: flex;
    align-items: center;
}
.strength input{
    width: 230px;
    height: 28px;
    outline: none;
    color: #808080;
    border: none;
    border-radius:4px;
}
.x-axis p{
    background-color:#F20004;
}
.y-axis p{
    background-color: #43BD00;
}
.z-axis p{
    background-color: #5180E9;
}
</style>