import { GameObject } from "../object/GameObject";
import * as THREE from 'three';

class WaterData {
    constructor() {
        this.m_color = new THREE.Color(1, 1, 1);
        this.m_scale = 4;
        this.m_flowX = 1,
		this.m_flowY = 1
    }

    setJson(json)
    {
        this.m_color.fromArray(json.m_color);
        this.m_scale = json.m_scale;
        this.m_flowX = json.m_flowX;
        this.m_flowY = json.m_flowY;
    }

    getJson()
    {
        var json = {
            m_color: [this.m_color.r, this.m_color.g, this.m_color.b],
            m_scale: this.m_scale,
            m_flowX: this.m_flowX,
            m_flowY: this.m_flowY
        }

        return json;
    }

    getJsonForUI()
    {
        var json = {
            type:GameObject.GameObjectType.e_WATER_BASE,
            m_color: "#" + this.m_color.getHexString(),
            m_scale: this.m_scale,
            m_flowX: this.m_flowX,
            m_flowY: this.m_flowY
        }

        return json;
    }
}
export { WaterData };