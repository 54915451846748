<template>
  <div class="behaviors-editor">
    <div>
      <el-dropdown  >
        <span class="el-dropdown-link">
          <i class="el-icon-plus"></i>新增行为
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-for="(entry, index) in BehaviorTypes" :key="index">
            <div @click="() => onAddNewBehavior(entry)">{{ t(entry) }}</div>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div>
      <BehaviorItemEditor
          v-for="(behavior, index) in this.behaviors"
          :key="index"
          :behavior="behavior"
          :updateProperties="updateProperties"
          :onDelete="() => deleteBehavior(index)"/>
    </div>
  </div>
</template>

<script>
import {AxisAngleGenerator, BehaviorTypes, Bezier, ColorRange, ConstantValue, PiecewiseBezier} from "three.quarks";
import {Vector3, Vector4} from "three";
import BehaviorItemEditor from "@/views/ParticleSystem/Editors/BehaviorItemEditor.vue";
import {t} from "../../util/lang";

export default {
  computed: {
    BehaviorTypes() {
      const excludeType = ["Noise", "Rotation3DOverLife", "ForceOverLife", "EmitSubParticleSystem"]
      console.log(BehaviorTypes,'BehaviorTypes')
      const result = Object.keys(BehaviorTypes).filter(item => !excludeType.find(child => child === item))
      return result
    }
  },
  components: {BehaviorItemEditor},
  props: {
    behaviors: Array,
    updateProperties: Function
  },
  methods: {
    t,
    deleteBehavior(index) {
      this.$props.behaviors.splice(index, 1);
      this.$props.updateProperties();
    },
    genDefaultBezier() {
      return new PiecewiseBezier([[new Bezier(0, 0.3333, 0.6667, 1.0), 0]]);
    },
    genDefaultColor() {
      return new ColorRange(new Vector4(1.0, 1.0, 1.0, 1.0), new Vector4(0.0, 0.0, 0.0, 1.0));
    },
    onAddNewBehavior(type) {
      const entry = BehaviorTypes[type];
      let behavior;
      if (entry) {
        const args = [];
        const params = entry.params;
        for (let i = 0; i < params.length; i++) {
          switch (params[i][1]) {
            case 'number':
              args.push(1);
              break;
            case 'vec3':
              args.push(new Vector3(1, 1, 1));
              break;
            case 'rotationFunc':
              args.push(new AxisAngleGenerator(new Vector3(0, 1, 0), new ConstantValue(0)));
              break;
            case 'valueFunc':
              args.push(this.genDefaultBezier());
              break;
            case 'value':
              args.push(new ConstantValue(1));
              break;
            case 'colorFunc':
              args.push(this.genDefaultColor());
              break;
            case 'boolean':
              args.push(false);
              break;
            case 'self':
              args.push(this.props.system);
              break;
            case 'particleSystem':
            case 'mesh':
              args.push(undefined);
              break;
          }
        }
        behavior = new entry.constructor(...args);
      }
      if (behavior) {
        this.$props.behaviors.push(behavior);
        this.$props.updateProperties();
      }
    },
  },
};
</script>
<style lang="less" >
.behaviors-editor {
  padding-bottom: 100px;

  padding-left: 20px;
  .el-dropdown-link {
    margin: 15px 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    .el-icon-plus {
      font-size: 20px;
    }
  }
}

</style>