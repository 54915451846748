import {AdditiveBlending, DoubleSide, Group, MeshBasicMaterial, NormalBlending, Vector4} from "three";
import {
    Bezier,
    ColorOverLife, ColorRange, ConeEmitter,
    ConstantColor,
    ConstantValue,
    IntervalValue,
    ParticleSystem, PiecewiseBezier,
    RenderMode, SizeOverLife
} from "three.quarks";


export function createShipSmoke(textures) {
    const emitterGroup = new Group();
    emitterGroup.name = "ShipSmoke";

    const mat = new MeshBasicMaterial({map: textures, blending: AdditiveBlending, transparent: true, side: DoubleSide});

    const grey = new Vector4(.5, .5, .5, 1);

    const smoke = new ParticleSystem({
        duration: 5,
        looping: true,
        startLife: new IntervalValue(1.5, 3),
        startSpeed: new IntervalValue(20, 30),
        startSize: new IntervalValue(10, 15),
        startColor: new ConstantColor(grey),
        worldSpace: true,

        emissionOverTime: new ConstantValue(20),
        shape: new ConeEmitter({
            radius: 10,
            thickness: 1,
            angle: 0.2
        }),
        material: mat,
        blending: NormalBlending,
        startTileIndex: new ConstantValue(2),
        uTileCount: 10,
        vTileCount: 10,
        renderMode: RenderMode.BillBoard,
        renderOrder: 2,
    });
    //gatherParticles.addBehavior(new SizeOverLife(new PiecewiseBezier([[new Bezier(1, 0.25, 0.05, 0), 0]])));
    smoke.addBehavior(new ColorOverLife(new ColorRange(new Vector4(1, 1, 1, 1), new Vector4(.8, .8, .8, 0))));
    smoke.addBehavior(new SizeOverLife(new PiecewiseBezier([[new Bezier(0.2, 1, 1, 1), 0]])));
    smoke.emitter.name = 'smoke';
    smoke.emitter.rotation.x = -Math.PI / 2;
    emitterGroup.add(smoke.emitter);
    mat.dispose()
    return emitterGroup;
}
