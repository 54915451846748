import * as THREE from 'three';
import { TransformControls } from '../controls/TransformControls';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { OrbitControls } from '../controls/OrbitControls';
import { OutlinePass } from '../shader/OutlinePass';
import { MainObjects } from "./MainObjects";
import { SceneFactory } from '../factory/SceneFactory';
import { EventListenerBp } from "../blueprint/EventListenerBp"
import { SelectListenerBp } from '../blueprint/SelectListenerBp';
import { CameraListenerBp } from '../blueprint/CameraListenerBp';
import { SelectorPropertyBp } from '../blueprint/SelectorPropertyBp';
import { SceneManagerBp } from "../blueprint/SceneManagerBp"
import { MaterialEditorBp } from "../blueprint/MaterialEditorBp"
import { HistoryListenerBp } from '../blueprint/HistoryListenerBp';
import { ViewHelper } from '../helper/ViewHelper';
import * as TWEEN from "@tweenjs/tween.js"
import { AnimationManagerBp } from '../blueprint/AnimationManagerBp';
import { RenderManagerBp } from '../blueprint/RenderManagerBp';
import { RuntimeJsManagerBp } from '../blueprint/RuntimeJsManagerBp';
import { BoundingBoxBp } from '../blueprint/BoundingBoxBp';
import { CurveEditorBp } from '../blueprint/CurveEditorBp';
import { Stats } from '../mock/stats.module';
import { CollisionsManagerBp } from '../blueprint/CollisionsManagerBp';
import { ScaleBoxBp } from '../blueprint/ScaleBoxBp';
import { CameraViewHelper } from '../helper/CameraViewHelper';
import { PreviewCamera } from './PreviewCamera';
import { AudioListenerBp } from '../blueprint/AudioListenerBp';
import { SkyBp } from '../blueprint/SkyBp';
import { LensFlareBp } from '../blueprint/LensFlareBp';
import { ParticleSystemBp } from '../blueprint/ParticleSystemBp';
import { GrassBp } from '../blueprint/GrassBp';
import { TerrainBp } from '../blueprint/TerrainBp';
import { WaterBp } from '../blueprint/WaterBp';
import { BehaviorBp } from '../blueprint/BehaviorBp';
import {BatchedParticleRenderer} from "three.quarks";

class MainRender {

    //option:
    //{
    //  container
    //}
    constructor(option) { 
        window.createImageBitmap = undefined;
        this.m_clock = new THREE.Clock(); // only used for animations
        MainObjects.Render.m_container = option.container;
        const width = MainObjects.Render.m_container.clientWidth;
        const height = MainObjects.Render.m_container.clientHeight;
        MainObjects.MathValue.m_x = new THREE.Vector3(1, 0, 0);
        MainObjects.MathValue.m_y = new THREE.Vector3(0, 1, 0);
        MainObjects.MathValue.m_z = new THREE.Vector3(0, 0, 1);
        //init camera
        const aspect = width / height;
        MainObjects.Camera.m_mainPerspectiveCamera = new THREE.PerspectiveCamera(50, aspect, 0.1, 10000);
        MainObjects.Camera.m_mainOrthographicCamera = new THREE.OrthographicCamera(MainObjects.Camera.m_frustumSize * aspect / -2, MainObjects.Camera.m_frustumSize * aspect / 2, MainObjects.Camera.m_frustumSize / 2, MainObjects.Camera.m_frustumSize / -2, 0.1, 10000);
        //set defult camera
        MainObjects.Camera.m_mainPerspectiveCamera.layers.enable( 10 );
        MainObjects.Camera.m_mainOrthographicCamera.layers.enable( 10 );
        MainObjects.Camera.m_mainPerspectiveCamera.layers.enable( 11 );
        MainObjects.Camera.m_mainOrthographicCamera.layers.enable( 11 );
        MainObjects.Camera.m_renderCamera = MainObjects.Camera.m_mainPerspectiveCamera;
        MainObjects.Camera.m_renderCamera.position.set(0, 1, 2);
        //init defult scene
        var scene = SceneFactory.createScene();
        SceneFactory.setRenderScene(scene);
        MainObjects.Blueprint.m_sceneManagerBp = new SceneManagerBp();
        SceneFactory.loadBaseRepos();
        //init render
        MainObjects.Render.m_renderer = new THREE.WebGLRenderer({ antialias: false });
        MainObjects.Render.m_renderer.setPixelRatio(window.devicePixelRatio);
        MainObjects.Render.m_renderer.setSize(width, height);
        MainObjects.Render.m_renderer.shadowMap.enabled = true;
        MainObjects.Render.m_renderer.shadowMapSoft = true;
        MainObjects.Render.m_renderer.shadowMap.type = THREE.PCFSoftShadowMap;
        MainObjects.Render.m_renderer.outputEncoding = THREE.sRGBEncoding;
        // MainObjects.Render.m_renderer.toneMapping = THREE.CineonToneMapping;
        MainObjects.Render.m_renderer.toneMappingExposure = 1;



        //posprocess
        // MainObjects.Render.m_composer = new EffectComposer(MainObjects.Render.m_renderer);
        // MainObjects.Render.m_renderPass = new RenderPass(MainObjects.Scene.m_renderScene, MainObjects.Camera.m_renderCamera);
        // MainObjects.Render.m_composer.addPass(MainObjects.Render.m_renderPass);
        // var w_outline = 256;
        // var h_outline = w_outline * height / width;
        MainObjects.Render.m_outlinePass = {
            selectedObjects : []
        }
        //MainObjects.Render.m_outlinePass = new OutlinePass(new THREE.Vector2(w_outline, h_outline), MainObjects.Scene.m_renderScene, MainObjects.Camera.m_renderCamera);
        // MainObjects.Render.m_composer.addPass(MainObjects.Render.m_outlinePass);
        //init control
        MainObjects.Control.m_orbitControl = new OrbitControls(MainObjects.Camera.m_renderCamera, MainObjects.Render.m_renderer.domElement);
        MainObjects.Control.m_orbitControl.damping = 0.2;
        MainObjects.Control.m_orbitControl.minDistance = 0.1;
        MainObjects.Control.m_orbitControl.maxDistance = 8000;
        MainObjects.Control.m_orbitControl.maxZoom = 5;
        MainObjects.Control.m_orbitControl.minZoom = 0.0001;

        MainObjects.Control.m_transformControl = new TransformControls(MainObjects.Camera.m_renderCamera, MainObjects.Render.m_renderer.domElement);
        SceneFactory.addToHelperGroup(MainObjects.Control.m_transformControl);
        //view helper
        MainObjects.Scene.m_viewHelper = new ViewHelper(MainObjects.Camera.m_renderCamera, MainObjects.Render.m_container, MainObjects.Control.m_orbitControl);
        MainObjects.Scene.m_cameraViewHelper = new CameraViewHelper(MainObjects.Render.m_container);
        MainObjects.MainRender.m_previewCamera = new PreviewCamera();
        MainObjects.MainRender.m_previewCamera.addRender({
            container :  MainObjects.Scene.m_cameraViewHelper.getDom()
        })

        //init event bp
        MainObjects.Blueprint.m_eventListenerBp = new EventListenerBp();
        MainObjects.Blueprint.m_selectListenerBp = new SelectListenerBp();
        MainObjects.Blueprint.m_cameraListenerBp = new CameraListenerBp();
        MainObjects.Blueprint.m_selectorPropertyBp = new SelectorPropertyBp();
        MainObjects.Blueprint.m_materialEditorBp = new MaterialEditorBp();
        MainObjects.Blueprint.m_historyListenerBp = new HistoryListenerBp();
        MainObjects.Blueprint.m_animationManagerBp = new AnimationManagerBp();
        MainObjects.Blueprint.m_renderManagerBp = new RenderManagerBp();
        MainObjects.Blueprint.m_runtimeJsManagerBp = new RuntimeJsManagerBp(); 
        MainObjects.Blueprint.m_boundingBoxBp = new BoundingBoxBp();
        MainObjects.Blueprint.m_curveEditorBp = new CurveEditorBp();
        MainObjects.Blueprint.m_collisionsManagerBp = new CollisionsManagerBp();
        MainObjects.Blueprint.m_scaleBoxBp = new ScaleBoxBp();
        MainObjects.Blueprint.m_audioListenerBp = new AudioListenerBp();
        MainObjects.Blueprint.m_skyBp = new SkyBp();
        MainObjects.Blueprint.m_lensFlareBp = new LensFlareBp();
        MainObjects.Blueprint.m_particleSystemBp = new ParticleSystemBp();
        MainObjects.Blueprint.m_terrainBp = new TerrainBp();
        MainObjects.Blueprint.m_waterBp = new WaterBp();
        MainObjects.Blueprint.m_grassBp = new GrassBp();
        MainObjects.Blueprint.m_behaviorBp = new BehaviorBp();
        
        //add render to doc
        MainObjects.Render.m_container.appendChild(MainObjects.Render.m_renderer.domElement);
        MainObjects.Render.m_stats = new Stats();
        MainObjects.Render.m_container.appendChild(MainObjects.Render.m_stats.dom);
        
        this.m_clock = new THREE.Clock(); // only used for animations
        this.m_renderTime = 1 / 60;
        this.m_timeTemp = 0;
        this.update();
        SceneFactory.createSceneObjects();
    }

    update() {
        requestAnimationFrame(this.update.bind(this));

        const delta = this.m_clock.getDelta();
        this.m_timeTemp = this.m_timeTemp + delta;
        if (this.m_timeTemp > this.m_renderTime) {
            const deltaConst = 0.015;
            MainObjects.Render.m_stats.update();
            MainObjects.Blueprint.m_grassBp.update(deltaConst);
            MainObjects.Blueprint.m_particleSystemBp.update(deltaConst);
            MainObjects.Blueprint.m_collisionsManagerBp.update(deltaConst);
            MainObjects.Scene.m_viewHelper.update(deltaConst);
            MainObjects.Blueprint.m_sceneManagerBp.m_gameScene.update(deltaConst);
            if(TWEEN){
                TWEEN.update();
            }
            this.render(delta);
            this.m_timeTemp = ( this.m_timeTemp % this.m_renderTime);
        }
    }

    render(delta) {
        // MainObjects.Render.m_composer.render();
        MainObjects.Render.m_renderer.render(MainObjects.Scene.m_renderScene, MainObjects.Camera.m_renderCamera);
        MainObjects.Render.m_renderer.autoClear = false;
        MainObjects.Scene.m_viewHelper.render(MainObjects.Render.m_renderer);
        MainObjects.Render.m_renderer.autoClear = true;

    }


}

export { MainRender };