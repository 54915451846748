import { MainObjects } from "../common/MainObjects";

class IUnity {
    static copyToScene() {
        
        var data = JSON.parse(localStorage.getItem("UnityJson"));
        if(data == null)
        {
            return;
        }
        var map = new Map();
        for (let index = 0; index < MainObjects.Scene.m_userGroup.children.length; index++) {
            const element = MainObjects.Scene.m_userGroup.children[index];
            if(element.userData != null)
            {
                map.set(element.userData.m_name, element);
            }
        }
        for (let index = 0; index < data.children.length; index++) {
            var element = data.children[index];
            var object = map.get(element.name);
   
            if(object != null)
            {
                

                var copy = MainObjects.Blueprint.m_sceneManagerBp.getObjectJsonInfo(object);
                MainObjects.Blueprint.m_sceneManagerBp.getObjectFromJson(copy, function (obj) {
                    IUnity.setCopyToScene(obj, element.data)
                }, true);
            }
        }

    }
    static setCopyToScene(object, data)
    {
        object.position.fromArray(data.position);
        object.rotation.fromArray(data.rotation);
        object.scale.fromArray(data.scale);
        object.updateMatrixWorld(true);
    }
}

export { IUnity };