import * as THREE from 'three';

class ViewData {
    constructor() {
        this.m_b = false;
        this.m_tb = 0;
        this.m_lr = 0;
        this.m_left = 0;   //px
        this.m_bottom = 0; //px
        this.m_width = 1.0;
        this.m_height = 1.0;

    }

    setJson(json) {
        this.m_b = json.m_b;
        this.m_tb = json.m_tb;
        this.m_lr = json.m_lr;
        this.m_left = json.m_left;     //px
        this.m_bottom = json.m_bottom; //px
        this.m_width = json.m_width;
        this.m_height = json.m_height;
    }

    getJson() {
        var json = {
            m_b : this.m_b,
            m_tb : this.m_tb,
            m_lr : this.m_lr,
            m_left : this.m_left,   //px
            m_bottom : this.m_bottom, //px
            m_width : this.m_width,
            m_height : this.m_height,
        }

        return json;
    }

}
export { ViewData };