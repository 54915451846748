import * as THREE from 'three';
import { MainObjects } from '../common/MainObjects';
import { GameObject } from '../object/GameObject';

class SkyBp {
    constructor() {

    }

    getSkyEffectController(sky) {
        const data = {
            type: GameObject.GameObjectType.e_SKY,
            topColor: "#0x0077ff ",
            bottomColor: "#0xffffff",
            topColorArray: [1, 1, 1],
            bottomColorArray: [1, 1, 1],
        };
        const uniforms = sky.material.uniforms;
        data.topColor = "#" + uniforms['topColor'].value.getHexString();
        data.bottomColor = "#" + uniforms['bottomColor'].value.getHexString();
        uniforms['topColor'].value.toArray(data.topColorArray);
        uniforms['bottomColor'].value.toArray(data.bottomColorArray);
        return data;
    }

    getRealTimeSkyEffectController(sky)
    {
        const data = {
            type: GameObject.GameObjectType.e_REAL_TIME_SKY, //类型21
            skySize: sky.userData.m_skyScalar,               //天空尺寸倍数
            skyLightRadius: sky.userData.m_skyLightRadius,   //太阳半径
            currentSkyTime: sky.userData.m_currentSkyTime,   //当前时间，换算成小时
            dayTime: sky.userData.m_dayTime,                 //太阳升起时间  太阳落下时间，换算成小时
            azimuth: sky.userData.m_azimuth,                 //水平角度
        };
        return data;
    }

    skyChange(sky, data) {
        const uniforms = sky.material.uniforms;
        if (data.topColorArray != undefined) {
            uniforms['topColor'].value = new THREE.Color(data.topColorArray[0], data.topColorArray[1], data.topColorArray[2]);
        }
        if (data.bottomColorArray != undefined) {
            uniforms['bottomColor'].value = new THREE.Color(data.bottomColorArray[0], data.bottomColorArray[1], data.bottomColorArray[2]);
        }
    }
    realTimeskyChange(sky, data)
    {
        if(data.skySize)
        {
            sky.userData.m_skyScalar = data.skySize;
        }
        if(data.skyLightRadius)
        {
            sky.userData.m_skyLightRadius = data.skyLightRadius;
        }
        if(data.currentSkyTime)
        {
            sky.userData.m_currentSkyTime = data.currentSkyTime;
        }
        if(data.dayTime)
        {
            sky.userData.m_dayTime = data.dayTime;
        }
        if(data.azimuth)
        {
            sky.userData.m_azimuth = data.azimuth;
        }
        this.updateSkySun(sky);
    }

    updateSkySun(sky) {
        sky.scale.setScalar(sky.userData.m_skyScalar);
        var elevation = this.timeToElevation(sky);
        const phi = THREE.MathUtils.degToRad(90 - elevation);
        const theta = THREE.MathUtils.degToRad(sky.userData.m_azimuth);

        var sun = new THREE.Vector3();
        sun.setFromSphericalCoords(sky.userData.m_skyLightRadius, phi, theta);
        sky.material.uniforms['sunPosition'].value.copy(sun);

        var lightPos = new THREE.Vector3();
        lightPos.copy(sun);
        lightPos.divideScalar(sky.userData.m_skyScalar);
        sky.userData.m_gameObjectHelper.position.copy(lightPos);
        sky.userData.m_gameObjectHelper.scale.setScalar(1 / sky.userData.m_skyScalar);
        var lightNormal = new THREE.Vector3();
        lightNormal.copy(lightPos);
        lightNormal.normalize();
        lightPos.add(lightNormal).multiplyScalar(10000);
        sky.userData.m_gameObjectHelper.lookAt(lightPos);

        sky.updateMatrixWorld(true, true);
        MainObjects.Blueprint.m_selectListenerBp.updateObject(sky.userData.m_gameObjectHelper);

    }

    timeToElevation(sky)
    {
        var elevation = 180 * (sky.userData.m_currentSkyTime - sky.userData.m_dayTime[0])/(sky.userData.m_dayTime[1] - sky.userData.m_dayTime[0]);
        if(sky.userData.m_currentSkyTime >= sky.userData.m_dayTime[0] && sky.userData.m_currentSkyTime <= sky.userData.m_dayTime[1])
        {
            //day
            const skyUniforms = sky.material.uniforms;
            var x = (elevation - 90) / 40;
            var ray = 0.1 + x * x;
            skyUniforms['rayleigh'].value = ray;
            skyUniforms['turbidity'].value = 10;
            return elevation;
        }
        else
        {
            //night
            const skyUniforms = sky.material.uniforms;
            skyUniforms['turbidity'].value = 0.1;
            skyUniforms['rayleigh'].value = 0.001;
            elevation = 360 - elevation;
            return elevation;
        }
    }

    initSky(sky, data) {
        this.skyChange(sky, data);
    }

    initRealTimeSky(sky, data) {
        this.realTimeskyChange(sky, data);
    }
}

export { SkyBp };