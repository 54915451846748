import { OssManager } from "../network/OssManager";
import { IImage } from "../interface/IRes"
import { MainObjects } from "../common/MainObjects"

class RuntimeJsManagerBp {
    constructor() {
        this.m_behaviorJs = null;
        this.getBehavior(function(){});
    }

    getBehavior(callback){
        var _this = this;
        if(this.m_behaviorJs != null){
            callback(this.m_behaviorJs);
        }
        else{
            let oss = new OssManager(MainObjects.Network.m_bucketName);
            oss.f_finishCallback = function (data) {
                _this.m_behaviorJs = data;
                callback(_this.m_behaviorJs);
            };
            oss.downloadJs(IImage.behaviorJS)
        }
    }
}

export { RuntimeJsManagerBp };
