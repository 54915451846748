import { MainObjects } from "../common/MainObjects"
import { ICamera } from "../interface/ICamera"

class EventListenerBp {
    constructor() {
        MainObjects.Render.m_container.addEventListener('pointerdown', this.onPointerDown.bind(this));
        MainObjects.Render.m_container.addEventListener('pointerup', this.onPointerUp.bind(this));
        MainObjects.Render.m_container.addEventListener('pointermove', this.onPointerMove.bind(this));
        window.addEventListener('resize', this.onWindowResize.bind(this));
        window.addEventListener('keydown', this.onKeyDown.bind(this));
        window.addEventListener('keyup', this.onKeyUp.bind(this));
        this.m_bCtrl = false;
        this.m_bShift = false;
        this.m_bAlt = false;
    }
    onCameraResize() {
        const width = MainObjects.Render.m_container.clientWidth;
        const height = MainObjects.Render.m_container.clientHeight;
        const aspect = width / height;
        MainObjects.Camera.m_mainPerspectiveCamera.aspect = aspect;
        MainObjects.Camera.m_mainPerspectiveCamera.updateProjectionMatrix();

        MainObjects.Camera.m_mainOrthographicCamera.left = MainObjects.Camera.m_frustumSize * aspect / -2;
        MainObjects.Camera.m_mainOrthographicCamera.right = MainObjects.Camera.m_frustumSize * aspect / 2;
        MainObjects.Camera.m_mainOrthographicCamera.top = MainObjects.Camera.m_frustumSize / 2;
        MainObjects.Camera.m_mainOrthographicCamera.bottom = MainObjects.Camera.m_frustumSize / -2;

        MainObjects.Camera.m_mainOrthographicCamera.updateProjectionMatrix();
        // var w_outline = 256;
        // var h_outline = w_outline * height / width;
        // MainObjects.Render.m_outlinePass.setSize(w_outline, h_outline);
        MainObjects.Render.m_renderer.setSize(width, height); 
        // MainObjects.Render.m_composer.setSize(width, height);
    }

    onWindowResize() {
        this.onCameraResize();
        if (ICamera.f_windowsResize != null) {
            ICamera.f_windowsResize();
        }
    }

    onPointerDown(even) {
        MainObjects.Control.m_isInput = false;
        MainObjects.Blueprint.m_selectListenerBp.onPointerDown(even);
    }

    onPointerUp(even) {
        MainObjects.Blueprint.m_selectListenerBp.onPointerUp(even);
    }

    onPointerMove(even) {
        MainObjects.Blueprint.m_selectListenerBp.onPointerMove(even);
    }

    onKeyDown(even) {
        if(!MainObjects.Control.m_isInput)
        {
            if (even.keyCode == 17) {
                this.m_bCtrl = true;
            }
            else if (even.keyCode == 16) {
                this.m_bShift = true;
            }
            else if (even.keyCode == 18) {
                this.m_bAlt = true;
            }
            MainObjects.Blueprint.m_selectListenerBp.onKeyDown(even);
            MainObjects.Blueprint.m_historyListenerBp.onKeyDown(even);
        }
    }

    onKeyUp(even) {
        if (even.keyCode == 17) {
            this.m_bCtrl = false;
        }
        else if (even.keyCode == 16) {
            this.m_bShift = false;
        }
        else if (even.keyCode == 18) {
            this.m_bAlt = false;
        }
        if(!MainObjects.Control.m_isInput)
        {
            MainObjects.Blueprint.m_selectListenerBp.onKeyUp(even);
            MainObjects.Blueprint.m_cameraListenerBp.onKeyUp(even);
            MainObjects.Blueprint.m_sceneManagerBp.onKeyUp(even);
            MainObjects.Blueprint.m_historyListenerBp.onKeyUp(even);
        }
    }

}
export { EventListenerBp };