import { MainObjects } from "../common/MainObjects";
import { Flow } from 'three/examples/jsm/modifiers/CurveModifier.js';
import { ICollisions } from "../interface/ICollisions";
import * as THREE from 'three';

class CurveBehavior {
    static LoopMode =
    {
        e_NONE: 0,
        e_POP: 1,
        e_LOOP: 2,
    }
    constructor() {
        this.m_curve_name = null;
        this.m_speed =  0.001;
        this.m_bloop = CurveBehavior.LoopMode.e_LOOP;
        this.m_sim = false;
        this.m_curve = null;
        this.m_list = null;
        this.m_index = 0;
        this.m_order = true;
        this.m_percent = 0;
    }

    update(object)
    {
        if(this.m_order)
        {
            this.m_index = this.m_index + 1;
        }
        else
        {
            this.m_index = this.m_index - 1;
        }
        if(this.m_index == this.m_list.length)
        {
            if(this.m_bloop == CurveBehavior.LoopMode.e_POP)
            {
                this.m_order = false;
            }
            else if(this.m_bloop == CurveBehavior.LoopMode.e_LOOP)
            {
                this.m_index = 0;
            }
        }
        if(this.m_index == 0)
        {
            this.m_order = true;
        }
    
        if(this.m_index >= 0 && this.m_index < this.m_list.length)
        {
            if( MainObjects.Blueprint.m_collisionsManagerBp.m_turnPhysics)
            {
                if(object.userData.m_collisionsType == ICollisions.CollisionsType.e_NONE)
                {
                    if(object.userData.m_collisionFlags == 2)
                    {
                        object.lookAt (this.m_list[this.m_index]);
                        object.position.copy(this.m_list[this.m_index]);
                        MainObjects.Blueprint.m_selectListenerBp.updateObject(object);
                        object.body.needUpdate = true;
                    }
                }
            }
            else
            {
                object.lookAt (this.m_list[this.m_index]);
                object.position.copy(this.m_list[this.m_index]);
                MainObjects.Blueprint.m_selectListenerBp.updateObject(object);
            }
        }
    }

    getJson()
    {
        var data = {
            curve_name : this.m_curve_name,
            speed : this.m_speed,
            bloop : this.m_bloop,
            sim : this.m_sim,
            percent : this.m_percent
        }
        return data;
    }
    
    setJson(json, bInit = false)
    {
        this.m_curve_name = json.curve_name;
        this.m_speed = json.speed;
        this.m_bloop = json.bloop;
        this.m_sim = json.sim;
        if(json.percent != undefined)
        {
            this.m_percent = json.percent;
        }

        if(bInit)
        {
            this.m_sim = false; 
        }
    }

    init(object)
    {
        if(this.m_sim)
        {
            const frame = MainObjects.Render.m_stats.getFrame();
            var speed = ((1000 / this.m_speed) * 60) / frame;
            var objectCurve = MainObjects.Scene.m_userGroup.getObjectByName(this.m_curve_name)
            this.m_curve = objectCurve.userData.m_curve;
            this.m_list = this.m_curve.getPoints(speed);
            this.m_index = Math.floor(speed * (this.m_percent / 100)) ;
            object.position.copy(this.m_list[this.m_index]);

            MainObjects.Blueprint.m_selectListenerBp.updateObject(object);
        }
    }
    destory()
    {
        
    }
}

export { CurveBehavior };