import { GameObject } from "../object/GameObject";

class WaterBp {
    constructor() {
      
    }

    setProperty(obj, prop)
    {
        if (obj.userData.m_gameObjectType == GameObject.GameObjectType.e_WATER_BASE) {
            obj.userData.m_water_data.setJson(prop);
            var value = obj.userData.m_water_data.getJsonForUI();
            obj.material.uniforms[ 'color' ].value.set( value.m_color );
            obj.material.uniforms[ 'config' ].value.w = value.m_scale;
            obj.material.uniforms[ 'flowDirection' ].value.x = value.m_flowX;
            obj.material.uniforms[ 'flowDirection' ].value.y = value.m_flowY;
            obj.material.uniforms[ 'flowDirection' ].value.normalize();
        }
 
    }
}

export { WaterBp };