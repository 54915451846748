<template>
    <div class="container" v-dragFixed>
        <div class="header" id="controlDrag">状态机</div>
        <div class="close" @click="closeChange">
            <img src="../../assets//Project/close.png" alt />
        </div>
        <div class="add">
            <div class="name">
                <p>状态机名称</p>
                <el-input v-model="name" @focus="Focus" @blur="Blur" :disabled="motionPlayerTypeId == 2"></el-input>
            </div>
            <el-button type="primary" @click="addAction()" size="small">新 增</el-button>
        </div>
        <div class="resourceTabel">
            <el-table :data="actionList" border height="22.78646vw" :row-style="tableRowStyle"
                :header-cell-style="tableHeaderColor" style="width: 100%">
                <el-table-column prop="date" label="编码" align="center" type="index" width="50">

                </el-table-column>
                <el-table-column prop="actionStart" label="动作开始" align="center">
                    <template slot-scope="scope">
                        <el-input style="width:4.55729vw;" v-model="scope.row.actionStart" placeholder="" maxlength="10"
                            @focus="Focus" @blur="Blur" :id="'action' + scope.row.id" :disabled="scope.row.id == 1">
                        </el-input>
                    </template>
                </el-table-column>
                <el-table-column prop="actionEnd" label="动作结束" align="center">
                    <template slot-scope="scope">
                        <el-input style="width:4.55729vw" v-model="scope.row.actionEnd" placeholder="" @focus="Focus"
                            @blur="Blur"></el-input>
                    </template>
                </el-table-column>
                <el-table-column prop="bLoop" label="是否循环" align="center">
                    <template slot-scope="scope">
                        <el-checkbox v-model="scope.row.bLoop"></el-checkbox>
                    </template>
                </el-table-column>
                <el-table-column prop="bEnd" label="是否停留" align="center">
                    <template slot-scope="scope">
                        <el-checkbox v-model="scope.row.bEnd"></el-checkbox>
                    </template>
                </el-table-column>
                <el-table-column prop="time" label="切换时间" align="center">
                    <template slot-scope="scope">
                        <el-input style="width:4.55729vw" v-model="scope.row.time" placeholder="" @focus="Focus"
                            @blur="Blur"></el-input>
                    </template>
                </el-table-column>
                <el-table-column prop="address" align="center" label="操作">
                    <template slot-scope="scope">
                        <el-button type="primary" @click="actionRemoval(scope.row)" size="small">删 除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="bottom">
            <div class="save" @click="save">确定</div>
        </div>
    </div>
</template>
<script>
import { ISetting } from "../../threejs/interface/ISetting";
import { IAnimation } from "../../threejs/interface/IAnimation";

import { INetwork } from "../../threejs/interface/INetwork";

import Resource from "./Resource"

export default {
    data() {
        return {
            actionList: [
                {
                    actionStart: "开始",
                    actionEnd: "",
                    bLoop: false,
                    bEnd: false,
                    time: "",
                    id: 1,
                },
            ],
            name: "",
            currentIndex: 2,
            motionPlayerId: "",
            motionPlayerTypeId:"",

        }
    },
    mounted() {
        IAnimation.createAnimationBehavior()
        if(this.$store.state.motionPlayerType == 2){
            this.motionPlayerTypeId = this.$store.state.motionPlayerType;
            this.actionList = this.$store.state.motionPlayerData;
            this.name = this.$store.state.motionPlayerSqlData.name;
            console.log(this.$store.state.motionPlayerSqlData.name)
        }
        
    },
    methods: {
        getData() {
            console.log(AnimationBehaviorList)
        },
        addAction() {
            let id = this.getNum(20) + Date.now()
            let index = this.currentIndex++;
            let data = {
                actionStart: "",
                actionEnd: "",
                bLoop: false,
                bEnd: false,
                time: "",
                id: index,
            }
            this.actionList.push(data)
        },
        actionRemoval(item) {
            // console.log(item, "item");
            if (item.id != 1) {
                this.actionList.splice(this.actionList.indexOf(item), 1);
            } else {
                this.$message({
                    message: "初始动作不可删除"
                })
            }
        },
        closeChange() {
            // this.$store.state.macState = false;

            this.$store.commit("changeMacState", false)


        },
        save() {
            if (this.actionList.some((val) => val.actionStart == '' && val.actionEnd == '' && val.time == '')) {
                this.$message({
                    // type: "error",
                    message: "请输入内容，不能为空"
                });
                return;
            } else if (this.name == '') {
                this.$message({
                    // type: "error",
                    message: "请输入状态机名称"
                });
            }
            else {

                if (this.$store.state.motionPlayerType == 1) {



                    let json = this.actionList;
                    console.log(json)
                    IAnimation.setAnimationBehaviorJson(json)
                    this.addMotionPlayerToSql()
                } else if (this.$store.state.motionPlayerType == 2) {

                    let json = this.actionList;
                    console.log(json)
                    IAnimation.setAnimationBehaviorJson(json)
                    this.motionPlayerId=this.$store.state.motionPlayerSqlData.id
                    let _this=this
                    var data = INetwork.getAnimationBehaviorJsonToSql(
                        _this.$store.state.motionPlayerSqlData.id
                        ,
                        () => {
                            this.updataMotionPlayerToSql(data)
                        }

                    );
                }


            }

        },
        tableRowStyle({ row, rowIndex }) {
            return "background-color:#F7F7F7;font-size:15px; color: #000000;height:30px; ";
        },
        tableHeaderColor({ row, column, rowIndex, columnIndex }) {
            return "background-color:#F7F7F7;height:30px;font-size: 14px;color: #000000;";
        },
        getNum(num) {
            var random = Math.floor(
                (Math.random() + Math.floor(Math.random() * 9 + 1)) *
                Math.pow(10, num - 1)
            );
            return random;
        },
        Focus() {
            ISetting.isInput(true);
        },
        Blur() {
            ISetting.isInput(false);
        },


        //数据库新建动作
        async addMotionPlayerToSql() {


            const res = await this.$https.addFile({
                //    this.url_list=url_list
                // this.mainUrl=mainUrl
                //文件夹id
                // folderId:this.id,
                projectId: localStorage.getItem("projectId"),
                folderId: this.$store.state.motionPlayerParentId,
                name: this.name,
                type: 12,
                fileUrl: "",
                fileSize: 0,
                imageUrl: "",
                deputyUrl: "",
            });
            if (res.success == true) {
                this.motionPlayerId = res.data;



                var data = INetwork.getAnimationBehaviorJsonToSql(
                    res.data
                    ,
                    () => {
                        this.updataMotionPlayerToSql(data)
                    }
                );



                // console.log(this.MultimediaData.files, res.data, "mp3", "创建");

            } else {
                this.$message({
                    message: res.errMessage,
                    // type: "warning"
                });
            }
        },
        async updataMotionPlayerToSql(_data) {
            var fileId = this.motionPlayerId;



            //获取真实的数据 这里需要传文件类型

            //   更新


            const loading = this.$loading({
                lock: true,
                text: "Loading",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            var time = new Date().getTime();

            const res = await this.$https.updateFile({
                //文件夹id
                // folderId:this.id,
                projectId: localStorage.getItem("projectId"),
                id: fileId,

                fileUrl: _data.mainName,
                imageUrl: _data.imageName + "?" + "time=" + time,
                deputyUrl: _data.otherNameList.toString(),
                fileSize: 0,
            });

            if (res.errCode == null) {

                this.$parent.getSubCatalog(this.$store.state.motionPlayerParentId)
                this.$store.commit("changeMacState", false)


                loading.close();


            } else {
                loading.close();

            }
        }



    }
}
</script>
<style scoped>
.container {
    width: 900px;
    height: 600px;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 10px;
}

.header {
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    border-bottom: 1px solid #eee;
}

.add {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.add .name {
    display: flex;
    height: 40px;
    align-items: center;
}

.add p {
    display: inline-block;
    width: 120px;
    height: 40px;
    line-height: 40px;
    text-align: center;
}

.name .el-input {
    height: 30px;
    width: 150px;
}

.name /deep/ .el-input__inner {
    height: 30px !important;
    width: 150px !important;
}

.add .el-button {
    margin-right: 10px;
}

.close {
    position: absolute;
    top: 13px;
    right: 15px;
    cursor: pointer;
}

.close img {
    width: 13px;
    height: 13px;
}

.bottom {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
}

.bottom .save {
    width: 140px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    background: #66b1ff;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
}

.el-input__inner {
    text-align: center !important;
}
</style>