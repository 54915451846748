import { MainObjects } from "../common/MainObjects"
import { GameObject } from "../object/GameObject";
import * as THREE from 'three';

class SelectorPropertyBp {
    constructor() {
        this.m_selectorGameObjTypeMap = new Map();
        this.m_selectorMaterialTypeMap = new Map();
        this.m_selectorType;
        this.m_selectorMaterialType;
    }

    changeLock() {
        const length = MainObjects.Render.m_outlinePass.selectedObjects.length;
        for (let index = 0; index < length; index++) {
            const obj = MainObjects.Render.m_outlinePass.selectedObjects[index];
            obj.userData.m_block = !obj.userData.m_block;
        }
    }

    changeAllObjectVisible(object, visible = true)
    {
        for (const iterator of object.children) {
            if(iterator.userData != null)
            {
                var data = iterator.userData.getTreeData(iterator);
                if(iterator.userData != null && iterator.userData.m_gameObjectType != GameObject.GameObjectType.e_SELECT_HELPER_BOX)
                {
                    if(data.object.isLight)
                    {
                        if(!data.object.userData.m_gameObjectHelper.visible){
                            this.setObjectVisible(data.object, visible)
                        }
                        else
                        {
                            this.setObjectVisible(data.object, visible)
                        }
                    }
                    else
                    {
                        if(!data.object.visible)
                        {
                            this.setObjectVisible(data.object, visible)
                        }
                        else
                        {
                            this.setObjectVisible(data.object, visible)
                        }
                    }
                    if (iterator.userData.m_gameObjectType == GameObject.GameObjectType.e_GROUP) {
                        this.changeAllObjectVisible(iterator);
                    }
                    else if(iterator.userData.m_gameObjectType == GameObject.GameObjectType.e_GLTF){
                        if(visible)
                        {
                            for (let index = 0; index < iterator.userData.m_gltf_meshList.length; index++) {
                                const element = iterator.userData.m_gltf_meshList[index];
                                element.visible = true;
                            }
                        }

                    }
                }
            }
        }
    }

    setObjectVisible(obj, visible){
        if(obj.isLight)
        {
            obj.userData.m_gameObjectHelper.visible = visible;
            if(obj.userData.m_gameObjectHelper.visible){
                //add
                MainObjects.Blueprint.m_sceneManagerBp.m_gameScene.getAllSelectObjects(obj)
                obj.visible = obj.userData.m_lightRealtime;
            }
            else{
                //remove
                MainObjects.Blueprint.m_sceneManagerBp.m_gameScene.removeAllSelectObjects(obj)
                obj.userData.m_lightRealtime = obj.visible;
                obj.visible = false;
            }
        }
        else
        {
            obj.visible = visible;
            if(obj.visible){
                //add
                MainObjects.Blueprint.m_sceneManagerBp.m_gameScene.getAllSelectObjects(obj)
                if(obj.userData.m_gameObjectHelper != null)
                {
                    obj.userData.m_gameObjectHelper.visible = true;
                }
            }
            else{
                //remove
                MainObjects.Blueprint.m_sceneManagerBp.m_gameScene.removeAllSelectObjects(obj)
                if(obj.userData.m_gameObjectHelper != null)
                {
                    obj.userData.m_gameObjectHelper.visible = false;
                }
            }
        }
    }

    changeObjectVisible(obj){
        if(obj.isLight)
        {
            obj.userData.m_gameObjectHelper.visible = !obj.userData.m_gameObjectHelper.visible;
            if(obj.userData.m_gameObjectHelper.visible){
                //add
                MainObjects.Blueprint.m_sceneManagerBp.m_gameScene.getAllSelectObjects(obj)
                obj.visible = obj.userData.m_lightRealtime;
            }
            else{
                //remove
                MainObjects.Blueprint.m_sceneManagerBp.m_gameScene.removeAllSelectObjects(obj)
                obj.userData.m_lightRealtime = obj.visible;
                obj.visible = false;
            }
        }
        else
        {
            obj.visible = !obj.visible;
            if(obj.visible){
                //add
                MainObjects.Blueprint.m_sceneManagerBp.m_gameScene.getAllSelectObjects(obj)
                if(obj.userData.m_gameObjectHelper != null)
                {
                    obj.userData.m_gameObjectHelper.visible = true;
                }
            }
            else{
                //remove
                MainObjects.Blueprint.m_sceneManagerBp.m_gameScene.removeAllSelectObjects(obj)
                if(obj.userData.m_gameObjectHelper != null)
                {
                    obj.userData.m_gameObjectHelper.visible = false;
                }
            }
        }
    }

    changeVisible() {
        const length = MainObjects.Render.m_outlinePass.selectedObjects.length;
        for (let index = 0; index < length; index++) {
            const obj = MainObjects.Render.m_outlinePass.selectedObjects[index];
            this.changeObjectVisible(obj);
        }
    }

    isSelectorSameType() {
        this.m_selectorGameObjTypeMap.clear();
        this.m_selectorMaterialTypeMap.clear();
        const length = MainObjects.Render.m_outlinePass.selectedObjects.length;
        for (let index = 0; index < length; index++) {
            const obj = MainObjects.Render.m_outlinePass.selectedObjects[index];
            this.m_selectorGameObjTypeMap.set(obj.userData.m_gameObjectType, 0);
            this.m_selectorType = obj.userData.m_gameObjectType;
            if (obj.isMesh || obj.isSkinnedMesh) {
                this.m_selectorMaterialTypeMap.set(obj.material.userData.m_materialObjectType);
                this.m_selectorMaterialType = obj.material.userData.m_materialObjectType;
            }
        }
        if (this.m_selectorGameObjTypeMap.size == 1) {
            return true;
        }
        else {
            return false
        }
    }

    isSelectorSameMaterialType() {
        if (this.m_selectorGameObjTypeMap.size == 0) {
            this.isSelectorSameType();
        }
        if (this.m_selectorMaterialTypeMap.size == 1) {
            return true;
        }
        else {
            return false
        }
    }

    getSameValue(val1, val2) {
        if (val1 != val2) {
            return "-";
        }
        else {
            return val1;
        }
    }

    getSameArrayValue(val1, val2) {
        if (val1.length != val2.length) {
            return val1;
        }
        else {
            for (let index = 0; index < val1.length; index++) {
                const element1 = val1[index];
                const element2 = val2[index];
                val1[index] = this.getSameValue(element1, element2);
            }
        }
        return val1;
    }

    getAddValue(val1, val2) {
        return val1 + "&" + val2;
    }

    getSelectObjectFirst() {
        const length = MainObjects.Render.m_outlinePass.selectedObjects.length;
        if (length > 0) {
            const obj = MainObjects.Render.m_outlinePass.selectedObjects[0];
            return obj;
        }
        else {
            return null;
        }
    }

    getSelectObjectFromSecond(callback) {
        const length = MainObjects.Render.m_outlinePass.selectedObjects.length;
        for (let index = 1; index < length; index++) {
            const obj = MainObjects.Render.m_outlinePass.selectedObjects[index];
            callback(obj)
        }
    }

    getSelectObjects(callback) {
        const length = MainObjects.Render.m_outlinePass.selectedObjects.length;
        for (let index = 0; index < length; index++) {
            const obj = MainObjects.Render.m_outlinePass.selectedObjects[index];
            callback(obj)
        }
    }

    getSelectObjectsCount()
    {
        const length = MainObjects.Render.m_outlinePass.selectedObjects.length;
        return length;
    }

    getCenterPosition()
    {
        var count =  this.getSelectObjectsCount();     
        var m_currentGroupVector = new THREE.Vector3();
        this.getSelectObjects(function(obj){
            var wp = new THREE.Vector3();
            obj.getWorldPosition(wp);
            m_currentGroupVector.add(wp);
        });
        m_currentGroupVector.multiplyScalar(1 / count);
        return m_currentGroupVector;
    }

}

export { SelectorPropertyBp };