<template>
    <div class="container">
        <div class="box" :class="isActive?'anmDiv':'aaa'">
            <div class="firstHalf1">
                <div @click="shrinkChange"><i class="el-icon-arrow-down"></i> </div>
                <p>环境光设置</p>
            </div>
           <div class="type">
               <p>类型</p>
               <div class="ipt_box">
                   <!-- <select class="select" v-model="select" @change="selectChange()">
                        <option v-for="(item,index) in optionList" :key="index">{{item}} </option>
                    </select> -->
                    <el-select class="select" @change="selectChange()" v-model="select" placeholder="请选择">
                        <el-option
                            v-for="item in optionList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
               </div>
           </div>
           <div v-show="hemisphere">
            <div class="color">
                    <div class="title">颜色</div>
                    <div class="firstHalf" style="margin-top:0.65104vw">
                        <p>上半圈</p>
                        <el-color-picker v-model="color" @change="TopColorChange()" @active-change="activeColor"></el-color-picker>
                    </div>
                    <div class="firstHalf">
                        <p>下半圈</p>
                        <el-color-picker v-model="groundColor" @change="BottomColorChange()" @active-change="BottomActiveColor"></el-color-picker>
                    </div>
            </div>
            <div class="intensity color">
                <div class="title">强度</div>
                <div class="firstHalf">
                    <p>强度</p>
                    <div class="txt_box">
                        <input class="ipt" type="number" @keydown="inputValue" @change="changeNum($event)" :min="0" name="" id="" v-model="intensity" @focus="Focus" @blur="Blur">
                    </div>
                </div>
            </div>
           </div>
           <div class="color" v-show="ambient">
                <div class="firstHalf" style="margin-top:0.65104vw">
                    <p>颜色</p>
                    <el-color-picker v-model="hemisphereLightColor" @change="hemisphereColor()" @active-change="AmbientActiveColor"></el-color-picker>
                </div>
                <div class="firstHalf">
                    <p>强度</p>
                    <div class="txt_box">
                        <input type="number" @keydown="inputValue" @change="changeNum($event)" :min="0" name="" id="" v-model="sphereIntensity" @focus="Focus" @blur="Blur">
                    </div>
                </div>
            </div>
        </div>
        <div class="boxOne" :class="cur?'anmDiv':'aaa'">
           <div class="header">
               <div @click="curChange"><i class="el-icon-arrow-down"></i> </div>
               <p>环境贴图</p>
           </div>
           <div>
               <div class="firstHalf2">
                    <p>是否启用</p>
                    <div class="ipt_box1">
                        <input class="input" type="checkbox" @change="bHelperChange($event)" v-model="bHelper" />
                    </div>
                </div>
                <div class="firstHalf2">
                    <p>环境贴图</p>
                    <div class="img_box">
                        <img :src="hdr_img" alt="">
                    </div>
                </div>
            </div>
        </div>
        <div class="boxOne" :class="active?'anmDiv':'aaa'">
           <div class="header">
               <div @click="unfoldChange"><i class="el-icon-arrow-down"></i> </div>
               <p>场景设置</p>
           </div>
           <div>
               <div class="firstHalf2">
                    <p >场景网格</p>
                    <div class="ipt_box1">
                        <input class="input" type="checkbox" @change="SceneChange($event)" v-model="scenenetwork" />
                    </div>
                </div>
                <div class="firstHalf2">
                    <p>全局曝光</p>
                    <div class="txt_box">
                        <input type="number" @keydown="inputValue" @change="exposureChange($event)" name="" id="" v-model="exposure" @focus="Focus" @blur="Blur">
                    </div>
                </div>
            </div>
        </div>
        <div class="boxOne" :class="gravity?'anmDiv':'aaa'">
           <div class="header">
               <div @click="gravityChange"><i class="el-icon-arrow-down"></i> </div>
               <p>物理</p>
           </div>
           <div>
                <!-- <div class="loction1">
                   <div class="title">重力</div>
                   <div class="x-axis axis">
                       <p>X</p>
                       <input v-model="positionX" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @change="changeNum0($event)"  @focus="Focus" @blur="Blur" />
                   </div>
                   <div class="y-axis axis">
                       <p>Y</p>
                       <input v-model="positionY" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @change="changeNum0($event)"  @focus="Focus" @blur="Blur"/>
                   </div>
                   <div class="z-axis axis">
                       <p>Z</p>
                       <input v-model="positionZ" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @change="changeNum0($event)"  @focus="Focus" @blur="Blur"/>
                   </div>
                </div> -->
                <div class="firstHalf2">
                    <p>碰撞盒</p>
                    <div class="ipt_box1">
                        <input class="input" type="checkbox" @change="collisionChange($event)" v-model="collision" />
                    </div>
                </div>
            </div>
        </div>
        <!-- 雾设置 -->
        <div class="boxOne" :class="fogActive?'anmDiv':'aaa'"  style="margin-bottom:3.25521vw">
           <div class="header">
               <div @click="fogChange"><i class="el-icon-arrow-down"></i> </div>
               <p>雾设置</p>
           </div>
           <div>
               <div class="firstHalf2">
                    <p >勾选</p>
                    <div class="ipt_box1">
                        <input class="input" type="checkbox" @change="setTurnFog($event)" v-model="turnFog" />
                    </div>
                </div>
                <div class="firstHalf" style="margin-top:0.65104vw">
                    <p>颜色</p>
                    <el-color-picker v-model="fogColor" @change="fogChangeColor()" @active-change="fogActiveColor"></el-color-picker>
                </div>
                <div class="firstHalf2">
                    <p>远</p>
                    <div class="txt_box">
                        <input type="number" @keydown="inputValue" @change="FogMicChange($event)" :step="1" name="" id="" v-model="far" @focus="Focus" @blur="Blur"
                        oninput="if(value.indexOf('.') > 0){value=value.slice(0,value.indexOf('.') + 7)}">
                    </div>
                </div>
                <div class="firstHalf2">
                    <p>近</p>
                    <div class="txt_box">
                        <input type="number" @keydown="inputValue" @change="FogMicChange($event)" :step="1" name="" id="" v-model="near" @focus="Focus" @blur="Blur"
                        oninput="if(value.indexOf('.') > 0){value=value.slice(0,value.indexOf('.') + 7)}">
                    </div>
                </div>
                <!-- <div class="firstHalf2">
                    <p>浓度</p>
                    <div class="txt_box">
                        <input type="number" @keydown="inputValue" @change="FogMicChange($event)" :step="0.001" name="" id="" v-model="density" @focus="Focus" @blur="Blur"
                        oninput="if(value.indexOf('.') > 0){value=value.slice(0,value.indexOf('.') + 7)}">
                    </div>
                </div> -->
            </div>
        </div>
        <YDCollapse :active-props="true" :title="'速度设置'">
          <PlayController
              :playSpeed="playSpeed"
              :setPlaySpeed="setPlaySpeed"
              class="playController" />
        </YDCollapse>
<!--        <div class="boxOne" :class="particleActive?'anmDiv':'aaa'"  style="margin-bottom:3.25521vw" v-show="toShow" >-->
<!--          <div class="header">-->
<!--            <div @click="particleChange"><i class="el-icon-arrow-down"></i> </div>-->
<!--            <p></p>-->
<!--          </div>-->

<!--        </div>-->
    </div>
</template>
<script>
import { ISetting } from "../../threejs/interface/ISetting";
import PlayController from "@/views/ParticleSystem/PlayController.vue";
import {mapMutations, mapState} from "vuex";
import YDCollapse from "@/views/component/YDCollapse.vue";
export default {
  components: {YDCollapse, PlayController},
    data(){
        return{
            isActive:true,
            hdr_img:'',
            color:'#FFFFFF',
            intensity:'',
            groundColor:'#FFFFFF',
            optionList:[
                {
                    value:0,
                    label:"气候光",
                },
                {
                    value:1,
                    label:"全局光",
                },
                {
                    value:2,
                    label:"无",
                },

            ],
            select:'',
            ambient:true,
            hemisphere:false,
            hemisphereLightColor:"#FFFFFF",
            sphereIntensity:'',
            bHelper:false,
            cur:true,
            active:true,
            gravity:true,
            fogActive:true,
            particleActive: true,
            selectList:[
                {
                    value:0,
                    label:"包围盒",
                },
                {
                    value:1,
                    label:"描边",
                }
            ],
            select1:'',
            scenenetwork:true,
            exposure:0,//全局曝光
            positionX:0,
            positionY:0,
            positionZ:0,
            collision:true,
            fogColor:"#FFFFFF",
            density:"",//浓度
            turnFog:false,//雾选择勾选
            far:0,//远
            near:0,//近
        }
    },
    computed: {
      ...mapState('controller', ['playSpeed','toShow']),
    },
    mounted(){
        ISetting.f_updateCallback = this.SceneEnvMap.bind(this);
    },
    methods:{
        ...mapMutations('controller', [
          'setPlaySpeed',
        ]),
        shrinkChange(){
            if (this.isActive) {
              this.isActive = false
            } else {
              this.isActive = true;
            }
        },
        SceneEnvMap(){
            let HDR = ISetting.getSceneEnvMap();
            this.hdr_img = HDR.envMapImage;
        },
        heimisphereChange(){
            let HemisphereList = ISetting.getAmbientSetting();
            this.hemisphereLightColor = HemisphereList.color;
            this.sphereIntensity = HemisphereList.intensity;
            this.bHelper = ISetting.getSceneEnvMap();
            let ambientList = ISetting.getHemisphereSetting();
            this.color = ambientList.color;
            this.intensity = ambientList.intensity;
            this.groundColor = ambientList.groundColor;
            this.scenenetwork = ISetting.getGrideVisible();
            let HDR = ISetting.getSceneEnvMap();
            this.hdr_img = HDR.envMapImage;
            // this.scenenetwork = scenenetwork;
            let mode = ISetting.getEnvLightMode();
            if(mode == 0){
                this.select = this.optionList[1].value;
            }else if(mode == 1){
                this.select = this.optionList[0].value;
            }else{
                this.select = this.optionList[2].value;
            }
            if(this.select == 0){
                this.ambient = false;
                this.hemisphere = true;
            }else if(this.select ==1){
                this.ambient = true;
                this.hemisphere = false;
            }else{
                this.ambient = false;
                this.hemisphere = false;
            }
            this.exposure = ISetting.getExposure();
            this.collision = ISetting.getTurnPhysicsHelper();
            //雾设置
            let fogList = ISetting.getFogValue();
            // console.log("11",fogList)
            this.fogColor = fogList.colorHex;
            this.density = fogList.density;
            this.turnFog = fogList.turnFog;
            this.far = fogList.far;
            this.near = fogList.near;
        },
        selectChange(){
            let HemisphereList = ISetting.getAmbientSetting();
            // console.log("11",HemisphereList)
            let box = document.querySelector(".box")
            if(this.select == 0){
                ISetting.setEnvLightMode(1)
                this.ambient = false;
                this.hemisphere = true;
            }else if(this.select == 1){
                ISetting.setEnvLightMode(0)
                this.ambient = true;
                this.hemisphere = false;
            }else{
                ISetting.setEnvLightMode(2)
                this.ambient = false;
                this.hemisphere = false;
            }
        },
        SceneChange(event){
            let checked = event.target.checked;
            ISetting.setGrideVisible(this.scenenetwork)
            // console.log(this.scenenetwork)
        },
        collisionChange(){
            ISetting.setTurnPhysicsHelper(this.collision)
        },
        //rgb转十六进制
        transitionColror(color){
             var values = color
            .replace(/rgba?\(/, '')
            .replace(/\)/, '')
            .replace(/[\s+]/g, '')
            .split(',');
            var a = parseFloat(values[3] || 1),
                r = Math.round(a * parseInt(values[0]) + (1 - a) * 255),
                g = Math.round(a * parseInt(values[1]) + (1 - a) * 255),
                b = Math.round(a * parseInt(values[2]) + (1 - a) * 255);
            return "#" +
                ("0" + r.toString(16)).slice(-2) +
                ("0" + g.toString(16)).slice(-2) +
                ("0" + b.toString(16)).slice(-2);
        },
        //点击选色
        activeColor(value){
            let value1 =  this.transitionColror(value)
            this.color = value1;
            ISetting.setEnvLightMode(1)
            let color = value1.slice(1);
            let rgba = [
                parseInt('0x'+color.slice(0, 2)),
                parseInt('0x'+color.slice(2, 4)),
                parseInt('0x'+color.slice(4, 6)),
            ];
            let color0 = rgba[0]/255;
            let color1 = rgba[1]/255;
            let color2 = rgba[2]/255;
            let prop = {
                color:[color0,color1,color2],
                groundColor:[1,1,1],
                intensity:Number(this.intensity)
            }
            ISetting.setHemisphereSetting(prop)
            
        },
        //上半圈颜色
        TopColorChange(){
            ISetting.setEnvLightMode(1)
            let color = this.color.slice(1);
            let rgba = [
                parseInt('0x'+color.slice(0, 2)),
                parseInt('0x'+color.slice(2, 4)),
                parseInt('0x'+color.slice(4, 6)),
            ];
            let color0 = rgba[0]/255;
            let color1 = rgba[1]/255;
            let color2 = rgba[2]/255;
            let prop = {
                color:[color0,color1,color2],
                groundColor:[1,1,1],
                intensity:Number(this.intensity)
            }
            ISetting.setHemisphereSetting(prop)
        },
        BottomActiveColor(value){
            let value1 =  this.transitionColror(value)
            this.groundColor = value1;
            let topColor = this.color.slice(1);
            let rgb = [
                parseInt('0x'+topColor.slice(0, 2)),
                parseInt('0x'+topColor.slice(2, 4)),
                parseInt('0x'+topColor.slice(4, 6)),
            ];
            let topColor0 = rgb[0]/255;
            let topColor1 = rgb[1]/255;
            let topColor2 = rgb[2]/255;

            let color = value1.slice(1);
            let rgba = [
                parseInt('0x'+color.slice(0, 2)),
                parseInt('0x'+color.slice(2, 4)),
                parseInt('0x'+color.slice(4, 6)),
            ];
            let color0 = rgba[0]/255;
            let color1 = rgba[1]/255;
            let color2 = rgba[2]/255;
            let prop = {
                color:[topColor0,topColor1,topColor2],
                groundColor:[color0,color1,color2],
                intensity:Number(this.intensity)
            }
            ISetting.setHemisphereSetting(prop)
        },
        //下半圈颜色
        BottomColorChange(){
            let topColor = this.color.slice(1);
            let rgb = [
                parseInt('0x'+topColor.slice(0, 2)),
                parseInt('0x'+topColor.slice(2, 4)),
                parseInt('0x'+topColor.slice(4, 6)),
            ];
            let topColor0 = rgb[0]/255;
            let topColor1 = rgb[1]/255;
            let topColor2 = rgb[2]/255;

            let color = this.groundColor.slice(1);
            let rgba = [
                parseInt('0x'+color.slice(0, 2)),
                parseInt('0x'+color.slice(2, 4)),
                parseInt('0x'+color.slice(4, 6)),
            ];
            let color0 = rgba[0]/255;
            let color1 = rgba[1]/255;
            let color2 = rgba[2]/255;
            let prop = {
                color:[topColor0,topColor1,topColor2],
                groundColor:[color0,color1,color2],
                intensity:Number(this.intensity)
            }
            ISetting.setHemisphereSetting(prop)
        },
        AmbientActiveColor(value){
            let value1 =  this.transitionColror(value)
            this.hemisphereLightColor = value1;
            let color = value1.slice(1);
            let rgba = [
                parseInt('0x'+color.slice(0, 2)),
                parseInt('0x'+color.slice(2, 4)),
                parseInt('0x'+color.slice(4, 6)),
            ];
            let color0 = rgba[0]/255;
            let color1 = rgba[1]/255;
            let color2 = rgba[2]/255;
            let prop = {
                color:[color0,color1,color2],
                intensity:Number(this.sphereIntensity)
            }
            ISetting.setAmbientSetting(prop)
        },
        //全局光颜色
        hemisphereColor(){
            let color = this.hemisphereLightColor.slice(1);
            let rgba = [
                parseInt('0x'+color.slice(0, 2)),
                parseInt('0x'+color.slice(2, 4)),
                parseInt('0x'+color.slice(4, 6)),
            ];
            let color0 = rgba[0]/255;
            let color1 = rgba[1]/255;
            let color2 = rgba[2]/255;
            let prop = {
                color:[color0,color1,color2],
                intensity:Number(this.sphereIntensity)
            }
            ISetting.setAmbientSetting(prop)
        },
        exposureChange(){
            ISetting.setExposure(Number(this.exposure))
        },
        //input加减
        changeNum(){
            if(this.select == 0){
                let topColor = this.color.slice(1);
                let rgb = [
                    parseInt('0x'+topColor.slice(0, 2)),
                    parseInt('0x'+topColor.slice(2, 4)),
                    parseInt('0x'+topColor.slice(4, 6)),
                ];
                let topColor0 = rgb[0]/255;
                let topColor1 = rgb[1]/255;
                let topColor2 = rgb[2]/255;

                let color = this.groundColor.slice(1);
                let rgba = [
                    parseInt('0x'+color.slice(0, 2)),
                    parseInt('0x'+color.slice(2, 4)),
                    parseInt('0x'+color.slice(4, 6)),
                ];
                let color0 = rgba[0]/255;
                let color1 = rgba[1]/255;
                let color2 = rgba[2]/255;
                let prop = {
                    color:[topColor0,topColor1,topColor2],
                    groundColor:[color0,color1,color2],
                    intensity:Number(this.intensity)
                }
                ISetting.setHemisphereSetting(prop)
            }else if(this.select == 1){
                let color = this.hemisphereLightColor.slice(1);
                let rgba = [
                parseInt('0x'+color.slice(0, 2)),
                parseInt('0x'+color.slice(2, 4)),
                parseInt('0x'+color.slice(4, 6)),
                ];
                let color0 = rgba[0]/255;
                let color1 = rgba[1]/255;
                let color2 = rgba[2]/255;
                let prop = {
                    color:[color0,color1,color2],
                    intensity:Number(this.sphereIntensity)
                }
                ISetting.setAmbientSetting(prop)
            }
        },
        curChange(){
            if (this.cur) {
              this.cur = false
            } else {
              this.cur = true;
            }
        },
        gravityChange(){
            if (this.gravity) {
              this.gravity = false
            } else {
              this.gravity = true;
            }
        },
        unfoldChange(){
            if (this.active) {
              this.active = false
            } else {
              this.active = true;
            }
        },
        fogChange(){
            if (this.fogActive) {
              this.fogActive = false
            } else {
              this.fogActive = true;
            }
        },
        particleChange() {
          this.particleActive = !this.particleActive
        },

        fogChangeColor(){
            let color = this.fogColor.slice(1);
            let rgba = [
                parseInt('0x'+color.slice(0, 2)),
                parseInt('0x'+color.slice(2, 4)),
                parseInt('0x'+color.slice(4, 6)),
            ];
            let color0 = rgba[0]/255;
            let color1 = rgba[1]/255;
            let color2 = rgba[2]/255;
            let data = {
                color:[color0,color1,color2],
                near:Number(this.near),
                far:Number(this.far)
            }
            ISetting.setFogValue(data)
        },
        fogActiveColor(value){
            let value1 =  this.transitionColror(value)
            this.fogColor = value1;
            let color = value1.slice(1);
            let rgba = [
                parseInt('0x'+color.slice(0, 2)),
                parseInt('0x'+color.slice(2, 4)),
                parseInt('0x'+color.slice(4, 6)),
            ];
            let color0 = rgba[0]/255;
            let color1 = rgba[1]/255;
            let color2 = rgba[2]/255;
            let data = {
                color:[color0,color1,color2],
                near:Number(this.near),
                far:Number(this.far)
            }
            ISetting.setFogValue(data)
        },
        FogMicChange(){
            let color = this.fogColor.slice(1);
            let rgba = [
                parseInt('0x'+color.slice(0, 2)),
                parseInt('0x'+color.slice(2, 4)),
                parseInt('0x'+color.slice(4, 6)),
            ];
            let color0 = rgba[0]/255;
            let color1 = rgba[1]/255;
            let color2 = rgba[2]/255;
            let data = {
                color:[color0,color1,color2],
                near:Number(this.near),
                far:Number(this.far)
            }
            ISetting.setFogValue(data)
        },
        setTurnFog(){
            ISetting.setTurnFog(this.turnFog)
        },
        Focus(){
            ISetting.isInput(true);
        },
        Blur(){
            ISetting.isInput(false);
        },
        inputValue(e){
            let key = e.key;
            if(key === "e" || key === "E" || key === "+"  || key === "-"){
                 e.returnValue = false;
            }else{
                e.returnValue = true;
            }
        },
        bHelperChange(event){
            let checked = event.target.checked;
            ISetting.setSceneEnvMap(this.bHelper)
        }
    },
}
</script>
<style scoped>
.container{
    width: 100%;
    height: 1530px;
    /* overflow-y: auto; */
}
.box{
    width: 80%;
    height: 330px;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    border-radius: 5px;
    margin-top:8px;
    margin-left: 70px;
    font-size: 10px;
    background: #fff;
    overflow: hidden;
}
.anmDiv{
transition: 1S;
 
}
.aaa{
height: 25px;
transition: 1S;
}
.boxOne{
    width: 80%;
    height: 240px;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    border-radius: 5px;
    margin-top: 20px;
    margin-left:70px;
    font-size: 10px;
    background: #ffff;
    overflow: hidden;
    position: relative;
}
.boxOne .header p{
    width: 100px;
    margin-left: 10px;
}
.anmDiv{
transition: 1S;
 
}
.aaa{
height: 40px;
transition: 1S;
}
.header{
    display: flex;
    width: 90%;
    height: 25px;
    margin: 8px auto;
    align-items: center;
    color: #000;
    font-weight: bold;
    font-size: 12px;
}
.ipt_box{
    width: 260px;
    height: 30px;
    box-shadow: 0px 0px 2px 0px rgba(33, 35, 49, 0.1);
    border-radius: 6px;
    display: flex;
    align-items: center;
}
.type{
    display: flex;
    align-items: center;
    margin-top: 10px;
}
.type p{
    width: 100px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-weight: bold;
    overflow: hidden;
}.type .select{
    width: 260px;
    height: 28px;
    outline: none;
    color: #808080;
    border: none;
    border-radius:4px;
}
.select /deep/ .el-input{
   width: 260px !important;
   height: 35px !important;
   outline: none !important;
}
.select /deep/ .el-input__inner{
   width: 260px !important;
   height: 28px !important;
   outline: none !important;
}
.select /deep/ .el-select__caret{
  height: 28px !important;
  line-height: 28px !important;
}
.header p{
    width: 60px;
    height: 30px;
    line-height: 30px;
    overflow: hidden;
    margin-left: 10px;
}
.header i{
    font-weight: bold;
    cursor: pointer;
}
.color{
    margin-top: 15px;
}
.color .title{
    width: 90%;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-radius:7px;
    background: #F7F7F7;
    margin: auto;
    overflow: hidden;
}
.firstHalf{
    display: flex;
    align-items: center;
    margin-top: 10px;
}
.firstHalf p{
    width: 100px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-weight: bold;
    overflow: hidden;
}
.firstHalf >>>.el-color-picker__trigger{
    height: 30px;
    width: 245px;
    margin-top: 5px;
}
.firstHalf input{
    outline: none;
    height: 25px;
    width: 90px;
    border: none;
    border-radius: 5px;
    color: #808080;
    text-indent: 5px;
}
.firstHalf1{
    display: flex;
    align-items: center;
}
.firstHalf1  p{
    width: 100px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-weight: bold;
    overflow: hidden;
}
.firstHalf1 i{
    font-weight: bold;
    cursor: pointer;
    margin-left: 20px;
}
.firstHalf2{
     display: flex;
    width: 100%;
    height: 25px;
    margin: 8px auto;
    align-items: center;
    /* color: #000; */
    font-weight: bold;
    font-size: 12px;
    margin-top: 20px;
}
.firstHalf2  p{
    width: 100px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    font-weight: bold;
    overflow: hidden;
}
.txt_box{
    width: 100px;
    height: 28px;
    box-shadow: 0px 0px 2px 0px rgba(33, 35, 49, 0.1);
    border-radius: 6px;
    display: flex;
    align-items: center;
}
.txt_box input{
    outline: none;
    height: 25px;
    width: 90px;
    border: none;
    border-radius: 5px;
    color: #808080;
    text-indent: 5px;
}
.shadow{
    display: flex;
    width: 90%;
    height: 25px;
    display: flex;
    align-items: center;
    margin:15px auto;
}
.shadow .title{
    width: 80px;
    height: 20px;
    line-height: 20px;
    background: #fff;
    text-align: center;
    font-weight: bold;
    /* overflow: hidden; */
}
.ipt_box1{
    width: 65px;
    height: 25px;
    background: #FFFFFF;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.img_box{
    width: 150px;
    height: 80px;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    margin-top: 50px;
}
.img_box img{
    width: 150px;
    height: 80px;
    object-fit: cover;
}
img[src=""],img:not([src]){
    opacity:0;
}
.input:checked {
	background:#1673ff
}
.input {
	width:16px;
	height:16px;
    outline: none;
	background-color:rgba(125, 128, 133, 0.4);
	border:solid 1px #dddddd;
	-webkit-border-radius:50%;
	border-radius:50%;
	font-size:0.8rem;
	padding:0;
	position:relative;
	display:inline-block;
	vertical-align:top;
	cursor:default;
	-webkit-appearance:none;
	-webkit-user-select:none;
	user-select:none;
	-webkit-transition:background-color ease 0.1s;
	transition:background-color ease 0.1s;
	outline: none;
}
.input:checked::after {
	content:'';
	top:3px;
	left:3px;
	position:absolute;
	background:transparent;
	border:#fff solid 2px;
	border-top:none;
	border-right:none;
	height:4px;
	width:6px;
	-moz-transform:rotate(-45deg);
	-ms-transform:rotate(-45deg);
	-webkit-transform:rotate(-45deg);
	transform:rotate(-45deg);
}
.selectBox{
    display: flex;
    width: 90%;
    height: 25px;
    margin: 8px auto;
    align-items: center;
    color: #000;
    font-weight: bold;
    font-size: 12px;
    margin-top: 20px;
}
.ipt_box{
    width: 260px;
    height: 30px;
    box-shadow: 0px 0px 2px 0px rgba(33, 35, 49, 0.1);
    border-radius: 6px;
    display: flex;
    align-items: center;
}
.selectBox .select{
    width: 260px;
    height: 28px;
    outline: none;
    color: #808080;
    border: none;
    border-radius:4px;
    /* margin-left: 10px; */
}
.selectBox .select /deep/ .el-input{
   width: 260px !important;
   height: 35px !important;
   outline: none !important;
}
.selectBox .select /deep/ .el-input__inner{
   width: 260px !important;
   height: 28px !important;
   outline: none !important;
}
.selectBox .select /deep/ .el-select__caret{
  height: 28px !important;
  line-height: 28px !important;
}
.selectBox p{
    width: 80px;
    height: 30px;
    line-height: 30px;
    overflow: hidden;
    margin-left: 15px;
}
.loction1{
    width: 90%;
    margin: 20px auto;
    display: flex;
    height: 25px;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
}
.loction1 .title{
    width: 80px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    font-weight: bolder;
    overflow: hidden;
}
.axis{
    width: 24%;
    height: 100%;
    display: flex;
    align-items: center;
    background-color:#F7F7F7;
    border-radius: 5px;

}
.axis p{
    width: 22px;
    height: 18px;
    line-height: 20px;
    text-align: center;
    color: #929292;
    background-color: #fff;
    margin-left: 2px;
    border-radius: 4px;
    float: left;
    color: #000000;
    font-size: 12px;
}
.axis input{
    width: 90%;
    height: 18px;
    text-align: center;
    outline: none;
    border: none;
    background-color: #F7F7F7;
    border-radius: 4px;
    font-weight: bold;
    font-size: 8px;
}

.x-axis p{
    background-color:#F20004;
}
.y-axis p{
    background-color: #43BD00;
}
.z-axis p {
    background-color: #5180E9;
}
</style>