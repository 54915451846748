class ObjectsRepos {
    constructor() {
        this.m_objectsMap = new Map();
    }

    add(object, ext) {
        if (this.m_objectsMap.get(object) == undefined) {
            this.m_objectsMap.set(object, ext);
            return true;
        }
        return false;
    }

    get(object) {
        return this.m_objectsMap.get(object);
    }

    remove(object) {
        if (this.m_objectsMap.get(object) != undefined) {
            this.m_objectsMap.delete(object);
            return true;
        }
        return false;
    }

    clear() {
        this.m_objectsMap.clear();
    }

}

export { ObjectsRepos };