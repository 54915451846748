import { Vector3 } from 'three';
import { Object3D } from 'three';
import { Line } from 'three';
import { Float32BufferAttribute } from 'three';
import { BufferGeometry } from 'three';
import { LineBasicMaterial } from 'three';
import { MainObjects } from '../common/MainObjects';
import { GameScene } from '../object/GameScene';

const _v1 = /*@__PURE__*/ new Vector3();
const _v2 = /*@__PURE__*/ new Vector3();
const _v3 = /*@__PURE__*/ new Vector3();

class DirectionalLightHelper extends Object3D {

	constructor( light, size, color ) {

		super();
		this.light = light;
		this.light.updateMatrixWorld();
		this.m_target_obj = new Object3D();
		MainObjects.Scene.m_helperGroup.add(this.m_target_obj);
		this.layers.set(11);

		this.matrix = light.matrixWorld;
		this.matrixAutoUpdate = false;

		this.color = color;

		if ( size === undefined ) size = 0.1;

		var gameScene = MainObjects.Blueprint.m_sceneManagerBp.m_gameScene;
		
		// let geometry = gameScene.m_geoRepos.get(GameScene.GeoKey.e_LINE_PLAN_0_1);
		var material = gameScene.m_matRepos.get(GameScene.MatKey.e_LINE);

		// this.lightPlane = new Line( geometry, material );
		// this.lightPlane.renderOrder = 100;
		// this.add( this.lightPlane );

		var geometryline = gameScene.m_geoRepos.get(GameScene.GeoKey.e_LINE);

		this.targetLine = new Line( geometryline, material );
		this.add( this.targetLine );
		this.targetLine.renderOrder = 100;
        this.targetLine.scale.z = 0.5;
		this.update();
	}

	dispose() {

		// this.lightPlane.geometry.dispose();
		// this.lightPlane.material.dispose();
		// this.targetLine.geometry.dispose();
		// this.targetLine.material.dispose();
		this.m_target_obj.removeFromParent();

	}

	update() {
		var position = new Vector3();
        var target = new Vector3();
        this.light.getWorldPosition(position);
        this.light.getWorldDirection(target);
		position.add(target);
		this.m_target_obj.position.copy(position);
		this.light.target = this.m_target_obj;
		this.light.target.updateMatrixWorld(true);

		_v1.setFromMatrixPosition( this.light.matrixWorld );
		_v2.setFromMatrixPosition( this.light.target.matrixWorld );
		_v3.subVectors( _v2, _v1 );

		// this.lightPlane.lookAt( _v2 );
		this.targetLine.lookAt( _v2 );
		this.updateMatrixWorld(true);
	}

}


export { DirectionalLightHelper };