<template>
    <div class="container">
        <div class="caption">旋转</div>
        <div class="alpha_box">
            <div class="loction1">
                <div class="x-axis axis">
                    <p>X</p>
                    <input v-model="rotateX" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @change="changeNum($event)"  @focus="Focus" @blur="Blur" />
                </div>
                <div class="y-axis axis">
                    <p>Y</p>
                    <input v-model="rotateY" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @change="changeNum($event)"  @focus="Focus" @blur="Blur"/>
                </div>
                <div class="z-axis axis">
                    <p>Z</p>
                    <input v-model="rotateZ" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @change="changeNum($event)"  @focus="Focus" @blur="Blur"/>
                </div>
            </div>
            <div class="easing">
                <div class="title">Easing</div>
                <el-select class="select" @change="rotateSelect()"   v-model="rotate" placeholder="请选择">
                    <el-option
                        v-for="item in rotateList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </div>
        </div>
    </div>
</template>
<script>
import { ISetting } from "../../threejs/interface/ISetting";
export default {
    data(){
        return{
            rotate:'',
            rotateX:0,
            rotateY:0,
            rotateZ:0,
            rotateList:[],
        }
    },
    mounted(){

    },
    methods:{
        rotateSelect(){

        },
        changeNum(){

        },
        Focus(){
            ISetting.isInput(true);
        },
        Blur(){
            ISetting.isInput(false);
        },
    }
}
</script>
<style scoped>
.container{
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding-bottom: 30px;
}
.container::-webkit-scrollbar{
  display: none;
}
.caption{
    width: 100%;
    height: 30px;
    line-height: 30px;
    background: #F7F7F7;
    font-weight: bolder;
    font-size: 12px;
    text-align: center;
    margin-top: 10px;
}
alpha {
    width: 100%;
}
.alpha .particles {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 15px;
}
.particles .title{
    width: 50px;
    height: 30px;
    font-size: 12px;
    line-height: 30px;
    text-align: center;
    font-weight: bold;
    overflow: hidden;
    font-size: 14px;
}
.particles .txt_box{
    width: 150px;
    height: 28px;
    background: #FFFFFF;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left:2px ;
}
.particles .txt_box .txt{
    display: inline-block;
    width: 40px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    font-size: 12px;
    color: #808080;
}
.particles .txt_box input{
    width: 110px;
    height: 25px;
    border: none;
    border-radius: 5px;
    outline: none;
    font-size: 10px;
    background-color: rgba(0, 0, 0, 0);
    color: #808080;
    text-indent: 5px;
}
.particles .txt_box1 .txt1{
    display: inline-block;
    width: 80px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    font-size: 10px;
    color: #808080;
}
.particles .txt_box1 input{
    width: 70px;
    height: 25px;
    border: none;
    border-radius: 5px;
    outline: none;
    font-size: 10px;
    background-color: rgba(0, 0, 0, 0);
    color: #808080;
    text-indent: 5px;
}
.particles .txt_box2{
    width: 95px;
    height: 28px;
    background: #FFFFFF;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-left:10px ; */
}
.particles .txt_box2 .txt{
    display: inline-block;
    width: 40px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    font-size: 10px;
    color: #808080;
}
.particles .txt_box2 input{
    width: 55px;
    height: 25px;
    border: none;
    border-radius: 5px;
    outline: none;
    font-size: 10px;
    background-color: rgba(0, 0, 0, 0);
    color: #808080;
    text-indent: 5px;
}

.easing{
    width: 100%;
    display: flex;
    margin-top: 20px;
    justify-content: space-around;
}
.easing .title{
    width: 60px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    font-weight: bold;
    overflow: hidden;
    font-size: 14px;
}
.select{
    width: 240px;
    height: 28px;
    /* position: relative; */
}
.select /deep/ .el-input{
   width: 100% !important;
   height: 28px !important;
   outline: none !important;
}
.select /deep/ .el-input__inner{
   width: 100% !important;
   height: 28px !important;
   outline: none !important;
}
.select /deep/ .el-select__caret{
  height: 100% !important;
  line-height: 28px !important;
}
.loction1{
    width: 100%;
    display: flex;
    height: 25px;
    align-items: center;
    justify-content: space-around;
    overflow: hidden;
    margin:10px auto;
}
.axis{
    width: 25%;
    height: 100%;
    display: flex;
    align-items: center;
    background-color:#F7F7F7;
    border-radius: 5px;
}
.axis p{
    width: 25px;
    height: 22px;
    line-height: 22px;
    text-align: center;
    color: #929292;
    background-color: #fff;
    margin-left: 2px;
    border-radius: 4px;
    float: left;
    color: #000000;
    font-size: 12px;
}
.axis input{
    width: 90%;
    height: 22px;
    text-align: center;
    outline: none;
    border: none;
    background-color: #F7F7F7;
    border-radius: 4px;
    font-weight: bold;
    font-size: 8px;
}
.x-axis p{
    background-color:#F20004;
}
.y-axis p{
    background-color: #43BD00;
}
.z-axis p {
    background-color: #5180E9;
}
</style>