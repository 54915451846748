import * as THREE from 'three';
import SpriteText from 'three-spritetext';

class HudBehavior {
    constructor() {
        this.m_center = new THREE.Vector3();
        this.m_text = "";
        this.m_size = 0.2;
        this.m_sprite = null;
        this.m_colorArray = [1, 1, 1];
        this.m_color = "#ffffff"
    }

    getJson()
    {
        var data = {
            m_center : [0, 0, 0],
            m_text : this.m_text,
            m_size : this.m_size,
            m_colorArray : this.m_colorArray,
            m_color : "#ffffff"
        }
        this.m_center.toArray(data.m_center);
        var color = new THREE.Color(this.m_colorArray[0], this.m_colorArray[1], this.m_colorArray[2]);
        data.m_color = "#" + color.getHexString();
        return data;
    }
    
    setJson(json)
    {
        this.m_colorArray = json.m_colorArray;
        this.m_center.fromArray(json.m_center);
        this.m_text = json.m_text;
        this.m_size = json.m_size;
        var color = new THREE.Color(this.m_colorArray[0], this.m_colorArray[1], this.m_colorArray[2]);
        this.m_color = "#" + color.getHexString();
    }

    init(object)
    {
        if(this.m_sprite == null)
        {
            this.m_sprite = new SpriteText(this.m_text, this.m_size, this.m_color);
            this.m_sprite.userData = null;
            object.add(this.m_sprite);
            this.m_sprite.position.copy(this.m_center);
        }
        else
        {
            this.m_sprite.text = this.m_text;
            this.m_sprite.textHeight = this.m_size;
            this.m_sprite.color = this.m_color;
            this.m_sprite.position.copy(this.m_center);
        }
        object.updateMatrixWorld(true);
    }

    remove()
    {
        this.m_sprite.removeFromParent();
        this.m_sprite.traverse(function (child) {
            if (child.isMesh) {
                child.material.dispose();
                child.geometry.dispose();
            }
            else if (child.isSprite) {
                child.material.dispose();
            }
        });
    }
    init(object){

    }

    destory()
    {
        this.remove();
    }
}

export { HudBehavior };
