import * as THREE from 'three';
// import { GLTFLoader } from '../loader/GLTFLoader'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { OBJLoader } from './OBJLoader.js';
import { GameObject } from "../object/GameObject"
import { MaterialFactory } from "../factory/MaterialFactory"
import { TextureObject } from "../object/TextureObject"
import { MainObjects } from '../common/MainObjects';
import * as SkeletonUtils from 'three/examples/jsm/utils/SkeletonUtils.js';
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader"
import { ITransform } from '../interface/ITransform.js';
import { IScene } from '../interface/IScene.js';
import { ISelector } from '../interface/ISelector.js';
import { ObjectFactory } from '../factory/ObjectFactory.js';
import { MaterialObject } from '../object/MaterialObject.js';
import { Saver } from './Saver.js';
import { ParticleFactory } from '../factory/ParticleFactory.js';
import { EXRLoader } from 'three/examples/jsm/loaders/EXRLoader';

class Loader {

    static m_objectGroupTemp = null;

    static loadFiles(files, ext, processCallback, finishCallback) {
        if (files.length > 0) {
            const manager = new THREE.LoadingManager();
            manager.onProgress = function (url, itemsLoaded, itemsTotal) {
                processCallback(itemsLoaded / itemsTotal);
            };

            manager.onError = function (url) {
                console.log('There was an error loading ' + url);
            };
            for (let i = 0; i < files.length; i++) {
                var file = files[i];
                var filename = file.split('?')[0];
                const extension = filename.split('.').pop().toLowerCase();
                if (ext.indexOf(extension) != -1 || ext == extension) {
                    Loader.loadFile(files[i], extension, manager, finishCallback, true);
                    break;
                }
            }
        }
    };

    static loadFile(file, extension, manager, callback, bTemp = false) {
        var gameScene = MainObjects.Blueprint.m_sceneManagerBp.m_gameScene;
        switch (extension) {

            case 'gltf':
                {
                    if (bTemp) {
                        const loader = new GLTFLoader(manager);
                        // loader.notCreateMaterial = true;
                        loader.load(file, function (gltf) {
                            callback(gltf, extension);
                        });
                        return;
                    }
                    var gltfCopy = gameScene.m_gltfRepos.get(file);
                    if (gltfCopy == undefined || gltfCopy == null) {
                        const loader = new GLTFLoader(manager);
                        // loader.notCreateMaterial = true;
                        loader.load(file, function (gltf) {
                            gameScene.m_gltfRepos.add(file, gltf)
                            callback(gltf, extension);
                        });
                    }
                    else {
                        callback(gltfCopy, extension);
                    }
                    break;
                }
            case "jpg":
            case "jpeg":
            case "png":
                {
                    if (bTemp) {
                        const loader = new THREE.TextureLoader(manager);
                        loader.load(file, function (texture) {
                            callback(texture, extension);
                        });
                        return;
                    }
                    var textureCopy = gameScene.m_textureRepos.get(file);
                    if (textureCopy == undefined || textureCopy == null) {
                        const loader = new THREE.TextureLoader(manager);
                        loader.load(file, function (texture) {
                            gameScene.m_textureRepos.add(file, texture)
                            callback(texture, extension);
                        });
                    }
                    else {
                        callback(textureCopy, extension);
                    }
                    break;
                }
            case "hdr":
                {
                    if (bTemp) {
                        const loader = new RGBELoader(manager);
                        loader.load(file, function (texture) {
                            texture.mapping = THREE.EquirectangularReflectionMapping;
                            callback(texture, extension);
                        });
                    }
                    var textureCopy = gameScene.m_textureRepos.get(file);
                    if (textureCopy == undefined || textureCopy == null) {
                        const loader = new RGBELoader(manager);
                        loader.load(file, function (texture) {
                            gameScene.m_textureRepos.add(file, texture)
                            texture.mapping = THREE.EquirectangularReflectionMapping;
                            callback(texture, extension);
                        });
                    }
                    else {
                        callback(textureCopy, extension);
                    }
                }
                break;
            case "exr":
                {
                    if (bTemp) {
                        const loader = new EXRLoader(manager);
                        loader.load(file, function (texture) {
                            callback(texture, extension);
                        });
                    }
                    var textureCopy = gameScene.m_textureRepos.get(file);
                    if (textureCopy == undefined || textureCopy == null) {
                        const loader = new EXRLoader(manager);
                        loader.load(file, function (texture) {
                            gameScene.m_textureRepos.add(file, texture)
                            callback(texture, extension);
                        });
                    }
                    else {
                        callback(textureCopy, extension);
                    }
                }
                break;
            case "mp3":
                {
                    if (bTemp) {
                        const loader = new THREE.AudioLoader(manager);
                        loader.load(file, function (audioBuffer) {
                            callback(audioBuffer, extension);
                        });
                        return;
                    }



                    var mp3Copy = gameScene.m_mp3Repos.get(file);
                    if (mp3Copy == undefined || mp3Copy == null) {
                        const loader = new THREE.AudioLoader(manager);
                        loader.load(file, function (audioBuffer) {
                            gameScene.m_mp3Repos.add(file, audioBuffer)
                            callback(audioBuffer, extension);
                        });
                    }
                    else {

                        callback(mp3Copy, extension);
                    }
                    break;
                }
            default:
                console.error('Unsupported file format (' + extension + ').');
                break;

        }
    }

    static loadFileToScene(url, name, id, processCallback, finishCallback, manager = null, file_name = "model", addScene = null) {
        
        if (manager == null) {
            manager = new THREE.LoadingManager();
            manager.onProgress = function (url, itemsLoaded, itemsTotal) {
                processCallback(itemsLoaded / itemsTotal);
            };

            manager.onError = function (url) {
                console.log('There was an error loading ' + url);
            };
        }
        var file = url;
        var filename = file.split('?')[0];
        const extension = filename.split('.').pop().toLowerCase();
        Loader.loadFile(url, extension, manager, function (res, extension) {
            switch (extension) {
                
                case 'gltf':
                    {
                        var group = null;
                        var isSkinMesh = false;

                        if (extension == 'gltf') {
                            res.scene.traverse(function (child) {
                                if (child.isSkinnedMesh) {
                                    isSkinMesh = true;
                                }
                            });
                        }
                        if (isSkinMesh) {
                            group = SkeletonUtils.clone(res.scene);
                        }
                        else {
                            if (extension == 'gltf') {
                                group = res.scene.clone();
                            }
                            else {
                                group = res.clone();
                            }
                        }

                        group.userData = new GameObject();
                        group.userData.m_gameObjectType = GameObject.GameObjectType.e_GLTF;
                        group.userData.m_name = file_name;
                        group.userData.m_id = id;
                        group.userData.m_gltf = res;
                        group.userData.m_url = name;
                        group.name = group.uuid;

                        var skinMeshList = new Array();
                        group.traverse(function (child) {
                            if (child.isMesh || child.isSkinnedMesh) {
                                MaterialFactory.createStandardMaterial(child.material);
                                child.material.userData.setDefultTextureUserData();
                                child.userData = new GameObject();
                                child.userData.m_gameObjectType = GameObject.GameObjectType.e_GLTF_MESH;
                                child.userData.m_name = child.name;
                                child.userData.m_parent = group;
                                if (child.isSkinnedMesh) {
                                    skinMeshList.push(child);
                                    group.userData.m_skeleton = child.skeleton;
                                }
                                group.userData.m_gltf_meshList.push(child);
                            }
                        });

                        if (isSkinMesh) {
                            MainObjects.Blueprint.m_animationManagerBp.computeBoundingBox(group, skinMeshList);
                        }

                        MainObjects.Blueprint.m_animationManagerBp.initAnimation(group, isSkinMesh);

                        if (addScene == null) {
                            MainObjects.Scene.m_userGroup.add(group);
                        }
                        else {
                            addScene.add(group);
                        }
                        if (isSkinMesh) {
                            group.userData.m_skeleton_name = group.id;
                        }
                        finishCallback(group);
                        break;
                    }
                case "jpg":
                case "jpeg":
                case "png":
                case "hdr":
                    {
                        var texture = res.clone();
                        //var texture = res;
                        if (extension != 'hdr') {
                            texture.wrapS = THREE.RepeatWrapping;
                            texture.wrapT = THREE.RepeatWrapping;
                            texture.flipY = false;
                        }
                        texture.userData = new TextureObject();
                        texture.userData.m_textureObjectType = TextureObject.TextureObjectType.e_NET_MAP;
                        texture.userData.m_id = id;
                        texture.userData.m_url = name;
                        texture.userData.m_texture = res;
                        finishCallback(texture);
                        break;
                    }
                case "exr":
                    {
                        var texture = res.clone();
                        texture.userData = new TextureObject();
                        texture.userData.m_textureObjectType = TextureObject.TextureObjectType.e_NET_MAP;
                        texture.userData.m_id = id;
                        texture.userData.m_url = name;
                        texture.userData.m_texture = res;
                        finishCallback(texture);
                        break;
                    }
                case "mp3":
                    {
                        var audioObject = ObjectFactory.createAudio(res, null);
                        audioObject.userData.m_id = id;
                        audioObject.userData.m_name = file_name;
                        audioObject.userData.m_url = name;
                        if (addScene == null) {
                            MainObjects.Scene.m_userGroup.add(audioObject);
                        }
                        else {
                            addScene.add(audioObject);
                        }
                        finishCallback(audioObject);
                        break;
                    }
            }

        });
    }

    static loadGroupToScene(json, processCallback, finishCallback, manager = null, file_name = "group") {
        if (manager == null) {
            manager = new THREE.LoadingManager();
            manager.onProgress = function (url, itemsLoaded, itemsTotal) {
                processCallback(itemsLoaded / itemsTotal);
            };

            manager.onError = function (url) {
                console.log('There was an error loading ' + url);
            };

            manager.onLoad = function () {


                MainObjects.Blueprint.m_sceneManagerBp.getObjectFromJson(json, function (res) {
                    Loader.m_objectGroupTemp = res;
                    Loader.m_objectGroupTemp.position.copy(MainObjects.Blueprint.m_cameraListenerBp.getCameraDirPosition(Loader.m_objectGroupTemp));
                    Loader.m_objectGroupTemp.userData.m_name = file_name;
                    MainObjects.Blueprint.m_selectListenerBp.updateObject(res);
                    ISelector.selectObjectByTree(Loader.m_objectGroupTemp, false);
                    if (IScene.f_sceneTreeCallback != null) {
                        IScene.f_sceneTreeCallback();
                    }
                    ISelector.sceneTreeSelectCallback();
                    finishCallback(Loader.m_objectGroupTemp);
                }, true, manager);
            };
        }

        Saver.m_resMap.clear();


        Saver.getObjectFromJson(json);
        if (Saver.m_resMap.size > 0) {


            Saver.loadFileToScene(manager);
        }
        else {
            manager.onLoad();
        }
    }

    static loadMaterialToObject(url, name, id, json, object, processCallback, finishCallback, manager = null) {
        if (!object.isMesh) {
            finishCallback();
            return;
        }
        if (manager == null) {
            manager = new THREE.LoadingManager();
            manager.onProgress = function (url, itemsLoaded, itemsTotal) {
                processCallback(itemsLoaded / itemsTotal);
            };

            manager.onError = function (url) {
                console.log('There was an error loading ' + url);
            };

            manager.onLoad = function () {
                //update
                if (ITransform.f_selectTransformCallback != null) {
                    ITransform.f_selectTransformCallback(true);
                }
                finishCallback();
            };
        }
        var material = null;
        if (json.type == MaterialObject.MaterialType.e_STANDARD) {
            material = MaterialFactory.createStandardMaterial(null, MaterialObject.MaterialEditorType.e_NET, url, name, id);
        }
        else if (json.type == MaterialObject.MaterialType.e_BASIC) {
            material = MaterialFactory.createBasicMaterial(null, MaterialObject.MaterialEditorType.e_NET, url, name, id);
        }
        else if (json.type == MaterialObject.MaterialType.e_PHONE) {
            material = MaterialFactory.createPhoneMaterial(null, MaterialObject.MaterialEditorType.e_NET, url, name, id);
        } else if (json.type == MaterialObject.MaterialType.e_TOON_PHONE) {
            material = MaterialFactory.createToonPhoneMaterial(null, MaterialObject.MaterialEditorType.e_NET, url, name, id);
        } else if (json.type == MaterialObject.MaterialType.e_TOON_PHONE_TWIST) {
            material = MaterialFactory.createToonPhoneTwistMaterial(null, MaterialObject.MaterialEditorType.e_NET, url, name, id);
        }

        object.material = material;
        MainObjects.Blueprint.m_materialEditorBp.getMaterialFromJson(object, json, manager);
        manager.onLoad();
    }

    static loadParticleSystemToScene(name, id, file_name, json, processCallback, finishCallback) {
        ParticleFactory.createParticleSystemByJson(json, function (group) {
            group.userData.m_name = file_name;
            group.userData.m_id = id;
            group.userData.m_url = name;
            group.name = group.uuid;

            MainObjects.Blueprint.m_particleSystemBp.init(group);
            processCallback(1);
            finishCallback(group);
        });
    }

    static loadMaterialJson(url, child, element, manager) {
        const loader = new THREE.FileLoader();
        //加载一个文本文件，并把结果输出到控制台上
        loader.load(
            // resource URL
            url,

            // onLoad回调
            function (data) {
                // output the text to the console
                if (data != null) {
                    var json = JSON.parse(data);
                    MainObjects.Blueprint.m_materialEditorBp.getMaterialFromJson(child, json, manager);
                }
                else {
                    MainObjects.Blueprint.m_materialEditorBp.getMaterialFromJson(child, element, manager);
                }

            },

            // onProgress回调
            function (xhr) {
            },

            // onError回调
            function (err) {
            }
        );
    }
}

export { Loader };