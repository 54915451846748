<template>
  <div class="main-particle" v-if="particleSystem">
    <el-collapse v-model="activeNames" >
      <el-collapse-item name="1">
        <template slot="title">
<!--          <el-tooltip class="icon " effect="dark" :content="tooltipText(editTypes.emitterShape)" placement="top-start">-->
<!--            <i class="el-icon-arrow-down"></i>-->
<!--          </el-tooltip>-->
          {{ options[editTypes.emitterShape].label }}
        </template>
        <EmitterShapeEditor
            :particleSystem="particleSystem"
        ></EmitterShapeEditor>
      </el-collapse-item>
      <el-collapse-item name="2">
        <template slot="title">
<!--          <el-tooltip class="icon " effect="dark" :content="tooltipText(editTypes.emit)" placement="top-start">-->
<!--            <i class="el-icon-arrow-down"></i>-->
<!--          </el-tooltip>-->
          {{ options[editTypes.emit].label }}
        </template>
        <EmissionEditor
            :particleSystem="particleSystem"
            :updateProperties="updateProperties"
        ></EmissionEditor>
      </el-collapse-item>
      <el-collapse-item name="3">
        <template slot="title">
<!--          <el-tooltip class="icon " effect="dark" :content="tooltipText(editTypes.particleInit)" placement="top-start">-->
<!--            <i class="el-icon-arrow-down"></i>-->
<!--          </el-tooltip>-->
          {{ options[editTypes.particleInit].label }}
        </template>
        <ParticleInitEditor
            :particleSystem="particleSystem"
            :updateProperties="updateProperties"
        ></ParticleInitEditor>
      </el-collapse-item>
      <el-collapse-item name="4">
        <template slot="title">
<!--          <el-tooltip class="icon " effect="dark" :content="tooltipText(editTypes.behavior)" placement="top-start">-->
<!--            <i class="el-icon-arrow-down"> </i>-->
<!--          </el-tooltip>-->
          {{ options[editTypes.behavior].label }}
        </template>
        <BehaviorEditor
            :behaviors="particleSystem.behaviors"

            :updateProperties="updateProperties"
        ></BehaviorEditor>
      </el-collapse-item>
      <el-collapse-item name="5">
        <template slot="title">
<!--          <el-tooltip class="icon " effect="dark" :content="tooltipText(editTypes.particleRender)" placement="top-start">-->
<!--            <i class="el-icon-arrow-down"></i>-->
<!--          </el-tooltip>-->
          {{ options[editTypes.particleRender].label }}
        </template>
        <ParticleRenderEditor
            :textures="MainObjects.DefaultTexture"
            :particleSystem="particleSystem"

            :updateProperties="updateProperties"
        ></ParticleRenderEditor>
      </el-collapse-item>
    </el-collapse>





  </div>
</template>
<script>

import EmitterShapeEditor from "@/views/ParticleSystem/EmitterShapePanel.vue";
import ParticleInitEditor from "@/views/ParticleSystem/ParticleInitPanel.vue";
import BehaviorEditor from "@/views/ParticleSystem/BehaviorsPanel.vue";
import ParticleRenderEditor from "@/views/ParticleSystem/ParticleRenderPanel.vue";
import {ParticleSystem} from "three.quarks";
import {mapMutations} from "vuex";
import EmissionEditor from "@/views/ParticleSystem/EmissionPanel.vue";
import {MainObjects} from "@/threejs/common/MainObjects";

const TYPE_EDIT = {
  emitterShape: 0,
  emit:1,
  particleInit:2,
  behavior:3,
  particleRender:4
}


export default {
  computed: {
    MainObjects() {
      return MainObjects
    },


  },

  components: {ParticleRenderEditor, BehaviorEditor, ParticleInitEditor, EmissionEditor, EmitterShapeEditor},
  props: {
    data: Object,
    particleSystem: Object
  },
  data() {
    const optionsData = [{
      value: TYPE_EDIT.emitterShape,
      label: '发射器形状'
    },{
      value: TYPE_EDIT.emit,
      label: '发射设置'
    },{
      value: TYPE_EDIT.particleInit,
      label: '粒子初始化'
    },{
      value: TYPE_EDIT.behavior,
      label: '行为'
    },{
      value: TYPE_EDIT.particleRender,
      label: '粒子渲染器'
    },]
    return {
      selectedType: optionsData[0].value,
      editTypes: TYPE_EDIT,
      options: optionsData,
      activeNames: ['1']
    }

  },
  methods: {
    ...mapMutations('particleSystem', [
      'updateEmitterShape',
      'updateProperties'
    ]),
    tooltipText(index) {
      const additionalText = [
        '粒子发射器形状和大小，调整对应参数，可影响粒子出现的发散效果',
        '针对发射器最近一步的参数设置调整，调整对应参数，可影响子效果',
        '调整粒子相关的基础参数，根据调整的参数，可对粒子产生变化',
        '对粒子子变化的额外参数设置，对粒子添加不同的行为，可令粒子产生更丰富的变化',
        '根据控制不同的参数，实现粒子渲染器的成像效果']
      return this.options[index].label + '是指' + additionalText[index]

    }
  },
  mounted() {
  }
}
</script>
<style lang="less" >

.main-particle {
  height: 100%;
  padding-bottom: 100%;

  .el-collapse-item {
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    border-radius: 5px;
    margin-top: 20px;
    margin-left:5px;
    margin-right: 5px;
    font-size: 10px;
    background: #ffff;
    transition: 0.8s;
    margin-bottom:20px;
    overflow: hidden;
    position: relative;
  }
  .el-collapse-item__header {
    margin-left: 50px;
  }
  .el-collapse-item__arrow {
    position: absolute;
    left: 20px;
  }

  .particle-header {
    margin-top: 10px;
    display: flex;
    align-items: center;
    .icon {
      transform: translateX(15px);
    }
    .main-select {
      .el-input__inner {
        padding-left: 5px;
        border: none;
        color: black;

      }
    }
  }
  input {
    outline: none;
    color: #808080;
    border: none;
    box-shadow: 0 0 0.125rem 0 rgba(33, 35, 49, 0.1);
    border-radius: 0.375rem;
  }



}
.el-icon-question {
  font-size: 25px;
  margin-left: 20px;
  margin-right: 10px;
}
.main-select {
  margin-left: 20px;
}
.property {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: 10px 4px 10px 0px;
  padding-left: 50px;

  .name {
    width: 115px;
  }
  .editor {
    width: 155px;
  }
}

</style>