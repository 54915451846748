class IImage {
    static hdr = "res/hdr/venice_sunset_1k.hdr";
    static hdr_image = "res/hdr/venice_sunset_1k.png";
    static sceneImage = "res/ui/scene.png";
    static behaviorJS = "res/js/Behavior.js";
    static behaviorImage = "/res/ui/icon_js.png";
    static audioImage = "res/ui/audioIcon.png";
    static particleImage = "res/ui/particleIcon.png";
    // static animationBehaviorImage = "res/ui/animationBehaviorIcon.png";
    static animationBehaviorImage = "res/ui/icon_js.png";

    //内置粒子纹理贴图
    static particleTextureImage1 = '/res/texture/particle/particle_texture1.png'
}

class IScript {
    // static m_http = "http://yidascenespace.oss-cn-hangzhou.aliyuncs.com/";
    static m_http = "https://file.yicreator.com/"
    static scriptPath = IScript.m_http + "res/js";
    static three = IScript.m_http + "res/js/three.min.js";
    static enable3d_ammoPhysics = IScript.m_http + "res/js/enable3d.ammoPhysics.0.25.2.min.js";
}

export { IImage, IScript}; 